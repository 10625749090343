
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-cordao-umbilical',
  templateUrl: './cordao-umbilical.component.html',
  styleUrls: ['./cordao-umbilical.component.css']
})
export class CordaoUmbilicalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdArteria') rdArteria: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('ckInsercaoPlacentaria') ckInsercaoPlacentaria: MatCheckbox;
  @ViewChild('slInsercaoCordao') slInsercaoCordao: MatSelect;

  showAndHideTerms: any;

  cordaoUmbilical: ArrayDropDown[] = [
    { value: 'central', viewValue: 'Central' },
    { value: 'paracentral', viewValue: 'Paracentral' },
    { value: 'marginal', viewValue: 'Marginal' },
    { value: 'velamentosa', viewValue: 'Velamentosa' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCkInsercaoCordUmb: true,
      sahInsercaoCordaoUmbilical: false,
    }
  }

  clickNormal(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical = false;
    this.showAndHideTerms.sahCkInsercaoCordUmb = false;
    this.ckInsercaoPlacentaria.checked = false;
    this.catchIDs();
  }

  clickInsercaoCordao(){
    this.showAndHideTerms.sahCkInsercaoCordUmb = true;
    this.catchIDs();
  }

  changeSelect(){
    this.showAndHideTerms.sahCkInsercaoCordUmb = true;
    this.catchIDs();
  }

  clickPlacentaria(){
    if(this.showAndHideTerms.sahInsercaoCordaoUmbilical == true){
      this.showAndHideTerms.sahInsercaoCordaoUmbilical = false;
    }
    else{
      this.showAndHideTerms.sahInsercaoCordaoUmbilical = true;
    }
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdArteria && this.rdArteria.checked){
      this.termoSelecionado.emit(this.rdArteria.id);
    }

    if (this.rdNaoCitar && this.rdNaoCitar.checked){
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.slInsercaoCordao && this.slInsercaoCordao.value  &&
      this.ckInsercaoPlacentaria.checked && !this.rdNaoCitar.checked) {
      const objeto = { elemento: this.slInsercaoCordao, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    
    // if(this.ckInsercaoPlacentaria && this.ckInsercaoPlacentaria.checked){
    //   this.termoSelecionado.emit(this.ckInsercaoPlacentaria.id);
    // }
    this.listaCompleta.emit();
  }

}
