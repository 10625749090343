import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-superficie-ossea-cotovelo',
  templateUrl: './superficie-ossea-cotovelo.component.html',
  styleUrls: ['./superficie-ossea-cotovelo.component.css']
})
export class SuperficieOsseaCotoveloComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEntesofito') rdEntesofito: MatRadioButton;
  @ViewChild('rdEntesofitosMarginais') rdEntesofitosMarginais: MatRadioButton;
  @ViewChild('slLocalizacaoEntesofito') slLocalizacaoEntesofito: MatSelect;
  @ViewChild('slLocalizacaoEntesofitosMarginais') slLocalizacaoEntesofitosMarginais: MatSelect;

  localizacoesEntesofito: ArrayDropDown[] = [
    { value: 'no aspecto posterior do olécrano', viewValue: 'Aspecto posterior do olécrano'},
    { value: 'no epicôndilo lateral', viewValue: 'Epicôndilo lateral' },
    { value: 'no epicôndilo medial', viewValue: 'Epicôndilo medial' }
  ];

  localizacoesEntesofitosMarginais: ArrayDropDown[] = [
    { value: 'radiocapitular', viewValue: 'Radiocapitular' },
    { value: 'ulnotroclear', viewValue: 'Ulnotroclear' },
    { value: 'radioulnar proximal', viewValue: 'Radioulnar proximal' }
  ];

  showAndHideTerms: any;
  showLocalizacoesEntesofito = false;
  showLocalizacoesEntesofitosMarginais = false;

  constructor() { }


  ngOnInit() {
    this.showAndHideTerms = {
      sahIrregularidades: false
    };
  }

  clickNaoCitar(){
    this.showLocalizacoesEntesofito = false;
    this.showLocalizacoesEntesofitosMarginais = false;
    this.catchIDs();
  }

  clickNormal() {
    this.showLocalizacoesEntesofito = false;
    this.showLocalizacoesEntesofitosMarginais = false;
    this.catchIDs();
  }

  clickEntesofito() {
    this.showLocalizacoesEntesofito = true;
    this.showLocalizacoesEntesofitosMarginais = false;
    this.catchIDs();
  }
  clickEntesofitosMarginais() {
    this.showLocalizacoesEntesofito = false;
    this.showLocalizacoesEntesofitosMarginais = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.value);
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEntesofito.checked) {
      this.termoSelecionado.emit(this.rdEntesofito.value);
      if (this.slLocalizacaoEntesofito && this.slLocalizacaoEntesofito.value) {
        const objeto = { elemento: this.slLocalizacaoEntesofito};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdEntesofitosMarginais.checked) {
      this.termoSelecionado.emit(this.rdEntesofitosMarginais.value);
      if (this.slLocalizacaoEntesofitosMarginais && this.slLocalizacaoEntesofitosMarginais.value) {
        const objeto = { elemento: this.slLocalizacaoEntesofitosMarginais};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
