import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-articulacao-acromio',
  templateUrl: './articulacao-acromio.component.html',
  styleUrls: ['./articulacao-acromio.component.css']
})
export class ArticulacaoAcromioComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEspessamento') rdEspessamento: MatRadioButton;
  @ViewChild('rdArtrose') rdArtrose: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEspessamento.checked) {
      this.termoSelecionado.emit(this.rdEspessamento.value);
    }

    if (this.rdArtrose.checked) {
      this.termoSelecionado.emit(this.rdArtrose.value);
    }

    this.listaCompleta.emit();
  }
}
