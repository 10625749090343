import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { take } from 'rxjs-compat/operator/take';
import { PagamentoService } from '../services/pagamento.service';
import { ListaPlanosService } from '../services/lista-planos.service';
import { ListPayment } from '../models/BO/ListPayment';

@Component({
  selector: 'app-pagamentos-realizados',
  templateUrl: './pagamentos-realizados.component.html',
  styleUrls: ['./pagamentos-realizados.component.css', '../app.component.css']
})
export class PagamentosRealizadosComponent implements OnInit {

  displayedColumns: any[] = ['plano', 'valor', 'status', 'dataDoPagamento'];
  dataSource: MatTableDataSource<any>;
  title: string;
  description: string;
  createdOn: string;
  watcher: Subscription;
  mostrarPagamento: boolean;
  dataControl_inicial: FormControl;
  dataControl_final: FormControl;
  dateNow: Date = new Date();
  formBusca: FormGroup;
  pgtos: any[] = [];
  pgtosOr: any[] = [];
  pagamentoView: any[] = [];
  payments: boolean;
  hashPayment: boolean;
  public loading: boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
    private _service: PagamentoService,
    private toastr: ToastrManager,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private _planoService: ListaPlanosService) { }

  ngOnInit() {
    this.carregarPgtoRealizados();
    this.carregaPagamentosDoUsuario();
    this.loading = false;
  }

  carregarPgtoRealizados() {
    this.dataControl_inicial = this.fb.control({ value: null, disabled: true });
    this.dataControl_final = this.fb.control({ value: null, disabled: true });
    this.formBusca = this.fb.group({
      DataInicial: this.dataControl_inicial,
      DataFinal: this.dataControl_final
    });

    //const userID = localStorage.getItem('userID');
    this._service.enviarPgto()
      .subscribe((pg: any) => {
        if (pg) {
          this.dataSource = new MatTableDataSource(pg);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paginator._intl.itemsPerPageLabel = 'Registros por página';
        }
      },
        erro => {
          console.log(erro);
        }
      );


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  abrirPgto(pgto: any) {
    this._service.emitPgto(pgto);
    this.goto('pagRealizados/pagDetalhes');
  }

  goto(url: string) {
    this.router.navigateByUrl(url);
  }

  carregaPagamentosDoUsuario() {
    this._service
      .pagamentosDoUsuario(parseInt(JSON.parse(localStorage.getItem('userID'))))
      .subscribe((pagamentos: any) => {
        if(pagamentos.length < 0) {
          this.payments = false;
        }

        this.payments = true;

        for(let i = 0; i < pagamentos.length; i++) {
          pagamentos[i].amount = pagamentos[i].amount / 100;
        }

        this.dataSource = new MatTableDataSource(pagamentos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.paginator._intl.itemsPerPageLabel = 'Registros por página';
      }, erro => {
        this.payments = false;
        console.log(erro);
      });
  }
}
