import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-coracao',
  templateUrl: './mst-coracao.component.html',
  styleUrls: ['./mst-coracao.component.css']
})
export class MstCoracaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slSitus') slSitus: MatSelect;
  @ViewChild('slCamaras') slCamaras: MatSelect;
  @ViewChild('slSepto') slSepto: MatSelect;
  @ViewChild('slVasos') slVasos: MatSelect;

  @ViewChild('inAlteradoCam') inAlteradoCam: ElementRef;
  @ViewChild('inAlteradoSep') inAlteradoSep: ElementRef;
  @ViewChild('inAlteradoVas') inAlteradoVas: ElementRef;


  showAndHideTerms: any;


  situs: ArrayDropDown[] = [
    { value: 'solitus', viewValue: 'Solitus' },
    { value: 'inversus', viewValue: 'Inversus' },

  ];

  camaras: ArrayDropDown[] = [
    { value: 'de aspecto habitual', viewValue: 'Aspecto habitual' },
    { value: 'Alterado: ', viewValue: 'Aspecto não habitual' },
    { value: 'não avaliadas', viewValue: 'Não foi possível avaliar' }
  ];

  septos: ArrayDropDown[] = [
    { value: 'íntegro', viewValue: 'Íntegro' },
    { value: 'Alterado: ', viewValue: 'Alterado' },
  ];

  vasos: ArrayDropDown[] = [
    { value: 'visibilizadas e proporcionais', viewValue: 'Visibilizadas e proporcionais' },
    { value: 'Alteradas: ', viewValue: 'Alteradas' },
  ];


  constructor() { }

  ngOnInit() {
    this.slSitus.value = this.situs[0].value;
    this.slCamaras.value = this.camaras[0].value;
    this.slSepto.value = this.septos[0].value;
    this.slVasos.value = this.vasos[0].value;

    this.showAndHideTerms = {
      sahAlteradoCam: false,
      sahAlteradoSep: false,
      sahAlteradoVas: false
    }
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;
    if (this.slSitus && this.slSitus.value) {
      const objeto = { elemento: this.slSitus, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slCamaras && this.slCamaras.value) {
      if (this.inAlteradoCam && this.slCamaras.value == 'Alterado: ')
        elemento = { id: this.slCamaras.id, value: "de aspecto não habitual. " + this.inAlteradoCam.nativeElement.value };
      else
        elemento = { id: this.slCamaras.id, value: this.slCamaras.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slSepto && this.slSepto.value) {
      if (this.inAlteradoSep && this.slSepto.value == 'Alterado: ')
        elemento = { id: this.slSepto.id, value: "alterado. " + this.inAlteradoSep.nativeElement.value };
      else
        elemento = { id: this.slSepto.id, value: this.slSepto.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slVasos && this.slVasos.value) {
      if (this.inAlteradoVas && this.slVasos.value == 'Alteradas: ')
        elemento = { id: this.slVasos.id, value: "alteradas. " + this.inAlteradoVas.nativeElement.value };
      else
        elemento = { id: this.slVasos.id, value: this.slVasos.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();

  }

  verificaAlterado() {
    if (this.slCamaras.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoCam = true;
    } else {
      this.showAndHideTerms.sahAlteradoCam = false;
    }

    if (this.slSepto.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoSep = true;
    } else {
      this.showAndHideTerms.sahAlteradoSep = false;
    }

    if (this.slVasos.value === 'Alteradas: ') {
      this.showAndHideTerms.sahAlteradoVas = true;
    } else {
      this.showAndHideTerms.sahAlteradoVas = false;
    }
  }

}
