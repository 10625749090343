import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Validacoes} from '../../../../../../utils/validacoes';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-submandibulares-direita',
  templateUrl: './submandibulares-direita.component.html',
  styleUrls: ['./submandibulares-direita.component.css']
})
export class SubmandibularesDireitaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;

  // Sialoadenite
  @ViewChild('rdSialoadenite') rdSialoadenite: MatRadioButton;
  @ViewChild('slDimensoes') slDimensoes: MatSelect;
  @ViewChild('slEcotextura') slEcotextura: MatSelect;

  // Nodulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('slComposicao') slComposicao: MatSelect;
  @ViewChild('slEcogenicidade') slEcogenicidade: MatSelect;
  @ViewChild('slContornos') slContornos: MatSelect;
  @ViewChild('inMedidaNodulo1') inMedidaNodulo1: ElementRef;
  @ViewChild('inMedidaNodulo2') inMedidaNodulo2: ElementRef;

  // Dilatacao
  @ViewChild('ckDilatacao') ckDilatacao: MatCheckbox;
  @ViewChild('rdNaoCaracterizado') rdNaoCaracterizado: MatRadioButton;
  @ViewChild('rdSialolitiase') rdSialolitiase: MatRadioButton;
  @ViewChild('inMedidaSialolitiase') inMedidaSialolitiase: ElementRef;

  dimensoes: ArrGenerico[] = [
    {value: 'normais', viewValue: 'Normais'},
    {value: 'aumentadas', viewValue: 'Aumentadas'}
  ];

  ecotexturas: ArrGenerico[] = [
    {value: 'homogênea', viewValue: 'Homogênea'},
    {value: 'difusamente heterogênea', viewValue: 'Difusamente heterogênea'}
  ];

  composicoes: ArrGenerico[] = [
    {value: 'sólido', viewValue: 'Sólido'},
    {value: 'sólido-cístico', viewValue: 'Sólido-cístico'}
  ];

  ecogenicidades: ArrGenerico[] = [
    {value: 'hipoecogênico', viewValue: 'Hipoecogênico'},
    {value: 'hiperecogênico', viewValue: 'Hiperecogênico'},
    {value: 'isoecogênico', viewValue: 'Isoecogênico'}
  ];

  contornos: ArrGenerico[] = [
    {value: 'regulares', viewValue: 'Regulares'},
    {value: 'irregulares', viewValue: 'Irregulares'},
    {value: 'indefinidos', viewValue: 'Indefinidos'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: true,
      sahSialoadenite: false,
      sahLinfonodosReacionais: false,
      sahNodulo: false,
      sahSemNodulo: true,
      sahDilatacao: false,
      sahSialolitiase: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }


  clickNormal() {
    this.showAndHideTerms.sahAusente = true;
    this.showAndHideTerms.sahSemNodulo = true;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahSialoadenite = false;
    this.catchIDs();
  }

  clickAusente() {
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahDilatacao = false;
    this.catchIDs();
  }

  clickSialoadenite() {
    this.showAndHideTerms.sahAusente = true;
    this.showAndHideTerms.sahSialoadenite = true;
    this.showAndHideTerms.sahSemNodulo = false;
    this.catchIDs();
  }


  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo == false) {
      this.showAndHideTerms.sahNodulo = true;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.catchIDs();
  }

  clickDilatacao() {
    if (this.showAndHideTerms.sahDilatacao == false) {
      this.showAndHideTerms.sahDilatacao = true;
    } else {
      this.showAndHideTerms.sahDilatacao = false;
    }
    this.catchIDs();
  }

  clickNaoCaracterizado() {
    this.showAndHideTerms.sahSialolitiase = false;
    this.catchIDs();
  }

  clickSialolitiase() {
    if (this.showAndHideTerms.sahSialolitiase == false) {
      this.showAndHideTerms.sahSialolitiase = true;
    } else {
      this.showAndHideTerms.sahSialolitiase = false;
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.ckNodulo != undefined && this.ckNodulo.checked) {
        this.termoSelecionado.emit(this.ckNodulo.id);

        if (this.slComposicao != undefined && this.slComposicao.value != undefined) {
          const objeto = {elemento: this.slComposicao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        if (this.slEcogenicidade != undefined && this.slEcogenicidade.value != undefined) {
          const objeto = {elemento: this.slEcogenicidade, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        if (this.slContornos != undefined && this.slContornos.value != undefined) {
          const objeto = {elemento: this.slContornos, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoNodulo();
      }

      this.emitDilatacao();
    }

    if (this.rdSialoadenite.checked) {
      this.termoSelecionado.emit(this.rdSialoadenite.id);

      if (this.slDimensoes != undefined && this.slDimensoes.value != undefined) {
        const objeto = {elemento: this.slDimensoes, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotextura != undefined && this.slEcotextura.value != undefined) {
        const objeto = {elemento: this.slEcotextura, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      this.emitDilatacao();
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitDilatacao() {
    if (this.ckDilatacao != undefined && this.ckDilatacao.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckDilatacao.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdNaoCaracterizado != undefined && this.rdNaoCaracterizado.checked) {
        const objeto = {id: this.rdNaoCaracterizado.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);

      } else if (this.rdSialolitiase != undefined && this.rdSialolitiase.checked) {
        const objeto = {id: this.rdSialolitiase.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);

        if (this.inMedidaSialolitiase != undefined &&
          this.inMedidaSialolitiase.nativeElement.id != undefined &&
          this.inMedidaSialolitiase.nativeElement.value != '') {

          const objeto = {elemento: this.inMedidaSialolitiase.nativeElement, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);
        }
      } else {
        //nao caracterizado
        const objeto = {id: 618, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  formataMedindoNodulo() {
    let medindoValor = '';

    if (this.inMedidaNodulo1 != undefined &&
      this.inMedidaNodulo1.nativeElement.id != undefined &&
      this.inMedidaNodulo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo1.nativeElement.value);
    }

    if (this.inMedidaNodulo2 != undefined &&
      this.inMedidaNodulo2.nativeElement.id != undefined &&
      this.inMedidaNodulo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 616, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    }
  }
}
