import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrGenerico } from '../../../testiculo-doppler/TermosComponents/varicocele/varicocele.component';

@Component({
  selector: 'app-priapismo',
  templateUrl: './priapismo.component.html',
  styleUrls: ['./priapismo.component.css']
})
export class PriapismoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAltoFluxo') rdAltoFluxo: MatRadioButton;
  @ViewChild('rdAltoSemEspectral') rdAltoSemEspectral: MatRadioButton;
  @ViewChild('rdAltoComEspectral') rdAltoComEspectral: MatRadioButton;
  @ViewChild('slAltoFluxoLateralidade') slAltoFluxoLateralidade: MatSelect;

  @ViewChild('rdBaixoFluxo') rdBaixoFluxo: MatRadioButton;
  @ViewChild('rdBaixoSemEspectral') rdBaixoSemEspectral: MatRadioButton;
  @ViewChild('rdBaixoComEspectral') rdBaixoComEspectral: MatRadioButton;
  @ViewChild('rdBaixoPadrao') rdBaixoPadrao: MatRadioButton;
  @ViewChild('rdBaixoArterias') rdBaixoArterias: MatRadioButton;
  @ViewChild('slBaixoFluxoLateralidade') slBaixoFluxoLateralidade: MatSelect;

  

  lateralidade: ArrGenerico[] = [//alterar import
    {value: 'direita', viewValue: 'Direita'},
    {value: 'esquerda', viewValue: 'Esquerda'},
  ];

  showAndHideTerms: any;
  
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlto: false,
      sahAltoSemEspectral: false,
      sahAltoComEspectral: false,
      sahBaixo: false,
      sahBaixoSemEspectral: false,
      sahBaixoComEspectral: false,
    };
  }

  clickAltoFluxo() {
    this.showAndHideTerms.sahAlto = true;
    this.showAndHideTerms.sahBaixo = false;
    this.catchIDs();
  }

  clickAltoSemEspectral() {
    this.showAndHideTerms.sahAltoSemEspectral = true;
    this.showAndHideTerms.sahAltoComEspectral = false;
    this.catchIDs();
  }

  clickAltoComEspectral() {
    this.showAndHideTerms.sahAltoComEspectral = true;
    this.showAndHideTerms.sahAltoSemEspectral = false;
    this.catchIDs();
  }

  clickBaixoFluxo() {
    this.showAndHideTerms.sahBaixo = true;
    this.showAndHideTerms.sahAlto = false;
    this.catchIDs();
  }
  
  clickBaixoSemEspectral() {
    this.showAndHideTerms.sahBaixoSemEspectral = true;
    this.showAndHideTerms.sahBaixoComEspectral = false;
    this.catchIDs();
  }

  clickBaixoComEspectral() {
    this.showAndHideTerms.sahBaixoComEspectral = true;
    this.showAndHideTerms.sahBaixoSemEspectral = false;
    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAltoFluxo && this.rdAltoFluxo.checked) {
      this.termoSelecionado.emit(this.rdAltoFluxo.id);

      if(this.rdAltoSemEspectral && this.rdAltoSemEspectral.checked){
        this.termoSelecionado.emit(this.rdAltoSemEspectral.id);
      }

      if(this.rdAltoComEspectral && this.rdAltoComEspectral.checked){
        this.termoSelecionado.emit(this.rdAltoComEspectral.id);

        if(this.slAltoFluxoLateralidade && this.slAltoFluxoLateralidade.value){
          const objeto = {elemento: this.slAltoFluxoLateralidade, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }      
    }

    if (this.rdBaixoFluxo && this.rdBaixoFluxo.checked) {
      this.termoSelecionado.emit(this.rdBaixoFluxo.id);

      if(this.rdBaixoSemEspectral && this.rdBaixoSemEspectral.checked){
        this.termoSelecionado.emit(this.rdBaixoSemEspectral.id);
      }

      if(this.rdBaixoComEspectral && this.rdBaixoComEspectral.checked){
        this.termoSelecionado.emit(this.rdBaixoComEspectral.id);

        if(this.rdBaixoPadrao && this.rdBaixoPadrao.checked){
          this.termoSelecionado.emit(this.rdBaixoPadrao.id);
        }

        if(this.rdBaixoArterias && this.rdBaixoArterias.checked){
          this.termoSelecionado.emit(this.rdBaixoArterias.id);
        }
      }   
       
      if(this.slBaixoFluxoLateralidade && this.slBaixoFluxoLateralidade.value){
        const objeto = {elemento: this.slBaixoFluxoLateralidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }    
    }


    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

}
