import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-pele-tecido-ombro',
  templateUrl: './pele-tecido-ombro.component.html',
  styleUrls: ['./pele-tecido-ombro.component.css']
})
export class PeleTecidoOmbroComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickNormal() {
    this.catchIDs();
  }

  clickEdema() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.value);
    }

    this.listaCompleta.emit();
  }
}
