import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-pele-mamilos',
  templateUrl: './pele-mamilos.component.html',
  styleUrls: ['./pele-mamilos.component.css']
})
export class PeleMamilosComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdRetraCut') rdRetraCut: MatRadioButton;
  @ViewChild('rdEspesPele') rdEspesPele: MatRadioButton;
  @ViewChild('rdRetraCutEspesPele') rdRetraCutEspesPele: MatRadioButton;

  @ViewChild('slMama') slMama: MatSelect;

  mamas: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];
  
  showAndHideTerms: any;
  slId: string;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMama: false
    };
  }
  clickrdNormal() {
    this.showAndHideTerms.sahMama = false;
    this.catchIDs();
  }

  clickDefault() {
    if (this.rdRetraCut.checked) {
      this.slId = '4041';
    } else if (this.rdEspesPele.checked) {
      this.slId = '4043';
    } else if (this.rdRetraCutEspesPele.checked) {
      this.slId = '4045';
    }
    this.showAndHideTerms.sahMama = true;
    if (this.slMama) {
      this.slMama.value = '';
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdRetraCut && this.rdRetraCut.checked) {
      this.termoSelecionado.emit(this.rdRetraCut.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdEspesPele && this.rdEspesPele.checked) {
      this.termoSelecionado.emit(this.rdEspesPele.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdRetraCutEspesPele && this.rdRetraCutEspesPele.checked) {
      this.termoSelecionado.emit(this.rdRetraCutEspesPele.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
