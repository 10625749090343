import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatDialog, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
@Component({
  selector: 'app-arterias-uterinas',
  templateUrl: './arterias-uterinas.component.html',
  styleUrls: ['./arterias-uterinas.component.css']
})

export class ArteriasUterinasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('inIPUTD') inIPUTD: ElementRef;
  @ViewChild('slMorfoEsquerda') slMorfoEsquerda: MatSelect;
  @ViewChild('ckSemDir') ckSemDir: MatCheckbox;

  @ViewChild('inIPUTE') inIPUTE: ElementRef;
  @ViewChild('slMorfoDireita') slMorfoDireita: MatSelect;
  @ViewChild('ckSemEsq') ckSemEsq: MatCheckbox;

  @ViewChild('inIpMedio') inIpMedio: ElementRef;

  showSelect = false;
  showAndHideTerms: any;
  ipMedio = 'IP Médio';

  morfologiasOnda: ArrayDropDown[] = [
    { value: 'com incisura protodiastólica', viewValue: 'Com incisura protodiastólica' },
    { value: 'sem incisura protodiastólica', viewValue: 'Sem incisura protodiastólica' }
  ];
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahSemDir: false,
      sahSemEsq: false,
    }
  }

  clickSemDir() {
    if (this.showAndHideTerms.sahSemDir == false) {
      this.showAndHideTerms.sahSemDir = true;
    } else {
      this.showAndHideTerms.sahSemDir = false;
      this.slMorfoDireita.value = '';
    }     
  }

  clickSemEsq() {
    if (this.showAndHideTerms.sahSemEsq == false) {
      this.showAndHideTerms.sahSemEsq = true;
    } else {
      this.showAndHideTerms.sahSemEsq = false;
      this.slMorfoEsquerda.value = '';
    } 
  }

  clickSemAvaliacao(){
    this.showSelect = false;
    this.catchIDs();
  }
  clickComAvaliacao(){
    this.showSelect = true;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  blurParametros(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.slMorfoDireita && this.slMorfoEsquerda){
      const objID = { id: 4761, value: 4761}
      const objeto = { elemento: objID, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto)
    }
    else{ 
      const objID = { id: 4760, value: 4761}
      const objeto = { elemento: objID, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto)
    }

    if (this.inIPUTD && this.inIPUTD.nativeElement.value) {
      const objeto = { elemento: this.inIPUTD.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    if (this.inIPUTE && this.inIPUTE.nativeElement.value) {
      const objeto = { elemento: this.inIPUTE.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    if (this.slMorfoEsquerda && this.slMorfoEsquerda.value) {
      let objeto = { elemento: this.slMorfoEsquerda, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.slMorfoDireita && this.slMorfoDireita.value){
      let objeto = { elemento: this.slMorfoDireita, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);     
    }
    this.calculaIpMedio();

    this.listaCompleta.emit();
  } 

  calculaIpMedio() {
    let resultado = 0;
    // ipMedio
    resultado = parseInt(this.inIPUTD.nativeElement.value);
    resultado = resultado + parseInt(this.inIPUTE.nativeElement.value);
    resultado = resultado / 2;
    this.ipMedio = resultado.toFixed(1).toString();
    
    if (this.inIpMedio && this.inIpMedio.nativeElement.value) {
      const objeto = { elemento: this.inIpMedio.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const objetoElemento2 = { id: this.inIpMedio.nativeElement.id, value: this.ipMedio };
      const objeto2 = { elemento: objetoElemento2, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto2);
    }
  }
}
