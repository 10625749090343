import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-calo-osseo',
  templateUrl: './calo-osseo.component.html',
  styleUrls: ['./calo-osseo.component.css']
})
export class CaloOsseoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckCaloDir') ckCaloDir: MatCheckbox;
  @ViewChild('ckCaloEsq') ckCaloEsq: MatCheckbox;
  @ViewChild('slCaloDir') slCaloDir: MatSelect;
  @ViewChild('slCaloEsq') slCaloEsq: MatSelect;

  calos: ArrayDropDown[] = [
    { value: '1ª', viewValue: '1ª' },
    { value: '2ª', viewValue: '2ª' },
    { value: '3ª', viewValue: '3ª' },
    { value: '4ª', viewValue: '4ª' },
    { value: '5ª', viewValue: '5ª' },
    { value: '6ª', viewValue: '6ª' },
    { value: '7ª', viewValue: '7ª' },
    { value: '8ª', viewValue: '8ª' },
    { value: '9ª', viewValue: '9ª' },
    { value: '10ª', viewValue: '10ª' },
    { value: '11ª', viewValue: '11ª' },
    { value: '12ª', viewValue: '12ª' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahCaloDir: false,
      sahCaloEsq: false
    };
  }

  changeSelect() {
    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahCaloDir = false;
    this.showAndHideTerms.sahCaloEsq = false;

    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;

    this.catchIDs();
  }

  clickCaloDir() {
    if (this.ckCaloDir && this.ckCaloDir.checked) {
      this.showAndHideTerms.sahCaloDir = true;
    } else {
      this.showAndHideTerms.sahCaloDir = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickCaloEsq() {
    if (this.ckCaloEsq && this.ckCaloEsq.checked) {
      this.showAndHideTerms.sahCaloEsq = true;
    } else {
      this.showAndHideTerms.sahCaloEsq = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {
      if (this.ckCaloDir && this.ckCaloDir.checked) {
        this.termoSelecionado.emit(this.ckCaloDir.id);

        if (this.slCaloDir && this.slCaloDir.value) {
          const objeto = { elemento: this.slCaloDir, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.ckCaloEsq && this.ckCaloEsq.checked) {
        this.termoSelecionado.emit(this.ckCaloEsq.id);

        if (this.slCaloEsq && this.slCaloEsq.value) {
          const objeto = { elemento: this.slCaloEsq, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }
    }


    this.listaCompleta.emit();
  }

  mudaAlterado() {
    if (
      (this.ckCaloDir && this.ckCaloDir.checked) ||
      (this.ckCaloEsq && this.ckCaloEsq.checked)
    ) {
      this.rdAlterado.checked = true;
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
      this.rdAlterado.checked = false;
    }
  }

}
