import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-tunica-albuginea',
  templateUrl: './tunica-albuginea.component.html',
  styleUrls: ['./tunica-albuginea.component.css']
})
export class TunicaAlbugineaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdPeyronie') rdPeyronie: MatRadioButton;
  @ViewChild('rdFratura') rdFratura: MatRadioButton;

  @ViewChild('rdUmPlaca') rdUmPlaca: MatRadioButton;
  @ViewChild('rdMaisPlacas') rdMaisPlacas: MatRadioButton;
  @ViewChild('slPlacas') slPlacas: MatSelect;
  @ViewChild('slLocalCorpo') slLocalCorpo: MatSelect;
  @ViewChild('slCorpoCavernoso') slCorpoCavernoso: MatSelect;
  @ViewChild('slLocalPenis') slLocalPenis: MatSelect;
  @ViewChild('inMedida') inMedida: ElementRef;


  @ViewChild('rdSemCol') rdSemCol: MatRadioButton;
  @ViewChild('rdComCol') rdComCol: MatRadioButton;
  //@ViewChild('slCorpoCavernoso') slCorpoCavernoso: MatSelect;
  @ViewChild('slFace') slFace: MatSelect;
  @ViewChild('slTercoPenis') slTercoPenis: MatSelect;
  @ViewChild('slLocalCol') slLocalCol: MatSelect;
  @ViewChild('inMedidaFratura1') inMedidaFratura1: ElementRef;
  @ViewChild('inMedidaFratura2') inMedidaFratura2: ElementRef;
  @ViewChild('inMedidaFratura3') inMedidaFratura3: ElementRef;
  @ViewChild('inMedidaCol1') inMedidaCol1: ElementRef;
  @ViewChild('inMedidaCol2') inMedidaCol2: ElementRef;
  @ViewChild('inMedidaCol3') inMedidaCol3: ElementRef;
  @ViewChild('inVolumeCol') inVolumCol: ElementRef;

  placa: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' }
  ];

  placas: ArrayDropDown[] = [
    { value: 'calcificadas', viewValue: 'Calcificadas' },
    { value: 'hipoecogênicas', viewValue: 'Hipoecogênicas' }
  ];

  localCorpos: ArrayDropDown[] = [
    { value: 'dorsal', viewValue: 'Dorsal' },
    { value: 'ventral', viewValue: 'Ventral' },
    { value: 'ventrolateral', viewValue: 'Ventrolateral' },
    { value: 'dorsolateral', viewValue: 'Dorsolateral' },    
    { value: 'dorsomedial', viewValue: 'Dorsomedial' },
    { value: 'ventromedial', viewValue: 'Ventromedial' }
  ];

  corpos: ArrayDropDown[] = [
    { value: 'direito', viewValue: 'Direito' },
    { value: 'esquerdo', viewValue: 'Esquerdo' }
  ];

  localPenis: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' }
  ];
  
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes,) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPeyronie: false,
      sahFratura: false,
      sahUma: false,
      sahMais: false,
      sahSem: false,
      sahCom: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahPeyronie = false;
    this.showAndHideTerms.sahFratura = false;
    this.showAndHideTerms.sahUma = false;
    this.showAndHideTerms.sahMais = false;
    this.showAndHideTerms.sahSem = false;
    this.showAndHideTerms.sahCom = false;
    this.catchIDs();
  }

  clickPeyronie() {
    this.showAndHideTerms.sahPeyronie = true;
    this.showAndHideTerms.sahFratura = false;
    this.catchIDs();
  }

  clickFratura() {
    this.showAndHideTerms.sahFratura = true;
    this.showAndHideTerms.sahPeyronie = false;
    this.catchIDs();
  }

  clickUmaPlaca() {
    this.showAndHideTerms.sahUma = true;
    this.showAndHideTerms.sahMais = false;
    this.catchIDs();
  }

  clickMaisPlaca() {
    this.showAndHideTerms.sahUma = false;
    this.showAndHideTerms.sahMais = true;
    this.catchIDs();
  }

  clickSemCol() {
    this.showAndHideTerms.sahSem = true;
    this.showAndHideTerms.sahCom = false;
    this.catchIDs();
  }

  clickComCol() {
    this.showAndHideTerms.sahSem = true;
    this.showAndHideTerms.sahCom = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdPeyronie.checked) {
      this.termoSelecionado.emit(this.rdPeyronie.id);

      if (this.rdUmPlaca && this.rdUmPlaca.checked) {
        this.termoSelecionado.emit(this.rdUmPlaca.id);

        if (this.slPlacas && this.slPlacas.value) {
          const objeto = { elemento: this.slPlacas, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalCorpo && this.slLocalCorpo.value) {
          const objeto = { elemento: this.slLocalCorpo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slCorpoCavernoso && this.slCorpoCavernoso.value) {
          const objeto = { elemento: this.slCorpoCavernoso, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalPenis && this.slLocalPenis.value) {
          const objeto = { elemento: this.slLocalPenis, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedida && this.inMedida.nativeElement.value) {
          const obj = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(obj);
        }

      }


      if (this.rdMaisPlacas && this.rdMaisPlacas.checked) {
        this.termoSelecionado.emit(this.rdMaisPlacas.id);

        if (this.slPlacas && this.slPlacas.value) {
          const objeto = { elemento: this.slPlacas, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalCorpo && this.slLocalCorpo.value) {
          const objeto = { elemento: this.slLocalCorpo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slCorpoCavernoso && this.slCorpoCavernoso.value) {
          const objeto = { elemento: this.slCorpoCavernoso, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLocalPenis && this.slLocalPenis.value) {
          const objeto = { elemento: this.slLocalPenis, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedida && this.inMedida.nativeElement.value) {
          const obj = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(obj);
        }

      }
    }

    if (this.rdFratura.checked) {
      this.termoSelecionado.emit(this.rdFratura.id);

      if (this.rdSemCol && this.rdSemCol.checked) {
        this.termoSelecionado.emit(this.rdSemCol.id);

        if (this.slCorpoCavernoso && this.slCorpoCavernoso.value) {
          const objeto = { elemento: this.slCorpoCavernoso, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slFace && this.slFace.value) {
          const objeto = { elemento: this.slFace, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slTercoPenis && this.slTercoPenis.value) {
          const objeto = { elemento: this.slTercoPenis, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoFratura();

      }

      if (this.rdComCol && this.rdComCol.checked) {
        this.termoSelecionado.emit(this.rdComCol.id);

        if (this.slCorpoCavernoso && this.slCorpoCavernoso.value) {
          const objeto = { elemento: this.slCorpoCavernoso, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slFace && this.slFace.value) {
          const objeto = { elemento: this.slFace, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slTercoPenis && this.slTercoPenis.value) {
          const objeto = { elemento: this.slTercoPenis, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoFratura();

        if (this.slLocalCol && this.slLocalCol.value) {
          const objeto = { elemento: this.slLocalCol, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoFraturaComCol();

      }

    }




    this.listaCompleta.emit();

  }


  formataMedindoFratura() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedidaFratura1 &&
      this.inMedidaFratura1.nativeElement.id &&
      this.inMedidaFratura1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaFratura1.nativeElement.value);
    }

    if (this.inMedidaFratura2 &&
      this.inMedidaFratura2.nativeElement.id &&
      this.inMedidaFratura2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaFratura2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaFratura2.nativeElement.value);
      }
    }

    if (this.inMedidaFratura3 &&
      this.inMedidaFratura3.nativeElement.id &&
      this.inMedidaFratura3.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaFratura3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaFratura3.nativeElement.value);
      }
    }

    if (medindoValor != '') {      
      const objetoElemento = {id: this.inMedidaFratura3.nativeElement.id, value: medindoValor};
      const objeto = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objeto);
    }
  }

  formataMedindoFraturaComCol() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inMedidaCol1 != undefined &&
      this.inMedidaCol1.nativeElement.id != undefined &&
      this.inMedidaCol1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaCol1.nativeElement.value);
      resultado = this.inMedidaCol1.nativeElement.value;
    }

    if (this.inMedidaCol2 != undefined &&
      this.inMedidaCol2.nativeElement.id != undefined &&
      this.inMedidaCol2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCol2.nativeElement.value);
        resultado = resultado * this.inMedidaCol2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCol2.nativeElement.value);
        resultado = this.inMedidaCol2.nativeElement.value;

      }
    }

    if (this.inMedidaCol3 != undefined &&
      this.inMedidaCol3.nativeElement.id != undefined &&
      this.inMedidaCol3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCol3.nativeElement.value);
        resultado = resultado * this.inMedidaCol3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCol3.nativeElement.value);
        resultado = this.inMedidaCol3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      //resultado = resultado * 0.523;

      medindoValor = medindoValor + ' cm (volume estimado de ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' cm³)';

      const objetoElemento = {id: this.inMedidaCol3.nativeElement.id, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: false, cenario: 0};
      this.termosGenericos.emit(objetoLesao);
    }
  }

}
