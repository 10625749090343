import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ventres-musculares-quadril',
  templateUrl: './ventres-musculares-quadril.component.html',
  styleUrls: ['./ventres-musculares-quadril.component.css']
})
export class VentresMuscularesQuadrilComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHipotrofia') rdHipotrofia: MatRadioButton;
  @ViewChild('rdLipossub') rdLipossub: MatRadioButton;
  @ViewChild('rdHipoLipo') rdHipoLipo: MatRadioButton;
  @ViewChild('slMusculatura') slMusculatura: MatSelect;

  musculaturas: ArrGenerico[] = [
    { value: 'glúteo mínimo', viewValue: 'Glúteo Mínimo' },
    { value: 'glúteo médio', viewValue: 'Glúteo Médio' },
    { value: 'glúteo máximo', viewValue: 'Glúteo Máximo' },
    { value: 'reto femoral', viewValue: 'Reto femoral' },
    { value: 'vasto lateral', viewValue: 'Vasto lateral' },
    { value: 'iliopsoas', viewValue: 'Iliopsoas' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMusculatura: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahMusculatura = false;
    this.catchIDs();
  }

  clickDefault() {
    this.showAndHideTerms.sahMusculatura = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdHipotrofia.checked) {
      this.termoSelecionado.emit(this.rdHipotrofia.value);
      
      if (this.slMusculatura && this.slMusculatura.value) {
        const objeto = { elemento: this.slMusculatura, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdLipossub.checked) {
      this.termoSelecionado.emit(this.rdLipossub.value);
      
      if (this.slMusculatura && this.slMusculatura.value) {
        const objeto = { elemento: this.slMusculatura, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdHipoLipo.checked) {
      this.termoSelecionado.emit(this.rdHipoLipo.value);
      
      if (this.slMusculatura && this.slMusculatura.value) {
        const objeto = { elemento: this.slMusculatura, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
