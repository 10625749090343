
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-edema-superior',
  templateUrl: './edema-superior.component.html',
  styleUrls: ['./edema-superior.component.css']
})
export class EdemaSuperiorComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;

  @ViewChild('slLaminas') slLaminas: MatSelect;
  @ViewChild('slLocFace') slLocFace: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  constructor() { }

  laminas: ArrayDropDown[] = [
    { value: 'com lâminas líquidas de permeio', viewValue: 'Com lâminas líquidas de permeio' },
    { value: 'sem lâminas líquidas de permeio', viewValue: 'Sem lâminas líquidas de permeio' }
  ];

  locFace: ArrayDropDown[] = [
    { value: 'na face anterior', viewValue: 'Face anterior' },
    { value: 'na face posterior', viewValue: 'Face posterior' },
    { value: 'na face lateral', viewValue: 'Face lateral' },
    { value: 'na face medial', viewValue: 'Face medial' },
    { value: 'difuso', viewValue: 'Difuso' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'braço', viewValue: 'Braço' },
    { value: 'antebraço', viewValue: 'Antebraço' },
    { value: 'membro superior', viewValue: 'Membro superior' }
  ];

  ngOnInit() {
  }

  showEdema = true;

  clickNormal() {
    this.showEdema = false;
    this.catchIDs();
  }
  clickEdema() {
    this.showEdema = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slLaminas && this.slLaminas.value) || (this.slLocFace && this.slLocFace.value) || (this.slLocalizacao && this.slLocalizacao.value)){
      this.rdEdema.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if(this.rdNormal.checked){
      this.termoSelecionado.emit('2562');
    }
    else{
      if ((this.slLaminas && this.slLaminas.value) &&
          (this.slLocFace && this.slLocFace.value) &&
          (this.slLocalizacao && this.slLocalizacao.value)) {

        //TODO: retirar essa linha para nao enviar o normal.
        this.termoSelecionado.emit('2562');
        let objeto = { elemento: this.slLaminas, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slLocFace, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
        objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);

      }
    }
    this.listaCompleta.emit();
  }
}

