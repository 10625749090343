import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatButtonToggleModule,
         MatCardModule,
         MatFormFieldModule,
         MatInputModule,
         MatIconModule,
         MatListModule,
         MatButtonModule,
         MatSelectModule,
         MatProgressSpinnerModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagamentosComponent } from './pagamentos.component';
import {CardModule} from 'ngx-card/ngx-card';



@NgModule({
  imports: [
    CommonModule,
    FilterPipeModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    CardModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule

  ],
  exports: [PagamentosComponent],
  declarations: [PagamentosComponent]
})
export class PagamentosModule { }
