import { Component, OnInit, ElementRef, EventEmitter, ViewChild, Output } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatSelect, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-aoili-endoprotese',
  templateUrl: './aoili-endoprotese.component.html',
  styleUrls: ['./aoili-endoprotese.component.css']
})
export class AoiliEndoproteseComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slEstenose') slEstenose: MatSelect;

  locais: ArrayDropDown[] = [
    { value: 'na aorta suprarrenal', viewValue: 'Aorta suprarrenal' },
    { value: 'na aorta ao nível das artérias renais', viewValue: 'Aorta ao nível das artérias renais' },
    { value: 'na aorta infrarrenal', viewValue: 'Aorta infrarrenal' },
    { value: 'aorto bi-ilíaca', viewValue: 'Aorto bi-ilíaca' },
    { value: 'na ilíaca comum direita', viewValue: 'Ilíaca comum direita' },
    { value: 'na ilíaca comum esquerda', viewValue: 'Ilíaca comum esquerda' }
  ];

  estenoses: ArrayDropDown[] = [
    { value: 'sem sinais de estenose hemodinamicamente significativa', viewValue: 'Sem sinais' },
    { value: 'com sinais de reestenose (> 50%)', viewValue: 'Com Sinais' },
    { value: 'ocluída', viewValue: 'Ocluída' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  ClickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  ClickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEstenose && this.slEstenose.value) {
        const objeto = { elemento: this.slEstenose, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.slLocalizacao && this.slLocalizacao.value) ||
    (this.slEstenose && this.slEstenose.value)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }
  }

}
