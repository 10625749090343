import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-parametros-biometricos-dialog',
  templateUrl: './parametros-biometricos-dialog.component.html',
  styleUrls: ['./parametros-biometricos-dialog.component.css']
})
export class ParametrosBiometricosDialogComponent {
  displayedColumns =
    ['semanas', 'p3', 'p10', 'p50', 'p90', 'p97'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<ParametrosBiometricosDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface ParamRefElement {
  semanas: string;
  p3: any;
  p10: any;
  p50: any;
  p90: any;
  p97: any;
}

const ELEMENT_DATA: ParamRefElement[] = [
  {semanas: '', p3: 'p 3', p10: 'p 10', p50: 'p 50', p90: 'p 90', p97: 'p 97'},
  {semanas:'10', p3:26, p10:29, p50:35, p90:41,p97:44},
  {semanas:'11', p3:34, p10:37, p50:45, p90:53,p97:56},
  {semanas:'12', p3:43, p10:48, p50:58, p90:68,p97:73},
  {semanas:'13', p3:55, p10:61, p50:73, p90:85,p97:91},
  {semanas:'14', p3:70, p10:77, p50:93, p90:109,p97:116},
  {semanas:'15', p3:88, p10:97, p50:117, p90:137,p97:146},
  {semanas:'16', p3:110, p10:121, p50:146, p90:171,p97:183},
  {semanas:'17', p3:136, p10:150, p50:181, p90:212,p97:226},
  {semanas:'18', p3:167, p10:185, p50:223, p90:261,p97:279},
  {semanas:'19', p3:205, p10:227, p50:273, p90:319,p97:341},
  {semanas:'20', p3:248, p10:275, p50:331, p90:387,p97:414},
  {semanas:'21', p3:299, p10:331, p50:399, p90:467,p97:499},
  {semanas:'22', p3:359, p10:398, p50:478, p90:559,p97:598},
  {semanas:'23', p3:426, p10:471, p50:568, p90:665,p97:710},
  {semanas:'24', p3:503, p10:556, p50:670, p90:784,p97:838},
  {semanas:'25', p3:589, p10:652, p50:785, p90:918,p97:981},
  {semanas:'26', p3:685, p10:758, p50:913, p90:1068,p97:1141},
  {semanas:'27', p3:791, p10:879, p50:1055, p90:1234,p97:1319},
  {semanas:'28', p3:908, p10:1004, p50:1210, p90:1416,p97:1513},
  {semanas:'29', p3:1034, p10:1145, p50:1379, p90:1613,p97:1754},
  {semanas:'30', p3:1169, p10:1294, p50:1559, p90:1824,p97:1949},
  {semanas:'31', p3:1313, p10:1453, p50:1751, p90:2049,p97:2189},
  {semanas:'32', p3:1465, p10:1621, p50:1953, p90:2285,p97:2441},
  {semanas:'33', p3:1622, p10:1794, p50:2162, p90:2530,p97:2703},
  {semanas:'34', p3:1783, p10:1973, p50:2377, p90:2781,p97:2971},
  {semanas:'35', p3:1946, p10:2154, p50:2595, p90:3036,p97:3244},
  {semanas:'36', p3:2110, p10:2335, p50:2813, p90:3291,p97:3516},
  {semanas:'37', p3:2271, p10:2513, p50:3028, p90:3543,p97:3785},
  {semanas:'38', p3:2427, p10:2686, p50:3236, p90:3786,p97:4045},
  {semanas:'39', p3:2576, p10:2851, p50:3435, p90:4019,p97:4294},
  {semanas:'40', p3:2714, p10:3004, p50:3619, p90:4234,p97:4524}
];
