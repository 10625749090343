import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-derrame-articular',
  templateUrl: './derrame-articular.component.html',
  styleUrls: ['./derrame-articular.component.css']
})
export class DerrameArticularComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;

  volumes: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' },
  ];


  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahVolume: true
    };
  }

  clickAusente(){
    this.catchIDs();
    this.showAndHideTerms.sahVolume = false;
  }
  clickPresente(){
    this.catchIDs();
    this.showAndHideTerms.sahVolume = true;
  }
  
  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slVolume && this.slVolume.value)){
      this.rdPresente.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked)
      this.termoSelecionado.emit(this.rdAusente.value);

    if (this.rdPresente.checked){
      this.termoSelecionado.emit(this.rdPresente.value);

      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    

    this.listaCompleta.emit();
  }

}
