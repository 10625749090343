import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-distorcao-arquitetural',
  templateUrl: './distorcao-arquitetural.component.html',
  styleUrls: ['./distorcao-arquitetural.component.css']
})
export class DistorcaoArquiteturalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdEmSitCir') rdEmSitCir: MatRadioButton;
  @ViewChild('rdForaSitCir') rdForaSitCir: MatRadioButton;
  @ViewChild('rdReaArq') rdReaArq: MatRadioButton;

  @ViewChild('slMama') slMama: MatSelect;
  @ViewChild('slLocal') slLocal: MatSelect;

  mamas: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'na região retroareolar', viewValue: 'Região retroareolar' },
    { value: 'no quadrante superolateral', viewValue: 'Quadrante superolateral' },
    { value: 'no quadrante inferolateral', viewValue: 'Quadrante inferolateral' },
    { value: 'no quadrante superomedial', viewValue: 'Quadrante superomedial' },
    { value: 'no quadrante inferomedial', viewValue: 'Quadrante inferomedial' },
    { value: 'junção dos quadrantes superiores', viewValue: 'Junção dos quadrantes superiores' },
    { value: 'junção dos quadrantes inferiores', viewValue: 'Junção dos quadrantes inferiores' },
    { value: 'junção dos quadrantes laterais', viewValue: 'Junção dos quadrantes laterais' },
    { value: 'junção dos quadrantes mediais', viewValue: 'Junção dos quadrantes mediais' }
  ];

  showAndHideTerms: any;
  slMamaId: string;
  slLocalId: string;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: false,
      sahRea: false
    };
  }

  clickrdAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickDefault() {
    if (this.rdEmSitCir.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahRea = true;
      this.slMamaId = '4069';
      this.slLocalId = '4070';
    } else if (this.rdForaSitCir.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahRea = true;
      this.slMamaId = '4072';
      this.slLocalId = '4073';
    } else if (this.rdReaArq.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahRea = false;
      this.slMamaId = '4075';
    }

    if (this.slMama)
      this.slMama.value = '';
    if (this.slLocal)
      this.slLocal.value = '';
    
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdEmSitCir && this.rdEmSitCir.checked) {
      this.termoSelecionado.emit(this.rdEmSitCir.id);
      this.termoSelecionado.emit('4067');

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLocal && this.slLocal.value) {
        const objeto = { elemento: this.slLocal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdForaSitCir && this.rdForaSitCir.checked) {
      this.termoSelecionado.emit(this.rdForaSitCir.id);
      this.termoSelecionado.emit('4067');

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLocal && this.slLocal.value) {
        const objeto = { elemento: this.slLocal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdReaArq && this.rdReaArq.checked) {
      this.termoSelecionado.emit(this.rdReaArq.id);
      this.termoSelecionado.emit('4067');

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
