import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-edema-superior',
  templateUrl: './edema-superior.component.html',
  styleUrls: ['./edema-superior.component.css']
})
export class EdemaSuperiorComponent implements OnInit {


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;

  @ViewChild('slLaminas') slLaminas: MatSelect;
  @ViewChild('slLocFace') slLocFace: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  constructor() { }

  laminas: ArrayDropDown[] = [
    { value: 'com lâminas líquidas de permeio', viewValue: 'Com lâminas líquidas de permeio' },
    { value: 'sem lâminas líquidas de permeio', viewValue: 'Sem lâminas líquidas de permeio' }
  ];

  locFace: ArrayDropDown[] = [
    { value: 'na face anterior', viewValue: 'Face anterior' },
    { value: 'na face posterior', viewValue: 'Face posterior' },
    { value: 'na face lateral', viewValue: 'Face lateral' },
    { value: 'na face medial', viewValue: 'Face medial' },
    { value: 'difusa', viewValue: 'Difusa' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'braço', viewValue: 'Braço' },
    { value: 'antebraço', viewValue: 'Antebraço' }
  ];

  showAndHideTerms: any;

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: true,
    }
  }

  clickNormal() {
    this.showAndHideTerms.sahEdema = false;
    this.catchIDs();
  }
  
  clickEdema() {
    this.showAndHideTerms.sahEdema = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraAusentaPresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdEdema && this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.id);

      if (this.slLaminas && this.slLaminas.value) {
        let objeto = { elemento: this.slLaminas, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocFace && this.slLocFace.value) {
        let objeto = { elemento: this.slLocFace, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacao && this.slLocalizacao.value) {
        let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();
  }

  alteraAusentaPresente() {
    if ((this.slLaminas && this.slLaminas.value) ||
        (this.slLocFace && this.slLocFace.value) ||
        (this.slLocalizacao && this.slLocalizacao.value)
    ) {
      this.rdEdema.checked = true;
    } else {
      this.rdNormal.checked = true;
      this.rdEdema.checked = false;
    }
  }
}

