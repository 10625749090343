import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notificacao } from '../models/BO/Notificacao';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  buscarNotificacao(): Observable<Notificacao[]> {
    return this.http.get<Notificacao[]>(environment.nLaudoApi + "Notification/Notification/");
  }

  getNotificacao(): Observable<Notificacao> {
    return this.http.get<Notificacao>(environment.nLaudoApi + "Notification/Notification/");
  }
  
}
