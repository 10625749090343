import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LateralidadeModule } from '../../../../utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from '../../../../utils/laudo-footer/laudo-footer.module';
import { VenosoMembroModule } from '../venoso-membro/venoso-membro.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { PenisDopplerComponent } from './penis-doppler.component';
import { PriapismoComponent } from './TermosComponents/priapismo/priapismo.component';
import { PenisComponent } from '../../modo-b/penis/penis.component';
import { PenisModule } from '../../modo-b/penis/penis.module';
import { ParedeAbdominalModule } from '../../modo-b/parede-abdominal/parede-abdominal.module';
import { ArteriasCavernosasComponent } from './TermosComponents/arterias-cavernosas/arterias-cavernosas.component';



@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LateralidadeModule,
    LaudoFooterModule,
    VenosoMembroModule,
    SharedModule,
    MatSlideToggleModule,
    TextEditorJSmodule,
    PenisModule,
    ParedeAbdominalModule
  ],
  exports: [
    PenisDopplerComponent,
  ],
  declarations: [
    PenisDopplerComponent,
    PriapismoComponent,
    ArteriasCavernosasComponent,
  ],
  
})
export class PenisDopplerModule { }
