import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-cranio',
  templateUrl: './mst-cranio.component.html',
  styleUrls: ['./mst-cranio.component.css']
})
export class MstCranioComponent implements OnInit {
  @ViewChild('cranioAlt') cranioAlt: MatSelect;
  @ViewChild('estruturalinhas') estruturalinhas: MatSelect;
  @ViewChild('cortexAlt') cortexAlt: MatSelect;
  @ViewChild('ventriCerebrais') ventriCerebrais: MatSelect;
  @ViewChild('cerebeloPos') cerebeloPos: MatSelect;
  @ViewChild('nucaAlt') nucaAlt: MatSelect;

  @ViewChild('cranioAlterado') cranioAlterado: ElementRef;
  @ViewChild('estruturalinhasAlterado') estruturalinhasAlterado: ElementRef;
  @ViewChild('cortexAlterado') cortexAlterado: ElementRef;
  @ViewChild('ventriCerebraisAlterado') ventriCerebraisAlterado: ElementRef;
  @ViewChild('cerebeloPosAlterado') cerebeloPosAlterado: ElementRef;
  @ViewChild('nucaAlterado') nucaAlterado: ElementRef;
  
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  showAndHideTerms: any;

  cranio: ArrayDropDown[] = [
    { value: 'forma normal, contorno regular', viewValue: 'Forma normal, contorno regular' },
    { value: 'alteradas: ', viewValue: 'Alteradas' },
  ];

  estruturalinhasMedia: ArrayDropDown[] = [
    { value: 'presentes e normais', viewValue: 'Presentes e normais' },
    { value: 'alteradas: ', viewValue: 'Alteradas' },
  ];

  cortex: ArrayDropDown[] = [
    { value: 'de aspecto habitual', viewValue: 'De aspecto habitual' },
    { value: 'alteradas: ', viewValue: 'Alteradas' },
  ];

  ventriculosCerebrais: ArrayDropDown[] = [
    { value: 'sem dilatações', viewValue: 'Sem dilatações' },
    { value: 'Presença de ventriculomegalia', viewValue: 'Dilatados' },
    { value: 'outras alterações: ', viewValue: 'Outras alterações' },
  ];

  cerebeloPosterior: ArrayDropDown[] = [
    { value: 'de forma habitual', viewValue: 'De forma habitual' },
    { value: 'outras alterações: ', viewValue: 'Outras alterações' },
  ];

  nuca: ArrayDropDown[] = [
    { value: 'sem edemas ou cistos', viewValue: 'Sem edemas ou cistos' },
    { value: 'outras alterações: ', viewValue: 'Outras alterações' },
  ];
  
  constructor() { }

  ngOnInit() {

    this.cranioAlt.value = this.cranio[0].value;
    this.estruturalinhas.value = this.estruturalinhasMedia[0].value;
    this.cortexAlt.value = this.cortex[0].value;
    this.ventriCerebrais.value = this.ventriculosCerebrais[0].value;
    this.cerebeloPos.value = this.cerebeloPosterior[0].value;
    this.nucaAlt.value = this.nuca[0].value;

    this.showAndHideTerms = {
      sahCranioAlt: false,
      sahEstruturalinhasAlt: false,
      sahCortexAltAlt: false,
      sahVentriCerebraisAlt: false,
      sahCerebeloPosteriorAlt: false,
      sahNucaAlt: false,
    };
  }

  blur() {
    this.catchIDs();
  }

  verificaAlterado() {
    if (this.cranioAlt.value === 'alteradas: ') {
      this.showAndHideTerms.sahCranioAlt = true;
    } else {
      this.showAndHideTerms.sahCranioAlt = false;
    }

    if (this.estruturalinhas.value === 'alteradas: ') {
      this.showAndHideTerms.sahEstruturalinhasAlt = true;
    } else {
      this.showAndHideTerms.sahEstruturalinhasAlt = false;
    }

    if (this.cortexAlt.value === 'alteradas: ') {
      this.showAndHideTerms.sahCortexAltAlt = true;
    } else {
      this.showAndHideTerms.sahCortexAltAlt = false;
    }

    if (this.ventriCerebrais.value === 'outras alterações: ') {
      this.showAndHideTerms.sahVentriCerebraisAlt = true;
    } else {
      this.showAndHideTerms.sahVentriCerebraisAlt = false;
    } 

    if (this.cerebeloPos.value === 'outras alterações: ') {
      this.showAndHideTerms.sahCerebeloPosteriorAlt = true;
    } else {
      this.showAndHideTerms.sahCerebeloPosteriorAlt = false;
    }

    if (this.nucaAlt.value === 'outras alterações: ') {
      this.showAndHideTerms.sahNucaAlt = true;
    } else {
      this.showAndHideTerms.sahNucaAlt = false;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;

    if (this.cranio && this.cranioAlt.value) {

      if (this.cranio && this.cranioAlt.value === 'alteradas: ')
        elemento = { id: this.cranioAlt.id, value: this.cranioAlterado.nativeElement.value };
      else
        elemento = { id: this.cranioAlt.id, value: this.cranioAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.estruturalinhas && this.estruturalinhas.value) {

      if (this.estruturalinhasAlterado && this.estruturalinhas.value === 'alteradas: ')
        elemento = { id: this.estruturalinhas.id, value: this.estruturalinhasAlterado.nativeElement.value };
      else
        elemento = { id: this.estruturalinhas.id, value: this.estruturalinhas.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.cortexAlt && this.cortexAlt.value) {
      
      if (this.cortexAlterado && this.cortexAlt.value === 'alteradas: ')
        elemento = { id: this.cortexAlt.id, value: this.cortexAlterado.nativeElement.value };
      else
        elemento = { id: this.cortexAlt.id, value: this.cortexAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.ventriCerebrais && this.ventriCerebrais.value) {
      
      if (this.ventriCerebraisAlterado && this.ventriCerebrais.value === 'outras alterações: ')
        elemento = { id: this.ventriCerebrais.id, value: this.ventriCerebraisAlterado.nativeElement.value };
      else
        elemento = { id: this.ventriCerebrais.id, value: this.ventriCerebrais.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.cerebeloPos && this.cerebeloPos.value) {
      
      if (this.cerebeloPosAlterado && this.cerebeloPos.value === 'outras alterações: ')
        elemento = { id: this.cerebeloPos.id, value: this.cerebeloPosAlterado.nativeElement.value };
      else
        elemento = { id: this.cerebeloPos.id, value: this.cerebeloPos.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.nucaAlt && this.nucaAlt.value) {
      
      if (this.nucaAlterado && this.nucaAlt.value === 'outras alterações: ')
        elemento = { id: this.nucaAlt.id, value: this.nucaAlterado.nativeElement.value };
      else
        elemento = { id: this.nucaAlt.id, value: this.nucaAlt.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  }
}
