import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-arteria-femoral-superficial',
  templateUrl: './arteria-femoral-superficial.component.html',
  styleUrls: ['./arteria-femoral-superficial.component.css']
})
export class ArteriaFemoralSuperficialComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdFemoralPlacaComMedida') rdFemoralPlacaComMedida: MatRadioButton;
  @ViewChild('rdFemoralMultiplasPlacasEstenosantes') rdFemoralMultiplasPlacasEstenosantes: MatRadioButton;
  @ViewChild('rdFemoralTromboseComMedida') rdFemoralTromboseComMedida: MatRadioButton;
  @ViewChild('rdFemoralEndoproteseStent') rdFemoralEndoproteseStent: MatRadioButton;
  @ViewChild('rdFemoralAneurismaSemtrombose') rdFemoralAneurismaSemtrombose: MatRadioButton;
  @ViewChild('slmotivo') slmotivo: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slecotextura') slecotextura: MatSelect;
  @ViewChild('slecogenicidade') slecogenicidade: MatSelect;
  @ViewChild('slsuperficie') slsuperficie: MatSelect;
  @ViewChild('Inespessura') Inespessura: ElementRef;
  @ViewChild('slestenose') slestenose: MatSelect;
  @ViewChild('slfluxodistal') slfluxodistal: MatSelect;
  @ViewChild('Inextensao') Inextensao: ElementRef;
  @ViewChild('Incalibre') Incalibre: ElementRef;
  @ViewChild('sltrombo') sltrombo: MatSelect;
  @ViewChild('sllocalizacaos') sllocalizacaos: MatSelect;


  public motivo: ArrayDropDown[] = [
    { value: 'edema', viewValue: 'Edema' },
    { value: 'atenuação do feixe acústico', viewValue: 'Atenuação do feixe acústico' },
  ];

  public localizacao: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço Proximal' },
    { value: 'no terço médio', viewValue: 'Terço Médio' },
    { value: 'no terço distal', viewValue: 'Terço Distal' },
  ];

  public ecogenicidade: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'mista', viewValue: 'Mista' },
    { value: 'iso/ hiperecogênica', viewValue: 'Iso/ Hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' },
  ];


  public ecotextura: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' },
  ];

  public superficie: ArrayDropDown[] = [
    { value: 'regular', viewValue: 'Regular' },
    { value: 'irregular', viewValue: 'Irregular' },
    { value: 'ulcerada', viewValue: 'Ulcerada' },
  ];


  public estenose: ArrayDropDown[] = [
    {
      value: 'sem determinar estenose hemodinamicamente significativa (< 50%)',
      viewValue: 'Sem determinar estenose hemodinamicamente significativa (< 50%)'
    },
    {
      value: 'determinando estenose hemodinamicamente significativa (≥ 50%)',
      viewValue: 'Determinando estenose hemodinamicamente significativa (≥ 50%)'
    },
    { value: 'determinando suboclusão', viewValue: 'Determinando Suboclusão' },
  ];

  public fluxodistal: ArrayDropDown[] = [
    { value: 'bifásico', viewValue: 'Bifásico' },
    { value: 'monofásico', viewValue: 'Monofásico' },
    { value: 'tardus-parvus', viewValue: 'Tardus-parvus' },
  ];

  public localizacaos: ArrayDropDown[] = [
    { value: 'em toda sua extensão', viewValue: 'Toda Sua Extensão' },
    { value: 'no terço proximal', viewValue: 'Terço Proximal' },
    { value: 'no terço médio', viewValue: 'Terço Médio' },
    { value: 'no terço distal', viewValue: 'Terço Distal' },
  ];


  public trombo: ArrayDropDown[] = [
    { value: 'trombo mural', viewValue: 'Trombo Mural' },
    { value: 'trombose', viewValue: 'Trombose' },
  ];

  public unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahnaoCaracterizada: false,
      sahFemoralPlacaComMedida: false,
      sahFemoralMultiplasPlacasEstenosantes: false,
      sahFemoralTromboseComMedida: false,
      sahFemoralEndoproteseStenta: false,
      sahFemoralAneurismaSemtrombose: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clicknormal(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahFemoralPlacaComMedida = false;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahFemoralTromboseComMedida = false;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = false;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clicknaocaracterizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = true;
    this.showAndHideTerms.sahFemoralPlacaComMedida = false;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahFemoralTromboseComMedida = false;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = false;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickFemoralPlacaComMedida(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahFemoralPlacaComMedida = true;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahFemoralTromboseComMedida = false;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = false;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = false;
    this.catchIDs();
  }


  public clickFemoralMultiplasPlacasEstenosantes(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahFemoralPlacaComMedida = false;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = true;
    this.showAndHideTerms.sahFemoralTromboseComMedida = false;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = false;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickFemoralTromboseComMedida(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahFemoralPlacaComMedida = false;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahFemoralTromboseComMedida = true;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = false;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickFemoralEndoproteseStenta(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahFemoralPlacaComMedida = false;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahFemoralTromboseComMedida = false;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = true;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickFemoralAneurismaSemtrombose(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahFemoralPlacaComMedida = false;
    this.showAndHideTerms.sahFemoralMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahFemoralTromboseComMedida = false;
    this.showAndHideTerms.sahFemoralEndoproteseStenta = false;
    this.showAndHideTerms.sahFemoralAneurismaSemtrombose = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }


  public blurMedindo(): any {
    this.catchIDs();
  }


  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    // Não Caracterizada
    if (this.rdnaoCaracterizada && this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);

      if (this.slmotivo && this.slmotivo.value) {
        const objeto = { elemento: this.slmotivo };
        this.termosGenericos.emit(objeto);
      }
    }

    // Placa Com Medida
    if (this.rdFemoralPlacaComMedida && this.rdFemoralPlacaComMedida.checked) {
      this.termoSelecionado.emit(this.rdFemoralPlacaComMedida.id);


      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

      if (this.slecogenicidade && this.slecogenicidade.value) {
        const objeto = { elemento: this.slecogenicidade };
        this.termosGenericos.emit(objeto);
      }

      if (this.slecotextura && this.slecotextura.value) {
        const objeto = { elemento: this.slecotextura };
        this.termosGenericos.emit(objeto);
      }

      if (this.slsuperficie && this.slsuperficie.value) {
        const objeto = { elemento: this.slsuperficie };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inespessura && this.Inespessura.nativeElement.value) {
        const objeto = { elemento: this.Inespessura.nativeElement };
        this.termosGenericos.emit(objeto);
      }

      if (this.slestenose && this.slestenose.value) {
        const objeto = { elemento: this.slestenose };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inextensao && this.Inextensao.nativeElement.value) {
        const objeto = { elemento: this.Inextensao.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    // Femoral Multiplas Placas Estenosantes
    if (this.rdFemoralMultiplasPlacasEstenosantes && this.rdFemoralMultiplasPlacasEstenosantes.checked) {
      this.termoSelecionado.emit(this.rdFemoralMultiplasPlacasEstenosantes.id);

      if (this.slfluxodistal && this.slfluxodistal.value) {
        const objeto = { elemento: this.slfluxodistal };
        this.termosGenericos.emit(objeto);
      }
    }

    // Femoral Trombose Com Medida
    if (this.rdFemoralTromboseComMedida && this.rdFemoralTromboseComMedida.checked) {
      this.termoSelecionado.emit(this.rdFemoralTromboseComMedida.id);

      if (this.sllocalizacaos && this.sllocalizacaos.value) {
        const objeto = { elemento: this.sllocalizacaos };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inextensao && this.Inextensao.nativeElement.value) {
        const objeto = { elemento: this.Inextensao.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    // Femoral Endoprotese Stent
    if (this.rdFemoralEndoproteseStent && this.rdFemoralEndoproteseStent.checked) {
      this.termoSelecionado.emit(this.rdFemoralEndoproteseStent.id);

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

      if (this.slestenose && this.slestenose.value) {
        const objeto = { elemento: this.slestenose };
        this.termosGenericos.emit(objeto);
      }
    }

    // Femoral Aneurisma Sem Trombose
    if (this.rdFemoralAneurismaSemtrombose && this.rdFemoralAneurismaSemtrombose.checked) {
      this.termoSelecionado.emit(this.rdFemoralAneurismaSemtrombose.id);

      if (this.sltrombo && this.sltrombo.value) {
        const objeto = { elemento: this.sltrombo };
        this.termosGenericos.emit(objeto);
      }

      if (this.Incalibre && this.Incalibre.nativeElement.value) {
        const objeto = { elemento: this.Incalibre.nativeElement };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacaos && this.sllocalizacaos.value) {
        const objeto = { elemento: this.sllocalizacaos };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();

  }

}
