import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlteraLaudoComponent } from './altera-laudo.component';
import { MenuLateralModule } from '../menu-lateral/menu-lateral.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatOptionModule, MatSelectModule, MatToolbarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextEditorModule } from '../utils/textEditor/text-editor/text-editor.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    MenuLateralModule,
    MatSidenavModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatListModule,
    MatToolbarModule,
    MatOptionModule,
    TextEditorModule,
  ],
  declarations: [
    AlteraLaudoComponent
   ],
  exports: [
    AlteraLaudoComponent
  ],
  providers: [
  ]
})
export class AlteraLaudoModule { }
