import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Validacoes } from '../../../../../utils/validacoes';

@Component({
  selector: 'app-linfonodo-torax',
  templateUrl: './linfonodo-torax.component.html',
  styleUrls: ['./linfonodo-torax.component.css']
})
export class LinfonodoToraxComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckLinfonodoCalcificado') ckLinfonodoCalcificado: MatCheckbox;
  @ViewChild('rd1LinfonodoCalcificado') rd1LinfonodoCalcificado: MatRadioButton;
  @ViewChild('rd2LinfonodoCalcificado') rd2LinfonodoCalcificado: MatRadioButton;
  @ViewChild('slCadeiaLinfonodoCalcificado') slCadeiaLinfonodoCalcificado: MatSelect;


  @ViewChild('ckLinfonodoProeminente') ckLinfonodoProeminente: MatCheckbox;
  @ViewChild('rd1LinfonodoProeminente') rd1LinfonodoProeminente: MatRadioButton;
  @ViewChild('rd2LinfonodoProeminente') rd2LinfonodoProeminente: MatRadioButton;
  @ViewChild('rd3LinfonodoProeminente') rd3LinfonodoProeminente: MatRadioButton;
  @ViewChild('slCadeiaLinfonodoProeminente') slCadeiaLinfonodoProeminente: MatSelect;

  @ViewChild('ckLinfonodomegalia') ckLinfonodomegalia: MatCheckbox;
  @ViewChild('rd1Linfonodomegalia') rd1Linfonodomegalia: MatRadioButton;
  @ViewChild('slCadeiaLinfonodomegalia1') slCadeiaLinfonodomegalia1: MatSelect;
  @ViewChild('inMedidaUm1') inMedidaUm1: ElementRef;
  @ViewChild('inMedidaUm2') inMedidaUm2: ElementRef;

  @ViewChild('rd2Linfonodomegalia') rd2Linfonodomegalia: MatRadioButton;

  @ViewChild('rdNLinfonodomegalia') rdNLinfonodomegalia: MatRadioButton;
  @ViewChild('slCadeiaNLinfonodomegalia') slCadeiaNLinfonodomegalia: MatSelect;
  @ViewChild('slMaiorCadeia') slMaiorCadeia: MatSelect;
  @ViewChild('inMedidaMult1') inMedidaMult1: ElementRef;
  @ViewChild('inMedidaMult2') inMedidaMult2: ElementRef;

  @ViewChildren('linfoChildren') linfoChildren: QueryList<any>;

  listaCadeiasCalcificados: ArrayDropDown[] = [
    { value: 'paratraqueal superior à direita', viewValue: 'Paratraqueal superior à direita' },
    { value: 'paratraqueal superior à esquerda', viewValue: 'Paratraqueal superior à esquerda' },
    { value: 'paratraqueal inferior à direita', viewValue: 'Paratraqueal inferior à direita' },
    { value: 'paratraqueal inferior à esquerda', viewValue: 'Paratraqueal inferior à esquerda' },
    { value: 'pré-vascular', viewValue: 'Pré-vascular' },
    { value: 'pré-vertebral', viewValue: 'Pré-vertebral' },
    { value: 'subaórtico', viewValue: 'Subaórtico' },
    { value: 'para-aórtico', viewValue: 'Para-aórtico' },
    { value: 'subcarinal', viewValue: 'Subcarinal' },
    { value: 'para-esofágico', viewValue: 'Para-esofágico' },
    { value: 'do ligamento pulmonar', viewValue: 'Ligamento pulmonar' },
    { value: 'peri-hilar direita', viewValue: 'Peri-hilar direita' },
    { value: 'peri-hilar esquerda', viewValue: 'Peri-hilar esquerda' },
  ];

  listaCadeiasProeminente: ArrayDropDown[] = [
    { value: 'cervical baixa', viewValue: 'Cervical baixa' },
    { value: 'supraclavicular à direita', viewValue: 'Supraclavicular à direita' },
    { value: 'supraclavicular à esquerda', viewValue: 'Supraclavicular à esquerda' },
    { value: 'paratraqueal superior à direita', viewValue: 'Paratraqueal superior à direita' },
    { value: 'paratraqueal superior à esquerda', viewValue: 'Paratraqueal superior à esquerda' },
    { value: 'paratraqueal inferior à direita', viewValue: 'Paratraqueal inferior à direita' },
    { value: 'paratraqueal inferior à esquerda', viewValue: 'Paratraqueal inferior à esquerda' },
    { value: 'pré-vascular', viewValue: 'Pré-vascular' },
    { value: 'pré-vertebral', viewValue: 'Pré-vertebral' },
    { value: 'subaórtico', viewValue: 'Subaórtico' },
    { value: 'para-aórtico', viewValue: 'Para-aórtico' },
    { value: 'subcarinal', viewValue: 'Subcarinal' },
    { value: 'para-esofágico', viewValue: 'Para-esofágico' },
    { value: 'do ligamento pulmonar', viewValue: 'Ligamento pulmonar' },
    { value: 'peri-hilar direita', viewValue: 'Peri-hilar direita' },
    { value: 'peri-hilar esquerda', viewValue: 'Peri-hilar esquerda' }
  ];

  showAndHideTerms: any;
  localizaCadeia: string;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  obj: any;
  objetofilho: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahLinfonodoCalcificado: false,
      sahLinfonodoProeminente: false,
      sahLinfonodomegalia: false,
      sahSubitem1LinfonodoCalcificado: false,
      sahSubitem2LinfonodoCalcificado: false,
      sahSubitem1LinfonodoProeminente: false,
      sahSubitem2LinfonodoProeminente: false,
      sahSubitem1Linfonodomegalia: false,
      sahSubitem2Linfonodomegalia: false,
      sahSubitemNLinfonodomegalia: false,
    };

    this.lesoesForm = this._fb.group({
      linfo: this._fb.array([this.addLinfoGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Dinamico
  addLinfoGrup() {
    return this._fb.group({
      cadeias: [],
      medida: [],
    });
  }

  addLinfo() {
    this.linfoArray.push(this.addLinfoGrup());
    this.catchIDs();
  }

  removeLinfo(index) {
    this.linfoArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get linfoArray() {
    return <FormArray>this.lesoesForm.get('linfo');
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahLinfonodoCalcificado = false;
    this.showAndHideTerms.sahLinfonodoProeminente = false;
    this.showAndHideTerms.sahLinfonodomegalia = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahLinfonodoCalcificado = false;
    this.showAndHideTerms.sahLinfonodoProeminente = false;
    this.showAndHideTerms.sahLinfonodomegalia = false;
    this.catchIDs();
  }

  clickLinfonodoCalcificado() {
    if (this.showAndHideTerms.sahLinfonodoCalcificado === false) {
      this.showAndHideTerms.sahLinfonodoCalcificado = true;
      this.showAndHideTerms.sahSubitem1LinfonodoCalcificado = true;
      this.showAndHideTerms.sahSubitem2LinfonodoCalcificado = false;
    } else {
      this.showAndHideTerms.sahLinfonodoCalcificado = false;
      this.showAndHideTerms.sahSubitem1LinfonodoCalcificado = false;
      this.showAndHideTerms.sahSubitem2LinfonodoCalcificado = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  click1LinfonodoCalcificado() {
    this.showAndHideTerms.sahSubitem1LinfonodoCalcificado = true;
    this.showAndHideTerms.sahSubitem2LinfonodoCalcificado = false;
  }
  click2LinfonodoCalcificado() {
    this.showAndHideTerms.sahSubitem1LinfonodoCalcificado = false;
    this.showAndHideTerms.sahSubitem2LinfonodoCalcificado = true;
  }

  clickLinfonodoProeminente() {
    if (this.showAndHideTerms.sahLinfonodoProeminente === false) {
      this.showAndHideTerms.sahLinfonodoProeminente = true;
      this.showAndHideTerms.sahSubitem1LinfonodoProeminente = true;
      this.showAndHideTerms.sahSubitem2LinfonodoProeminente = false;
    } else {
      this.showAndHideTerms.sahLinfonodoProeminente = false;
      this.showAndHideTerms.sahSubitem1LinfonodoProeminente = false;
      this.showAndHideTerms.sahSubitem2LinfonodoProeminente = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  click1LinfonodoProeminente() {
    this.showAndHideTerms.sahSubitem1LinfonodoProeminente = true;
    this.showAndHideTerms.sahSubitem2LinfonodoProeminente = false;
  }

  click2LinfonodoProeminente() {
    this.showAndHideTerms.sahSubitem1LinfonodoProeminente = false;
    this.showAndHideTerms.sahSubitem2LinfonodoProeminente = true;
  }

  clickLinfonodoReacional() {
    this.showAndHideTerms.sahSubitem1LinfonodoProeminente = false;
    this.showAndHideTerms.sahSubitem2LinfonodoProeminente = false;
    this.catchIDs();
  }

  clickLinfonodomegalia() {
    if (this.showAndHideTerms.sahLinfonodomegalia === false) {
      this.showAndHideTerms.sahLinfonodomegalia = true;
      this.showAndHideTerms.sahSubitem1Linfonodomegalia = true;
      this.showAndHideTerms.sahSubitem2Linfonodomegalia = false;
      this.showAndHideTerms.sahSubitemNLinfonodomegalia = false;
    } else {
      this.showAndHideTerms.sahLinfonodomegalia = false;
      this.showAndHideTerms.sahSubitem1Linfonodomegalia = false;
      this.showAndHideTerms.sahSubitem2Linfonodomegalia = false;
      this.showAndHideTerms.sahSubitemNLinfonodomegalia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.ajustaArrayDinamico();
    this.verificaAlterado();
    this.catchIDs();
  }

  click1Linfonodomegalia() {
    this.showAndHideTerms.sahSubitem1Linfonodomegalia = true;
    this.showAndHideTerms.sahSubitem2Linfonodomegalia = false;
    this.showAndHideTerms.sahSubitemNLinfonodomegalia = false;
    this.ajustaArrayDinamico();
  }
  click2Linfonodomegalia() {
    this.showAndHideTerms.sahSubitem1Linfonodomegalia = false;
    this.showAndHideTerms.sahSubitem2Linfonodomegalia = true;
    this.showAndHideTerms.sahSubitemNLinfonodomegalia = false;
    this.ajustaArrayDinamico();
  }
  clickNLinfonodomegalia() {
    this.showAndHideTerms.sahSubitem1Linfonodomegalia = false;
    this.showAndHideTerms.sahSubitem2Linfonodomegalia = false;
    this.showAndHideTerms.sahSubitemNLinfonodomegalia = true;
    this.ajustaArrayDinamico();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      this.emitLinfonodomegalia();

      if (this.countLesoes > 0)
        this.emmitCalcificadoLesao();
      else
        this.emmitCalcificado();
      
        if (this.countLesoes > 0)
        this.emitProeminenteLesao();
      else
        this.emitProeminente();

      
    }

    this.listaCompleta.emit();
  }

  emmitCalcificado() {
    if (this.ckLinfonodoCalcificado && this.ckLinfonodoCalcificado.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLinfonodoCalcificado.id);

      if (this.rd1LinfonodoCalcificado && this.rd1LinfonodoCalcificado.checked) {
        this.termoSelecionado.emit(this.rd1LinfonodoCalcificado.id);
      } else if (this.rd2LinfonodoCalcificado && this.rd2LinfonodoCalcificado.checked) {
        this.termoSelecionado.emit(this.rd2LinfonodoCalcificado.id);
      }

      if (this.slCadeiaLinfonodoCalcificado && this.slCadeiaLinfonodoCalcificado.value) {
        const objeto = { elemento: this.slCadeiaLinfonodoCalcificado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emmitCalcificadoLesao() {

    if (this.ckLinfonodoCalcificado && this.ckLinfonodoCalcificado.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLinfonodoCalcificado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rd1LinfonodoCalcificado && this.rd1LinfonodoCalcificado.checked) {
        const objeto = { id: this.rd1LinfonodoCalcificado.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      } else if (this.rd2LinfonodoCalcificado && this.rd2LinfonodoCalcificado.checked) {
        const objeto = { id: this.rd2LinfonodoCalcificado.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.slCadeiaLinfonodoCalcificado && this.slCadeiaLinfonodoCalcificado.value) {
        const objeto = { elemento: this.slCadeiaLinfonodoCalcificado, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitProeminente() {
    if (this.ckLinfonodoProeminente && this.ckLinfonodoProeminente.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLinfonodoProeminente.id);

      if (this.rd1LinfonodoProeminente && this.rd1LinfonodoProeminente.checked) {
        this.termoSelecionado.emit(this.rd1LinfonodoProeminente.id);

      } else if (this.rd2LinfonodoProeminente && this.rd2LinfonodoProeminente.checked) {
        this.termoSelecionado.emit(this.rd2LinfonodoProeminente.id);

      } else if (this.rd3LinfonodoProeminente && this.rd3LinfonodoProeminente.checked) {
        this.termoSelecionado.emit(this.rd3LinfonodoProeminente.id);
      }

      if (this.slCadeiaLinfonodoProeminente && this.slCadeiaLinfonodoProeminente.value) {
        const objeto = { elemento: this.slCadeiaLinfonodoProeminente, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitProeminenteLesao() {
    if (this.ckLinfonodoProeminente && this.ckLinfonodoProeminente.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLinfonodoProeminente.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rd1LinfonodoProeminente && this.rd1LinfonodoProeminente.checked) {
        const objeto = { id: this.rd1LinfonodoProeminente.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

      } else if (this.rd2LinfonodoProeminente && this.rd2LinfonodoProeminente.checked) {
        const objeto = { id: this.rd2LinfonodoProeminente.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

      } else if (this.rd3LinfonodoProeminente && this.rd3LinfonodoProeminente.checked) {
        const objeto = { id: this.rd3LinfonodoProeminente.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.slCadeiaLinfonodoProeminente && this.slCadeiaLinfonodoProeminente.value) {
        const objeto = { elemento: this.slCadeiaLinfonodoProeminente, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitLinfonodomegalia() {
    if (this.ckLinfonodomegalia && this.ckLinfonodomegalia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLinfonodomegalia.id);

      if (this.rd1Linfonodomegalia && this.rd1Linfonodomegalia.checked) {
        this.termoSelecionado.emit(this.rd1Linfonodomegalia.id);

        if (this.slCadeiaLinfonodomegalia1 && this.slCadeiaLinfonodomegalia1.value) {
          const objeto = { elemento: this.slCadeiaLinfonodomegalia1, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindo1Linfo(true);

      } else if (this.rd2Linfonodomegalia && this.rd2Linfonodomegalia.checked) {
        this.termoSelecionado.emit(this.rd2Linfonodomegalia.id);
        let posicao = 0;
        this.linha = 0;

        this.linfoChildren.forEach(obj => {
          if (obj.nativeElement) {
            if (posicao < 2) {
              posicao++;
            } else {
              posicao = 1;
            }

            this.objetofilho = { id: 1594, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1592) this.linha++;

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          }
        });
      } else if (this.rdNLinfonodomegalia && this.rdNLinfonodomegalia.checked) {
        this.termoSelecionado.emit(this.rdNLinfonodomegalia.id);

        if (this.slCadeiaNLinfonodomegalia && this.slCadeiaNLinfonodomegalia.value) {
          const objeto = { elemento: this.slCadeiaNLinfonodomegalia, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slMaiorCadeia && this.slMaiorCadeia.value) {
          const objeto = { elemento: this.slMaiorCadeia, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoMultLinfo(true);

      }
    }
  }

  emitLinfonodomegaliaLesao() {
    if (this.ckLinfonodomegalia && this.ckLinfonodomegalia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLinfonodomegalia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rd1Linfonodomegalia && this.rd1Linfonodomegalia.checked) {
        const objeto = { id: this.rd1Linfonodomegalia.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slCadeiaLinfonodomegalia1 && this.slCadeiaLinfonodomegalia1.value) {
          const objeto = { elemento: this.slCadeiaLinfonodomegalia1, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindo1Linfo(false);

      } else if (this.rd2Linfonodomegalia && this.rd2Linfonodomegalia.checked) {
        const objeto = { id: this.rd2Linfonodomegalia.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
        let posicao = 0;
        this.linha = 0;

        this.linfoChildren.forEach(obj => {
          if (obj.nativeElement) {
            if (posicao < 2) {
              posicao++;
            } else {
              posicao = 1;
            }

            this.objetofilho = { id: 1594, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1592) this.linha++;

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        });

      } else if (this.rdNLinfonodomegalia && this.rdNLinfonodomegalia.checked) {
        const objeto = { elemento: this.rdNLinfonodomegalia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        if (this.slCadeiaNLinfonodomegalia && this.slCadeiaNLinfonodomegalia.value) {
          const objeto = { elemento: this.slCadeiaNLinfonodomegalia, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slMaiorCadeia && this.slMaiorCadeia.value) {
          const objeto = { elemento: this.slMaiorCadeia, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoMultLinfo(false);
      }
    }
  }

  formataMedindo1Linfo(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaUm1 && this.inMedidaUm1.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaUm1.nativeElement.value);


    if (this.inMedidaUm2 && this.inMedidaUm2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUm2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUm2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 1593, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoMultLinfo(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaMult1 && this.inMedidaMult1.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaMult1.nativeElement.value);


    if (this.inMedidaMult2 && this.inMedidaMult2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaMult2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaMult2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 1598, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {
    const arrayTotal = this.linfoArray.length;
    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.linfoArray.length - 1
      if (index != 0) {
        this.linfoArray.removeAt(index);
      }
    }
    this.linfoArray.reset();
  }

  verificaAlterado() {
    if (!this.ckLinfonodoCalcificado.checked &&
      !this.ckLinfonodoProeminente.checked &&
      !this.ckLinfonodomegalia.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
