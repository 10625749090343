import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Manual } from '../models/BO/Manual';
import { Step } from '../models/BO/Manual';

@Injectable({
  providedIn: 'root'
})
export class ManualService {

  constructor(private http: HttpClient) { }

  getManual(): Observable<Manual>{
    return this.http.get<Manual>(environment.nLaudoApi + "Manual/BuscaManual/");
  }

  getStep(stepId): Observable<Step>{
    return this.http.get<Step>(environment.nLaudoApi + "ManualStep/BuscaManualStepPorManual/" + stepId );
  }

}
