import { Component, OnInit, ViewChild, Output, EventEmitter, } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';



@Component({
  selector: 'app-pele-tecido-subcutaneo',
  templateUrl: './pele-tecido-subcutaneo.component.html',
  styleUrls: ['./pele-tecido-subcutaneo.component.css']
})
export class PeleTecidoSubcutaneoComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();



  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;

  public Localizacao: ArrayDropDown[] = [
    { value: 'dorsal', viewValue: 'Dorsal' },
    { value: 'palmar', viewValue: 'Palmar' },
    { value: 'medial', viewValue: 'Medial' },
    { value: 'lateral', viewValue: 'Lateral' },
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: true
    };

  }
  public clickNormal(): any {
    this.showAndHideTerms.sahEdema = false;
    this.catchIDs();
  }
  public clickEdema(): any {
    this.showAndHideTerms.sahEdema = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.sllocalizacao && this.sllocalizacao.value)){
      this.rdEdema.checked = true;
    }
  }
  public changeDefault(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');


    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }
    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.value);

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();

  }
}
