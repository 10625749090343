import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendoes-extensores',
  templateUrl: './tendoes-extensores.component.html',
  styleUrls: ['./tendoes-extensores.component.css']
})
export class TendoesExtensoresComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTenossinovite') rdTenossinovite: MatRadioButton;
  @ViewChild('rdTendiTenossi') rdTendiTenossi: MatRadioButton;
  @ViewChild('slCompartimento') slCompartimento: MatSelect;

  compartimentos: ArrayDropDown[] = [
    { value: '1°', viewValue: '1°' },
    { value: '2°', viewValue: '2°' },
    { value: '3°', viewValue: '3°' },
    { value: '4°', viewValue: '4°' },
    { value: '5°', viewValue: '5°' },
    { value: '6°', viewValue: '6°' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCompartimento: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahCompartimento = false;
    this.catchIDs();
  }

  clickDefault() {
    this.showAndHideTerms.sahCompartimento = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      
      if (this.slCompartimento && this.slCompartimento.value) {
        const objeto = { elemento: this.slCompartimento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTenossinovite.checked) {
      this.termoSelecionado.emit(this.rdTenossinovite.value);
      
      if (this.slCompartimento && this.slCompartimento.value) {
        const objeto = { elemento: this.slCompartimento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTendiTenossi.checked) {
      this.termoSelecionado.emit(this.rdTendiTenossi.value);
      
      if (this.slCompartimento && this.slCompartimento.value) {
        const objeto = { elemento: this.slCompartimento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
