import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-esofago',
  templateUrl: './esofago.component.html',
  styleUrls: ['./esofago.component.css']
})
export class EsofagoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckEsofagoEctasia') ckEsofagoEctasia: MatCheckbox;
  @ViewChild('ckEctasiaConteudo') ckEctasiaConteudo: MatCheckbox;
  @ViewChild('slLocalizacaoEctasia') slLocalizacaoEctasia: MatSelect;

  @ViewChild('ckEsofagoHernia') ckEsofagoHernia: MatCheckbox;
  @ViewChild('slGrauHernia') slGrauHernia: MatSelect;

  listaGrau: ArrayDropDown[] = [
    { value: 'Pequena', viewValue: 'Pequena' },
    { value: 'Moderada', viewValue: 'Moderada' },
    { value: 'Acentuada', viewValue: 'Acentuada' }
  ];

  listaLocalizacao: ArrayDropDown[] = [
    { value: 'difusa', viewValue: 'Difusa' },
    { value: 'distal', viewValue: 'Distal' },
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahEctasia: false,
      sahHernia: false,
    };
  }

  clickDefault() {
    this.showAndHideTerms.sahAlterado = false
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahHernia = false;

    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahHernia = false;

    this.catchIDs();
  }

  clickEctasia() {
    if (this.showAndHideTerms.sahEctasia === false)
      this.showAndHideTerms.sahEctasia = true;
    else
      this.showAndHideTerms.sahEctasia = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }
  clickHernia() {
    if (this.showAndHideTerms.sahHernia === false)
      this.showAndHideTerms.sahHernia = true;
    else
      this.showAndHideTerms.sahHernia = false;
    
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  clickCk() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      this.emitEctasia();

      if (this.countLesoes > 0)
        this.emitHerniaLesao();
      else
        this.emitHernia();

    }

    this.listaCompleta.emit();
  }

  emitEctasia() {
    if (this.ckEsofagoEctasia && this.ckEsofagoEctasia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEsofagoEctasia.id);

      if (this.slLocalizacaoEctasia && this.slLocalizacaoEctasia.value) {
        const objeto = { elemento: this.slLocalizacaoEctasia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckEctasiaConteudo && this.ckEctasiaConteudo.checked) {
        this.termoSelecionado.emit(this.ckEctasiaConteudo.id);
      }
    }
  }

  emitHernia() {
    if (this.ckEsofagoHernia && this.ckEsofagoHernia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEsofagoHernia.id);

      if (this.slGrauHernia && this.slGrauHernia.value) {
        const objeto = { elemento: this.slGrauHernia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHerniaLesao() {
    if (this.ckEsofagoHernia && this.ckEsofagoHernia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEsofagoHernia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slGrauHernia && this.slGrauHernia.value) {
        const objeto = { elemento: this.slGrauHernia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckEsofagoEctasia.checked &&
      !this.ckEsofagoHernia.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
