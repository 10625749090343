import { ElementCarotida } from '../Helper/ElementCarotida'

export class LesoesCarotidas {
  id: number;
  value: string;
  blockID: number;
  position: number;
  element: Array<ElementCarotida>
}

