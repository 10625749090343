import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatInput, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mediatismo',
  templateUrl: './mediatismo.component.html',
  styleUrls: ['./mediatismo.component.css']
})
export class MediatismoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckAreaCardiaca') ckAreaCardiaca: MatCheckbox;
  @ViewChild('ckIndiceCardiotoracico') ckIndiceCardiotoracico: MatCheckbox;
  @ViewChild('inIndice') inIndice: ElementRef;
  @ViewChild('ckMarcapasso') ckMarcapasso: MatCheckbox;
  @ViewChild('ckPneumomediastino') ckPneumomediastino: MatCheckbox;
  @ViewChild('ckAorta') ckAorta: MatCheckbox;
  @ViewChild('slAchado') slAchado: MatSelect;
  @ViewChild('ckHernia') ckHernia: MatCheckbox;
  @ViewChild('ckProeminencia') ckProeminencia: MatCheckbox; 

  achado: ArrayDropDown[] = [
    { value: 'alongada', viewValue: 'Alongada' },
    { value: 'ectasiada', viewValue: 'Ectasiada' },
    { value: 'tortuosa', viewValue: 'Tortuosa' },
    { value: 'com calcificações', viewValue: 'Com calcificações' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahIndiceCardio: false,
      sahAorta: false,
    }
  }

  clickDefault(){
    this.catchIDs();
  } 

  changeSelect(){
    this.catchIDs();
  }

  blurMedindo(){
    this.catchIDs();
  }

  clickIndiceCardio(){
    this.showAndHideTerms.sahIndiceCardio = !this.showAndHideTerms.sahIndiceCardio;
    this.catchIDs();
  }

  clickAorta(){
    this.showAndHideTerms.sahAorta = !this.showAndHideTerms.sahAorta;
    this.catchIDs();
  }

  checkPresente(){
    if(this.ckAreaCardiaca && !this.ckAreaCardiaca.checked &&
      this.ckIndiceCardiotoracico && !this.ckIndiceCardiotoracico.checked &&
      this.ckMarcapasso && !this.ckMarcapasso.checked &&
      this.ckPneumomediastino && !this.ckPneumomediastino.checked &&
      this.ckAorta && !this.ckAorta.checked &&
      this.ckHernia && !this.ckHernia.checked &&
      this.ckProeminencia && !this.ckProeminencia.checked){
        return true;
    }
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitAreaCardiaca();

    //Indice Cardiotorácico
    if(this.countLesoes > 0){
      this.emitIndiceCardioLesao();
    } else {
      this.emitIndiceCardioSimples();
    }

    //Marcapasso
    if(this.countLesoes > 0){
      this.emitMarcapassoLesao();
    } else {
      this.emitMarcapassoSimples();
    }

    //Pneumomediastino
    if(this.countLesoes > 0){
      this.emitPneumomediastinoLesao();
    } else {
      this.emitPneumomediastinoSimples();
    }

    //Aorta
    if(this.countLesoes > 0){
      this.emitAortaLesao();
    } else {
      this.emitAortaSimples();
    }

    //Hernia
    if(this.countLesoes > 0){
      this.emitHerniaLesao();
    } else {
      this.emitHerniaSimples();
    }

    //Proeminencia
    if(this.countLesoes > 0){
      this.emitProeminenciaLesao();
    } else {
      this.emitProeminenciaSimples();
    }
    
    if(this.checkPresente()){
      this.termoSelecionado.emit(4904);
    }

    this.listaCompleta.emit();
  }

  emitAreaCardiaca(){
    if(this.ckAreaCardiaca.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAreaCardiaca.id);
    }
  }

  emitIndiceCardioLesao(){
    if(this.ckIndiceCardiotoracico.checked){
      this.countLesoes++;
      const objeto = { id: this.ckIndiceCardiotoracico.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.inIndice && this.inIndice.nativeElement.value){
        const objeto = {elemento: this.inIndice.nativeElement, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitIndiceCardioSimples(){
    if(this.ckIndiceCardiotoracico.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckIndiceCardiotoracico.id);
      if(this.inIndice && this.inIndice.nativeElement.value){
        const objeto = {elemento: this.inIndice.nativeElement, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitMarcapassoLesao(){
    if(this.ckMarcapasso.checked){
      this.countLesoes++;
      const objeto = { id: this.ckMarcapasso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitMarcapassoSimples(){
    if(this.ckMarcapasso.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMarcapasso.id);
    }
  }

  emitPneumomediastinoLesao(){
    if(this.ckPneumomediastino.checked){
      this.countLesoes++;
      const objeto = { id: this.ckPneumomediastino.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitPneumomediastinoSimples(){
    if(this.ckPneumomediastino.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckPneumomediastino.id);
    }
  }

  emitAortaLesao(){
    if(this.ckAorta.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAorta.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slAchado && this.slAchado.value){
        const objeto = {elemento: this.slAchado, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAortaSimples(){
    if(this.ckAorta.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAorta.id);
      if(this.slAchado && this.slAchado.value){
        const objeto = {elemento: this.slAchado, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHerniaLesao(){
    if(this.ckHernia.checked){
      this.countLesoes++;
      const objeto = { id: this.ckHernia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitHerniaSimples(){
    if(this.ckHernia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHernia.id);
    }
  }

  emitProeminenciaLesao(){
    if(this.ckProeminencia.checked){
      this.countLesoes++;
      const objeto = { id: this.ckProeminencia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitProeminenciaSimples(){
    if(this.ckProeminencia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckProeminencia.id);
    }
  }

}
