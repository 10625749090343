import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-alcas-intestinais',
  templateUrl: './alcas-intestinais.component.html',
  styleUrls: ['./alcas-intestinais.component.css']
})
export class AlcasIntestinaisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckDistensaoAlcas') ckDistensaoAlcas: MatCheckbox;
  @ViewChild('ckAcumuloFecal') ckAcumuloFecal: MatCheckbox;
  @ViewChild('ckNivelHidro') ckNivelHidro: MatCheckbox;

  @ViewChild('slLocalizacaoAcumuloFecal') slLocalizacaoAcumuloFecal : MatSelect;

  showAndHideTerms;
  countLesoes = 0;
  ausente=true;

  localizacaoAcumulo: ArrayDropDown[]=[
    { value: ' do cólon direito', viewValue: 'Cólon direito' },
    { value: ' do cólon esquerdo', viewValue: 'Cólon esquerdo' },
    { value: ' do cólon transverso', viewValue: 'Cólon transverso' },
    { value: ' da ampola retal', viewValue: 'Ampola retal' },
  ]
  constructor() { }

  ngOnInit() {
      this.showAndHideTerms = {
        acumuloFecal:false,
        distensaoAlcas:false
      }
    }

  clickDefault(){
    this.checkAlterado();
    this.catchIDs();
  }

  clickNormal(){
    this.showAndHideTerms.acumuloFecal=false;
    this.showAndHideTerms.distensaoAlcas=false;

    this.ckAcumuloFecal.checked=false;
    this.ckDistensaoAlcas.checked=false;
    this.ckNivelHidro.checked=false;

    this.checkAlterado();
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickAcumuloFecal(){
    this.showAndHideTerms.acumuloFecal= !this.showAndHideTerms.acumuloFecal;
    this.checkAlterado();
    this.catchIDs();
  }

  clickDistensaoAlcas(){
    this.showAndHideTerms.distensaoAlcas= !this.showAndHideTerms.distensaoAlcas;
    this.checkAlterado();
    this.catchIDs();
  }

  checkAlterado(){
    if(this.ckAcumuloFecal.checked || this.ckDistensaoAlcas.checked){
      this.rdAlterado.checked= true;
      this.rdNormal.checked=false;
    }else{
      this.rdNormal.checked=true;
      this.rdAlterado.checked=false;
    }
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if(this.countLesoes > 0){
      this.emitDistensaoLesao();
    } else {
      this.emitDistensaoSimples();
    }

    if(this.countLesoes > 0){
      this.emitAcumuloLesao();
    } else {
      this.emitAcumuloSimples();
    }
    this.listaCompleta.emit();
  }

  emitDistensaoSimples() {
    if(this.ckDistensaoAlcas.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDistensaoAlcas.id);

      if(this.ckNivelHidro  && this.ckNivelHidro.value){
        if(this.ckNivelHidro.checked){
          const objeto = {elemento: this.ckNivelHidro, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitDistensaoLesao() {
    if(this.ckDistensaoAlcas.checked){
      this.countLesoes++;
      const objeto = { id: this.ckDistensaoAlcas.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.ckNivelHidro && this.ckNivelHidro.value){
        if(this.ckNivelHidro.checked){
          const objeto = {elemento: this.ckNivelHidro, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }

  }

  emitAcumuloLesao() {
    if(this.ckAcumuloFecal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAcumuloFecal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLocalizacaoAcumuloFecal && this.slLocalizacaoAcumuloFecal.value){
        const objeto = {elemento: this.slLocalizacaoAcumuloFecal, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAcumuloSimples() {
    if(this.ckAcumuloFecal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAcumuloFecal.id);

      if(this.slLocalizacaoAcumuloFecal && this.slLocalizacaoAcumuloFecal.value){
        const objeto = {elemento: this.slLocalizacaoAcumuloFecal, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  replaceVirgulaMultSelect(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }

}
