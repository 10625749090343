import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-metodo',
  templateUrl: './metodo.component.html',
  styleUrls: ['./metodo.component.css']
})
export class MetodoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('slTecnica') slTecnica: MatSelect;

  @ViewChild('rdSemContraste') rdSemContraste: MatRadioButton;
  @ViewChild('rdContraste') rdContraste: MatRadioButton;
  @ViewChild('slFase') slFase: MatSelect;

  tecnicas: ArrayDropDown[] = [
    { value: 'helicoidal', viewValue: 'Helicoidal' },
    { value: 'dualslice', viewValue: 'Dualslice' },
    { value: 'multislice', viewValue: 'Multislice' }
  ];

  fases: ArrayDropDown[] = [
    { value: 'pós-contraste', viewValue: 'Pós-contraste' },
    { value: 'pré e pós-contraste', viewValue: 'Pré e pós-contraste' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;
  tecnicaSelected: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      contraste: false,
    };

    this.tecnicaSelected = this.tecnicas[2].value;
  }

  clickDefault() {
    this.showAndHideTerms.contraste = false;
    this.catchIDs();
  }

  clickConstraste() {
    this.showAndHideTerms.contraste = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.slTecnica && this.slTecnica.value) {
      this.countLesoes++;
      // ID de técnica
      this.termoSelecionado.emit(1574);

      const objeto = { elemento: this.slTecnica, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      this.countLesoes++;
      // ID de técnica
      this.termoSelecionado.emit(1574);

      const elemento = { id: 1575, value: this.tecnicaSelected }
      const objeto = {elemento: elemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.rdSemContraste && this.rdSemContraste.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdSemContraste.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }


    if (this.rdContraste && this.rdContraste.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdContraste.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slFase && this.slFase.value) {
        const objeto = { elemento: this.slFase, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
