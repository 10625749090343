import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ducto-venoso-dialog',
  templateUrl: './ducto-venoso-dialog.component.html',
  styleUrls: ['./ducto-venoso-dialog.component.css']
})
export class DuctoVenosoDialogComponent {

  displayedColumns =
    ['crl', '5th', "50th", "95th"];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<DuctoVenosoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface transRefElement {
  crl: string;
  th5: string;
  th50: string;
  th95: string;
}

const ELEMENT_DATA: transRefElement[] = [
  {crl: '45', th5: '0.9', th50: '1.1', th95: '1.4'},
  {crl: '46', th5: '0.9', th50: '1.1', th95: '1.4'},
  {crl: '47', th5: '0.9', th50: '1.1', th95: '1.4'},
  {crl: '48', th5: '0.9', th50: '1.1', th95: '1.4'},
  {crl: '49', th5: '0.9', th50: '1.1', th95: '1.4'},
  {crl: '50', th5: '0.9', th50: '1.1', th95: '1.4'},
  {crl: '51', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '52', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '53', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '54', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '55', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '56', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '57', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '58', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '59', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '60', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '61', th5: '0.8', th50: '1.1', th95: '1.4'},
  {crl: '62', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '63', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '64', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '65', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '66', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '67', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '68', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '69', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '70', th5: '0.8', th50: '1.1', th95: '1.3'},
  {crl: '71', th5: '0.8', th50: '1.0', th95: '1.3'},
  {crl: '72', th5: '0.8', th50: '1.0', th95: '1.3'},
  {crl: '73', th5: '0.8', th50: '1.0', th95: '1.3'},
  {crl: '74', th5: '0.8', th50: '1.0', th95: '1.3'},
  {crl: '75', th5: '0.8', th50: '1.0', th95: '1.3'},
  {crl: '76', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '77', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '78', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '79', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '80', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '81', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '82', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '83', th5: '0.7', th50: '1.0', th95: '1.3'},
  {crl: '84', th5: '0.7', th50: '1.0', th95: '1.3'},
];
