
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-estruturas-vasculares',
  templateUrl: './estruturas-vasculares.component.html',
  styleUrls: ['./estruturas-vasculares.component.css']
})
export class EstruturasVascularesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckAteromatose') ckAteromatose: MatCheckbox;
  @ViewChild('ckAteromatoseAortica') ckAteromatoseAortica: MatCheckbox;
  @ViewChild('ckRamosSupraAorticos') ckRamosSupraAorticos: MatCheckbox;
  @ViewChild('ckAteromatoseCoronariana') ckAteromatoseCoronariana: MatCheckbox;

  @ViewChild('ckCalcificacaoValvarAortica') ckCalcificacaoValvarAortica: MatCheckbox;
  @ViewChild('slValva') slValva: MatSelect;
  @ViewChild('ckCalcificacaoValvarMitral') ckCalcificacaoValvarMitral: MatCheckbox;

  @ViewChild('ckStentCoronariano') ckStentCoronariano: MatCheckbox;

  @ViewChild('ckEctasia') ckEctasia: MatCheckbox;
  @ViewChild('inEctasiaCalibre') inEctasiaCalibre: ElementRef;

  @ViewChild('ckEctasiaAorta') ckEctasiaAorta: MatCheckbox;
  @ViewChild('inAortaCalibre') inAortaCalibre: ElementRef;
  @ViewChild('slSegmentoAorta') slSegmentoAorta: MatSelect;

  @ViewChild('ckAneurismaAorta') ckAneurismaAorta: MatCheckbox;
  @ViewChild('inAortaDimensao') inAortaDimensao: ElementRef;
  @ViewChild('inAortaExtensao') inAortaExtensao: ElementRef;
  @ViewChild('slSegmentoAortaAneu') slSegmentoAortaAneu: MatSelect;

  listaSegmentoAorta: ArrayDropDown[] = [
    { value: 'da raiz da aorta', viewValue: 'Raiz da aorta' },
    { value: 'da aorta ascendente', viewValue: 'Aorta ascendente' },
    { value: 'do arco aórtico', viewValue: 'Arco aórtico' },
    { value: 'da aorta descendente', viewValue: 'Aorta descendente' },
  ];

  listaValva: ArrayDropDown[] = [
    { value: 'aórtica', viewValue: 'Aórtica' },
    { value: 'mitral', viewValue: 'Mitral' },
    { value: 'aórtica e mitral', viewValue: 'Aórtica e mitral' },
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahAteromatose: false,
      sahCalcificacaoValvar: false,
      sahStentCoronariano: false,
      sahEctasia: false,
      sahEctasiaAorta: false,
      sahAneurismaAorta: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahAteromatose = false;
    this.showAndHideTerms.sahCalcificacaoValvar = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahEctasiaAorta = false;
    this.showAndHideTerms.sahAneurismaAorta = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahAteromatose = false;
    this.showAndHideTerms.sahCalcificacaoValvar = false;
    this.showAndHideTerms.sahEctasia = false;
    this.showAndHideTerms.sahEctasiaAorta = false;
    this.showAndHideTerms.sahAneurismaAorta = false;
    this.catchIDs();
  }

  clickAteromatose() {
    if (this.showAndHideTerms.sahAteromatose === false)
      this.showAndHideTerms.sahAteromatose = true;
    else
      this.showAndHideTerms.sahAteromatose = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCalcificacao() {
    if (this.showAndHideTerms.sahCalcificacaoValvar === false)
      this.showAndHideTerms.sahCalcificacaoValvar = true;
    else
      this.showAndHideTerms.sahCalcificacaoValvar = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEctasia() {
    if (this.showAndHideTerms.sahEctasia === false)
      this.showAndHideTerms.sahEctasia = true;
    else
      this.showAndHideTerms.sahEctasia = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEctasiaAorta() {
    if (this.showAndHideTerms.sahEctasiaAorta === false)
      this.showAndHideTerms.sahEctasiaAorta = true;
    else
      this.showAndHideTerms.sahEctasiaAorta = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickAneurismaAorta() {
    if (this.showAndHideTerms.sahAneurismaAorta === false)
      this.showAndHideTerms.sahAneurismaAorta = true;
    else
      this.showAndHideTerms.sahAneurismaAorta = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDefault() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckAteromatose && this.ckAteromatose.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckAteromatose.id);
      }

      if (this.countLesoes > 0)
        this.emitAteromatoseAorLesao();
      else
        this.emitAteromatoseAor();

      if (this.ckAteromatoseCoronariana && this.ckAteromatoseCoronariana.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckAteromatoseCoronariana.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckAteromatoseCoronariana.id);
        }
      }

      if (this.countLesoes > 0)
        this.emitCalcAorticaLesao();
      else
        this.emitCalcAortica();

      if (this.ckCalcificacaoValvarMitral && this.ckCalcificacaoValvarMitral.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckCalcificacaoValvarMitral.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckCalcificacaoValvarMitral.id);
        }
      }

      if (this.ckStentCoronariano && this.ckStentCoronariano.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckStentCoronariano.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckStentCoronariano.id);
        }
      }

      if (this.countLesoes > 0)
        this.emitEctasiaLesao();
      else
        this.emitEctasia();

      if (this.countLesoes > 0)
        this.emitEctasiaAortaLesao();
      else
        this.emitEctasiaAorta();

      if (this.countLesoes > 0)
        this.emitAneurismaAortaLesao();
      else
        this.emitAneurismaAorta();
    }

    this.listaCompleta.emit();
  }

  emitAteromatoseAor() {
    if (this.ckAteromatoseAortica && this.ckAteromatoseAortica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAteromatoseAortica.id);

      if (this.ckRamosSupraAorticos && this.ckRamosSupraAorticos.checked) {
        this.termoSelecionado.emit(this.ckRamosSupraAorticos.id);
      }
    }
  }

  emitAteromatoseAorLesao() {
    if (this.ckAteromatoseAortica && this.ckAteromatoseAortica.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAteromatoseAortica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckRamosSupraAorticos && this.ckRamosSupraAorticos.checked) {
        const objeto = { id: this.ckRamosSupraAorticos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitCalcAortica() {
    if (this.ckCalcificacaoValvarAortica && this.ckCalcificacaoValvarAortica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCalcificacaoValvarAortica.id);

      if (this.slValva && this.slValva.value) {
        const objeto = { elemento: this.slValva, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCalcAorticaLesao() {
    if (this.ckCalcificacaoValvarAortica && this.ckCalcificacaoValvarAortica.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCalcificacaoValvarAortica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slValva && this.slValva.value) {
        const objeto = { elemento: this.slValva, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEctasia() {
    if (this.ckEctasia && this.ckEctasia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEctasia.id);

      if (this.inEctasiaCalibre && this.inEctasiaCalibre.nativeElement.value) {
        const objeto = { elemento: this.inEctasiaCalibre.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEctasiaLesao() {
    if (this.ckEctasia && this.ckEctasia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEctasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inEctasiaCalibre && this.inEctasiaCalibre.nativeElement.value) {
        const objeto = { elemento: this.inEctasiaCalibre.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEctasiaAorta() {
    if (this.ckEctasiaAorta && this.ckEctasiaAorta.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEctasiaAorta.id);

      if (this.inAortaCalibre && this.inAortaCalibre.nativeElement.value) {
        const objeto = { elemento: this.inAortaCalibre.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmentoAorta && this.slSegmentoAorta.value) {
        const objeto = { elemento: this.slSegmentoAorta, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEctasiaAortaLesao() {
    if (this.ckEctasiaAorta && this.ckEctasiaAorta.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEctasiaAorta.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inAortaCalibre && this.inAortaCalibre.nativeElement.value) {
        const objeto = { elemento: this.inAortaCalibre.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmentoAorta && this.slSegmentoAorta.value) {
        const objeto = { elemento: this.slSegmentoAorta, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAneurismaAorta() {
    if (this.ckAneurismaAorta && this.ckAneurismaAorta.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAneurismaAorta.id);

      if (this.inAortaDimensao && this.inAortaDimensao.nativeElement.value) {
        const objeto = { elemento: this.inAortaDimensao.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inAortaExtensao && this.inAortaExtensao.nativeElement.value) {
        const objeto = { elemento: this.inAortaExtensao.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmentoAortaAneu && this.slSegmentoAortaAneu.value) {
        const objeto = { elemento: this.slSegmentoAortaAneu, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAneurismaAortaLesao() {
    if (this.ckAneurismaAorta && this.ckAneurismaAorta.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAneurismaAorta.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inAortaDimensao && this.inAortaDimensao.nativeElement.value) {
        const objeto = { elemento: this.inAortaDimensao.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inAortaExtensao && this.inAortaExtensao.nativeElement.value) {
        const objeto = { elemento: this.inAortaExtensao.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmentoAortaAneu && this.slSegmentoAortaAneu.value) {
        const objeto = { elemento: this.slSegmentoAortaAneu, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckAteromatoseAortica.checked &&
      !this.ckAteromatose.checked &&
      !this.ckAteromatoseCoronariana.checked &&
      !this.ckCalcificacaoValvarAortica.checked &&
      !this.ckStentCoronariano.checked &&
      !this.ckEctasia.checked &&
      !this.ckEctasiaAorta.checked &&
      !this.ckAneurismaAorta.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
