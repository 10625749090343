import { ArrayDropDown } from './../../../../../models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';

@Component({
  selector: 'app-pos-cirurgico',
  templateUrl: './pos-cirurgico.component.html',
  styleUrls: ['./pos-cirurgico.component.css']
})
export class PosCirurgicoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckArtrodese') ckArtrodese: MatCheckbox;
  @ViewChild('ckArtrodeseSoltura') ckArtrodeseSoltura: MatCheckbox;
  @ViewChild('ckArtrodeseFratura') ckArtrodeseFratura: MatCheckbox;
  @ViewChild('ckEspacadorDiscal') ckEspacadorDiscal: MatCheckbox;
  @ViewChild('ckEspacadorDiscalAncora') ckEspacadorDiscalAncora: MatCheckbox;
  @ViewChild('ckProteseDiscal') ckProteseDiscal: MatCheckbox;

  @ViewChild('slLateralidadeArtrodese') slLateralidadeArtrodese: MatSelect;
  @ViewChild('slNivelArtrodese') slNivelArtrodese: MatSelect;
  @ViewChild('slNivelEspacadorDiscal') slNivelEspacadorDiscal: MatSelect;
  @ViewChild('slNivelEspacadorDiscalAncora') slNivelEspacadorDiscalAncora: MatSelect;
  @ViewChild('slNivelProteseDiscal') slNivelProteseDiscal: MatSelect;


  lateralidadeArtrodese: ArrayDropDown[] = [
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'direito', viewValue: 'Direito' },
  ];

  nivelArtrodese: ArrayDropDown[] = [
    { value: 'C2', viewValue: 'C2' },
    { value: 'C3', viewValue: 'C3' },
    { value: 'C4', viewValue: 'C4' },
    { value: 'C5', viewValue: 'C5' },
    { value: 'C6', viewValue: 'C6' },
    { value: 'C7', viewValue: 'C7' },
  ];

  nivelEspacadorDiscal: ArrayDropDown[] = [
    { value: 'C2-C3', viewValue: 'C2-C3' },
    { value: 'C3-C4', viewValue: 'C3-C4' },
    { value: 'C4-C5', viewValue: 'C4-C5' },
    { value: 'C5-C6', viewValue: 'C5-C6' },
    { value: 'C6-C7', viewValue: 'C6-C7' },
    { value: 'C7-T1', viewValue: 'C7-T1' },
  ];

  nivelEspacadorDiscalAncora: ArrayDropDown[] = [
    { value: 'C2-C3', viewValue: 'C2-C3' },
    { value: 'C3-C4', viewValue: 'C3-C4' },
    { value: 'C4-C5', viewValue: 'C4-C5' },
    { value: 'C5-C6', viewValue: 'C5-C6' },
    { value: 'C6-C7', viewValue: 'C6-C7' },
    { value: 'C7-T1', viewValue: 'C7-T1' },
  ];

  nivelProteseDiscal: ArrayDropDown[] = [
    { value: 'C2-C3', viewValue: 'C2-C3' },
    { value: 'C3-C4', viewValue: 'C3-C4' },
    { value: 'C4-C5', viewValue: 'C4-C5' },
    { value: 'C5-C6', viewValue: 'C5-C6' },
    { value: 'C6-C7', viewValue: 'C6-C7' },
    { value: 'C7-T1', viewValue: 'C7-T1' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahArtrodeseSoltura: false,
      sahEspacadorDiscal: false,
      sahEspacadorDiscalAncora: false,
      sahProteseDiscal: false,
    };
  }

  clickArtrodese(){
    this.showAndHideTerms.sahArtrodeseSoltura = false;
    this.ckArtrodeseSoltura.checked = false;
    this.catchIDs();
  }

  clickArtrodeseSoltura(){
    this.showAndHideTerms.sahArtrodeseSoltura = !this.showAndHideTerms.sahArtrodeseSoltura;
    this.ckArtrodese.checked = false;
    this.catchIDs();
  }

  clickArtrodeseFratura(){
    this.catchIDs();
  }

  clickEspacadorDiscal(){
    this.showAndHideTerms.sahEspacadorDiscal = !this.showAndHideTerms.sahEspacadorDiscal;
    this.showAndHideTerms.sahEspacadorDiscalAncora = false;
    this.ckEspacadorDiscalAncora.checked = false;
    this.catchIDs();
  }

  clickEspacadorDiscalAncora(){
    this.showAndHideTerms.sahEspacadorDiscalAncora = !this.showAndHideTerms.sahEspacadorDiscalAncora;
    this.showAndHideTerms.sahEspacadorDiscal = false;
    this.ckEspacadorDiscal.checked = false;
    this.catchIDs();
  }

  clickProteseDiscal(){
    this.showAndHideTerms.sahProteseDiscal = !this.showAndHideTerms.sahProteseDiscal;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitArtrodeseSimples();

    if(this.countLesoes > 0){
      this.emitArtrodeseSolturaLesao();
    } else {
      this.emitArtrodeseSolturaSimples();
    }

    if(this.countLesoes > 0){
      this.emitArtrodeseFraturaLesao();
    } else {
      this.emitArtrodeseFraturaSimples();
    }

    if(this.countLesoes > 0){
      this.emitEspacadorDiscalLesao();
    } else {
      this.emitEspacadorDiscalSimples();
    }

    if(this.countLesoes > 0){
      this.emitEspacadorDiscalAncoraLesao();
    } else {
      this.emitEspacadorDiscalAncoraSimples();
    }

    if(this.countLesoes > 0){
      this.emitProteseLesao();
    } else {
      this.emitProteseSimples();
    }

    if(!this.ckArtrodese.checked && !this.ckArtrodeseSoltura.checked && !this.ckProteseDiscal.checked &&
      !this.ckArtrodeseFratura.checked
     && !this.ckEspacadorDiscal.checked && !this.ckEspacadorDiscalAncora.checked){
        this.termoSelecionado.emit(5162);
      }

    this.listaCompleta.emit();

  }

  emitArtrodeseLesao() {
    if(this.ckArtrodese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitArtrodeseSimples() {
    if(this.ckArtrodese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodese.id);
    }
  }

  emitArtrodeseSolturaSimples() {
    if(this.ckArtrodeseSoltura.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseSoltura.id);

      if(this.slLateralidadeArtrodese && this.slLateralidadeArtrodese.value){
        const objeto = {elemento: this.slLateralidadeArtrodese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodese && this.slNivelArtrodese.value){
        const objeto = {elemento: this.slNivelArtrodese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtrodeseSolturaLesao() {
    if(this.ckArtrodeseSoltura.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseSoltura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeArtrodese && this.slLateralidadeArtrodese.value){
        const objeto = {elemento: this.slLateralidadeArtrodese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelArtrodese && this.slNivelArtrodese.value){
        const objeto = {elemento: this.slNivelArtrodese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtrodeseFraturaLesao(){
    if (this.ckArtrodeseFratura && this.ckArtrodeseFratura.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseFratura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto)
    }
  }

  emitArtrodeseFraturaSimples(){
    if (this.ckArtrodeseFratura && this.ckArtrodeseFratura.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseFratura.id);
    }
  }

  emitEspacadorDiscalLesao(){
    if(this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelEspacadorDiscal && this.slNivelEspacadorDiscal.value){
        const objeto = {elemento: this.slNivelEspacadorDiscal, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalSimples(){
    if(this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscal.id);

      if(this.slNivelEspacadorDiscal && this.slNivelEspacadorDiscal.value){
        const objeto = {elemento: this.slNivelEspacadorDiscal, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalAncoraLesao(){
    if(this.ckEspacadorDiscalAncora.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscalAncora.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelEspacadorDiscalAncora && this.slNivelEspacadorDiscalAncora.value){
        const objeto = {elemento: this.slNivelEspacadorDiscalAncora, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspacadorDiscalAncoraSimples(){
    if(this.ckEspacadorDiscalAncora.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscalAncora.id);

      if(this.slNivelEspacadorDiscalAncora && this.slNivelEspacadorDiscalAncora.value){
        const objeto = {elemento: this.slNivelEspacadorDiscalAncora, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitProteseLesao(){
    if(this.ckProteseDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckProteseDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelProteseDiscal && this.slNivelProteseDiscal.value){
        const objeto = {elemento: this.slNivelProteseDiscal, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitProteseSimples(){
    if(this.ckProteseDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckProteseDiscal.id);

      if(this.slNivelProteseDiscal && this.slNivelProteseDiscal.value){
        const objeto = {elemento: this.slNivelProteseDiscal, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

}
