import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Validacoes} from '../../../../../../utils/validacoes';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-plano-muscular',
  templateUrl: './plano-muscular.component.html',
  styleUrls: ['./plano-muscular.component.css']
})
export class PlanoMuscularComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;

  @ViewChild('rdDiastase') rdDiastase: MatRadioButton;
  @ViewChild('inMedidaDiastase') inMedidaDiastase: ElementRef;
  @ViewChild('inDistCicatriz') inDistCicatriz: ElementRef;
  @ViewChild('slLocalizacaoDiastase') slLocalizacaoDiastase: MatSelect;

  @ViewChild('ckColecaoOrg') ckColecaoOrg: MatCheckbox;
  @ViewChild('slParedesColecaoOrg') slParedesColecaoOrg: MatSelect;
  @ViewChild('slConteudoColecaoOrg') slConteudoColecaoOrg: MatSelect;
  @ViewChild('inMedidaColecaoOrg1') inMedidaColecaoOrg1: ElementRef;
  @ViewChild('inMedidaColecaoOrg2') inMedidaColecaoOrg2: ElementRef;
  @ViewChild('inMedidaColecaoOrg3') inMedidaColecaoOrg3: ElementRef;
  @ViewChild('inVolumeColecaoOrg') inVolumeColecaoOrg: ElementRef;
  @ViewChild('inDistPeleColecaoOrg') inDistPeleColecaoOrg: ElementRef;
  @ViewChild('ckSeptosColecaoOrg') ckSeptosColecaoOrg: MatCheckbox;

  @ViewChild('ckColecaoNaoOrg') ckColecaoNaoOrg: MatCheckbox;
  @ViewChild('inMedidaColecaoNaoOrg1') inMedidaColecaoNaoOrg1: ElementRef;
  @ViewChild('inMedidaColecaoNaoOrg2') inMedidaColecaoNaoOrg2: ElementRef;
  @ViewChild('inMedidaColecaoNaoOrg3') inMedidaColecaoNaoOrg3: ElementRef;
  @ViewChild('inDistPeleColecaoNaoOrg') inDistPeleColecaoNaoOrg: ElementRef;
  @ViewChild('inDistanciaReto') inDistanciaReto: ElementRef;

  localizacaoCicatriz: ArrGenerico[] = [
    {value: 'acima', viewValue: 'Acima'},
    {value: 'abaixo', viewValue: 'Abaixo'}
  ];

  localizacaoCicatrizSem: ArrGenerico[] = [
    {value: 'acima', viewValue: 'Acima'},
    {value: 'abaixo', viewValue: 'Abaixo'},
    {value: 'ao nível', viewValue: 'Ao nível'}
  ];

  paredes: ArrGenerico[] = [
    {value: 'finas', viewValue: 'Finas'},
    {value: 'espessadas', viewValue: 'Espessadas'}
  ];

  conteudos: ArrGenerico[] = [
    {value: 'anecoico', viewValue: 'Anecoico'},
    {value: 'hipoecoico/espesso', viewValue: 'Hipoecoico/espesso'},
    {value: 'heterogêneo', viewValue: 'Heterogêneo'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  volumeColecao = 'Volume';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahDiastase: false,
      sahColecaoOrg: false,
      sahColecaoNaoOrg: false,
      sahDistaReto: true,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahDiastase = false;
    this.showAndHideTerms.sahDistaReto = true;
    this.catchIDs();
  }

  clickDiastase() {
    this.showAndHideTerms.sahDiastase = true;
    this.showAndHideTerms.sahDistaReto = false;
    this.catchIDs();
  }

  clickColecaoOrg() {
    if (this.showAndHideTerms.sahColecaoOrg == false) {
      this.showAndHideTerms.sahColecaoOrg = true;
    } else {
      this.showAndHideTerms.sahColecaoOrg = false;
    }
    this.catchIDs();
  }

  clickColecaoNaoOrg() {
    if (this.showAndHideTerms.sahColecaoNaoOrg == false) {
      this.showAndHideTerms.sahColecaoNaoOrg = true;
    } else {
      this.showAndHideTerms.sahColecaoNaoOrg = false;
    }
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  isSemDistancia(){
    if(this.inDistCicatriz && this.inDistCicatriz.nativeElement.value){
        return false;
    }
    return true;
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inDistanciaReto && this.inDistanciaReto.nativeElement.value) {
        const objeto = {elemento: this.inDistanciaReto.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdDiastase.checked) {
      this.termoSelecionado.emit(this.rdDiastase.id);

      if (this.inMedidaDiastase !== undefined &&
        this.inMedidaDiastase.nativeElement.id !== undefined &&
        this.inMedidaDiastase.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaDiastase.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistCicatriz !== undefined &&
        this.inDistCicatriz.nativeElement.id !== undefined &&
        this.inDistCicatriz.nativeElement.value != '') {

        const objeto = {elemento: this.inDistCicatriz.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoDiastase != undefined && this.slLocalizacaoDiastase.value != undefined) {
        const objeto = {elemento: this.slLocalizacaoDiastase, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckColecaoOrg.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckColecaoOrg.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slParedesColecaoOrg != undefined && this.slParedesColecaoOrg.value != undefined) {
        const objeto = {elemento: this.slParedesColecaoOrg, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoColecaoOrg != undefined && this.slConteudoColecaoOrg.value != undefined) {
        const objeto = {elemento: this.slConteudoColecaoOrg, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoColecaoOrg(false);

      if (this.inVolumeColecaoOrg !== undefined &&
        this.inVolumeColecaoOrg.nativeElement.id !== undefined) {

        // Volume coleção
        let elemento;
        if (!this.inVolumeColecaoOrg.nativeElement.value && this.volumeColecao !== 'Volume') {
          elemento = {id: 940, value: this.volumeColecao, cenario: this.countLesoes};
        } else {
          elemento = {id: 940, value: this.inVolumeColecaoOrg.nativeElement.value, cenario: this.countLesoes};
        }
        const objeto = {elemento: elemento, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleColecaoOrg !== undefined &&
        this.inDistPeleColecaoOrg.nativeElement.id !== undefined &&
        this.inDistPeleColecaoOrg.nativeElement.value != '') {

        const objeto = {elemento: this.inDistPeleColecaoOrg.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }
      if (this.ckSeptosColecaoOrg){
        if (this.ckSeptosColecaoOrg.checked) {
          const objeto = {id: this.ckSeptosColecaoOrg.id, cenario: this.countLesoes};
          this.lesoesSelecionadas.emit(objeto);
        }
      }
    }

    if (this.ckColecaoNaoOrg.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckColecaoNaoOrg.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoColecaoNaoOrg(false);

      if (this.inDistPeleColecaoNaoOrg !== undefined &&
        this.inDistPeleColecaoNaoOrg.nativeElement.id !== undefined &&
        this.inDistPeleColecaoNaoOrg.nativeElement.value != '') {

        const objeto = {elemento: this.inDistPeleColecaoNaoOrg.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  formataMedindoColecaoOrg(isPrimeiraLesao) {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedidaColecaoOrg1 != undefined &&
      this.inMedidaColecaoOrg1.nativeElement.id != undefined &&
      this.inMedidaColecaoOrg1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoOrg1.nativeElement.value);
      resultado = this.inMedidaColecaoOrg1.nativeElement.value;
    }

    if (this.inMedidaColecaoOrg2 != undefined &&
      this.inMedidaColecaoOrg2.nativeElement.id != undefined &&
      this.inMedidaColecaoOrg2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoOrg2.nativeElement.value);
        resultado = resultado * this.inMedidaColecaoOrg2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoOrg2.nativeElement.value);
        resultado = this.inMedidaColecaoOrg2.nativeElement.value;
      }
    }

    if (this.inMedidaColecaoOrg3 != undefined &&
      this.inMedidaColecaoOrg3.nativeElement.id != undefined &&
      this.inMedidaColecaoOrg3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoOrg3.nativeElement.value);
        resultado = resultado * this.inMedidaColecaoOrg3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoOrg3.nativeElement.value);
        resultado = this.inMedidaColecaoOrg3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume coleção
      resultado = resultado * 0.523;
      this.volumeColecao = resultado.toFixed(1).toString();


      const objetoElemento = {id: 939, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoColecaoNaoOrg(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaColecaoNaoOrg1 != undefined &&
      this.inMedidaColecaoNaoOrg1.nativeElement.id != undefined &&
      this.inMedidaColecaoNaoOrg1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg1.nativeElement.value);
    }

    if (this.inMedidaColecaoNaoOrg2 != undefined &&
      this.inMedidaColecaoNaoOrg2.nativeElement.id != undefined &&
      this.inMedidaColecaoNaoOrg2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg2.nativeElement.value);
      }
    }

    if (this.inMedidaColecaoNaoOrg3 != undefined &&
      this.inMedidaColecaoNaoOrg3.nativeElement.id != undefined &&
      this.inMedidaColecaoNaoOrg3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 944, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao};
      this.termosGenericos.emit(objetoLesao);
    }
  }
}
