import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-veia-tibias-posteriores',
  templateUrl: './veia-tibias-posteriores.component.html',
  styleUrls: ['./veia-tibias-posteriores.component.css']
})
export class VeiaTibiasPosterioresComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdTVPAguda') rdTVPAguda: MatRadioButton;
  @ViewChild('rdTVPSubagudaCronicanaorecanalizada') rdTVPSubagudaCronicanaorecanalizada: MatRadioButton;
  @ViewChild('rdTVPSubagudaCronicaparcialmenterecanalizada') rdTVPSubagudaCronicaparcialmenterecanalizada: MatRadioButton;
  @ViewChild('slmotivo') slmotivo: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slcalibre') slcalibre: MatSelect;


  public motivo: ArrayDropDown[] = [
    { value: 'edema', viewValue: 'Edema' },
    { value: 'atenuação do feixe acústico', viewValue: 'Atenuação do feixe acústico' },
  ];

  public localizacao: ArrayDropDown[] = [
    { value: 'em toda sua extensão', viewValue: 'Toda Sua Extensão' },
    { value: 'terço proximal', viewValue: 'Terço Proximal' },
    { value: 'terço médio', viewValue: 'Terço Médio' },
    { value: 'terço distal', viewValue: 'Terço Distal' },
  ];

  public calibre: ArrayDropDown[] = [
    { value: 'aumentado', viewValue: 'Aumentado' },
    { value: 'reduzido', viewValue: 'Reduzido' },
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahnaoCaracterizada: false,
      sahTVPAguda: false,
      sahTVPSubagudaCronicanaorecanalizada: false,
      sahTVPSubagudaCronicaparcialmenterecanalizada: false

    };
  }

  public ClickNormal(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTVPAguda = false;
    this.showAndHideTerms.sahTVPSubagudaCronicanaorecanalizada = false;
    this.showAndHideTerms.sahTVPSubagudaCronicaparcialmenterecanalizada = false;
    this.catchIDs();
  }

  public ClicknaoCaracterizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = true;
    this.showAndHideTerms.sahTVPAguda = false;
    this.showAndHideTerms.sahTVPSubagudaCronicanaorecanalizada = false;
    this.showAndHideTerms.sahTVPSubagudaCronicaparcialmenterecanalizada = false;
    this.catchIDs();
  }

  public ClickTVPAguda(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTVPAguda = true;
    this.showAndHideTerms.sahTVPSubagudaCronicanaorecanalizada = false;
    this.showAndHideTerms.sahTVPSubagudaCronicaparcialmenterecanalizada = false;
    this.catchIDs();
  }

  public ClickTVPSubagudaCronicanaorecanalizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTVPAguda = false;
    this.showAndHideTerms.sahTVPSubagudaCronicanaorecanalizada = true;
    this.showAndHideTerms.sahTVPSubagudaCronicaparcialmenterecanalizada = false;
    this.catchIDs();
  }

  public ClickTVPSubagudaCronicaparcialmenterecanalizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTVPAguda = false;
    this.showAndHideTerms.sahTVPSubagudaCronicanaorecanalizada = false;
    this.showAndHideTerms.sahTVPSubagudaCronicaparcialmenterecanalizada = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdnaoCaracterizada && this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);

      if (this.slmotivo && this.slmotivo.value) {
        const objeto = { elemento: this.slmotivo };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTVPAguda && this.rdTVPAguda.checked) {
      this.termoSelecionado.emit(this.rdTVPAguda.id);


      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

    }
    // fibromatose 2 ou mais nodulos
    if (this.rdTVPSubagudaCronicanaorecanalizada && this.rdTVPSubagudaCronicanaorecanalizada.checked) {
      this.termoSelecionado.emit(this.rdTVPSubagudaCronicanaorecanalizada.id);

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }
      if (this.slcalibre && this.slcalibre.value) {
        const objeto = { elemento: this.slcalibre };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTVPSubagudaCronicaparcialmenterecanalizada && this.rdTVPSubagudaCronicaparcialmenterecanalizada.checked) {
      this.termoSelecionado.emit(this.rdTVPSubagudaCronicaparcialmenterecanalizada.id);

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }
      if (this.slcalibre && this.slcalibre.value) {
        const objeto = { elemento: this.slcalibre };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();

  }

}
