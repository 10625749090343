import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-opt-trofoblasto',
  templateUrl: './opt-trofoblasto.component.html',
  styleUrls: ['./opt-trofoblasto.component.css']
})
export class OptTrofoblastoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdDescolamento') rdDescolamento: MatRadioButton;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;

  @ViewChild('slInsercao') slInsercao: MatSelect;
  @ViewChild('slLocal') slLocal: MatSelect;

  showAndHideTerms: any;

  insercoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Predominantemente anterior' },
    { value: 'posterior', viewValue: 'Predominantemente posterior' },
    { value: 'difusa', viewValue: 'Difusa' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'fúndica', viewValue: 'Fúndica' },
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'inferior', viewValue: 'Inferior' },
    { value: 'lateral direita', viewValue: 'Lateral direita' },
    { value: 'lateral esquerda', viewValue: 'Lateral esquerda' }
  ];

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: false,
    }
  }

  clickNormal() {
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  clickDescolamento() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.slInsercao && this.slInsercao.value) {
        const objeto = { elemento: this.slInsercao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdDescolamento && this.rdDescolamento.checked) {
      this.termoSelecionado.emit(this.rdDescolamento.id);
      if (this.slInsercao && this.slInsercao.value) {
        const objeto = { elemento: this.slInsercao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLocal && this.slLocal.value) {
        const objeto = { elemento: this.slLocal, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindo();
    }
    this.listaCompleta.emit();
  }


  formataMedindo() {
    let medidaFrase = '';
    let med1 = 0;
    let med2 = 0;
    let med3 = 0;

    if (this.inMedida1 !== undefined &&
      this.inMedida1.nativeElement.id !== undefined &&
      this.inMedida1.nativeElement.value !== '') {
      med1 = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {
      med2 = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {
      med3 = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
    }

    medidaFrase = med1 + ' x ' + med2 + ' x ' + med3;
    const objetoElemento = { id: 2013, value: medidaFrase, cenario: 0 };
    const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
    this.termosGenericos.emit(objeto);
  }


}
