import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mg-recomendacao',
  templateUrl: './mg-recomendacao.component.html',
  styleUrls: ['./mg-recomendacao.component.css']
})
export class MgRecomendacaoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdZeroAssCom') rdZeroAssCom: MatRadioButton;
  @ViewChild('rdZeroAssUlt') rdZeroAssUlt: MatRadioButton;
  @ViewChild('rdZeroCalci') rdZeroCalci: MatRadioButton;
  @ViewChild('rdZeroNod') rdZeroNod: MatRadioButton;
  @ViewChild('rdUm') rdUm: MatRadioButton;
  @ViewChild('rdDois') rdDois: MatRadioButton;
  @ViewChild('rdTres') rdTres: MatRadioButton;
  @ViewChild('rdQuatro') rdQuatro: MatRadioButton;
  @ViewChild('rdCinco') rdCinco: MatRadioButton;

  @ViewChild('slMama') slMama: MatSelect;
  @ViewChild('slTempo') slTempo: MatSelect;

  showAndHideTerms: any;

  mamas: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  tempos: ArrayDropDown[] = [
    { value: '6 meses', viewValue: '6 Meses' },
    { value: '1 ano', viewValue: '1 Ano' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: false,
      sahZero: false,
      sahTres: false
    };
  }

  clickZero() {
    this.showAndHideTerms.sahPresente = true;
    this.showAndHideTerms.sahZero = true;
    this.showAndHideTerms.sahTres = false;
    this.catchIDs();
  }

  clickDefault() {
    this.showAndHideTerms.sahPresente = false;
    this.showAndHideTerms.sahZero = false;
    this.showAndHideTerms.sahTres = false;
    this.catchIDs();
  }

  clickTres() {
    this.showAndHideTerms.sahPresente = true;
    this.showAndHideTerms.sahZero = false;
    this.showAndHideTerms.sahTres = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdZeroAssCom.checked) {
      this.termoSelecionado.emit(this.rdZeroAssCom.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } 
    }

    if (this.rdZeroAssUlt.checked) {
      this.termoSelecionado.emit(this.rdZeroAssUlt.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } 
    }

    if (this.rdZeroCalci.checked) {
      this.termoSelecionado.emit(this.rdZeroCalci.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } 
    }
    
    if (this.rdZeroNod.checked) {
      this.termoSelecionado.emit(this.rdZeroNod.id);

      if (this.slMama && this.slMama.value) {
        const objeto = { elemento: this.slMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } 
    }

    if (this.rdUm.checked) {
      this.termoSelecionado.emit(this.rdUm.id);
    }

    if (this.rdDois.checked) {
      this.termoSelecionado.emit(this.rdDois.id);
    }

    if (this.rdTres.checked) {
      this.termoSelecionado.emit(this.rdTres.id);

      if (this.slTempo && this.slTempo.value) {
        const objeto = { elemento: this.slTempo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }      
    }

    if (this.rdQuatro.checked) {
      this.termoSelecionado.emit(this.rdQuatro.id);
    }

    if (this.rdCinco.checked) {
      this.termoSelecionado.emit(this.rdCinco.id);
    }

    this.listaCompleta.emit();
  }

}
