import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-cornetos-nasais',
  templateUrl: './cornetos-nasais.component.html',
  styleUrls: ['./cornetos-nasais.component.css']
})
export class CornetosNasaisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHipertrofiados') rdHipertrofiados: MatRadioButton;
  
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  lateralidade: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahHipertrofiados: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahHipertrofiados = false;
    this.catchIDs();
  }

  clickHipertrofiados() {
    this.showAndHideTerms.sahHipertrofiados = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraHipertrofiados();
    this.catchIDs();
  }

  catchIDs() {
      
      this.termoSelecionado.emit('Clean');
  
      if (this.rdNormal && this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
      }
  
      if (this.rdHipertrofiados && this.rdHipertrofiados.checked) {
        this.termoSelecionado.emit(this.rdHipertrofiados.id);

        if(this.slLateralidade && this.slLateralidade.value) {
          if(this.slLateralidade.value.length == 2) {
            this.termoSelecionado.emit(4806);
          } else{
            const objeto = { elemento: this.slLateralidade };
            this.termosGenericos.emit(objeto);
          }
        }
      }

      this.listaCompleta.emit();
  }

  alteraHipertrofiados() {
    if ((this.slLateralidade && this.slLateralidade.selected)        
    ) {
      this.rdHipertrofiados.checked = true;
    } else {
      this.rdNormal.checked = true;
      this.rdHipertrofiados.checked = false;
    }
  }
}
