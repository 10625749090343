import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-seios-costofrenicos',
  templateUrl: './seios-costofrenicos.component.html',
  styleUrls: ['./seios-costofrenicos.component.css']
})
export class SeiosCostofrenicosComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckDerramePleural') ckDerramePleural: MatCheckbox;
  @ViewChild('slLocalizacaoDerrame') slLocalizacaoDerrame: MatSelect;
  @ViewChild('slGraduacao') slGraduacao: MatSelect;

  @ViewChild('ckVelamento') ckVelamento: MatCheckbox;
  @ViewChild('slLocalizacaoVelamento') slLocalizacaoVelamento: MatSelect;

  @ViewChild('ckElevacaoCupulaDiafragmatica') ckElevacaoCupulaDiafragmatica: MatCheckbox;
  @ViewChild('slLocalizacaoElevacao') slLocalizacaoElevacao: MatSelect;


  localizacao: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  localizacaoVelamento: ArrayDropDown[] = [
    { value: 'direito', viewValue: 'Direito' },
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  localizacaoElevacao: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  graduacao: ArrayDropDown[] = [
    { value: 'pequeno', viewValue: 'Pequeno' },
    { value: 'moderado', viewValue: 'Moderado' },
    { value: 'acentuado', viewValue: 'Acentuado' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahDerramePleural: false,
      sahVelamento: false,
      sahElevacaoCupulaDiafragmatica: false,
    };
  }

  changeSelect() {
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickDerramePleural() {
    this.showAndHideTerms.sahDerramePleural = !this.showAndHideTerms.sahDerramePleural;
    this.catchIDs();
  }

  clickVelamento() {
    this.showAndHideTerms.sahVelamento = !this.showAndHideTerms.sahVelamento;
    this.catchIDs();
  }

  clickElevacaoCupulaDiafragmatica() {
    this.showAndHideTerms.sahElevacaoCupulaDiafragmatica = !this.showAndHideTerms.sahElevacaoCupulaDiafragmatica;
    this.catchIDs();
  }

  checkPresente(){
    if(!this.ckDerramePleural.checked &&
      !this.ckVelamento.checked &&
      !this.ckElevacaoCupulaDiafragmatica.checked){
      return true;
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitDerramePleural();

    //Velamento
    if (this.countLesoes > 0) {
      this.emitVelamentoLesao();
    } else {
      this.emitVelamentoSimples();
    }

    //Elevação da cúpula diafragmática
    if (this.countLesoes > 0) {
      this.emitElevacaoCupulaDiafragmaticaLesao();
    } else {
      this.emitElevacaoCupulaDiafragmaticaSimples();
    }

    if (this.checkPresente()) {
      this.termoSelecionado.emit(4914);
    }

    this.listaCompleta.emit();
  }

  emitDerramePleural() {
    if(this.ckDerramePleural && this.ckDerramePleural.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDerramePleural.id);

      if (this.slLocalizacaoDerrame && this.slLocalizacaoDerrame.value) {
        const objeto = {elemento: this.slLocalizacaoDerrame, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
      if(this.slGraduacao && this.slGraduacao.value){
        const objeto = {elemento: this.slGraduacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVelamentoLesao() {
    if(this.ckVelamento && this.ckVelamento.checked){
      this.countLesoes++;
      const objeto = { id: this.ckVelamento.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLocalizacaoVelamento && this.slLocalizacaoVelamento.value){
        const objeto = {elemento: this.slLocalizacaoVelamento, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVelamentoSimples() {
    if(this.ckVelamento && this.ckVelamento.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckVelamento.id);

      if(this.slLocalizacaoVelamento && this.slLocalizacaoVelamento.value){
        const objeto = {elemento: this.slLocalizacaoVelamento, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitElevacaoCupulaDiafragmaticaLesao() {
    if(this.ckElevacaoCupulaDiafragmatica && this.ckElevacaoCupulaDiafragmatica.checked){
      this.countLesoes++;
      const objeto = { id: this.ckElevacaoCupulaDiafragmatica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLocalizacaoElevacao && this.slLocalizacaoElevacao.value){
        const objeto = {elemento: this.slLocalizacaoElevacao, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitElevacaoCupulaDiafragmaticaSimples() {
    if(this.ckElevacaoCupulaDiafragmatica && this.ckElevacaoCupulaDiafragmatica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckElevacaoCupulaDiafragmatica.id);

      if(this.slLocalizacaoElevacao && this.slLocalizacaoElevacao.value){
        const objeto = {elemento: this.slLocalizacaoElevacao, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }
}
