import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-percentil-ip-medio-dialog',
  templateUrl: './percentil-ip-medio-dialog.component.html',
  styleUrls: ['./percentil-ip-medio-dialog.component.css']
})
export class PercentilIpMediaDialogComponent {
  displayedColumns =
    ['semanas', 'p5', 'p50', 'p95'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<PercentilIpMediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface ParamRefElement {
  semanas: string;
  p5: any;
  p50: any;
  p95: any;
}

const ELEMENT_DATA: ParamRefElement[] = [
  { semanas: '11', p5: 1.18, p50: 1.79, p95: 2.70 },
  { semanas: '12', p5: 1.11, p50: 1.68, p95: 2.53 },
  { semanas: '13', p5: 1.05, p50: 1.58, p95: 2.38 },
  { semanas: '14', p5: 0.99, p50: 1.49, p95: 2.24 },
  { semanas: '15', p5: 0.94, p50: 1.41, p95: 2.11 },
  { semanas: '16', p5: 0.89, p50: 1.33, p95: 1.99 },
  { semanas: '17', p5: 0.85, p50: 1.27, p95: 1.88 },
  { semanas: '18', p5: 0.81, p50: 1.20, p95: 1.79 },
  { semanas: '19', p5: 0.78, p50: 1.15, p95: 1.70 },
  { semanas: '20', p5: 0.74, p50: 1.10, p95: 1.61 },
  { semanas: '21', p5: 0.71, p50: 1.05, p95: 1.54 },
  { semanas: '22', p5: 0.69, p50: 1.00, p95: 1.47 },
  { semanas: '23', p5: 0.66, p50: 0.96, p95: 1.41 },
  { semanas: '24', p5: 0.64, p50: 0.93, p95: 1.35 },
  { semanas: '25', p5: 0.62, p50: 0.89, p95: 1.30 },
  { semanas: '26', p5: 0.60, p50: 0.86, p95: 1.25 },
  { semanas: '27', p5: 0.58, p50: 0.84, p95: 1.21 },
  { semanas: '28', p5: 0.56, p50: 0.81, p95: 1.17 },
  { semanas: '29', p5: 0.55, p50: 0.79, p95: 1.13 },
  { semanas: '30', p5: 0.54, p50: 0.77, p95: 1.10 },
  { semanas: '31', p5: 0.52, p50: 0.75, p95: 1.06 },
  { semanas: '32', p5: 0.51, p50: 0.73, p95: 1.04 },
  { semanas: '33', p5: 0.50, p50: 0.71, p95: 1.01 },
  { semanas: '34', p5: 0.50, p50: 0.70, p95: 0.99 },
  { semanas: '35', p5: 0.49, p50: 0.69, p95: 0.97 },
  { semanas: '36', p5: 0.48, p50: 0.68, p95: 0.95 },
  { semanas: '37', p5: 0.48, p50: 0.67, p95: 0.94 },
  { semanas: '38', p5: 0.47, p50: 0.66, p95: 0.92 },
  { semanas: '39', p5: 0.47, p50: 0.65, p95: 0.91 },
  { semanas: '40', p5: 0.47, p50: 0.65, p95: 0.90 }
];
