import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-figado-segmentacao',
  templateUrl: './figado-segmentacao.component.html',
  styleUrls: ['./figado-segmentacao.component.css']
})
export class FigadoSegmentacaoComponent{

  constructor(
    public dialogRef: MatDialogRef<FigadoSegmentacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}