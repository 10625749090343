import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule, MatCardModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuMgComponent } from './menu-mg.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonToggleModule,
    MatIconModule, 
    MatTooltipModule,
    FilterPipeModule,
    FlexLayoutModule    
  ],
  exports: [MenuMgComponent],
  declarations: [MenuMgComponent]
})
export class MenuMgModule { }
