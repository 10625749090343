import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-tendoes-flexores-pe',
  templateUrl: './tendoes-flexores-pe.component.html',
  styleUrls: ['./tendoes-flexores-pe.component.css']
})
export class TendoesFlexoresPeComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTendissinovite') rdTendissinovite: MatRadioButton;
  @ViewChild('rdTendiTeno') rdTendiTeno: MatRadioButton;

  @ViewChildren('tendoesFlexoresChildren') tendoesFlexoresChildren: QueryList<any>;

  dedos: ArrayDropDown[] = [
    { value: 'hálux', viewValue: 'hálux' },
    { value: '2° dedo', viewValue: '2° dedo' },
    { value: '3° dedo', viewValue: '3° dedo' },
    { value: '4° dedo', viewValue: '4° dedo' },
    { value: '5° dedo', viewValue: '5° dedo' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: false
    };
    this.lesoesForm = this._fb.group({
      tendoesFlexores: this._fb.array([this.addTendoesFlexores()]),
    });
  }

  //Dinamico
  addTendoesFlexores() {
    return this._fb.group({
      dedo: []
    });
  }

  addTendoes() {
    this.tendoesFlexoresArray.push(this.addTendoesFlexores());
    this.catchIDs();
  }

  get tendoesFlexoresArray() {
    return <FormArray>this.lesoesForm.get('tendoesFlexores');
  }

  removeTendoes(index) {
    this.tendoesFlexoresArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickTendinopatia() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickTendissinovite() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickTendiTeno() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }


  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      this.verificaTendoes();
    }

    if (this.rdTendissinovite.checked) {
      this.termoSelecionado.emit(this.rdTendissinovite.value);
      this.verificaTendoes();
    }

    if (this.rdTendiTeno.checked) {
      this.termoSelecionado.emit(this.rdTendiTeno.value);
      this.verificaTendoes();
    }

    this.listaCompleta.emit();
  }

  verificaTendoes() {
    this.linha = 0;
    this.tendoesFlexoresChildren.forEach(obj => {

      if(+obj.id === 1762 ) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdTendinopatia && !this.rdTendinopatia.checked) ||
        (this.rdTendissinovite && !this.rdTendissinovite.checked) ||
        (this.rdTendiTeno && !this.rdTendiTeno.checked)) {
      const arrayTotal = this.tendoesFlexoresArray.length;
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.tendoesFlexoresArray.length - 1
        if (index != 0) {
          this.tendoesFlexoresArray.removeAt(index);
        }
      }
      this.tendoesFlexoresArray.reset();
    }
  }

}
