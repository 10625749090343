import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule, 
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { UsgMorfologicaPrimeiroTrimestreComponent } from './usg-morfologica-primeiro-trimestre.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { TransvSegTercTrimestreComponent } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.component';
import { HistoriaClinicaComponent } from './termosComponents/historia-clinica/historia-clinica.component';
import { MedidasPrimeiroTrimestreComponent } from './termosComponents/medidas-primeiro-trimestre/medidas-primeiro-trimestre.component';
import { CrescimentoFetalComponent } from './termosComponents/crescimento-fetal/crescimento-fetal.component';
import { TransvSegTercTrimestreModule } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
import { AnatomiaFetalComponent } from './termosComponents/anatomia-fetal/anatomia-fetal.component';
import { RiscoFmfComponent } from './termosComponents/risco-fmf/risco-fmf.component';
import { OrientacoesAdicionaisComponent } from './termosComponents/orientacoes-adicionais/orientacoes-adicionais.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    ObsCalculadoraModule,
    TransvSegTercTrimestreModule, 
    TextEditorJSmodule,
    MatSlideToggleModule
  ],
  declarations: [
    UsgMorfologicaPrimeiroTrimestreComponent,
    HistoriaClinicaComponent,
    MedidasPrimeiroTrimestreComponent,
    CrescimentoFetalComponent,
    AnatomiaFetalComponent,
    RiscoFmfComponent,
    OrientacoesAdicionaisComponent,
  ],
  exports:[
    HistoriaClinicaComponent,
    MedidasPrimeiroTrimestreComponent,
    CrescimentoFetalComponent,
    AnatomiaFetalComponent,
    RiscoFmfComponent,
    OrientacoesAdicionaisComponent,
  ]
  // providers: [
  //   TransvSegTercTrimestreComponent
  // ]
})
export class UsgMorfologicaPrimeiroTrimestreModule { }
