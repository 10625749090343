import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-fratura-clavicula',
  templateUrl: './fratura-clavicula.component.html',
  styleUrls: ['./fratura-clavicula.component.css']
})
export class FraturaClaviculaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckFratura') ckFratura: MatCheckbox;
  @ViewChild('slLado') slLado: MatSelect;


  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  showAndHideTerms: any;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahFratura: false
    };
  }

  changeSelect() {
    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahFratura = false;

    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;

    this.catchIDs();
  }

  clickFratura() {
    if (this.ckFratura && this.ckFratura.checked) {
      this.showAndHideTerms.sahFratura = true;
    } else {
      this.showAndHideTerms.sahFratura = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

    }
    if (this.rdAlterado && this.rdAlterado.checked) {
      if (this.ckFratura && this.ckFratura.checked) {
        this.termoSelecionado.emit(this.ckFratura.id);

        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: true }
          this.termosGenericos.emit(objeto);
        }
      }
    }

    this.listaCompleta.emit();
  }

  mudaAlterado() {
    if (
      (this.ckFratura && this.ckFratura.checked)
    ) {
      this.rdAlterado.checked = true;
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
      this.rdAlterado.checked = false;
    }
  }

}
