import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-genitalia',
  templateUrl: './mst-genitalia.component.html',
  styleUrls: ['./mst-genitalia.component.css']
})
export class MstGenitaliaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slGenitalia') slGenitalia: MatSelect;


  genitalias: ArrayDropDown[] = [
    { value: 'Feminino', viewValue: 'Feminino' },
    { value: 'Masculino', viewValue: 'Masculino' },
    { value: 'Não foi possível avaliar devido a dificuldade técnica.', viewValue: 'Não identificada' },
    { value: 'Identificada e não descrita à pedido dos pais.', viewValue: 'Não descrita à pedido' },
  ];

  constructor() { }

  ngOnInit() {

  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;
    if (this.slGenitalia && this.slGenitalia.value) {
      const objeto = { elemento: this.slGenitalia, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    this.listaCompleta.emit();

  }
}
