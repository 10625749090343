import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mg-historia-clinica',
  templateUrl: './mg-historia-clinica.component.html',
  styleUrls: ['./mg-historia-clinica.component.css']
})
export class MgHistoriaClinicaComponent implements OnInit {

  @Output() enviaHistoria = new EventEmitter();

  @ViewChild('inHistoria') inHistoria: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  blur() {
    if (this.inHistoria.nativeElement.value.includes('\n')) {
      var textoInput = this.inHistoria.nativeElement.value.replace(/\n/gi, '<br>');
      this.enviaHistoria.emit(textoInput);
    } else if (this.inHistoria) {
      this.enviaHistoria.emit(this.inHistoria.nativeElement.value);
    }
  }
}
