import { MatCheckbox, MatRadioButton } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-espacos-discais',
  templateUrl: './espacos-discais.component.html',
  styleUrls: ['./espacos-discais.component.css']
})
export class EspacosDiscaisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('ckReducaoEspacoDiscal') ckReducaoEspacoDiscal: MatCheckbox;
  @ViewChild('ckReducaoMultiplosEspacosDiscais') ckReducaoMultiplosEspacosDiscais: MatCheckbox;

  showAndHideTerms: any;
  countLesoes = 0;

  lesaoReducaoEspacoDiscalFormGroup: FormGroup;
  lesaoReducaoMultiplosEspacosDiscaisFormGroup: FormGroup;

  lesaoReducaoEspacoDiscal: any;
  lesaoReducaoMultiplosEspacosDiscais: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    
    this.iniciaFormReducaoEspacoDiscal();
    this.iniciaFormReducaoMultiplosEspacosDiscais();

    this.lesaoReducaoEspacoDiscal = [
      { id: 'DiscalT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'DiscalL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'DiscalL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'DiscalL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'DiscalL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'DiscalL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.lesaoReducaoMultiplosEspacosDiscais = [
      { id: 'DiscalT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'DiscalL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'DiscalL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'DiscalL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'DiscalL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'DiscalL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.showAndHideTerms = {
      sahlesaoReducaoEspacoDiscal: false,
      sahLesaoReducaoMultiplosEspacosDiscais: false,
    };
  }
  
  clickPreservados(){
    this.rdNormal.checked = true;
    this.showAndHideTerms.sahlesaoReducaoEspacoDiscal = false;
    this.showAndHideTerms.sahLesaoReducaoMultiplosEspacosDiscais = false;
    this.ckReducaoEspacoDiscal.checked = false;
    this.ckReducaoMultiplosEspacosDiscais.checked = false;
    this.checkAusente();
    this.catchIDs();
  }

  clickReducaoEspacoDiscal(){
    if (this.ckReducaoEspacoDiscal && this.ckReducaoEspacoDiscal.checked) {
      this.showAndHideTerms.sahlesaoReducaoEspacoDiscal = true;
      this.ckReducaoMultiplosEspacosDiscais.checked = false;
      this.showAndHideTerms.sahLesaoReducaoMultiplosEspacosDiscais = false;
    } else {
      this.showAndHideTerms.sahlesaoReducaoEspacoDiscal = false;
    }

    this.rdNormal.checked = false;
    this.checkAusente();
    this.catchIDs();
  }

  clickReducaoMultiplosEspacosDiscais(){
    if (this.ckReducaoMultiplosEspacosDiscais && this.ckReducaoMultiplosEspacosDiscais.checked) {
      this.showAndHideTerms.sahLesaoReducaoMultiplosEspacosDiscais = true;
      this.ckReducaoEspacoDiscal.checked = false;
      this.showAndHideTerms.sahlesaoReducaoEspacoDiscal = false;
    } else {
      this.showAndHideTerms.sahLesaoReducaoMultiplosEspacosDiscais = false;
    }
    this.rdNormal.checked = false;
    this.checkAusente();
    this.catchIDs();
  }

  clickCheckboxLesaoReducaoEspacoDisca(event) {
    let lesaoReducaoEspacoDiscal= <FormArray>this.lesaoReducaoEspacoDiscalFormGroup.get('lesaoReducaoEspacoDiscal') as FormArray;

    if (event.checked) {
      lesaoReducaoEspacoDiscal.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iLit = lesaoReducaoEspacoDiscal.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoReducaoEspacoDiscal.removeAt(iLit);
    }

    this.catchIDs();
  }

  clickCheckboxLesaoReducaoMultiplosEspacosDiscais(event) {
    let lesaoEsc= <FormArray>this.lesaoReducaoMultiplosEspacosDiscaisFormGroup.get('lesaoReducaoMultiplosEspacosDiscais') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  iniciaFormReducaoEspacoDiscal() {
    this.lesaoReducaoEspacoDiscalFormGroup = this.fb.group({
      lesaoReducaoEspacoDiscal: this.fb.array([])
    });
  }

  iniciaFormReducaoMultiplosEspacosDiscais() {
    this.lesaoReducaoMultiplosEspacosDiscaisFormGroup= this.fb.group({
      lesaoReducaoMultiplosEspacosDiscais: this.fb.array([])
    });
  }

  checkAusente() {
    if (this.ckReducaoEspacoDiscal.checked ||
        this.ckReducaoMultiplosEspacosDiscais.checked) {
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
    }
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.ckReducaoEspacoDiscal.checked) {
      this.iniciaFormReducaoEspacoDiscal();
    }
    if (!this.ckReducaoMultiplosEspacosDiscais.checked) {
      this.iniciaFormReducaoMultiplosEspacosDiscais();
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    } else if (!this.rdNormal.checked) {
      this.emitLesaoReducaoEspacoDiscal();

      // Lesão Esclerótica
      debugger
      if (this.countLesoes > 0) {
        this.emitReducaoMultiplosEspacosDiscais();
      } else {
        this.emitReducaoMultiplosEspacosDiscaisSimples();
      }
    }

    this.listaCompleta.emit();
  }

  emitLesaoReducaoEspacoDiscal(){
    if(this.ckReducaoEspacoDiscal && this.ckReducaoEspacoDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckReducaoEspacoDiscal.id);

      let posicao = this.lesaoReducaoEspacoDiscalFormGroup.value.lesaoReducaoEspacoDiscal;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5334, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5334, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitReducaoMultiplosEspacosDiscais(){
    if (this.ckReducaoMultiplosEspacosDiscais && this.ckReducaoMultiplosEspacosDiscais.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckReducaoMultiplosEspacosDiscais.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.lesaoReducaoMultiplosEspacosDiscaisFormGroup.value.lesaoReducaoMultiplosEspacosDiscais;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5335, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5335, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitReducaoMultiplosEspacosDiscaisSimples(){
    if (this.ckReducaoMultiplosEspacosDiscais && this.ckReducaoMultiplosEspacosDiscais.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckReducaoMultiplosEspacosDiscais.id);

      let posicao = this.lesaoReducaoMultiplosEspacosDiscaisFormGroup.value.lesaoReducaoMultiplosEspacosDiscais;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5335, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5335, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
