import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';
import {ArrGenerico} from '../hidrocele-dieita/hidrocele-dieita.component';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-hidrocele-esquerda',
  templateUrl: './hidrocele-esquerda.component.html',
  styleUrls: ['./hidrocele-esquerda.component.css']
})
export class HidroceleEsquerdaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckSeptacoes') ckSeptacoes: MatCheckbox;
  @ViewChild('slQuantidade') slQuantidade: MatSelect;
  @ViewChild('slConteudo') slConteudo: MatSelect;

  quantidades: ArrGenerico[] = [
    {value: 'Pequena', viewValue: 'Pequena'},
    {value: 'Moderada', viewValue: 'Moderada'},
    {value: 'Acentuada', viewValue: 'Acentuada'}
  ];

  conteudos: ArrGenerico[] = [
    {value: 'anecoico', viewValue: 'Anecoico'},
    {value: 'hipoecogênico', viewValue: 'Hipoecogênico'},
    {value: 'anecoico com debris', viewValue: 'Anecoico com debris'}
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  clickSeptacoes() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeSelect() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.ckSeptacoes !== undefined && this.ckSeptacoes.checked) {
        this.termoSelecionado.emit(this.ckSeptacoes.id);
      }

      if (this.slQuantidade !== undefined && this.slQuantidade.value !== undefined) {
        const objeto = {elemento: this.slQuantidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudo !== undefined && this.slConteudo.value !== undefined) {
        const objeto = {elemento: this.slConteudo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.ckSeptacoes && this.ckSeptacoes.checked) ||
        (this.slQuantidade && this.slQuantidade.value) ||
        (this.slConteudo && this.slConteudo.value)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = true;
    }
  }
}
