import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';

import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { User } from 'src/app/auth/user';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorImprimirService } from 'src/app/services/controladorImprimir.service';
import { OptEmbriaoComponent } from './termosComponents/opt-embriao/opt-embriao.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-transv-primeiro-trimestre',
  templateUrl: './transv-primeiro-trimestre.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./transv-primeiro-trimestre.component.css']
})
export class TransvPrimeiroTrimestreComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1972],blockID:169},
    {listTerm:[1974,1976],blockID:170},
    {listTerm:[2026],blockID:171},
    {listTerm:[2006],blockID:172},
    {listTerm:[2010],blockID:173},
    {listTerm:[2015],blockID:174},
    {listTerm:[1973],blockID:175},
    {listTerm:[2017],blockID:176},
    {listTerm:[2021],blockID:177},
  ]

  descricao: DescricaoConclusao[] = [
    { value: 'Útero de dimensões aumentadas.', blockID: 169, textTermID: 0, visivel: true },
    { value: 'Diâmetro médio de saco gestacional (DMSG) de *** mm.', blockID: 170, textTermID: 0, visivel: true },
    { value: 'Concepto único, com batimentos cardíacos em torno de *** batimentos por minuto (bpm).</br>Comprimento cabeça- nádega (CCN) de *** mm.', blockID: 171, textTermID: 0, visivel: true },
    { value: 'Vesícula vitelínica caracterizada, de aspecto habitual.', blockID: 172, textTermID: 0, visivel: true },
    { value: 'Trofoblasto de inserção predominantemente anterior / posterior/ difusa.', blockID: 173, textTermID: 0, visivel: true },
    { value: 'Colo uterino de aspecto habitual, apresentando orifício interno fechado. ', blockID: 174, textTermID: 0, visivel: true },
    { value: 'Ovários de aspecto habitual.', blockID: 175, textTermID: 0, visivel: true },
    { value: '', blockID: 176, textTermID: 0, visivel: true },
    { value: '', blockID: 177, textTermID: 0, visivel: true }
  ];

  conclusao: DescricaoConclusao[] = [
    { value: 'Gestação única, tópica, de concepto vivo.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 171, textTermID: 0, visivel: true },
    { value: 'Biometria compatível com *** semanas e *** dias (+/- 4 dias).', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 169, textTermID: 0, visivel: true },
    { value: '', blockID: 170, textTermID: 0, visivel: true },
    { value: '', blockID: 172, textTermID: 0, visivel: true },
    { value: '', blockID: 173, textTermID: 0, visivel: true },
    { value: '', blockID: 174, textTermID: 0, visivel: true },
    { value: '', blockID: 175, textTermID: 0, visivel: true },
    { value: '', blockID: 176, textTermID: 0, visivel: true },
    { value: '', blockID: 177, textTermID: 0, visivel: true }
  ];

  genericSacoGestacional: TermosGenericos[] = [
    { value: '', selected: false, termID: 1976, term: '[SG]' }
  ];

  genericEmbriao: TermosGenericos[] = [
    { value: '', selected: false, termID: 2028, term: '[BCF]' },
    { value: '', selected: false, termID: 2029, term: '[CCN]' },
    { value: '', selected: false, termID: 2031, term: '[Lateralidade]' },
    { value: '', selected: false, termID: 2032, term: '[Medida]' },
    { value: '', selected: false, termID: 2034, term: '[Semana]' }
  ];

  genericVesiculaVitelina: TermosGenericos[] = [
    { value: '', selected: false, termID: 2008, term: '[VV]' }
  ];

  genericTrofoblasto: TermosGenericos[] = [
    { value: '', selected: false, termID: 2012, term: '[Insercao]' },
    { value: '', selected: false, termID: 2013, term: '[Medida]' },
    { value: '', selected: false, termID: 2014, term: '[Localizacao]' }
  ];

  genericColoUterino: TermosGenericos[] = [
    { value: '', selected: false, termID: 2016, term: '[Medida]' }
  ];

  genericCorpoLuteo: TermosGenericos[] = [
    { value: '', selected: false, termID: 2019, term: '[CorpoLuteo]' },
    { value: '', selected: false, termID: 2020, term: '[Medida]' }
  ];

  genericLiquidoLivre: TermosGenericos[] = [
    { value: '', selected: false, termID: 2023, term: '[Quantidade]' },
    { value: '', selected: false, termID: 2024, term: '[Conteudo]' },
    { value: '', selected: false, termID: 2025, term: '[Local]' }
  ];

  genericTermsUtero: TermosGenericos[] = [
    { value: '', selected: false, termID: 3959, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3960, term: '[Contornos]' },
    { value: '', selected: false, termID: 3961, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3962, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3963, term: '[Medindo]' },
    { value: '', selected: false, termID: 3966, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3967, term: '[Contornos]' },
    { value: '', selected: false, termID: 3968, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3969, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3970, term: '[Medindo]' }
  ];


  Subtitulo: string;
  subtituloBloco:string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  medicaoNodulo = '';
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  calcFrase = '';
  rodapeTexto = '';
  frasePrimeiroExame = 'Este é o primeiro exame da paciente nesta gestação.';
  mascaraBiometria = 'Biometria compatível com *** semanas e *** dias (+/- 4 dias).';

  viaRealizacao = '';
  cabecalho = false;
  tecnica = true;
  calculadora = false;
  utero = false;
  sacoGes = false;
  embriao = false;
  vesiculavite = false;
  trofoblasto = false;
  coloUterino = false;
  ovarios = false;
  corpoLuteo = false;
  liquidoLivre = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;
  ehEmbriao = false;
  ehSacoGes = false;
  ehCalculadora = false;

  medicalReportTypeID = 35;
  uteroID = 169;
  sacoGesID = 170;
  embriaoID = 171;
  vesiculaviteID = 172;
  trofoblastoID = 173;
  coloUterinoID = 174;
  ovariosID = 175;
  corpoLuteoID = 176;
  liquidoLivreID = 177;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;
  impressaoAtivaSaco:boolean = false;
  impressaoAtivaEmbriao: boolean = false;
  emitEmbriao: any;
  calcConclusao: any;
  embriaoNaoCaracterizado: boolean= false;
  injectarEventoSacoGestacional: Subject<any> = new Subject<any>();


  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _copyPaste: CopyPaste,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _user: User,
    public dialog: MatDialog,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService,
    private _controlImprimir:ControladorImprimirService,
    public embriaoComponent: OptEmbriaoComponent,
    private _toastr: ToastrManager,
    ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._toastr.warningToastr('Atenção, este exame passou por uma atualização para receber a última versão, pressione CTRL + SHIFT + DEL para excluir os dados de navegação e recarregue a página posteriormente. Para mais informações, entre em contato com nosso suporte.', 'Atualização realizada!',
    {
      position: 'top-right',
      toastTimeout: 8000,
      maxShown: 1
    });
    this._pouchService.loadDatabase("transv-primeiroAnalise", this.descricao, this.conclusao);
    this.resetaDialog();
    this.Subtitulo = 'Tecnica';
    this.subtituloBloco = "Tecnica";
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.uteroID,
      this.sacoGesID,
      this.embriaoID,
      this.vesiculaviteID,
      this.trofoblastoID ,
      this.coloUterinoID,
      this.ovariosID,
      this.corpoLuteoID,
      this.liquidoLivreID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "transv-primeiroMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "obsTransPrimeiro");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-obstePrimeiro');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-obstePrimeiro');
      }
    });
  }

  ngAfterViewInit(): void {
    this._controlImprimir.desabilitarImprimir();
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
            this._controlImprimir.desabilitarImprimir();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("transv-primeiroAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricao[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusao[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.viaRealizacao = "";
    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.calcFrase = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
      blocked:false
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo:'Preencha o campo indicação',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Tecnica',
      id: 'tecnica',
      name: 'tecnica',
      selected: true,
      blocked:false
    },
    {
      titulo: 'Calculadora IG',
      id: 'calculadora',
      name: 'calculadora',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Útero',
      id: this.uteroID,
      name: 'Útero',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Saco Gestacional',
      id: this.sacoGesID,
      name: 'saco-gestacional',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Embrião',
      id: this.embriaoID,
      name: 'embriao',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Vesicula Vitelina',
      id: this.vesiculaviteID,
      name: 'vesicula',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Trofoblasto',
      id: this.trofoblastoID,
      name: 'trofoblasto',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Colo Uterino',
      id: this.coloUterinoID,
      name: 'colo-uterino',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Ovários',
      id: this.ovariosID,
      name: 'ovarios',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Corpo Lúteo',
      id: this.corpoLuteoID,
      name: 'corpo-luteo',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Liquido Livre',
      id: this.liquidoLivreID,
      name: 'liquido-livre',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
      blocked:false
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tecnica = (itemSelecionado.id === 'tecnica');
    this.calculadora = (itemSelecionado.id === 'calculadora');
    this.utero = (itemSelecionado.id === this.uteroID);
    this.sacoGes = (itemSelecionado.id === this.sacoGesID);
    this.embriao = (itemSelecionado.id === this.embriaoID);
    this.vesiculavite = (itemSelecionado.id === this.vesiculaviteID);
    this.trofoblasto = (itemSelecionado.id === this.trofoblastoID);
    this.coloUterino = (itemSelecionado.id === this.coloUterinoID);
    this.ovarios = (itemSelecionado.id === this.ovariosID);
    this.corpoLuteo = (itemSelecionado.id === this.corpoLuteoID);
    this.liquidoLivre = (itemSelecionado.id === this.liquidoLivreID);
    this.achadosTexto = (itemSelecionado.id === 'achados_adicionais');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericSacoGestacional = [
      { value: '', selected: false, termID: 1976, term: '[SG]' }
    ];

    this.genericEmbriao = [
      { value: '', selected: false, termID: 2028, term: '[BCF]' },
      { value: '', selected: false, termID: 2029, term: '[CCN]' },
      { value: '', selected: false, termID: 2031, term: '[Lateralidade]' },
      { value: '', selected: false, termID: 2032, term: '[Medida]' },
      { value: '', selected: false, termID: 2034, term: '[Semana]' }
    ];

    this.genericVesiculaVitelina = [
      { value: '', selected: false, termID: 2008, term: '[VV]' }
    ];

    this.genericTrofoblasto = [
      { value: '', selected: false, termID: 2012, term: '[Insercao]' },
      { value: '', selected: false, termID: 2013, term: '[Medida]' },
      { value: '', selected: false, termID: 2014, term: '[Localizacao]' }
    ];

    this.genericColoUterino = [
      { value: '', selected: false, termID: 2016, term: '[Medida]' }
    ];

    this.genericCorpoLuteo = [
      { value: '', selected: false, termID: 2019, term: '[CorpoLuteo]' },
      { value: '', selected: false, termID: 2020, term: '[Medida]' }
    ];

    this.genericLiquidoLivre = [
      { value: '', selected: false, termID: 2023, term: '[Quantidade]' },
      { value: '', selected: false, termID: 2024, term: '[Conteudo]' },
      { value: '', selected: false, termID: 2025, term: '[Local]' }
    ];

    this.genericTermsUtero = [
      { value: '', selected: false, termID: 3959, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3960, term: '[Contornos]' },
      { value: '', selected: false, termID: 3961, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3962, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3963, term: '[Medindo]' },
      { value: '', selected: false, termID: 3966, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3967, term: '[Contornos]' },
      { value: '', selected: false, termID: 3968, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3969, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3970, term: '[Medindo]' }
    ];

  }

  enviaTermos($event) {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaTecnica($event) {
    // this.viaRealizacao = $event;

    // if(!this.newEditor) {
    //   this.AdicionaTexto(this.newEditor);
    // } else {
    //   this.atualizaLinha({
    //     blockId: 'tecnica',
    //     tecnica: this.viaRealizacao
    //   });
    // }
    this.viaRealizacao = $event;
    this.AdicionaTexto(this.newEditor);
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adiconais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 3970 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 3970,
          lesionID: 3958,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 3970;
            obj.lesionID = 3958;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';

          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsUtero.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      //NODULO
      case '3966':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3966,
          lesionID: 3958,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3967':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3967,
          lesionID: 3958,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contornos]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3968':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3968,
          lesionID: 3958,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3969':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3969,
          lesionID: 3958,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoUtero($event) {
    this.genericTermsUtero.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  enviaCalculadora($event) {
    if($event[0].toString() == 'unCheck' && $event[1]){
      this.calcFrase = $event[1].toString();
    } else {
      this.calcFrase = $event[0].toString();
    }

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      setTimeout(() => {
        this.atualizaLinha({
          blockId: 'calculadora',
          calculadora: this.calcFrase
        });
      }, 1000);
    }
  }

  enviaCalculadoraConclusao($event) {
    if($event[0] !== undefined){
      this.calcConclusao = $event[0].toString();
      this.AdicionaTexto(this.newEditor);
    }
  }

  adicionaSelecaoSaco($event) {
    this.genericSacoGestacional.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoEmbriao($event) {
    this.genericEmbriao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoVesicula($event) {
    this.genericVesiculaVitelina.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTrofoblasto($event) {
    this.genericTrofoblasto.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoColoUterino($event) {
    this.genericColoUterino.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCorpoLuteo($event) {
    this.genericCorpoLuteo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLiquidoLivre($event) {
    this.genericLiquidoLivre.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.utero) {
        this.term.blockID = this.uteroID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.uteroID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoUtero(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.uteroID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.uteroID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.uteroID;
              obj.value = obj.value + espaco + this.MontaConclusaoUtero(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.uteroID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.uteroID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.ovarios) {
        this.term.blockID = this.ovariosID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.ovariosID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });
              let index = this.descricao.findIndex(x => x.blockID === this.ovariosID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ovariosID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.ovariosID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ovariosID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.sacoGes) {
        this.term.blockID = this.sacoGesID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.sacoGesID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text) {
                  obj.value = this.MontaDescricaoConclusaoSaco(resultado.text);
                }
              });

              const index = this.descricao.findIndex(x => x.blockID === this.sacoGesID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.sacoGesID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoConclusaoSaco(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.sacoGesID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.sacoGesID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.embriao) {

        this.term.blockID = this.embriaoID;
        this.emitEmbriao = this.term
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.embriaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEmbriao(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.embriaoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.embriaoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoEmbriao(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          // const novaConclusao = obj.value.split('</br>');
          // obj.value = novaConclusao[0];
          // this.conclusao[2].value = novaConclusao[1];
          // this.ehEmbriao = true;

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.embriaoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.embriaoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.vesiculavite) {
        this.term.blockID = this.vesiculaviteID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.vesiculaviteID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text) {
                  obj.value = this.MontaDescricaoVesicula(resultado.text);
                }
              });

              const index = this.descricao.findIndex(x => x.blockID === this.vesiculaviteID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.vesiculaviteID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoVesicula(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.vesiculaviteID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.vesiculaviteID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.trofoblasto) {

        this.term.blockID = this.trofoblastoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.trofoblastoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTrofoblasto(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.trofoblastoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.trofoblastoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoTrofoblasto(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.trofoblastoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.trofoblastoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.coloUterino) {
        this.term.blockID = this.coloUterinoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.coloUterinoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text) {
                  obj.value = this.MontaDescricaoColoUterino(resultado.text);
                }
              });

              const index = this.descricao.findIndex(x => x.blockID === this.coloUterinoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc,
                });
              }
            }
          });
        });
      } else if (this.corpoLuteo) {
        this.term.blockID = this.corpoLuteoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.corpoLuteoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                if (resultado.text) {
                  obj.value = this.MontaDescricaoCorpoLuteo(resultado.text);
                }
              });

              const index = this.descricao.findIndex(x => x.blockID === this.corpoLuteoID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.term.blockID,
                  descricao: desc,
                });
              }
            }
          });
        });
      } else if (this.liquidoLivre) {

        this.term.blockID = this.liquidoLivreID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricao.forEach(obj => {
            if (obj.blockID === this.liquidoLivreID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLiquidoLivre(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricao.findIndex(x => x.blockID === this.liquidoLivreID);
              if (index > -1) {
                this.descricao[index].value = obj.value;
                this.descricao[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.liquidoLivreID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoLiquidoLivre(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaconclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusao.filter(x => x.value !== '');
          const conclusaoNull = this.conclusao.find(x => x.blockID === 0);
          const conclusao = this.conclusao.find(x => x.blockID === this.liquidoLivreID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoLivreID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoUtero(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoUteroDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoUteroDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoUteroDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {

        if (+term === 3959 || +term === 3960 || +term === 3961 || +term === 3962 || +term === 3963) {
          this.genericTermsUtero.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoUteroDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0];
      textoDinamico = textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (+term === 3966 || +term === 3967) {
        this.genericTermsUtero.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID === 3966 || item.termID === 3967 || item.termID == 3968 || item.termID == 3969 || item.termID == 3970) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID == 3966 || item.termID == 3967 || item.termID == 3968 || item.termID == 3969 || item.termID == 3970) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoUtero(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3959 || +term === 3960 || +term === 3961 || +term === 3962 || +term === 3963 ||
        +term === 3966 || +term === 3967 || +term === 3968 || +term === 3969 || +term === 3970) {
        this.genericTermsUtero.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoConclusaoSaco(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1976) {
        this.genericSacoGestacional.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoVesicula(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2008) {
        this.genericVesiculaVitelina.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoVesicula(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2008) {
        this.genericVesiculaVitelina.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTrofoblasto(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2012 || +term === 2013 || +term === 2014) {
        this.genericTrofoblasto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoTrofoblasto(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2012 || +term === 2013 || +term === 2014) {
        this.genericTrofoblasto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoColoUterino(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2016) {
        this.genericColoUterino.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoCorpoLuteo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2019 || +term === 2020) {
        this.genericCorpoLuteo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoLiquidoLivre(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2023 || +term === 2024 || +term === 2025) {
        this.genericLiquidoLivre.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoLiquidoLivre(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2023 || +term === 2024 || +term === 2025) {
        this.genericLiquidoLivre.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoEmbriao(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2028 || +term === 2029 || +term === 2031
        || +term === 2032 || +term === 2034) {
        this.genericEmbriao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclussaoEmbriao(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 2028 || +term === 2029 || +term === 2031
        || +term === 2032 || +term === 2034) {
        this.genericEmbriao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    // this.laudoCompleto.push({
    //   'type': 'paragraph',
    //   'data': {
    //     'text': `<p>${maskSplit[0]}</p>`,
    //   }
    // });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-tecnica`,
        'name': ''
      },
      'data': {
        'text': `<div style="text-align: center;"><b>ULTRASSONOGRAFIA OBSTÉTRICA ${this.viaRealizacao.toUpperCase()}</b></div>`,
      }
    });

    if (this.calcFrase) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-calculadora`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.calcFrase}</p>`,
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<br/><br/><b>TÉCNICA:</b><br/></br>Exame realizado com transdutor de 5.5 Mhz.</br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.descricao.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.conclusao.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-observacao`
      },
      'data': {
        'text': `<p>${this.RetornaConclusaoLista()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    this.htmlContent += this.viaRealizacao.toUpperCase();

    this.htmlContent += maskSplit[1];

    if(this.calcFrase !== 'unCheck'){
      this.htmlContent += '<br>' + this.calcFrase + '<br><br>';
    }

    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor endocavitário multifrequencial.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.descricao.length; i++) {
      if (this.descricao[i].value) {
        this.htmlContent += '<br/>' + this.descricao[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[3];

    if(this.conclusao[1].value){
      this.ehEmbriao = true;
    } else {
      this.ehEmbriao = false;
    }
    //Controla a função caso não tenha embriao registrado, logo caso não tenha embriao, o value deve ser gerado pela calculadora e atribuido no conclusao[0]
    if(this.calcFrase != '' && this.calcFrase !== 'unCheck' && !this.embriao){
      this.conclusao[2].visivel = true;
      this.conclusao[2].value = this.calcConclusao;
    }
    //Controla a função caso não tenha embriao e não tenha conclusao pela calculadora
    if(!this.embriao && this.calcFrase == 'unCheck'){
        this.conclusao[2].visivel = true;
        this.conclusao[2].value = this.mascaraBiometria;
    }
    //Controla a função pois tem prioridade de resultado, logo caso ele exista, o value deve ser gerado pela embriao e atribuido no conclusao[0]
    if(this.ehEmbriao){
      this.conclusao[1].visivel = true;
      this.conclusao[2].visivel = false;
    }

    for (i = 0; i < this.conclusao.length; i++) {
      var hapVid = localStorage.getItem('hapVida')
      if (hapVid) {
        this.conclusao[4].visivel = false;
      }
      if (this.conclusao[i].value && this.conclusao[i].visivel == true) {
        this.htmlContent += '<br/>' + this.conclusao[i].value + '<br/>';
      }
    }

    this.htmlContent += this.RetornaConclusaoLista();

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    calculadora,
    tecnica
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricao.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusao.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(tecnica !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);
          let tec = `<div style="text-align: center;"><b>ULTRASSONOGRAFIA OBSTÉTRICA ${tecnica.toUpperCase()}</b></div>`
          if(footer) {
						footer.innerHTML = tec.toUpperCase();
          }
        }

        if(calculadora !== undefined) {
          const calc = document.querySelector(`#e-${blockId} p`);

          if(calc) {
            calc.innerHTML = calculadora;
          }
        }
			} else if(indicacao !== undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }

			this._addText.lineHeight();
    }
  }

  RetornaconclusaoNull(Texto) {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  RetornaConclusaoLista() {
    let texto = `<br/>
            <div style='margin-left: 20px;'><p><small>
              1. Sugere-se à critério clínico, exame morfológico de 1º trimestre entre 11 semanas e 13 semanas e 6 dias.<br/>
            </small></p></div>`;
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  ativarImpressaoEmbriao($event){
    if($event.ativo){
      this.impressaoAtivaEmbriao=true;
      this.blocos[6].blocked=false;
    }else{
      this.impressaoAtivaEmbriao=false;
      this.blocos[6].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoSaco($event){
    if($event.ativo){
      this.impressaoAtivaSaco=true;
      this.blocos[5].blocked=false;
    }else{
      this.impressaoAtivaSaco=false;
      this.blocos[5].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressao(){
    if(this.impressaoAtivaSaco && this.impressaoAtivaEmbriao)
      this._controlImprimir.habilitarImprimir();
    else
      this._controlImprimir.desabilitarImprimir();
  }
}
