import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-carotida-dialog',
  templateUrl: './carotida-dialog.component.html',
  styleUrls: ['./carotida-dialog.component.css']
})
export class CarotidaDialogComponent {

  @ViewChild('ckTabela') ckTabela: MatCheckbox;

  displayedColumns =
    ['dados', 'vpsAci', 'vdf', 'vpsAcc', 'placa'];
  dataSource = ELEMENT_DATA;

  displayedFooter = ['Informações mais importantes em destaque'];

  constructor(
    public dialogRef: MatDialogRef<CarotidaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) {
  }

  ClickNao(): void {
    this.dialogRef.close(this.ckTabela.checked);
  }
}

export interface RinsRefElement {
  dados: string;
  vpsAci: string;
  vdf: string;
  vpsAcc: string;
  placa: string;
}

const ELEMENT_DATA: RinsRefElement[] = [
  { dados: 'Normal', vpsAci: '< 125', vdf: '< 40', vpsAcc: '< 2', placa: 'Nenhuma' },
  { dados: '< 50%', vpsAci: '< 125', vdf: '< 40', vpsAcc: '< 2', placa: '< 50' },
  { dados: '50-69%', vpsAci: '125-230', vdf: '40-100', vpsAcc: '2-4', placa: '≥ 50' },
  { dados: '≥ 70%', vpsAci: '> 230', vdf: '> 100', vpsAcc: '> 4', placa: '≥ 50' },
  { dados: 'Suboclusão', vpsAci: 'Alta, baixa ou não detectável', vdf: 'Variável', vpsAcc: 'Variável', placa: 'Variável' },
  { dados: 'Oclusão', vpsAci: 'Não detectável', vdf: 'Não aplicável', vpsAcc: 'Não aplicável', placa: 'Não aplicável' }
];
