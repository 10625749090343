import { Injectable } from '@angular/core';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { MedicalReport } from '../models/BO/MedicalReport';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }
  private app: any;
  private userid;
  private analytics;

  initFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyAPEolqQh-XGC9HhgE9Sr-KJS4xsb3fqss",
      authDomain: "n-report-apps.firebaseapp.com",
      projectId: "n-report-apps",
      storageBucket: "n-report-apps.appspot.com",
      messagingSenderId: "118378609373",
      appId: "1:118378609373:web:eb76091982ce9054a9b934",
      measurementId: "G-CFNYNTQT9W"
    };
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
    document.addEventListener("visibilitychange", this.visibilityChanged);
  }

  visibilityChanged() {
    if (document.visibilityState === 'visible' && +localStorage.getItem('userID') != 0) {
      this.analytics = getAnalytics(this.app);
      logEvent(this.analytics, 'manual_user_engagement', { client: 'Hapvida', id_user: +localStorage.getItem('userID') });
    }
  }

  setUserProperties(): void {
    setUserProperties(this.analytics, { client: 'Hapvida', user_id: this.userid });
  }

  verificaAcessoDiario() {
    let firstTime = new Date().setUTCHours(0, 0, 0, 0);

    if (localStorage.getItem('firstTime') == null) {
      localStorage.setItem('firstTime', firstTime.toString());
      logEvent(this.analytics, 'manual_session_start', { client: 'Hapvida', id_user: this.userid });
    } else {
      var secondTime = new Date().setUTCHours(0, 0, 0, 0)
      if (secondTime != +firstTime) {
        logEvent(this.analytics, 'manual_session_start', { client: 'Hapvida', id_user: this.userid });
      }
    }
  }

  logDefault(log: string) {
    logEvent(this.analytics, log, {
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logLogin() {
    this.userid = +localStorage.getItem('userID');
    this.analytics = getAnalytics(this.app);
    logEvent(this.analytics, 'manual_login', { client: 'Hapvida', id_user: this.userid });
  }

  logLogoff() {
    logEvent(this.analytics, 'manual_logoff', { client: 'Hapvida', id_user: this.userid });
  }

  logMedicalReport(medicalReport: any, isCopyHtml: boolean, clicks: number) {
    logEvent(this.analytics, 'register_medical_report', {
      medical_report_id: [medicalReport.id],
      medical_report_type_name: [medicalReport.title],
      client: 'Hapvida',
      id_user: this.userid,
      clicks_qty: clicks,
      //editor_check: false,
      copy_html_check: isCopyHtml,
    });
  }

  logEnterMedicalReport(entrada: string) {
    logEvent(this.analytics, 'enter_medical_report', {
      which_enter: entrada,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logExamType(examType: string) {
    logEvent(this.analytics, 'choose_exam_type', {
      exam_type: examType,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logSpeciality(speciality: string) {
    logEvent(this.analytics, 'choose_speciality', {
      speciality: speciality,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logSearchForExam(search: string) {
    logEvent(this.analytics, 'search_for_exam', {
      search: search,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logSendWhatsapp(whichSend: string) {
    logEvent(this.analytics, 'send_whatsapp', {
      which_send: whichSend,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logSendEmail(subject: string) {
    logEvent(this.analytics, 'send_email', {
      subject: subject,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logFeedback(feedback: string, rate: number) {
    logEvent(this.analytics, 'send_feedback', {
      stars: rate,
      subject: feedback,
      client: 'Hapvida',
      id_user: this.userid,
    });
  }

  logManualPageView() {
    logEvent(this.analytics, 'manual_page_view', {
      client: 'B2C',
      id_user: this.userid,
    });
  }

  logGenerateSenha() {
    logEvent(this.analytics, 'generate_new_password', {
      client: 'B2C',
      id_user: this.userid,
    });
  }
}
