import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ControladorFrasesLaudoService {

constructor() { }
  verificarSeContemTermoSelecionado(listTerm, termo){
  let contemTermoSelecionado;
  let contemTermoSelecionadoListTerm;
  if(listTerm.injuries.length!==0){
    for (let i = 0; i < listTerm.injuries.length; i++) {
       contemTermoSelecionado = listTerm.injuries[i].listInjuries.find(item=> item == termo);
    }
  }
  contemTermoSelecionadoListTerm = listTerm.listTermID.find(item=> item == termo);

  if(contemTermoSelecionado || contemTermoSelecionadoListTerm)
    return false;
  else
    return true;
  }
}
