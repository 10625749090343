import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-abdome',
  templateUrl: './mst-abdome.component.html',
  styleUrls: ['./mst-abdome.component.css']
})
export class MstAbdomeComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slEstomago') slEstomago: MatSelect;
  @ViewChild('slParede') slParede: MatSelect;
  @ViewChild('slFigado') slFigado: MatSelect;
  @ViewChild('slVesicula') slVesicula: MatSelect;
  @ViewChild('slIntestino') slIntestino: MatSelect;
  @ViewChild('slRins') slRins: MatSelect;
  @ViewChild('slBexiga') slBexiga: MatSelect;

  @ViewChild('inOutrasEsto') inOutrasEsto: ElementRef;
  @ViewChild('inAlteradoPar') inAlteradoPar: ElementRef;
  @ViewChild('inAlteradoFig') inAlteradoFig: ElementRef;
  @ViewChild('inAlteradoInt') inAlteradoInt: ElementRef;
  @ViewChild('inOutrasRins') inOutrasRins: ElementRef;
  @ViewChild('inOutrasBex') inOutrasBex: ElementRef;


  showAndHideTerms: any;


  estomagos: ArrayDropDown[] = [
    { value: 'identificado em topografia habitual', viewValue: 'Identificado em topografia habitual' },
    { value: 'Alterado: ', viewValue: 'Alterado' },

  ];

  paredes: ArrayDropDown[] = [
    { value: ' íntegra', viewValue: 'Íntegra' },
    { value: ' com sinais de herniação de conteúdo abdominal através da parede, à direita do cordão umbilical: Gastrosquise', viewValue: 'Sinais de herniação de conteúdo abdominal através da parede, à direita do cordão umbilical' },
    { value: ' com sinais de herniação de conteúdo abdominal, envolto por uma fina membrana, que envolve a inserção do cordão umbilical: Onfalocele', viewValue: 'Sinais de herniação de conteúdo abdominal, envolto por uma fina membrana, que envolve a inserção do cordão umbilical' },
    { value: ' com outras alterações: ', viewValue: 'Outras alterações' },
  ];

  figados: ArrayDropDown[] = [
    { value: 'com ecotextura homogênea, sem calcificações', viewValue: 'Com ecotextura homogênea' },
    { value: 'Alterado: ', viewValue: 'Alterado' },
  ];

  vesiculas: ArrayDropDown[] = [
    { value: 'visibilizada', viewValue: 'Visibilizada' },
    { value: 'Não visibilizada', viewValue: 'Não visibilizada' },
  ];

  intestinos: ArrayDropDown[] = [
    { value: 'de aspecto normal', viewValue: 'Aspecto normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  rins: ArrayDropDown[] = [
    { value: 'com parênquima normal, sem dilatações das pelves renais', viewValue: 'com parênquima normal' },
    { value: 'Alterados: ', viewValue: 'Alterados' }

  ];

  bexigas: ArrayDropDown[] = [
    { value: 'em topografia usual e de dimensões normais', viewValue: 'Aspecto normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }

  ];


  constructor() { }

  ngOnInit() {
    this.slEstomago.value = this.estomagos[0].value;
    this.slParede.value = this.paredes[0].value;
    this.slFigado.value = this.figados[0].value;
    this.slVesicula.value = this.vesiculas[0].value;
    this.slIntestino.value = this.intestinos[0].value;
    this.slRins.value = this.rins[0].value;
    this.slBexiga.value = this.bexigas[0].value;

    this.showAndHideTerms = {
      sahAlteradoEsto: false,
      sahAlteradoPar: false,
      sahAlteradoFig: false,
      sahAlteradoVes: false,
      sahAlteradoInt: false,
      sahAlteradoRins: false,
      sahAlteradoBex: false
    }
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    let elemento;
    if (this.slEstomago && this.slEstomago.value) {
      if (this.inOutrasEsto && this.slEstomago.value == 'Alterado: ')
        elemento = { id: this.slEstomago.id, value: `: ${this.inOutrasEsto.nativeElement.value}` };
      else
        elemento = { id: this.slEstomago.id, value: this.slEstomago.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slParede && this.slParede.value) {
      if (this.inAlteradoPar && this.slParede.value == 'Outras alterações: ')
        elemento = { id: this.slParede.id, value: this.inAlteradoPar.nativeElement.value };
      else
        elemento = { id: this.slParede.id, value: this.slParede.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slFigado && this.slFigado.value) {
      if (this.inAlteradoFig && this.slFigado.value == 'Alterado: ')
        elemento = { id: this.slFigado.id, value: this.inAlteradoFig.nativeElement.value };
      else
        elemento = { id: this.slFigado.id, value: this.slFigado.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slVesicula && this.slVesicula.value) {
      const objeto = { elemento: this.slVesicula, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slIntestino && this.slIntestino.value) {
      if (this.inAlteradoInt && this.slIntestino.value == 'Alterado: ')
        elemento = { id: this.slIntestino.id, value: this.inAlteradoInt.nativeElement.value };
      else
        elemento = { id: this.slIntestino.id, value: this.slIntestino.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slRins && this.slRins.value) {
      if (this.inOutrasRins && this.slRins.value == 'Alterados: ')
        elemento = { id: this.slRins.id, value: this.inOutrasRins.nativeElement.value };
      else
        elemento = { id: this.slRins.id, value: this.slRins.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slBexiga && this.slBexiga.value) {
      if (this.inOutrasBex && this.slBexiga.value == 'Alterado: ')
        elemento = { id: this.slBexiga.id, value: this.inOutrasBex.nativeElement.value };
      else
        elemento = { id: this.slBexiga.id, value: this.slBexiga.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }


    this.listaCompleta.emit();

  }

  verificaAlterado() {
    if (this.slEstomago.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoEsto = true;
    } else {
      this.showAndHideTerms.sahAlteradoEsto = false;
    }

    if (this.slParede.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoPar = true;
    } else {
      this.showAndHideTerms.sahAlteradoPar = false;
    }

    if (this.slFigado.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoFig = true;
    } else {
      this.showAndHideTerms.sahAlteradoFig = false;
    }

    if (this.slIntestino.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoInt = true;
    } else {
      this.showAndHideTerms.sahAlteradoInt = false;
    }

    if (this.slRins.value === 'Alterados: ') {
      this.showAndHideTerms.sahAlteradoRins = true;
    } else {
      this.showAndHideTerms.sahAlteradoRins = false;
    }

    if (this.slBexiga.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoBex = true;
    } else {
      this.showAndHideTerms.sahAlteradoBex = false;
    }


  }

}
