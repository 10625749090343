import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';
import { emit } from 'cluster';

@Component({
  selector: 'app-traqueia-bronquios',
  templateUrl: './traqueia-bronquios.component.html',
  styleUrls: ['./traqueia-bronquios.component.css']
})
export class TraqueiaBronquiosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckBronco') ckBronco: MatCheckbox;
  @ViewChild('ckImpaccaoBronco') ckImpaccaoBronco: MatCheckbox;

  @ViewChild('ckBroncolocal') ckBroncolocal: MatCheckbox;
  @ViewChild('slLobos') slLobos: MatSelect;
  @ViewChild('ckImpaccaoLocal') ckImpaccaoLocal: MatCheckbox;

  @ViewChild('ckTRaqueo') ckTRaqueo: MatCheckbox;

  @ViewChild('ckBroncoLeve') ckBroncoLeve: MatCheckbox;

  @ViewChild('ckSecrecao') ckSecrecao: MatCheckbox;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  lobos: ArrayDropDown[] = [
    { value: 'no lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'no lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'no lobo médio', viewValue: 'Lobo médio' },
    { value: 'no lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'no lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
    { value: 'na língula', viewValue: 'Língula' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'traqueais', viewValue: 'Traqueais' },
    { value: 'brônquicas', viewValue: 'Brônquicas' },
    { value: 'traqueais e brônquicas', viewValue: 'Traqueais e brônquicas' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahBronco: false,
      sahBroncoLocal: false,
      sahSecrecao: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahBronco = false;
    this.showAndHideTerms.sahBroncoLocal = false;
    this.showAndHideTerms.sahSecrecao = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahBronco = false;
    this.showAndHideTerms.sahBroncoLocal = false;
    this.showAndHideTerms.sahSecrecao = false;
    this.catchIDs();
  }

  clickBronco() {
    if (this.showAndHideTerms.sahBronco === false)
      this.showAndHideTerms.sahBronco = true;
    else
      this.showAndHideTerms.sahBronco = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickBroncoLocal() {
    if (this.showAndHideTerms.sahBroncoLocal === false)
      this.showAndHideTerms.sahBroncoLocal = true;
    else
      this.showAndHideTerms.sahBroncoLocal = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickTraqueo() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clicksecrecao() {
    if (this.showAndHideTerms.sahSecrecao === false)
      this.showAndHideTerms.sahSecrecao = true;
    else
      this.showAndHideTerms.sahSecrecao = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickBronqLeve() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckBronco && this.ckBronco.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckBronco.id);
        if (this.ckImpaccaoBronco && this.ckImpaccaoBronco.checked) {
          this.termoSelecionado.emit(this.ckImpaccaoBronco.id);
        }
      }

      if (this.countLesoes > 0)
        this.emitBroncoLeveLesao();
      else
        this.emitBroncoLeve();

      if (this.countLesoes > 0)
        this.emitBroncoLocalLesao();
      else
        this.emitBroncoLocal();

      if (this.countLesoes > 0)
        this.emitSecrecoesLesao();
      else
        this.emitSecrecoes();

      if (this.ckTRaqueo && this.ckTRaqueo.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckTRaqueo.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckTRaqueo.id);
        }
      }
    }

    this.listaCompleta.emit();
  }

  emitBroncoLeve() {
    if (this.ckBroncoLeve && this.ckBroncoLeve.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckBroncoLeve.id);
    }
  }

  emitBroncoLeveLesao() {
    if (this.ckBroncoLeve && this.ckBroncoLeve.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckBroncoLeve.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitBroncoLocal() {
    if (this.ckBroncolocal && this.ckBroncolocal.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckBroncolocal.id);

      if (this.ckImpaccaoLocal && this.ckImpaccaoLocal.checked) {
        this.termoSelecionado.emit(this.ckImpaccaoLocal.id);
      }

      if (this.slLobos && this.slLobos.value) {
        const objeto = { elemento: this.slLobos, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitBroncoLocalLesao() {
    if (this.ckBroncolocal && this.ckBroncolocal.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckBroncolocal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckImpaccaoLocal && this.ckImpaccaoLocal.checked) {
        const objeto = { id: this.ckImpaccaoLocal.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.slLobos && this.slLobos.value) {
        const objeto = { elemento: this.slLobos, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitSecrecoes() {
    if (this.ckSecrecao && this.ckSecrecao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSecrecao.id);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitSecrecoesLesao() {
    if (this.ckSecrecao && this.ckSecrecao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckSecrecao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckBronco.checked &&
      !this.ckBroncolocal.checked &&
      !this.ckTRaqueo.checked &&
      !this.ckBroncoLeve.checked &&
      !this.ckSecrecao.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
