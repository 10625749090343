import { NgModule } from '@angular/core';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTableDataSource,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';

import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { CervicalDopplerComponent } from './cervical-doppler.component';
import { ParotidaDireitaComponent } from './TermosComponent/parotida-direita/parotida-direita.component';
import { ParotidaEsquerdaComponent } from './TermosComponent/parotida-esquerda/parotida-esquerda.component';
import { SubmandibularDireitaComponent } from './TermosComponent/submandibular-direita/submandibular-direita.component';
import { SubmandibularEsquerdaComponent } from './TermosComponent/submandibular-esquerda/submandibular-esquerda.component';
import { VeiasJugularesComponent } from './TermosComponent/veias-jugulares/veias-jugulares.component';
import { CervicalModule } from '../../modo-b/cervical/cervical.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { TireoideDopplerModule } from '../tireoide-doppler/tireoide-doppler.module';
import { TireoideDopplerOrgaoComponent } from '../tireoide-doppler/TermosComponents/tireoide-doppler-orgao/tireoide-doppler-orgao.component';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';




@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatDialogModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    CervicalModule,
    TireoideDopplerModule,
    SharedModule, 
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    CervicalDopplerComponent
  ],
  entryComponents: [
  ],
  declarations: [
    CervicalDopplerComponent,
    ParotidaDireitaComponent,
    ParotidaEsquerdaComponent,
    SubmandibularDireitaComponent,
    SubmandibularEsquerdaComponent,
    VeiasJugularesComponent,

  ]
})
export class CervicalDopplerModule { }
