import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-arterias-cavernosas',
  templateUrl: './arterias-cavernosas.component.html',
  styleUrls: ['./arterias-cavernosas.component.css']
})
export class ArteriasCavernosasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() tabelaTexto = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdnaocitar') rdnaocitar: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('ckTabela') ckTabela: MatCheckbox;

  // Direita
  @ViewChild('VelSisDir') VelSisDir: ElementRef;
  @ViewChild('VelDisDir') VelDisDir: ElementRef;
  @ViewChild('AceleracaoDir') AceleracaoDir: ElementRef;

  // Esquerda
  @ViewChild('VelSisEsq') VelSisEsq: ElementRef;
  @ViewChild('VelDisEsq') VelDisEsq: ElementRef;
  @ViewChild('AceleracaoEsq') AceleracaoEsq: ElementRef;

  //Calibre
  @ViewChild('ckCitarCalibres') ckCitarCalibres: MatCheckbox;
  @ViewChild('CalibreEsq') CalibreEsq: ElementRef;
  @ViewChild('CalibreDir') CalibreDir: ElementRef;

  //Citar IR
  @ViewChild('ckCitarIR') ckCitarIR: MatCheckbox;
  @ViewChild('IREsq') IREsq: ElementRef;
  @ViewChild('IRDir') IRDir: ElementRef;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false,
      sahCalibres: false,
      sahIR: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clickNaoCitar(): any {
    this.showAndHideTerms.sahCitar = false;
    this.showAndHideTerms.sahCalibres = false;
    this.showAndHideTerms.sahIR = false;
    this.catchIDs();
  }

  public clickCitar(): any {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  public clickNormal() {
    this.showAndHideTerms.sahCitar = false;
    this.showAndHideTerms.sahCalibres = false;
    this.showAndHideTerms.sahIR = false;
    this.catchIDs();
  }


  clickCitarCalibres() {
    if (this.showAndHideTerms.sahCalibres == false) {
      this.showAndHideTerms.sahCalibres = true;
    } else {
      this.showAndHideTerms.sahCalibres = false;
    }
    this.catchIDs();
  }

  clickCitarIR() {
    if (this.showAndHideTerms.sahIR == false) {
      this.showAndHideTerms.sahIR = true;
    } else {
      this.showAndHideTerms.sahIR = false;
    }
    this.catchIDs();
  }


  public blurMedida(): any {
    this.catchIDs();
    this.tabelaTexto.emit('element');
  }

  public catchIDs() {
    this.enviaMedida.emit('Clean');
    this.termoSelecionado.emit('Clean');

    if (this.rdnaocitar && this.rdnaocitar.checked) {
      const element = { value: '', id: this.rdnaocitar.id }
      this.enviaMedida.emit(element);
    }

    if (this.rdNormal && this.rdNormal.checked) {
      const element = { value: '', id: this.rdNormal.id }
      this.enviaMedida.emit(element);
      this.termoSelecionado.emit(this.rdNormal.value);
    } else{
      this.termoSelecionado.emit(this.rdnaocitar.value);
    }

    //direita
    if (this.ckTabela && this.ckTabela.checked) {
      if (this.VelSisDir && this.VelSisDir.nativeElement.value) {
        const element = { value: this.VelSisDir.nativeElement.value, id: this.VelSisDir.nativeElement.id }
        this.enviaMedida.emit(element);
      }
      if (this.VelDisDir && this.VelDisDir.nativeElement.value) {
        const element = { value: this.VelDisDir.nativeElement.value, id: this.VelDisDir.nativeElement.id }
        this.enviaMedida.emit(element);
      }
      if (this.AceleracaoDir && this.AceleracaoDir.nativeElement.value) {
        const element = { value: this.AceleracaoDir.nativeElement.value, id: this.AceleracaoDir.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      //esquerda
      if (this.VelSisEsq && this.VelSisEsq.nativeElement.value) {
        const element = { value: this.VelSisEsq.nativeElement.value, id: this.VelSisEsq.nativeElement.id }
        this.enviaMedida.emit(element);
      }
      if (this.VelDisEsq && this.VelDisEsq.nativeElement.value) {
        const element = { value: this.VelDisEsq.nativeElement.value, id: this.VelDisEsq.nativeElement.id }
        this.enviaMedida.emit(element);
      }
      if (this.AceleracaoEsq && this.AceleracaoEsq.nativeElement.value) {
        const element = { value: this.AceleracaoEsq.nativeElement.value, id: this.AceleracaoEsq.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ckCitarCalibres) {
        const element = { value: this.ckCitarCalibres.checked, id: this.ckCitarCalibres.id }
        this.enviaMedida.emit(element);
        if (this.ckCitarCalibres.checked) {
          if (this.CalibreEsq && this.CalibreEsq.nativeElement.value) {
            const element = { value: this.CalibreEsq.nativeElement.value, id: this.CalibreEsq.nativeElement.id }
            this.enviaMedida.emit(element);
          }
          if (this.CalibreDir && this.CalibreDir.nativeElement.value) {
            const element = { value: this.CalibreDir.nativeElement.value, id: this.CalibreDir.nativeElement.id }
            this.enviaMedida.emit(element);
          }
        }
      }

      if (this.ckCitarIR) {
        const element = { value: this.ckCitarIR.checked, id: this.ckCitarIR.id }
        this.enviaMedida.emit(element);
        if (this.ckCitarIR.checked) {
          if (this.IREsq && this.IREsq.nativeElement.value) {
            const element = { value: this.IREsq.nativeElement.value, id: this.IREsq.nativeElement.id }
            this.enviaMedida.emit(element);
          }
          if (this.IRDir && this.IRDir.nativeElement.value) {
            const element = { value: this.IRDir.nativeElement.value, id: this.IRDir.nativeElement.id }
            this.enviaMedida.emit(element);
          }
        }
      }
    }
    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }
}
