import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Plano } from '../models/BO/Plano';
import { UserHasPlan } from '../models/BO/UserHasPlan';
import { Speciality } from '../models/BO/Speciality';
import { ListaPlanosFavoritosService } from './lista-planos-favoritos.service';

@Injectable({
  providedIn: 'root'
})
export class ListaPlanosService {

  planoData = new BehaviorSubject<Speciality[][]>(null);
  planoDoUsuario = new BehaviorSubject<Array<UserHasPlan>>(null);

  constructor(private http: HttpClient, private _favoritosService: ListaPlanosFavoritosService) { }

  buscarPlanos(): Observable<Plano[]> {
    return this.http.get<Plano[]>(environment.nLaudoApi + "Plan/Plan/");
  }
  buscarPlanosVoucher(): Observable<Plano[]> {
    return this.http.get<Plano[]>(environment.nLaudoApi + "Plan/PlanVoucher/");
  }

  getPlano(): Observable<Plano> {
    return this.http.get<Plano>(environment.nLaudoApi + "Plan/Plan/");
  }

  buscarPlanosPorUsuario(userID: number): Observable<Array<Array<Speciality>>> {
    return this.http.get<Array<Array<Speciality>>>(environment.nLaudoApi + "Plan/BuscaPlanoPorUsuario/" + userID);
  }

  async getPlanosPorUsuario(): Promise<Speciality[][]> {
    if (this.planoData.getValue()) {
      return this.planoData.getValue();
    } else {
      const planos = await this.buscarPlanosPorUsuario(+localStorage.getItem('userID')).toPromise();
      // const favoritos = await this._favoritosService.buscarFavsPorUsuario(+localStorage.getItem('userID')).toPromise();
      for (const plano of planos) {
        for (const exame of plano) {
          // for (const favorito of favoritos) {
          //   if (exame.url === favorito.url) {
          //     exame.favorite = true;
          //   }
          // }
        }
      }
      this.planoData.next(planos);
      return this.planoData.getValue();
    }
  }

  async getPlanoDousuario(): Promise<Array<UserHasPlan>>{
    if(this.planoDoUsuario.getValue()){
      return this.planoDoUsuario.getValue();
    } else {
      const planos = await this.buscaPlanoDoUsuario(+localStorage.getItem('userID')).toPromise();
      this.planoDoUsuario.next(planos);
      return this.planoDoUsuario.getValue();
    }
  }

  buscaPlanoDoUsuario(userID: number): Observable<Array<UserHasPlan>> {
    return this.http.get<Array<UserHasPlan>>(environment.nLaudoApi + `Plan/PlanByUser/${userID}`);
  }

  buscarFavoritosPorUsuario(userID: number): Observable<Array<Speciality>> {
    return this.http.get<Array<Speciality>>(environment.nLaudoApi + "Plan/BuscaFavoritosPorUsuario/" + userID);
  }
  buscarPlanosPorVoucher(voucherID: number): Observable<Plano> {
    return this.http.get<Plano>(environment.nLaudoApi + "Plan/BuscaPlanoPorVoucher/" + voucherID);
  }
}
