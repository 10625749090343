import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-paralisia-diafragmatica',
  templateUrl: './paralisia-diafragmatica.component.html',
  styleUrls: ['./paralisia-diafragmatica.component.css']
})
export class ParalisiaDiafragmaticaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('rdParcial') rdParcial: MatRadioButton;
  @ViewChild('rdCompleta') rdCompleta: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;

  lados: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    }
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  clickParcial() {
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCompleta() {
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);
      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.rdParcial && this.rdParcial.checked) {
        this.termoSelecionado.emit(this.rdParcial.id);
      }
      if (this.rdCompleta && this.rdCompleta.checked) {
        this.termoSelecionado.emit(this.rdCompleta.id);
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  verificaAlterado() {
    if (this.slLado.value ||
      this.rdParcial.checked ||
      this.rdCompleta.checked) {
      // Marca Presente
      this.rdPresente.checked = true;
    }
  }

}
