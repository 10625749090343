import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-densidade-ossea',
  templateUrl: './densidade-ossea.component.html',
  styleUrls: ['./densidade-ossea.component.css']
})
export class DensidadeOsseaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdRarefacaoOssea') rdRarefacaoOssea: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }
  clickDefault(){
    this.catchIDs();
  }
  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    } else {
      this.termoSelecionado.emit(this.rdRarefacaoOssea.id);
    }

    this.listaCompleta.emit();
  }
}
