import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Validacoes} from '../../../../../../utils/validacoes';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-prostata-orgao',
  templateUrl: './prostata-orgao.component.html',
  styleUrls: ['./prostata-orgao.component.css']
})
export class ProstataOrgaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdDimensoesAumentadas') rdDimensoesAumentadas: MatRadioButton;

  // Dimensoes aumentadas
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inVolume') inVolume: ElementRef;
  @ViewChild('ckAbaulando') ckAbaulando: MatCheckbox;

  @ViewChild('ckCalciGrosseiras') ckCalciGrosseiras: MatCheckbox;
  @ViewChild('inIndice') inIndice: ElementRef;

  volume = 'Volume';
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedidas: true,
      sahDimensoesAumentas: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahMedidas = true;
    this.showAndHideTerms.sahDimensoesAumentas = false;
    this.catchIDs();
  }
  
  clickNaoCitar() {
    this.showAndHideTerms.sahMedidas = true;
    this.showAndHideTerms.sahDimensoesAumentas = false;
    this.catchIDs();
  }

  clickAusente() {
    this.showAndHideTerms.sahMedidas = false;
    this.showAndHideTerms.sahDimensoesAumentas = false;
    this.catchIDs();
  }

  clickDimensoesAumentadas() {
    this.showAndHideTerms.sahMedidas = true;
    this.showAndHideTerms.sahDimensoesAumentas = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickGeneric() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      this.formataMedindo();
      if (this.inVolume !== undefined &&
        this.inVolume.nativeElement.id !== undefined &&
        this.inVolume.nativeElement.value !== '' ||
        this.inVolume !== undefined && this.volume !== '') {

        if(this.inMedida1.nativeElement.value != '' || this.inMedida2.nativeElement.value != '' || this.inMedida3.nativeElement.value != ''){
          if (!this.inVolume.nativeElement.value) {
            const elemento = {id: 691, value: this.volume};
            const objeto = {elemento: elemento, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = {elemento: this.inVolume.nativeElement, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
            this.volume = 'Volume';
          }
        }
        
      }

      if (this.ckCalciGrosseiras != undefined && this.ckCalciGrosseiras.checked) {
        this.termoSelecionado.emit(this.ckCalciGrosseiras.id);
      }

    }

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);

      this.formataMedindo();

      if (this.inVolume !== undefined &&
        this.inVolume.nativeElement.id !== undefined &&
        this.inVolume.nativeElement.value !== '' ||
        this.inVolume !== undefined && this.volume !== '') {

        if(this.inMedida1.nativeElement.value != '' || this.inMedida2.nativeElement.value != '' || this.inMedida3.nativeElement.value != ''){
          if (!this.inVolume.nativeElement.value) {
            const elemento = {id: 691, value: this.volume};
            const objeto = {elemento: elemento, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = {elemento: this.inVolume.nativeElement, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
            this.volume = 'Volume';
          }
        }
      }

      if (this.ckCalciGrosseiras != undefined && this.ckCalciGrosseiras.checked) {
        this.termoSelecionado.emit(this.ckCalciGrosseiras.id);
      }

    }

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdDimensoesAumentadas.checked) {
      this.termoSelecionado.emit(this.rdDimensoesAumentadas.id);

      this.formataMedindo();

      if (this.inVolume !== undefined &&
        this.inVolume.nativeElement.id !== undefined &&
        this.inVolume.nativeElement.value !== '' ||
        this.inVolume !== undefined && this.volume !== '') {

        if(this.inMedida1.nativeElement.value != '' || this.inMedida2.nativeElement.value != '' || this.inMedida3.nativeElement.value != ''){
          if (!this.inVolume.nativeElement.value) {
            const elemento = {id: 691, value: this.volume};
            const objeto = {elemento: elemento, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = {elemento: this.inVolume.nativeElement, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
            this.volume = 'Volume';
          }
        }
      }

      if (this.ckCalciGrosseiras != undefined && this.ckCalciGrosseiras.checked) {
        this.termoSelecionado.emit(this.ckCalciGrosseiras.id);
      }

      if (this.ckAbaulando != undefined && this.ckAbaulando.checked) {
        this.termoSelecionado.emit(this.ckAbaulando.id);
      }

      if (this.inIndice != undefined &&
        this.inIndice.nativeElement.id != undefined &&
        this.inIndice.nativeElement.value != '') {

        const objeto = {elemento: this.inIndice.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }


  formataMedindo() {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedida1 != undefined &&
      this.inMedida1.nativeElement.id != undefined &&
      this.inMedida1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
      resultado = +this.inMedida1.nativeElement.value;
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
        resultado = +resultado * +this.inMedida2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
        resultado = +this.inMedida2.nativeElement.value;
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
        resultado = +resultado * +this.inMedida3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
        resultado = +this.inMedida3.nativeElement.value;
      }
    }

    if (medindoValor != '') {

      resultado = resultado * 0.523;
      this.volume = resultado.toFixed(1).toString();

      const objetoElemento = {id: 690, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    } else {
      this.volume = 'Volume';
    }
  }

}
