import { EcoGlandularComponent } from './termosComponents/eco-glandular/eco-glandular.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule,
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { CervicometriaComponent } from './cervicometria.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { CerColoUterinoComponent } from './termosComponents/cer-colo-uterino/cer-colo-uterino.component';
import { CerSludgeComponent } from './termosComponents/cer-sludge/cer-sludge';
import { CerPlacentaComponent } from './termosComponents/cer-placenta/cer-placenta.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    ObsCalculadoraModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    CervicometriaComponent,
    CerColoUterinoComponent,
    CerSludgeComponent
  ],
  declarations: [CervicometriaComponent, CerColoUterinoComponent, CerSludgeComponent, CerPlacentaComponent, EcoGlandularComponent]
})
export class CervicometriaModule { }
