import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-tabela-varizes',
  templateUrl: './tabela-varizes.component.html',
  styleUrls: ['./tabela-varizes.component.css']
})
export class TabelaVarizesComponent implements OnInit {

  showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdnaocitar') rdnaocitar: MatRadioButton;
  @ViewChild('rdcitar') rdcitar: MatRadioButton;

  @ViewChild('Magna') Magna: ElementRef;
  @ViewChild('TercoProxCoxaMagna') TercoProxCoxaMagna: ElementRef;
  @ViewChild('TercoMediCoxaMagna') TercoMediCoxaMagna: ElementRef;
  @ViewChild('TercoDistCoxaMagna') TercoDistCoxaMagna: ElementRef;
  @ViewChild('TercoProxPernaMagna') TercoProxPernaMagna: ElementRef;
  @ViewChild('TercoMediPernaMagna') TercoMediPernaMagna: ElementRef;
  @ViewChild('TercoDistPernaMagna') TercoDistPernaMagna: ElementRef;
  @ViewChild('TercoProxPernaParva') TercoProxPernaParva: ElementRef;
  @ViewChild('TercoMedPernaParva') TercoMedPernaParva: ElementRef;
  @ViewChild('TercoDistPernaParva') TercoDistPernaParva: ElementRef;

  unidadeMedida: string;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public ClickNaoCitar(): any {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();
  }

  public ClickCitar(): any {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  public blurMedida(): any {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.Magna && this.Magna.nativeElement.value) || 
    (this.TercoProxCoxaMagna && this.TercoProxCoxaMagna.nativeElement.value) || 
    (this.TercoMediCoxaMagna && this.TercoMediCoxaMagna.nativeElement.value) || 
    (this.TercoDistCoxaMagna && this.TercoDistCoxaMagna.nativeElement.value) || 
    (this.TercoProxPernaMagna && this.TercoProxPernaMagna.nativeElement.value) || 
    (this.TercoMediPernaMagna && this.TercoMediPernaMagna.nativeElement.value) || 
    (this.TercoDistPernaMagna && this.TercoDistPernaMagna.nativeElement.value) || 
    (this.TercoProxPernaParva && this.TercoProxPernaParva.nativeElement.value) || 
    (this.TercoMedPernaParva && this.TercoMedPernaParva.nativeElement.value) ||
    (this.TercoDistPernaParva && this.TercoDistPernaParva.nativeElement.value)){
      this.rdcitar.checked = true;
    }
  }

  public catchIDs(): any {
    this.enviaMedida.emit('Clean');

    if (this.rdnaocitar && this.rdnaocitar.checked) {
      const element = { value: '', id: this.rdnaocitar.id }
      this.enviaMedida.emit(element);
    }

    if (this.rdcitar && this.rdcitar.checked) {
      if (this.Magna && this.Magna.nativeElement.value) {
        const element = { value: this.Magna.nativeElement.value, id: this.Magna.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.TercoProxCoxaMagna && this.TercoProxCoxaMagna.nativeElement.value) {
        const element = { value: this.TercoProxCoxaMagna.nativeElement.value, id: this.TercoProxCoxaMagna.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.TercoMediCoxaMagna && this.TercoMediCoxaMagna.nativeElement.value) {
        const element = { value: this.TercoMediCoxaMagna.nativeElement.value, id: this.TercoMediCoxaMagna.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.TercoDistCoxaMagna && this.TercoDistCoxaMagna.nativeElement.value) {
        const element = { value: this.TercoDistCoxaMagna.nativeElement.value, id: this.TercoDistCoxaMagna.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.TercoProxPernaMagna && this.TercoProxPernaMagna.nativeElement.value) {
        const element = { value: this.TercoProxPernaMagna.nativeElement.value, id: this.TercoProxPernaMagna.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.TercoMediPernaMagna && this.TercoMediPernaMagna.nativeElement.value) {
        const element = { value: this.TercoMediPernaMagna.nativeElement.value, id: this.TercoMediPernaMagna.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.TercoDistPernaMagna && this.TercoDistPernaMagna.nativeElement.value) {
        const element = { value: this.TercoDistPernaMagna.nativeElement.value, id: this.TercoDistPernaMagna.nativeElement.id };
        this.enviaMedida.emit(element);
      }
      if (this.TercoProxPernaParva && this.TercoProxPernaParva.nativeElement.value) {
        const element = { value: this.TercoProxPernaParva.nativeElement.value, id: this.TercoProxPernaParva.nativeElement.id };
        this.enviaMedida.emit(element);
      }
      if (this.TercoMedPernaParva && this.TercoMedPernaParva.nativeElement.value) {
        const element = { value: this.TercoMedPernaParva.nativeElement.value, id: this.TercoMedPernaParva.nativeElement.id };
        this.enviaMedida.emit(element);
      }
      if (this.TercoDistPernaParva && this.TercoDistPernaParva.nativeElement.value) {
        const element = { value: this.TercoDistPernaParva.nativeElement.value, id: this.TercoDistPernaParva.nativeElement.id };
        this.enviaMedida.emit(element);
      }

    }

    this.listaCompleta.emit();
  }

}
