import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-instabilidade-durante-manobras',
  templateUrl: './instabilidade-durante-manobras.component.html',
  styleUrls: ['./instabilidade-durante-manobras.component.css']
})
export class InstabilidadeDuranteManobrasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickAusente(){
    this.catchIDs();
  }
  clickPresente(){
    this.catchIDs();
  }
  
  blurVolume(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked)
      this.termoSelecionado.emit(this.rdAusente.value);

    if (this.rdPresente.checked){
      this.termoSelecionado.emit(this.rdPresente.value);
    }
    

    this.listaCompleta.emit();
  }

}
