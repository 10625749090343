import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-aoili-tabela',
  templateUrl: './aoili-tabela.component.html',
  styleUrls: ['./aoili-tabela.component.css']
})
export class AoiliTabelaComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdnaocitar') rdnaocitar: MatRadioButton;
  @ViewChild('rdcitar') rdcitar: MatRadioButton;

  @ViewChild('Aorta') Aorta: ElementRef;
  @ViewChild('ArtIliComumDireita') ArtIliComumDireita: ElementRef;
  @ViewChild('ArtIliComumEsquerda') ArtIliComumEsquerda: ElementRef;
  @ViewChild('ArtIliExterDireita') ArtIliExterDireita: ElementRef;
  @ViewChild('ArtIliExterEsquerda') ArtIliExterEsquerda: ElementRef;
  @ViewChild('ArtIliInterDireita') ArtIliInterDireita: ElementRef;
  @ViewChild('ArtIliInterEsquerda') ArtIliInterEsquerda: ElementRef;

  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public ClickNaoCitar(): any {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();
  }

  public ClickCitar(): any {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  public blurMedida(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');
    this.enviaMedida.emit('Clean');

    if (this.rdnaocitar && this.rdnaocitar.checked) {
      const element = { value: '', id: this.rdnaocitar.id }
      this.enviaMedida.emit(element);
    }

    if (this.rdcitar && this.rdcitar.checked) {
      if (this.Aorta && this.Aorta.nativeElement.value) {
        const element = { value: this.Aorta.nativeElement.value, id: this.Aorta.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ArtIliComumDireita && this.ArtIliComumDireita.nativeElement.value) {
        const element = { value: this.ArtIliComumDireita.nativeElement.value, id: this.ArtIliComumDireita.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ArtIliComumEsquerda && this.ArtIliComumEsquerda.nativeElement.value) {
        const element = { value: this.ArtIliComumEsquerda.nativeElement.value, id: this.ArtIliComumEsquerda.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ArtIliExterDireita && this.ArtIliExterDireita.nativeElement.value) {
        const element = { value: this.ArtIliExterDireita.nativeElement.value, id: this.ArtIliExterDireita.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ArtIliExterEsquerda && this.ArtIliExterEsquerda.nativeElement.value) {
        const element = { value: this.ArtIliExterEsquerda.nativeElement.value, id: this.ArtIliExterEsquerda.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ArtIliInterDireita && this.ArtIliInterDireita.nativeElement.value) {
        const element = { value: this.ArtIliInterDireita.nativeElement.value, id: this.ArtIliInterDireita.nativeElement.id }
        this.enviaMedida.emit(element);
      }

      if (this.ArtIliInterEsquerda && this.ArtIliInterEsquerda.nativeElement.value) {
        const element = { value: this.ArtIliInterEsquerda.nativeElement.value, id: this.ArtIliInterEsquerda.nativeElement.id }
        this.enviaMedida.emit(element);
      }

    }

    this.listaCompleta.emit();
  }

}
