import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';
import { Validacoes } from '../../../../../utils/validacoes';

@Component({
  selector: 'app-parede-toracica',
  templateUrl: './parede-toracica.component.html',
  styleUrls: ['./parede-toracica.component.css']
})
export class ParedeToracicaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckImplante') ckImplante: MatCheckbox;

  @ViewChild('ckGinecomastia') ckGinecomastia: MatCheckbox;

  @ViewChild('ckTireoide') ckTireoide: MatCheckbox;
  @ViewChild('ckHeterogeneo') ckHeterogeneo: MatCheckbox;
  @ViewChild('ckDimensoes') ckDimensoes: MatCheckbox;
  @ViewChild('ckImgNopdular') ckImgNopdular: MatCheckbox;
  @ViewChild('slLoboTire') slLoboTire: MatSelect;
  @ViewChild('inMedidaTire') inMedidaTire: ElementRef;
  @ViewChild('slLoboHD') slLoboHD: MatSelect;
  @ViewChild('inMedidaHD') inMedidaHD: ElementRef;

  @ViewChild('ckElastofibroma') ckElastofibroma: MatCheckbox;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;
  @ViewChild('inMedidaElas1') inMedidaElas1: ElementRef;
  @ViewChild('inMedidaElas2') inMedidaElas2: ElementRef;

  @ViewChild('ckLinfonodomegalia') ckLinfonodomegalia: MatCheckbox;
  @ViewChild('rd1Linfo') rd1Linfo: MatRadioButton;
  @ViewChild('slCadeias') slCadeias: MatSelect;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;

  @ViewChild('rd2Linfo') rd2Linfo: MatRadioButton;
  @ViewChildren('linfoChildren') linfoChildren: QueryList<any>;

  lateralidades: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }
  ];

  lobos: ArrayDropDown[] = [
    { value: 'lobo direito', viewValue: 'Lobo direito' },
    { value: 'lobo esquerdo', viewValue: 'Lobo esquerdo' },
    { value: 'istmo', viewValue: 'Istmo' }
  ];

  cadeias: ArrayDropDown[] = [
    { value: 'axilar direita', viewValue: 'Axilar direita' },
    { value: 'axilar esquerda', viewValue: 'Axilar esquerda' },
    { value: 'retropeitoral direita', viewValue: 'Retropeitoral direita' },
    { value: 'retropeitoral esquerda', viewValue: 'Retropeitoral esquerda' },
    { value: 'na cadeia torácica interna direita', viewValue: 'Cadeia torácica interna direita' },
    { value: 'na cadeia torácica interna esquerda', viewValue: 'Cadeia torácica interna esquerda' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  obj: any;
  objetofilho: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahElastro: false,
      sahTireoide: false,
      sahImgNodular: false,
      sahLinfo: false,
      sah1Linfo: false,
      sah2Linfo: false,
      sahHeterDimensoes: false,
    };
    this.lesoesForm = this._fb.group({
      linfo: this._fb.array([this.addLinfoGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Dinamico
  addLinfoGrup() {
    return this._fb.group({
      cadeias: [],
      medida: []
    });
  }

  addLinfo() {
    this.linfoArray.push(this.addLinfoGrup());
    this.catchIDs();
  }

  removeLinfo(index) {
    this.linfoArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get linfoArray() {
    return <FormArray>this.lesoesForm.get('linfo');
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahElastro = false;
    this.showAndHideTerms.sahTireoide = false;
    this.showAndHideTerms.sahLinfo = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahElastro = false;
    this.showAndHideTerms.sahLinfo = false;
    this.catchIDs();
  }

  clickGinecomastia() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickTireiode() {
    if (this.showAndHideTerms.sahTireoide === false) {
      this.showAndHideTerms.sahTireoide = true;
      this.showAndHideTerms.sahImgNodular = false;
    } else {
      this.showAndHideTerms.sahTireoide = false;
      this.showAndHideTerms.sahHeterDimensoes = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickImgNodular() {
    if (this.showAndHideTerms.sahImgNodular === false)
      this.showAndHideTerms.sahImgNodular = true;
    else
      this.showAndHideTerms.sahImgNodular = false;

    this.catchIDs();
  }

  clickElastofibroma() {
    if (this.showAndHideTerms.sahElastro === false)
      this.showAndHideTerms.sahElastro = true;
    else
      this.showAndHideTerms.sahElastro = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLinfonodomegalia() {
    if (this.showAndHideTerms.sahLinfo === false) {
      this.showAndHideTerms.sahLinfo = true;
      this.showAndHideTerms.sah1Linfo = true;
      this.showAndHideTerms.sah2Linfo = false;
    } else {
      this.showAndHideTerms.sahLinfo = false;
      this.showAndHideTerms.sah1Linfo = false;
      this.showAndHideTerms.sah2Linfo = false;
    }
    this.rdAlterado.checked = true;
    this.ajustaArrayDinamico();
    this.verificaAlterado();
    this.catchIDs();
  }

  click1Linfonodomegalia() {
    this.showAndHideTerms.sahLinfo = true;
    this.showAndHideTerms.sah1Linfo = true;
    this.showAndHideTerms.sah2Linfo = false;
    this.ajustaArrayDinamico();
  }

  click2Linfonodomegalia() {
    this.showAndHideTerms.sahLinfo = true;
    this.showAndHideTerms.sah2Linfo = true;
    this.showAndHideTerms.sah1Linfo = false;
    this.ajustaArrayDinamico();
  }

  clickDefault() {
    if (this.ckHeterogeneo.checked &&
      this.ckDimensoes.checked &&
      this.showAndHideTerms.sahHeterDimensoes === false) {
      this.showAndHideTerms.sahHeterDimensoes = true;
    } else {
      this.showAndHideTerms.sahHeterDimensoes = false;
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckImplante && this.ckImplante.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckImplante.id);
      }

      if (this.countLesoes > 0)
        this.emitGinecomastiaLesao();
      else
        this.emitGinecomastia();

      if (this.countLesoes > 0)
        this.emitTireoideLesao();
      else
        this.emitTireoide();

      if (this.countLesoes > 0)
        this.emitElastofibromaLesao();
      else
        this.emitElastofibroma();

      if (this.countLesoes > 0)
        this.emitLinfonodoLesao();
      else
        this.emitLinfonodo();
    }

    this.listaCompleta.emit();
  }

  emitGinecomastia() {
    if (this.ckGinecomastia && this.ckGinecomastia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckGinecomastia.id);
    }
  }

  emitGinecomastiaLesao() {
    if (this.ckGinecomastia && this.ckGinecomastia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckGinecomastia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitTireoide() {
    if (this.ckTireoide && this.ckTireoide.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTireoide.id);

      if (this.ckDimensoes && this.ckDimensoes.checked)
        this.termoSelecionado.emit(this.ckDimensoes.id);

      if (this.ckHeterogeneo && this.ckHeterogeneo.checked)
        this.termoSelecionado.emit(this.ckHeterogeneo.id);

      if (this.ckHeterogeneo && this.ckHeterogeneo.checked
        &&
        this.ckDimensoes && this.ckDimensoes.checked) {

        if (this.slLoboHD && this.slLoboHD.value) {
          const objeto = { elemento: this.slLoboHD, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaHD && this.inMedidaHD.nativeElement.value) {
          const objeto = { elemento: this.inMedidaHD.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }

      if (this.ckImgNopdular && this.ckImgNopdular.checked) {
        this.termoSelecionado.emit(this.ckImgNopdular.id);

        if (this.slLoboTire && this.slLoboTire.value) {
          const objeto = { elemento: this.slLoboTire, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaTire && this.inMedidaTire.nativeElement.value) {
          const objeto = { elemento: this.inMedidaTire.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitTireoideLesao() {
    if (this.ckTireoide && this.ckTireoide.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckTireoide.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDimensoes && this.ckDimensoes.checked) {
        const objeto = { id: this.ckDimensoes.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }


      if (this.ckHeterogeneo && this.ckHeterogeneo.checked) {
        const objeto = { id: this.ckHeterogeneo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.ckHeterogeneo && this.ckHeterogeneo.checked
        &&
        this.ckDimensoes && this.ckDimensoes.checked) {

        if (this.slLoboHD && this.slLoboHD.value) {
          const objeto = { elemento: this.slLoboHD, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaHD && this.inMedidaHD.nativeElement.value) {
          const objeto = { elemento: this.inMedidaHD.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

      if (this.ckImgNopdular && this.ckImgNopdular.checked) {
        const objeto = { id: this.ckImgNopdular.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slLoboTire && this.slLoboTire.value) {
          const objeto = { elemento: this.slLoboTire, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaTire && this.inMedidaTire.nativeElement.value) {
          const objeto = { elemento: this.inMedidaTire.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitElastofibroma() {
    if (this.ckElastofibroma && this.ckElastofibroma.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckElastofibroma.id);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoElastro(true);
    }
  }

  emitElastofibromaLesao() {
    if (this.ckElastofibroma && this.ckElastofibroma.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckElastofibroma.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      this.formataMedindoElastro(false);
    }
  }

  emitLinfonodo() {
    if (this.ckLinfonodomegalia && this.ckLinfonodomegalia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLinfonodomegalia.id);

      if (this.rd1Linfo && this.rd1Linfo.checked) {
        this.termoSelecionado.emit(this.rd1Linfo.id);

        if (this.slCadeias && this.slCadeias.value) {
          const objeto = { elemento: this.slCadeias, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoLinfo(true);

      } else if (this.rd2Linfo && this.rd2Linfo.checked) {
        this.termoSelecionado.emit(this.rd2Linfo.id);
        let posicao = 0;
        this.linha = 0;

        this.linfoChildren.forEach(obj => {
          if (obj.nativeElement) {
            if (posicao < 2) {
              posicao++;
            } else {
              posicao = 1;
            }

            this.objetofilho = { id: 1610, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1609) this.linha++;

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          }
        });
      }
    }
  }

  emitLinfonodoLesao() {
    if (this.ckLinfonodomegalia && this.ckLinfonodomegalia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLinfonodomegalia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rd1Linfo && this.rd1Linfo.checked) {
        const objeto = { id: this.rd1Linfo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slCadeias && this.slCadeias.value) {
          const objeto = { elemento: this.slCadeias, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoLinfo(false);

      } else if (this.rd2Linfo && this.rd2Linfo.checked) {
        const objeto = { id: this.rd2Linfo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
        let posicao = 0;
        this.linha = 0;

        this.linfoChildren.forEach(obj => {
          if (obj.nativeElement) {
            if (posicao < 2) {
              posicao++;
            } else {
              posicao = 1;
            }

            this.objetofilho = { id: 1610, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1609) this.linha++;

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        });
      }
    }
  }

  formataMedindoElastro(isPrimeiraLesao: boolean) {
    let medindoValor = '';

    if (this.inMedidaElas1 && this.inMedidaElas1.nativeElement.value) {
      medindoValor = this._validacoes.formataDecimal(this.inMedidaElas1.nativeElement.value);
    }

    if (this.inMedidaElas2 && this.inMedidaElas2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaElas2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaElas2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 1603, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoLinfo(isPrimeiraLesao: boolean) {
    let medindoValor = '';

    if (this.inMedida1 && this.inMedida1.nativeElement.value) {
      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 && this.inMedida2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 1607, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {
    const arrayTotal = this.linfoArray.length;
    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.linfoArray.length - 1
      if (index != 0) {
        this.linfoArray.removeAt(index);
      }
    }
    this.linfoArray.reset();
  }

  verificaAlterado() {
    if (!this.ckGinecomastia.checked &&
      !this.ckElastofibroma.checked &&
      !this.ckImplante.checked &&
      !this.ckTireoide.checked &&
      !this.ckLinfonodomegalia.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
