import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';


@Component({
  selector: 'app-arteria-renal-esquerda',
  templateUrl: './arteria-renal-esquerda.component.html',
  styleUrls: ['./arteria-renal-esquerda.component.css']
})
export class ArteriaRenalEsquerdaComponent implements OnInit {

  public showAndHideTerms: any;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdestenose') rdestenose: MatRadioButton;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      SahNormal: false,
      SahNaoCaracterizada: false,
      SahEstenose: false,
    };
  }

  public ClickNormal() {
    this.showAndHideTerms.SahNormal = true;
    this.showAndHideTerms.SahNaoCaracterizada = false;
    this.showAndHideTerms.SahEstenose = false;
    this.catchIDs();
  }

  public ClickNaoCaracterizada() {
    this.showAndHideTerms.SahNormal = false;
    this.showAndHideTerms.SahNaoCaracterizada = true;
    this.showAndHideTerms.SahEstenose = false;
    this.catchIDs();
  }

  public ClickEstenose() {
    this.showAndHideTerms.SahNormal = false;
    this.showAndHideTerms.SahNaoCaracterizada = false;
    this.showAndHideTerms.SahEstenose = true;
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);
    }

    if (this.rdestenose.checked) {
      this.termoSelecionado.emit(this.rdestenose.id);
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();

  }
}
