import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mg-calcificacoes',
  templateUrl: './mg-calcificacoes.component.html',
  styleUrls: ['./mg-calcificacoes.component.css']
})
export class MgCalcificacoesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdCalcBeg') rdCalcBeg: MatRadioButton;
  @ViewChild('rdCalcLeite') rdCalcLeite: MatRadioButton;
  @ViewChild('rdMastSecre') rdMastSecre: MatRadioButton;
  @ViewChild('rdCalcSusp') rdCalcSusp: MatRadioButton;

  @ViewChild('slLados') slLados: MatSelect;
  @ViewChild('slTipos') slTipos: MatSelect;
  @ViewChild('slMorfo') slMorfo: MatSelect;
  @ViewChild('slDistr') slDistr: MatSelect;
  @ViewChild('slLocal') slLocal: MatSelect;

  
  tipos: ArrayDropDown[] = [
    { value: 'puntiformes', viewValue: 'Puntiformes' },
    { value: 'arredondadas', viewValue: 'Arredondadas' },
    { value: 'distróficas', viewValue: 'Distróficas' }
  ];

  morfos: ArrayDropDown[] = [
    { value: 'amorfa', viewValue: 'Amorfa' },
    { value: 'pleomórfica', viewValue: 'Pleomórfica' },
    { value: 'grosseira e heterogênea', viewValue: 'Grosseira e heterogênea' },
    { value: 'puntiforme', viewValue: 'Puntiforme' }    
  ];

  ditribuicoes: ArrayDropDown[] = [
    { value: 'agrupada', viewValue: 'Agrupada' },
    { value: 'regional', viewValue: 'Regional' },
    { value: 'difusa', viewValue: 'Difusa' },
    { value: 'linear', viewValue: 'Linear' },
    { value: 'segmentar', viewValue: 'Segmentar' }    
  ];
  
  ladosBenig: ArrayDropDown[] = [
    { value: 'na mama direita', viewValue: 'Direita' },
    { value: 'na mama esquerda', viewValue: 'Esquerda' },
    { value: 'e bilaterais', viewValue: 'Bilateral' }
  ];

  lados: ArrayDropDown[] = [
    { value: 'na mama direita', viewValue: 'Direita' },
    { value: 'na mama esquerda', viewValue: 'Esquerda' },
    { value: 'bilaterais', viewValue: 'Bilateral' }
  ];

  ladosSusp: ArrayDropDown[] = [
    { value: 'na mama direita', viewValue: 'Direita' },
    { value: 'na mama esquerda', viewValue: 'Esquerda' }
  ];

  ladosCalc: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'na região retroareolar', viewValue: 'Na região retroareolar' },
    { value: 'no quadrante superolateral', viewValue: 'No quadrante superolateral' },
    { value: 'no quadrante inferolateral', viewValue: 'No quadrante inferolateral' },
    { value: 'no quadrante superomedial', viewValue: 'No quadrante superomedial' },
    { value: 'no quadrante inferomedial', viewValue: 'No quadrante inferomedial' },
    { value: 'na junção dos quadrantes superiores', viewValue: 'Na junção dos quadrantes superiores' },
    { value: 'na junção dos quadrantes inferiores', viewValue: 'Na junção dos quadrantes inferiores' },
    { value: 'na junção dos quadrantes laterais', viewValue: 'Na junção dos quadrantes laterais' },
    { value: 'na junção dos quadrantes mediais', viewValue: 'Na junção dos quadrantes mediais' }
  ];

  showAndHideTerms: any;
  slLadosId: string;
  slTiposId: string;
  slMorfoId: string;
  slDistrId: string;
  slLocalId: string;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: false,
      sahTipo: false,
      sahSusp: false,
      sahLados: false,
      sahLadosBenig: false,
      sahLadosSusp: false
    };
  }

  clickrdAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickDefault() {
    if (this.rdCalcBeg.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahLados = false;
      this.showAndHideTerms.sahLadosBenig = true;
      this.showAndHideTerms.sahLadosSusp = false;
      this.showAndHideTerms.sahTipo = true;
      this.showAndHideTerms.sahSusp = false;
      
      this.slTiposId = '4078';
      this.slLadosId = '4079';      
    } else if (this.rdCalcLeite.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahLados = true;
      this.showAndHideTerms.sahLadosBenig = false;
      this.showAndHideTerms.sahLadosSusp = false;
      this.showAndHideTerms.sahTipo = false;
      this.showAndHideTerms.sahSusp = false;

      this.slLadosId = '4081';
    } else if (this.rdMastSecre.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahLados = true;
      this.showAndHideTerms.sahLadosBenig = false;
      this.showAndHideTerms.sahLadosSusp = false
      this.showAndHideTerms.sahTipo = false;
      this.showAndHideTerms.sahSusp = false;

      this.slLadosId = '4083';
    } else if (this.rdCalcSusp.checked) {
      this.showAndHideTerms.sahPresente = true;
      this.showAndHideTerms.sahLados = false;
      this.showAndHideTerms.sahLadosBenig = false;
      this.showAndHideTerms.sahLadosSusp = false;
      this.showAndHideTerms.sahTipo = false;
      this.showAndHideTerms.sahSusp = true;

      this.slMorfoId = '4085';
      this.slDistrId = '4086';
      this.slLocalId = '4087';
      this.slLadosId = '4088';
    }

    if (this.slLados)
      this.slLados.value = '';
    if (this.slLocal)
      this.slLocal.value = '';
      if (this.slTipos)
      this.slTipos.value = '';
      if (this.slMorfo)
      this.slMorfo.value = '';
      if (this.slDistr)
      this.slDistr.value = '';
    
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdCalcBeg && this.rdCalcBeg.checked) {
      this.termoSelecionado.emit(this.rdCalcBeg.id);

      if (this.slLados && this.slLados.value) {
        const objeto = { elemento: this.slLados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slTipos && this.slTipos.value) {
        const objeto = { elemento: this.slTipos, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdCalcLeite && this.rdCalcLeite.checked) {
      this.termoSelecionado.emit(this.rdCalcLeite.id);

      if (this.slLados && this.slLados.value) {
        const objeto = { elemento: this.slLados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdMastSecre && this.rdMastSecre.checked) {
      this.termoSelecionado.emit(this.rdMastSecre.id);

      if (this.slLados && this.slLados.value) {
        const objeto = { elemento: this.slLados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdCalcSusp && this.rdCalcSusp.checked) {
      this.termoSelecionado.emit(this.rdCalcSusp.id);

      if (this.slMorfo && this.slMorfo.value) {
        const objeto = { elemento: this.slMorfo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDistr && this.slDistr.value) {
        const objeto = { elemento: this.slDistr, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocal && this.slLocal.value) {
        const objeto = { elemento: this.slLocal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      
      if (this.slLados && this.slLados.value) {
        const objeto = { elemento: this.slLados, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      
    }

    this.listaCompleta.emit();
  }


}
