import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Calculadora } from 'src/app/utils/calculadora';

@Component({
  selector: 'app-mst-crescimento-fetal',
  templateUrl: './mst-crescimento-fetal.component.html',
  styleUrls: ['./mst-crescimento-fetal.component.css']
})
export class MstCrescimentoFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('inDOF') inDOF: ElementRef;
  @ViewChild('inCC') inCC: ElementRef;
  @ViewChild('inCA') inCA: ElementRef;
  @ViewChild('inCUM') inCUM: ElementRef;
  @ViewChild('inCF') inCF: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;
  @ViewChild('inPercentil') inPercentil: ElementRef;
  @ViewChild('ckUSG1TrimestreIndisponivel') ckUSG1TrimestreIndisponivel: MatCheckbox;

  showAndHideTerms: any;
  peso;
  ca;
  cf;
  dbp;
  cc;
  inCFInvalid: boolean = true;
  inDBPInvalid: boolean = true;
  inCAInvalid: boolean = true;
  inCCInvalid: boolean = true;

  constructor(private _calc: Calculadora) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFormulario: true,
      sahPercentil: true,
    }
  }
  clickSemPreenchimento() {
    if (this.showAndHideTerms.sahFormulario == false) {
      this.showAndHideTerms.sahFormulario = true;
    } else {
      this.showAndHideTerms.sahFormulario = false;
      this.termosGenericos.emit('');
      this.listaCompleta.emit();
    }
    this.catchIDs();
  }
  clickUSG1TrimestreIndisponivel() {
    if (this.showAndHideTerms.sahPercentil === false) {
      this.showAndHideTerms.sahPercentil = true;
    } else {
      this.showAndHideTerms.sahPercentil = false;
      this.termosGenericos.emit('');
      this.listaCompleta.emit();
    }
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      // if (this.inPeso) {
      //   this.inPeso.nativeElement.value = this.calculaPeso().toFixed(2);
      // }

    }

    if (this.inDBP && this.inDBP.nativeElement.value) {
      const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inDOF && this.inDOF.nativeElement.value) {
      const objeto = { elemento: this.inDOF.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inCC && this.inCC.nativeElement.value) {
      const objeto = { elemento: this.inCC.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inCA && this.inCA.nativeElement.value) {
      const objeto = { elemento: this.inCA.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inCUM && this.inCUM.nativeElement.value) {
      const objeto = { elemento: this.inCUM.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inCF && this.inCF.nativeElement.value) {
      const objeto = { elemento: this.inCF.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inPeso && this.inPeso.nativeElement.value) {
      const objeto = { elemento: this.inPeso.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inPercentil && this.inPercentil.nativeElement.value) {
      const objeto = { elemento: this.inPercentil.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    //Logica placeholder peso
    if (this.inPeso.nativeElement.value) {
      const objetoElemento = { id: this.inPeso.nativeElement.id, value: this.inPeso.nativeElement.value };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    } else {
      const objetoElemento = { id: this.inPeso.nativeElement.id, value: this.peso };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    }

    let idade = this.calculaIG();
    let semanas = parseInt(idade + '');
    let dias = parseInt(((idade - semanas) * 7)+'');
    const ig = semanas + ' semanas e ' + dias + ' dias.';

    if(ig != 'NaN semanas e NaN dias.'){
      const objeto = { elemento: { id: '4777', value: ig }, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if ((this.inDBP && this.inDBP.nativeElement.value)
      && (this.inCC && this.inCC.nativeElement.value)
      && (this.inCF && this.inCF.nativeElement.value)
      && (this.inCA && this.inCA.nativeElement.value)) {
      this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'crescimento-fetal' });
    } else {
      this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'crescimento-fetal' });
    }

    this.listaCompleta.emit();
  }

  calculaIG(): number {
    return 10.85 +
      (0.06 * (parseFloat(this.inCC.nativeElement.value) / 10) * (parseFloat(this.inCF.nativeElement.value) / 10)) +
      (0.67 * (parseFloat(this.inDBP.nativeElement.value) / 10)) + (0.168 * (parseFloat(this.inCA.nativeElement.value) / 10));
  }

  calculaPeso(): number {
    //fórmula: [Log10(PE)=1,3596+(0,00061xDBPxCA)+(0,424xCA)+(1,74xCF)+(0,0064xCC)-(0,00386xCAxCF)]
    let ca = this.inCA.nativeElement.value ? this.inCA.nativeElement.value : '0';
    let cf = this.inCF.nativeElement.value ? this.inCF.nativeElement.value : '0';
    let dbp = this.inDBP.nativeElement.value ? this.inDBP.nativeElement.value : '0';
    let cc = this.inCC.nativeElement.value ? this.inCC.nativeElement.value : '0'

    return 10 ** (1.3596 -
      0.00386 * parseFloat(ca)/10 * parseFloat(cf)/10 +
      0.0064 * parseFloat(cc)/10 +
      0.00061 * parseFloat(dbp)/10 *
      parseFloat(ca)/10 +
      0.0424 * parseFloat(ca)/10 +
      0.174 * parseFloat(cf)/10);
  }

  placeholderPeso(): string {
    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      this.peso = this.calculaPeso().toFixed(2);
      return this.peso;
    } else
    this.peso = 'Peso Fetal';
    return this.peso;
  }

  removeInvalidField() {
    if (this.inCF && this.inCF.nativeElement.value) {
      this.inCFInvalid = false;
    } else {
      this.inCFInvalid = true;
    }
    if (this.inDBP && this.inDBP.nativeElement.value) {
      this.inDBPInvalid = false;
    } else {
      this.inDBPInvalid = true;
    }
    if (this.inCA && this.inCA.nativeElement.value) {
      this.inCAInvalid = false;
    } else {
      this.inCAInvalid = true;
    }
    if (this.inCC && this.inCC.nativeElement.value) {
      this.inCCInvalid = false;
    } else {
      this.inCCInvalid = true;
    }
  }
}
