import { Component, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { MatRadioButton } from "@angular/material";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-corionicidade-gemelar",
  templateUrl: "./corionicidade-gemelar.component.html",
  styleUrls: ["./corionicidade-gemelar.component.css"],
})
export class CorionicidadeGemelarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdDicorionica') rdDicorionica: MatRadioButton;
  @ViewChild('rdMonocronionicaDiamniotica') rdMonocronionicaDiamniotica: MatRadioButton;
  @ViewChild('rdMonocronionicaMonoamniotica') rdMonocronionicaMonoamniotica: MatRadioButton;
  @ViewChild('rdMassaPlacentariaUnica') rdMassaPlacentariaUnica: MatRadioButton;
  @ViewChild('rdTricorionicaTriamniotica') rdTricorionicaTriamniotica: MatRadioButton;
  @ViewChild('rdDicorionicaTriamniotica') rdDicorionicaTriamniotica: MatRadioButton;
  @ViewChild('rdMonocorionicaTriamniotica') rdMonocorionicaTriamniotica: MatRadioButton;

  showAndHideCorionicidade: any;

  quantidadeFetos: number = 2;
  constructor(private _common: CommonService) {}

  ngOnInit() {
    this.showAndHideCorionicidade = {
      "fetos2": true,
      "fetos3": false,
      "fetos4": false,
    };
    this._common.quantidadeFeto.subscribe((quantidade: any) => {
      this.quantidadeFetos = quantidade;
      //atualizar de acordo com quantidade de fetos
      this.showAndHideCorionicidade = {
        "fetos2": false,
        "fetos3": false,
        "fetos4": false,
      };

      if (quantidade >= 2 && quantidade <= 4) {
        this.showAndHideCorionicidade[`fetos${quantidade}`] = true;
      }
      //catchIDs();
    });
  }

  clickRadio() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdDicorionica && this.rdDicorionica.checked){
      this.termoSelecionado.emit(this.rdDicorionica.id);
    }

    if (this.rdMonocronionicaDiamniotica && this.rdMonocronionicaDiamniotica.checked){
      this.termoSelecionado.emit(this.rdMonocronionicaDiamniotica.id);
    }

    if (this.rdMonocronionicaMonoamniotica && this.rdMonocronionicaMonoamniotica.checked){
      this.termoSelecionado.emit(this.rdMonocronionicaMonoamniotica.id);
    }

    if (this.rdMassaPlacentariaUnica && this.rdMassaPlacentariaUnica.checked){
      this.termoSelecionado.emit(this.rdMassaPlacentariaUnica.id);
    }

    if (this.rdTricorionicaTriamniotica && this.rdTricorionicaTriamniotica.checked){
      this.termoSelecionado.emit(this.rdTricorionicaTriamniotica.id);
    }

    if (this.rdDicorionicaTriamniotica && this.rdDicorionicaTriamniotica.checked){
      this.termoSelecionado.emit(this.rdDicorionicaTriamniotica.id);
    }

    if (this.rdMonocorionicaTriamniotica && this.rdMonocorionicaTriamniotica.checked){
      this.termoSelecionado.emit(this.rdMonocorionicaTriamniotica.id);
    }

    this.listaCompleta.emit();
  }
}
