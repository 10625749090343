import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-opt-tecnica',
  templateUrl: './opt-tecnica.component.html',
  styleUrls: ['./opt-tecnica.component.css']
})
export class OptTecnicaComponent implements OnInit {

  @Output() viaEmmit = new EventEmitter();

  @ViewChild('slVia') slVia: MatSelect;

  viaRealizacao: ArrayDropDown[] = [
    { value: 'transvaginal', viewValue: 'Transvaginal' },
    { value: 'abdominal', viewValue: 'Abdominal' },
    { value: 'transvaginal e abdominal', viewValue: 'Transvaginal e Abdominal' }
  ];

  constructor() { }

  ngOnInit() {}

  changeSelect() {
      this.viaEmmit.emit(this.slVia.value + ' (1º Trimestre)');
  }

}
