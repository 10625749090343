import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Exame } from '../models/BO/Exame';

@Injectable({
  providedIn: 'root'
})

export class ListaExamesService {

  constructor(private http: HttpClient) { }

  getExames(userID: string): Observable<Exame>{
    return this.http.get<Exame>(environment.nLaudoApi + "Exames/" + userID)
  }

  getExamesByCPF(userCPF: any): Observable<Exame>{
    return this.http.get<Exame>(environment.nLaudoApi + "Exames/CPF/" + userCPF)
  }

  getExamesPorRangeData(userId: string, dataInicial: any, dataFinal: any): Observable<Exame>{
    return this.http.get<Exame>(environment.nLaudoApi + "Exames/FiltrarPorRangeDate?CPF=" + userId + "&dataInicial=" + dataInicial + "&dataFinal=" + dataFinal);
  }

  getLaudos(): Observable<any[]>{
    return this.http.get<any[]>(environment.nLaudoApi + "MedicalReport/BuscaLaudos")
  }
  getBlocoPorLaudo(laudo: string): Observable<any[]>{
    return this.http.get<any[]>(environment.nLaudoApi + "MedicalReport/BuscaBlocoPorLaudo/"+laudo)
  }
  getTermosPorBloco(bloco: string): Observable<any[]>{
    return this.http.get<any[]>(environment.nLaudoApi + "MedicalReport/BuscaTermosPorBloco/"+bloco)
  }

}
