import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-veia-esplenica',
  templateUrl: './veia-esplenica.component.html',
  styleUrls: ['./veia-esplenica.component.css']
})
export class VeiaEsplenicaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdPervia') rdPervia: MatRadioButton;
  @ViewChild('rdTrombose') rdTrombose: MatRadioButton;

  @ViewChild('slCalibre') slCalibre: MatSelect;
  @ViewChild('slFluxo') slFluxo: MatSelect;
  @ViewChild('inCalibre') inCalibre: ElementRef;

  calibres: ArrGenerico[] = [
    {value: '1124', viewValue: 'Preservado'},
    {value: '1125', viewValue: 'Aumentado'}
  ];

  fluxos: ArrGenerico[] = [
    {value: '1126', viewValue: 'Hepatopetal'},
    {value: '1127', viewValue: 'Hepatofugal'}
  ];

  calibreSelected: string;
  fluxoSelected: string;
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPervia: true
    };

    this.calibreSelected = this.calibres[0].value;
    this.fluxoSelected = this.fluxos[0].value;

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickPervia() {
    this.showAndHideTerms.sahPervia = true;
    this.catchIDs();
  }

  clickTrombose() {
    this.showAndHideTerms.sahPervia = false;
    this.catchIDs();
  }

  changeDefault() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdPervia.checked) {
      this.termoSelecionado.emit(this.rdPervia.id);

      if (this.slCalibre != undefined && this.slCalibre.value != undefined) {
        this.termoSelecionado.emit(this.slCalibre.value);
      } else {
        this.termoSelecionado.emit(this.calibreSelected);
      }

      if (this.slFluxo != undefined && this.slFluxo.value != undefined) {
        this.termoSelecionado.emit(this.slFluxo.value);
      } else {
        this.termoSelecionado.emit(this.fluxoSelected);
      }

      if (this.inCalibre != undefined &&
        this.inCalibre.nativeElement.id != undefined &&
        this.inCalibre.nativeElement.value != '') {
  
        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTrombose.checked) {
      this.termoSelecionado.emit(this.rdTrombose.id);
    
      if (this.inCalibre != undefined &&
        this.inCalibre.nativeElement.id != undefined &&
        this.inCalibre.nativeElement.value != '') {
  
        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}