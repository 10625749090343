import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-megapofise-trans',
  templateUrl: './megapofise-trans.component.html',
  styleUrls: ['./megapofise-trans.component.css']
})
export class MegapofiseTransComponent implements OnInit {
  
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdMegapofise') rdMegapofise: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  lateralidade: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda'},
    { value: 'bilateral', viewValue: 'Bilateral'}
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMegapofise:false
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahMegapofise = false;
    this.catchIDs();
  }

  clickMegapofise() {
    this.showAndHideTerms.sahMegapofise = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {

    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdMegapofise && this.rdMegapofise.checked) {
      this.termoSelecionado.emit(this.rdMegapofise.id);

      if(this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true  };
        this.termosGenericos.emit(objeto);
      }

    }

    this.listaCompleta.emit();
  }


}
