import * as moment from 'moment';
import { animate } from '@angular/animations';

export class Calculadora {

   calculoDPP(dum) {
     const DUM = moment(dum);
     const mesDias = moment.duration(9,'month').add(7,'d');

     const Dpp = moment(DUM).add(mesDias);
     const res1 = moment(Dpp, 'DD/MM/YYYY').format('DD/MM/YYYY');
     return res1;
   }

  calculoIGMenstrual(d1, d2) {
    const currDay = moment(d1);
    const DUM = moment(d2);

    const diff = moment.duration(currDay.diff(DUM,'days'),'days');
    const res1 = Math.floor(diff.asWeeks()) + ' semanas e ' + diff.asDays() % 7 + ' dias';

    return res1;
  }

  calculoIGPrimUltrasom(d1, d2, iGPrimSem, iGPrimDias) {
    let qtdSemanas = 0;
    let qtdDias = 0;
    const currDay = moment(d1);
    const DPM = moment(d2);
    if (iGPrimDias) {
      qtdDias = iGPrimDias;
    }
    if(iGPrimSem){
      qtdSemanas = iGPrimSem;
    }

    let diff = moment.duration(currDay.diff(DPM,'days'),'days').add(qtdDias, 'days').add(qtdSemanas, 'weeks');

    const res1 = Math.floor(diff.asWeeks()) + ' semanas e ' + diff.asDays() % 7 + ' dias';

    return res1;
  }

  calculaDppIg(w,d, dt1, dt2 ) {
    if(d == '' || d == undefined){
      d=0;
    }
    const week = w;
    const day = d;
    const currDay = moment(dt1);
    const DUM = moment(dt2);
    const dpp = moment.duration(week,'weeks').add(day,'days');
    const diff = moment.duration(currDay.diff(DUM,'days'),'days');
    const fras = dpp.add(diff, 'days');
    const res2 = Math.floor(fras.asWeeks()) + ' semanas e ' + fras.asDays() % 7 + ' dias';

    return res2;
  }

  calculaDMSG(sg1, sg2, sg3) {
    let dmsg = 0;
    let n1 = parseFloat(sg1);
    let n2 = parseFloat(sg2);
    let n3 = parseFloat(sg3);

    return dmsg = (n1 + n2 + n3) / 3;
  }

  calcIdadeCcn(ccn) {
    let dias = 0;

    dias = 40.9041 + (3.21585 * ccn ^ 0.5) + (0.348956 * ccn);
    const dife = moment(dias, 'DDD');

    const semana = Math.floor(dife.weeks()) + ' semanas e ' + dife.days() % 7 + ' dias.';

    return semana;
  }

  calculaIdadeGes(ccn: number) {
    let frase = '';
    if (0 < ccn && ccn <= 2) {
      frase = '5 semanas e 5 dias';
    } else if (2 < ccn && ccn <= 3) {
      frase = '5 semanas e 6 dias';
    } else if (3 < ccn && ccn <= 5) {
      frase = '6 semanas e 1 dia';
    } else if (5 < ccn && ccn <= 6) {
      frase = '6 semanas e 3 dias';
    } else if (6 < ccn && ccn <= 7) {
      frase = '6 semanas e 4 dias';
    } else if (7 < ccn && ccn <= 8) {
      frase = '6 semanas e 5 dias';
    } else if (8 < ccn && ccn <= 9) {
      frase = '6 semanas e 6 dias';
    } else if (9 < ccn && ccn <= 11) {
      frase = '7 semanas e 1 dia';
    } else if (11 < ccn && ccn <= 12) {
      frase = '7 semanas e 3 dias';
    } else if (12 < ccn && ccn <= 13) {
      frase = '7 semanas e 4 dias';
    } else if (13 < ccn && ccn <= 14) {
      frase = '7 semanas e 5 dias';
    } else if (14 < ccn && ccn <= 15) {
      frase = '7 semanas e 6 dias';
    } else if (15 < ccn && ccn <= 16) {
      frase = '8 semanas';
    } else if (16 < ccn && ccn <= 17) {
      frase = '8 semanas e 1 dia';
    } else if (17 < ccn && ccn <= 18) {
      frase = '8 semanas e 2 dias';
    } else if (18 < ccn && ccn <= 19) {
      frase = '8 semanas e 3 dias';
    } else if (19 < ccn && ccn <= 20) {
      frase = '8 semanas e 4 dias';
    } else if (20 < ccn && ccn <= 21) {
      frase = '8 semanas e 5 dias';
    } else if (21 < ccn && ccn <= 22) {
      frase = '8 semanas e 6 dias';
    } else if (22 < ccn && ccn <= 23) {
      frase = '9 semanas';
    } else if (23 < ccn && ccn <= 24) {
      frase = '9 semanas e 1 dia';
    } else if (24 < ccn && ccn <= 25) {
      frase = '9 semanas e 2 dias';
    } else if (25 < ccn && ccn <= 26) {
      frase = '9 semanas e 3 dias';
    } else if (26 < ccn && ccn <= 27) {
      frase = '9 semanas e 4 dias';
    } else if (27 < ccn && ccn <= 28) {
      frase = '9 semanas e 4 dias';
    } else if (28 < ccn && ccn <= 29) {
      frase = '9 semanas e 5 dias';
    } else if (29 < ccn && ccn <= 30) {
      frase = '9 semanas e 6 dias';
    } else if (30 < ccn && ccn <= 31) {
      frase = '10 semanas';
    } else if (31 < ccn && ccn <= 32) {
      frase = '10 semanas e 1 dia';
    } else if (32 < ccn && ccn <= 33) {
      frase = '10 semanas e 1 dia';
    } else if (33 < ccn && ccn <= 34) {
      frase = '10 semanas e 2 dias';
    } else if (34 < ccn && ccn <= 35) {
      frase = '10 semanas e 3 dias';
    } else if (35 < ccn && ccn <= 36) {
      frase = '10 semanas e 4 dias';
    } else if (36 < ccn && ccn <= 37) {
      frase = '10 semanas e 4 dias';
    } else if (37 < ccn && ccn <= 38) {
      frase = '10 semanas e 5 dias';
    } else if (38 < ccn && ccn <= 39) {
      frase = '10 semanas e 6 dias';
    } else if (39 < ccn && ccn <= 40) {
      frase = '10 semanas e 6 dias';
    } else if (40 < ccn && ccn <= 41) {
      frase = '11 semanas';
    } else if (41 < ccn && ccn <= 42) {
      frase = '11 semanas e 1 dia';
    } else if (42 < ccn && ccn <= 43) {
      frase = '11 semanas e 1 dia';
    } else if (43 < ccn && ccn <= 44) {
      frase = '11 semanas e 1 dia';
    } else if (44 < ccn && ccn <= 45) {
      frase = '11 semanas e 2 dias';
    } else if (45 < ccn && ccn <= 46) {
      frase = '11 semanas e 3 dias';
    } else if (46 < ccn && ccn <= 47) {
      frase = '11 semanas e 4 dias';
    } else if (47 < ccn && ccn <= 48) {
      frase = '11 semanas e 4 dias';
    } else if (48 < ccn && ccn <= 49) {
      frase = '11 semanas e 5 dias';
    } else if (49 < ccn && ccn <= 50) {
      frase = '11 semanas e 5 dias';
    } else if (50 < ccn && ccn <= 51) {
      frase = '11 semanas e 6 dias';
    } else if (51 < ccn && ccn <= 52) {
      frase = '11 semanas e 6 dias';
    } else if (52 < ccn && ccn <= 53) {
      frase = '12 semanas';
    } else if (53 < ccn && ccn <= 54) {
      frase = '12 semanas';
    } else if (54 < ccn && ccn <= 55) {
      frase = '12 semanas e 1 dia';
    } else if (55 < ccn && ccn <= 56) {
      frase = '12 semanas e 1 dia';
    } else if (56 < ccn && ccn <= 57) {
      frase = '12 semanas e 2 dias';
    } else if (57 < ccn && ccn <= 58) {
      frase = '12 semanas e 2 dias';
    } else if (58 < ccn && ccn <= 59) {
      frase = '12 semanas e 3 dias';
    } else if (59 < ccn && ccn <= 60) {
      frase = '12 semanas e 4 dias';
    } else if (60 < ccn && ccn <= 61) {
      frase = '12 semanas e 4 dias';
    } else if (61 < ccn && ccn <= 62) {
      frase = '12 semanas e 4 dias';
    } else if (62 < ccn && ccn <= 63) {
      frase = '12 semanas e 5 dias';
    } else if (63 < ccn && ccn <= 64) {
      frase = '12 semanas e 6 dias';
    } else if (64 < ccn && ccn <= 65) {
      frase = '12 semanas e 6 dias';
    } else if (65 < ccn && ccn <= 66) {
      frase = '12 semanas e 6 dias';
    } else if (66 < ccn && ccn <= 67) {
      frase = '13 semanas';
    } else if (67 < ccn && ccn <= 68) {
      frase = '13 semanas e 1 dia';
    } else if (68 < ccn && ccn <= 69) {
      frase = '13 semanas e 1 dia';
    } else if (69 < ccn && ccn <= 70) {
      frase = '13 semanas e 1 dia';
    } else if (70 < ccn && ccn <= 71) {
      frase = '13 semanas e 2 dias';
    } else if (71 < ccn && ccn <= 72) {
      frase = '13 semanas e 3 dias';
    } else if (72 < ccn && ccn <= 73) {
      frase = '13 semanas e 3 dias';
    } else if (73 < ccn && ccn <= 74) {
      frase = '13 semanas e 4 dias';
    } else if (74 < ccn && ccn <= 75) {
      frase = '13 semanas e 4 dias';
    } else if (75 < ccn && ccn <= 76) {
      frase = '13 semanas e 5 dias';
    } else if (76 < ccn && ccn <= 77) {
      frase = '13 semanas e 6 dias';
    } else if (77 < ccn && ccn <= 78) {
      frase = '13 semanas e 6 dias';
    } else if (78 < ccn && ccn <= 79) {
      frase = '13 semanas e 6 dias';
    } else {
      frase = '_ semanas e _ dia';
    }
    return frase;
  }

  calculadoraIdadeBiometria(cc, cf, dbp, ca) {
    const dias = 10.85 + (0.06 * cc * cf) + (0.67 * dbp) + (0.168 * ca);

    const dife = moment(dias, 'DDD');

    const semana = Math.floor(dife.weeks()) + ' semanas e ' + dife.days() % 7 + ' dias.';

    return semana;
  }

}
