import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-estruturas-osseas',
  templateUrl: './estruturas-osseas.component.html',
  styleUrls: ['./estruturas-osseas.component.css']
})
export class EstruturasOsseasComponent implements OnInit {
  
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckFratura') ckFratura: MatCheckbox;
  @ViewChild('slFraturaDireita') slFraturaDireita: MatSelect;
  @ViewChild('slFraturaEsquerda') slFraturaEsquerda: MatSelect;
  @ViewChild('slFraturaLateralidade') slFraturaLateralidade: MatSelect;
  
  @ViewChild('ckLesoesEscleroticas') ckLesoesEscleroticas: MatCheckbox;
  @ViewChild('slLesaoLateralidade') slLesaoLateralidade: MatSelect;
  @ViewChild('slLesaoDireita') slLesaoDireita: MatSelect;
  @ViewChild('slLesaoEsquerda') slLesaoEsquerda: MatSelect;
  
  @ViewChild('ckLesoesLiticas') ckLesoesLiticas: MatCheckbox;
  @ViewChild('slLesaoLiticaLateralidade') slLesaoLiticaLateralidade: MatSelect;
  @ViewChild('slLesaoLiticaDireita') slLesaoLiticaDireita: MatSelect;
  @ViewChild('slLesaoLiticaEsquerda') slLesaoLiticaEsquerda: MatSelect;
  
  lateralidadeFratura: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  lateralidade: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' },
    { value: 'multiplas', viewValue: 'Multiplas' }
  ];

  ossosDireito: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal direito' },
    { value: 'temporal', viewValue: 'Temporal direito' },
    { value: 'parietal', viewValue: 'Parietal direito' },
    { value: 'occipital', viewValue: 'Occipital  direito' }
  ];

  ossosEsquerdo: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal esquerdo' },
    { value: 'temporal', viewValue: 'Temporal esquerdo' },
    { value: 'parietal', viewValue: 'Parietal esquerdo' },
    { value: 'occipital', viewValue: 'Occipital  esquerdo' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFratura: false,
      sahFraturaDireita: false,
      sahFraturaEsquerda: false,
      sahLesoesEscl: false,
      sahLesoesDireita: false,
      sahLesoesEsquerda: false,
      sahLesoesLiticas: false,
      sahLesoesLiticasDireita: false,
      sahLesoesLiticasEsquerda: false,
    };
  }

  changeSelectFratura(){
    this.catchIDs();
  }

  changeSelectLesoesEscl(){
    this.catchIDs();
  }

  changeSelectLesoesLiticas(){
    this.catchIDs();
  }

  changeFraturaLateralidade(){
    this.showAndHideTerms.sahFraturaDireita = (this.slFraturaLateralidade.value === 'à direita' || this.slFraturaLateralidade.value === 'bilateral' );
    this.showAndHideTerms.sahFraturaEsquerda = (this.slFraturaLateralidade.value === 'à esquerda' || this.slFraturaLateralidade.value === 'bilateral');
    this.catchIDs();
  }

  changeLesoesLateralidade(){
    this.showAndHideTerms.sahLesoesDireita = (this.slLesaoLateralidade.value === 'à direita' || this.slLesaoLateralidade.value === 'bilateral' );
    this.showAndHideTerms.sahLesoesEsquerda = (this.slLesaoLateralidade.value === 'à esquerda' || this.slLesaoLateralidade.value === 'bilateral');
    this.catchIDs();
  }

  changeLesoesLiticasLateralidade(){
    this.showAndHideTerms.sahLesoesLiticasDireita = (this.slLesaoLiticaLateralidade.value === 'à direita' || this.slLesaoLiticaLateralidade.value === 'bilateral' );
    this.showAndHideTerms.sahLesoesLiticasEsquerda = (this.slLesaoLiticaLateralidade.value === 'à esquerda' || this.slLesaoLiticaLateralidade.value === 'bilateral');
    this.catchIDs();
  }

  clickLesoesEscleroticas(){
    this.showAndHideTerms.sahLesoesEscl = this.ckLesoesEscleroticas.checked;
    this.catchIDs();

  }

  clickLesoesLiticas(){
    this.showAndHideTerms.sahLesoesLiticas = this.ckLesoesLiticas.checked;
    this.catchIDs();

  }

  clickFratura(){
    // this.catchIDs();
    this.showAndHideTerms.sahFratura = this.ckFratura.checked;
    console.log(this.ckFratura.checked);
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitFratura();

    //Lesões Escleróticas
    if(this.countLesoes > 0){
      this.emitLesoesEscleroticasLesao();
    } else {
      this.emitLesoesEscleroticas();
    }
    //Lesões Líticas
    if(this.countLesoes > 0){
      this.emitLesoesLiticasLesao();
    } else {
      this.emitLesoesLiticas();
    }
    
    this.listaCompleta.emit();
  }

  emitFratura(){
    if (this.ckFratura && this.ckFratura.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFratura.id);

      if(this.slFraturaLateralidade && this.slFraturaLateralidade.value){
        if(this.slFraturaLateralidade.value === 'à direita'){
          if(this.slFraturaDireita && this.slFraturaDireita.value){
            if(this.slFraturaDireita.value.length > 1){
              this.termoSelecionado.emit('5007'); //FraturaMultiplaDireita
            }
            const objeto = {elemento: this.slFraturaDireita, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slFraturaLateralidade.value === 'à esquerda'){
          if(this.slFraturaEsquerda && this.slFraturaEsquerda.value){
            if(this.slFraturaEsquerda.value.length > 1){
              this.termoSelecionado.emit('5009'); //FraturaMultiplaEsquerda
            }
            const objeto = {elemento: this.slFraturaEsquerda, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slFraturaLateralidade.value === 'bilateral'){
          if(this.slFraturaDireita && this.slFraturaDireita.value){
            const objeto = {elemento: this.slFraturaDireita, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

          if(this.slFraturaEsquerda && this.slFraturaEsquerda.value){
            const objeto = {elemento: this.slFraturaEsquerda, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }
        } 
      }
    }
  }

  emitLesoesEscleroticas(){
    if (this.ckLesoesEscleroticas && this.ckLesoesEscleroticas.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesoesEscleroticas.id);

      if(this.slLesaoLateralidade && this.slLesaoLateralidade.value){
        if(this.slLesaoLateralidade.value === 'à direita'){
          if(this.slLesaoDireita && this.slLesaoDireita.value){
            if(this.slLesaoDireita.value.length > 1){
              this.termoSelecionado.emit('5012'); //LesoesMultiplaDireita
            }
            const objeto = {elemento: this.slLesaoDireita, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLateralidade.value === 'à esquerda'){
          if(this.slLesaoEsquerda && this.slLesaoEsquerda.value){
            if(this.slLesaoEsquerda.value.length > 1){
              this.termoSelecionado.emit('5014'); //LesoesMultiplaEsquerda
            }
            const objeto = {elemento: this.slLesaoEsquerda, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLateralidade.value === 'bilateral'){
          if(this.slLesaoDireita && this.slLesaoDireita.value){
            const objeto = {elemento: this.slLesaoDireita, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

          if(this.slLesaoEsquerda && this.slLesaoEsquerda.value){
            const objeto = {elemento: this.slLesaoEsquerda, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }
        } else if(this.slLesaoLateralidade.value === 'multiplas'){
          this.termoSelecionado.emit('5015'); //LesoesEscleroticasMultiplas
        } 
      }
    }
  }

  emitLesoesLiticas(){
    if (this.ckLesoesLiticas && this.ckLesoesLiticas.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesoesLiticas.id);

      if(this.slLesaoLiticaLateralidade && this.slLesaoLiticaLateralidade.value){
        if(this.slLesaoLiticaLateralidade.value === 'à direita'){
          if(this.slLesaoLiticaDireita && this.slLesaoLiticaDireita.value){
            if(this.slLesaoLiticaDireita.value.length > 1){
              this.termoSelecionado.emit('5018'); //MultiplasLesoesLiticasDireita
            }
            const objeto = {elemento: this.slLesaoLiticaDireita, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLiticaLateralidade.value === 'à esquerda'){
          if(this.slLesaoLiticaEsquerda && this.slLesaoLiticaEsquerda.value){
            if(this.slLesaoLiticaEsquerda.value.length > 1){
              this.termoSelecionado.emit('5020'); //MultiplasLesoesLiticasEsquerda
            }
            const objeto = {elemento: this.slLesaoLiticaEsquerda, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLiticaLateralidade.value === 'bilateral'){
          if(this.slLesaoLiticaDireita && this.slLesaoLiticaDireita.value){
            const objeto = {elemento: this.slLesaoLiticaDireita, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }

          if(this.slLesaoLiticaEsquerda && this.slLesaoLiticaEsquerda.value){
            const objeto = {elemento: this.slLesaoLiticaEsquerda, isPrimeiraLesao: true}
            this.termosGenericos.emit(objeto);
          }
        } else if(this.slLesaoLiticaLateralidade.value === 'multiplas'){
          this.termoSelecionado.emit('5021'); //MultiplasLesoesLiticas
        } 
      }
    }
  }

  emitLesoesEscleroticasLesao() {
    if (this.ckLesoesEscleroticas && this.ckLesoesEscleroticas.checked) {
      this.countLesoes++;
      const objetoCk = { id: this.ckLesoesEscleroticas.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCk);


      if(this.slLesaoLateralidade && this.slLesaoLateralidade.value){
        if(this.slLesaoLateralidade.value === 'à direita'){
          if(this.slLesaoDireita && this.slLesaoDireita.value){
            if(this.slLesaoDireita.value.length > 1){
              const objeto = { id: '5012', cenario: this.countLesoes }; //LesoesMultiplaDireita
              this.lesoesSelecionadas.emit(objeto);
            }
            const objeto = {elemento: this.slLesaoDireita, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLateralidade.value === 'à esquerda'){
          if(this.slLesaoEsquerda && this.slLesaoEsquerda.value){
            if(this.slLesaoEsquerda.value.length > 1){
              const objeto = { id: '5014', cenario: this.countLesoes }; //LesoesMultiplaEsquerda
              this.lesoesSelecionadas.emit(objeto);
            }
            const objeto = {elemento: this.slLesaoEsquerda, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLateralidade.value === 'bilateral'){
          if(this.slLesaoDireita && this.slLesaoDireita.value){
            const objeto = {elemento: this.slLesaoDireita, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }

          if(this.slLesaoEsquerda && this.slLesaoEsquerda.value){
            const objeto = {elemento: this.slLesaoEsquerda, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }
        } else if(this.slLesaoLateralidade.value === 'multiplas'){
          const objeto = { id: '5015', cenario: this.countLesoes }; //LesoesEscleroticasMultiplas
          this.lesoesSelecionadas.emit(objeto);
        } 
      }
    }
  }
  emitLesoesLiticasLesao() {
    if (this.ckLesoesLiticas && this.ckLesoesLiticas.checked) {
      this.countLesoes++;
      const objetoCk = { id: this.ckLesoesLiticas.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCk);


      if(this.slLesaoLiticaLateralidade && this.slLesaoLiticaLateralidade.value){
        if(this.slLesaoLiticaLateralidade.value === 'à direita'){
          if(this.slLesaoLiticaDireita && this.slLesaoLiticaDireita.value){
            if(this.slLesaoLiticaDireita.value.length > 1){
              const objeto = { id: '5018', cenario: this.countLesoes }; //MultiplasLesoesLiticasDireita
              this.lesoesSelecionadas.emit(objeto);
            }
            const objeto = {elemento: this.slLesaoLiticaDireita, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLiticaLateralidade.value === 'à esquerda'){
          if(this.slLesaoLiticaEsquerda && this.slLesaoLiticaEsquerda.value){
            if(this.slLesaoLiticaEsquerda.value.length > 1){
              const objeto = { id: '5020', cenario: this.countLesoes }; //MultiplasLesoesLiticasEsquerda
              this.lesoesSelecionadas.emit(objeto);
            }
            const objeto = {elemento: this.slLesaoLiticaEsquerda, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }

        } else if(this.slLesaoLiticaLateralidade.value === 'bilateral'){
          if(this.slLesaoLiticaDireita && this.slLesaoLiticaDireita.value){
            const objeto = {elemento: this.slLesaoLiticaDireita, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }

          if(this.slLesaoLiticaEsquerda && this.slLesaoLiticaEsquerda.value){
            const objeto = {elemento: this.slLesaoLiticaEsquerda, isPrimeiraLesao: false}
            this.termosGenericos.emit(objeto);
          }
        } else if(this.slLesaoLiticaLateralidade.value === 'multiplas'){
          const objeto = { id: '5021', cenario: this.countLesoes }; //MultiplasLesoesLiticas
          this.lesoesSelecionadas.emit(objeto);
        } 
      }
    }
  }


}
