import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-residuo-dialog',
  templateUrl: './residuo-dialog.component.html',
  styleUrls: ['./residuo-dialog.component.css']
})
export class ResiduoDialogComponent {
  displayedColumns =
    ['volume', 'diagnostico'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<ResiduoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface RinsRefElement {
  volume: string;
  diagnostico: string;
}

const ELEMENT_DATA: RinsRefElement[] = [
  {volume: '00 a 30', diagnostico: 'Desprezível '},
  {volume: '30 a 80', diagnostico: 'Pequeno'},
  {volume: '80 a 150', diagnostico: 'Moderado'},
  {volume: '150 a 300', diagnostico: 'Acentuado'},
  {volume: '> 300', diagnostico: 'Muito acentuado'}
];
