import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-veia-basilica',
  templateUrl: './veia-basilica.component.html',
  styleUrls: ['./veia-basilica.component.css']
})
export class VeiaBasilicaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCaracterizada') rdNaoCaracterizada: MatRadioButton;
  @ViewChild('rdTromboflebite') rdTromboflebite: MatRadioButton;

  @ViewChild('slMotivo') slMotivo: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slTerco') slTerco: MatSelect;

  motivo: ArrayDropDown[] = [
    { value: 'pelo acentuado edema', viewValue: 'Edema' },
    { value: 'pela intensa atenuação do feixe acústico posterior', viewValue: 'Atenuação do feixe acústico' }
  ];

  localizacao: ArrayDropDown[] = [
    { value: 'braço', viewValue: 'Braço' },
    { value: 'antebraço', viewValue: 'Antebraço' },
  ];
  tercos: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' },
    { value: 'em toda sua extensão', viewValue: 'Toda sua extensão' }
  ];

  constructor() { }

  ngOnInit() {
  }
  showNaoCaracterizada = false;
  showTromboflebite = false;

  clickNormal() {
    this.showNaoCaracterizada = false;
    this.showTromboflebite = false;
    this.catchIDs();
  }
  clickNaoCaracterizada() {
    this.showNaoCaracterizada = true;
    this.showTromboflebite = false;
    this.catchIDs();
  }

  clickTromboflebite(){
    this.showNaoCaracterizada = false;
    this.showTromboflebite = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
      this.termoSelecionado.emit('Clean');

      if (this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
      }

      if (this.rdNaoCaracterizada.checked) {
        this.termoSelecionado.emit(this.rdNaoCaracterizada.id);

        if (this.slMotivo && this.slMotivo.value){
          let objeto = { elemento: this.slMotivo, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdTromboflebite.checked) {
        this.termoSelecionado.emit(this.rdTromboflebite.id);

        if ((this.slTerco && this.slTerco.value)&&(this.slLocalizacao && this.slLocalizacao.value)){
          let objeto = { elemento: this.slTerco, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);

          objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

    this.listaCompleta.emit();
    }

}
