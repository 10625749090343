import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-feto-dialog',
  templateUrl: './feto-dialog.component.html',
  styleUrls: ['./feto-dialog.component.css']
})
export class FetoDialogComponent {
  displayedColumns =
  ['ccn', 'ig', 'ccn2', 'ig2', 'ccn3', 'ig3'];
dataSource = ELEMENT_DATA;

constructor(
  public dialogRef: MatDialogRef<FetoDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public message: string) { }

ClickNao(): void {
  this.dialogRef.close();
}
}

export interface FetoRefElement {
ccn: string;
ig: string;
ccn2: string;
ig2: string;
ccn3: string;
ig3: string;
}

const ELEMENT_DATA: FetoRefElement[] = [
  {ccn: '2', ig: "5+5", ccn2: "30", ig2: "9+6", ccn3: "58", ig3: "12+2"},
  {ccn: '3', ig: "5+6", ccn2: "31", ig2: "10+0", ccn3: "59", ig3: "12+3"},
  {ccn: '4', ig: "6+1", ccn2: "32", ig2: "10+1", ccn3: "60", ig3: "12+4"},
  {ccn: '5', ig: "6+1", ccn2: "33", ig2: "10+1", ccn3: "61", ig3: "12+4"},
  {ccn: '6', ig: "6+3", ccn2: "34", ig2: "10+2", ccn3: "62", ig3: "12+4"},
  {ccn: '7', ig: "6+4", ccn2: "35", ig2: "10+3", ccn3: "63", ig3: "12+5"},
  {ccn: '8', ig: "6+5", ccn2: "36", ig2: "10+4", ccn3: "64", ig3: "12+6"},
  {ccn: '9', ig: "6+6", ccn2: "37", ig2: "10+4", ccn3: "65", ig3: "12+6"},
  {ccn: '10', ig: "7+1", ccn2: "38", ig2: "10+5", ccn3: "66", ig3: "12+6"},
  {ccn: '11', ig: "7+1", ccn2: "39", ig2: "10+6", ccn3: "67", ig3: "13+0"},
  {ccn: '12', ig: "7+3", ccn2: "40", ig2: "10+6", ccn3: "68", ig3: "13+1"},
  {ccn: '13', ig: "7+4", ccn2: "41", ig2: "11+0", ccn3: "69", ig3: "13+1"},
  {ccn: '14', ig: "7+5", ccn2: "42", ig2: "11+1", ccn3: "70", ig3: "13+1"},
  {ccn: '15', ig: "7+6", ccn2: "43", ig2: "11+1", ccn3: "71", ig3: "13+2"},
  {ccn: '16', ig: "8+0", ccn2: "44", ig2: "11+1", ccn3: "72", ig3: "13+3"},
  {ccn: '17', ig: "8+1", ccn2: "45", ig2: "11+2", ccn3: "73", ig3: "13+3"},
  {ccn: '18', ig: "8+2", ccn2: "46", ig2: "11+3", ccn3: "74", ig3: "13+4"},
  {ccn: '19', ig: "8+3", ccn2: "47", ig2: "11+4", ccn3: "75", ig3: "13+4"},
  {ccn: '20', ig: "8+4", ccn2: "48", ig2: "11+4", ccn3: "76", ig3: "13+5"},
  {ccn: '21', ig: "8+5", ccn2: "49", ig2: "11+5", ccn3: "77", ig3: "13+6"},
  {ccn: '22', ig: "8+6", ccn2: "50", ig2: "11+5", ccn3: "78", ig3: "13+6"},
  {ccn: '23', ig: "9+0", ccn2: "51", ig2: "11+6", ccn3: "79", ig3: "13+6"},
  {ccn: '24', ig: "9+1", ccn2: "52", ig2: "11+6", ccn3: "80", ig3: "14+0"},
  {ccn: '25', ig: "9+1", ccn2: "53", ig2: "12+0", ccn3: "81", ig3: "14+1"},
  {ccn: '26', ig: "9+3", ccn2: "54", ig2: "12+0", ccn3: "82", ig3: "14+1"},
  {ccn: '27', ig: "9+4", ccn2: "55", ig2: "12+1", ccn3: "83", ig3: "14+1"},
  {ccn: '28', ig: "9+4", ccn2: "56", ig2: "12+1", ccn3: "84", ig3: "14+2"},
  {ccn: '29', ig: "9+5", ccn2: "57", ig2: "12+2", ccn3: "", ig3: ""},
];


