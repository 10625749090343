import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-linhas-peritoneais',
  templateUrl: './linhas-peritoneais.component.html',
  styleUrls: ['./linhas-peritoneais.component.css']
})
export class LinhasPeritoneaisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdApagamentoLinha') rdApagamentoLinha: MatRadioButton;

  @ViewChild('slLinha') slLinha: MatSelect;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  showAndHideTerms;

  lateralidade: ArrayDropDown[]=[
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ]

  linha: ArrayDropDown[]=[
    { value: 'renal', viewValue: 'Renal' },
    { value: 'do psoas', viewValue: 'Psoas' },
    { value: 'renal e do psoas', viewValue: 'Renal e Psoas' }
  ]

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      apagamento:false
    }
  }

  clickNormal(){
    this.showAndHideTerms.apagamento=false;
    this.rdApagamentoLinha.checked=false;
    this.rdNormal.checked=true;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  clickApagamentoLinha(){
    this.showAndHideTerms.apagamento = !this.showAndHideTerms.apagamento;
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdApagamentoLinha.checked) {
      this.termoSelecionado.emit(this.rdApagamentoLinha.id);

      if(this.slLateralidade && this.slLateralidade.value){
          const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
      }

      if(this.slLinha && this.slLinha.value){
        const objeto = {elemento: this.slLinha, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }


}
