import { Component, EventEmitter, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-sulcos-cisternas',
  templateUrl: './sulcos-cisternas.component.html',
  styleUrls: ['./sulcos-cisternas.component.css']
})
export class SulcosCisternasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckDemSulcos') ckDemSulcos: MatCheckbox;

  @ViewChild('ckHemEpi') ckHemEpi: MatCheckbox;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('slAferida') slAferida: MatSelect;
  @ViewChild('slDesvio') slDesvio: MatSelect;
  @ViewChild('inMedida') inMedida: ElementRef;
  @ViewChild('inDesvioMed') inDesvioMed: ElementRef;

  @ViewChild('ckHemSub') ckHemSub: MatCheckbox;
  @ViewChild('slLocalizacaoHemSub') slLocalizacaoHemSub: MatSelect;
  @ViewChild('slLadoHemSub') slLadoHemSub: MatSelect;
  @ViewChild('slAferidaHemSub') slAferidaHemSub: MatSelect;
  @ViewChild('slDesvioHemSub') slDesvioHemSub: MatSelect;
  @ViewChild('inMedidaHemSub') inMedidaHemSub: ElementRef;
  @ViewChild('inDesvioMedHemSub') inDesvioMedHemSub: ElementRef;

  @ViewChild('ckHSub') ckHSub: MatCheckbox;
  @ViewChild('slLocalizacaoHSub') slLocalizacaoHSub: MatSelect;
  @ViewChild('slLadoHSub') slLadoHSub: MatSelect;
  @ViewChild('slCisternasHSub') slCisternasHSub: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'hemisférico', viewValue: 'Hemisférico' },
    { value: 'na tenda cerebelar', viewValue: 'Tenda cerebelar' }
  ];

  localizacoesHemSub: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'hemisférico', viewValue: 'Hemisférico' },
    { value: 'parassagital anterior', viewValue: 'Parassagital anterior' },
    { value: 'parassagital posterior', viewValue: 'Parassagital posterior' },
    { value: 'na tenda cerebelar', viewValue: 'Tenda cerebelar' }
  ];

  lados: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  ladosBilateral: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  aferidas: ArrayDropDown[] = [
    { value: 'axial', viewValue: 'Axial' },
    { value: 'coronal', viewValue: 'Coronal' },
    { value: 'sagital', viewValue: 'Sagital' }
  ];

  desvios: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  sulcos: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-occipital' },
    { value: 'parassagital anterior', viewValue: 'Parassagital anterior' },
    { value: 'parassagital posterior', viewValue: 'Parassagital posterior' }
  ];

  cisternas: ArrayDropDown[] = [
    { value: 'cisterna Sylviana direita', viewValue: 'Cisterna Sylviana direita' },
    { value: 'cisterna Sylviana esquerda', viewValue: 'Cisterna Sylviana esquerda' },
    { value: 'cisternas Sylvianas', viewValue: 'Cisternas Sylvianas' },
    { value: 'cisterna suprasselar', viewValue: 'Cisterna suprasselar' },
    { value: 'cisterna interpeduncular', viewValue: 'Cisterna interpeduncular' },
    { value: 'cisterna ambiens', viewValue: 'Cisterna ambiens' },
    { value: 'cisterna quadrigeminal', viewValue: 'Cisterna quadrigeminal' },
    { value: 'cisterna supracerebelar', viewValue: 'Cisterna supracerebelar' },
    { value: 'cisterna pré-pontina', viewValue: 'Cisterna pré-pontina' },
    { value: 'cisterna pontocerebelar direita', viewValue: 'Cisterna pontocerebelar direita' },
    { value: 'cisterna pontocerebelar esquerda', viewValue: 'Cisterna pontocerebelar esquerda' },
    { value: 'cisternas pontocerebelares', viewValue: 'Cisternas pontocerebelares' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahHemEpi: false,
      sahHemSub: false,
      sahHSub: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickHemEpi() {
    if (this.showAndHideTerms.sahHemEpi === false) {
      this.showAndHideTerms.sahHemEpi = true;
    } else {
      this.showAndHideTerms.sahHemEpi = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickHemSub() {
    if (this.showAndHideTerms.sahHemSub === false) {
      this.showAndHideTerms.sahHemSub = true;
    } else {
      this.showAndHideTerms.sahHemSub = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickHSub() {
    if (this.showAndHideTerms.sahHSub === false) {
      this.showAndHideTerms.sahHSub = true;
    } else {
      this.showAndHideTerms.sahHSub = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickCheckBox() {
    this.mudaPresente();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  mudaPresente() {
    if ((this.ckDemSulcos && this.ckDemSulcos.checked) ||
      (this.ckHemEpi && this.ckHemEpi.checked) ||
      (this.ckHemSub && this.ckHemSub.checked) ||
      (this.ckHSub && this.ckHSub.checked)
    ) {
      this.rdAlterado.checked = true;
    } else {
      this.rdNormal.checked = true;
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      if (this.ckDemSulcos && this.ckDemSulcos.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckDemSulcos.id);
      }

      if (this.countLesoes > 0) {
        this.emitHemEpiLesao();
      } else {
        this.emitHemEpi();
      }

      if (this.countLesoes > 0) {
        this.emitHemSubLesao();
      } else {
        this.emitHemSub();
      }

      if (this.countLesoes > 0) {
        this.emitHSubLesao();
      } else {
        this.emitHSub();
      }
    }
    
    this.listaCompleta.emit();
  }

  emitHemEpi() {
    if (this.ckHemEpi && this.ckHemEpi.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHemEpi.id);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedida && this.inMedida.nativeElement.value) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slAferida && this.slAferida.value) {
        const objeto = { elemento: this.slAferida, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDesvio && this.slDesvio.value) {
        const objeto = { elemento: this.slDesvio, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDesvioMed && this.inDesvioMed.nativeElement.value) {
        const objeto = { elemento: this.inDesvioMed.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHemEpiLesao() {
    if (this.ckHemEpi && this.ckHemEpi.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHemEpi.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedida && this.inMedida.nativeElement.value) {
        const objeto = { elemento: this.inMedida.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slAferida && this.slAferida.value) {
        const objeto = { elemento: this.slAferida, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDesvio && this.slDesvio.value) {
        const objeto = { elemento: this.slDesvio, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDesvioMed && this.inDesvioMed.nativeElement.value) {
        const objeto = { elemento: this.inDesvioMed.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHemSub() {
    if (this.ckHemSub && this.ckHemSub.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHemSub.id);

      if (this.slLocalizacaoHemSub && this.slLocalizacaoHemSub.value) {
        const objeto = { elemento: this.slLocalizacaoHemSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoHemSub && this.slLadoHemSub.value) {
        const objeto = { elemento: this.slLadoHemSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaHemSub && this.inMedidaHemSub.nativeElement.value) {
        const objeto = { elemento: this.inMedidaHemSub.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slAferidaHemSub && this.slAferidaHemSub.value) {
        const objeto = { elemento: this.slAferidaHemSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDesvioHemSub && this.slDesvioHemSub.value) {
        const objeto = { elemento: this.slDesvioHemSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDesvioMedHemSub && this.inDesvioMedHemSub.nativeElement.value) {
        const objeto = { elemento: this.inDesvioMedHemSub.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHemSubLesao() {
    if (this.ckHemSub && this.ckHemSub.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHemSub.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoHemSub && this.slLocalizacaoHemSub.value) {
        const objeto = { elemento: this.slLocalizacaoHemSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoHemSub && this.slLadoHemSub.value) {
        const objeto = { elemento: this.slLadoHemSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaHemSub && this.inMedidaHemSub.nativeElement.value) {
        const objeto = { elemento: this.inMedidaHemSub.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slAferidaHemSub && this.slAferidaHemSub.value) {
        const objeto = { elemento: this.slAferidaHemSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDesvioHemSub && this.slDesvioHemSub.value) {
        const objeto = { elemento: this.slDesvioHemSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDesvioMedHemSub && this.inDesvioMedHemSub.nativeElement.value) {
        const objeto = { elemento: this.inDesvioMedHemSub.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHSub() {
    if (this.ckHSub && this.ckHSub.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHSub.id);

      if (this.slLocalizacaoHSub && this.slLocalizacaoHSub.value) {
        const objeto = { elemento: this.slLocalizacaoHSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoHSub && this.slLadoHSub.value) {
        const objeto = { elemento: this.slLadoHSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCisternasHSub && this.slCisternasHSub.value) {
        const objeto = { elemento: this.slCisternasHSub, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHSubLesao() {
    if (this.ckHSub && this.ckHSub.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHSub.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoHSub && this.slLocalizacaoHSub.value) {
        const objeto = { elemento: this.slLocalizacaoHSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoHSub && this.slLadoHSub.value) {
        const objeto = { elemento: this.slLadoHSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCisternasHSub && this.slCisternasHSub.value) {
        const objeto = { elemento: this.slCisternasHSub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
}
