import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../utils/dialog/confirm-dialog/confirm-dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Term, Injuries } from '../../../../models/BO/Term';
import { Validacoes } from '../../../../utils/validacoes';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';


@Component({
  selector: 'app-transfontanelar',
  templateUrl: './transfontanelar.component.html',
  styleUrls: ['./transfontanelar.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class TransfontanelarComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1792],blockID:160},
    {listTerm:[1791],blockID:161},
  ]

  public htmlContent: string;
  public mask: string;
  public Subtitulo: string;
  subtituloBloco:string;
  public ParenquimaCerebral: Boolean = true;
  public SistemaVentricular: Boolean = false;
  public medicalReportTypeID: number = 24;
  public ParenquimaCerebralID: Number = 160;
  public SistemaVentricularID: Number = 161;
  public indicacao: Boolean = false;
  public achadosTexto: Boolean = false;
  public indicacaoTexto: any = '';
  public cabecalhoTexto: any = '';
  public achadosAdTexto: any = '';
  public rodape: Boolean = false;
  public rodapeTexto: any = '';
  public term: any = new Term();
  public objetoGrupoLesao: any;
  public termResultsAPI: Array<TermResult>;
  public termConclusionAPI: Array<TermResult>;
  injuries = new Injuries();
  cenarioCorrente: any = 0;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public cabecalho: Boolean = false;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  public blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Parênquima Cerebral',
      id: this.ParenquimaCerebralID,
      name: 'Parênquima Cerebral',
      selected: true,
    },
    {
      titulo: 'Sistema Ventricular',
      id: this.SistemaVentricularID,
      name: 'Sistema-Ventricular',
      selected: false,
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados-adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  public Descricoes: DescricaoConclusao[] = [
    { value: 'Parênquima cerebral com morfologia e ecogenicidade preservada.', blockID: 160, textTermID: 0, visivel: true },
    {
      value: 'Sistema ventricular supratentorial e infratentorial de morfologia e dimensões normais.',
      blockID: 161, textTermID: 0, visivel: true
    },
    { value: 'Foice cerebral centrada.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Sulcos e cisternas encefálicas preservadas.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Tronco e cerebelo com dimensões, situação e ecogenicidade normais.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Não há coleções extra-axiais nas janelas acústicas disponíveis.', blockID: 0, textTermID: 0, visivel: true },
  ];

  public conclusoes: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 160, textTermID: 0, visivel: true },
    { value: '', blockID: 161, textTermID: 0, visivel: true },
  ];

  public genecicParenquimaCerebral: TermosGenericos[] = [
    { value: '', selected: false, termID: 1794, term: '[Lado]' },
    { value: '', selected: false, termID: 1796, term: '[Lado]' },
    { value: '', selected: false, termID: 1798, term: '[Lado]' },
    { value: '', selected: false, termID: 1800, term: '[Lado]' },
    { value: '', selected: false, termID: 1801, term: '[Localização]' },
    { value: '', selected: false, termID: 1803, term: '[Lado]' },
    { value: '', selected: false, termID: 1804, term: '[[persistem em relação ao estudo anterior (mais do que 7 dias)] (sim)]' },
    { value: '', selected: false, termID: 1806, term: '[Lado]' },
    { value: '', selected: false, termID: 1807, term: '[ [persistem em relação ao estudo anterior (mais do que 7 dias)] (não)]' },
    { value: '', selected: false, termID: 1809, term: '[Lado]' },
    { value: '', selected: false, termID: 1811, term: '[Lado]' },
    { value: '', selected: false, termID: 1812, term: '[Tamanho]' },
    { value: '', selected: false, termID: 1814, term: '[Lado]' },
  ];

  public genericSistemaVentricular: TermosGenericos[] = [
    { value: '', selected: false, termID: 1917, term: '[Maior lado da dilatação]' },
    { value: '', selected: false, termID: 1918, term: '[Medida Dir]' },
    { value: '', selected: false, termID: 1919, term: '[Medida Esq]' },
    { value: '', selected: false, termID: 1920, term: '[Graduacao]' },
  ];
  editLaudoMode: boolean = false;

  constructor(private readonly _selecteditememiter: SelectedItemEmiter,
    private readonly _copyPaste: CopyPaste,
    public readonly dialog: MatDialog,
    private readonly _toastr: ToastrManager,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private readonly _validacoes: Validacoes,
    private readonly _laudoservice: LaudosService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService
  ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("transfontanelarAnalise", this.Descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Parênquima Cerebral';
    this.subtituloBloco= this.Subtitulo;
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.ParenquimaCerebralID,
      this.SistemaVentricularID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "transfontanelarMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "transfontanela");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-transfontanelar');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-transfontanelar');
      }
    });
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("transfontanelarAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.Descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  public selecionar(itemSelecionado): any {
    const arrayAtualizado = this._selecteditememiter.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco=itemSelecionado.subtitulo;
    else
      this.subtituloBloco=itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.achadosTexto = (itemSelecionado.id === 'achados-adicionais');
    this.rodape =  (itemSelecionado.id === 'rodape');
    this.ParenquimaCerebral = (itemSelecionado.id === this.ParenquimaCerebralID);
    this.SistemaVentricular = (itemSelecionado.id === this.SistemaVentricularID);

    this.AdicionaTexto(this.newEditor);
  }

  public enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  public limparTermos(): any {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genecicParenquimaCerebral = [
      { value: '', selected: false, termID: 1794, term: '[Lado]' },
      { value: '', selected: false, termID: 1796, term: '[Lado]' },
      { value: '', selected: false, termID: 1798, term: '[Lado]' },
      { value: '', selected: false, termID: 1800, term: '[Lado]' },
      { value: '', selected: false, termID: 1801, term: '[Localização]' },
      { value: '', selected: false, termID: 1803, term: '[Lado]' },
      { value: '', selected: false, termID: 1804, term: '[[persistem em relação ao estudo anterior (mais do que 7 dias)] (sim)]' },
      { value: '', selected: false, termID: 1806, term: '[Lado]' },
      { value: '', selected: false, termID: 1807, term: '[ [persistem em relação ao estudo anterior (mais do que 7 dias)] (não)]' },
      { value: '', selected: false, termID: 1809, term: '[Lado]' },
      { value: '', selected: false, termID: 1811, term: '[Lado]' },
      { value: '', selected: false, termID: 1812, term: '[Tamanho]' },
      { value: '', selected: false, termID: 1814, term: '[Lado]' },
    ];

    this.genericSistemaVentricular = [
      { value: '', selected: false, termID: 1917, term: '[Maior lado da dilatação]' },
      { value: '', selected: false, termID: 1918, term: '[Medida Dir]' },
      { value: '', selected: false, termID: 1919, term: '[Medida Esq]' },
      { value: '', selected: false, termID: 1920, term: '[Graduacao]' },
    ];
  }

  public MontaDescricaoParenquimaCerebral(texto, contadorTexto): any {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1794 || +term === 1796 || +term === 1798 || +term === 1800 || +term === 1801
          || +term === 1803 || +term === 1804 || +term === 1806 || +term === 1807 || +term === 1809
          || +term === 1811 || +term === 1812 || +term === 1814) {
          this.genecicParenquimaCerebral.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1794 || +term === 1796 || +term === 1798 || +term === 1800 || +term === 1801
          || +term === 1803 || +term === 1804 || +term === 1806 || +term === 1807 || +term === 1809
          || +term === 1811 || +term === 1812 || +term === 1814) {
          this.genecicParenquimaCerebral.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaDescricaoSistemaVentricular(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1917 || +term === 1918 || +term === 1919 || +term === 1920) {
        this.genericSistemaVentricular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public MontaConclussaoParenquimaCerebral(texto, contadorTexto): any {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 1794 || +term === 1796 || +term === 1798 || +term === 1800 || +term === 1801
          || +term === 1803 || +term === 1804 || +term === 1806 || +term === 1807 || +term === 1809
          || +term === 1811 || +term === 1812 || +term === 1814) {
          this.genecicParenquimaCerebral.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 1794 || +term === 1796 || +term === 1798 || +term === 1800 || +term === 1801
          || +term === 1803 || +term === 1804 || +term === 1806 || +term === 1807 || +term === 1809
          || +term === 1811 || +term === 1812 || +term === 1814) {
          this.genecicParenquimaCerebral.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  public MontaConclussaoSistemaVentricular(texto): any {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1917 || +term === 1918 || +term === 1919 || +term === 1920) {
        this.genericSistemaVentricular.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  public adicionaSelecaoParenquimaCerebral($event): any {
    this.genecicParenquimaCerebral.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  public adicionaSelecaoSistemaVentricular($event): any {
    this.genericSistemaVentricular.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  public consultarAPI(): any {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
    } else if(!this.editLaudoMode){
      let desc;
      if (this.ParenquimaCerebral) {
        this.term.blockID = this.ParenquimaCerebralID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.ParenquimaCerebralID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoParenquimaCerebral(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.ParenquimaCerebralID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });

          let obj = {
            value: '',
            blockID: this.ParenquimaCerebralID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoParenquimaCerebral(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.ParenquimaCerebralID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ParenquimaCerebralID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.ParenquimaCerebralID,
              descricao: desc,
            });
          }
        });

      } else if (this.SistemaVentricular) {
        this.term.blockID = this.SistemaVentricularID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.Descricoes.forEach(obj => {
            if (obj.blockID === this.SistemaVentricularID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSistemaVentricular(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.Descricoes.findIndex(x => x.blockID === this.SistemaVentricularID);
              if (index > -1) {
                this.Descricoes[index].value = obj.value;
                this.Descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              //this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.SistemaVentricularID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclussaoSistemaVentricular(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const descricoes = this.conclusoes.find(x => x.blockID === this.SistemaVentricularID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.SistemaVentricularID) {
                conclusaoNull.value = obj.value;
                descricoes.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              descricoes.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            descricoes.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.SistemaVentricularID,
              descricao: desc,
            });
          }
        });

      }
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  public adicionaTextoNewEditor(): any {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];
    let ultimoBiRads = 0;

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    if(this.indicacaoTexto){
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-indicacao`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.indicacaoTexto}</p>`,
        }
      });
    }

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.Descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.Descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }
  public adicionaTextoOldEditor(): any {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
     //Lógica Rules
     if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
     this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.Descricoes.length; i++) {
      var newValue = this.Descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.Descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.Descricoes.length; i++) {
      if (this.Descricoes[i].value) {
        this.htmlContent += '<br/>' + this.Descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  public enviaTermos($event): any {
    if ($event === 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.Descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }

			this._addText.lineHeight();
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  public RetornaConclusaoNull(Texto): any {
    let texto = '';
    texto = 'Exame dentro dos padrões da normalidade.';
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
