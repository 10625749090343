import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-partes-moles',
  templateUrl: './partes-moles.component.html',
  styleUrls: ['./partes-moles.component.css']
})
export class PartesMolesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('rdAumento') rdAumento: MatCheckbox;
  @ViewChild('slLocalizacaoAumento') slLocalizacaoAumento: MatSelect;
  @ViewChild('slLateralidadeAumento') slLateralidadeAumento: MatSelect;

  @ViewChild('rdHematoma') rdHematoma: MatCheckbox;
  @ViewChild('slLocalizacaoHematoma') slLocalizacaoHematoma: MatSelect;
  @ViewChild('slLateralidadeHematoma') slLateralidadeHematoma: MatSelect;

  @ViewChild('rdColecao') rdColecao: MatCheckbox;
  @ViewChild('slLocalizacaoColecao') slLocalizacaoColecao: MatSelect;
  @ViewChild('slLateralidadeColecao') slLateralidadeColecao: MatSelect;
  @ViewChild('slCaracteristicaColecao') slCaracteristicaColecao: MatSelect;
  @ViewChild('ckEnfisemaColecao') ckEnfisemaColecao: MatCheckbox;

  @ViewChild('rdManipulacao') rdManipulacao: MatCheckbox;
  @ViewChild('slLateralidadeManipulacao') slLateralidadeManipulacao: MatSelect;
  @ViewChild('slLocalizacaoManipulacao') slLocalizacaoManipulacao: MatSelect;

  @ViewChild('rdLipoma') rdLipoma: MatCheckbox;
  @ViewChild('slLateralidadeLipoma') slLateralidadeLipoma: MatSelect;
  @ViewChild('slCaracteristicaLipoma') slCaracteristicaLipoma: MatSelect;
  @ViewChild('slLocalizacaoLipoma') slLocalizacaoLipoma: MatSelect;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;

  @ViewChild('rdCalcificacoes') rdCalcificacoes: MatCheckbox;
  @ViewChild('slLocalizacaoCalcificacoes') slLocalizacaoCalcificacoes: MatSelect;
  @ViewChild('slLateralidadeCalcificacoes') slLateralidadeCalcificacoes: MatSelect;

  @ViewChild('ckEnfisema') ckEnfisema: MatCheckbox;


  Caracteristicas: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' },
    { value: 'hemática', viewValue: 'Hemática' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-Occipital' }
  ];

  Lateralidades: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  localizacoesLipoma: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' }
  ];

  localizacoesCalcificacoes: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' },
    { value: 'frontoparietal', viewValue: 'Frontoparietal' },
    { value: 'frontotemporal', viewValue: 'Frontotemporal' },
    { value: 'parietotemporal', viewValue: 'Parietotemporal' },
    { value: 'parieto-occipital', viewValue: 'Parieto-Occipital' },
    { value: 'na face', viewValue: 'Na face' }
  ];

  LateralidadesCalcificacoes: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];


  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahEnfisema: false,
      sahCaractristica: false,
      sahLocLat: false,
      sahLipoma: false,
      sahCalcificacoes: false,
      sahAumento: false,
      sahHematoma: false,
      sahColecao: false,
      sahManipulacao: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickAumento() {

    if (this.showAndHideTerms.sahAumento === false) {
      this.showAndHideTerms.sahAumento = true;
    } else {
      this.showAndHideTerms.sahAumento = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickHematoma() {
    if (this.showAndHideTerms.sahHematoma === false) {
      this.showAndHideTerms.sahHematoma = true;
    } else {
      this.showAndHideTerms.sahHematoma = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickColecao() {
    if (this.showAndHideTerms.sahColecao === false) {
      this.showAndHideTerms.sahColecao = true;
    } else {
      this.showAndHideTerms.sahColecao = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickManipulacao() {
    if (this.showAndHideTerms.sahManipulacao === false) {
      this.showAndHideTerms.sahManipulacao = true;
    } else {
      this.showAndHideTerms.sahManipulacao = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickEnfisema() {
    this.catchIDs();
  }

  clickLipoma() {
    if (this.showAndHideTerms.sahLipoma === false) {
      this.showAndHideTerms.sahLipoma = true;
    } else {
      this.showAndHideTerms.sahLipoma = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickCalcificacoes() {
    if (this.showAndHideTerms.sahCalcificacoes === false) {
      this.showAndHideTerms.sahCalcificacoes = true;
    } else {
      this.showAndHideTerms.sahCalcificacoes = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      if (this.rdAumento && this.rdAumento.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdAumento.id);
        if (this.slLocalizacaoAumento && this.slLocalizacaoAumento.value) {
          const objeto = { elemento: this.slLocalizacaoAumento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidadeAumento && this.slLateralidadeAumento.value) {
          const objeto = { elemento: this.slLateralidadeAumento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.countLesoes > 0) {
        this.emitHematomaLesao();
      } else {
        this.emitHematoma();
      }
      if (this.countLesoes > 0) {
        this.emitColecaoLesao();
      } else {
        this.emitColecao();
      }
      if (this.countLesoes > 0) {
        this.emitManipulacaoLesao();
      } else {
        this.emitManipulacao();
      }
      if (this.countLesoes > 0) {
        this.emitLipomaLesao();
      } else {
        this.emitLipoma();
      }
      if (this.countLesoes > 0) {
        this.emitCalcificacoesLesao();
      } else {
        this.emitCalcificacoes();
      }

    }
    this.listaCompleta.emit();
  }
  emitHematoma() {
    if (this.rdHematoma && this.rdHematoma.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdHematoma.id);

      if (this.slLocalizacaoHematoma && this.slLocalizacaoHematoma.value) {
        const objeto = { elemento: this.slLocalizacaoHematoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeHematoma && this.slLateralidadeHematoma.value) {
        const objeto = { elemento: this.slLateralidadeHematoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHematomaLesao() {
  if (this.rdHematoma && this.rdHematoma.checked) {
      this.countLesoes++;
      const objeto = {id: this.rdHematoma.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoHematoma && this.slLocalizacaoHematoma.value) {
        const objeto = { elemento: this.slLocalizacaoHematoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeHematoma && this.slLateralidadeHematoma.value) {
        const objeto = { elemento: this.slLateralidadeHematoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitColecao() {
    if (this.rdColecao && this.rdColecao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdColecao.id);

      if (this.slLocalizacaoColecao && this.slLocalizacaoColecao.value) {
        const objeto = { elemento: this.slLocalizacaoColecao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeColecao && this.slLateralidadeColecao.value) {
        const objeto = { elemento: this.slLateralidadeColecao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCaracteristicaColecao && this.slCaracteristicaColecao.value) {
        const objeto = { elemento: this.slCaracteristicaColecao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckEnfisemaColecao && this.ckEnfisemaColecao.checked) {
        this.termoSelecionado.emit(this.ckEnfisemaColecao.id);
      }
    }
  }

  emitColecaoLesao() {
  if (this.rdColecao && this.rdColecao.checked) {
      this.countLesoes++;
      const objeto = {id: this.rdColecao.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoColecao && this.slLocalizacaoColecao.value) {
        const objeto = { elemento: this.slLocalizacaoColecao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeColecao && this.slLateralidadeColecao.value) {
        const objeto = { elemento: this.slLateralidadeColecao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCaracteristicaColecao && this.slCaracteristicaColecao.value) {
        const objeto = { elemento: this.slCaracteristicaColecao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.ckEnfisemaColecao && this.ckEnfisemaColecao.checked) {
        const objeto = {id: this.ckEnfisemaColecao.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitManipulacao() {
    if (this.rdManipulacao && this.rdManipulacao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdManipulacao.id);

      if (this.slLocalizacaoManipulacao && this.slLocalizacaoManipulacao.value) {
        const objeto = { elemento: this.slLocalizacaoManipulacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeManipulacao && this.slLateralidadeManipulacao.value) {
        const objeto = { elemento: this.slLateralidadeManipulacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.ckEnfisema && this.ckEnfisema.checked) {
        this.termoSelecionado.emit(this.ckEnfisema.id);
      }
    }
  }

  emitManipulacaoLesao() {
  if (this.rdManipulacao && this.rdManipulacao.checked) {
      this.countLesoes++;
      const objeto = {id: this.rdManipulacao.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoManipulacao && this.slLocalizacaoManipulacao.value) {
        const objeto = { elemento: this.slLocalizacaoManipulacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeManipulacao && this.slLateralidadeManipulacao.value) {
        const objeto = { elemento: this.slLateralidadeManipulacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.ckEnfisema && this.ckEnfisema.checked) {
        const objeto = {id: this.ckEnfisema.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitLipoma() {
    if (this.rdLipoma && this.rdLipoma.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdLipoma.id);

      if (this.slCaracteristicaLipoma && this.slCaracteristicaLipoma.value) {
        const objeto = { elemento: this.slCaracteristicaLipoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeLipoma && this.slLateralidadeLipoma.value) {
        const objeto = { elemento: this.slLateralidadeLipoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoLipoma && this.slLocalizacaoLipoma.value) {
        const objeto = { elemento: this.slLocalizacaoLipoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if ((this.inMedida1 && this.inMedida1.nativeElement.value) ||
        (this.inMedida2 && this.inMedida2.nativeElement.value)) {
          this.formataMedida(false);
      }
    }
  }

  emitLipomaLesao() {
    if (this.rdLipoma && this.rdLipoma.checked) {
      this.countLesoes++;
      const objeto = {id: this.rdLipoma.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slCaracteristicaLipoma && this.slCaracteristicaLipoma.value) {
        const objeto = { elemento: this.slCaracteristicaLipoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeLipoma && this.slLateralidadeLipoma.value) {
        const objeto = { elemento: this.slLateralidadeLipoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoLipoma && this.slLocalizacaoLipoma.value) {
        const objeto = { elemento: this.slLocalizacaoLipoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if ((this.inMedida1 && this.inMedida1.nativeElement.value) ||
      (this.inMedida2 && this.inMedida2.nativeElement.value)) {
        this.formataMedida(true);
    }
    }
  }

  emitCalcificacoes() {
    if (this.rdCalcificacoes && this.rdCalcificacoes.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdCalcificacoes.id);

      if (this.slLocalizacaoCalcificacoes && this.slLocalizacaoCalcificacoes.value) {
        const objeto = { elemento: this.slLocalizacaoCalcificacoes, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeCalcificacoes && this.slLateralidadeCalcificacoes.value) {
        const objeto = { elemento: this.slLateralidadeCalcificacoes, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCalcificacoesLesao() {
  if (this.rdCalcificacoes && this.rdCalcificacoes.checked) {
      this.countLesoes++;
      const objeto = {id: this.rdCalcificacoes.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalizacaoCalcificacoes && this.slLocalizacaoCalcificacoes.value) {
        const objeto = { elemento: this.slLocalizacaoCalcificacoes, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLateralidadeCalcificacoes && this.slLateralidadeCalcificacoes.value) {
        const objeto = { elemento: this.slLateralidadeCalcificacoes, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  mudaAlterado() {

    if ((this.rdAumento && this.rdAumento.checked) ||
      (this.rdHematoma && this.rdHematoma.checked) ||
      (this.rdColecao && this.rdColecao.checked) ||
      (this.rdManipulacao && this.rdManipulacao.checked) ||
      (this.rdLipoma && this.rdLipoma.checked) ||
      (this.rdCalcificacoes && this.rdCalcificacoes.checked)
    ) {
      this.rdAlterado.checked = true;
    } else {
      this.rdAlterado.checked = false;
    }
  }

  formataMedida(isLesao) {
    let medindoValor = '';

    if (this.inMedida1 != undefined &&
      this.inMedida1.nativeElement.id != undefined &&
      this.inMedida1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if(isLesao){
        const objetoElemento = { id: 4566, value: medindoValor };
        const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objetoLesao);
      } else {
        const objetoElemento = { id: 4566, value: medindoValor };
        const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objetoLesao);
      }
    }
  }

}
