import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatToolbarModule, MatTooltipModule, MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { VenosoMembroSuperiorComponent } from './venoso-membro-superior.component';
import { VeiasRadiaisComponent } from './TermosComponents/veias-radiais/veias-radiais.component';
import { VeiasUlnaresComponent } from './TermosComponents/veias-ulnares/veias-ulnares.component';
import { VeiasBraquiaisComponent } from './TermosComponents/veias-braquiais/veias-braquiais.component';
import { VeiasAxilarComponent } from './TermosComponents/veias-axilar/veias-axilar.component';
import { VeiaSubclaviaComponent } from './TermosComponents/veia-subclavia/veia-subclavia.component';
import { VeiaCefalicaComponent } from './TermosComponents/veia-cefalica/veia-cefalica.component';
import { VeiaBasilicaComponent } from './TermosComponents/veia-basilica/veia-basilica.component';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { EdemaSuperiorComponent } from './TermosComponents/edema-superior/edema-superior.component';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
    imports: [
        MatExpansionModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatCardModule,
        DragDropModule,
        MatMenuModule,
        MatTabsModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        FormsModule,
        AngularEditorModule,
        HttpClientModule,
        LayoutModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatDialogModule,
        TextEditorModule,
        CustomDirectiveModule,
        LateralidadeModule,
        LaudoFooterModule,
        SharedModule,
        TextEditorJSmodule,
        MatSlideToggleModule,
    ],
    declarations: [
        VenosoMembroSuperiorComponent,
        VeiasRadiaisComponent,
        VeiasUlnaresComponent,
        VeiasBraquiaisComponent,
        VeiasAxilarComponent,
        VeiaSubclaviaComponent,
        VeiaCefalicaComponent,
        VeiaBasilicaComponent,
        EdemaSuperiorComponent,

    ],
    providers: [

    ]

})
export class VenosoMembroSuperiorModule { }
