import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';
import {Validacoes} from '../../../../../../utils/validacoes';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-endometrio',
  templateUrl: './endometrio.component.html',
  styleUrls: ['./endometrio.component.css']
})
export class EndometrioComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTrilaminar') rdTrilaminar: MatRadioButton;
  @ViewChild('rdLaminar') rdLaminar: MatRadioButton;
  @ViewChild('rdHeterogeneo') rdHeterogeneo: MatRadioButton;
  @ViewChild('rdEspessado') rdEspessado: MatRadioButton;
  @ViewChild('inEspesura') inEspesura: ElementRef;
  @ViewChild('slAspecto') slAspecto: MatSelect;

  @ViewChild('ckDiuNormoposicionado') ckDiuNormoposicionado: MatCheckbox;
  @ViewChild('inMedidaUmNormo') inMedidaUmNormo: ElementRef;
  @ViewChild('inMedidaDoisNormo') inMedidaDoisNormo: ElementRef;

  @ViewChild('ckDiuDeslocado') ckDiuDeslocado: MatCheckbox;
  @ViewChild('rdPosicaoBaixa') rdPosicaoBaixa: MatRadioButton;
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('rdIntramural') rdIntramural: MatRadioButton;
  @ViewChild('rdSerosa') rdSerosa: MatRadioButton;
  @ViewChild('slParedeDeslocada') slParedeDeslocada: MatSelect;

  @ViewChild('ckPolipo') ckPolipo: MatCheckbox;
  @ViewChild('slSegmentoPolipo') slSegmentoPolipo: MatSelect;
  @ViewChild('inMedidaPolipo1') inMedidaPolipo1: ElementRef;
  @ViewChild('inMedidaPolipo2') inMedidaPolipo2: ElementRef;
  @ViewChild('inMedidaPolipo3') inMedidaPolipo3: ElementRef;

  @ViewChild('ckLaminaLiq') ckLaminaLiq: MatCheckbox;


  paredes: ArrGenerico[] = [
    {value: 'corporal anterior', viewValue: 'Corporal anterior'},
    {value: 'corporal posterior', viewValue: 'Corporal posterior'},
    {value: 'lateral direita', viewValue: 'Lateral direita'},
    {value: 'lateral esquerda', viewValue: 'Lateral esquerda'}
  ];

  segmentos: ArrGenerico[] = [
    {value: 'fúndica', viewValue: 'Fúndica'},
    {value: 'corporal', viewValue: 'Corporal'},
    {value: 'ístmica', viewValue: 'Ístmica'},
    {value: 'endocervical', viewValue: 'Endocervical'}
  ];

  aspectos: ArrGenerico[] = [
    {value: 'homogêneo', viewValue: 'Homogêneo'},
    {value: 'heterogêneo', viewValue: 'Heterogêneo'}
  ];

  countLesoes = 0;
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormal: true,
      sahEspessura: true,
      sahEspessado: false,
      sahDiuNormoposicionado: false,
      sahDiuDeslocado: false,
      sahMedidaPosicaoBaixa: false,
      sahDeslocadoParede: false,
      sahPolipo: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahEspessado = false;
    this.showAndHideTerms.sahEspessura = true;
    this.catchIDs();
  }

  clickLaminar() {
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahEspessado = false;
    this.showAndHideTerms.sahEspessura = false;
    this.catchIDs();
  }

  clickTrilaminar() {
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahEspessado = false;
    this.showAndHideTerms.sahEspessura = true;
    this.catchIDs();
  }

  clickHeterogeneo() {
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahEspessado = false;
    this.showAndHideTerms.sahEspessura = true;
    this.catchIDs();
  }

  clickEspessado() {
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahEspessado = true;
    this.showAndHideTerms.sahEspessura = true;
    this.catchIDs();
  }

  clickDiuNormoposicionado() {
    if (this.showAndHideTerms.sahDiuNormoposicionado == false) {
      this.showAndHideTerms.sahDiuNormoposicionado = true;
      this.ckDiuDeslocado.checked = false;
      this.showAndHideTerms.sahDiuDeslocado = false;
    } else {
      this.showAndHideTerms.sahDiuNormoposicionado = false;
    }
    this.catchIDs();
  }

  clickDiuDeslocado() {
    if (this.showAndHideTerms.sahDiuDeslocado == false) {
      this.showAndHideTerms.sahDiuDeslocado = true;
      this.showAndHideTerms.sahMedidaPosicaoBaixa = true;
      this.ckDiuNormoposicionado.checked = false;
      this.showAndHideTerms.sahDiuNormoposicionado = false;
    } else {
      this.showAndHideTerms.sahDiuDeslocado = false;
    }
    this.catchIDs();
  }

  clickPosicaoBaixa() {
    this.showAndHideTerms.sahMedidaPosicaoBaixa = true;
    this.showAndHideTerms.sahDeslocadoParede = false;
    this.catchIDs();
  }

  clickIntramural() {
    this.showAndHideTerms.sahDeslocadoParede = true;
    this.showAndHideTerms.sahMedidaPosicaoBaixa = false;
    this.catchIDs();
  }

  clickSerosa() {
    this.showAndHideTerms.sahDeslocadoParede = true;
    this.showAndHideTerms.sahMedidaPosicaoBaixa = false;
    this.catchIDs();
  }

  clickPolipo() {
    if (this.showAndHideTerms.sahPolipo == false) {
      this.showAndHideTerms.sahPolipo = true;
    } else {
      this.showAndHideTerms.sahPolipo = false;
    }
    this.catchIDs();
  }

  clickLaminaLiq() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdLaminar.checked) {
      this.termoSelecionado.emit(this.rdLaminar.id);
    }

    if (this.rdTrilaminar.checked) {
      this.termoSelecionado.emit(this.rdTrilaminar.id);
    }

    if (this.rdHeterogeneo.checked) {
      this.termoSelecionado.emit(this.rdHeterogeneo.id);
    }

    if (this.rdEspessado.checked) {
      this.termoSelecionado.emit(this.rdEspessado.id);

      if (this.slAspecto != undefined && this.slAspecto.value != undefined) {
        const objeto = {elemento: this.slAspecto, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.inEspesura != undefined &&
      this.inEspesura.nativeElement.id != undefined &&
      this.inEspesura.nativeElement.value != '') {

      const objeto = {elemento: this.inEspesura.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }
    
    if (this.ckLaminaLiq.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckLaminaLiq.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);
    }

    if (this.ckDiuNormoposicionado.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckDiuNormoposicionado.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.inMedidaUmNormo != undefined &&
        this.inMedidaUmNormo.nativeElement.id != undefined &&
        this.inMedidaUmNormo.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaUmNormo.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaDoisNormo != undefined &&
        this.inMedidaDoisNormo.nativeElement.id != undefined &&
        this.inMedidaDoisNormo.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaDoisNormo.nativeElement, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckDiuDeslocado.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckDiuDeslocado.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdPosicaoBaixa != undefined && this.rdPosicaoBaixa.checked) {
        const objeto = {id: this.rdPosicaoBaixa.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);

        if (this.inMedida1 && this.inMedida1.nativeElement.value) {
          const objeto = {elemento: this.inMedida1.nativeElement, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);
        }
  
        if (this.inMedida2 && this.inMedida2.nativeElement.value) {
          const objeto = {elemento: this.inMedida2.nativeElement, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rdIntramural != undefined && this.rdIntramural.checked) {
        const objeto = {id: this.rdIntramural.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);

        if (this.slParedeDeslocada != undefined && this.slParedeDeslocada.value != undefined) {
          const objeto = {elemento: this.slParedeDeslocada, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);
        }
      } else if (this.rdSerosa != undefined && this.rdSerosa.checked) {
        const objeto = {id: this.rdSerosa.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);

        if (this.slParedeDeslocada != undefined && this.slParedeDeslocada.value != undefined) {
          const objeto = {elemento: this.slParedeDeslocada, isPrimeiraLesao: false};
          this.termosGenericos.emit(objeto);
        }
      } else {
        const objeto = {id: 409, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);

      }
    }

    if (this.ckPolipo.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckPolipo.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slSegmentoPolipo != undefined && this.slSegmentoPolipo.value != undefined) {
        const objeto = {elemento: this.slSegmentoPolipo, isPrimeiraLesao: false};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoPolipo();
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  formataMedindoPolipo() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;

    if (this.inMedidaPolipo1 != undefined &&
      this.inMedidaPolipo1.nativeElement.id != undefined &&
      this.inMedidaPolipo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaPolipo1.nativeElement.value);
    }

    if (this.inMedidaPolipo2 != undefined &&
      this.inMedidaPolipo2.nativeElement.id != undefined &&
      this.inMedidaPolipo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaPolipo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaPolipo2.nativeElement.value);
      }
    }

    if (this.inMedidaPolipo3 != undefined &&
      this.inMedidaPolipo3.nativeElement.id != undefined &&
      this.inMedidaPolipo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaPolipo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaPolipo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      // if (this.countLesoes <= 1) {
      //   isPrimeiraLesao = true;
      // }

      const objetoElemento = {id: 415, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: false};
      this.termosGenericos.emit(objetoLesao);
    }
  }

}
