import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-espacos-discais',
  templateUrl: './espacos-discais.component.html',
  styleUrls: ['./espacos-discais.component.css']
})
export class EspacosDiscaisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdPreservado') rdPreservado: MatRadioButton;
  @ViewChild('rdReducao') rdReducao: MatRadioButton;

  showAndHideTerms: any;

  reducaoFormGroup: FormGroup;

  reducao: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormReducao();

    this.reducao = [
      { id: 'C1-C2', value: 'C1-C2', name: 'C1-C2' },
      { id: 'C2-C3', value: 'C2-C3', name: 'C2-C3' },
      { id: 'C3-C4', value: 'C3-C4', name: 'C3-C4' },
      { id: 'C4-C5', value: 'C4-C5', name: 'C4-C5' },
      { id: 'C5-C6', value: 'C5-C6', name: 'C5-C6' },
      { id: 'C6-C7', value: 'C6-C7', name: 'C6-C7' },
      { id: 'C7-T1', value: 'C7-T1', name: 'C7-T1' },
    ];

    this.showAndHideTerms = {
      sahReducao: false,
    };
  }

  clickPreservado(){
    this.showAndHideTerms.sahReducao = false;
    this.catchIDs()
  }

  clickReducao(){
    this.showAndHideTerms.sahReducao = true;
    this.catchIDs()
  }

  clickCheckboxReducao(event){
    let reducao= <FormArray>this.reducaoFormGroup.get('reducao') as FormArray;

    if (event.checked) {
      reducao.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iReducao = reducao.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      reducao.removeAt(iReducao);
    }

    this.catchIDs();
  }

  iniciaFormReducao(){
    this.reducaoFormGroup = this.fb.group({
      reducao: this.fb.array([])
    });
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (!this.rdReducao.checked) {
      this.iniciaFormReducao();
    }

    if (this.rdPreservado.checked) {
      this.termoSelecionado.emit(this.rdPreservado.id);
    }

    if(this.rdReducao){
      if(this.rdReducao.checked){
        this.termoSelecionado.emit(this.rdReducao.id);

        let posicao = this.reducaoFormGroup.value.reducao;
        let posicaoTratada = this.replaceVirgula(posicao.toString());

        if(posicao.length > 0){
          if(posicao.length == 1){
            let objeto = {id: 5172, value: posicao};
            let elemento = {elemento: objeto, isPrimeiraLesao: true};
            this.termosGenericos.emit(elemento);

          }else if(posicao.length > 1){
            let objeto = {id: 5173, value: posicaoTratada};
            let elemento = {elemento: objeto, isPrimeiraLesao: true};
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
    this.listaCompleta.emit();
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }

}
