import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-termo-uso-dialog',
  templateUrl: './termo-uso-dialog.component.html',
  styleUrls: ['./termo-uso-dialog.component.css']
})
export class TermoUsoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TermoUsoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}
