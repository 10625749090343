import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-sistema-ventricular',
  templateUrl: './sistema-ventricular.component.html',
  styleUrls: ['./sistema-ventricular.component.css']
})
export class SistemaVentricularComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('rdSupratentorial') rdSupratentorial: MatRadioButton;
  @ViewChild('rdSupratentorialeinfratentorial') rdSupratentorialeinfratentorial: MatRadioButton;
  @ViewChild('rdSimétrica') rdSimétrica: MatRadioButton;
  @ViewChild('rdAssimetrica') rdAssimetrica: MatRadioButton;
  @ViewChild('slGraduacao') slGraduacao: MatSelect;
  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('inMedidaDir') inMedidaDir: ElementRef;
  @ViewChild('inMedidaEsq') inMedidaEsq: ElementRef;



  public graduacao: ArrayDropDown[] = [
    { value: 'Pequena', viewValue: 'Pequena' },
    { value: 'Moderada', viewValue: 'Moderada' },
    { value: 'Acentuada', viewValue: 'Acentuada' },
  ];

  public Lado: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
  ];


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
    };
  }

  public ClickNormal(): any {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  public ClickAlterado(): any {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahSupratentorial = false;
    this.showAndHideTerms.sahSupratentorialeinfratentorial = false;
    this.showAndHideTerms.sahSimétrica = false;
    this.showAndHideTerms.sahAssimétrica = false;
    this.catchIDs();
  }

  public ClickSupratentorial(): any {
    this.verificaAlterado();
    this.catchIDs();
  }

  public ClickSupratentorialeinfratentorial(): any {
    this.verificaAlterado();
    this.catchIDs();
  }

  public ClickSimetrica(): any {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahSupratentorial = false;
    this.showAndHideTerms.sahSupratentorialeinfratentorial = false;
    this.showAndHideTerms.sahSimétrica = true;
    this.showAndHideTerms.sahAssimétrica = false;
    this.catchIDs();
  }

  public ClickAssimetrica(): any {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahSupratentorial = false;
    this.showAndHideTerms.sahSupratentorialeinfratentorial = false;
    this.showAndHideTerms.sahSimétrica = false;
    this.showAndHideTerms.sahAssimétrica = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  public changeSelect(): any {
    this.verificaAlterado();
    this.catchIDs();
  }

  public blurMedida(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {

      if (this.rdSupratentorial && this.rdSupratentorial.checked) {
        this.termoSelecionado.emit(this.rdSupratentorial.id);

        if (this.slGraduacao && this.slGraduacao.value) {
          const objeto = { elemento: this.slGraduacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaDir && this.inMedidaDir.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDir.nativeElement };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEsq && this.inMedidaEsq.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEsq.nativeElement };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdSupratentorialeinfratentorial && this.rdSupratentorialeinfratentorial.checked) {
        this.termoSelecionado.emit(this.rdSupratentorialeinfratentorial.id);

        if (this.slGraduacao && this.slGraduacao.value) {
          const objeto = { elemento: this.slGraduacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaDir && this.inMedidaDir.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDir.nativeElement };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEsq && this.inMedidaEsq.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEsq.nativeElement };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdSimétrica && this.rdSimétrica.checked) {
        this.termoSelecionado.emit(this.rdSimétrica.id);

        if (this.slGraduacao && this.slGraduacao.value) {
          const objeto = { elemento: this.slGraduacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaDir && this.inMedidaDir.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDir.nativeElement };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEsq && this.inMedidaEsq.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEsq.nativeElement };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdAssimetrica && this.rdAssimetrica.checked) {
        this.termoSelecionado.emit(this.rdAssimetrica.id);

        if (this.slGraduacao && this.slGraduacao.value) {
          const objeto = { elemento: this.slGraduacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLado && this.slLado.value) {
          const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaDir && this.inMedidaDir.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDir.nativeElement };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEsq && this.inMedidaEsq.nativeElement.value) {
          const objeto = { elemento: this.inMedidaEsq.nativeElement };
          this.termosGenericos.emit(objeto);
        }
      }
    }
    this.listaCompleta.emit();

  }

  verificaAlterado() {
    if (this.rdSupratentorial.checked
      || this.rdSimétrica.checked
      || this.slGraduacao.value
      || this.slLado.value
      || this.inMedidaDir.nativeElement.value
      || this.inMedidaEsq.nativeElement.value) {
      // Marca Alterado
      this.rdAlterado.checked = true;
    }

  }
}
