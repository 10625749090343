import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatDialog, MatSelect } from '@angular/material';
import { LesoesDireitaComponent } from '../dialog/lesoes-direita/lesoes-direita.component';
import { Observable, of } from 'rxjs';
import { LesoesCarotidas } from '../../../../../../models/Helper/LesoesCarotidas';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-carotida-direita',
  templateUrl: './carotida-direita.component.html',
  styleUrls: ['./carotida-direita.component.css']
})
export class CarotidaDireitaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() adicionaLesaoArray = new EventEmitter();
  @Output() removeLesoes = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  // CMI
  @ViewChild('ckNormalCmi') ckNormalCmi: MatCheckbox;
  @ViewChild('ckEspessamentoCmi') ckEspessamentoCmi: MatCheckbox;
  @ViewChild('ckNaoCaracterizadoCmi') ckNaoCaracterizadoCmi: MatCheckbox;
  @ViewChild('inCmiDireito') inCmiDireito: ElementRef;

  // Medidas
  @ViewChild('ACCSitDireita') ACCSitDireita: ElementRef;
  @ViewChild('ACCDistDireita') ACCDistDireita: ElementRef;
  @ViewChild('ACISistDireita') ACISistDireita: ElementRef;
  @ViewChild('ACIDistDireita') ACIDistDireita: ElementRef;
  @ViewChild('ckVelocidadeAce') ckVelocidadeAce: MatCheckbox;
  @ViewChild('ACESistDireita') ACESistDireita: ElementRef;
  @ViewChild('ACEDistDireita') ACEDistDireita: ElementRef;
  @ViewChild('ckVelocidadeVert') ckVelocidadeVert: MatCheckbox;
  @ViewChild('VertebralSistDireita') VertebralSistDireita: ElementRef;
  @ViewChild('VertebralDistDireita') VertebralDistDireita: ElementRef;

  // Vertebral
  @ViewChild('ckNormalVert') ckNormalVert: MatCheckbox;
  @ViewChild('ckNaoCaracterizadoVert') ckNaoCaracterizadoVert: MatCheckbox;
  @ViewChild('ckRouboVert') ckRouboVert: MatCheckbox;
  @ViewChild('slGraduacao') slGraduacao: MatSelect;

  graduacoes: ArrGenerico[] = [
    { value: 'desaceleração sistólica', viewValue: 'Latente' },
    { value: 'inversão sistólica', viewValue: 'Parcial' },
    { value: 'inversão sisto-diastólica', viewValue: 'Total' }
  ];

  lesoes: LesoesCarotidas[];
  lesoes$: Observable<LesoesCarotidas[]>;

  showAndHideTerms: any;
  countLesoes = 0;
  countListaLesoes = 0;
  isItemExcluido = true;
  unidadeMedida: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    localStorage.removeItem('objLesoesDireita');
    this.showAndHideTerms = {
      sahCmiMedida: true,
      sahRouboVert: false,
      sahVelocidade: true,
      sahVelocidadeAce: false,
      sahVelocidadeVert: false
    };

    this.lesoes = [];

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormalCmi() {
    this.showAndHideTerms.sahCmiMedida = true;

    this.catchIDs();
  }

  clickEspessamentoCmi() {
    this.showAndHideTerms.sahCmiMedida = true;

    this.catchIDs();
  }

  clickNaoCaractCmi() {
    this.showAndHideTerms.sahCmiMedida = false;
    this.catchIDs();
  }

  clickNormalVert() {
    this.showAndHideTerms.sahRouboVert = false;
    this.showAndHideTerms.sahVelocidade = true;
    this.catchIDs();
  }

  clickNaoCaractVert() {
    this.showAndHideTerms.sahRouboVert = false;
    this.showAndHideTerms.sahVelocidade = false;
    this.showAndHideTerms.sahVelocidadeVert = false;
    this.catchIDs();
  }

  clickRouboVer() {
    this.showAndHideTerms.sahRouboVert = true;
    this.showAndHideTerms.sahVelocidade = false;
    this.showAndHideTerms.sahVelocidadeVert = false;
    this.catchIDs();
  }

  clickVelocidadeAce() {
    if (this.showAndHideTerms.sahVelocidadeAce === false) {
      this.showAndHideTerms.sahVelocidadeAce = true;
    } else {
      this.showAndHideTerms.sahVelocidadeAce = false;
    }
    this.catchIDs();
  }

  clickVelocidadeVert() {

    if (this.showAndHideTerms.sahVelocidadeVert === false) {
      this.showAndHideTerms.sahVelocidadeVert = true;
    } else {
      this.showAndHideTerms.sahVelocidadeVert = false;
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  dialogLesoes() {
    const dialogRef = this.dialog.open(LesoesDireitaComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      //this.catchIDs();
      this.termoSelecionado.emit('Clean');
      this.countLesoes = 0;
      this.verificaLesao();
    });
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    // CMI
    this.emitNormalCmi();
    this.emitEspessamentoCmi();
    this.emitNaoCaracterizadoCmi();

    // Vertebral
    this.emitNormalVertebral();
    this.emitNaoCaractVertebral();
    this.emitRouboVertebral();

    // Busca lesões
    this.verificaLesao();

    // Medidas
    debugger
    this.vericaMedidas();
  }

  emitNormalCmi() {
    if (this.ckNormalCmi !== undefined && this.ckNormalCmi.checked) {
      this.termoSelecionado.emit(1058);
      this.termoSelecionado.emit(this.ckNormalCmi.id);

      if (this.inCmiDireito != undefined && this.inCmiDireito.nativeElement.value) {

        const objeto = { elemento: this.inCmiDireito.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      this.listaCompleta.emit(58); // CMI Direito
      this.termoSelecionado.emit('CleanList');
    }
  }

  emitEspessamentoCmi() {
    if (this.ckEspessamentoCmi !== undefined && this.ckEspessamentoCmi.checked) {
      this.termoSelecionado.emit(1058);
      this.termoSelecionado.emit(this.ckEspessamentoCmi.id);

      if (this.inCmiDireito != undefined && this.inCmiDireito.nativeElement.value) {

        const objeto = { elemento: this.inCmiDireito.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      this.listaCompleta.emit(58); // CMI Direito
      this.termoSelecionado.emit('CleanList');
    }
  }

  emitNaoCaracterizadoCmi() {
    if (this.ckNaoCaracterizadoCmi !== undefined && this.ckNaoCaracterizadoCmi.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(1058);
      this.termoSelecionado.emit(this.ckNaoCaracterizadoCmi.id);

      this.listaCompleta.emit(58); // CMI Direito
      this.termoSelecionado.emit('CleanList');
    }
  }

  emitNormalVertebral() {
    if (this.ckNormalVert !== undefined && this.ckNormalVert.checked === true) {
      this.termoSelecionado.emit(1063);
      this.termoSelecionado.emit(this.ckNormalVert.id);

      this.listaCompleta.emit(60); // Vertebral Direito
    }
  }

  emitNaoCaractVertebral() {
    if (this.ckNaoCaracterizadoVert !== undefined && this.ckNaoCaracterizadoVert.checked === true) {
      this.termoSelecionado.emit(1063);
      this.termoSelecionado.emit(this.ckNaoCaracterizadoVert.id);

      this.listaCompleta.emit(60); // Vertebral Direito
    }
  }

  emitRouboVertebral() {
    if (this.ckRouboVert !== undefined && this.ckRouboVert.checked === true) {
      this.termoSelecionado.emit(1063);
      this.termoSelecionado.emit(this.ckRouboVert.id);

      if (this.slGraduacao != undefined && this.slGraduacao.value != undefined) {
        const objeto = { elemento: this.slGraduacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      this.listaCompleta.emit(60); // Vertebral Direito
    }
  }

  vericaMedidas() {
    if (this.ACCSitDireita !== undefined &&
      this.ACCSitDireita.nativeElement.value) {

      const element = { value: this.ACCSitDireita.nativeElement.value, id: this.ACCSitDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ACCSitDireita.nativeElement.value) {
      const element = { value: 0, id: this.ACCSitDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ACCDistDireita !== undefined &&
      this.ACCDistDireita.nativeElement.value) {

      const element = { value: this.ACCDistDireita.nativeElement.value, id: this.ACCDistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ACCDistDireita.nativeElement.value) {
      const element = { value: 0, id: this.ACCDistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ACISistDireita !== undefined &&
      this.ACISistDireita.nativeElement.value) {

      const element = { value: this.ACISistDireita.nativeElement.value, id: this.ACISistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ACISistDireita.nativeElement.value) {
      const element = { value: 0, id: this.ACISistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ACIDistDireita !== undefined &&
      this.ACIDistDireita.nativeElement.value) {

      const element = { value: this.ACIDistDireita.nativeElement.value, id: this.ACIDistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ACIDistDireita.nativeElement.value) {
      const element = { value: 0, id: this.ACIDistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeAce.checked && this.ACESistDireita !== undefined &&
      this.ACESistDireita.nativeElement.value) {

      const element = { value: this.ACESistDireita.nativeElement.value, id: this.ACESistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeAce.checked || !this.ACESistDireita.nativeElement.value) {
      const element = { value: 0, id: "ACESistDireita" }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeAce.checked && this.ACEDistDireita !== undefined &&
      this.ACEDistDireita.nativeElement.value) {

      const element = { value: this.ACEDistDireita.nativeElement.value, id: this.ACEDistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeAce.checked || !this.ACEDistDireita.nativeElement.value) {
      const element = { value: 0, id: "ACEDistDireita" }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeVert.checked && this.VertebralSistDireita !== undefined &&
      this.VertebralSistDireita.nativeElement.value) {

      const element = { value: this.VertebralSistDireita.nativeElement.value, id: this.VertebralSistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeVert.checked || !this.VertebralSistDireita.nativeElement.value) {
      const element = { value: 0, id: "VertebralSistDireita" }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeVert.checked && this.VertebralDistDireita !== undefined &&
      this.VertebralDistDireita.nativeElement.value) {

      const element = { value: this.VertebralDistDireita.nativeElement.value, id: this.VertebralDistDireita.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeVert.checked || !this.VertebralDistDireita.nativeElement.value) {
      const element = { value: 0, id: "VertebralDistDireita" }
      this.enviaMedida.emit(element);
    }
  }

  verificaLesao() {
    const objLesao = JSON.parse(localStorage.getItem('objLesoesDireita'));
    localStorage.removeItem('objLesoesDireita');

    if (objLesao) {
      // Adiciona obj a lista
      this.lesoes.push(objLesao);
    }

    if (this.lesoes.length > 0 && this.lesoes.length > this.countListaLesoes
      || this.lesoes.length > 0 && this.isItemExcluido === true) {
      this.countListaLesoes++;
      this.isItemExcluido = false;

      // Percorre a lista de lesoes
      for (let i = 0; i < this.lesoes.length; i++) {
        const obj = this.lesoes[i];
        obj.position = this.countLesoes;

        const objeto = { id: obj.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        obj.element.forEach(objElemnt => {
          const objeto = { elemento: objElemnt, isPrimeiraLesao: false, posicao: this.countLesoes };
          this.termosGenericos.emit(objeto);

          const objetoEnv = { id: objElemnt.id, value: objElemnt.value, posicao: this.countLesoes };
          this.adicionaLesaoArray.emit(objetoEnv);
        });
        // Envia os resultados
        this.listaCompleta.emit(obj.blockID);

        // Adiciona lista a tela
        this.lesoes$ = of(this.lesoes);
        // Adiciona o count da lesão
        this.countLesoes++;
        this.countLesoes++;
      }
    } else if (this.lesoes.length === 0) {
      this.removeLesoes.emit('direita');
    }
  }

  removeLesao(event) {
    const index = this.lesoes.findIndex(x => x.position === +event);
    if (index > -1) {
      this.lesoes.splice(index, 1);
    }

    //  Ajusta o count da lista
    this.countListaLesoes = this.lesoes.length;
    this.isItemExcluido = true;

    this.catchIDs();
  }
}
