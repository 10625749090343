import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CommonService } from '../services/common.service';
import { TextEditorJsComponent } from './text-editor-js/text-editor-js.component';
import { DescricaoConclusao } from '../models/Helper/DescricaoConclusao';

export interface IAtualizaLinha {
	blockId: number | string;
	descricao?: any;
	conclusao?: any;
	conclusaoNull?: any;
	cabecalho?: any;
	indicacao?: any;
	achados?: any;
	historia?: any;
	lado?: string;
	rodape?: any;
	lateralidade?: any;
	tabela?: any;
	tabelaMagna?: any;
	tabelaParva?: any;
	tabelaVelocidade?: any;
	tabelaCalibre?: any;
	tabelaIR?: any;
	tabelaDoppler?: any;
	calculadora?: any;
  tecnica?: any;
}

@Injectable({
	providedIn: 'root'
})
export class AddText {
	public laudoCompleto: any = [];
	public mask: any;
	public cabecalhoTexto: string;
	public indicacaoTexto: string;
	public descricoes: DescricaoConclusao[];
	public conclusoes: DescricaoConclusao[];
	public achadosAdTexto: string;
	public historiaAdTexto: string;
	public rodapeTexto: string;

	constructor(private _common: CommonService) { }

	iniciaLaudo(): void {
		let maskSplit = this.mask.split('|');
		this.laudoCompleto = [];

		this.laudoCompleto.push({
			'type': 'paragraph',
			'data': {
				'text': `<p>${maskSplit[0]}</p>`,
			}
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'blockMenu': {
				'id': `e-cabecalho`,
				'name': ''
			},
			'data': {
				'text': `<p>${this.cabecalhoTexto}</p>`,
			}
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'blockMenu': {
				'id': `e-indicacao`,
				'name': ''
			},
			'data': {
				'text': `<p>${this.indicacaoTexto}</p>`,
			}
		});

		if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
			this.laudoCompleto.push({
				'type': 'paragraph',
				'data': {
					'text': '<p><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></p>',
				}
			});
		}

		this.laudoCompleto.push({
			'type': 'paragraph',
			'data': {
				'text': `<p>${maskSplit[1]}</p>`,
			}
		});

		this.descricoes.forEach(descricao => {
			this.laudoCompleto.push({
				'type': 'paragraph',
				'blockMenu': {
					'id': `e-${descricao.blockID}`,
					'name': ''
				},
				'data': {
					'text': `<p>${descricao.value}</p>`,
				}
			});
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'data': {
				'text': `<p>${maskSplit[2]}</p>`,
			}
		});

		this.conclusoes.forEach((conclusao, index) => {
			if (conclusao.value && this.descricoes[index].value !== '') {
				this.laudoCompleto.push({
					'type': 'paragraph',
					'blockMenu': {
						'id': `e-conclusao`
					},
					'data': {
						'text': `<p>${conclusao.value}</p>`,
					}
				});
			}
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'data': {
				'text': `<p>${maskSplit[3]}</p>`,
			}
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'blockMenu': {
				'id': `e-achados_adicionais`,
				'name': ''
			},
			'data': {
				'text': `<p>${this.achadosAdTexto}</p>`,
			}
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'blockMenu': {
				'id': `e-historia_clinica`,
				'name': ''
			},
			'data': {
				'text': `<p>${this.historiaAdTexto}</p>`,
			}
		});

		this.laudoCompleto.push({
			'type': 'paragraph',
			'blockMenu': {
			  'id': `e-rodape`,
			  'name': ''
			},
			'data': {
			  'text': `<p>${this.rodapeTexto}</p>`,
			}
		});

		this._common.atualizaEditorJs(this.laudoCompleto);

		setTimeout(() => {
			this.lineHeight();
		}, 100);
	}

	atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
		cabecalho,
		rodape,
		historia
	}: IAtualizaLinha): void {
		if (blockId !== undefined) {
			if (indicacao === undefined && achados === undefined) {
				if (descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);

				const conclusion = document.querySelector('#e-conclusao p');
				if (conclusao !== '' || conclusaoNull !== '') {
					if (conclusion) {
						conclusion.innerHTML = conclusao === ''
							? conclusaoNull !== ''
								? conclusaoNull
								: ''
							: conclusao;
					}
				} else if (conclusao === '' && conclusaoNull === '') {
					this.conclusoes.forEach((conc, index) => {
						if (conc.value && this.descricoes[index].value !== '') {
							conclusao = conc.value;

							if (conclusion) {
								conclusion.innerHTML = conclusao === ''
									? conclusaoNull !== ''
										? conclusaoNull
										: ''
									: conclusao;
							}
						}
					});
				}

				if (description) {
					description.innerHTML = descricao;
				} else if (!description) {
					this.iniciaLaudo();
				} else if (!description && !conclusion) {
					this.iniciaLaudo();
				}
			} else if (indicacao || achados  || cabecalho || rodape) {
				if(indicacao !== undefined) {
					const recommendation = document.querySelector(`#e-${blockId} p`);

					if(recommendation) {
					  recommendation.innerHTML = indicacao;
					} else {
					  this.iniciaLaudo();
					}
						  }


				  if(achados !== undefined) {
					const findings = document.querySelector(`#e-${blockId} p`);

					if(findings) {
								  findings.innerHTML = achados;
					} else {
					  this.iniciaLaudo();
					}
						  }

				  if(cabecalho !== undefined) {
					const header = document.getElementById(`#e-${blockId} p`);

					if(header) {
								  header.innerHTML = cabecalho;
					}
				  }


				  if(rodape !== undefined) {
					debugger
					const footer = document.getElementById(`#e-${blockId} p`);

					if(footer) {
								  footer.innerHTML = rodape;
					}
				  }
			}

			if (indicacao === undefined && historia === undefined) {
				if (descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);

				const conclusion = document.querySelector('#e-conclusao p');
				if (conclusao !== '' || conclusaoNull !== '') {
					if (conclusion) {
						conclusion.innerHTML = conclusao === ''
							? conclusaoNull !== ''
								? conclusaoNull
								: ''
							: conclusao;
					}
				} else if (conclusao === '' && conclusaoNull === '') {
					this.conclusoes.forEach((conc, index) => {
						if (conc.value && this.descricoes[index].value !== '') {
							conclusao = conc.value;

							if (conclusion) {
								conclusion.innerHTML = conclusao === ''
									? conclusaoNull !== ''
										? conclusaoNull
										: ''
									: conclusao;
							}
						}
					});
				}

				if (description) {
					description.innerHTML = descricao;
				} else if (!description) {
					this.iniciaLaudo();
				} else if (!description && !conclusion) {
					this.iniciaLaudo();
				}
			} else if (indicacao || historia  || cabecalho || rodape) {
				if(indicacao !== undefined) {
					const recommendation = document.querySelector(`#e-${blockId} p`);

					if(recommendation) {
					  recommendation.innerHTML = indicacao;
					} else {
					  this.iniciaLaudo();
					}
						  }


				  if(historia !== undefined) {
					const findings = document.querySelector(`#e-${blockId} p`);

					if(findings) {
								  findings.innerHTML = historia;
					} else {
					  this.iniciaLaudo();
					}
						  }

				  if(cabecalho !== undefined) {
					const header = document.getElementById(`#e-${blockId} p`);

					if(header) {
								  header.innerHTML = cabecalho;
					}
				  }


				  if(rodape !== undefined) {
					debugger
					const footer = document.getElementById(`#e-${blockId} p`);

					if(footer) {
								  footer.innerHTML = rodape;
					}
				  }
			}

			this.lineHeight();
		}
	}

	lineHeight(): void {
		const paragraphs = Array.from(document.getElementsByClassName('ce-paragraph'));

		paragraphs.forEach(paragraph => {
			if (paragraph.textContent === '') {
				paragraph.parentElement.parentElement.style.width = '0px';
				paragraph.parentElement.parentElement.style.height = '0px';
				paragraph.parentElement.parentElement.style.visibility = 'hidden';
				paragraph.parentElement.parentElement.style.opacity = '0';
			} else {
				paragraph.parentElement.parentElement.style.width = '100%';
				paragraph.parentElement.parentElement.style.height = '100%';
				paragraph.parentElement.parentElement.style.visibility = 'visible';
				paragraph.parentElement.parentElement.style.opacity = '1';
			}
		});
	}
}
