import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-articulacao-glenoumeral',
  templateUrl: './articulacao-glenoumeral.component.html',
  styleUrls: ['./articulacao-glenoumeral.component.css']
})
export class ArticulacaoGlenoumeralComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdDerrame') rdDerrame: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;

  volumes: ArrGenerico[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahDerrame: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahDerrame = false;
    this.catchIDs();
  }

  clickIrregularidade() {
    this.showAndHideTerms.sahDerrame = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slVolume && this.slVolume.value)){
      this.rdDerrame.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdDerrame.checked) {
      this.termoSelecionado.emit(this.rdDerrame.value);
      
      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
