import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatRadioButton } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-liquido-amniotico-gemelar',
  templateUrl: './liquido-amniotico-gemelar.component.html',
  styleUrls: ['./liquido-amniotico-gemelar.component.css']
})
export class LiquidoAmnioticoGemelarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdOligo') rdOligo: MatRadioButton;
  @ViewChild('rdPoli') rdPoli: MatRadioButton;
  @ViewChild('rdAnid') rdAnid: MatRadioButton;
  @ViewChild('inMBV') inMBV: ElementRef;

  @ViewChild('rdNormal2') rdNormal2: MatRadioButton;
  @ViewChild('rdOligo2') rdOligo2: MatRadioButton;
  @ViewChild('rdPoli2') rdPoli2: MatRadioButton;
  @ViewChild('rdAnid2') rdAnid2: MatRadioButton;
  @ViewChild('inMBV2') inMBV2: ElementRef;

  @ViewChild('rdNormal3') rdNormal3: MatRadioButton;
  @ViewChild('rdOligo3') rdOligo3: MatRadioButton;
  @ViewChild('rdPoli3') rdPoli3: MatRadioButton;
  @ViewChild('rdAnid3') rdAnid3: MatRadioButton;
  @ViewChild('inMBV3') inMBV3: ElementRef;

  @ViewChild('rdNormal4') rdNormal4: MatRadioButton;
  @ViewChild('rdOligo4') rdOligo4: MatRadioButton;
  @ViewChild('rdPoli4') rdPoli4: MatRadioButton;
  @ViewChild('rdAnid4') rdAnid4: MatRadioButton;
  @ViewChild('inMBV4') inMBV4: ElementRef;

  inMBVInvalid: boolean = true;
  inMBVInvalid2: boolean = true;
  inMBVInvalid3: boolean = true;
  inMBVInvalid4: boolean = true;
  fetoSelected: number = 1;
  showAndHideFetos: any;


  constructor(public dialog: MatDialog,
    private _common: CommonService) { }

  ngOnInit() {
    this._common.posicaoFeto.subscribe((feto: any) => {
      this.fetoSelected = feto;
      this.showAndHideFetos = {
        feto1: feto === 1,
        feto2: feto === 2,
        feto3: feto === 3,
        feto4: feto === 4,
      };
    });

    this.showAndHideFetos = {
      feto1: true,
      feto2: false,
      feto3: false,
      feto4: false,
    };
  }

  clickNormal() {
    this.catchIDs();
  }

  clickNormal2() {
    this.catchIDs();
  }

  clickNormal3() {
    this.catchIDs();
  }

  clickNormal4() {
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.removeInvalidField();

    if (this.fetoSelected === 1) {
      if (this.inMBV && this.inMBV.nativeElement.value) {
        if (this.inMBV.nativeElement.value == 0) {
          this.termoSelecionado.emit(this.rdAnid.id);
          this.changeChecked(this.rdAnid.id);
        }
        if (this.inMBV.nativeElement.value > 0 && this.inMBV.nativeElement.value < 2) {
          this.termoSelecionado.emit(this.rdOligo.id);
          this.changeChecked(this.rdOligo.id);
        }
        if (this.inMBV.nativeElement.value > 8) {
          this.termoSelecionado.emit(this.rdPoli.id);
          this.changeChecked(this.rdPoli.id);
        }
        if (this.inMBV.nativeElement.value <= 8 && this.inMBV.nativeElement.value >= 2) {
          this.termoSelecionado.emit(this.rdNormal.id);
          this.changeChecked(this.rdNormal.id);
        }
      }

      if (this.inMBV && this.inMBV.nativeElement.value) {
        const objetoElemento = { id: this.inMBV.nativeElement.id, value: this.inMBV.nativeElement.value + 'cm' };
        const objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'LiquidoAmniotico' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'LiquidoAmniotico' });
      }
    } else if (this.fetoSelected === 2) {
      if (this.inMBV2 && this.inMBV2.nativeElement.value) {
        if (this.inMBV2.nativeElement.value == 0) {
          this.termoSelecionado.emit(this.rdAnid2.id);
          this.changeChecked(this.rdAnid2.id);
        }
        if (this.inMBV2.nativeElement.value > 0 && this.inMBV2.nativeElement.value < 2) {
          this.termoSelecionado.emit(this.rdOligo2.id);
          this.changeChecked(this.rdOligo2.id);
        }
        if (this.inMBV2.nativeElement.value > 8) {
          this.termoSelecionado.emit(this.rdPoli2.id);
          this.changeChecked(this.rdPoli2.id);
        }
        if (this.inMBV2.nativeElement.value <= 8 && this.inMBV2.nativeElement.value >= 2) {
          this.termoSelecionado.emit(this.rdNormal2.id);
          this.changeChecked(this.rdNormal2.id);
        }
      }

      if (this.inMBV2 && this.inMBV2.nativeElement.value) {
        const objetoElemento = { id: this.inMBV2.nativeElement.id, value: this.inMBV2.nativeElement.value + 'cm' };
        const objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'LiquidoAmniotico' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'LiquidoAmniotico' });
      }
    } else if (this.fetoSelected === 3) {
      if (this.inMBV3 && this.inMBV3.nativeElement.value) {
        if (this.inMBV3.nativeElement.value == 0) {
          this.termoSelecionado.emit(this.rdAnid3.id);
          this.changeChecked(this.rdAnid3.id);
        }
        if (this.inMBV3.nativeElement.value > 0 && this.inMBV3.nativeElement.value < 2) {
          this.termoSelecionado.emit(this.rdOligo3.id);
          this.changeChecked(this.rdOligo3.id);
        }
        if (this.inMBV3.nativeElement.value > 8) {
          this.termoSelecionado.emit(this.rdPoli3.id);
          this.changeChecked(this.rdPoli3.id);
        }
        if (this.inMBV3.nativeElement.value <= 8 && this.inMBV3.nativeElement.value >= 2) {
          this.termoSelecionado.emit(this.rdNormal3.id);
          this.changeChecked(this.rdNormal3.id);
        }
      }

      if (this.inMBV3 && this.inMBV3.nativeElement.value) {
        const objetoElemento = { id: this.inMBV3.nativeElement.id, value: this.inMBV3.nativeElement.value + 'cm' };
        const objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'LiquidoAmniotico' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'LiquidoAmniotico' });
      }
    } else if (this.fetoSelected === 4) {
      if (this.inMBV4 && this.inMBV4.nativeElement.value) {
        if (this.inMBV4.nativeElement.value == 0) {
          this.termoSelecionado.emit(this.rdAnid4.id);
          this.changeChecked(this.rdAnid4.id);
        }
        if (this.inMBV4.nativeElement.value > 0 && this.inMBV4.nativeElement.value < 2) {
          this.termoSelecionado.emit(this.rdOligo4.id);
          this.changeChecked(this.rdOligo4.id);
        }
        if (this.inMBV4.nativeElement.value > 8) {
          this.termoSelecionado.emit(this.rdPoli4.id);
          this.changeChecked(this.rdPoli4.id);
        }
        if (this.inMBV4.nativeElement.value <= 8 && this.inMBV4.nativeElement.value >= 2) {
          this.termoSelecionado.emit(this.rdNormal4.id);
          this.changeChecked(this.rdNormal4.id);
        }
      }

      if (this.inMBV4 && this.inMBV4.nativeElement.value) {
        const objetoElemento = { id: this.inMBV4.nativeElement.id, value: this.inMBV4.nativeElement.value + 'cm' };
        const objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'LiquidoAmniotico' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'LiquidoAmniotico' });
      }
    }

    this.listaCompleta.emit();
  }

  changeChecked(id) {
    if (id === '2002f1') {
      this.rdNormal.checked = true;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2003f1') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = true;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2004f1') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = true;
      this.rdAnid.checked = false;
    } else if (id === '5645f1') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = true;
    } else if (id === '2002f2') {
      this.rdNormal.checked = true;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2003f2') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = true;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2004f2') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = true;
      this.rdAnid.checked = false;
    }else if (id === '5645f2') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = true;
    } else if (id === '2002f3') {
      this.rdNormal.checked = true;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2003f3') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = true;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2004f3') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = true;
      this.rdAnid.checked = false;
    } else if (id === '5645f3') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = true;
    } else if (id === '2002f4') {
      this.rdNormal.checked = true;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2003f4') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = true;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '2004f4') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = true;
      this.rdAnid.checked = false;
    } else if (id === '5645f4') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = true;
    }
  }

  removeInvalidField(){
    if (this.fetoSelected === 1) {
    this.inMBVInvalid = this.inMBV && this.inMBV.nativeElement.value ? false : true;
    } else if (this.fetoSelected === 2) {
      this.inMBVInvalid2 = this.inMBV2 && this.inMBV2.nativeElement.value ? false : true;
    } else if (this.fetoSelected === 3) {
      this.inMBVInvalid3 = this.inMBV && this.inMBV3.nativeElement.value ? false : true;
    }  else if (this.fetoSelected === 4) {
      this.inMBVInvalid4 = this.inMBV && this.inMBV4.nativeElement.value ? false : true;
    }

  }

}
