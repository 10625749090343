import { PowerBIToken } from './../models/BO/PowerBIToken';
import { PowerBIService } from './../services/powerBI.service';
import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { models, service } from 'powerbi-client';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {

  adminUser: boolean;
  embedToken: string;
  isEmbedded = false;
  displayMessage = '';
  reportClass = 'reportClass hidden';
  safeURLPower: SafeResourceUrl;

  embedConfig =
    {
      type: "report",
      id: "18317a26-f1d1-436d-8470-e82c1e3a43c6",
      embedUrl: "https://app.powerbi.com/reportEmbed?reportId=18317a26-f1d1-436d-8470-e82c1e3a43c6&groupId=d08a4f10-6d44-479d-8ed3-22a740ff9517&w=2",
      accessToken: undefined,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          }
        },
        background: models.BackgroundType.Transparent,
      }
    }

  eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report has loaded')],
    ['rendered', () => console.log('Report rendered')],
    ['error', (event?: service.ICustomEvent<any>) => console.error(event ? event.detail : "no event obj")],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]);

  constructor(
    private userService: UserService,
    public sanitizer: DomSanitizer,
    private powerBI: PowerBIService
  ) { }

  ngOnInit() {
    this.verificarAdmin();
    this.embedReport();
    // this.openPowerBIHapvida();
  }

  verificarAdmin() {
    this.userService.buscarUsuario(parseInt(localStorage.getItem('userID')))
      .subscribe((user) => {
        if (user.profileID === 3) {
          this.adminUser = true;
        } else {
          this.adminUser = false;
        }
      })
  }

  async embedReport() {
    let reportConfigResponse: PowerBIToken;
    reportConfigResponse = await this.powerBI.getAccessToken().toPromise();
    this.embedConfig = {
      ...this.embedConfig,
      accessToken: reportConfigResponse.token,
    };
    console.log(this.embedConfig.accessToken)
  }

  openPowerBIHapvida() {
    const embedUrl = "https://app.powerbi.com/view?r=eyJrIjoiNjVkYTYwYzktNjZmNy00MWE3LThhNDUtZjJkZTI5YWI2ZGQyIiwidCI6IjUzMjMzZGQ1LTQzM2MtNDM3YS05ZjJhLWZiMzE5NmEwOGZlMCJ9&pageName=ReportSection10c151d5552a9318b2a9";
    this.safeURLPower = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

}
