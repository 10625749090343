import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArteriaDoMembroInferiorComponent } from './arteria-do-membro-inferior.component';
import {
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { ArteriaFemoralComumComponent } from './TermosComponents/arteria-femoral-comum/arteria-femoral-comum.component';
import { ArteriaFemoralSuperficialComponent } from './TermosComponents/arteria-femoral-superficial/arteria-femoral-superficial.component';
import { ArteriaFemoralProfundaComponent } from './TermosComponents/arteria-femoral-profunda/arteria-femoral-profunda.component';
import { ArteriaPopliteaComponent } from './TermosComponents/arteria-poplitea/arteria-poplitea.component';
import { ArteriaTibialAnteriorComponent } from './TermosComponents/arteria-tibial-anterior/arteria-tibial-anterior.component';
import { ArteriaTibialPosteriorComponent } from './TermosComponents/arteria-tibial-posterior/arteria-tibial-posterior.component';
import { ArteriaFibularComponent } from './TermosComponents/arteria-fibular/arteria-fibular.component';
import { AteromatoseComponent } from './TermosComponents/ateromatose/ateromatose.component';
import { AchadosAdicionaisInferiorComponent } from './TermosComponents/achados-adicionais-inferior/achados-adicionais-inferior.component';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';


@NgModule({
    imports: [
        MatExpansionModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatCardModule,
        DragDropModule,
        MatMenuModule,
        MatTabsModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        FormsModule,
        AngularEditorModule,
        HttpClientModule,
        LayoutModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        TextEditorModule,
        CustomDirectiveModule,
        LateralidadeModule,
        LaudoFooterModule,
        SharedModule, 
        TextEditorJSmodule,
        MatSlideToggleModule,
    ],
    exports: [
        ArteriaDoMembroInferiorComponent
    ],
    declarations: [
        ArteriaDoMembroInferiorComponent,
        ArteriaFemoralComumComponent,
        ArteriaFemoralSuperficialComponent,
        ArteriaFemoralProfundaComponent,
        ArteriaPopliteaComponent,
        ArteriaTibialAnteriorComponent,
        ArteriaTibialPosteriorComponent,
        ArteriaFibularComponent,
        AteromatoseComponent,
        AchadosAdicionaisInferiorComponent
    ]
})
export class ArteriaDoMembroInferiorModule { }