import { NgModule } from '@angular/core';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MamaComponent} from './mama.component';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { IndicacaoComponent } from './TermosComponents/indicacao/indicacao.component';
import { CistoComponent } from './TermosComponents/cisto/cisto.component';
import { NoduloComponent } from './TermosComponents/nodulo/nodulo.component';
import { EctasiaDuctalComponent } from './TermosComponents/ectasia-ductal/ectasia-ductal.component';
import { DistorcaoArquiteturalComponent } from './TermosComponents/distorcao-arquitetural/distorcao-arquitetural.component';
import { ImplanteMamarioComponent } from './TermosComponents/implante-mamario/implante-mamario.component';
import { LinfonodomegaliaComponent } from './TermosComponents/linfonodomegalia/linfonodomegalia.component';
import { ExameComparativoComponent } from './TermosComponents/exame-comparativo/exame-comparativo.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { CirurgiaComponent } from './TermosComponents/cirurgia/cirurgia.component';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule, 
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    MamaComponent,
    IndicacaoComponent,
    CistoComponent,
    NoduloComponent,
    EctasiaDuctalComponent,
    DistorcaoArquiteturalComponent,
    ImplanteMamarioComponent,
    LinfonodomegaliaComponent,
    ExameComparativoComponent,
    CirurgiaComponent
  ],
  declarations: [
    MamaComponent,
    IndicacaoComponent,
    CistoComponent,
    NoduloComponent,
    EctasiaDuctalComponent,
    DistorcaoArquiteturalComponent,
    ImplanteMamarioComponent,
    LinfonodomegaliaComponent,
    ExameComparativoComponent,
    CirurgiaComponent
  ]
})
export class MamaModule { }
