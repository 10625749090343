import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-coracao',
  templateUrl: './coracao.component.html',
  styleUrls: ['./coracao.component.css']
})
export class CoracaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckCardiomegalia') ckCardiomegalia: MatCheckbox;
  @ViewChild('slDimensao') slDimensao: MatSelect;
  @ViewChild('inVD') inVD: ElementRef;
  @ViewChild('inVE') inVE: ElementRef;

  @ViewChild('ckDerrame') ckDerrame: MatCheckbox;
  @ViewChild('slGrauDerrame') slGrauDerrame: MatSelect;
  @ViewChild('inEspessura') inEspessura: ElementRef;

  dimensoes: ArrayDropDown[] = [
    { value: 'global', viewValue: 'Global' },
    { value: 'às custas das câmaras esquerdas', viewValue: 'Custas das câmaras esquerdas' },
    { value: 'às custas das câmaras direitas', viewValue: 'Custas das câmaras direitas' }
  ];

  listaGrau: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahCardiomegalia: false,
      sahDerramePericardio: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahCardiomegalia = false;
    this.showAndHideTerms.sahDerramePericardio = false;

    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahCardiomegalia = false;
    this.showAndHideTerms.sahDerramePericardio = false;

    this.catchIDs();
  }

  clickCardiomegalia() {
    if (this.showAndHideTerms.sahCardiomegalia === false)
      this.showAndHideTerms.sahCardiomegalia = true;
    else
      this.showAndHideTerms.sahCardiomegalia = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }
  clickDerramePericardio() {
    if (this.showAndHideTerms.sahDerramePericardio === false)
      this.showAndHideTerms.sahDerramePericardio = true;
    else
      this.showAndHideTerms.sahDerramePericardio = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      this.emitCardimegalia();

      if (this.countLesoes > 0)
      this.emitDerrameLesao();
      else
        this.emitDerrame();

    }

    this.listaCompleta.emit();
  }

  emitCardimegalia() {
    if (this.ckCardiomegalia && this.ckCardiomegalia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCardiomegalia.id);

      if (this.slDimensao && this.slDimensao.value) {
        const objeto = { elemento: this.slDimensao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inVD && this.inVD.nativeElement.value) {
        const objeto = { elemento: this.inVD.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inVE && this.inVE.nativeElement.value) {
        const objeto = { elemento: this.inVE.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDerrame() {
    if (this.ckDerrame && this.ckDerrame.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDerrame.id);

      if (this.slGrauDerrame && this.slGrauDerrame.value) {
        const objeto = { elemento: this.slGrauDerrame, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura && this.inEspessura.nativeElement.value) {
        const objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDerrameLesao() {
    if (this.ckDerrame && this.ckDerrame.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckDerrame.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slGrauDerrame && this.slGrauDerrame.value) {
        const objeto = { elemento: this.slGrauDerrame, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura && this.inEspessura.nativeElement.value) {
        const objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckCardiomegalia.checked &&
      !this.ckDerrame.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}