import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-parenquima-mamario',
  templateUrl: './parenquima-mamario.component.html',
  styleUrls: ['./parenquima-mamario.component.css']
})
export class ParenquimaMamarioComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdPratLipo') rdPratLipo: MatRadioButton;
  @ViewChild('rdPredoLipos') rdPredoLipos: MatRadioButton;
  @ViewChild('rdModDen') rdModDen: MatRadioButton;
  @ViewChild('rdMamasDensas') rdMamasDensas: MatRadioButton;

  showAndHideTerms: any;
  rdId: string;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMama: true
    };
  }

  clickDefault() {
    this.showAndHideTerms.sahMama = true;
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    // if (this.rdNormal && this.rdNormal.checked) {
    //   this.termoSelecionado.emit(this.rdNormal.id);
    // }

        //   normal
    this.termoSelecionado.emit("4094");

    if (this.rdPratLipo && this.rdPratLipo.checked) {
      this.termoSelecionado.emit(this.rdPratLipo.id);
    }

    if (this.rdPredoLipos && this.rdPredoLipos.checked) {
      this.termoSelecionado.emit(this.rdPredoLipos.id);
    }

    if (this.rdModDen && this.rdModDen.checked) {
      this.termoSelecionado.emit(this.rdModDen.id);
    }

    if (this.rdMamasDensas && this.rdMamasDensas.checked) {
      this.termoSelecionado.emit(this.rdMamasDensas.id);
    }

    this.listaCompleta.emit();
  }

}
