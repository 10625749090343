import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';


@Component({
  selector: 'app-arteria-segmentares-esquerda',
  templateUrl: './arteria-segmentares-esquerda.component.html',
  styleUrls: ['./arteria-segmentares-esquerda.component.css']
})
export class ArteriaSegmentaresEsquerdaComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdestenoseindireta') rdestenoseindireta: MatRadioButton;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      SahNormal: false,
      SahEstenoseindireta: false,
    };
  }
  public ClickNormal() {
    this.showAndHideTerms.SahNormal = true;
    this.showAndHideTerms.SahEstenoseindireta = false;
    this.catchIDs();
  }

  public ClickEstenoseindireta() {
    this.showAndHideTerms.SahNormal = false;
    this.showAndHideTerms.SahEstenoseindireta = true;
    this.catchIDs();
  }


  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdestenoseindireta.checked) {
      this.termoSelecionado.emit(this.rdestenoseindireta.id);
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();

  }
}
