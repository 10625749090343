import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-rx-coluna-lombar',
  templateUrl: './rx-coluna-lombar.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./rx-coluna-lombar.component.css']
})

export class RxColunaLombarComponent implements OnInit {

  descricoes: DescricaoConclusao[] = [
    {
      value: '',//tecnica
      blockID: 398, textTermID: 0, visivel: true
    },
    {
      value: 'Textura óssea preservada.',
      blockID: 399, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 400, textTermID: 0, visivel: true
    },
    {
      value: 'Alinhamento preservado dos corpos vertebrais.',
      blockID: 401, textTermID: 0, visivel: true
    },
    {
      value: 'Ausência de lesões ósseas focais.',
      blockID: 402, textTermID: 0, visivel: true
    },
    {
      value: 'Corpos vertebrais lombares com altura preservada.',
      blockID: 403, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 404, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 405, textTermID: 0, visivel: true
    },
    {
      value: 'Espaços discais lombares sem alterações.',
      blockID: 406, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 407, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 408, textTermID: 0, visivel: true
    },
  ];

  conclusoes: DescricaoConclusao[] = [];

  genericMegapofise: TermosGenericos[] = [
    { value: '', selected: false, termID: 5220, term: '[Lateralidade]'}
   ];

  genericAlinhamento: TermosGenericos[] = [
    { value: '', selected: false, termID: 5230, term: '[LateralidadeEscoliose]'},
    { value: '', selected: false, termID: 5233, term: '[LateralidadeSinuosaUm]'},
    { value: '', selected: false, termID: 5234, term: '[NivelSinuosaUm]'},
    { value: '', selected: false, termID: 5235, term: '[LateralidadeSinuosaDois]'},
    { value: '', selected: false, termID: 5236, term: '[NivelSinuosaDois]'},
    { value: '', selected: false, termID: 5238, term: '[GrauAnterolistese]'},
    { value: '', selected: false, termID: 5239, term: '[NivelAnterolistese]'},
    { value: '', selected: false, termID: 5241, term: '[NivelEspondilolise]'},
    { value: '', selected: false, termID: 5242, term: '[LateralidadeEspondilolise]'},
    { value: '', selected: false, termID: 5245, term: '[NivelDireita]'},
    { value: '', selected: false, termID: 5246, term: '[NivelEsquerda]'},
    { value: '', selected: false, termID: 5248, term: '[GrauRetrolistese]'},
    { value: '', selected: false, termID: 5249, term: '[NivelRetrolistese]'},
  ];

  genericLesoesOsseas: TermosGenericos[] = [
    { value: '', selected: false, termID: 5285, term: '[NivelLitica]'},
    { value: '', selected: false, termID: 5288, term: '[NivelEsclerotica]'},
  ];

  genericCorposVertebrais: TermosGenericos[] = [
    { value: '', selected: false, termID: 5292, term: '[NivelOsteofito]' },
    { value: '', selected: false, termID: 5295, term: '[NivelFraturaUnica]' },
    { value: '', selected: false, termID: 5296, term: '[NivelFraturaMultipla]' },
    { value: '', selected: false, termID: 5299, term: '[NivelFraturaReducao]' },
    { value: '', selected: false, termID: 5300, term: '[ReducaoDaAltura]' },
  ];

  genericEspacosDiscais: TermosGenericos[] = [
    { value: '', selected: false, termID: 5334, term: '[nivel]'},
    { value: '', selected: false, termID: 5335, term: '[nivel]'},
  ]

  genericPosCirurgico: TermosGenericos[] = [
    { value: '', selected: false, termID: 5306, term: '[LateralidadeSoltura]'},
    { value: '', selected: false, termID: 5307, term: '[NivelSoltura]'},
    { value: '', selected: false, termID: 5309, term: '[LateralidadeFraturaParafuso]'},
    { value: '', selected: false, termID: 5310, term: '[NivelFraturaParafuso]'},
    { value: '', selected: false, termID: 5312, term: '[NivelEspacador]'},
    { value: '', selected: false, termID: 5314, term: '[NivelEspacadorAncora]'},
    { value: '', selected: false, termID: 5316, term: '[NivelProtese]'},
    { value: '', selected: false, termID: 5318, term: '[LateralidadeFraturaHaste]'},
    { value: '', selected: false, termID: 5319, term: '[NivelFraturaHaste]'},
  ]

  genericProcessoTransverso: TermosGenericos[] = [
    { value: '', selected: false, termID: 5327, term: '[NivelDireita]' },
    { value: '', selected: false, termID: 5329, term: '[NivelDireitaMult]' },
    { value: '', selected: false, termID: 5328, term: '[NivelEsquerda]' },
    { value: '', selected: false, termID: 5330, term: '[NivelEsquerdaMult]' },
  ];

  genericInterapofisarias: TermosGenericos[] = [
    { value: '', selected: false, termID: 5338, term: '[Nivel]' },
  ];

  genericProcessosEspinhosos: TermosGenericos[] = [
    { value: '', selected: false, termID: 5271, term: '[Nível]'},
    { value: '', selected: false, termID: 5272, term: '[Nível]'},
  ];

  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public _dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
  ) { }

  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  cenarioCorrente = 0;
  objetoGrupoLesao: any;

  Subtitulo: string;
  subtituloBloco: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  indicacaoTexto = '';
  achadosAdTexto = '';
  cabecalhoTexto = '';
  Lado = '';
  rodapeTexto = '';

  cabecalho = false;
  indicacao = false;
  tecnica = true;
  densidadeOssea = false;
  megapofiseTransversa = false;
  alinhamento = false;
  lesoesOsseas = false;
  corposVertebrais = false;
  processoTransverso = false;
  interapofisarias = false;
  espacosDiscais = false;
  posCirurgico = false;
  processosEspinhosos = false;
  achados = false;
  rodape = false;

  public showForm: Boolean = true;
  public unidadeMedida: string;
  public switchFormReport = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  medicalReportTypeID = 84;

  tecnicaId = 398;
  densidadeOsseaId = 399;
  megapofiseTransversaId = 400;
  alinhamentoId = 401;
  lesoesOsseasId = 402;
  corposVertebraisId = 403;
  processoTransversoId = 404;
  interapofisariasId = 405;
  espacosDiscaisId = 406;
  posCirurgicoId = 407;
  processosEspinhososId = 408;

  ngOnInit() {

    this.utilizarEditor();
    this._pouchService.loadDatabase("rxColunaLombar", this.descricoes, this.conclusoes);
    this.subtituloBloco = this.Subtitulo;
    this.Subtitulo = 'Técnica';
    this.term.listTermID = new Array<number>();
    this.resetaDialog();

    const listBlocks = [
      this.tecnicaId,
      this.densidadeOsseaId,
      this.megapofiseTransversaId,
      this.alinhamentoId,
      this.lesoesOsseasId,
      this.corposVertebraisId,
      this.processoTransversoId,
      this.interapofisariasId,
      this.espacosDiscaisId,
      this.posCirurgicoId,
      this.processosEspinhososId,
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "rxColunaLombarMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "colunaLombar");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-coluna-lombar');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-coluna-lombar');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo:'preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Técnica',
      id: this.tecnicaId,
      name: 'tecnica',
      selected: true
    },
    {
      titulo: 'Densidade Óssea',
      id: this.densidadeOsseaId,
      name: 'densidadeOssea',
      selected: false
    },
    {
      titulo: 'Megapófise Transversa',
      id: this.megapofiseTransversaId,
      name: 'megapofiseTransversa',
      selected: false
    },
    {
      titulo: 'Alinhamento',
      id: this.alinhamentoId,
      name: 'alinhamento',
      selected: false
    },
    {
      titulo: 'Lesões Ósseas',
      id: this.lesoesOsseasId,
      name: 'lesoesOsseas',
      selected: false
    },
    {
      titulo: 'Corpos Vertebrais',
      id: this.corposVertebraisId,
      name: 'corposVertebrais',
      selected: false
    },
    {
      titulo: 'Processo Transverso',
      id: this.processoTransversoId,
      name: 'processoTransverso',
      selected: false
    },
    {
      titulo: 'Interapofisárias',
      id: this.interapofisariasId,
      name: 'interapofisarias',
      selected: false
    },
    {
      titulo: 'Espaços Discais',
      id: this.espacosDiscaisId,
      name: 'espacosDiscais',
      selected: false
    },
    {
      titulo: 'Pós-Cirúrgico',
      id: this.posCirurgicoId,
      name: 'posCirurgico',
      selected: false
    },
    {
      titulo: 'Processos Espinhosos',
      id: this.processosEspinhososId,
      name: 'processosEspinhosos',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo:'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tecnica = (itemSelecionado.id === this.tecnicaId);
    this.densidadeOssea = (itemSelecionado.id === this.densidadeOsseaId);
    this.megapofiseTransversa = (itemSelecionado.id === this.megapofiseTransversaId);
    this.alinhamento = (itemSelecionado.id === this.alinhamentoId);
    this.lesoesOsseas = (itemSelecionado.id === this.lesoesOsseasId);
    this.corposVertebrais = (itemSelecionado.id === this.corposVertebraisId);
    this.processoTransverso = (itemSelecionado.id === this.processoTransversoId);
    this.interapofisarias = (itemSelecionado.id === this.interapofisariasId);
    this.espacosDiscais = (itemSelecionado.id === this.espacosDiscaisId);
    this.posCirurgico = (itemSelecionado.id === this.posCirurgicoId);
    this.processosEspinhosos = (itemSelecionado.id === this.processosEspinhososId);
    this.achados = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {

    this.genericMegapofise = [
      { value: '', selected: false, termID: 5220, term: '[Lateralidade]'}
     ];

    this.genericAlinhamento = [
      { value: '', selected: false, termID: 5230, term: '[LateralidadeEscoliose]'},
      { value: '', selected: false, termID: 5233, term: '[LateralidadeSinuosaUm]'},
      { value: '', selected: false, termID: 5234, term: '[NivelSinuosaUm]'},
      { value: '', selected: false, termID: 5235, term: '[LateralidadeSinuosaDois]'},
      { value: '', selected: false, termID: 5236, term: '[NivelSinuosaDois]'},
      { value: '', selected: false, termID: 5238, term: '[GrauAnterolistese]'},
      { value: '', selected: false, termID: 5239, term: '[NivelAnterolistese]'},
      { value: '', selected: false, termID: 5241, term: '[NivelEspondilolise]'},
      { value: '', selected: false, termID: 5242, term: '[LateralidadeEspondilolise]'},
      { value: '', selected: false, termID: 5245, term: '[NivelDireita]'},
      { value: '', selected: false, termID: 5246, term: '[NivelEsquerda]'},
      { value: '', selected: false, termID: 5248, term: '[GrauRetrolistese]'},
      { value: '', selected: false, termID: 5249, term: '[NivelRetrolistese]'},
    ];

    this.genericLesoesOsseas = [
      { value: '', selected: false, termID: 5285, term: '[NivelLitica]'},
      { value: '', selected: false, termID: 5288, term: '[NivelEsclerotica]'},
    ];

    this.genericCorposVertebrais = [
      { value: '', selected: false, termID: 5292, term: '[NivelOsteofito]' },
      { value: '', selected: false, termID: 5295, term: '[NivelFraturaUnica]' },
      { value: '', selected: false, termID: 5296, term: '[NivelFraturaMultipla]' },
      { value: '', selected: false, termID: 5299, term: '[NivelFraturaReducao]' },
      { value: '', selected: false, termID: 5300, term: '[ReducaoDaAltura]' },
    ];

    this.genericEspacosDiscais = [
      { value: '', selected: false, termID: 5334, term: '[nivel]'},
      { value: '', selected: false, termID: 5335, term: '[nivel]'},
    ]

    this.genericPosCirurgico = [
      { value: '', selected: false, termID: 5306, term: '[LateralidadeSoltura]'},
      { value: '', selected: false, termID: 5307, term: '[NivelSoltura]'},
      { value: '', selected: false, termID: 5309, term: '[LateralidadeFraturaParafuso]'},
      { value: '', selected: false, termID: 5310, term: '[NivelFraturaParafuso]'},
      { value: '', selected: false, termID: 5312, term: '[NivelEspacador]'},
      { value: '', selected: false, termID: 5314, term: '[NivelEspacadorAncora]'},
      { value: '', selected: false, termID: 5316, term: '[NivelProtese]'},
      { value: '', selected: false, termID: 5318, term: '[LateralidadeFraturaHaste]'},
      { value: '', selected: false, termID: 5319, term: '[NivelFraturaHaste]'},
    ]

    this.genericProcessoTransverso = [
      { value: '', selected: false, termID: 5327, term: '[NivelDireita]' },
      { value: '', selected: false, termID: 5329, term: '[NivelDireitaMult]' },
      { value: '', selected: false, termID: 5328, term: '[NivelEsquerda]' },
      { value: '', selected: false, termID: 5330, term: '[NivelEsquerdaMult]' },
    ];

    this.genericInterapofisarias = [
      { value: '', selected: false, termID: 5338, term: '[Nivel]' },
    ];

    this.genericProcessosEspinhosos = [
      { value: '', selected: false, termID: 5271, term: '[Nível]'},
      { value: '', selected: false, termID: 5272, term: '[Nível]'},
    ];

    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoMegapofise($event) {
    this.genericMegapofise.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoAlinhamento($event) {
    this.genericAlinhamento.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoLesoesOsseas($event) {
    this.genericLesoesOsseas.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPosCirurgico($event) {
    this.genericPosCirurgico.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoCorposVertebrais($event) {
    this.genericCorposVertebrais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoProcessoTransverso($event) {
    this.genericProcessoTransverso.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoInterapofisarias($event) {
    this.genericInterapofisarias.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoProcessosEspinhosos($event) {
    this.genericProcessosEspinhosos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEspacosDiscais($event) {
    this.genericEspacosDiscais.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI(){
    let desc;

    if (this.tecnica) {
      this.term.blockID = this.tecnicaId;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.tecnicaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + resultado.text;
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.tecnicaId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.tecnicaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + resultado.conclusion
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.tecnicaId);

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.densidadeOssea) {
      this.term.blockID = this.densidadeOsseaId;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.densidadeOsseaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + resultado.text;
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.densidadeOsseaId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.densidadeOsseaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + resultado.conclusion
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.densidadeOsseaId);

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.megapofiseTransversa){
      this.term.blockID = this.megapofiseTransversaId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.megapofiseTransversaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoMegapofise(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.megapofiseTransversaId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.megapofiseTransversaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoMegapofise(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.megapofiseTransversaId);

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.alinhamento){
      this.term.blockID = this.alinhamentoId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.alinhamentoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoAlinhamento(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.alinhamentoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.alinhamentoId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoAlinhamento(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.alinhamentoId);

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.lesoesOsseas){
      this.term.blockID = this.lesoesOsseasId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.lesoesOsseasId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoLesoesOsseas(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.lesoesOsseasId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.lesoesOsseasId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoLesoesOsseas(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.lesoesOsseasId);

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.corposVertebrais){
      this.term.blockID = this.corposVertebraisId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.corposVertebraisId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoCorposVertebrais(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.corposVertebraisId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        let obj = {
          value: '',
          blockID: this.corposVertebraisId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoCorposVertebrais(resultado.conclusion, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.corposVertebraisId);

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.processoTransverso){
    this.term.blockID = this.processoTransversoId;

    this.term.injuries.push(this.injuries);
    this.cenarioCorrente++;

    this._pouchService.getLaudos(this.term).then((termResults) => {
      this.descricoes.forEach(obj => {
        if (obj.blockID === this.processoTransversoId && termResults[0] !== undefined) {
          this.termResultsAPI = termResults;
          obj.textTermID = this.termResultsAPI[0].id;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termResultsAPI.forEach(resultado => {
            obj.value = obj.value + espaco + this.MontaDescricaoProcessoTransverso(resultado.text, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          });

          let index = this.descricoes.findIndex(x => x.blockID === this.processoTransversoId);
          if (index > -1) {
            this.descricoes[index].value = obj.value;
            this.descricoes[index].textTermID = obj.textTermID;
            desc = obj.value;
          }
        }
      });

      let obj = {
        value: '',
        blockID: this.processoTransversoId,
        textTermID: 0
      };
      const indexConclusion = termResults.filter(x => x.conclusion !== null);
      this.termConclusionAPI = termResults;
      obj.value = '';
      let espaco = '';
      let contadorTexto = 0;
      this.termConclusionAPI.forEach(resultado => {
        if (resultado.conclusion) {
          obj.value = obj.value + espaco + this.MontaDescricaoProcessoTransverso(resultado.conclusion, contadorTexto);
          espaco = '</br>';
          contadorTexto++;
        } else {
          if (indexConclusion.length <= 0) {
            obj.blockID = 0;
            obj.value = this.RetornaConclusaoNull(resultado.conclusion);
          } else {
            if (!obj.value) {
              obj.blockID = 0;
              obj.value = '';
            }
          }
        }
      });

      const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
      const conclusao = this.conclusoes.find(x => x.blockID === this.processoTransversoId);

      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: this.term.blockID,
          descricao: desc,
          conclusao: conclusao.value,
          conclusaoNull: conclusaoNull.value
        });
      }
    });
  } else if (this.interapofisarias){
    this.term.blockID = this.interapofisariasId;

    this.term.injuries.push(this.injuries);
    this.cenarioCorrente++;

    this._pouchService.getLaudos(this.term).then((termResults) => {
      this.descricoes.forEach(obj => {
        if (obj.blockID === this.interapofisariasId && termResults[0] !== undefined) {
          this.termResultsAPI = termResults;
          obj.textTermID = this.termResultsAPI[0].id;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termResultsAPI.forEach(resultado => {
            obj.value = obj.value + espaco + this.MontaDescricaoInterapofisarias(resultado.text, contadorTexto);
            espaco = '</br>';
            contadorTexto++;
          });

          let index = this.descricoes.findIndex(x => x.blockID === this.interapofisariasId);
          if (index > -1) {
            this.descricoes[index].value = obj.value;
            this.descricoes[index].textTermID = obj.textTermID;
            desc = obj.value;
          }
        }
      });

      let obj = {
        value: '',
        blockID: this.interapofisariasId,
        textTermID: 0
      };
      const indexConclusion = termResults.filter(x => x.conclusion !== null);
      this.termConclusionAPI = termResults;
      obj.value = '';
      let espaco = '';
      let contadorTexto = 0;
      this.termConclusionAPI.forEach(resultado => {
        if (resultado.conclusion) {
          obj.value = obj.value + espaco + this.MontaDescricaoInterapofisarias(resultado.conclusion, contadorTexto);
          espaco = '</br>';
          contadorTexto++;
        } else {
          if (indexConclusion.length <= 0) {
            obj.blockID = 0;
            obj.value = this.RetornaConclusaoNull(resultado.conclusion);
          } else {
            if (!obj.value) {
              obj.blockID = 0;
              obj.value = '';
            }
          }
        }
      });

      const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
      const conclusao = this.conclusoes.find(x => x.blockID === this.interapofisariasId);

      if(!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: this.term.blockID,
          descricao: desc,
          conclusao: conclusao.value,
          conclusaoNull: conclusaoNull.value
        });
      }
    });
  } else if (this.espacosDiscais){
      this.term.blockID = this.espacosDiscaisId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.espacosDiscaisId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoEspacosDiscais(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.espacosDiscaisId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
          });
        }
      });
    } else if (this.posCirurgico){
      this.term.blockID = this.posCirurgicoId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.posCirurgicoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoPosCirurgico(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.posCirurgicoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
          });
        }
      });
    } else if (this.processosEspinhosos){
      this.term.blockID = this.processosEspinhososId;

      this.term.injuries.push(this.injuries);
      this.cenarioCorrente++;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.processosEspinhososId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoProcessosEspinhosos(resultado.text, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.processosEspinhososId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
          }
        });

        if(!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
          });
        }
      });
    }
  }

  MontaDescricaoMegapofise(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5220) {
        this.genericMegapofise.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5220) {
          this.genericMegapofise.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoAlinhamento(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5230 ||
        +term === 5233 ||
        +term === 5234 ||
        +term === 5235 ||
        +term === 5236 ||
        +term === 5238 ||
        +term === 5239 ||
        +term === 5241 ||
        +term === 5242 ||
        +term === 5245 ||
        +term === 5246 ||
        +term === 5248 ||
        +term === 5249
        ) {
        this.genericAlinhamento.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5230 ||
          +term === 5233 ||
          +term === 5234 ||
          +term === 5235 ||
          +term === 5236 ||
          +term === 5238 ||
          +term === 5239 ||
          +term === 5241 ||
          +term === 5242 ||
          +term === 5245 ||
          +term === 5246 ||
          +term === 5248 ||
          +term === 5249) {
          this.genericAlinhamento.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;

  }

  MontaDescricaoLesoesOsseas(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5285 || +term === 5288) {
        this.genericLesoesOsseas.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5285 || +term === 5288) {
          this.genericLesoesOsseas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoCorposVertebrais(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5292 || +term === 5295 || +term === 5296 || +term === 5299 || +term === 5300) {
        this.genericCorposVertebrais.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5292 || +term === 5295 || +term === 5296 || +term === 5299 || +term === 5300) {
          this.genericCorposVertebrais.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoProcessoTransverso(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5327 || +term === 5329 || +term === 5328 || +term === 5330) {
        this.genericProcessoTransverso.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5327 || +term === 5329 || +term === 5328 || +term === 5330) {
          this.genericProcessoTransverso.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoPosCirurgico(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term == 5306 || +term == 5307 || +term == 5309 || +term == 5310 || +term == 5312 || +term == 5314 || +term == 5316 || +term == 5318 || +term == 5319) {
        this.genericPosCirurgico.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term == 5306 || +term == 5307 || +term == 5309 || +term == 5310 || +term == 5312 || +term == 5314 || +term == 5316 || +term == 5318 || +term == 5319) {
          this.genericPosCirurgico.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

    MontaDescricaoInterapofisarias(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5338) {
        this.genericInterapofisarias.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5338) {
          this.genericInterapofisarias.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoProcessosEspinhosos(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (term == 5271 || term == 5272) {
        this.genericProcessosEspinhosos.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoEspacosDiscais(texto, contadorTexto){
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term == 5334 || +term == 5335) {
        this.genericEspacosDiscais.forEach(generic => {
          if (generic.termID == term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });


    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '' ) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    //Lógica Rules
    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.htmlContent += '<br/><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';
    // }

    for (i = 0; i < this.descricoes.length; i++) {
      let newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      let newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    this.htmlContent += this.indicacaoTexto + ' </br>';
    this.htmlContent += maskSplit[1];
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';

      }
    }

    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {

          if (this.conclusoes[i].value) {
            this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';

        }
    }

    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    calculadora,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if(calculo) {
						calculo.innerHTML = calculadora;
          }
        }

			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = '<p>' + Texto + '</p>';
    return texto;
  }

  public resetaLaudo() {
    this._pouchService.getAll("rxColunaLombar").then((obj) => {
      console.log(obj)
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

}


