import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Endereco } from '../models/BO/Endereco';
import { environment } from '../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class ViaCepServiceService {

  constructor(private _http: HttpClient) { }

  getEnderecoPorCEP(cep: string): Observable<Endereco>{
    return this._http.get<Endereco>(`${environment.viaCepApi}${cep}/json/`);
  }
}
