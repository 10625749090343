import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-laudo-por-voz',
  templateUrl: './laudo-por-voz.component.html',
  styleUrls: ['./laudo-por-voz.component.css']
})
export class LaudoPorVozComponent implements OnInit {
  public newEditor: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {  }

  clickButton(url) {
    this.router.navigateByUrl(url);
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      // this.iniciaLaudo(this.newEditor);
    }, 100);
  }
}
