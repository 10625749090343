import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatDatepickerModule } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-cabecalho-exame',
  templateUrl: './cabecalho-exame.component.html',
  styleUrls: ['./cabecalho-exame.component.css']
})
export class CabecalhoExameComponent implements OnInit {
  @Output() enviaCabecalho = new EventEmitter();

  @ViewChild('dataExame') dataExame: ElementRef;
  @ViewChild('nomePaciente') nomePaciente: ElementRef;
  @ViewChild('dataNascimento') dataNascimento: ElementRef;
  @ViewChild('numeroAtendimento') numeroAtendimento: ElementRef;
  @ViewChild('ckcopiarHtml') ckcopiarHtml: MatCheckbox;
  
  dataAtual;
  diaAtual: Date;
  showCampos = false;
  dataExameObj = '';
  nomeObj = '' ;
  dataNascObj = '' ;
  numeroAtenObj = '';
  showAndHideTerms: any;

  constructor(
    private _toastr: ToastrManager,
  ) { }

  ngOnInit() {
    this.diaAtual = new Date();
    this.diaAtual.setDate(this.diaAtual.getDate());
    this.showAndHideTerms = {
      sahSem: true
    };
  }

  adicionaRemoveCampos() {
    if (this.ckcopiarHtml.checked === false) {
      this.showCampos = true;
    } else {
      this.showCampos = false;
      this.dataExameObj = '';
      this.nomeObj = '' ;
      this.dataNascObj = '' ;
      this.numeroAtenObj = '';
      this.adicionaTexto();
    }
  }

  blurNomePaciente() {
    let nomePaciente = this.nomePaciente.nativeElement.value;
    if (this.nomePaciente){
      if (nomePaciente !== '') {
        this.nomeObj = `<br/><div style="margin: -4px 0px;" id="NomePacienteLaudo"><b> Nome do paciente: </b>${nomePaciente}</div>`;
        this.adicionaTexto();
      } else {
        this.nomeObj = ``;
        this.adicionaTexto();
      }
    }
    this._toastr.warningToastr('Os campos Nome do paciente e Data de nascimento não serão salvos no histórico.');
  }

  blurDataNascimento() {
    let dataNascimento = this.dataNascimento.nativeElement.value;
    if (this.nomePaciente){
      if (dataNascimento !== '') {
        this.dataNascObj = `<br/><div style="margin: -4px 0px;" id="DataNascimentoLaudo"><b>Data de nascimento:</b> ${dataNascimento}</div>`;
        this.adicionaTexto();
      } else {
        this.dataNascObj = ``;
        this.adicionaTexto();
      }
    }
    this._toastr.warningToastr('Os campos Nome do paciente e Data de nascimento não serão salvos no histórico.');
  }

  blurDataExame() {
    let dataExame = this.dataExame.nativeElement.value;
    if (this.dataExame){
      if (dataExame !== '') {
        this.dataExameObj = `<br/><div style="margin: -4px 0px;"><b> Data do exame:</b> ${dataExame}</div>`;
        this.adicionaTexto();
      } else {
        this.dataExameObj = ``;
        this.adicionaTexto();
      }
    }
  }

  blurNumeroAtendimento() {
    let numeroAtendimento = this.numeroAtendimento.nativeElement.value;
    if (this.nomePaciente){
      if (numeroAtendimento !== '') {
        this.numeroAtenObj = `<br/><div style="margin: -4px 0px;"><b>Número de atendimento/registro:</b> ${numeroAtendimento}<div>`;
        this.adicionaTexto();
      } else {
        this.numeroAtenObj = ``;
        this.adicionaTexto();
      }
    }
  }

  adicionaTexto(){
    const obj = {
      nome: this.nomeObj,
      dataNascimento: this.dataNascObj,
      dataAtual: this.dataExameObj,
      numeroAtendimento: this.numeroAtenObj
    }

    let text = Object.entries(obj)
    let textCompleto;

    if(text) {
      text.forEach(desc => {
        if (desc[1] !== undefined || desc[1] !== null || desc[1] !== '') {
          if(textCompleto === undefined) {
            textCompleto = desc[1];
          } else if (textCompleto !== undefined) {
            textCompleto += desc[1];
          }
        }
      });
    }

    if (textCompleto)
      this.enviaCabecalho.emit(`${textCompleto}<br/></br>`);
    else 
      this.enviaCabecalho.emit('');
  }
}
