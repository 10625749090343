import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatCheckbox, MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RinsDialogComponent } from '../../../../../../utils/dialog/table/rins-dialog/rins-dialog.component';
import { User } from 'src/app/auth/user';
import { TranslateService } from '@ngx-translate/core';

export interface Localizacao {
  value: string;
  viewValue: string;
}
export interface Terco {
  value: string;
  viewValue: string;
}
export interface Margem {
  value: string;
  viewValue: string;
}
export interface Ecogenicidade {
  value: string;
  viewValue: string;
}
export interface Grau {
  value: string;
  viewValue: string;
}
export interface Calculo {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-rin-direito',
  templateUrl: './rin-direito.component.html',
  styleUrls: ['./rin-direito.component.css']
})
export class RinDireitoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNefropatia') rdNefropatia: MatRadioButton;
  @ViewChild('rdNefrectomia') rdNefrectomia: MatRadioButton;

  @ViewChild('inDimensaoNormal') inDimensaoNormal: ElementRef;
  @ViewChild('inEspessuraNormal') inEspessuraNormal: ElementRef;
  @ViewChild('inDimensaoNefropatia') inDimensaoNefropatia: ElementRef;
  @ViewChild('inEspessuraNefropatia') inEspessuraNefropatia: ElementRef;
  @ViewChild('ckNefropatiaCronica') ckNefropatiaCronica: MatCheckbox;

  @ViewChild('ckCalculo') ckCalculo: MatCheckbox;
  @ViewChild('ckCalcObstrutivo') ckCalcObstrutivo: MatCheckbox;
  @ViewChild('rdCalcNaoObstrutivo') rdCalcNaoObstrutivo: MatRadioButton;
  @ViewChild('rdCalcMultiplos') rdCalcMultiplos: MatRadioButton;

  @ViewChild('inDimensaoCalcNaoObstrutivo') inDimensaoCalcNaoObstrutivo: ElementRef;
  @ViewChild('slLocalizacoCalcNaoObstrutivo') slLocalizacoCalcNaoObstrutivo: MatSelect;

  @ViewChild('inDimensaoCalcMultiplos') inDimensaoCalcMultiplos: ElementRef;
  @ViewChild('slLocalizacoCalcMultiplos') slLocalizacoCalcMultiplos: MatSelect;

  @ViewChild('inDimensaoCalcObstrutivo') inDimensaoCalcObstrutivo: ElementRef;
  @ViewChild('slLocalizacoCalcObstrutivo') slLocalizacoCalcObstrutivo: MatSelect;
  @ViewChild('slGrauCalcObstrutivo') slGrauCalcObstrutivo: MatSelect;

  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('rd1Cisto') rd1Cisto: MatRadioButton;
  @ViewChild('rd2Cistos') rd2Cistos: MatRadioButton;
  @ViewChild('rdCistoEsparsos') rdCistoEsparsos: MatRadioButton;

  @ViewChild('inDimensao1Cisto') inDimensao1Cisto: ElementRef;
  @ViewChild('slLocalizaco1Cisto') slLocalizaco1Cisto: MatSelect;

  @ViewChild('inDimensao2Cistos') inDimensao2Cistos: ElementRef;
  @ViewChild('slLocalizaco2Cistos') slLocalizaco2Cistos: MatSelect;

  @ViewChild('inDimensaoCistoEsparsos') inDimensaoCistoEsparsos: ElementRef;
  @ViewChild('slLocalizacoCistoEsparsos') slLocalizacoCistoEsparsos: MatSelect;

  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('rd1Nodulo') rd1Nodulo: MatRadioButton;
  @ViewChild('rd2Nodulos') rd2Nodulos: MatRadioButton;

  @ViewChild('inDimensaoNodulo') inDimensaoNodulo: ElementRef;
  @ViewChild('slLocalizacaoNodulo') slLocalizacaoNodulo: MatSelect;
  @ViewChild('slEcogenicidadeNodulo') slEcogenicidadeNodulo: MatSelect;
  @ViewChild('slMargensNodulo') slMargensNodulo: MatSelect;

  @ViewChild('ckDilatacao') ckDilatacao: MatCheckbox;
  @ViewChild('slGrauDilatacao') slGrauDilatacao: MatSelect;

  @ViewChildren('noduloChildren') noduloChildren: QueryList<any>;
  @ViewChildren('cistoChildren') cistoChildren: QueryList<any>;
  @ViewChildren('calculoNaoObsChildren') calculoNaoObsChildren: QueryList<any>;

  tercos: Terco[] = [
    { value: 'terço inferior', viewValue: 'Terço inferior' },
    { value: 'terço médio', viewValue: 'Terço médio' },
    { value: 'terço superior', viewValue: 'Terço superior' },
  ];

  margens: Margem[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'lobuladas', viewValue: 'Lobuladas' },
    { value: 'indefinidas', viewValue: 'Indefinidas' },
  ];

  ecogenicidades: Ecogenicidade[] = [
    { value: 'hiperecogênica', viewValue: 'Hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' },
    { value: 'isoecogênica', viewValue: 'Isoecogênica' },
  ];

  localizacoes: Localizacao[] = [];
  graus: Grau[] = [];
  grausMaiusculo: Grau[] = [];
  calculos: Calculo[] = [];

  lesoesForm: FormGroup;
  showAndHideTerms: any;
  countLesoes = 0;
  companyID = 0;
  temDilatacao = false;
  unidadeMedida: string;

  constructor(private _fb: FormBuilder,
    public dialog: MatDialog,
    private _user: User,
    private _translate: TranslateService) { }

  async ngOnInit() {
    this.showAndHideTerms = {
      sahNormal: true,
      sahNefropatia: false,
      sahLesoesAll: true,
      sahCalculo: false,
      sahCalculoObs: false,
      sahCalculoNaoObs: false,
      sahCalculoMulti: false,
      sahCisto: false,
      sah1Cisto: false,
      sah2Cistos: false,
      sahCistoEsparsos: false,
      sahNodulo: false,
      sahNoduloSimples: false,
      sahNoduloDinamico: false,
      sahDilatacao: false,
    };
    this.lesoesForm = this._fb.group({
      nodulo: this._fb.array([this.addNoduloGrup()]),
      cisto: this._fb.array([this.addCistoGrup()]),
      calcNaoObs: this._fb.array([this.addCistoGrup()])
    });

    // TODO: Criar lógica dinamica para os campos de seleção
    //! Lógica para as modificações DASA (AJUSTAR POSTERIORMENTE)
    this.companyID = this._user.getUserCompanyID();
    if (this.companyID === 2) {
      // Localizacao
      this.localizacoes = [
        { value: 'terço superior', viewValue: 'Terço superior' },
        { value: 'terço médio', viewValue: 'Terço médio' },
        { value: 'terço inferior', viewValue: 'Terço inferior' },
      ];
      // Grau
      this.graus = [
        { value: 'Pequena', viewValue: 'Pequena' },
        { value: 'Moderada', viewValue: 'Moderada' },
        { value: 'Grande', viewValue: 'Grande' },
      ];
      // Grau maiusculo
      this.grausMaiusculo = [
        { value: 'Pequena', viewValue: 'Pequena' },
        { value: 'Moderada', viewValue: 'Moderada' },
        { value: 'Grande', viewValue: 'Grande' },
      ];
      // Calculo
      this.calculos = [
        { value: 'proximal', viewValue: 'Proximal' },
        { value: 'médio', viewValue: 'Médio' },
        { value: 'distal', viewValue: 'Distal' },
      ];
    } else {
      // Localizacao
      this.localizacoes = [
        { value: 'grupamento calicinal inferior', viewValue: 'Grupamento calicinal inferior' },
        { value: 'grupamento calicinal médio', viewValue: 'Grupamento calicinal médio' },
        { value: 'grupamento calicinal superior', viewValue: 'Grupamento calicinal superior' },
      ];
      // Grau
      this.graus = [
        { value: 'pequena', viewValue: 'Pequena' },
        { value: 'moderada', viewValue: 'Moderada' },
        { value: 'acentuada', viewValue: 'Acentuada' },
      ];
      // Grau maiusculo
      this.grausMaiusculo = [
        { value: 'Pequena', viewValue: 'Pequena' },
        { value: 'Moderada', viewValue: 'Moderada' },
        { value: 'Acentuada', viewValue: 'Acentuada' },
      ];
      // Calculo
      this.calculos = [
        { value: 'na pelve renal', viewValue: 'Pelve renal' },
        { value: 'na junção ureteropiélica (JUP)', viewValue: 'Junção ureteropiélica (JUP)' },
        { value: 'no ureter proximal', viewValue: 'Ureter proximal' },
        { value: 'no ureter médio', viewValue: 'Ureter médio' },
        { value: 'no ureter distal', viewValue: 'Ureter distal ' },
        { value: 'na junção ureterovesical (JUV)', viewValue: 'Junção ureterovesical (JUV)' },
      ];
    }

    let cmValidator = localStorage.getItem('cmRule');
    if (cmValidator == undefined || cmValidator == null) {
      this.unidadeMedida = 'cm';
    } else {
      let cmRule = JSON.parse(localStorage.getItem('cmRule'));
      this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
    }

    // o arquivo de traducao da hapvida ta menor que o de desenv. E essa referencia esta errada. nao deveria ser adbtotal e sim rins e vias. 
    // await this._translate.get('StartTranslation').toPromise().then();
    // this.ecogenicidades = this.ecogenicidades.map((item, i) => {
    //   item.viewValue = this._translate.instant(`Laudos.abdomeTotal.Rim Direito.ecogenicidades.viewValue.${i}`);
    //   item.value = this._translate.instant(`Laudos.abdomeTotal.Rim Direito.ecogenicidades.value.${i}`);
    //   return item;
    // });
  }

  // Nódulo ******************
  addNoduloGrup() {
    return this._fb.group({
      dimensao: [],
      margens: [],
      ecogenicidade: [],
      localizacao: []
    });
  }

  addNodulo() {
    this.noduloArray.push(this.addNoduloGrup());
    this.catchIDs();
  }

  removeNodulo(index) {
    this.noduloArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get noduloArray() {
    return <FormArray>this.lesoesForm.get('nodulo');
  }

  // Cisto ******************
  addCistoGrup() {
    return this._fb.group({
      dimensao: [],
      localizacao: []
    });
  }

  addCisto() {
    this.cistoArray.push(this.addCistoGrup());
    this.catchIDs();
  }

  removeCisto(index) {
    this.cistoArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get cistoArray() {
    return <FormArray>this.lesoesForm.get('cisto');
  }

  // Calc não obs ******************
  addCalcNaoObsGrup() {
    return this._fb.group({
      dimensao: [],
      localizacao: []
    });
  }

  addCalcNaoObs() {
    this.calcNaoObsArray.push(this.addCalcNaoObsGrup());
    this.catchIDs();
  }

  removeCalcNaoObs(index) {
    this.calcNaoObsArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get calcNaoObsArray() {
    return <FormArray>this.lesoesForm.get('calcNaoObs');
  }

  clickNormal() {
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahLesoesAll = true;
    this.showAndHideTerms.sahNefropatia = false;
    this.catchIDs();
  }

  clickNefropatia() {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahLesoesAll = true;
    this.showAndHideTerms.sahNefropatia = true;
    this.catchIDs();
  }


  clickNefrectomia() {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahLesoesAll = false;
    this.showAndHideTerms.sahNefropatia = false;
    this.showAndHideTerms.sahCalculo = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahDilatacao = false;

    this.removeCalcNaoObsDinamico();
    this.catchIDs();
  }

  clickCalculo() {
    if (this.showAndHideTerms.sahCalculo === false) {
      this.showAndHideTerms.sahCalculo = true;
      this.showAndHideTerms.sahCalculoNaoObs = true;
    } else {
      this.showAndHideTerms.sahCalculo = false;
      this.showAndHideTerms.sahCalculoMulti = false;
    }

    if (!this.ckCalculo.checked) {
      this.showAndHideTerms.sahCalculoNaoObs = false;

      const arrayTotal = this.calcNaoObsArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.calcNaoObsArray.length - 1

        if (index != 0) {
          this.calcNaoObsArray.removeAt(index);
        }
      }
      this.calcNaoObsArray.reset();
    }
    this.catchIDs();
  }

  clickCalculoObstrutivo() {
    if (this.showAndHideTerms.sahCalculoObs === false) {
      this.showAndHideTerms.sahCalculoObs = true;
      this.ckDilatacao.checked = false;
      this.showAndHideTerms.sahDilatacao = false;
    } else {
      this.showAndHideTerms.sahCalculoObs = false;
    }
    this.catchIDs();
  }

  clickCalculoNaoObstrutivo() {
    this.showAndHideTerms.sahCalculoNaoObs = true;
    this.showAndHideTerms.sahCalculoMulti = false;
    this.catchIDs();
  }

  clickCalculoMultiplos() {
    this.showAndHideTerms.sahCalculoNaoObs = false;
    this.showAndHideTerms.sahCalculoMulti = true;
    this.removeCalcNaoObsDinamico();
    this.catchIDs();
  }

  clickCisto() {
    if (this.showAndHideTerms.sahCisto === false) {
      this.showAndHideTerms.sahCisto = true;
      this.showAndHideTerms.sah1Cisto = true;
    } else {
      this.showAndHideTerms.sahCisto = false;
      this.showAndHideTerms.sah2Cistos = false;
      this.showAndHideTerms.sahCistoEsparsos = false;
    }

    if (!this.ckCisto.checked) {
      this.showAndHideTerms.sahCisto = false;
      this.showAndHideTerms.sah2Cistos = false;
      this.showAndHideTerms.sahCistoEsparsos = false;

      const arrayTotal = this.cistoArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.cistoArray.length - 1

        if (index != 0) {
          this.cistoArray.removeAt(index);
        }
      }
      this.cistoArray.reset();
    }

    this.catchIDs();
  }


  click1Cisto() {
    this.showAndHideTerms.sah1Cisto = true;
    this.showAndHideTerms.sah2Cistos = false;
    this.showAndHideTerms.sahCistoEsparsos = false;
    this.catchIDs();
  }


  click2Cistos() {
    this.showAndHideTerms.sah1Cisto = false;
    this.showAndHideTerms.sah2Cistos = true;
    this.showAndHideTerms.sahCistoEsparsos = false;
    this.catchIDs();
  }


  clickCistoEsparsos() {
    this.showAndHideTerms.sah1Cisto = false;
    this.showAndHideTerms.sah2Cistos = false;
    this.showAndHideTerms.sahCistoEsparsos = true;
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo === false) {
      this.showAndHideTerms.sahNodulo = true;
      this.showAndHideTerms.sahNoduloSimples = true;
    } else {
      this.showAndHideTerms.sahNodulo = false;
      this.showAndHideTerms.sahNoduloDinamico = false;
    }

    if (!this.ckNodulo.checked) {
      this.showAndHideTerms.sahNoduloDinamico = false;

      const arrayTotal = this.noduloArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.noduloArray.length - 1

        if (index != 0) {
          this.noduloArray.removeAt(index);
        }
      }
      this.noduloArray.reset();
    }

    this.catchIDs();
  }

  click1Nodulo() {
    this.showAndHideTerms.sahNoduloSimples = true;
    this.showAndHideTerms.sahNoduloDinamico = false;
  }

  click2Nodulo() {
    this.showAndHideTerms.sahNoduloSimples = false;
    this.showAndHideTerms.sahNoduloDinamico = true;
  }

  clickCronico() {
    this.catchIDs();
  }

  clickDilatacao() {
    if (this.showAndHideTerms.sahDilatacao === false) {
      this.showAndHideTerms.sahDilatacao = true;
      this.ckCalcObstrutivo.checked = false;
      this.showAndHideTerms.sahCalculoObs = false;
    } else {
      this.showAndHideTerms.sahDilatacao = false;
    }
    this.catchIDs();
  }

  changeEcogenicidade($event) {
    this.catchIDs();
  }

  changeMargens($event) {
    this.catchIDs();
  }

  changeLocalizacao($event) {
    this.catchIDs();
  }

  changeGrau($event) {
    this.catchIDs();
  }

  changeCalculo($event) {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickTabelaReferencia() {
    this.dialog.open(RinsDialogComponent, {});
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    this.temDilatacao = false;

    if (this.rdNormal.checked) {
      this.countLesoes++;

      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inDimensaoNormal != undefined &&
        this.inDimensaoNormal.nativeElement.id != undefined &&
        this.inDimensaoNormal.nativeElement.value != '') {

        const objeto = { elemento: this.inDimensaoNormal.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessuraNormal != undefined &&
        this.inEspessuraNormal.nativeElement.id != undefined &&
        this.inEspessuraNormal.nativeElement.value != '') {

        const objeto = { elemento: this.inEspessuraNormal.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if(!this.ckCalculo.checked && !this.ckCalcObstrutivo.checked){
        this.AdicionaFraseAusencia();
      }

      if(!this.ckDilatacao.checked && !this.ckCalcObstrutivo.checked){
        this.AdicionaFraseHidronefrose();
      }
    }

    if (this.rdNefrectomia.checked) {
      this.termoSelecionado.emit(this.rdNefrectomia.id);
    }

    if (this.rdNefropatia.checked && this.inDimensaoNefropatia.nativeElement.value != '' && this.inEspessuraNefropatia.nativeElement.value != '') {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdNefropatia.id);

      if (this.inDimensaoNefropatia != undefined &&
        this.inDimensaoNefropatia.nativeElement.id != undefined &&
        this.inDimensaoNefropatia.nativeElement.value != '') {

        const objeto = { elemento: this.inDimensaoNefropatia.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessuraNefropatia != undefined &&
        this.inEspessuraNefropatia.nativeElement.id != undefined &&
        this.inEspessuraNefropatia.nativeElement.value != '') {

        const objeto = { elemento: this.inEspessuraNefropatia.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckNefropatiaCronica != undefined &&
        this.ckNefropatiaCronica.checked) {

        this.termoSelecionado.emit(this.ckNefropatiaCronica.id);
      }

      if(!this.ckCalculo.checked && !this.ckCalcObstrutivo.checked){
        this.AdicionaFraseAusencia();
      }

      if(!this.ckDilatacao.checked && !this.ckCalcObstrutivo.checked){
        this.AdicionaFraseHidronefrose();
      }
    }

    // CALCULO OBS ***************************
    if (this.ckCalcObstrutivo != undefined && this.ckCalcObstrutivo.checked && !this.rdNefrectomia.checked) {
      this.countLesoes++;

        const objeto = { id: this.ckCalcObstrutivo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.inDimensaoCalcObstrutivo != undefined && this.inDimensaoCalcObstrutivo.nativeElement.id != undefined) {
          const objetoLesao = { elemento: this.inDimensaoCalcObstrutivo.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slLocalizacoCalcObstrutivo != undefined && this.slLocalizacoCalcObstrutivo.id != undefined) {
          const objetoLesao = { elemento: this.slLocalizacoCalcObstrutivo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slGrauCalcObstrutivo != undefined && this.slGrauCalcObstrutivo.id != undefined) {
          const objetoLesao = { elemento: this.slGrauCalcObstrutivo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
      }
    }

    // CALCULO NÃO OBS ***************************
    if (this.ckCalculo != undefined && this.ckCalculo.checked && !this.rdNefrectomia.checked) {
      this.countLesoes++;
      if(this.rdCalcNaoObstrutivo != undefined && this.rdCalcNaoObstrutivo.checked ){
        const objetoGrupoLesao = { id: this.rdCalcNaoObstrutivo.value, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoGrupoLesao);

        let posicao = 1;
        let linha = 0;

        this.calculoNaoObsChildren.forEach(obj => {

          if (obj.nativeElement != undefined) {
            linha++;
            const objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario:this.countLesoes };
            const objetoPai = { elemento: objetofilho, linha: linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(objetoPai);

          } else {
            const objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            const objetoPai = { elemento: objetofilho, linha: linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(objetoPai);
          }
        });
      }

      if (this.rdCalcMultiplos != undefined && this.rdCalcMultiplos.checked) {
        const objeto = { id: this.rdCalcMultiplos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.inDimensaoCalcMultiplos != undefined && this.inDimensaoCalcMultiplos.nativeElement.id != undefined) {
          const objetoLesao = { elemento: this.inDimensaoCalcMultiplos.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slLocalizacoCalcMultiplos != undefined && this.slLocalizacoCalcMultiplos.id != undefined) {
          const objetoLesao = { elemento: this.slLocalizacoCalcMultiplos, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
      }
    }

    // CISTO ***************************
    if (this.ckCisto != undefined && this.ckCisto.checked && !this.rdNefrectomia.checked) {
      this.countLesoes++;

      if (this.rd1Cisto != undefined && this.rd1Cisto.checked) {
        const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objetoGrupoLesao = { id: this.rd1Cisto.value, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoGrupoLesao);

        if (this.inDimensao1Cisto != undefined && this.inDimensao1Cisto.nativeElement.id != undefined) {
          const objetoLesao = { elemento: this.inDimensao1Cisto.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slLocalizaco1Cisto != undefined && this.slLocalizaco1Cisto.id != undefined) {
          const objetoLesao = { elemento: this.slLocalizaco1Cisto, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
      }

      if (this.rd2Cistos != undefined && this.rd2Cistos.checked) {
        const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objetoGrupoLesao = { id: this.rd2Cistos.value, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoGrupoLesao);

        if (this.inDimensao2Cistos != undefined && this.inDimensao2Cistos.nativeElement.id != undefined) {
          const objetoLesao = { elemento: this.inDimensao2Cistos.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slLocalizaco2Cistos != undefined && this.slLocalizaco2Cistos.id != undefined) {
          const objetoLesao = { elemento: this.slLocalizaco2Cistos, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }

        const posicao = 1;
        let linha = 0;

        this.cistoChildren.forEach(obj => {
          if (obj.nativeElement != undefined) {
            linha++;
            const objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            const objetoPai = { elemento: objetofilho, posicao: posicao, linha: linha, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(objetoPai);
          } else {
            const objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            const objetoPai = { elemento: objetofilho, posicao: 0, linha: linha, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(objetoPai);
          }
        });

      }

      if (this.rdCistoEsparsos != undefined && this.rdCistoEsparsos.checked) {
        const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objetoGrupoLesao = { id: this.rdCistoEsparsos.value, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoGrupoLesao);

        if (this.inDimensaoCistoEsparsos != undefined && this.inDimensaoCistoEsparsos.nativeElement.id != undefined) {
          const objetoLesao = { elemento: this.inDimensaoCistoEsparsos.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slLocalizacoCistoEsparsos != undefined && this.slLocalizacoCistoEsparsos.id != undefined) {
          const objetoLesao = { elemento: this.slLocalizacoCistoEsparsos, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
      }
    }

    // NODULO ***************************
    if (this.ckNodulo != undefined && this.ckNodulo.checked && !this.rdNefrectomia.checked) {
      this.countLesoes++;
      if (this.rd1Nodulo != undefined && this.rd1Nodulo.checked) {
        const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objetoGrupoLesao = { id: this.rd1Nodulo.value, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoGrupoLesao);

        if (this.inDimensaoNodulo != undefined && this.inDimensaoNodulo.nativeElement.id != undefined) {
          const objetoLesao = { elemento: this.inDimensaoNodulo.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slEcogenicidadeNodulo != undefined && this.slEcogenicidadeNodulo.id != undefined) {
          const objetoLesao = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slMargensNodulo != undefined && this.slMargensNodulo.id != undefined) {
          const objetoLesao = { elemento: this.slMargensNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
        if (this.slLocalizacaoNodulo != undefined && this.slLocalizacaoNodulo.id != undefined) {
          const objetoLesao = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objetoLesao);
        }
      }
      if (this.rd2Nodulos != undefined && this.rd2Nodulos.checked) {
        const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objetoGrupoLesao = { id: this.rd2Nodulos.value, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objetoGrupoLesao);

        let posicao = 1;
        let linha = 0;

        this.noduloChildren.forEach(obj => {
          if (obj.nativeElement != undefined) {
            linha++;
            const objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            const objetoPai = { elemento: objetofilho, linha: linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(objetoPai);
          } else {
            const objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            const objetoPai = { elemento: objetofilho, linha: linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(objetoPai);
          }
        });
      }
    }

    // DILATACAO ***************************
    if (this.ckDilatacao != undefined && this.ckDilatacao.checked && !this.rdNefrectomia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDilatacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slGrauDilatacao != undefined && this.slGrauDilatacao.id != undefined) {
        const objetoLesao = { elemento: this.slGrauDilatacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objetoLesao);
      }
    }

    this.listaCompleta.emit();
  }

  removeCalcNaoObsDinamico() {
    const arrayTotal = this.calcNaoObsArray.length;

    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.calcNaoObsArray.length - 1

      if (index != 0) {
        this.calcNaoObsArray.removeAt(index);
      }
    }
    this.calcNaoObsArray.reset();
  }

  AdicionaFraseAusencia(){
    this.countLesoes++;
    const objeto = { id: 5429, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);
  }

  AdicionaFraseHidronefrose(){
    this.countLesoes++;
    const objeto = { id: 5430, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);
  }
}
