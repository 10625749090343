import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material';

@Component({
  selector: 'app-indicacao-pm',
  templateUrl: './indicacao-pm.component.html',
  styleUrls: ['./indicacao-pm.component.css']
})
export class IndicacaoPmComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAbaulamento') rdAbaulamento: MatRadioButton;
  @ViewChild('rdNodulo') rdNodulo: MatRadioButton;
  @ViewChild('rdColecao') rdColecao: MatRadioButton;
  @ViewChild('rdPesquisaColecao') rdPesquisaColecao: MatRadioButton;
  @ViewChild('inRegiaoCorpo') inRegiaoCorpo: ElementRef;


  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  blurDescricao() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAbaulamento.checked) {
      this.termoSelecionado.emit(this.rdAbaulamento.id);
    }

    if (this.rdNodulo.checked) {
      this.termoSelecionado.emit(this.rdNodulo.id);
    }

    if (this.rdColecao.checked) {
      this.termoSelecionado.emit(this.rdColecao.id);
    }

    if (this.inRegiaoCorpo !== undefined &&
      this.inRegiaoCorpo.nativeElement.id !== undefined &&
      this.inRegiaoCorpo.nativeElement.value != '') {

      const objeto = {elemento: this.inRegiaoCorpo.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }
}
