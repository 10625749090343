import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { forEach } from '@angular/router/src/utils/collection';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/cdk/overlay/typings/overlay-directives';
import { on } from 'process';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface DescricaoConclusao {
  value: string;
  textTermID: number;
  blockID: number;
  visivel: boolean;
}

export interface TermosGenericos {
  value: string;
  selected: boolean;
  termID: number;
  term: string;
}

@Component({
  selector: 'app-testiculo',
  templateUrl: './testiculo.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./testiculo.component.css']
})
export class TesticuloComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[769],blockID:43},
    {listTerm:[801],blockID:44},
    {listTerm:[710],blockID:38},
    {listTerm:[732],blockID:39},
    {listTerm:[754],blockID:40},
    {listTerm:[759],blockID:41},
    {listTerm:[765],blockID:42},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: '', blockID: 42, textTermID: 0, visivel: true },
    {
      value: 'Testículo direito tópico, de contornos regulares, com dimensões preservadas. O parênquima testicular apresenta ecotextura homogênea.</br>' +
        'Testículo direito: _ x _ x _ cm. Volume de __ cm³.', blockID: 43, textTermID: 0, visivel: true
    },
    { value: 'Epidídimo direito de dimensões preservadas e ecotextura homogênea.', blockID: 38, textTermID: 0, visivel: true },
    { value: 'Quantidade de líquido fisiológico na bolsa testicular direita.', blockID: 40, textTermID: 0, visivel: true },
    {
      value: 'Testículo esquerdo tópico, de contornos regulares, com dimensões preservadas. O parênquima testicular apresenta ecotextura homogênea.</br>' +
        'Testículo esquerdo: _ x _ x _ cm. Volume de __ cm³.', blockID: 44, textTermID: 0, visivel: true
    },
    { value: 'Epidídimo esquerdo de dimensões preservadas e ecotextura homogênea.', blockID: 39, textTermID: 0, visivel: true },
    { value: 'Quantidade de líquido fisiológico na bolsa testicular esquerda.', blockID: 41, textTermID: 0, visivel: true }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Estudo ultrassonográfico dentro dos padrões da normalidade.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 43, textTermID: 0, visivel: true },
    { value: '', blockID: 38, textTermID: 0, visivel: true },
    { value: '', blockID: 40, textTermID: 0, visivel: true },
    { value: '', blockID: 42, textTermID: 0, visivel: true },
    { value: '', blockID: 44, textTermID: 0, visivel: true },
    { value: '', blockID: 39, textTermID: 0, visivel: true },
    { value: '', blockID: 41, textTermID: 0, visivel: true }
  ];

  genericTermsEpididimoDireito: TermosGenericos[] = [
    { value: '', selected: false, termID: 712, term: '[Dimensão]' },
    { value: '', selected: false, termID: 713, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 714, term: '[Localização]' },
    { value: '', selected: false, termID: 716, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 717, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 718, term: '[Calcificação]' },
    { value: '', selected: false, termID: 719, term: '[Contornos]' },
    { value: '', selected: false, termID: 720, term: '[Localização]' },
    { value: '', selected: false, termID: 721, term: '[Doppler]' },
    { value: '', selected: false, termID: 722, term: '[Medindo]' },
    { value: '', selected: false, termID: 724, term: '[Localização]' },
    { value: '', selected: false, termID: 725, term: '[Medindo]' },
    { value: '', selected: false, termID: 729, term: '[Localização coleção]' },
    { value: '', selected: false, termID: 730, term: '[Medindo]' },
    { value: '', selected: false, termID: 731, term: '[Volume]' }
  ];

  genericTermsEpididimoEsquerdo: TermosGenericos[] = [
    { value: '', selected: false, termID: 734, term: '[Dimensão]' },
    { value: '', selected: false, termID: 735, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 736, term: '[Localização]' },
    { value: '', selected: false, termID: 738, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 739, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 740, term: '[Calcificação]' },
    { value: '', selected: false, termID: 741, term: '[Contornos]' },
    { value: '', selected: false, termID: 742, term: '[Localização]' },
    { value: '', selected: false, termID: 743, term: '[Doppler]' },
    { value: '', selected: false, termID: 744, term: '[Medindo]' },
    { value: '', selected: false, termID: 746, term: '[Localização]' },
    { value: '', selected: false, termID: 747, term: '[Medindo]' },
    { value: '', selected: false, termID: 751, term: '[Localização coleção]' },
    { value: '', selected: false, termID: 752, term: '[Medindo]' },
    { value: '', selected: false, termID: 753, term: '[Volume]' }
  ];

  genericTermsHidroceleDireita: TermosGenericos[] = [
    { value: '', selected: false, termID: 757, term: '[Quantidade]' },
    { value: '', selected: false, termID: 758, term: '[Conteúdo]' }
  ];

  genericTermsHidroceleEsquerda: TermosGenericos[] = [
    { value: '', selected: false, termID: 762, term: '[Quantidade]' },
    { value: '', selected: false, termID: 763, term: '[Conteúdo]' }
  ];

  genericTermsTesticuloDireito: TermosGenericos[] = [
    { value: '', selected: false, termID: 767, term: '[Medindo Test]' },
    { value: '', selected: false, termID: 768, term: '[Volume Test]' },
    { value: '', selected: false, termID: 773, term: '[Contornos]' },
    { value: '', selected: false, termID: 798, term: '[Dimensões]' },
    { value: '', selected: false, termID: 775, term: '[Ecotextura torção]' },
    { value: '', selected: false, termID: 776, term: '[Doppler torção]' },
    { value: '', selected: false, termID: 777, term: '[Medida do funículo]' },
    { value: '', selected: false, termID: 779, term: '[Contornos]' },
    { value: '', selected: false, termID: 780, term: '[Dimensões]' },
    { value: '', selected: false, termID: 781, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 783, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 784, term: '[EcotexturaNoduloDir]' },
    { value: '', selected: false, termID: 785, term: '[Calcificação]' },
    { value: '', selected: false, termID: 786, term: '[Contornos]' },
    { value: '', selected: false, termID: 787, term: '[Localização]' },
    { value: '', selected: false, termID: 788, term: '[Doppler]' },
    { value: '', selected: false, termID: 789, term: '[Medindo]' },
    { value: '', selected: false, termID: 795, term: '[Localização]' },
    { value: '', selected: false, termID: 796, term: '[Medindo]' },
    { value: '', selected: false, termID: 797, term: '[Volume]' }
  ];

  genericTermsTesticuloEsquerdo: TermosGenericos[] = [
    { value: '', selected: false, termID: 799, term: '[Medindo Test]' },
    { value: '', selected: false, termID: 800, term: '[Volume Test]' },
    { value: '', selected: false, termID: 805, term: '[Contornos]' },
    { value: '', selected: false, termID: 806, term: '[Dimensões]' },
    { value: '', selected: false, termID: 807, term: '[Ecotextura torção]' },
    { value: '', selected: false, termID: 808, term: '[Doppler torção]' },
    { value: '', selected: false, termID: 809, term: '[Medida do funículo]' },
    { value: '', selected: false, termID: 811, term: '[Contornos]' },
    { value: '', selected: false, termID: 812, term: '[Dimensões]' },
    { value: '', selected: false, termID: 813, term: '[Ecotextura]' },
    { value: '', selected: false, termID: 815, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 816, term: '[EcotexturaNoduloEsq]' },
    { value: '', selected: false, termID: 817, term: '[Calcificação]' },
    { value: '', selected: false, termID: 818, term: '[Contornos]' },
    { value: '', selected: false, termID: 819, term: '[Localização]' },
    { value: '', selected: false, termID: 820, term: '[Doppler]' },
    { value: '', selected: false, termID: 821, term: '[Medindo]' },
    { value: '', selected: false, termID: 826, term: '[Localização]' },
    { value: '', selected: false, termID: 827, term: '[Medindo]' },
    { value: '', selected: false, termID: 829, term: '[Volume]' }
  ];

  genericTermsOrquiectomia: TermosGenericos[] = [
    { value: '', selected: false, termID: 4607, term: '[ContornosDir]' },
    { value: '', selected: false, termID: 4608, term: '[DimensoesDir]' },
    { value: '', selected: false, termID: 4609, term: '[EcotexturaDir]' },
    { value: '', selected: false, termID: 4610, term: '[MedindoDir]' },
    { value: '', selected: false, termID: 4611, term: '[ContornosEsq]' },
    { value: '', selected: false, termID: 4612, term: '[DimensoesEsq]' },
    { value: '', selected: false, termID: 4613, term: '[EcotexturaEsq]' },
    { value: '', selected: false, termID: 4614, term: '[MedindoEsq]' },
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  testiculoDireito = true;
  testiculoEsquerdo = false;
  epididimoDireito = false;
  epididimoEsquerdo = false;
  hidroceleDireita = false;
  hidroceleEsquerda = false;
  orquiectomia = false;
  indicacao = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 6;
  testiculoDireitoID = 43;
  testiculoEsquerdoID = 44;
  epididimoDireitoID = 38;
  epididimoEsquerdoID = 39;
  hidroceleDireitaID = 40;
  hidroceleEsquerdaID = 41;
  orquiectomiaID = 42;
  public switchFormReport = true;
  public showForm: Boolean = true;
  unidadeMedida: string;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  editLaudoMode: boolean = false;

  constructor(private _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService,
    private _copyPaste: CopyPaste) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("testiculoAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Testículo direito';
    this.subtituloBloco = 'Testículo direito';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.testiculoDireitoID,
      this.testiculoEsquerdoID,
      this.epididimoDireitoID,
      this.epididimoEsquerdoID,
      this.hidroceleDireitaID,
      this.hidroceleEsquerdaID,
      this.orquiectomiaID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "testiculoMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "testiculo");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-testiculo');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-testiculo');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("testiculoAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Testículo direito',
      id: this.testiculoDireitoID,
      name: 'testiculo-direito',
      selected: true,
    },
    {
      titulo: 'Epidídimo direito',
      id: this.epididimoDireitoID,
      name: 'epididimo-direito',
      selected: false
    },
    {
      titulo: 'Hidrocele direita',
      id: this.hidroceleDireitaID,
      name: 'hidrocele-direita',
      selected: false
    },
    {
      titulo: 'Testículo esquerdo',
      id: this.testiculoEsquerdoID,
      name: 'testiculo-esquerdo',
      selected: false
    },
    {
      titulo: 'Epidídimo esquerdo',
      id: this.epididimoEsquerdoID,
      name: 'epididimo-esquerdo',
      selected: false
    },
    {
      titulo: 'Hidrocele esquerda',
      id: this.hidroceleEsquerdaID,
      name: 'hidrocele-esquerda',
      selected: false
    },
    {
      titulo: 'Orquiectomia / Criptorquidia',
      id: this.orquiectomiaID,
      name: 'orquiectomia',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;

    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }

    this.cabecalho =  (itemSelecionado.id === 'cabecalho');
    this.indicacao =  (itemSelecionado.id === 'indicacao');
    this.testiculoDireito =  (itemSelecionado.id === this.testiculoDireitoID);
    this.testiculoEsquerdo =  (itemSelecionado.id === this.testiculoEsquerdoID);
    this.epididimoDireito =  (itemSelecionado.id === this.epididimoDireitoID);
    this.epididimoEsquerdo =  (itemSelecionado.id === this.epididimoEsquerdoID);
    this.hidroceleDireita =  (itemSelecionado.id === this.hidroceleDireitaID);
    this.hidroceleEsquerda =  (itemSelecionado.id === this.hidroceleEsquerdaID);
    this.orquiectomia =  (itemSelecionado.id === this.orquiectomiaID);
    this.achadosTexto =  (itemSelecionado.id === 'achados_adicionais');
    this.rodape =  (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTermsEpididimoDireito = [
      { value: '', selected: false, termID: 712, term: '[Dimensão]' },
      { value: '', selected: false, termID: 713, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 714, term: '[Localização]' },
      { value: '', selected: false, termID: 716, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 717, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 718, term: '[Calcificação]' },
      { value: '', selected: false, termID: 719, term: '[Contornos]' },
      { value: '', selected: false, termID: 720, term: '[Localização]' },
      { value: '', selected: false, termID: 721, term: '[Doppler]' },
      { value: '', selected: false, termID: 722, term: '[Medindo]' },
      { value: '', selected: false, termID: 724, term: '[Localização]' },
      { value: '', selected: false, termID: 725, term: '[Medindo]' },
      { value: '', selected: false, termID: 729, term: '[Localização coleção]' },
      { value: '', selected: false, termID: 730, term: '[Medindo]' },
      { value: '', selected: false, termID: 731, term: '[Volume]' }
    ];

    this.genericTermsEpididimoEsquerdo = [
      { value: '', selected: false, termID: 734, term: '[Dimensão]' },
      { value: '', selected: false, termID: 735, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 736, term: '[Localização]' },
      { value: '', selected: false, termID: 738, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 739, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 740, term: '[Calcificação]' },
      { value: '', selected: false, termID: 741, term: '[Contornos]' },
      { value: '', selected: false, termID: 742, term: '[Localização]' },
      { value: '', selected: false, termID: 743, term: '[Doppler]' },
      { value: '', selected: false, termID: 744, term: '[Medindo]' },
      { value: '', selected: false, termID: 746, term: '[Localização]' },
      { value: '', selected: false, termID: 747, term: '[Medindo]' },
      { value: '', selected: false, termID: 751, term: '[Localização coleção]' },
      { value: '', selected: false, termID: 752, term: '[Medindo]' },
      { value: '', selected: false, termID: 753, term: '[Volume]' }
    ];

    this.genericTermsHidroceleDireita = [
      { value: '', selected: false, termID: 757, term: '[Quantidade]' },
      { value: '', selected: false, termID: 758, term: '[Conteúdo]' }
    ];

    this.genericTermsHidroceleEsquerda = [
      { value: '', selected: false, termID: 762, term: '[Quantidade]' },
      { value: '', selected: false, termID: 763, term: '[Conteúdo]' }
    ];

    this.genericTermsTesticuloDireito = [
      { value: '', selected: false, termID: 767, term: '[Medindo Test]' },
      { value: '', selected: false, termID: 768, term: '[Volume Test]' },
      { value: '', selected: false, termID: 773, term: '[Contornos]' },
      { value: '', selected: false, termID: 775, term: '[Ecotextura torção]' },
      { value: '', selected: false, termID: 776, term: '[Doppler torção]' },
      { value: '', selected: false, termID: 777, term: '[Medida do funículo]' },
      { value: '', selected: false, termID: 779, term: '[Contornos]' },
      { value: '', selected: false, termID: 780, term: '[Dimensões]' },
      { value: '', selected: false, termID: 781, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 783, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 784, term: '[EcotexturaNoduloDir]' },
      { value: '', selected: false, termID: 785, term: '[Calcificação]' },
      { value: '', selected: false, termID: 786, term: '[Contornos]' },
      { value: '', selected: false, termID: 787, term: '[Localização]' },
      { value: '', selected: false, termID: 788, term: '[Doppler]' },
      { value: '', selected: false, termID: 789, term: '[Medindo]' },
      { value: '', selected: false, termID: 795, term: '[Localização]' },
      { value: '', selected: false, termID: 796, term: '[Medindo]' },
      { value: '', selected: false, termID: 797, term: '[Volume]' },
      { value: '', selected: false, termID: 798, term: '[Dimensões]' }
    ];

    this.genericTermsTesticuloEsquerdo = [
      { value: '', selected: false, termID: 799, term: '[Medindo Test]' },
      { value: '', selected: false, termID: 800, term: '[Volume Test]' },
      { value: '', selected: false, termID: 805, term: '[Contornos]' },
      { value: '', selected: false, termID: 806, term: '[Dimensões]' },
      { value: '', selected: false, termID: 807, term: '[Ecotextura torção]' },
      { value: '', selected: false, termID: 808, term: '[Doppler torção]' },
      { value: '', selected: false, termID: 809, term: '[Medida do funículo]' },
      { value: '', selected: false, termID: 811, term: '[Contornos]' },
      { value: '', selected: false, termID: 812, term: '[Dimensões]' },
      { value: '', selected: false, termID: 813, term: '[Ecotextura]' },
      { value: '', selected: false, termID: 815, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 816, term: '[EcotexturaNoduloEsq]' },
      { value: '', selected: false, termID: 817, term: '[Calcificação]' },
      { value: '', selected: false, termID: 818, term: '[Contornos]' },
      { value: '', selected: false, termID: 819, term: '[Localização]' },
      { value: '', selected: false, termID: 820, term: '[Doppler]' },
      { value: '', selected: false, termID: 821, term: '[Medindo]' },
      { value: '', selected: false, termID: 826, term: '[Localização]' },
      { value: '', selected: false, termID: 827, term: '[Medindo]' },
      { value: '', selected: false, termID: 829, term: '[Volume]' }
    ];

    this.genericTermsOrquiectomia = [
      { value: '', selected: false, termID: 4607, term: '[ContornosDir]' },
      { value: '', selected: false, termID: 4608, term: '[DimensoesDir]' },
      { value: '', selected: false, termID: 4609, term: '[EcotexturaDir]' },
      { value: '', selected: false, termID: 4610, term: '[MedindoDir]' },
      { value: '', selected: false, termID: 4611, term: '[ContornosEsq]' },
      { value: '', selected: false, termID: 4612, term: '[DimensoesEsq]' },
      { value: '', selected: false, termID: 4613, term: '[EcotexturaEsq]' },
      { value: '', selected: false, termID: 4614, term: '[MedindoEsq]' },
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados_adicionais',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  adicionaSelecaoEpididimoDireito($event) {
    this.genericTermsEpididimoDireito.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEpididimoEsquerdo($event) {
    this.genericTermsEpididimoEsquerdo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoHidroceleDireita($event) {
    this.genericTermsHidroceleDireita.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoHidroceleEsquerda($event) {
    this.genericTermsHidroceleEsquerda.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    }
  }

  adicionaSelecaoTesticuloDireito($event) {
    this.genericTermsTesticuloDireito.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoTesticuloEsquerdo($event) {
    this.genericTermsTesticuloEsquerdo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      if ($event.elemento.cenario) {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
      } else {
        this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      }
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoOrquiectomia($event) {
    this.genericTermsOrquiectomia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.epididimoDireito) {
        this.term.blockID = this.epididimoDireitoID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.epididimoDireitoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEpididimoDireito(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.epididimoDireitoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.epididimoDireitoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.epididimoDireitoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.epididimoDireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.epididimoDireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.epididimoDireitoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.epididimoEsquerdo) {
        this.term.blockID = this.epididimoEsquerdoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.epididimoEsquerdoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEpididimoEsquerdo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.epididimoEsquerdoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.epididimoEsquerdoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.epididimoEsquerdoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.epididimoEsquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.epididimoEsquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.epididimoEsquerdoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.hidroceleDireita) {
        this.term.blockID = this.hidroceleDireitaID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.hidroceleDireitaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoHidroceleDireita(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.hidroceleDireitaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.hidroceleDireitaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.hidroceleDireitaID;
              obj.value = this.MontaConclusaoHidroceleDireita(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.hidroceleDireitaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.hidroceleDireitaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.hidroceleDireitaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.hidroceleEsquerda) {
        this.term.blockID = this.hidroceleEsquerdaID;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.hidroceleEsquerdaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoHidroceleEsquerda(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.hidroceleEsquerdaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.hidroceleEsquerdaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.hidroceleEsquerdaID;
              obj.value = this.MontaConclusaoHidroceleEsquerda(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.hidroceleEsquerdaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.hidroceleEsquerdaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.hidroceleEsquerdaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.orquiectomia) {
        this.term.blockID = this.orquiectomiaID;
        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.orquiectomiaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoOrquiectomia(resultado.text, contadorTexto);
                espaco = '</br></br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.orquiectomiaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.orquiectomiaID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoOrquiectomia(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.orquiectomiaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.orquiectomiaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          // Lógica para não exibir lado direito ou esquerdo
          // POG: Ajustar essa lógica, já fui melhor!!! Ass: Gabs
          // POG 2.0: Ajustes para reconhecer lesões. tbm ja fomos melhores!!! Ass: Paulo e Leo
          //! POG 2.0: OBS IMPORTANTE, Não pode entrar mais nenhuma lesão nessa lógica!
          //! Debito tecnico para discussão URGENTE!!!
          this.controleExibicaoLado(termResults);


          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.orquiectomiaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }

        });
      } else if (this.testiculoDireito) {
        this.term.blockID = this.testiculoDireitoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.testiculoDireitoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTesticuloDireito(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.testiculoDireitoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.testiculoDireitoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.testiculoDireitoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.testiculoDireitoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.testiculoDireitoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.testiculoDireitoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.testiculoEsquerdo) {
        this.term.blockID = this.testiculoEsquerdoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        let desc;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.testiculoEsquerdoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoTesticuloEsquerdo(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.testiculoEsquerdoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.testiculoEsquerdoID,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.testiculoEsquerdoID;
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.testiculoEsquerdoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.testiculoEsquerdoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.testiculoEsquerdoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      }
    }
  }

  controleExibicaoLado(termResults) {
    if (termResults[0] === undefined || termResults[0].text === '') {
      this.descricoes.forEach(obj => {
        obj.visivel = true;
      });
      this.conclusoes.forEach(obj => {
        obj.visivel = true;
      });
    } else {
      for (let z = 0; z < termResults.length; z++) {
        if (+termResults[z].id === 661 || +termResults[z].id === 2621 || +termResults[z].id === 2623) {
          this.descricoes.forEach(obj => {
            if (obj.blockID === 43 || obj.blockID === 38 || obj.blockID === 40) {
              obj.visivel = false;
            } else {
              if (+termResults[z].id === 661 || this.injuries.listInjuries.length <= 0) {
              obj.visivel = true;
            }
            }

          });
          this.conclusoes.forEach(obj => {
            if (obj.blockID === 43 || obj.blockID === 38 || obj.blockID === 40) {
              obj.visivel = false;
            } else {
              if (+termResults[z].id === 661 || this.injuries.listInjuries.length <= 0) {
                obj.visivel = true;
              }
            }
          });
        } else if (+termResults[z].id === 662 || +termResults[z].id === 2622 || +termResults[z].id === 2624) {
          this.descricoes.forEach(obj => {
            if (obj.blockID === 44 || obj.blockID === 39 || obj.blockID === 41) {
              obj.visivel = false;
            } else {
              if (+termResults[z].id === 662 || this.injuries.listInjuries.length <= 0) {
                obj.visivel = true;
              }
            }

          });
          this.conclusoes.forEach(obj => {
            if (obj.blockID === 44 || obj.blockID === 39 || obj.blockID === 41) {
              obj.visivel = false;
            } else {
              if (+termResults[z].id === 662 || this.injuries.listInjuries.length <= 0) {
                obj.visivel = true;
              }
            }
          });
        } else {
          this.descricoes.forEach(obj => {
            if (obj.blockID !== 42) {
              obj.visivel = false;
            }
          });
          this.conclusoes.forEach(obj => {
            if (obj.blockID !== 42) {
              obj.visivel = false;
            }
          });
        }
      }
    }
  }


  MontaDescricaoEpididimoDireito(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 713 || +term === 714 || +term === 716 || +term === 717 ||
          +term === 718 || +term === 719 || +term === 720 || +term === 721 ||
          +term === 722 || +term === 724 || +term === 725 || +term === 729 ||
          +term === 730 || +term === 731 || +term === 712) {
          this.genericTermsEpididimoDireito.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 713 || +term === 714 || +term === 716 || +term === 717 ||
          +term === 718 || +term === 719 || +term === 720 || +term === 721 ||
          +term === 722 || +term === 724 || +term === 725 || +term === 729 ||
          +term === 730 || +term === 731 || +term === 712) {
          this.genericTermsEpididimoDireito.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoEpididimoEsquerdo(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 734 || +term === 735 || +term === 736 || +term === 738 ||
          +term === 739 || +term === 740 || +term === 741 || +term === 742 ||
          +term === 743 || +term === 744 || +term === 746 || +term === 747 ||
          +term === 751 || +term === 752 || +term === 753) {
          this.genericTermsEpididimoEsquerdo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 734 || +term === 735 || +term === 736 || +term === 738 ||
          +term === 739 || +term === 740 || +term === 741 || +term === 742 ||
          +term === 743 || +term === 744 || +term === 746 || +term === 747 ||
          +term === 751 || +term === 752 || +term === 753) {
          this.genericTermsEpididimoEsquerdo.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoHidroceleDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 757 || +term === 758) {
        this.genericTermsHidroceleDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoHidroceleDireita(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 757) {
        this.genericTermsHidroceleDireita.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoHidroceleEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 762 || +term === 763) {
        this.genericTermsHidroceleEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoHidroceleEsquerda(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 762) {
        this.genericTermsHidroceleEsquerda.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTesticuloDireito(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 773 || +term === 775 || +term === 776 ||
        +term === 777 || +term === 779 || +term === 780 || +term === 781 || +term === 783 ||
        +term === 784 || +term === 785 || +term === 786 || +term === 787 || +term === 788 ||
        +term === 789 || +term === 795 || +term === 796 || +term === 797 || +term === 798) {
        this.genericTermsTesticuloDireito.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 767 || +term === 768 || +term === 783 || +term === 784 || +term === 785 || +term === 786 ||
          +term === 787 || +term === 788 || +term === 789 || +term === 795 || +term === 796 || +term === 797 || +term === 798) {
          this.genericTermsTesticuloDireito.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoTesticuloEsquerdo(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 805 || +term === 806 || +term === 807 ||
        +term === 808 || +term === 809 || +term === 811 || +term === 812 || +term === 813 ||
        +term === 815 || +term === 816 || +term === 817 || +term === 818 || +term === 819 ||
        +term === 820 || +term === 821 || +term === 826 || +term === 827 || +term === 829) {
        this.genericTermsTesticuloEsquerdo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 799 || +term === 800 || +term === 815 || +term === 816 || +term === 817 || +term === 818 ||
          +term === 819 || +term === 820 || +term === 821 || +term === 826 || +term === 827 || +term === 829) {
          this.genericTermsTesticuloEsquerdo.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }

    return texto;
  }

  MontaDescricaoOrquiectomia(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 4607 || +term === 4608 || +term === 4609 || +term === 4610
          || +term === 4611 || +term === 4612 || +term === 4613 || +term === 4614) {
          this.genericTermsOrquiectomia.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 4607 || +term === 4608 || +term === 4609 || +term === 4610
          || +term === 4611 || +term === 4612 || +term === 4613 || +term === 4614) {
          this.genericTermsOrquiectomia.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '<p><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></p>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });
    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value && conclusao.visivel === true) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados_adicionais`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|')

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.indicacaoTexto;
     //Lógica Rules
     if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
     this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.</br></br>';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].visivel === true) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value && this.conclusoes[i].visivel === true) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId} p`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    const texto = 'Estudo ultrassonográfico sem alterações significativas.';

    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
