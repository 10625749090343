import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';


@Component({
  selector: 'app-musculatura-regional',
  templateUrl: './musculatura-regional.component.html',
  styleUrls: ['./musculatura-regional.component.css']
})
export class MusculaturaRegionalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;


  constructor() { }

  ngOnInit() {

  }
  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }
    this.listaCompleta.emit();
  }

}
