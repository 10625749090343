import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatDialog } from '@angular/material';
import { Calculadora } from 'src/app/utils/calculadora';
import { ParametrosBiometricosDialogComponent } from 'src/app/utils/dialog/table/parametros-biometricos-dialog/parametros-biometricos-dialog.component';
import { Validacoes } from 'src/app/utils/validacoes';
import { ObstetricoImagesReferenciaComponent } from 'src/app/utils/dialog/imagens/obstetrico-images-referencia/obstetrico-images-referencia.component';
import { CommonService } from 'src/app/services/common.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-biometria-fetal-gemelar',
  templateUrl: './biometria-fetal-gemelar.component.html',
  styleUrls: ['./biometria-fetal-gemelar.component.css']
})
export class BiometriaFetalGemelarComponent implements OnInit {

    @Output() termoSelecionado = new EventEmitter();
    @Output() termosGenericos = new EventEmitter();
    @Output() listaCompleta = new EventEmitter();
    @Output() camposObrigatoriosPreenchidos = new EventEmitter();
  
    @ViewChild('rdSemMedida') rdSemMedida: MatRadioButton;
    @ViewChild('rdComMedida') rdComMedida: MatRadioButton;
    @ViewChild('inDBP') inDBP: ElementRef;
    @ViewChild('inCC') inCC: ElementRef;
    @ViewChild('inCA') inCA: ElementRef;
    @ViewChild('inCF') inCF: ElementRef;
    @ViewChild('inPeso') inPeso: ElementRef;
    @ViewChild('inDOF') inDOF: ElementRef;
    @ViewChild('inCUM') inCUM: ElementRef;
    @ViewChild('inPercentil') inPercentil: ElementRef;
  
    @ViewChild('inSemanas') inSemanas: ElementRef;
    @ViewChild('inDias') inDias: ElementRef;
  
    showMedida = false;
    peso;
    semanas: string = 'Semanas';
    dias: string = 'Dias';
  
    inPesoInvalid: boolean = true;
    inCFInvalid: boolean = true;
    inDBPInvalid: boolean = true;
    inCAInvalid: boolean = true;
    inCCInvalid: boolean = true;

    @ViewChild('rdSemMedida2') rdSemMedida2: MatRadioButton;
    @ViewChild('rdComMedida2') rdComMedida2: MatRadioButton;
    @ViewChild('inDBP2') inDBP2: ElementRef;
    @ViewChild('inCC2') inCC2: ElementRef;
    @ViewChild('inCA2') inCA2: ElementRef;
    @ViewChild('inCF2') inCF2: ElementRef;
    @ViewChild('inPeso2') inPeso2: ElementRef;
    @ViewChild('inDOF2') inDOF2: ElementRef;
    @ViewChild('inCUM2') inCUM2: ElementRef;
    @ViewChild('inPercentil2') inPercentil2: ElementRef;
  
    @ViewChild('inSemanas2') inSemanas2: ElementRef;
    @ViewChild('inDias2') inDias2: ElementRef;
  
    showMedida2 = false;
    peso2;
    semanas2: string = 'Semanas';
    dias2: string = 'Dias';
  
    inPesoInvalid2: boolean = true;
    inCFInvalid2: boolean = true;
    inDBPInvalid2: boolean = true;
    inCAInvalid2: boolean = true;
    inCCInvalid2: boolean = true;

    @ViewChild('rdSemMedida3') rdSemMedida3: MatRadioButton;
    @ViewChild('rdComMedida3') rdComMedida3: MatRadioButton;
    @ViewChild('inDBP3') inDBP3: ElementRef;
    @ViewChild('inCC3') inCC3: ElementRef;
    @ViewChild('inCA3') inCA3: ElementRef;
    @ViewChild('inCF3') inCF3: ElementRef;
    @ViewChild('inPeso3') inPeso3: ElementRef;
    @ViewChild('inDOF3') inDOF3: ElementRef;
    @ViewChild('inCUM3') inCUM3: ElementRef;
    @ViewChild('inPercentil3') inPercentil3: ElementRef;
  
    @ViewChild('inSemanas3') inSemanas3: ElementRef;
    @ViewChild('inDias3') inDias3: ElementRef;
  
    showMedida3 = false;
    peso3;
    semanas3: string = 'Semanas';
    dias3: string = 'Dias';
  
    inPesoInvalid3: boolean = true;
    inCFInvalid3: boolean = true;
    inDBPInvalid3: boolean = true;
    inCAInvalid3: boolean = true;
    inCCInvalid3: boolean = true;

    @ViewChild('rdSemMedida4') rdSemMedida4: MatRadioButton;
    @ViewChild('rdComMedida4') rdComMedida4: MatRadioButton;
    @ViewChild('inDBP4') inDBP4: ElementRef;
    @ViewChild('inCC4') inCC4: ElementRef;
    @ViewChild('inCA4') inCA4: ElementRef;
    @ViewChild('inCF4') inCF4: ElementRef;
    @ViewChild('inPeso4') inPeso4: ElementRef;
    @ViewChild('inDOF4') inDOF4: ElementRef;
    @ViewChild('inCUM4') inCUM4: ElementRef;
    @ViewChild('inPercentil4') inPercentil4: ElementRef;
  
    @ViewChild('inSemanas4') inSemanas4: ElementRef;
    @ViewChild('inDias4') inDias4: ElementRef;
  
    showMedida4 = false;
    peso4;
    semanas4: string = 'Semanas';
    dias4: string = 'Dias';
  
    inPesoInvalid4: boolean = true;
    inCFInvalid4: boolean = true;
    inDBPInvalid4: boolean = true;
    inCAInvalid4: boolean = true;
    inCCInvalid4: boolean = true;

    showAndHideFetos: any;

    fetoSelected: number = 1;
    quantidadeFetos: number = 2;
  
    constructor(private _calculadora: Calculadora,
      public dialog: MatDialog,
      private _validacoes: Validacoes,
      private _common: CommonService) { }
  
    ngOnInit() {
      this._common.posicaoFeto.subscribe((feto: any) => {
        this.fetoSelected = feto;
        this.showAndHideFetos = {
          feto1: feto === 1,
          feto2: feto === 2,
          feto3: feto === 3,
          feto4: feto === 4,
        };
      });
      this._common.quantidadeFeto.subscribe((quantidade: any) => {
        this.quantidadeFetos = quantidade;
        this.camposPreenchidos();
      });

      this.showAndHideFetos = {
        feto1: true,
        feto2: false,
        feto3: false,
        feto4: false,
      };
    }
  
    blurParametros() {
      this.catchIDs();
    }
  
    clickSemMedida() {
      this.catchIDs();
      this.showMedida = false;
    }

    clickSemMedida2() {
      this.catchIDs();
      this.showMedida2 = false;
    }

    clickSemMedida3() {
      this.catchIDs();
      this.showMedida3 = false;
    }
    
    clickSemMedida4() {
      this.catchIDs();
      this.showMedida4 = false;
    }

    clickComMedida() {
      this.catchIDs();
      this.showMedida = true;
    }

    clickComMedida2() {
      this.catchIDs();
      this.showMedida2 = true;
    }

    clickComMedida3() {
      this.catchIDs();
      this.showMedida3 = true;
    }

    clickComMedida4() {
      this.catchIDs();
      this.showMedida4 = true;
    }
  
    clickTabelaReferencia() {
      this.dialog.open(ParametrosBiometricosDialogComponent, {});
    }
  
    clickImagemReferencia() {
      this.dialog.open(ObstetricoImagesReferenciaComponent, {});
    }
  
    calculaIG(): number {
      return 10.85 +
        (0.06 * (parseFloat(this.inCC.nativeElement.value) / 10) * (parseFloat(this.inCF.nativeElement.value) / 10)) +
        (0.67 * (parseFloat(this.inDBP.nativeElement.value) / 10)) + (0.168 * (parseFloat(this.inCA.nativeElement.value) / 10));
    }

    calculaIG2(): number {
      return 10.85 +
        (0.06 * (parseFloat(this.inCC2.nativeElement.value) / 10) * (parseFloat(this.inCF2.nativeElement.value) / 10)) +
        (0.67 * (parseFloat(this.inDBP2.nativeElement.value) / 10)) + (0.168 * (parseFloat(this.inCA2.nativeElement.value) / 10));
    }

    calculaIG3(): number {
      return 10.85 +
        (0.06 * (parseFloat(this.inCC3.nativeElement.value) / 10) * (parseFloat(this.inCF3.nativeElement.value) / 10)) +
        (0.67 * (parseFloat(this.inDBP3.nativeElement.value) / 10)) + (0.168 * (parseFloat(this.inCA3.nativeElement.value) / 10));
    }

    calculaIG4(): number {
      return 10.85 +
        (0.06 * (parseFloat(this.inCC4.nativeElement.value) / 10) * (parseFloat(this.inCF4.nativeElement.value) / 10)) +
        (0.67 * (parseFloat(this.inDBP4.nativeElement.value) / 10)) + (0.168 * (parseFloat(this.inCA4.nativeElement.value) / 10));
    }
  
    calculaPeso(): number {
      //fórmula: [Log10(PE)=1,3596+(0,00061xDBPxCA)+(0,424xCA)+(1,74xCF)+(0,0064xCC)-(0,00386xCAxCF)]
      let ca = this.inCA.nativeElement.value ? this.inCA.nativeElement.value : '0';
      let cf = this.inCF.nativeElement.value ? this.inCF.nativeElement.value : '0';
      let dbp = this.inDBP.nativeElement.value ? this.inDBP.nativeElement.value : '0';
      let cc = this.inCC.nativeElement.value ? this.inCC.nativeElement.value : '0'
  
      return 10 ** (1.3596 -
        0.00386 * parseFloat(ca)/10 * parseFloat(cf)/10 +
        0.0064 * parseFloat(cc)/10 +
        0.00061 * parseFloat(dbp)/10 *
        parseFloat(ca)/10 +
        0.0424 * parseFloat(ca)/10 +
        0.174 * parseFloat(cf)/10);
    }

    calculaPeso2(): number {
      //fórmula: [Log10(PE)=1,3596+(0,00061xDBPxCA)+(0,424xCA)+(1,74xCF)+(0,0064xCC)-(0,00386xCAxCF)]
      let ca = this.inCA2.nativeElement.value ? this.inCA2.nativeElement.value : '0';
      let cf = this.inCF2.nativeElement.value ? this.inCF2.nativeElement.value : '0';
      let dbp = this.inDBP2.nativeElement.value ? this.inDBP2.nativeElement.value : '0';
      let cc = this.inCC2.nativeElement.value ? this.inCC2.nativeElement.value : '0'
  
      return 10 ** (1.3596 -
        0.00386 * parseFloat(ca)/10 * parseFloat(cf)/10 +
        0.0064 * parseFloat(cc)/10 +
        0.00061 * parseFloat(dbp)/10 *
        parseFloat(ca)/10 +
        0.0424 * parseFloat(ca)/10 +
        0.174 * parseFloat(cf)/10);
    }

    calculaPeso3(): number {
      //fórmula: [Log10(PE)=1,3596+(0,00061xDBPxCA)+(0,424xCA)+(1,74xCF)+(0,0064xCC)-(0,00386xCAxCF)]
      let ca = this.inCA3.nativeElement.value ? this.inCA3.nativeElement.value : '0';
      let cf = this.inCF3.nativeElement.value ? this.inCF3.nativeElement.value : '0';
      let dbp = this.inDBP3.nativeElement.value ? this.inDBP3.nativeElement.value : '0';
      let cc = this.inCC3.nativeElement.value ? this.inCC3.nativeElement.value : '0'
  
      return 10 ** (1.3596 -
        0.00386 * parseFloat(ca)/10 * parseFloat(cf)/10 +
        0.0064 * parseFloat(cc)/10 +
        0.00061 * parseFloat(dbp)/10 *
        parseFloat(ca)/10 +
        0.0424 * parseFloat(ca)/10 +
        0.174 * parseFloat(cf)/10);
    }

    calculaPeso4(): number {
      //fórmula: [Log10(PE)=1,3596+(0,00061xDBPxCA)+(0,424xCA)+(1,74xCF)+(0,0064xCC)-(0,00386xCAxCF)]
      let ca = this.inCA4.nativeElement.value ? this.inCA4.nativeElement.value : '0';
      let cf = this.inCF4.nativeElement.value ? this.inCF4.nativeElement.value : '0';
      let dbp = this.inDBP4.nativeElement.value ? this.inDBP4.nativeElement.value : '0';
      let cc = this.inCC4.nativeElement.value ? this.inCC4.nativeElement.value : '0'
  
      return 10 ** (1.3596 -
        0.00386 * parseFloat(ca)/10 * parseFloat(cf)/10 +
        0.0064 * parseFloat(cc)/10 +
        0.00061 * parseFloat(dbp)/10 *
        parseFloat(ca)/10 +
        0.0424 * parseFloat(ca)/10 +
        0.174 * parseFloat(cf)/10);
    }
  
    placeholderPeso(): string {
      if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
        || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
        this.peso = this.calculaPeso();
        return this.peso.toFixed(4);
      } else
        this.peso = 'Peso';
      this.peso = 'Peso';
      return this.peso;
    }

    placeholderPeso2(): string {
      if ((this.inDBP2 && this.inDBP2.nativeElement.value) || (this.inCC2 && this.inCC2.nativeElement.value)
        || (this.inCF2 && this.inCF2.nativeElement.value) || (this.inCA2 && this.inCA2.nativeElement.value)) {
        this.peso2 = this.calculaPeso2();
        return this.peso2.toFixed(4);
      } else
        this.peso2 = 'Peso';
      this.peso2 = 'Peso';
      return this.peso2;
    }

    placeholderPeso3(): string {
      if ((this.inDBP3 && this.inDBP3.nativeElement.value) || (this.inCC3 && this.inCC3.nativeElement.value)
        || (this.inCF3 && this.inCF3.nativeElement.value) || (this.inCA3 && this.inCA3.nativeElement.value)) {
        this.peso3 = this.calculaPeso3();
        return this.peso3.toFixed(4);
      } else
        this.peso3 = 'Peso';
      this.peso3 = 'Peso';
      return this.peso3;
    }

    placeholderPeso4(): string {
      if ((this.inDBP4 && this.inDBP4.nativeElement.value) || (this.inCC4 && this.inCC4.nativeElement.value)
        || (this.inCF4 && this.inCF4.nativeElement.value) || (this.inCA4 && this.inCA4.nativeElement.value)) {
        this.peso4 = this.calculaPeso4();
        return this.peso4.toFixed(4);
      } else
        this.peso4 = 'Peso';
      this.peso4 = 'Peso';
      return this.peso4;
    }
  
    catchIDs() {
      this.termoSelecionado.emit('Clean');
      if(this.fetoSelected === 1){
        this.removeInvalidField();
        if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
          || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
          if (this.inPeso) {
            this.inPeso.nativeElement.value = this.calculaPeso().toFixed(2);
          }
        }
    
        if ((this.inDBP && this.inDBP.nativeElement.value) && (this.inCC && this.inCC.nativeElement.value) && (this.inCA && this.inCA.nativeElement.value)
          && (this.inCF && this.inCF.nativeElement.value) && (this.peso != 'Peso')
          && (this.inDOF && this.inDOF.nativeElement.value) && (this.inCUM && this.inCUM.nativeElement.value)) {
    
          //com medida
          this.termoSelecionado.emit("1984f1");
    
          let objetoElemento = { id: "1986f1", value: this.inDBP.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1988f1", value: this.inCC.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1989f1", value: this.inCA.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1991f1", value: this.inCF.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          //Logica placeholder peso
          if (this.inPeso.nativeElement.value) {
            objetoElemento = { id: "1992f1", value: this.inPeso.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          } else {
            objetoElemento = { id: "1992f1", value: this.peso };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          }
    
          objetoElemento = { id: "1987f1", value: this.inDOF.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1990f1", value: this.inCUM.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          let idade = this.calculaIG();
          let semanas = parseInt(idade + '');
          let dias = parseInt(((idade - semanas) * 7) + '');
    
          if ((semanas >= 0) && (dias >= 0)) {
    
            if (this.inSemanas && this.inSemanas.nativeElement.value) {
              objetoCriado = { elemento: this.inSemanas.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.semanas = semanas.toString();
              objetoElemento = { id: "2035f1", value: semanas };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            if (this.inDias && this.inDias.nativeElement.value) {
              objetoCriado = { elemento: this.inDias.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.dias = dias.toString();
              objetoElemento = { id: "2036f1", value: dias };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
          }
    
        } else
          if ((this.inDBP && this.inDBP.nativeElement.value)
            && (this.inCC && this.inCC.nativeElement.value) && (this.inCA && this.inCA.nativeElement.value)
            || (this.inCF && this.inCF.nativeElement.value) || (this.peso != 'Peso')) {
    
            //sem medida
            this.termoSelecionado.emit("1985f1");
    
            let objetoElemento = { id: "1986f1", value: this.inDBP.nativeElement.value };
            let objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1988f1", value: this.inCC.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1989f1", value: this.inCA.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1991f1", value: this.inCF.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            //Logica placeholder peso
            if (this.inPeso.nativeElement.value) {
              objetoElemento = { id: "1992f1", value: this.inPeso.nativeElement.value };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            } else {
              objetoElemento = { id: "1992f1", value: this.peso };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            let idade = this.calculaIG();
            //      const teste = this._calculadora.semanasDiff(parseInt(idade))
            // let semanas = Math.floor(idade / 7);
            // let dias = Math.round(idade % 7);
    
            let semanas = parseInt(idade + '');
            let dias = parseInt(((idade - semanas) * 7) + '');
    
            if ((semanas >= 0) && (dias >= 0)) {
    
              if (this.inSemanas && this.inSemanas.nativeElement.value) {
                objetoCriado = { elemento: this.inSemanas.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.semanas = semanas.toString();
                objetoElemento = { id: "2035f1", value: semanas };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
              if (this.inDias && this.inDias.nativeElement.value) {
                objetoCriado = { elemento: this.inDias.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.dias = dias.toString();
                objetoElemento = { id: "2036f1", value: dias };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
            }
          }
        if (this.inPercentil && this.inPercentil.nativeElement.value) {
          let objetoElemento = { id: "4787f1", value: this.inPercentil.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }
    
        this.camposPreenchidos();
        /* if ((this.inDBP && this.inDBP.nativeElement.value)
          && (this.inCC && this.inCC.nativeElement.value)
          && (this.inCF && this.inCF.nativeElement.value)
          && (this.inCA && this.inCA.nativeElement.value)) {
          this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
        } else {
          this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
        } */
      } else if(this.fetoSelected === 2){
        this.removeInvalidField2();
        if ((this.inDBP2 && this.inDBP2.nativeElement.value) || (this.inCC2 && this.inCC2.nativeElement.value)
          || (this.inCF2 && this.inCF2.nativeElement.value) || (this.inCA2 && this.inCA2.nativeElement.value)) {
          if (this.inPeso2) {
            this.inPeso2.nativeElement.value = this.calculaPeso2().toFixed(2);
          }
        }
    
        if ((this.inDBP2 && this.inDBP2.nativeElement.value) && (this.inCC2 && this.inCC2.nativeElement.value) && (this.inCA2 && this.inCA2.nativeElement.value)
          && (this.inCF2 && this.inCF2.nativeElement.value) && (this.peso2 != 'Peso')
          && (this.inDOF2 && this.inDOF2.nativeElement.value) && (this.inCUM2 && this.inCUM2.nativeElement.value)) {
    
          //com medida
          this.termoSelecionado.emit("1984f2");
    
          let objetoElemento = { id: "1986f2", value: this.inDBP2.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1988f2", value: this.inCC2.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1989f2", value: this.inCA2.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1991f2", value: this.inCF2.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          //Logica placeholder peso
          if (this.inPeso.nativeElement.value) {
            objetoElemento = { id: "1992f2", value: this.inPeso2.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          } else {
            objetoElemento = { id: "1992f2", value: this.peso2 };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          }
    
          objetoElemento = { id: "1987f2", value: this.inDOF2.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1990f2", value: this.inCUM2.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          let idade = this.calculaIG2();
          let semanas = parseInt(idade + '');
          let dias = parseInt(((idade - semanas) * 7) + '');
    
          if ((semanas >= 0) && (dias >= 0)) {
    
            if (this.inSemanas2 && this.inSemanas2.nativeElement.value) {
              objetoCriado = { elemento: this.inSemanas2.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.semanas2 = semanas.toString();
              objetoElemento = { id: "2035f2", value: semanas };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            if (this.inDias2 && this.inDias2.nativeElement.value) {
              objetoCriado = { elemento: this.inDias2.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.dias2 = dias.toString();
              objetoElemento = { id: "2036f2", value: dias };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
          }
    
        } else
          if ((this.inDBP2 && this.inDBP2.nativeElement.value)
            && (this.inCC2 && this.inCC2.nativeElement.value) && (this.inCA2 && this.inCA2.nativeElement.value)
            || (this.inCF2 && this.inCF2.nativeElement.value) || (this.peso2 != 'Peso')) {
    
            //sem medida
            this.termoSelecionado.emit("1985f2");
    
            let objetoElemento = { id: "1986f2", value: this.inDBP2.nativeElement.value };
            let objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1988f2", value: this.inCC2.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1989f2", value: this.inCA2.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1991f2", value: this.inCF2.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            //Logica placeholder peso
            if (this.inPeso2.nativeElement.value) {
              objetoElemento = { id: "1992f2", value: this.inPeso2.nativeElement.value };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            } else {
              objetoElemento = { id: "1992f2", value: this.peso2 };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            let idade = this.calculaIG2();
            //      const teste = this._calculadora.semanasDiff(parseInt(idade))
            // let semanas = Math.floor(idade / 7);
            // let dias = Math.round(idade % 7);
    
            let semanas = parseInt(idade + '');
            let dias = parseInt(((idade - semanas) * 7) + '');
    
            if ((semanas >= 0) && (dias >= 0)) {
    
              if (this.inSemanas2 && this.inSemanas2.nativeElement.value) {
                objetoCriado = { elemento: this.inSemanas2.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.semanas2 = semanas.toString();
                objetoElemento = { id: "2035f2", value: semanas };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
              if (this.inDias2 && this.inDias2.nativeElement.value) {
                objetoCriado = { elemento: this.inDias2.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.dias2 = dias.toString();
                objetoElemento = { id: "2036f2", value: dias };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
            }
          }
        if (this.inPercentil2 && this.inPercentil2.nativeElement.value) {
          let objetoElemento = { id: "4787f2", value: this.inPercentil2.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }
    
        this.camposPreenchidos();

        /* if ((this.inDBP2 && this.inDBP2.nativeElement.value)
          && (this.inCC2 && this.inCC2.nativeElement.value)
          && (this.inCF2 && this.inCF2.nativeElement.value)
          && (this.inCA2 && this.inCA2.nativeElement.value)) {
          this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
        } else {
          this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
        } */
      } else if(this.fetoSelected === 3){
        this.removeInvalidField3();
        if ((this.inDBP3 && this.inDBP3.nativeElement.value) || (this.inCC3 && this.inCC3.nativeElement.value)
          || (this.inCF3 && this.inCF3.nativeElement.value) || (this.inCA3 && this.inCA3.nativeElement.value)) {
          if (this.inPeso3) {
            this.inPeso3.nativeElement.value = this.calculaPeso3().toFixed(2);
          }
        }

        if ((this.inDBP3 && this.inDBP3.nativeElement.value) && (this.inCC3 && this.inCC3.nativeElement.value) && (this.inCA3 && this.inCA3.nativeElement.value)
          && (this.inCF3 && this.inCF3.nativeElement.value) && (this.peso3 != 'Peso')
          && (this.inDOF3 && this.inDOF3.nativeElement.value) && (this.inCUM3 && this.inCUM3.nativeElement.value)) {
    
          //com medida
          this.termoSelecionado.emit("1984f3");
    
          let objetoElemento = { id: "1986f3", value: this.inDBP3.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1988f3", value: this.inCC3.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1989f3", value: this.inCA3.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1991f3", value: this.inCF3.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          //Logica placeholder peso
          if (this.inPeso.nativeElement.value) {
            objetoElemento = { id: "1992f3", value: this.inPeso3.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          } else {
            objetoElemento = { id: "1992f3", value: this.peso3 };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          }
    
          objetoElemento = { id: "1987f3", value: this.inDOF3.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1990f3", value: this.inCUM3.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          let idade = this.calculaIG3();
          let semanas = parseInt(idade + '');
          let dias = parseInt(((idade - semanas) * 7) + '');
    
          if ((semanas >= 0) && (dias >= 0)) {
    
            if (this.inSemanas3 && this.inSemanas3.nativeElement.value) {
              objetoCriado = { elemento: this.inSemanas3.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.semanas3 = semanas.toString();
              objetoElemento = { id: "2035f3", value: semanas };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            if (this.inDias3 && this.inDias3.nativeElement.value) {
              objetoCriado = { elemento: this.inDias3.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.dias3 = dias.toString();
              objetoElemento = { id: "2036f3", value: dias };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
          }
    
        } else
          if ((this.inDBP3 && this.inDBP3.nativeElement.value)
            && (this.inCC3 && this.inCC3.nativeElement.value) && (this.inCA3 && this.inCA3.nativeElement.value)
            || (this.inCF3 && this.inCF3.nativeElement.value) || (this.peso3 != 'Peso')) {
    
            //sem medida
            this.termoSelecionado.emit("1985f3");
    
            let objetoElemento = { id: "1986f3", value: this.inDBP3.nativeElement.value };
            let objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1988f3", value: this.inCC3.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1989f3", value: this.inCA3.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1991f3", value: this.inCF3.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            //Logica placeholder peso
            if (this.inPeso3.nativeElement.value) {
              objetoElemento = { id: "1992f3", value: this.inPeso3.nativeElement.value };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            } else {
              objetoElemento = { id: "1992f3", value: this.peso3 };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            let idade = this.calculaIG3();
            //      const teste = this._calculadora.semanasDiff(parseInt(idade))
            // let semanas = Math.floor(idade / 7);
            // let dias = Math.round(idade % 7);
    
            let semanas = parseInt(idade + '');
            let dias = parseInt(((idade - semanas) * 7) + '');
    
            if ((semanas >= 0) && (dias >= 0)) {
    
              if (this.inSemanas3 && this.inSemanas3.nativeElement.value) {
                objetoCriado = { elemento: this.inSemanas3.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.semanas3 = semanas.toString();
                objetoElemento = { id: "2035f3", value: semanas };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
              if (this.inDias3 && this.inDias3.nativeElement.value) {
                objetoCriado = { elemento: this.inDias3.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.dias3 = dias.toString();
                objetoElemento = { id: "2036f3", value: dias };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
            }
          }
        if (this.inPercentil3 && this.inPercentil3.nativeElement.value) {
          let objetoElemento = { id: "4787f3", value: this.inPercentil3.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }
    
        this.camposPreenchidos();

        /* if ((this.inDBP3 && this.inDBP3.nativeElement.value)
          && (this.inCC3 && this.inCC3.nativeElement.value)
          && (this.inCF3 && this.inCF3.nativeElement.value)
          && (this.inCA3 && this.inCA3.nativeElement.value)) {
          this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
        } else {
          this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
        }
        */
      } else if(this.fetoSelected === 4){
        this.removeInvalidField4();

        if ((this.inDBP4 && this.inDBP4.nativeElement.value) || (this.inCC4 && this.inCC4.nativeElement.value)
          || (this.inCF4 && this.inCF4.nativeElement.value) || (this.inCA4 && this.inCA4.nativeElement.value)) {
          if (this.inPeso4) {
            this.inPeso4.nativeElement.value = this.calculaPeso4().toFixed(2);
          }
        }

        if ((this.inDBP4 && this.inDBP4.nativeElement.value) && (this.inCC4 && this.inCC4.nativeElement.value) && (this.inCA4 && this.inCA4.nativeElement.value)
          && (this.inCF4 && this.inCF4.nativeElement.value) && (this.peso4 != 'Peso')
          && (this.inDOF4 && this.inDOF4.nativeElement.value) && (this.inCUM4 && this.inCUM4.nativeElement.value)) {
    
          //com medida
          this.termoSelecionado.emit("1984f4");
    
          let objetoElemento = { id: "1986f4", value: this.inDBP4.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1988f4", value: this.inCC4.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1989f4", value: this.inCA4.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1991f4", value: this.inCF4.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          //Logica placeholder peso
          if (this.inPeso.nativeElement.value) {
            objetoElemento = { id: "1992f4", value: this.inPeso4.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          } else {
            objetoElemento = { id: "1992f4", value: this.peso4 };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          }
    
          objetoElemento = { id: "1987f4", value: this.inDOF4.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          objetoElemento = { id: "1990f4", value: this.inCUM4.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
    
          let idade = this.calculaIG4();
          let semanas = parseInt(idade + '');
          let dias = parseInt(((idade - semanas) * 7) + '');
    
          if ((semanas >= 0) && (dias >= 0)) {
    
            if (this.inSemanas4 && this.inSemanas4.nativeElement.value) {
              objetoCriado = { elemento: this.inSemanas4.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.semanas4 = semanas.toString();
              objetoElemento = { id: "2035f4", value: semanas };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            if (this.inDias4 && this.inDias4.nativeElement.value) {
              objetoCriado = { elemento: this.inDias4.nativeElement };
              this.termosGenericos.emit(objetoCriado);
            } else {
              this.dias4 = dias.toString();
              objetoElemento = { id: "2036f4", value: dias };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
          }
    
        } else
          if ((this.inDBP4 && this.inDBP4.nativeElement.value)
            && (this.inCC4 && this.inCC4.nativeElement.value) && (this.inCA4 && this.inCA4.nativeElement.value)
            || (this.inCF4 && this.inCF4.nativeElement.value) || (this.peso4 != 'Peso')) {
    
            //sem medida
            this.termoSelecionado.emit("1985f4");
    
            let objetoElemento = { id: "1986f4", value: this.inDBP4.nativeElement.value };
            let objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1988f4", value: this.inCC4.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1989f4", value: this.inCA4.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            objetoElemento = { id: "1991f4", value: this.inCF4.nativeElement.value };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
    
            //Logica placeholder peso
            if (this.inPeso4.nativeElement.value) {
              objetoElemento = { id: "1992f4", value: this.inPeso4.nativeElement.value };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            } else {
              objetoElemento = { id: "1992f4", value: this.peso4 };
              objetoCriado = { elemento: objetoElemento };
              this.termosGenericos.emit(objetoCriado);
            }
    
            let idade = this.calculaIG4();
            //      const teste = this._calculadora.semanasDiff(parseInt(idade))
            // let semanas = Math.floor(idade / 7);
            // let dias = Math.round(idade % 7);
    
            let semanas = parseInt(idade + '');
            let dias = parseInt(((idade - semanas) * 7) + '');
    
            if ((semanas >= 0) && (dias >= 0)) {
    
              if (this.inSemanas4 && this.inSemanas4.nativeElement.value) {
                objetoCriado = { elemento: this.inSemanas4.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.semanas4 = semanas.toString();
                objetoElemento = { id: "2035f4", value: semanas };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
              if (this.inDias4 && this.inDias4.nativeElement.value) {
                objetoCriado = { elemento: this.inDias4.nativeElement };
                this.termosGenericos.emit(objetoCriado);
              } else {
                this.dias4 = dias.toString();
                objetoElemento = { id: "2036f4", value: dias };
                objetoCriado = { elemento: objetoElemento };
                this.termosGenericos.emit(objetoCriado);
              }
    
            }
          }
        if (this.inPercentil4 && this.inPercentil4.nativeElement.value) {
          let objetoElemento = { id: "4787f4", value: this.inPercentil4.nativeElement.value };
          let objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }

        this.camposPreenchidos();
        
      }
      
      this.listaCompleta.emit();
    }
  
    removeInvalidField() {
      if (this.inCF && this.inCF.nativeElement.value) {
        this.inCFInvalid = false;
      } else {
        this.inCFInvalid = true;
      }
      if (this.inDBP && this.inDBP.nativeElement.value) {
        this.inDBPInvalid = false;
      } else {
        this.inDBPInvalid = true;
      }
      if (this.inCA && this.inCA.nativeElement.value) {
        this.inCAInvalid = false;
      } else {
        this.inCAInvalid = true;
      }
      if (this.inCC && this.inCC.nativeElement.value) {
        this.inCCInvalid = false;
      } else {
        this.inCCInvalid = true;
      }
    }

    removeInvalidField2() {
      if (this.inCF2 && this.inCF2.nativeElement.value) {
        this.inCFInvalid2 = false;
      } else {
        this.inCFInvalid2 = true;
      }
      if (this.inDBP2 && this.inDBP2.nativeElement.value) {
        this.inDBPInvalid2 = false;
      } else {
        this.inDBPInvalid2 = true;
      }
      if (this.inCA2 && this.inCA2.nativeElement.value) {
        this.inCAInvalid2 = false;
      } else {
        this.inCAInvalid2 = true;
      }
      if (this.inCC2 && this.inCC2.nativeElement.value) {
        this.inCCInvalid2 = false;
      } else {
        this.inCCInvalid2 = true;
      }
    }

    removeInvalidField3() {
      if (this.inCF3 && this.inCF3.nativeElement.value) {
        this.inCFInvalid3 = false;
      } else {
        this.inCFInvalid3 = true;
      }
      if (this.inDBP3 && this.inDBP3.nativeElement.value) {
        this.inDBPInvalid3 = false;
      } else {
        this.inDBPInvalid3 = true;
      }
      if (this.inCA3 && this.inCA3.nativeElement.value) {
        this.inCAInvalid3 = false;
      } else {
        this.inCAInvalid3 = true;
      }
      if (this.inCC3 && this.inCC3.nativeElement.value) {
        this.inCCInvalid3 = false;
      } else {
        this.inCCInvalid3 = true;
      }
    }

    removeInvalidField4() {
      if (this.inCF4 && this.inCF4.nativeElement.value) {
        this.inCFInvalid4 = false;
      } else {
        this.inCFInvalid4 = true;
      }
      if (this.inDBP4 && this.inDBP4.nativeElement.value) {
        this.inDBPInvalid4 = false;
      } else {
        this.inDBPInvalid4 = true;
      }
      if (this.inCA4 && this.inCA4.nativeElement.value) {
        this.inCAInvalid4 = false;
      } else {
        this.inCAInvalid4 = true;
      }
      if (this.inCC4 && this.inCC4.nativeElement.value) {
        this.inCCInvalid4 = false;
      } else {
        this.inCCInvalid4 = true;
      }
    }

    camposPreenchidos(){
      if(this.quantidadeFetos === 1){
        if ((this.inDBP && this.inDBP.nativeElement.value)
          && (this.inCC && this.inCC.nativeElement.value)
          && (this.inCF && this.inCF.nativeElement.value)
          && (this.inCA && this.inCA.nativeElement.value)) {
            this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
          } else {
            this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
          }
      } else if(this.quantidadeFetos === 2){
        if ((this.inDBP && this.inDBP.nativeElement.value)
          && (this.inCC && this.inCC.nativeElement.value)
          && (this.inCF && this.inCF.nativeElement.value)
          && (this.inCA && this.inCA.nativeElement.value)
          && (this.inDBP2 && this.inDBP2.nativeElement.value)
          && (this.inCC2 && this.inCC2.nativeElement.value)
          && (this.inCF2 && this.inCF2.nativeElement.value)
          && (this.inCA2 && this.inCA2.nativeElement.value)) {
            this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
          } else {
            this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
          }  
      } else if(this.quantidadeFetos === 3){
        if ((this.inDBP && this.inDBP.nativeElement.value)
          && (this.inCC && this.inCC.nativeElement.value)
          && (this.inCF && this.inCF.nativeElement.value)
          && (this.inCA && this.inCA.nativeElement.value)
          && (this.inDBP2 && this.inDBP2.nativeElement.value)
          && (this.inCC2 && this.inCC2.nativeElement.value)
          && (this.inCF2 && this.inCF2.nativeElement.value)
          && (this.inCA2 && this.inCA2.nativeElement.value)
          && (this.inDBP3 && this.inDBP3.nativeElement.value)
          && (this.inCC3 && this.inCC3.nativeElement.value)
          && (this.inCF3 && this.inCF3.nativeElement.value)
          && (this.inCA3 && this.inCA3.nativeElement.value)) {
            this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
          } else {
            this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
          }
      } else if(this.quantidadeFetos === 4){
        if ((this.inDBP && this.inDBP.nativeElement.value)
          && (this.inCC && this.inCC.nativeElement.value)
          && (this.inCF && this.inCF.nativeElement.value)
          && (this.inCA && this.inCA.nativeElement.value)
          && (this.inDBP2 && this.inDBP2.nativeElement.value)
          && (this.inCC2 && this.inCC2.nativeElement.value)
          && (this.inCF2 && this.inCF2.nativeElement.value)
          && (this.inCA2 && this.inCA2.nativeElement.value)
          && (this.inDBP3 && this.inDBP3.nativeElement.value)
          && (this.inCC3 && this.inCC3.nativeElement.value)
          && (this.inCF3 && this.inCF3.nativeElement.value)
          && (this.inCA3 && this.inCA3.nativeElement.value)
          && (this.inDBP4 && this.inDBP4.nativeElement.value)
          && (this.inCC4 && this.inCC4.nativeElement.value)
          && (this.inCF4 && this.inCF4.nativeElement.value)
          && (this.inCA4 && this.inCA4.nativeElement.value)) {
            this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
          } else {
            this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
          }
      }
    } 

  }
  