import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css']
})
export class DispositivosComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdEndotraqueal') rdEndotraqueal: MatRadioButton;
  @ViewChild('rdTraqueostomia') rdTraqueostomia: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdEndotraqueal.checked) {
      this.termoSelecionado.emit(this.rdEndotraqueal.id);
    }
    if (this.rdTraqueostomia.checked) {
      this.termoSelecionado.emit(this.rdTraqueostomia.id);
    }
    this.listaCompleta.emit();
  }

}
