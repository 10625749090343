import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatDialog, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Calculadora } from 'src/app/utils/calculadora';
import { DuctoVenosoDialogComponent } from 'src/app/utils/dialog/table/ducto-venoso-dialog/ducto-venoso-dialog.component';
import { FetoDialogComponent } from 'src/app/utils/dialog/table/feto-dialog/feto-dialog.component';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-medidas-primeiro-trimestre',
  templateUrl: './medidas-primeiro-trimestre.component.html',
  styleUrls: ['./medidas-primeiro-trimestre.component.css']
})
export class MedidasPrimeiroTrimestreComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() calculadoraEmmit = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdBCFPresente') rdBCFPresente: MatCheckbox;
  @ViewChild('rdBCFAusente') rdBCFAusente: MatCheckbox;
  @ViewChild('ckCCN') ckCCN: MatCheckbox;
  @ViewChild('inCCN') inCCN: ElementRef;
  @ViewChild('ckDBP') ckDBP: MatCheckbox;
  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('ckNT') ckNT: MatCheckbox;
  @ViewChild('inNT') inNT: ElementRef;
  @ViewChild('inMedidaTranCraniana') inMedidaTranCraniana: ElementRef;
  @ViewChild('ckTraIntraCraniana') ckTraIntraCraniana: MatCheckbox;
  @ViewChild('slTraIntraCraniana') slTraIntraCraniana: MatSelect;
  @ViewChild('ckOssoNasal') ckOssoNasal: MatCheckbox;
  @ViewChild('slOssoNasal') slOssoNasal: MatSelect;
  @ViewChild('inOssoNasalMedida') inOssoNasalMedida: ElementRef;
  @ViewChild('ckDuctoVenoso') ckDuctoVenoso: MatCheckbox;
  @ViewChild('slDuctoVenoso') slDuctoVenoso: MatSelect;
  @ViewChild('inIP') inIP: ElementRef;
  @ViewChild('inIPPercentil') inIPPercentil: ElementRef;
  @ViewChild('ckRegurgitacao') ckRegurgitacao: MatCheckbox;
  @ViewChild('slRegurgitacao') slRegurgitacao: MatSelect;
  @ViewChild('inBCF') inBCF: ElementRef;
  @ViewChild('ckLiquido') ckLiquido: MatCheckbox;
  @ViewChild('slLiquido') slLiquido: MatSelect;

  countLesoes = 0;

  showAndHideTerms: any;

  inDBPInvalid: boolean = true;
  inCCNInvalid: boolean = true;
  inNTInvalid: boolean = true;

  traIntraCranianas: ArrayDropDown[] = [
    { value: 'presente', viewValue: 'Presente' },
    { value: 'ausente', viewValue: 'Ausente' },
  ];

  ossoNasal: ArrayDropDown[] = [
    { value: 'presente', viewValue: 'Presente' },
    { value: 'ausente', viewValue: 'Ausente' },
    { value: 'hipoplásico', viewValue: 'Hipoplásico' },
  ];

  ductoVenoso: ArrayDropDown[] = [
    { value: 'presente', viewValue: 'Presente' },
    { value: 'ausente', viewValue: 'Ausente' },
  ];

  regurgitacao: ArrayDropDown[] = [
    { value: 'presente', viewValue: 'Presente' },
    { value: 'ausente', viewValue: 'Ausente' },
    { value: 'não avaliada pelo posicionamento fetal', viewValue: 'Não avaliada pelo posicionamento fetal' },
  ];

  liquidoAmniotico: ArrayDropDown[] = [
    { value: 'subjetivamente normal', viewValue: 'Subjetivamente normal' },
    { value: 'reduzido', viewValue: 'Reduzido' },
    { value: 'aumentado', viewValue: 'Aumentado' },
  ];

  constructor(public dialog: MatDialog, private _validacoes: Validacoes, public _calc: Calculadora) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahDbp: false,
      sahCcn: false,
      sahTranslucenciaNucal: false,
      sahTranslucenciaIntraCraniana: false,
      sahOssoNasal: false,
      sahDuctoVenoso: false,
      sahRegurgitacao: false,
      sahBcf: false,
      sahLiquido: false,
      sahMedidaTranCraniana: false
    };
  }

  clickTabelaReferencia() {
    this.dialog.open(FetoDialogComponent, {});
  }

  blurParametros() {
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickDbp() {
    if (this.showAndHideTerms.sahDbp === false) {
      this.showAndHideTerms.sahDbp = true;
    } else {
      this.showAndHideTerms.sahDbp = false;
    }
    this.catchIDs();
  }
  clickCcn() {
    if (this.showAndHideTerms.sahCcn === false) {
      this.showAndHideTerms.sahCcn = true;
    } else {
      this.showAndHideTerms.sahCcn = false;
    }
    this.catchIDs();
  }
  clickTranslucenciaNucal() {
    if (this.showAndHideTerms.sahTranslucenciaNucal === false) {
      this.showAndHideTerms.sahTranslucenciaNucal = true;
    } else {
      this.showAndHideTerms.sahTranslucenciaNucal = false;
    }
    this.catchIDs();
  }
  clickTranslucenciaIntraCraniana() {
    if (this.showAndHideTerms.sahTranslucenciaIntraCraniana === false) {
      this.showAndHideTerms.sahTranslucenciaIntraCraniana = true;
    } else {
      this.showAndHideTerms.sahTranslucenciaIntraCraniana = false;
    }
    this.catchIDs();
  }
  clickOssoNasal() {
    if (this.showAndHideTerms.sahOssoNasal === false) {
      this.showAndHideTerms.sahOssoNasal = true;
    } else {
      this.showAndHideTerms.sahOssoNasal = false;
    }
    this.catchIDs();
  }
  clickDuctoVenoso() {
    if (this.showAndHideTerms.sahDuctoVenoso === false) {
      this.showAndHideTerms.sahDuctoVenoso = true;
    } else {
      this.showAndHideTerms.sahDuctoVenoso = false;
    }
    this.catchIDs();
  }
  clickRegurgitacao() {
    if (this.showAndHideTerms.sahRegurgitacao === false) {
      this.showAndHideTerms.sahRegurgitacao = true;
    } else {
      this.showAndHideTerms.sahRegurgitacao = false;
    }
    this.catchIDs();
  }
  clickBcfPresente() {
    if (this.showAndHideTerms.sahBcf === false) {
      this.showAndHideTerms.sahBcf = true;
    } else {
      this.showAndHideTerms.sahBcf = false;
    }
    this.catchIDs();
  }
  clickBcfAusente() {
    if (this.showAndHideTerms.sahBcf === true) {
      this.showAndHideTerms.sahBcf = false;
    }
    this.catchIDs();
  }
  clickLiquido() {
    if (this.showAndHideTerms.sahLiquido === false) {
      this.showAndHideTerms.sahLiquido = true;
    } else {
      this.showAndHideTerms.sahLiquido = false;
    }
    this.catchIDs();
  }

  changeSelect() {
    if (this.slTraIntraCraniana.value === "presente") {
      this.showAndHideTerms.sahMedidaTranCraniana = true;
    } else {
      this.showAndHideTerms.sahMedidaTranCraniana = false;
    }

    this.catchIDs();
  }

  clickTabelaReferenciaDucto() {
    this.dialog.open(DuctoVenosoDialogComponent, {});
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    this.emitCcn();

    if (this.countLesoes > 0) {
      this.emitBcfLesaoPresente();
    } else {
      this.emitBcfPresente();
    }
    if (this.countLesoes > 0) {
      this.emitBcfLesaoAusente();
    } else {
      this.emitBcfAusente();
    }

    if (this.countLesoes > 0) {
      this.emitDbpLesao();
    } else {
      this.emitDbp();
    }
    if (this.countLesoes > 0) {
      this.emitTranslucenciaNucalLesao();
    } else {
      this.emitTranslucenciaNucal();
    }
    if (this.countLesoes > 0) {
      this.emitTranslucenciaIntracranianaLesao();
    } else {
      this.emitTranslucenciaIntracraniana();
    }
    if (this.countLesoes > 0) {
      this.emitOssoNasalLesao();
    } else {
      this.emitOssoNasal();
    }
    if (this.countLesoes > 0) {
      this.emitDuctoVenosoLesao();
    } else {
      this.emitDuctoVenoso();
    }
    if (this.countLesoes > 0) {
      this.emitRegurgitacaoLesao();
    } else {
      this.emitRegurgitacao();
    }
    if (this.countLesoes > 0) {
      this.emitLiquidoLesao();
    } else {
      this.emitLiquido();
    }

    if ((this.ckCCN && this.ckCCN.checked && this.inCCN && this.inCCN.nativeElement.value)
      && (this.ckNT && this.ckNT.checked && this.inNT && this.inNT.nativeElement.value)
      && (this.ckDBP && this.ckDBP.checked && this.inDBP && this.inDBP.nativeElement.value)) {
      this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'medidasPrimeiroTrimestre' });
    } else {
      this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'medidasPrimeiroTrimestre' });
    }

    this.listaCompleta.emit();
  }
  emitCcn() {
    if (this.inCCN && this.inCCN.nativeElement.value) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCCN.id);
      const objeto = { elemento: this.inCCN.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);

      const ccn = parseFloat(this._validacoes.formataDecimal(this.inCCN.nativeElement.value));
      let idadeGestacional = this._calc.calculaIdadeGes(ccn);
      let elemento;
      elemento = { id: '4397', value: idadeGestacional };
      const obj = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(obj);
    }
  }
  emitCcnLesao() {
    if (this.inCCN && this.inCCN.nativeElement.value) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCCN.id);
      const objeto = { id: this.ckCCN.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      const ccn = parseFloat(this._validacoes.formataDecimal(this.inCCN.nativeElement.value));
      let idadeGestacional = this._calc.calculaIdadeGes(ccn);
      let elemento;
      elemento = { id: '4397', value: idadeGestacional };
      const obj = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(obj);
    }
  }
  emitDbp() {
    if (this.ckDBP && this.ckDBP.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDBP.id);
      if (this.inDBP && this.inDBP.nativeElement.value) {
        const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

      }
    }
  }
  emitDbpLesao() {
    if (this.ckDBP && this.ckDBP.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDBP.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.inDBP && this.inDBP.nativeElement.value) {
        const objeto = { elemento: this.inDBP.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitTranslucenciaNucal() {
    if (this.ckNT && this.ckNT.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNT.id);
      if (this.inNT && this.inNT.nativeElement.value) {
        const objeto = { elemento: this.inNT.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitTranslucenciaNucalLesao() {
    if (this.ckNT && this.ckNT.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckNT.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.inNT && this.inNT.nativeElement.value) {
        const objeto = { elemento: this.inNT.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitTranslucenciaIntracraniana() {
    if (this.ckTraIntraCraniana && this.ckTraIntraCraniana.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTraIntraCraniana.id);
      if (this.slTraIntraCraniana && this.slTraIntraCraniana.value) {
        const objeto = { elemento: this.slTraIntraCraniana, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slTraIntraCraniana.value == "presente") {
        if (this.inMedidaTranCraniana && this.inMedidaTranCraniana.nativeElement.value) {
          const objeto = { elemento: this.inMedidaTranCraniana.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }
  emitTranslucenciaIntracranianaLesao() {
    if (this.ckTraIntraCraniana && this.ckTraIntraCraniana.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckTraIntraCraniana.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slTraIntraCraniana && this.slTraIntraCraniana.value) {
        const objeto = { elemento: this.slTraIntraCraniana, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitOssoNasal() {
    if (this.ckOssoNasal && this.ckOssoNasal.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOssoNasal.id);
      if (this.slOssoNasal && this.slOssoNasal.value) {
        const objeto = { elemento: this.slOssoNasal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.inOssoNasalMedida && this.inOssoNasalMedida.nativeElement.value) {
        const objeto = { elemento: this.inOssoNasalMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitOssoNasalLesao() {
    if (this.ckOssoNasal && this.ckOssoNasal.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckOssoNasal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slOssoNasal && this.slOssoNasal.value) {
        const objeto = { elemento: this.slOssoNasal, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.inOssoNasalMedida && this.inOssoNasalMedida.nativeElement.value) {
        const objeto = { elemento: this.inOssoNasalMedida.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitDuctoVenoso() {
    if (this.ckDuctoVenoso && this.ckDuctoVenoso.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDuctoVenoso.id);
      if (this.slDuctoVenoso && this.slDuctoVenoso.value) {
        const objeto = { elemento: this.slDuctoVenoso, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIPPercentil && this.inIPPercentil.nativeElement.value) {
        const objeto = { elemento: this.inIPPercentil.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitDuctoVenosoLesao() {
    if (this.ckDuctoVenoso && this.ckDuctoVenoso.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDuctoVenoso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slDuctoVenoso && this.slDuctoVenoso.value) {
        const objeto = { elemento: this.slDuctoVenoso, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIP && this.inIP.nativeElement.value) {
        const objeto = { elemento: this.inIP.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIPPercentil && this.inIPPercentil.nativeElement.value) {
        const objeto = { elemento: this.inIPPercentil.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitRegurgitacao() {
    if (this.ckRegurgitacao && this.ckRegurgitacao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRegurgitacao.id);
      if (this.slRegurgitacao && this.slRegurgitacao.value) {
        const objeto = { elemento: this.slRegurgitacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitRegurgitacaoLesao() {
    if (this.ckRegurgitacao && this.ckRegurgitacao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckRegurgitacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slRegurgitacao && this.slRegurgitacao.value) {
        const objeto = { elemento: this.slRegurgitacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitBcfPresente() {
    if (this.rdBCFPresente && this.rdBCFPresente.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdBCFPresente.id);
      if (this.inBCF && this.inBCF.nativeElement.value) {
        const objeto = { elemento: this.inBCF.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitBcfLesaoPresente() {
    if (this.rdBCFPresente && this.rdBCFPresente.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdBCFPresente.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.inBCF && this.inBCF.nativeElement.value) {
        const objeto = { elemento: this.inBCF.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitBcfAusente() {
    if (this.rdBCFAusente && this.rdBCFAusente.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdBCFAusente.id);
    }
  }
  emitBcfLesaoAusente() {
    if (this.rdBCFAusente && this.rdBCFAusente.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdBCFAusente.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }
  emitLiquido() {
    if (this.ckLiquido && this.ckLiquido.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLiquido.id);
      if (this.slLiquido && this.slLiquido.value) {
        const objeto = { elemento: this.slLiquido, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitLiquidoLesao() {
    if (this.ckLiquido && this.ckLiquido.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLiquido.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slLiquido && this.slLiquido.value) {
        const objeto = { elemento: this.slLiquido, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  removeInvalidField() {
    if (this.inCCN && this.inCCN.nativeElement.value) {
      this.inCCNInvalid = false;
    } else {
      this.inCCNInvalid = true;
    }
    if (this.inDBP && this.inDBP.nativeElement.value) {
      this.inDBPInvalid = false;
    } else {
      this.inDBPInvalid = true;
    }
    if (this.inNT && this.inNT.nativeElement.value) {
      this.inNTInvalid = false;
    } else {
      this.inNTInvalid = true;
    }
  }
}
