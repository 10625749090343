import { MatCheckbox, MatRadioButton } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-lesoes-osseas',
  templateUrl: './lesoes-osseas.component.html',
  styleUrls: ['./lesoes-osseas.component.css']
})
export class LesoesOsseasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdLiticaMult') rdLiticaMult: MatRadioButton;
  @ViewChild('rdEscleroticaMult') rdEscleroticaMult: MatRadioButton;

  @ViewChild('ckLesaoLitica') ckLesaoLitica: MatCheckbox;
  @ViewChild('ckLesaoEsclerotica') ckLesaoEsclerotica: MatCheckbox;

  showAndHideTerms: any;
  countLesoes = 0;

  lesaoLiticaFormGroup: FormGroup;
  lesaoEscleroticaFormGroup: FormGroup;

  lesaoLitica: any;
  lesaoEsclerotica: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormLesaoLitica();
    this.iniciaFormLesaoEsclerotica();

    this.lesaoLitica = [
      { id: 'liticaT12', value: 'T12', name: 'T12' },
      { id: 'liticaL1', value: 'L1', name: 'L1' },
      { id: 'liticaL2', value: 'L2', name: 'L2' },
      { id: 'liticaL3', value: 'L3', name: 'L3' },
      { id: 'liticaL4', value: 'L4', name: 'L4' },
      { id: 'liticaL5', value: 'L5', name: 'L5' },
      { id: 'liticaS1', value: 'S1', name: 'S1' },
    ];

    this.lesaoEsclerotica = [
      { id: 'escleroticaT12', value: 'T12', name: 'T12' },
      { id: 'escleroticaL1', value: 'L1', name: 'L1' },
      { id: 'escleroticaL2', value: 'L2', name: 'L2' },
      { id: 'escleroticaL3', value: 'L3', name: 'L3' },
      { id: 'escleroticaL4', value: 'L4', name: 'L4' },
      { id: 'escleroticaL5', value: 'L5', name: 'L5' },
      { id: 'escleroticaS1', value: 'S1', name: 'S1' },
    ];

    this.showAndHideTerms = {
      sahLesaoLitica: false,
      sahLesaoEsclerotica: false,

    };
  }

  clickAusente(){
    this.rdAusente.checked = true;
    this.showAndHideTerms.sahLesaoLitica = false;
    this.showAndHideTerms.sahLesaoEsclerotica = false;
    this.ckLesaoLitica.checked = false;
    this.ckLesaoEsclerotica.checked = false;
    this.rdLiticaMult.checked = false;
    this.rdEscleroticaMult.checked = false;
    this.checkAusente();
    this.catchIDs();
  }

  clickLesaoLitica(){
    if (this.ckLesaoLitica && this.ckLesaoLitica.checked) {
      this.showAndHideTerms.sahLesaoLitica = true;
    } else {
      this.showAndHideTerms.sahLesaoLitica = false;
    }
    this.rdAusente.checked = false;
    this.rdLiticaMult.checked = false;
    this.checkAusente();
    this.catchIDs();
  }

  clickLiticaMult(){
    this.rdAusente.checked = false;
    this.showAndHideTerms.sahLesaoLitica = false;
    this.ckLesaoLitica.checked = false;
    this.catchIDs();
  }

  clickLesaoEsclerotica(){
    if (this.ckLesaoEsclerotica && this.ckLesaoEsclerotica.checked) {
      this.showAndHideTerms.sahLesaoEsclerotica = true;
    } else {
      this.showAndHideTerms.sahLesaoEsclerotica = false;
    }
    this.rdAusente.checked = false;
    this.rdEscleroticaMult.checked = false;
    this.checkAusente();
    this.catchIDs();
  }

  clickEscleroticaMult(){
    this.rdAusente.checked = false;
    this.showAndHideTerms.sahLesaoEsclerotica = false;
    this.ckLesaoEsclerotica.checked = false;
    this.catchIDs();
  }

  clickCheckboxLesaoLitica(event) {
    let lesaoLitica= <FormArray>this.lesaoLiticaFormGroup.get('lesaoLitica') as FormArray;

    if (event.checked) {
      lesaoLitica.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iLit = lesaoLitica.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoLitica.removeAt(iLit);
    }

    this.catchIDs();
  }


  clickCheckboxLesaoEsclerotica(event) {
    let lesaoEsc= <FormArray>this.lesaoEscleroticaFormGroup.get('lesaoEsclerotica') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  iniciaFormLesaoLitica() {
    this.lesaoLiticaFormGroup = this.fb.group({
      lesaoLitica: this.fb.array([])
    });
  }

  iniciaFormLesaoEsclerotica() {
    this.lesaoEscleroticaFormGroup= this.fb.group({
      lesaoEsclerotica: this.fb.array([])
    });
  }

  checkAusente() {
    if (this.ckLesaoLitica.checked ||
        this.ckLesaoEsclerotica.checked ||
        this.rdLiticaMult.checked ||
        this.rdEscleroticaMult.checked) {
      this.rdAusente.checked = false;
    } else {
      this.rdAusente.checked = true;
    }
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.ckLesaoLitica.checked) {
      this.iniciaFormLesaoLitica();
    }
    if (!this.ckLesaoEsclerotica.checked) {
      this.iniciaFormLesaoEsclerotica();
    }

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    } else if (!this.rdAusente.checked) {
      this.emitLesaoLitica();

      // Lesão Esclerótica
      if (this.countLesoes > 0) {
        this.emitLesaoEscleroticaLesao();
      } else {
        this.emitLesaoEscleroticaSimples();
      }

    }
    this.listaCompleta.emit();
  }

  emitLesaoLitica(){
    if(this.ckLesaoLitica && this.ckLesaoLitica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesaoLitica.id);

      let posicao = this.lesaoLiticaFormGroup.value.lesaoLitica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5285, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5285, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.rdLiticaMult.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdLiticaMult.id);
    }
  }

  emitLesaoEscleroticaLesao(){
    if (this.ckLesaoEsclerotica && this.ckLesaoEsclerotica.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLesaoEsclerotica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.lesaoEscleroticaFormGroup.value.lesaoEsclerotica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5288, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5288, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }

    if (this.rdEscleroticaMult.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdEscleroticaMult.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto)
    }

  }

  emitLesaoEscleroticaSimples(){
    if (this.ckLesaoEsclerotica && this.ckLesaoEsclerotica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesaoEsclerotica.id);

      let posicao = this.lesaoEscleroticaFormGroup.value.lesaoEsclerotica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5288, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5288, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.rdEscleroticaMult.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdEscleroticaMult.id);
    }

  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }

}
