import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-mg-nodulos',
  templateUrl: './mg-nodulos.component.html',
  styleUrls: ['./mg-nodulos.component.css']
})
export class MgNodulosComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckUmNodulo') ckUmNodulo: MatCheckbox;
  @ViewChild('ckLinfoIntra') ckLinfoIntra: MatCheckbox;

  @ViewChildren('nodulosUmChildren') nodulosUmChildren: QueryList<any>;
  @ViewChildren('linfoIntroChildren') linfoIntroChildren: QueryList<any>;

  // @ViewChild('slDens') slDens: MatSelect;
  // @ViewChild('slForma') slForma: MatSelect;
  // @ViewChild('slMargens') slMargens: MatSelect;
  // @ViewChild('slLado') slLado: MatSelect;
  // @ViewChild('slLocal') slLocal: MatSelect;
  // @ViewChild('slLadoLinfo') slLadoLinfo: MatSelect;
  // @ViewChild('slLocalLinfo') slLocalLinfo: MatSelect;

  // @ViewChild('inMedidaNodulo1') inMedidaNodulo1: ElementRef;
  // @ViewChild('inMedidaNodulo2') inMedidaNodulo2: ElementRef;
  // @ViewChild('inMedidaNodulo3') inMedidaNodulo3: ElementRef;
  // @ViewChild('inMedidaLinfo1') inMedidaLinfo1: ElementRef;
  // @ViewChild('inMedidaLinfo2') inMedidaLinfo2: ElementRef;
  // @ViewChild('inMedidaLinfo3') inMedidaLinfo3: ElementRef;

  densidades: ArrayDropDown[] = [
    { value: 'isodenso', viewValue: 'Isodenso' },
    { value: 'hiperdenso', viewValue: 'Hiperdenso' },
    { value: 'hipodenso', viewValue: 'Hipodenso' },
    { value: 'com conteúdo adiposo', viewValue: 'Com conteúdo adiposo' }
  ];

  formas: ArrayDropDown[] = [
    { value: 'oval', viewValue: 'Oval' },
    { value: 'redondo', viewValue: 'Redondo' },
    { value: 'irregular', viewValue: 'Irregular' }
  ];

  margens: ArrayDropDown[] = [
    { value: 'circunscrito', viewValue: 'Circunscrito' },
    { value: 'não circunscrito', viewValue: 'Não circunscrito' },
    { value: 'microlobulado', viewValue: 'Microlobulado' },
    { value: 'obscurecido', viewValue: 'Obscurecido' },
    { value: 'indistinto', viewValue: 'Indistinto' },
    { value: 'espiculado', viewValue: 'Espiculado' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'na região retroareolar', viewValue: 'Região retroareolar' },
    { value: 'no quadrante superolateral', viewValue: 'Quadrante superolateral' },
    { value: 'no quadrante inferolateral', viewValue: 'Quadrante inferolateral' },
    { value: 'no quadrante superomedial', viewValue: 'Quadrante superomedial' },
    { value: 'no quadrante inferomedial', viewValue: 'Quadrante inferomedial' },
    { value: 'junção dos quadrantes superiores', viewValue: 'Junção dos quadrantes superiores' },
    { value: 'junção dos quadrantes inferiores', viewValue: 'Junção dos quadrantes inferiores' },
    { value: 'junção dos quadrantes laterais', viewValue: 'Junção dos quadrantes laterais' },
    { value: 'junção dos quadrantes mediais', viewValue: 'Junção dos quadrantes mediais' }
  ];

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  lesoesForm: FormGroup;
  linha = 0;
  objeto: any;
  objetofilho: any;
  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;


  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahUmNod: false,
      sahLinfo: false
    };
    this.lesoesForm = this._fb.group({
      nodulosUm: this._fb.array([this.addNoduloUmGrup()]),
      linfoIntro: this._fb.array([this.addLinfoIntroGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  addNoduloUmGrup() {
    return this._fb.group({
      densidade: [],
      forma: [],
      margens: [],
      medida: [],
      localizacao: [],
      ladoMama: []
    });
  }

  addNodulosUM() {
    this.nodulosUmArray.push(this.addNoduloUmGrup());
    this.catchIDs();
  }

  removeNodulosUm(index) {
    this.nodulosUmArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get nodulosUmArray() {
    return <FormArray>this.lesoesForm.get('nodulosUm');
  }

  addLinfoIntroGrup() {
    return this._fb.group({
      localizacaoLinfo: [],
      ladoLinfo: [],
      medidaLinfo: []
    });
  }

  addLinfoIntro() {
    this.linfoIntroArray.push(this.addNoduloUmGrup());
    this.catchIDs();
  }

  removeLinfoIntro(index) {
    this.linfoIntroArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }
  
  get linfoIntroArray() {
    return <FormArray>this.lesoesForm.get('linfoIntro');
  }

  clickrdAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.showAndHideTerms.sahUmNod = false;
    this.showAndHideTerms.sahLinfo = false;
    this.ajustaArrayDinamicoNod();
    this.ajustaArrayDinamicoLinfo();
    this.catchIDs();
  }

  clickUmNodulo() {
    if (this.showAndHideTerms.sahUmNod === false) {
      this.showAndHideTerms.sahUmNod = true;
    } else {
      this.showAndHideTerms.sahUmNod = false;
    }
    this.ajustaArrayDinamicoNod();
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickLinfo() {
    if (this.showAndHideTerms.sahLinfo === false) {
      this.showAndHideTerms.sahLinfo = true;
    } else {
      this.showAndHideTerms.sahLinfo = false;
    }
    this.ajustaArrayDinamicoLinfo();
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {

      if (this.ckUmNodulo && this.ckUmNodulo.checked) {
        //this.termoSelecionado.emit(this.ckUmNodulo.id);
        if (this.countLesoes > 0) {
          this.emmitNoduloLesao();
        } else {
          this.emmitNodulo();
        }
      } 
      
      if (this.ckLinfoIntra && this.ckLinfoIntra.checked) {
        //this.termoSelecionado.emit(this.ckLinfoIntra.id);
        if (this.countLesoes > 0) {
          this.emmitLinfonodoLesao();
        } else {
          this.emmitLinfonodo();
        }
      }

    }



    this.listaCompleta.emit();
  }

  emmitNodulo() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    this.termoSelecionado.emit(this.ckUmNodulo.id);

    this.nodulosUmChildren.forEach(obj => {


      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4055) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4052) {
          this.linha++;
        }

        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }

  emmitNoduloLesao() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    const objeto = {id: this.ckUmNodulo.id, cenario: this.countLesoes};
    this.lesoesSelecionadas.emit(objeto);

    this.nodulosUmChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {


        if (obj.nativeElement.id == 4055) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false};
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4052) {
          this.linha++;
        }

        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false};
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }

  emmitLinfonodo() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    this.termoSelecionado.emit(this.ckLinfoIntra.id);

    this.linfoIntroChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4059) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4060) {
          this.linha++;
        }

        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }  
 
  emmitLinfonodoLesao() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    const objeto = {id: this.ckLinfoIntra.id, cenario: this.countLesoes};
    this.lesoesSelecionadas.emit(objeto);

    this.linfoIntroChildren.forEach(obj => {

      if (obj.nativeElement != undefined) {

        if (obj.nativeElement.id == 4059) {
          if (posicao < 3) {
            posicao++;
          } else {
            posicao = 1;
          }
        } else {
          posicao = 0;
        }

        this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false};
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (obj.id == 4060) {
          this.linha++;
        }

        this.objetofilho = {id: obj.id, value: obj.value, cenario: this.countLesoes};
        this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false};
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }
  
  
  // formataMedindoNodulo(isPrimeiraLesao: boolean) {
  //   let medindoValor = '';
  //   //let isPrimeiraLesao = false;

  //   if (this.inMedidaNodulo1 &&
  //     this.inMedidaNodulo1.nativeElement.id &&
  //     this.inMedidaNodulo1.nativeElement.value) {

  //     medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo1.nativeElement.value);
  //   }

  //   if (this.inMedidaNodulo2 &&
  //     this.inMedidaNodulo2.nativeElement.id &&
  //     this.inMedidaNodulo2.nativeElement.value) {

  //     if (medindoValor != '') {
  //       medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
  //     } else {
  //       medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
  //     }
  //   }

  //   if (this.inMedidaNodulo3 &&
  //     this.inMedidaNodulo3.nativeElement.id &&
  //     this.inMedidaNodulo3.nativeElement.value) {

  //     if (medindoValor != '') {
  //       medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
  //     } else {
  //       medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
  //     }
  //   }

  //   if (medindoValor != '') {
  //     const objetoElemento = { id: this.inMedidaId, value: medindoValor };
  //     const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
  //     this.termosGenericos.emit(objeto);
  //   }
  // }

  // formataMedindoLinfo(isPrimeiraLesao: boolean) {
  //   let medindoValor = '';
  //   //let isPrimeiraLesao = false;

  //   if (this.inMedidaLinfo1 &&
  //     this.inMedidaLinfo1.nativeElement.id &&
  //     this.inMedidaLinfo1.nativeElement.value) {

  //     medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfo1.nativeElement.value);
  //   }

  //   if (this.inMedidaLinfo2 &&
  //     this.inMedidaLinfo2.nativeElement.id &&
  //     this.inMedidaLinfo2.nativeElement.value) {

  //     if (medindoValor != '') {
  //       medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLinfo2.nativeElement.value);
  //     } else {
  //       medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfo2.nativeElement.value);
  //     }
  //   }

  //   if (this.inMedidaLinfo3 &&
  //     this.inMedidaLinfo3.nativeElement.id &&
  //     this.inMedidaLinfo3.nativeElement.value) {

  //     if (medindoValor != '') {
  //       medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLinfo3.nativeElement.value);
  //     } else {
  //       medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfo3.nativeElement.value);
  //     }
  //   }

  //   if (medindoValor != '') {
  //     const objetoElemento = { id: this.inMedidaLinfoId, value: medindoValor };
  //     const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
  //     this.termosGenericos.emit(objeto);
  //   }
  // }

  alteraAusentePresente() {
    if ((this.ckUmNodulo && this.ckUmNodulo.checked) ||
        (this.ckLinfoIntra && this.ckLinfoIntra.checked)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }
  }

  ajustaArrayDinamicoNod() {
    
      const arrayTotal = this.nodulosUmArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.nodulosUmArray.length - 1;

        if (index !== 0) {
          this.nodulosUmArray.removeAt(index);
        }
      }
      this.nodulosUmArray.reset();
  }

  ajustaArrayDinamicoLinfo() {
  
  const arrayTot = this.linfoIntroArray.length;

  for (let i = 0; i <= arrayTot; i++) {
    const index = this.linfoIntroArray.length - 1;

    if (index !== 0) {
      this.linfoIntroArray.removeAt(index);
    }
  }
  this.linfoIntroArray.reset();
}


}
