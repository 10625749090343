import { NgModule } from '@angular/core';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatTableDataSource,
  MatDialogModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ProstataComponent } from './prostata.component';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { ProstataOrgaoComponent } from './TermosComponents/prostata-orgao/prostata-orgao.component';
import { VesiculasSeminaisComponent } from './TermosComponents/vesiculas-seminais/vesiculas-seminais.component';
import { VolumeMiccionalComponent } from './TermosComponents/volume-miccional/volume-miccional.component';
import { ResiduoMiccionalComponent } from './TermosComponents/residuo-miccional/residuo-miccional.component';
import {BexigaComponent} from './TermosComponents/bexiga/bexiga.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJsComponent } from 'src/app/utils/text-editor-js/text-editor-js.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
// import {AbdomeTotalModule} from '../abdome-total/abdome-total.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    // AbdomeTotalModule
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    MatSlideToggleModule,
    TextEditorJSmodule
  ],
  exports: [
    ProstataComponent,
    ProstataOrgaoComponent,
    VesiculasSeminaisComponent,
    VolumeMiccionalComponent,
    ResiduoMiccionalComponent,
  ],
  declarations: [
    BexigaComponent,
    ProstataComponent,
    ProstataOrgaoComponent,
    VesiculasSeminaisComponent,
    VolumeMiccionalComponent,
    ResiduoMiccionalComponent,
  ]
})
export class ProstataModule { }
