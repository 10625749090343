import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-veias-perfurantes',
  templateUrl: './veias-perfurantes.component.html',
  styleUrls: ['./veias-perfurantes.component.css']
})
export class VeiasPerfurantesComponent implements OnInit {

  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckInsuficiente') ckInsuficiente: MatCheckbox;
  @ViewChild('ckHipoplasica') ckHipoplasica: MatCheckbox;
  @ViewChild('ckTromboflebite') ckTromboflebite: MatCheckbox;

  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLocalTrombo') slLocalTrombo: MatSelect;
  @ViewChild('slCalibre') slCalibre: MatSelect;
  @ViewChild('slFace') slFace: MatSelect;
  @ViewChild('slFaceTro') slFaceTro: MatSelect;
  @ViewChild('inDistPlantar') inDistPlantar: ElementRef;


  calibres: ArrayDropDown[] = [
    { value: 'preservado', viewValue: 'Preservado' },
    { value: 'aumentado', viewValue: 'Aumentado' },
  ];

  faces: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'lateral', viewValue: 'Lateral' },
    { value: 'medial', viewValue: 'Medial' }
  ];

  facesTro: ArrayDropDown[] = [
    { value: 'na face anterior', viewValue: 'Face anterior' },
    { value: 'na face posterior', viewValue: 'Face posterior' },
    { value: 'na face lateral', viewValue: 'Face lateral' },
    { value: 'na face medial', viewValue: 'Face medial' }
  ];

  locais: ArrayDropDown[] = [
    { value: 'do terço proximal da coxa', viewValue: 'Terço proximal da coxa' },
    { value: 'do terço médio da coxa', viewValue: 'Terço médio da coxa' },
    { value: 'do terço distal da coxa', viewValue: 'Terço distal da coxa' },
    { value: 'do terço proximal da perna', viewValue: 'Terço proximal da perna' },
    { value: 'do terço médio da perna', viewValue: 'Terço médio da perna' },
    { value: 'do terço distal da perna', viewValue: 'Terço distal da perna' }
  ];

  locaistrombo: ArrayDropDown[] = [
    { value: 'coxa', viewValue: 'Coxa' },
    { value: 'perna', viewValue: 'Perna' },
  ];

  countLesoes: number = 0;
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahInsuf: false,
      sahTrombo: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahInsuf = false;
    this.showAndHideTerms.sahTrombo = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
  }

  clickckInsuficiente() {
    this.verificaLesoes();
    if (this.showAndHideTerms.sahInsuf === false) {
      this.showAndHideTerms.sahInsuf = true;
    } else {
      this.showAndHideTerms.sahInsuf = false;
    }
    this.ckChecked();
    this.catchIDs();
  }

  clickckTromboflebite() {
    this.verificaLesoes();
    if (this.showAndHideTerms.sahTrombo === false) {
      this.showAndHideTerms.sahTrombo = true;
    } else {
      this.showAndHideTerms.sahTrombo = false;
    }
    this.ckChecked();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.ckInsuficiente && this.ckInsuficiente.checked) || 
    (this.ckHipoplasica && this.ckHipoplasica.checked) || (this.ckTromboflebite && this.ckTromboflebite.checked)){
      this.rdAlterado.checked = true;
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {
      this.termoSelecionado.emit(this.rdAlterado.id);

      if (this.ckInsuficiente && this.ckInsuficiente.checked) {
        if (this.countLesoes > 0)
          this.emitInsuficienteLesao();
        else
          this.emitInsuficiente();
      }

      if (this.ckTromboflebite && this.ckTromboflebite.checked) {
        if (this.countLesoes > 0)
          this.emitTromboflebiteLesao();
        else
          this.emitTromboflebite();
      }

    }


    this.listaCompleta.emit();
  }

  emitInsuficiente() {
    if (this.ckInsuficiente && this.ckInsuficiente.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckInsuficiente.id);

      if (this.slCalibre && this.slCalibre.value) {
        const objeto = { elemento: this.slCalibre, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slFace && this.slFace.value) {
        const objeto = { elemento: this.slFace, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPlantar && this.inDistPlantar.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inDistPlantar.nativeElement.value);
        const objetoElemento = { id: this.inDistPlantar.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitInsuficienteLesao() {
    if (this.ckInsuficiente && this.ckInsuficiente.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckInsuficiente.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slCalibre && this.slCalibre.value) {
        const obj = { elemento: this.slCalibre, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
      if (this.slFace && this.slFace.value) {
        const obj = { elemento: this.slFace, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }

      if (this.slLocalizacao && this.slLocalizacao.value) {
        const obj = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }

      if (this.inDistPlantar && this.inDistPlantar.nativeElement.value) {
        const comp = this._validacoes.formataDecimal(this.inDistPlantar.nativeElement.value);
        const objetoElemento = { id: this.inDistPlantar.nativeElement.id, value: comp, cenario: this.countLesoes };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }


  emitTromboflebite() {
    if (this.ckTromboflebite && this.ckTromboflebite.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTromboflebite.id);

      if (this.slFaceTro && this.slFaceTro.value) {
        const objeto = { elemento: this.slFaceTro, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLocalTrombo && this.slLocalTrombo.value) {
        const objeto = { elemento: this.slLocalTrombo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      
    }
  }

  emitTromboflebiteLesao() {
    if (this.ckTromboflebite && this.ckTromboflebite.checked) {
      this.countLesoes++;

      const alterado = { id: this.rdAlterado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(alterado);

      const objeto = { id: this.ckTromboflebite.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slFaceTro && this.slFaceTro.value) {
        const obj = { elemento: this.slFaceTro, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
      if (this.slLocalTrombo && this.slLocalTrombo.value) {
        const obj = { elemento: this.slLocalTrombo, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }


  ckChecked() {
    if (this.ckInsuficiente.checked === false &&
      this.ckTromboflebite.checked === false) {
      this.rdAlterado.checked = false;
      this.showAndHideTerms.sahAlterado = false;
      this.rdNormal.checked = true;
    }
  }

}
