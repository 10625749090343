export class Term {
  listTermID: Array<number|string>;
  blockID: number;
  userID: number;
  companyID: number;
  injuries: Array<Injuries>;
  }
  export  class  Injuries {
    listInjuries: Array<number|string>;
    position: number;
  }
