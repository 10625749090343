import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-risco-aneuploidias-dialog',
  templateUrl: './risco-aneuploidias-dialog.component.html',
  styleUrls: ['./risco-aneuploidias-dialog.component.css']
})
export class RiscoAneuploidiasDialogComponent {

  displayedColumns =
    ['valor', 'risco'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<RiscoAneuploidiasDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}
export interface riscoAneuploidiasElement {
  valor: string;
  risco: string;
}

const ELEMENT_DATA: riscoAneuploidiasElement[] = [
  {valor: '< 1:1000', risco: 'Baixo risco'},
  {valor: '1:100-1:1000', risco: 'Moderado Risco'},
  {valor: '> 1:100', risco: 'Alto risco'}
]