import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

declare const window: any;
@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  invokeEvent: Subject<any> = new Subject();


  constructor(public platform: Platform) {
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  private updateOnlineStatus() {
    const onLine = navigator.onLine;

    if (!onLine) {
      this.invokeEvent.next("offline");
    }
    else {
      this.invokeEvent.next("online");
    }
  }

  get isOnline() {
    return !!window.navigator.onLine;
  }

  get connectionChanged() {
    return this.invokeEvent.asObservable();
  }
}
