import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-achados-cranio',
  templateUrl: './achados-cranio.component.html',
  styleUrls: ['./achados-cranio.component.css']
})
export class AchadosCranioComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckEspessamento') ckEspessamento: MatCheckbox;
  @ViewChild('ckHidroaereo') ckHidroaereo: MatCheckbox;
  @ViewChild('ckRetencao') ckRetencao: MatCheckbox;
  @ViewChild('ckOsteoma') ckOsteoma: MatCheckbox;
  @ViewChild('ckMastoide') ckMastoide: MatCheckbox;
  @ViewChild('ckFacectomia') ckFacectomia: MatCheckbox;
  @ViewChild('ckEsclerais') ckEsclerais: MatCheckbox;
  @ViewChild('ckMiopia') ckMiopia: MatCheckbox;
  @ViewChild('ckSequelares') ckSequelares: MatCheckbox;
  

  @ViewChild('slSeio') slSeio: MatSelect;  
  @ViewChild('slSeioRetencao') slSeioRetencao: MatSelect;
  @ViewChild('slSeioOsteoma') slSeioOsteoma: MatSelect;  
  @ViewChild('slLadoOsteoma') slLadoOsteoma: MatSelect;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;
  @ViewChild('slLateralidadeEsclerais') slLateralidadeEsclerais: MatSelect;
  @ViewChild('slLateralCristalino') slLateralCristalino: MatSelect;  
  @ViewChild('slLateralidadeMiopia') slLateralidadeMiopia: MatSelect;  
  @ViewChild('slLateralSequelares') slLateralSequelares: MatSelect;


  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;

  lateralidades: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  lado: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  lateralCristalino: ArrayDropDown[] = [
    { value: 'do cristalino direito', viewValue: 'Cristalino direito' },
    { value: 'do cristalino esquerdo', viewValue: 'Cristalino esquerdo' },
    { value: 'dos cristalinos', viewValue: 'Dos cristalinos' }
  ];

  laterais: ArrayDropDown[] = [
    { value: 'direito', viewValue: 'Direito' },
    { value: 'esquerdo', viewValue: 'Esquerdo' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];


  seios: ArrayDropDown[] = [
    { value: 'do seio maxilar direito', viewValue: 'Seio maxilar direito' },
    { value: 'do seio maxilar esquerdo', viewValue: 'Seio maxilar esquerdo' },
    { value: 'dos seios maxilares', viewValue: 'Seios maxilares' },
    { value: 'do seio esfenoidal direito', viewValue: 'Seio esfenoidal direito' },
    { value: 'do seio esfenoidal esquerdo', viewValue: 'Seio esfenoidal esquerdo' },
    { value: 'dos seios esfenoidais', viewValue: 'Seios esfenoidais' },
    { value: 'do seio frontal direito', viewValue: 'Seio frontal direito' },
    { value: 'do seio frontal esquerdo', viewValue: 'Seio frontal esquerdo' },
    { value: 'dos seios frontais', viewValue: 'Seios frontais' },
    { value: 'das células etmoidais', viewValue: 'Células etmoidais' },
    { value: 'das células etmoidais anteriores', viewValue: 'Células etmoidais anteriores' },
    { value: 'das células etmoidais posteriores', viewValue: 'Células etmoidais posteriores' },
    { value: 'do recesso frontal direito', viewValue: 'Recesso frontal direito' },
    { value: 'do recesso frontal esquerdo', viewValue: 'Recesso frontal esquerdo' },
    { value: 'dos recessos frontais', viewValue: 'Recessos frontais' }
  ];

  seiosRet: ArrayDropDown[] = [
    { value: 'seio maxilar direito', viewValue: 'Seio maxilar direito' },
    { value: 'seio maxilar esquerdo', viewValue: 'Seio maxilar esquerdo' },
    { value: 'seios maxilares', viewValue: 'Seios maxilares' },
    { value: 'seio esfenoidal direito', viewValue: 'Seio esfenoidal direito' },
    { value: 'seio esfenoidal esquerdo', viewValue: 'Seio esfenoidal esquerdo' },
    { value: 'seios esfenoidais', viewValue: 'Seios esfenoidais' },
    { value: 'seio frontal direito', viewValue: 'Seio frontal direito' },
    { value: 'seio frontal esquerdo', viewValue: 'Seio frontal esquerdo' },
    { value: 'seios frontais', viewValue: 'Seios frontais' },
    { value: 'células etmoidais', viewValue: 'Células etmoidais' },
    { value: 'células etmoidais anteriores', viewValue: 'Células etmoidais anteriores' },
    { value: 'células etmoidais posteriores', viewValue: 'Células etmoidais posteriores' }
  ];

  seiosOst: ArrayDropDown[] = [
    { value: 'do seio maxilar', viewValue: 'Seio maxilar' },
    { value: 'do seio esfenoidal', viewValue: 'Seio esfenoidal' },
    { value: 'do seio frontal', viewValue: 'Seio frontal' },
    { value: 'das células etmoidais', viewValue: 'Células etmoidais' },
    { value: 'em célula etmoidal anterior', viewValue: 'Célula etmoidal anterior' },
    { value: 'em célula etmoidal posterior', viewValue: 'Célula etmoidal posterior' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahEspessamento: false,
      sahMastoide: false,
      sahRetencao: false,
      sahOsteoma: false,
      sahFacectomia: false,
      sahEsclerais: false,
      sahMiopia: false,
      sahSequelares: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickEspessamento() {
    if (this.showAndHideTerms.sahEspessamento === false) {
      this.showAndHideTerms.sahEspessamento = true;
    this.mudaAlterado();
    } else {
      this.showAndHideTerms.sahEspessamento = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickHidroaereo() {
    this.catchIDs();
  }

  clickRetencao() {
    if (this.showAndHideTerms.sahRetencao === false) {
      this.showAndHideTerms.sahRetencao = true;
    this.mudaAlterado();
    } else {
      this.showAndHideTerms.sahRetencao = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickOsteoma() {
    if (this.showAndHideTerms.sahOsteoma === false) {
      this.showAndHideTerms.sahOsteoma = true;
    this.mudaAlterado();
    } else {
      this.showAndHideTerms.sahOsteoma = false;
    }
    this.mudaAlterado();    
    this.catchIDs();
  }

  clickMastoide() {
    if (this.showAndHideTerms.sahMastoide === false) {
      this.showAndHideTerms.sahMastoide = true;
    } else {
      this.showAndHideTerms.sahMastoide = false;
    }    
    this.mudaAlterado();
    this.catchIDs();
  }

  clickFacectomia() {
    if (this.showAndHideTerms.sahFacectomia === false) {
      this.showAndHideTerms.sahFacectomia = true;
    } else {
      this.showAndHideTerms.sahFacectomia = false;
    }    
    this.mudaAlterado();
    this.catchIDs();
  }

  clickEsclerais() {
    if (this.showAndHideTerms.sahEsclerais === false) {
      this.showAndHideTerms.sahEsclerais = true;
    } else {
      this.showAndHideTerms.sahEsclerais = false;
    }    
    this.mudaAlterado();
    this.catchIDs();
  }
  
  clickMiopia() {
    if (this.showAndHideTerms.sahMiopia === false) {
      this.showAndHideTerms.sahMiopia = true;
    } else {
      this.showAndHideTerms.sahMiopia = false;
    }    
    this.mudaAlterado();
    this.catchIDs();
  }

  clickSequelares() {
    if (this.showAndHideTerms.sahSequelares === false) {
      this.showAndHideTerms.sahSequelares = true;
    } else {
      this.showAndHideTerms.sahSequelares = false;
    }    
    this.mudaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckEspessamento && this.ckEspessamento.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckEspessamento.id);

        if(this.slSeio && this.slSeio.value) {
        const objeto = { elemento: this.slSeio, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
        }

        if (this.ckHidroaereo && this.ckHidroaereo.checked) {
          this.termoSelecionado.emit(this.ckHidroaereo.id);
        }

      }

      if (this.countLesoes > 0) {
        this.emitRetencaoLesao();
      } else {
        this.emitRetencao();
      }

      
      if (this.countLesoes > 0) {
        this.emitOsteomaLesao();
      } else {
        this.emitOsteoma();
      }

      if (this.countLesoes > 0) {
        this.emitMastoideLesao();
      } else {
        this.emitMastoide();
      }

      if (this.countLesoes > 0) {
        this.emitFacectomiaLesao();
      } else {
        this.emitFacectomia();
      }

      if (this.countLesoes > 0) {
        this.emitEscleraisLesao();
      } else {
        this.emitEsclerais();
      }

      
      if (this.countLesoes > 0) {
        this.emitMiopiaLesao();
      } else {
        this.emitMiopia();
      }

      
      if (this.countLesoes > 0) {
        this.emitSequelaresLesao();
      } else {
        this.emitSequelares();
      }

    }

    this.listaCompleta.emit();
  }

  emitRetencao() {
    if (this.ckRetencao && this.ckRetencao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRetencao.id);

      if (this.slSeioRetencao && this.slSeioRetencao.value) {
        const objeto = { elemento: this.slSeioRetencao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitRetencaoLesao() {
    if (this.ckRetencao && this.ckRetencao.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckRetencao.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slSeioRetencao && this.slSeioRetencao.value) {
        const objeto = { elemento: this.slSeioRetencao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitOsteoma() {
    if (this.ckOsteoma && this.ckOsteoma.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOsteoma.id);

      if (this.slSeioOsteoma && this.slSeioOsteoma.value) {
        const objeto = { elemento: this.slSeioOsteoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoOsteoma && this.slLadoOsteoma.value) {
        const objeto = { elemento: this.slLadoOsteoma, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindo(true);
    }
  }
  emitOsteomaLesao() {
    if (this.ckOsteoma && this.ckOsteoma.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckOsteoma.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slSeioOsteoma && this.slSeioOsteoma.value) {
        const objeto = { elemento: this.slSeioOsteoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoOsteoma && this.slLadoOsteoma.value) {
        const objeto = { elemento: this.slLadoOsteoma, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindo(false);
    }
  }

  emitMastoide() {
    if (this.ckMastoide && this.ckMastoide.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMastoide.id);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }
  emitMastoideLesao() {
    if (this.ckMastoide && this.ckMastoide.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckMastoide.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFacectomia() {
    if (this.ckFacectomia && this.ckFacectomia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFacectomia.id);

      if (this.slLateralCristalino && this.slLateralCristalino.value) {
        const objeto = { elemento: this.slLateralCristalino, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitFacectomiaLesao() {
    if (this.ckFacectomia && this.ckFacectomia.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckFacectomia.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralCristalino && this.slLateralCristalino.value) {
        const objeto = { elemento: this.slLateralCristalino, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitEsclerais() {
    if (this.ckEsclerais && this.ckEsclerais.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEsclerais.id);

      if (this.slLateralidadeEsclerais && this.slLateralidadeEsclerais.value) {
        const objeto = { elemento: this.slLateralidadeEsclerais, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitEscleraisLesao() {
    if (this.ckEsclerais && this.ckEsclerais.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckEsclerais.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidadeEsclerais && this.slLateralidadeEsclerais.value) {
        const objeto = { elemento: this.slLateralidadeEsclerais, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitMiopia() {
    if (this.ckMiopia && this.ckMiopia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMiopia.id);

      if (this.slLateralidadeMiopia && this.slLateralidadeMiopia.value) {
        const objeto = { elemento: this.slLateralidadeMiopia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitMiopiaLesao() {
    if (this.ckMiopia && this.ckMiopia.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckMiopia.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidadeMiopia && this.slLateralidadeMiopia.value) {
        const objeto = { elemento: this.slLateralidadeMiopia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitSequelares() {
    if (this.ckSequelares && this.ckSequelares.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSequelares.id);

      if (this.slLateralSequelares && this.slLateralSequelares.value) {
        const objeto = { elemento: this.slLateralSequelares, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitSequelaresLesao() {
    if (this.ckSequelares && this.ckSequelares.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckSequelares.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralSequelares && this.slLateralSequelares.value) {
        const objeto = { elemento: this.slLateralSequelares, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  formataMedindo(isPrimeiraLesao) {
    let medidaFrase = '';
    let med1 = 0;
    let med2 = 0;
    let med3 = 0;

    if (this.inMedida1 !== undefined &&
      this.inMedida1.nativeElement.id !== undefined &&
      this.inMedida1.nativeElement.value !== '') {

      med1 = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);

      if (med1) {
        medidaFrase = med1.toString();
      }
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {
       
      med2 = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);

      if (medidaFrase != '') {
        medidaFrase = medidaFrase + ' x ' + med2;
      } else {
        medidaFrase = med2.toString();
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      med3 = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);

      if (medidaFrase != '') {
        medidaFrase = medidaFrase + ' x ' + med3;
      } else {
        medidaFrase = med3.toString();
      }
    }

    const objetoElemento = { id: this.inMedida1.nativeElement.id, value: medidaFrase, cenario: 0 };
    const objeto = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
    this.termosGenericos.emit(objeto);
  }



  mudaAlterado() {
    if ((this.ckEspessamento && this.ckEspessamento.checked) ||
      (this.ckMastoide && this.ckMastoide.checked) ||
      (this.ckRetencao && this.ckRetencao.checked) ||
      (this.ckOsteoma && this.ckOsteoma.checked) ||
      (this.ckFacectomia && this.ckFacectomia.checked) ||
      (this.ckEsclerais && this.ckEsclerais.checked) ||
      (this.ckMiopia && this.ckMiopia.checked) ||
      (this.ckSequelares && this.ckSequelares.checked)
    ) {
      this.rdAlterado.checked = true;
    } else {
      this.rdNormal.checked = true;
    }
  }

}
