import { Directive, HostListener } from '@angular/core';
import { ConnectionService } from '../services/connection.service';
import { MedicalReportLogService } from '../services/MedicalReportLog.service';
import { PouchDBService } from '../services/pouchdb.service';
import { CopyPaste } from '../utils/textEditor/CopyPaste';

@Directive({
  selector: '[auditFocus]'
})
export class AuditFocusDirective {

  @HostListener('focus', ['$event']) auditFocus(event: any) {
    // Aqui a gente pode pegar as informacoes do elemento que disparou o evento
    const id = event.target.id;
    let value = '';
    const nomeUsuario = localStorage.getItem('nomeUsuario');
    const tipoImagem = localStorage.getItem('TipoImagem');
    const usuario = +localStorage.getItem('userID');

    if(event.target.value != undefined)
      value = event.target.value;
    else
      value = event.target.innerText;

    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(position => {
        //TODO: enviar lat/long para a API quando estiver prota
        // console.log(position.coords);
      });
    }

    const obj = {
      UserID: usuario,
      MedicalReportTypeID: +id, //this.medicalReportTypeID,
      ContentReport: value,
      MedicalReportLogTypeID: 2
    };
    
    if (obj)
      this._pouch.saveLaudo(obj, "LogMedicalReport");

  }
  constructor(private _serviceMediocalReportLog: MedicalReportLogService,
    private _connection: ConnectionService,
    private _pouch: PouchDBService) { }

}
