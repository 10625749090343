import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-testiculo-direito',
  templateUrl: './testiculo-direito.component.html',
  styleUrls: ['./testiculo-direito.component.css']
})
export class TesticuloDireitoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  // Normal e aumentado
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAumentada') rdAumentada: MatRadioButton;

  // Medidas
  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inVolume') inVolume: ElementRef;

  // Torção
  @ViewChild('rdTorcao') rdTorcao: MatRadioButton;
  @ViewChild('slContornoTorcao') slContornoTorcao: MatSelect;
  @ViewChild('slDimensaoTorcao') slDimensaoTorcao: MatSelect;
  @ViewChild('slEcotexturaTorcao') slEcotexturaTorcao: MatSelect;
  @ViewChild('slDopplerTorcao') slDopplerTorcao: MatSelect;
  @ViewChild('ckFuniculo') ckFuniculo: MatCheckbox;
  @ViewChild('inMedidaFuniculo') inMedidaFuniculo: ElementRef;

  // Orquite
  @ViewChild('rdOrquite') rdOrquite: MatRadioButton;
  @ViewChild('slContornoOrquite') slContornoOrquite: MatSelect;
  @ViewChild('slDimensaoOrquite') slDimensaoOrquite: MatSelect;
  @ViewChild('slEcotexturaOrquite') slEcotexturaOrquite: MatSelect;

  // Nódulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('slEcogenicidadeNodulo') slEcogenicidadeNodulo: MatSelect;
  @ViewChild('slEcotexturaNodulo') slEcotexturaNodulo: MatSelect;
  @ViewChild('slCalcificacaoNodulo') slCalcificacaoNodulo: MatSelect;
  @ViewChild('slContornoNodulo') slContornoNodulo: MatSelect;
  @ViewChild('slLocalizacaoNodulo') slLocalizacaoNodulo: MatSelect;
  @ViewChild('slDopplerNodulo') slDopplerNodulo: MatSelect;
  @ViewChild('inMedidaNodulo1') inMedidaNodulo1: ElementRef;
  @ViewChild('inMedidaNodulo2') inMedidaNodulo2: ElementRef;
  @ViewChild('inMedidaNodulo3') inMedidaNodulo3: ElementRef;

  // Microlitiase
  @ViewChild('ckMicrolitiase') ckMicrolitiase: MatCheckbox;

  // Varicocele
  @ViewChild('ckVaricocele') ckVaricocele: MatCheckbox;

  // Ectasia
  @ViewChild('ckEctasia') ckEctasia: MatCheckbox;

  // Coleção
  @ViewChild('ckColecao') ckColecao: MatCheckbox;
  @ViewChild('slLocalizacaoColecao') slLocalizacaoColecao: MatSelect;
  @ViewChild('ckColecaoOrganizada') ckColecaoOrganizada: MatCheckbox;
  @ViewChild('inMedidaColecao1') inMedidaColecao1: ElementRef;
  @ViewChild('inMedidaColecao2') inMedidaColecao2: ElementRef;
  @ViewChild('inMedidaColecao3') inMedidaColecao3: ElementRef;
  @ViewChild('inVolumeColecao') inVolumeColecao: ElementRef;

  contornos: ArrGenerico[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'irregulares', viewValue: 'Irregulares' }
  ];

  contornosNodulo: ArrGenerico[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'irregulares', viewValue: 'Irregulares' },
    { value: 'parcialmente caracterizados', viewValue: 'Parcialmente caracterizados' }
  ];

  dimensoes: ArrGenerico[] = [
    { value: 'preservadas', viewValue: 'Preservadas' },
    { value: 'aumentadas', viewValue: 'Aumentadas' }
  ];

  ecotexturas: ArrGenerico[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' }
  ];

  ecotexturasMasculino: ArrGenerico[] = [
    { value: 'homogêneo', viewValue: 'Homogêneo' },
    { value: 'heterogêneo', viewValue: 'Heterogêneo' }
  ];

  ecotexturasOrquite: ArrGenerico[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' },
    { value: 'heterogênea com áreas hipoecogênicas de permeio', viewValue: 'Heterogênea com áreas hipoecogênicas de permeio' }
  ];

  dopplers: ArrGenerico[] = [
    { value: 'reduzido', viewValue: 'Reduzido' },
    { value: 'ausente', viewValue: 'Ausente' }
  ];

  ecogenicidades: ArrGenerico[] = [
    { value: 'hipoecogênico', viewValue: 'Hipoecogênico' },
    { value: 'isoecogênico', viewValue: 'Isoecogênico' },
    { value: 'hiperecogênico', viewValue: 'Hiperecogênico' }
  ];

  localizacoes: ArrGenerico[] = [
    { value: 'terço superior', viewValue: 'Terço superior' },
    { value: 'terço médio', viewValue: 'Terço médio' },
    { value: 'terço inferior', viewValue: 'Terço inferior' }
  ];

  comSem: ArrGenerico[] = [
    { value: 'com', viewValue: 'Com' },
    { value: 'sem', viewValue: 'Sem' }
  ];

  volume = 'Volume';
  volumeColecao = 'Volume';
  showAndHideTerms: any;
  countLesoes = 0;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true,
      sahTorcao: false,
      sahFuniculo: false,
      sahOrquite: false,
      sahNodulo: false,
      sahColecao: false,
      sahColecaoOrganizada: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahTorcao = false;
    this.showAndHideTerms.sahOrquite = false;
    this.catchIDs();
  }

  clickTorcao() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahTorcao = true;
    this.showAndHideTerms.sahOrquite = false;
    this.catchIDs();
  }

  clickFuniculo() {
    if (this.showAndHideTerms.sahFuniculo == false) {
      this.showAndHideTerms.sahFuniculo = true;
    } else {
      this.showAndHideTerms.sahFuniculo = false;
    }
    this.catchIDs();
  }

  clickOrquite() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahOrquite = true;
    this.showAndHideTerms.sahTorcao = false;
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo == false) {
      this.showAndHideTerms.sahNodulo = true;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.catchIDs();
  }

  clickColecao() {
    if (this.showAndHideTerms.sahColecao == false) {
      this.showAndHideTerms.sahColecao = true;
    } else {
      this.showAndHideTerms.sahColecao = false;
      this.showAndHideTerms.sahColecaoOrganizada = false;
    }
    this.catchIDs();
  }

  clickColecaoOrganizada() {
    if (this.showAndHideTerms.sahColecaoOrganizada == false) {
      this.showAndHideTerms.sahColecaoOrganizada = true;
    } else {
      this.showAndHideTerms.sahColecaoOrganizada = false;
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  clickDefaultLesao() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    // Normal
    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      // Nódulo
      if (this.countLesoes > 0) {
        this.emitNoduloLesao();
      } else {
        this.emitNoduloSimples();
      }

      // Microlitiase
      if (this.countLesoes > 0) {
        this.emitMicrolitiase(false);
      } else {
        this.emitMicrolitiase(true);
      }
      // Varicocele
      if (this.countLesoes > 0) {
        this.emitVaroicocele(false);
      } else {
        this.emitVaroicocele(true);
      }
      // Ectasia
      if (this.countLesoes > 0) {
        this.emitEctasia(false);
      } else {
        this.emitEctasia(true);
      }
      // Coleção
      this.emitColecaoLesao();
      this.formataMedindo();
    }

    // Aumentada
    if (this.rdAumentada.checked) {
      this.termoSelecionado.emit(this.rdAumentada.id);

      // Nódulo
      if (this.countLesoes > 0) {
        this.emitNoduloLesao();
      } else {
        this.emitNoduloSimples();
      }

      // Microlitiase
      if (this.countLesoes > 0) {
        this.emitMicrolitiase(false);
      } else {
        this.emitMicrolitiase(true);
      }
      // Varicocele
      if (this.countLesoes > 0) {
        this.emitVaroicocele(false);
      } else {
        this.emitVaroicocele(true);
      }
      // Ectasia
      if (this.countLesoes > 0) {
        this.emitEctasia(false);
      } else {
        this.emitEctasia(true);
      }
      // Coleção
      if (this.countLesoes > 0) {
        this.emitColecaoLesao();
      } else {
        this.emitColecaoSimples();
      }

      this.formataMedindo();
    }

    // Torção
    if (this.rdTorcao.checked) {
      this.termoSelecionado.emit(this.rdTorcao.id);

      if (this.slContornoTorcao !== undefined && this.slContornoTorcao.value !== undefined) {
        const objeto = { elemento: this.slContornoTorcao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDimensaoTorcao !== undefined && this.slDimensaoTorcao.value !== undefined) {
        const objeto = { elemento: this.slDimensaoTorcao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaTorcao !== undefined && this.slEcotexturaTorcao.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaTorcao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerTorcao !== undefined && this.slDopplerTorcao.value !== undefined) {
        const objeto = { elemento: this.slDopplerTorcao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckFuniculo !== undefined && this.ckFuniculo.checked) {
        this.termoSelecionado.emit(this.ckFuniculo.id);


        if (this.inMedidaFuniculo !== undefined &&
          this.inMedidaFuniculo.nativeElement.id !== undefined &&
          this.inMedidaFuniculo.nativeElement.value != '') {

          const objeto = { elemento: this.inMedidaFuniculo.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // Nódulo
      this.emitNoduloLesao();
      // Microlitiase
      this.emitMicrolitiase(false);
      // Varicocele
      this.emitVaroicocele(false);
      // Ectasia
      this.emitEctasia(false);
      // Coleção
      this.emitColecaoLesao();
      this.formataMedindo();
    }

    // Orquite
    if (this.rdOrquite.checked) {
      this.termoSelecionado.emit(this.rdOrquite.id);

      if (this.slContornoOrquite !== undefined && this.slContornoOrquite.value !== undefined) {
        const objeto = { elemento: this.slContornoOrquite, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDimensaoOrquite !== undefined && this.slDimensaoOrquite.value !== undefined) {
        const objeto = { elemento: this.slDimensaoOrquite, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaOrquite !== undefined && this.slEcotexturaOrquite.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaOrquite, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      // Coleção
      this.emitColecaoSimples();
      // Nódulo
      this.emitNoduloLesao();
      // Microlitiase
      this.emitMicrolitiase(false);
      // Varicocele
      this.emitVaroicocele(false);
      // Ectasia
      this.emitEctasia(false);
      this.formataMedindo();
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitNoduloSimples() {
    if (this.ckNodulo !== undefined && this.ckNodulo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);

      if (this.slEcogenicidadeNodulo !== undefined && this.slEcogenicidadeNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaNodulo !== undefined && this.slEcotexturaNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCalcificacaoNodulo !== undefined && this.slCalcificacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slCalcificacaoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoNodulo !== undefined && this.slContornoNodulo.value !== undefined) {
        const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoNodulo !== undefined && this.slLocalizacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerNodulo !== undefined && this.slDopplerNodulo.value !== undefined) {
        const objeto = { elemento: this.slDopplerNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo(true);
    }
  }

  emitNoduloLesao() {
    if (this.ckNodulo !== undefined && this.ckNodulo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slEcogenicidadeNodulo !== undefined && this.slEcogenicidadeNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaNodulo !== undefined && this.slEcotexturaNodulo.value !== undefined) {
        const objeto = { elemento: this.slEcotexturaNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slCalcificacaoNodulo !== undefined && this.slCalcificacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slCalcificacaoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoNodulo !== undefined && this.slContornoNodulo.value !== undefined) {
        const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoNodulo !== undefined && this.slLocalizacaoNodulo.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerNodulo !== undefined && this.slDopplerNodulo.value !== undefined) {
        const objeto = { elemento: this.slDopplerNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo(false);
    }
  }

  emitMicrolitiase(isPrimeiraLesao) {
    if (this.ckMicrolitiase !== undefined && this.ckMicrolitiase.checked) {
      this.countLesoes++;
      if (isPrimeiraLesao) {
        this.termoSelecionado.emit(this.ckMicrolitiase.id);
      } else {
        const objeto = { id: this.ckMicrolitiase.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitVaroicocele(isPrimeiraLesao) {
    if (this.ckVaricocele !== undefined && this.ckVaricocele.checked) {
      this.countLesoes++;
      if (isPrimeiraLesao) {
        this.termoSelecionado.emit(this.ckVaricocele.id);
      } else {
        const objeto = { id: this.ckVaricocele.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitEctasia(isPrimeiraLesao) {
    if (this.ckEctasia !== undefined && this.ckEctasia.checked) {
      this.countLesoes++;
      if (isPrimeiraLesao) {
        this.termoSelecionado.emit(this.ckEctasia.id);
      } else {
        const objeto = { id: this.ckEctasia.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitColecaoSimples() {
    if (this.ckColecao !== undefined && this.ckColecao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckColecao.id);

      this.formataMedindoColecao(true);

      if (this.slLocalizacaoColecao != undefined && this.slLocalizacaoColecao.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoColecao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckColecaoOrganizada !== undefined && this.ckColecaoOrganizada.checked) {
        this.termoSelecionado.emit(this.ckColecaoOrganizada.id);

        if (this.volumeColecao ||
          this.inVolumeColecao.nativeElement.value !== undefined) {

          // Volume
          let elemento;
          if (this.inVolumeColecao && this.inVolumeColecao.nativeElement.value) {
            elemento = { id: 797, value: this.inVolumeColecao.nativeElement.value, cenario: this.countLesoes };
          } else {
            elemento = { id: 797, value: this.volumeColecao, cenario: this.countLesoes };
          }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitColecaoLesao() {
    if (this.ckColecao !== undefined && this.ckColecao.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckColecao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoColecao(false);

      if (this.slLocalizacaoColecao != undefined && this.slLocalizacaoColecao.value !== undefined) {
        const objeto = { elemento: this.slLocalizacaoColecao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckColecaoOrganizada !== undefined && this.ckColecaoOrganizada.checked) {
        const objeto = { id: this.ckColecaoOrganizada.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.volumeColecao ||
          this.inVolumeColecao.nativeElement.value !== undefined) {

          // Volume
          let elemento;
          if (this.inVolumeColecao && this.inVolumeColecao.nativeElement.value) {
            elemento = { id: 797, value: this.inVolumeColecao.nativeElement.value, cenario: this.countLesoes };
          } else {
            elemento = { id: 797, value: this.volumeColecao, cenario: this.countLesoes };
          }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  formataMedindo() {
    if (this.inMedida1 && this.inMedida2 && this.inMedida3 &&
      this.inMedida1.nativeElement.value || this.inMedida2.nativeElement.value || this.inMedida3.nativeElement.value) {
      this.countLesoes++;
      let medindoValor = '';
      let resultado = 0;

      if (this.inMedida1 != undefined &&
        this.inMedida1.nativeElement.id != undefined &&
        this.inMedida1.nativeElement.value != '') {

        medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
        resultado = +this.inMedida1.nativeElement.value;
      }

      if (this.inMedida2 != undefined &&
        this.inMedida2.nativeElement.id != undefined &&
        this.inMedida2.nativeElement.value != '') {

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
          resultado = +resultado * +this.inMedida2.nativeElement.value;
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
          resultado = +this.inMedida2.nativeElement.value;
        }
      }

      if (this.inMedida3 != undefined &&
        this.inMedida3.nativeElement.id != undefined &&
        this.inMedida3.nativeElement.value != '') {

        if (medindoValor != '') {
          medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
          resultado = +resultado * +this.inMedida3.nativeElement.value;
        } else {
          medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
          resultado = +this.inMedida3.nativeElement.value;
        }
      }

      if (medindoValor != '') {

        resultado = resultado * 0.523;
        this.volume = resultado.toFixed(1).toString();

        const objetoElemento = { id: 767, value: medindoValor, cenario: this.countLesoes };
        const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objetoLesao);

        // Volume
        let elemento;
        if (!this.inVolume.nativeElement.value && this.volume !== 'Volume') {
          elemento = { id: 768, value: this.volume, cenario: this.countLesoes };
        } else {
          elemento = { id: 768, value: this.inVolume.nativeElement.value, cenario: this.countLesoes };
        }
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  formataMedindoNodulo(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaNodulo1 != undefined &&
      this.inMedidaNodulo1.nativeElement.id != undefined &&
      this.inMedidaNodulo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo1.nativeElement.value);
    }

    if (this.inMedidaNodulo2 != undefined &&
      this.inMedidaNodulo2.nativeElement.id != undefined &&
      this.inMedidaNodulo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      }
    }

    if (this.inMedidaNodulo3 != undefined &&
      this.inMedidaNodulo3.nativeElement.id != undefined &&
      this.inMedidaNodulo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 789, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoColecao(isPrimeiraLesao) {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedidaColecao1 != undefined &&
      this.inMedidaColecao1.nativeElement.id != undefined &&
      this.inMedidaColecao1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaColecao1.nativeElement.value);
      resultado = this.inMedidaColecao1.nativeElement.value;

    }

    if (this.inMedidaColecao2 != undefined &&
      this.inMedidaColecao2.nativeElement.id != undefined &&
      this.inMedidaColecao2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecao2.nativeElement.value);
        resultado = resultado * this.inMedidaColecao2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecao2.nativeElement.value);
        resultado = this.inMedidaColecao2.nativeElement.value;
      }
    }

    if (this.inMedidaColecao3 != undefined &&
      this.inMedidaColecao3.nativeElement.id != undefined &&
      this.inMedidaColecao3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecao3.nativeElement.value);
        resultado = resultado * this.inMedidaColecao3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecao3.nativeElement.value);
        resultado = this.inMedidaColecao3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume coleção
      resultado = resultado * 0.523;
      this.volumeColecao = resultado.toFixed(1).toString();

      const objetoElemento = { id: 796, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }
}
