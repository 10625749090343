import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  invokeEvent: Subject<any> = new Subject(); 
  desbloqLaudo: Subject<any> = new Subject(); 
  carregaDados: Subject<any> = new BehaviorSubject([]); 
  recarregaDados: Subject<any> = new Subject(); 
  posicaoFeto: Subject<any> = new Subject();
  quantidadeFeto: Subject<any> = new Subject();

  callCommonMethod() {
    if(this.invokeEvent.observers.length>1){
      this.invokeEvent.observers.shift();
    }
    this.invokeEvent.next(true);
  }

  desbloqueiaLaudo() {
    if(this.desbloqLaudo.observers.length>1){
      this.desbloqLaudo.observers.shift();
    }
    this.desbloqLaudo.next(true);
  }

  editorJs(blocks: string): void {
    this.carregaDados.next(blocks);
  }

  atualizaEditorJs(blocks: string): void {
    this.recarregaDados.next(blocks);
  }

  setFetoGemelar(feto: any): void {
    this.posicaoFeto.next(feto);
  }

  setQuantidadeFetos(quantidade: any): void {
    this.quantidadeFeto.next(quantidade);
  }
}
