import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatButtonToggleModule,
  MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { RxColunaToracicaComponent } from './rx-coluna-toracica.component';
import { TecnicaComponent } from './TermosComponents/tecnica/tecnica.component';
import { DensidadeOsseaComponent } from './TermosComponents/densidade-ossea/densidade-ossea.component';
import { AlinhamentoComponent } from './TermosComponents/alinhamento/alinhamento.component';
import { LesoesOsseasComponent } from './TermosComponents/lesoes-osseas/lesoes-osseas.component';
import { CorposVertebraisComponent } from './TermosComponents/corpos-vertebrais/corpos-vertebrais.component';
import { ProcessoTransversoComponent } from './TermosComponents/processo-transverso/processo-transverso.component';
import { InterapofisariasComponent } from './TermosComponents/interapofisarias/interapofisarias.component';
import { EspacosDiscaisComponent } from './TermosComponents/espacos-discais/espacos-discais.component';
import { PosCirurgicoComponent } from './TermosComponents/pos-cirurgico/pos-cirurgico.component';
import { DispositivosComponent } from './TermosComponents/dispositivos/dispositivos.component';
import { ProcessosEspinhososComponent } from './TermosComponents/processos-espinhosos/processos-espinhosos.component';


@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    MatButtonToggleModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule
  ],
  exports: [
    RxColunaToracicaComponent
  ],
  declarations: [
    RxColunaToracicaComponent,
    TecnicaComponent,
    DensidadeOsseaComponent,
    AlinhamentoComponent,
    LesoesOsseasComponent,
    CorposVertebraisComponent,
    ProcessoTransversoComponent,
    InterapofisariasComponent,
    EspacosDiscaisComponent,
    PosCirurgicoComponent,
    DispositivosComponent,
    ProcessosEspinhososComponent
  ],
  entryComponents: [
  ]
})
export class RxColunaToracicaModule { }
