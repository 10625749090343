import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatDialog, MatSelect } from '@angular/material';
import { CarotidaDialogComponent } from 'src/app/utils/dialog/table/carotida-dialog/carotida-dialog.component';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-vasos',
  templateUrl: './vasos.component.html',
  styleUrls: ['./vasos.component.css']
})
export class VasosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();



  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckCarotidas') ckCarotidas: MatCheckbox;
  @ViewChild('ckVertebrais') ckVertebrais: MatCheckbox;
  @ViewChild('ckCerebral') ckCerebral: MatCheckbox;
  @ViewChild('ckTrombose') ckTrombose: MatCheckbox;
  @ViewChild('ckGranula') ckGranula: MatCheckbox;
  @ViewChild('ckDVA') ckDVA: MatCheckbox;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;
  @ViewChild('slLocaTrombo') slLocaTrombo: MatSelect;
  @ViewChild('slLateralTrombo') slLateralTrombo: MatSelect;
  @ViewChild('slLocaGranula') slLocaGanula: MatSelect;
  @ViewChild('slLateralGranula') slLateralGranula: MatSelect;
  @ViewChild('slLocaDVA') slLocaDVA: MatSelect;
  @ViewChild('slLateralDVA') slLateralDVA: MatSelect;

  Lateralidades: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'seio transverso', viewValue: 'seio transverso' },
    { value: 'seio sigmoide', viewValue: 'seio sigmoide' },
    { value: 'confluência dos seios', viewValue: 'confluência dos seios' },
    { value: 'reto', viewValue: 'reto' },
    { value: 'seio sagital superior', viewValue: 'seio sagital superior' },
  ];

  locais: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'frontal' },
    { value: 'parietal', viewValue: 'parietal' },
    { value: 'temporal', viewValue: 'temporal' },
    { value: 'occipital', viewValue: 'occipital' },
    { value: 'nucleocapsular', viewValue: 'nucleocapsular' },
  ];
  countLesoes = 0;
  showAndHideTerms: any;

  constructor() { }


  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahCerebral: false,
      sahTrombose: false,
      sahGranula: false,
      sahDVA: false,
    };
  }
  changeSelect() {
    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahCerebral = false;
    this.catchIDs();
  }

  clickCarotidas() {
    this.mudaAlterado();
    this.catchIDs();
  }

  clickVertebrais() {
    this.mudaAlterado();
    this.catchIDs();
  }
  clickCerebral() {
    if (this.ckCerebral && this.ckCerebral.checked)
      this.showAndHideTerms.sahCerebral = true;
    else
      this.showAndHideTerms.sahCerebral = false;
    this.mudaAlterado();
    this.catchIDs();
  }

  clickTrombose() {
    if (this.showAndHideTerms.sahTrombose === false) {
      this.showAndHideTerms.sahTrombose = true;
    } else {
      this.showAndHideTerms.sahTrombose = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickGranula() {
    if (this.showAndHideTerms.sahGranula === false) {
      this.showAndHideTerms.sahGranula = true;
    } else {
      this.showAndHideTerms.sahGranula = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickDVA() {
    if (this.showAndHideTerms.sahDVA === false) {
      this.showAndHideTerms.sahDVA = true;
    } else {
      this.showAndHideTerms.sahDVA = false;
    }
    this.mudaAlterado();
    this.catchIDs();
  }

  clickAlterado() {
    if (this.rdAlterado && this.rdAlterado.checked)
      this.showAndHideTerms.sahAlterado = true;
    else
      this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }



  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    if (this.rdNormal && this.rdNormal.checked)
      this.termoSelecionado.emit(this.rdNormal.id);

    if (this.rdAlterado && this.rdAlterado.checked) {
      //this.countLesoes++;
      // this.termoSelecionado.emit(this.rdAlterado.id);

      if (this.countLesoes > 0)
        this.emitCerebralLesao();
      else
        this.emitCerebral();

      if (this.countLesoes > 0)
        this.emitTromboseLesao();
      else
        this.emitTrombose();

      if (this.countLesoes > 0)
        this.emitGranulaLesao();
      else
        this.emitGranula();

      if (this.countLesoes > 0)
        this.emitDvaLesao();
      else
        this.emitDva();

      if (this.countLesoes > 0)
        this.emitCarotidasLesao();
      else
        this.emitCarotidas();

    }

    this.listaCompleta.emit();
  }


  emitCarotidas() {
    if ((this.ckCarotidas && this.ckCarotidas.checked) &&
      (this.ckVertebrais && this.ckVertebrais.checked)
    ) {
      this.countLesoes++;
      const id = '4313';
      this.termoSelecionado.emit(id);
    } else {
      if (this.ckCarotidas && this.ckCarotidas.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckCarotidas.id);
      }

      if (this.ckVertebrais && this.ckVertebrais.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckVertebrais.id);
      }
    }
  }


  emitCarotidasLesao() {

    if ((this.ckCarotidas && this.ckCarotidas.checked) &&
      (this.ckVertebrais && this.ckVertebrais.checked)
    ) {
      this.countLesoes++;
      const objeto = { id: '4313', cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    } else {
      if (this.ckVertebrais && this.ckVertebrais.checked) {
        this.countLesoes++;
        const objeto = { id: this.ckVertebrais.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
      if (this.ckCarotidas && this.ckCarotidas.checked) {
        this.countLesoes++;
        const objeto = { id: this.ckCarotidas.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }

  }

  emitCerebral() {
    if (this.ckCerebral && this.ckCerebral.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCerebral.id);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCerebralLesao() {
    if (this.ckCerebral && this.ckCerebral.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCerebral.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitTrombose() {
    if (this.ckTrombose && this.ckTrombose.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTrombose.id);

      if (this.slLocaTrombo && this.slLocaTrombo.value) {
        const objeto = { elemento: this.slLocaTrombo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralTrombo && this.slLateralTrombo.value) {
        const objeto = { elemento: this.slLateralTrombo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitTromboseLesao() {
    if (this.ckTrombose && this.ckTrombose.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckTrombose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocaTrombo && this.slLocaTrombo.value) {
        const objeto = { elemento: this.slLocaTrombo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralTrombo && this.slLateralTrombo.value) {
        const objeto = { elemento: this.slLateralTrombo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitGranula() {
    if (this.ckGranula && this.ckGranula.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckGranula.id);

      if (this.slLocaGanula && this.slLocaGanula.value) {
        const objeto = { elemento: this.slLocaGanula, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralGranula && this.slLateralGranula.value) {
        const objeto = { elemento: this.slLateralGranula, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitGranulaLesao() {
    if (this.ckGranula && this.ckGranula.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckGranula.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.slLocaGanula && this.slLocaGanula.value) {
        const objeto = { elemento: this.slLocaGanula, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralGranula && this.slLateralGranula.value) {
        const objeto = { elemento: this.slLateralGranula, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDva() {
    if (this.ckDVA && this.ckDVA.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDVA.id);

      if (this.slLocaDVA && this.slLocaDVA.value) {
        const objeto = { elemento: this.slLocaDVA, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralDVA && this.slLateralDVA.value) {
        const objeto = { elemento: this.slLateralDVA, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitDvaLesao() {
    if (this.ckDVA && this.ckDVA.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckDVA.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocaDVA && this.slLocaDVA.value) {
        const objeto = { elemento: this.slLocaDVA, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slLateralDVA && this.slLateralDVA.value) {
        const objeto = { elemento: this.slLateralDVA, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }


  mudaAlterado() {
    if ((this.ckCarotidas && this.ckCarotidas.checked) ||
      (this.ckVertebrais && this.ckVertebrais.checked) ||
      (this.ckCerebral && this.ckCerebral.checked) ||
      (this.ckTrombose && this.ckTrombose.checked) ||
      (this.ckGranula && this.ckGranula.checked) ||
      (this.ckDVA && this.ckDVA.checked)
    ) {
      this.rdAlterado.checked = true;
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
      this.rdAlterado.checked = false;
    }
  }

}
