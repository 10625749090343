import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterPipe } from 'ngx-filter-pipe';
import { Speciality } from 'src/app/models/BO/Speciality';
import { ListaPlanosFavoritosService } from 'src/app/services/lista-planos-favoritos.service';
import { ListaPlanosService } from 'src/app/services/lista-planos.service';
import { first } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
  selector: 'app-menu-rx',
  templateUrl: './menu-rx.component.html',
  styleUrls: ['./menu-rx.component.css']
})
export class MenuRxComponent implements OnInit {
  examesRadiograficos: Speciality[] = [];
  public loading: boolean = true;
  private planoData: Speciality[][];

  exameFilter: Speciality = {
    id: 0,
    description: '',
    parentMedicalReportTypeID: 0,
    specialityID: 0,
    title: '',
    image: '',
    url: '',
    visible: false,
    favorite: false
  };

  constructor(private router: Router,
    private _planoService: ListaPlanosService,
    private _favoritosService: ListaPlanosFavoritosService) { }

  async ngOnInit() {
    var planLoaded = JSON.parse(localStorage.getItem('planLoaded'));

    if (planLoaded == null || planLoaded == false) {
      const plans = await from(this._planoService.getPlanosPorUsuario()).pipe(first()).toPromise();
      localStorage.setItem('planLoaded', 'true');
      localStorage.setItem('planoData', JSON.stringify(plans));
    }

    this.planoData = JSON.parse(localStorage.getItem('planoData'));
    this.planoData.forEach(item => {
      item.forEach(exame => {
        if (exame.specialityID === 7) { // Radiografico
          this.examesRadiograficos.push(exame);
        }
      });
    });
    this.loading = false;
  }

  goTo(where: string) {
    this.router.navigate([`/laudos/${where}`]);
  }

  changeFavorito(exame: Speciality) {
    const userId = localStorage.getItem('userID');
    if (this._favoritosService.isFavorito(exame)) {
      exame.favorite = false;
      this._favoritosService.apagarFavoritoUsuario(+userId, exame)
    } else {
      exame.favorite = true;
      this._favoritosService.insereFavoritoUsuario(+userId, exame)
    }
  }

  isFavorito(exame: Speciality): boolean {
    return this._favoritosService.isFavorito(exame);
  }

}
