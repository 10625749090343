import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-translucencia-nucal-dialog',
  templateUrl: './translucencia-nucal-dialog.component.html',
  styleUrls: ['./translucencia-nucal-dialog.component.css']
})
export class TranslucenciaNucalDialogComponent {

  displayedColumns =
    ['percentil', 'mm', "mdm"];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<TranslucenciaNucalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }

}

export interface transRefElement {
  percentil: string;
  mm: string;
  mdm: string;
}

const ELEMENT_DATA: transRefElement[] = [
  {percentil: '1', mm: '0,74', mdm: '0,50'},
  {percentil: '5', mm: '0,90', mdm: '0,63'},
  {percentil: '10', mm: '1,00', mdm: '0,70'},
  {percentil: '25', mm: '1,30', mdm: '0,83'},
  {percentil: '50', mm: '1,60', mdm: '1,00'},
  {percentil: '75', mm: '1,90', mdm: '1,19'},
  {percentil: '90', mm: '2,30', mdm: '1,37'},
  {percentil: '95', mm: '2,50', mdm: '1,51'},
  {percentil: '99', mm: '3,16', mdm: '2,04'},
];
