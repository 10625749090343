import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-cavidade-glenoide',
  templateUrl: './cavidade-glenoide.component.html',
  styleUrls: ['./cavidade-glenoide.component.css']
})
export class CavidadeGlenoideComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdRasa') rdRasa: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  Lateralidade: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda'},
    { value: 'bilateral', viewValue: 'Bilateral'}
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahRasa:false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahRasa = false;
    this.catchIDs();
  }

  clickRasa() {
    this.showAndHideTerms.sahRasa = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {

    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdRasa && this.rdRasa.checked) {
      this.termoSelecionado.emit(this.rdRasa.id);

      if(this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true  };
        this.termosGenericos.emit(objeto);
      }

    }

    this.listaCompleta.emit();
  }


}

