import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-translucencia-nucal-morfo-dialog',
  templateUrl: './translucencia-nucal-morfo-dialog.component.html',
  styleUrls: ['./translucencia-nucal-morfo-dialog.component.css']
})
export class TranslucenciaNucalMorfoDialogComponent {

  displayedColumns =
    ['ccn', 'p1', "p2", "p3"];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<TranslucenciaNucalMorfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }

}

export interface transRefElement {
  ccn: string;
  p1: string;
  p2: string;
  p3: string;
}

const ELEMENT_DATA: transRefElement[] = [
  {ccn: '45', p1: '0.7', p2: '1.3', p3: '2.0'},
  {ccn: '46', p1: '0.7', p2: '1.3', p3: '2.0'},
  {ccn: '47', p1: '0.7', p2: '1.3', p3: '2.0'},
  {ccn: '48', p1: '0.7', p2: '1.4', p3: '2.0'},
  {ccn: '49', p1: '0.8', p2: '1.4', p3: '2.0'},
  {ccn: '50', p1: '0.8', p2: '1.5', p3: '2.1'},
  {ccn: '51', p1: '0.8', p2: '1.5', p3: '2.1'},
  {ccn: '52', p1: '0.9', p2: '1.5', p3: '2.1'},
  {ccn: '53', p1: '0.9', p2: '1.5', p3: '2.2'},
  {ccn: '54', p1: '0.9', p2: '1.6', p3: '2.2'},
  {ccn: '55', p1: '0.9', p2: '1.6', p3: '2.2'},
  {ccn: '56', p1: '1.0', p2: '1.6', p3: '2.3'},
  {ccn: '57', p1: '1.0', p2: '1.7', p3: '2.3'},
  {ccn: '58', p1: '1.0', p2: '1.7', p3: '2.3'},
  {ccn: '59', p1: '1.1', p2: '1.7', p3: '2.4'},
  {ccn: '60', p1: '1.1', p2: '1.7', p3: '2.4'},
  {ccn: '61', p1: '1.1', p2: '1.8', p3: '2.4'},
  {ccn: '62', p1: '1.2', p2: '1.8', p3: '2.5'},
  {ccn: '63', p1: '1.2', p2: '1.8', p3: '2.5'},
  {ccn: '64', p1: '1.2', p2: '1.9', p3: '2.5'},
  {ccn: '65', p1: '1.2', p2: '1.9', p3: '2.5'},
  {ccn: '66', p1: '1.3', p2: '1.9', p3: '2.6'},
  {ccn: '67', p1: '1.3', p2: '1.9', p3: '2.6'},
  {ccn: '68', p1: '1.3', p2: '2.0', p3: '2.6'},
  {ccn: '69', p1: '1.4', p2: '2.0', p3: '2.7'},
  {ccn: '70', p1: '1.4', p2: '2.0', p3: '2.7'},
  {ccn: '71', p1: '1.4', p2: '2.1', p3: '2.7'},
  {ccn: '72', p1: '1.4', p2: '2.1', p3: '2.7'},
  {ccn: '73', p1: '1.5', p2: '2.1', p3: '2.8'},
  {ccn: '74', p1: '1.5', p2: '2.1', p3: '2.8'},
  {ccn: '75', p1: '1.5', p2: '2.1', p3: '2.8'},
  {ccn: '76', p1: '1.6', p2: '2.2', p3: '2.9'},
  {ccn: '77', p1: '1.6', p2: '2.2', p3: '2.9'},
  {ccn: '78', p1: '1.6', p2: '2.3', p3: '2.9'},
  {ccn: '79', p1: '1.6', p2: '2.3', p3: '2.9'},
  {ccn: '80', p1: '1.7', p2: '2.4', p3: '3.0'},
  {ccn: '81', p1: '1.7', p2: '2.4', p3: '3.0'},
  {ccn: '82', p1: '1.7', p2: '2.4', p3: '3.0'},
  {ccn: '83', p1: '1.8', p2: '2.4', p3: '3.1'},
  {ccn: '84', p1: '1.8', p2: '2.4', p3: '3.1'},
];
