import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material';

@Component({
  selector: 'app-indicacao-torax-us',
  templateUrl: './indicacao-torax-us.component.html',
  styleUrls: ['./indicacao-torax-us.component.css']
})
export class IndicacaoToraxUsComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdDerramePleural') rdDerramePleural: MatRadioButton;
  @ViewChild('rdParalisiaDiaf') rdParalisiaDiaf: MatRadioButton;
  @ViewChild('rdConsoladacaoAtele') rdConsoladacaoAtele: MatRadioButton;

  constructor() { }

  ngOnInit() {
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdDerramePleural.checked) {
      this.termoSelecionado.emit(this.rdDerramePleural.id);
    } else if (this.rdParalisiaDiaf.checked) {
      this.termoSelecionado.emit(this.rdParalisiaDiaf.id);
    } else if (this.rdConsoladacaoAtele.checked) {
      this.termoSelecionado.emit(this.rdConsoladacaoAtele.id);
    } else {
      this.termoSelecionado.emit(1859);
    }

    this.listaCompleta.emit();
  }

}
