import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-liquido-amniotico-dialog',
  templateUrl: './liquido-amniotico-dialog.component.html',
  styleUrls: ['./liquido-amniotico-dialog.component.css']
})
export class LiquidoAmnioticoDialogComponent {
  displayedColumns =
  ['semanas', 'p3', 'p10', 'p50', 'p90', 'p97'];
dataSource = ELEMENT_DATA;

constructor(
  public dialogRef: MatDialogRef<LiquidoAmnioticoDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public message: string) { }

ClickNao(): void {
  this.dialogRef.close();
}
}

export interface ParamRefElement {
semanas: string;
p3: any;
p10: any;
p50: any;
p90: any;
p97: any;
}

const ELEMENT_DATA: ParamRefElement[] = [
  {semanas: '', p3: 'p 3', p10: 'p 5', p50: 'p 50', p90: 'p 95', p97: 'p 97'},
  {semanas: '16', p3:73, p10:79,p50:121,p90:185, p97:201},
  {semanas: '17', p3:77, p10:83,p50:127,p90:194, p97:211},
  {semanas: '18', p3:80, p10:87,p50:133,p90:202, p97:220},
  {semanas: '19', p3:83, p10:90,p50:137,p90:207, p97:225},
  {semanas: '20', p3:86, p10:93,p50:141,p90:212, p97:230},
  {semanas: '21', p3:88, p10:95,p50:143,p90:214, p97:233},
  {semanas: '22', p3:89, p10:97,p50:145,p90:216, p97:235},
  {semanas: '23', p3:90, p10:98,p50:146,p90:218, p97:237},
  {semanas: '24', p3:90, p10:98,p50:147,p90:219, p97:238},
  {semanas: '25', p3:89, p10:97,p50:147,p90:221, p97:240},
  {semanas: '26', p3:89, p10:97,p50:147,p90:223, p97:242},
  {semanas: '27', p3:85, p10:95,p50:146,p90:226, p97:245},
  {semanas: '28', p3:86, p10:94,p50:146,p90:228, p97:249},
  {semanas: '29', p3:84, p10:92,p50:145,p90:231, p97:254},
  {semanas: '30', p3:82, p10:90,p50:145,p90:234, p97:258},
  {semanas: '31', p3:79, p10:88,p50:144,p90:238, p97:263},
  {semanas: '32', p3:77, p10:86,p50:144,p90:242, p97:269},
  {semanas: '33', p3:74, p10:83,p50:143,p90:245, p97:274},
  {semanas: '34', p3:72, p10:81,p50:142,p90:248, p97:278},
  {semanas: '35', p3:70, p10:79,p50:140,p90:249, p97:279},
  {semanas: '36', p3:68, p10:77,p50:138,p90:249, p97:279},
  {semanas: '37', p3:66, p10:75,p50:135,p90:244, p97:275},
  {semanas: '38', p3:65, p10:73,p50:132,p90:239, p97:269},
  {semanas: '39', p3:64, p10:72,p50:127,p90:226, p97:255},
  {semanas: '40', p3:63, p10:71,p50:123,p90:214, p97:240},
  {semanas: '41', p3:63, p10:70,p50:116,p90:194, p97:216},
  {semanas: '42', p3:63, p10:69,p50:110,p90:175, p97:192}
];


