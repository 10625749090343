import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
@Component({
  selector: 'app-tendoes-compartimento-lateral',
  templateUrl: './tendoes-compartimento-lateral.component.html',
  styleUrls: ['./tendoes-compartimento-lateral.component.css']
})
export class TendoesCompartimentoLateralComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTendiFissura') rdTendiFissura: MatRadioButton;
  @ViewChild('rdTenossinovite') rdTenossinovite: MatRadioButton;
  @ViewChild('rdTendiTeno') rdTendiTeno: MatRadioButton;

  @ViewChildren('tendiTenoChildren') tendiTenoChildren: QueryList<any>;

  tendoes: ArrayDropDown[] = [
    { value: 'fibular curto', viewValue: 'Fibular curto' },
    { value: 'fibular longo', viewValue: 'Fibular longo' }
  ];

  segmentos: ArrayDropDown[] = [
    { value: 'retromaleolar', viewValue: 'Retromaleolar' },
    { value: 'inframaleolar', viewValue: 'Inframaleolar' },
    { value: 'retromaleolar e inframaleolar', viewValue: 'Retromaleolar e inframaleolar' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlteracao: false
    };
    this.lesoesForm = this._fb.group({
      tendiTeno: this._fb.array([this.addTendiTenoGrup()]),
    });
  }


  //Dinamico Fibromatose
  addTendiTenoGrup() {
    return this._fb.group({
      tendao: [],
      segmento: []
    });
  }

  addTendiTeno() {
    this.tendiTenoArray.push(this.addTendiTenoGrup());
    this.catchIDs();
  }

  removeTendiTeno(index) {
    this.tendiTenoArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get tendiTenoArray() {
    return <FormArray>this.lesoesForm.get('tendiTeno');
  }

  clickNormal() {
    this.showAndHideTerms.sahAlteracao = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickAlteracao() {
    this.showAndHideTerms.sahAlteracao = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if(this.rdTendinopatia.checked){
      this.termoSelecionado.emit(this.rdTendinopatia.value);

      this.verificaLigamento();
    }

    if(this.rdTendiFissura.checked){
      this.termoSelecionado.emit(this.rdTendiFissura.value);

      this.verificaLigamento();
    }

    if(this.rdTenossinovite.checked){
      this.termoSelecionado.emit(this.rdTenossinovite.value);

      this.verificaLigamento();
    }

    
    if(this.rdTendiTeno.checked){
      this.termoSelecionado.emit(this.rdTendiTeno.value);

      this.verificaLigamento();
    }

    this.listaCompleta.emit();
  }

  verificaLigamento() {
    this.linha = 0;
    this.tendiTenoChildren.forEach(obj => {

      if(+obj.id === 1415) this.linha++;
      
      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdTendinopatia && !this.rdTendinopatia.checked) || (this.rdTendiFissura && !this.rdTendiFissura.checked)
    || (this.rdTenossinovite && !this.rdTenossinovite.checked) || (this.rdTendiTeno && !this.rdTendiTeno.checked)) {
  
      const arrayTotal = this.tendiTenoArray.length;
  
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.tendiTenoArray.length - 1
  
        if (index != 0) {
          this.tendiTenoArray.removeAt(index);
        }
      }
      this.tendiTenoArray.reset();
    }
  }
}