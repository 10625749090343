import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-ost-anatomia-fetal',
  templateUrl: './ost-anatomia-fetal.component.html',
  styleUrls: ['./ost-anatomia-fetal.component.css']
})
export class OstAnatomiaFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('slCranio') slCranio: MatSelect;
  @ViewChild('slSistema') slSistema: MatSelect;
  @ViewChild('slTorax') slTorax: MatSelect;
  @ViewChild('slCoracao') slCoracao: MatSelect;
  @ViewChild('slPrdAbdominal') slPrdAbdominal: MatSelect;
  @ViewChild('slAbdome') slAbdome: MatSelect;
  @ViewChild('slAparelho') slAparelho: MatSelect;
  @ViewChild('slCordao') slCordao: MatSelect;

  @ViewChild('inOutrasCra') inOutrasCra: ElementRef;
  @ViewChild('inOutrasSis') inOutrasSis: ElementRef;
  @ViewChild('inOutrasTor') inOutrasTor: ElementRef;
  @ViewChild('inOutrasCor') inOutrasCor: ElementRef;
  @ViewChild('inOutrasPrd') inOutrasPrd: ElementRef;
  @ViewChild('inOutrasAbd') inOutrasAbd: ElementRef;
  @ViewChild('inOutrasApr') inOutrasApr: ElementRef;
  @ViewChild('inOutrasCrd') inOutrasCrd: ElementRef;

  showAndHideTerms: any;
  countLesoes = 0;

  cranioDefault: string;
  sistemaDefault: string;
  toraxDefault: string;
  coracaoDefault: string;
  prdDefault: string;
  abdDefault: string;
  aparelhoDefault: string;
  cordaoDefault: string;

  cranio: ArrayDropDown[] = [
    { value: 'de forma normal, contorno regular.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  sistema: ArrayDropDown[] = [
    { value: 'estruturas da linha média presentes, córtex de aspecto habitual, ventrículos cerebrais sem dilatações.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  torax: ArrayDropDown[] = [
    { value: 'pulmões homogêneos, com ecogenicidade habitual. Formação costal simétrica e proporcional.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  coracao: ArrayDropDown[] = [
    { value: 'Situs solitus, quatro câmaras cardíacas de aspecto habitual; vias de saída sem alterações; septo interventricular aparentemente íntegro.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  paredeAbdominal: ArrayDropDown[] = [
    { value: 'íntegra.', viewValue: 'Íntegra' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  abdome: ArrayDropDown[] = [
    { value: 'estômago identificado em topografia habitual.', viewValue: 'Aparentemente Normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  aparelhoUrinario: ArrayDropDown[] = [
    { value: 'rins com parênquima normal, sem dilatações das pelves renais, bexiga urinária em topografia usual e de dimensões normais.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  cordaoUmbilical: ArrayDropDown[] = [
    { value: 'identificam-se 3 vasos no cordão umbilical.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  constructor() { }

  ngOnInit() {
    this.slCranio.value = '**';
    this.slSistema.value = '**';
    this.slTorax.value = '**';
    this.slCoracao.value = '**';
    this.slPrdAbdominal.value = '**';
    this.slAbdome.value = '**';
    this.slAparelho.value = '**';
    this.slCordao.value = '**';

    this.showAndHideTerms = {
      sahAlteradoCra: false,
      sahAlteradoSis: false,
      sahAlteradoTor: false,
      sahAlteradoCor: false,
      sahAlteradoPrd: false,
      sahAlteradoAbd: false,
      sahAlteradoApr: false,
      sahAlteradoCrd: false
    }
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitCranioSimples();

    if(this.countLesoes > 0){
      this.emitSistemaLesao();
    } else {
      this.emitSistemaSimples();
    }

    if(this.countLesoes > 0){
      this.emitToraxLesao();
    } else {
      this.emitToraxSimples();
    }

    if(this.countLesoes > 0){
      this.emitCoracaoLesao();
    } else {
      this.emitCoracaoSimples();
    }

    if(this.countLesoes > 0){
      this.emitParedeLesao();
    } else {
      this.emitParedeSimples();
    }

    if(this.countLesoes > 0){
      this.emitAbdomeLesao();
    } else {
      this.emitAbdomeSimples();
    }

    if(this.countLesoes > 0){
      this.emitAparelhoLesao();
    } else {
      this.emitAparelhoSimples();
    }

    if(this.countLesoes > 0){
      this.emitCordaoLesao();
    } else {
      this.emitCordaoSimples();
    }

    this.listaCompleta.emit();
  }

  emitCranioSimples(){
    let elemento;

    if (this.slCranio && this.slCranio.value != '**') {
      this.countLesoes++;

      if (this.inOutrasCra && this.slCranio.value == 'Outras alterações: '){
        elemento = { id: this.slCranio.id, value: this.inOutrasCra.nativeElement.value };

        const cranioAlt = { elemento: { id: 5652, value: this.inOutrasCra.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(cranioAlt);
      }else{
        elemento = { id: this.slCranio.id, value: this.slCranio.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitSistemaLesao(){
    let elemento;

    if (this.slSistema && this.slSistema.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slSistema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasSis && this.slSistema.value == 'Outras alterações: '){
        elemento = { id: this.slSistema.id, value: this.inOutrasSis.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5654, value: this.inOutrasSis.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slSistema.id, value: this.slSistema.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitSistemaSimples(){
    let elemento;

    if (this.slSistema && this.slSistema.value != '**') {
      this.countLesoes++;

      if (this.inOutrasSis && this.slSistema.value == 'Outras alterações: '){
        elemento = { id: this.slSistema.id, value: this.inOutrasSis.nativeElement.value };

        const colunaAlt = { elemento: { id: 5654, value: this.inOutrasSis.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slSistema.id, value: this.slSistema.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitToraxLesao(){
    let elemento;

    if (this.slTorax && this.slTorax.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slTorax.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasTor && this.slTorax.value == 'Outras alterações: '){
        elemento = { id: this.slTorax.id, value: this.inOutrasTor.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5656, value: this.inOutrasTor.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slTorax.id, value: this.slTorax.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitToraxSimples(){
    let elemento;

    if (this.slTorax && this.slTorax.value != '**') {
      this.countLesoes++;

      if (this.inOutrasTor && this.slTorax.value == 'Outras alterações: '){
        elemento = { id: this.slTorax.id, value: this.inOutrasTor.nativeElement.value };

        const colunaAlt = { elemento: { id: 5656, value: this.inOutrasTor.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slTorax.id, value: this.slTorax.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitCoracaoLesao(){
    let elemento;

    if (this.slCoracao && this.slCoracao.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slCoracao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasCor && this.slCoracao.value == 'Outras alterações: '){
        elemento = { id: this.slCoracao.id, value: this.inOutrasCor.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5658, value: this.inOutrasCor.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slCoracao.id, value: this.slCoracao.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCoracaoSimples(){
    let elemento;

    if (this.slCoracao && this.slCoracao.value != '**') {
      this.countLesoes++;

      if (this.inOutrasCor && this.slCoracao.value == 'Outras alterações: '){
        elemento = { id: this.slCoracao.id, value: this.inOutrasCor.nativeElement.value };

        const colunaAlt = { elemento: { id: 5658, value: this.inOutrasCor.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slCoracao.id, value: this.slCoracao.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitParedeLesao(){
    let elemento;

    if (this.slPrdAbdominal && this.slPrdAbdominal.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slPrdAbdominal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasPrd && this.slPrdAbdominal.value == 'Outras alterações: '){
        elemento = { id: this.slPrdAbdominal.id, value: this.inOutrasPrd.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5660, value: this.inOutrasPrd.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slPrdAbdominal.id, value: this.slPrdAbdominal.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitParedeSimples(){
    let elemento;

    if (this.slPrdAbdominal && this.slPrdAbdominal.value != '**') {
      this.countLesoes++;

      if (this.inOutrasPrd && this.slPrdAbdominal.value == 'Outras alterações: '){
        elemento = { id: this.slPrdAbdominal.id, value: this.inOutrasPrd.nativeElement.value };

        const colunaAlt = { elemento: { id: 5660, value: this.inOutrasPrd.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slPrdAbdominal.id, value: this.slPrdAbdominal.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitAbdomeLesao(){
    let elemento;

    if (this.slAbdome && this.slAbdome.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slAbdome.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasAbd && this.slAbdome.value == 'Outras alterações: '){
        elemento = { id: this.slAbdome.id, value: this.inOutrasAbd.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5662, value: this.inOutrasAbd.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slAbdome.id, value: this.slAbdome.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAbdomeSimples(){
    let elemento;

    if (this.slAbdome && this.slAbdome.value != '**') {
      this.countLesoes++;

      if (this.inOutrasAbd && this.slAbdome.value == 'Outras alterações: '){
        elemento = { id: this.slAbdome.id, value: this.inOutrasAbd.nativeElement.value };

        const colunaAlt = { elemento: { id: 5662, value: this.inOutrasAbd.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slAbdome.id, value: this.slAbdome.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitAparelhoLesao(){
    let elemento;

    if (this.slAparelho && this.slAparelho.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slAparelho.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasApr && this.slAparelho.value == 'Outras alterações: '){
        elemento = { id: this.slAparelho.id, value: this.inOutrasApr.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5664, value: this.inOutrasApr.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slAparelho.id, value: this.slAparelho.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAparelhoSimples(){
    let elemento;

    if (this.slAparelho && this.slAparelho.value != '**') {
      this.countLesoes++;

      if (this.inOutrasApr && this.slAparelho.value == 'Outras alterações: '){
        elemento = { id: this.slAparelho.id, value: this.inOutrasApr.nativeElement.value };

        const colunaAlt = { elemento: { id: 5664, value: this.inOutrasApr.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slAparelho.id, value: this.slAparelho.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitCordaoLesao(){
    let elemento;

    if (this.slCordao && this.slCordao.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slCordao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasCrd && this.slCordao.value == 'Outras alterações: '){
        elemento = { id: this.slCordao.id, value: this.inOutrasCrd.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5666, value: this.inOutrasCrd.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slCordao.id, value: this.slCordao.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCordaoSimples(){
    let elemento;

    if (this.slCordao && this.slCordao.value != '**') {
      this.countLesoes++;

      if (this.inOutrasCrd && this.slCordao.value == 'Outras alterações: '){
        elemento = { id: this.slCordao.id, value: this.inOutrasCrd.nativeElement.value };

        const colunaAlt = { elemento: { id: 5666, value: this.inOutrasCrd.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slCordao.id, value: this.slCordao.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  verificaAlterado() {
    if (this.slCranio.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoCra = true;
    } else {
      this.showAndHideTerms.sahAlteradoCra = false;
    }

    if (this.slSistema.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoSis = true;
    } else {
      this.showAndHideTerms.sahAlteradoSis = false;
    }

    if (this.slTorax.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoTor = true;
    } else {
      this.showAndHideTerms.sahAlteradoTor = false;
    }

    if (this.slCoracao.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoCor = true;
    } else {
      this.showAndHideTerms.sahAlteradoCor = false;
    }

    if (this.slPrdAbdominal.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoPrd = true;
    } else {
      this.showAndHideTerms.sahAlteradoPrd = false;
    }

    if (this.slAbdome.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoAbd = true;
    } else {
      this.showAndHideTerms.sahAlteradoAbd = false;
    }

    if (this.slAparelho.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoApr = true;
    } else {
      this.showAndHideTerms.sahAlteradoApr = false;
    }

    if (this.slCordao.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoCrd = true;
    } else {
      this.showAndHideTerms.sahAlteradoCrd = false;
    }

  }

}
