import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tecnica',
  templateUrl: './tecnica.component.html',
  styleUrls: ['./tecnica.component.css']
})
export class TecnicaComponent implements OnInit {

  
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;
  
  @ViewChild('slIncidencias') slIncidencias: MatSelect;

  incidencias: ArrayDropDown[] = [
    { value: 'AP', viewValue: 'AP' },
    { value: 'perfil', viewValue: 'Perfil' },
    { value: 'ossos nasais', viewValue: 'Ossos Nasais' },
    { value: 'waters', viewValue: 'Waters' },
    { value: 'caldwell', viewValue: 'Caldwell' },
    { value: 'hirtz', viewValue: 'Hirtz' }
  ];
  
  showAndHideTerms: any;
  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: true
    };
  }
  clickrdNaoCitar() {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();
  }

  clickCitar() {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraCitar();
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if(this.rdCitar && this.rdCitar.checked) {
      this.termoSelecionado.emit(this.rdCitar.id);  
      
      if(this.slIncidencias && this.slIncidencias.value) {
        const objeto = { elemento: this.slIncidencias, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
    if(this.rdNaoCitar && this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    this.listaCompleta.emit();
  }

  alteraCitar() {
    if ((this.slIncidencias && this.slIncidencias.selected)        
    ) {
      this.rdCitar.checked = true;
    } else {
      this.rdNaoCitar.checked = true;
      this.rdCitar.checked = false;
    }
  }

}
