import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ventres-musculares',
  templateUrl: './ventres-musculares.component.html',
  styleUrls: ['./ventres-musculares.component.css']
})
export class VentresMuscularesComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckHipotrofia') ckHipotrofia: MatRadioButton;
  @ViewChild('slMusculaturaHipotrofia') slMusculaturaHipotrofia: MatSelect;

  @ViewChild('ckLipossub') ckLipossub: MatRadioButton;
  @ViewChild('slMusculaturaLipossub') slMusculaturaLipossub: MatSelect;

  @ViewChild('ckHipoLipo') ckHipoLipo: MatRadioButton;
  @ViewChild('slMusculaturaHipoLipo') slMusculaturaHipoLipo: MatSelect;


  musculaturas: ArrGenerico[] = [
    { value: 'supraespinal', viewValue: 'Supraespinal' },
    { value: 'infraespinal', viewValue: 'Infraespinal' },
    { value: 'redondo menor', viewValue: 'Redondo menor' },
    { value: 'subescapular', viewValue: 'Subescapular' }
  ];

  showAndHideTerms: any;
  countLesoes: number = 0;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: false,
      sahHipotrofia: false,
      sahLipossub: false,
      sahHipoLipo: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahHipotrofia = false;
    this.showAndHideTerms.sahHipoLipo = false;
    this.showAndHideTerms.sahLipossub = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    //this.catchIDs();
  }
  clickHipotrofia() {
    if(this.ckHipotrofia && this.ckHipotrofia.checked)
      this.showAndHideTerms.sahHipotrofia = true;
    else  
      this.showAndHideTerms.sahHipotrofia = false;

    this.catchIDs();
  }
  clickHipoLipo() {
    if(this.ckHipoLipo && this.ckHipoLipo.checked)
      this.showAndHideTerms.sahHipoLipo = true;
    else  
      this.showAndHideTerms.sahHipoLipo = false;
  
    this.catchIDs();
  }
  clickLipossub() {
    if(this.ckLipossub && this.ckLipossub.checked)
      this.showAndHideTerms.sahLipossub = true;
    else  
      this.showAndHideTerms.sahLipossub = false;
  
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }
    else {

      if (this.countLesoes > 0) {
        this.emitHipotrofiaLesao();
      } else {
        this.emitHipotrofiaSimples();
      }
      if (this.countLesoes > 0) {
        this.emitLipossubLesao();
      } else {
        this.emitLipossubSimples();
      }
      if (this.countLesoes > 0) {
        this.emitHipoLipoLesao();
      } else {
        this.emitHipoLipoSimples();
      }
    }

    this.listaCompleta.emit();
  }

  emitHipotrofiaSimples() {
    if (this.ckHipotrofia && this.ckHipotrofia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHipotrofia.value);

      if (this.slMusculaturaHipotrofia && this.slMusculaturaHipotrofia.value) {
        const objeto = { elemento: this.slMusculaturaHipotrofia, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    
    }
  }

  emitHipotrofiaLesao() {
    if (this.ckHipotrofia && this.ckHipotrofia.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHipotrofia.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slMusculaturaHipotrofia && this.slMusculaturaHipotrofia.value) {
        const objeto = { elemento: this.slMusculaturaHipotrofia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitLipossubSimples() {
    if (this.ckLipossub && this.ckLipossub.checked) {
      if (this.slMusculaturaLipossub && this.slMusculaturaLipossub.value) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckLipossub.value);

        const objeto = { elemento: this.slMusculaturaLipossub, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    
    }
  }

  emitLipossubLesao() {
    if (this.ckLipossub && this.ckLipossub.checked) {
      if (this.slMusculaturaLipossub && this.slMusculaturaLipossub.value) {
        this.countLesoes++;
        const objeto = { id: this.ckLipossub.value, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        const objetosl = { elemento: this.slMusculaturaLipossub, isPrimeiraLesao: false };
        this.termosGenericos.emit(objetosl);
      }
    }
  }
  emitHipoLipoSimples() {
    if (this.ckHipoLipo && this.ckHipoLipo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHipoLipo.value);

      if (this.slMusculaturaHipoLipo && this.slMusculaturaHipoLipo.value) {
        const objeto = { elemento: this.slMusculaturaHipoLipo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHipoLipoLesao() {
    if (this.ckHipoLipo && this.ckHipoLipo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckHipoLipo.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      
      if (this.slMusculaturaHipoLipo && this.slMusculaturaHipoLipo.value) {
        const objeto = { elemento: this.slMusculaturaHipoLipo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

}
