import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {TextEditorComponent} from './text-editor.component';
import {FormsModule} from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
@NgModule({
  imports: [
    CommonModule,
    AngularEditorModule,
    FormsModule
  ],
  providers: [CommonService],
  declarations: [TextEditorComponent],
  exports: [TextEditorComponent]
})
export class TextEditorModule { }
