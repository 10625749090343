import { Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-colaterais',
  templateUrl: './colaterais.component.html',
  styleUrls: ['./colaterais.component.css']
})
export class ColateraisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  localizacoes: ArrGenerico[] = [
    {value: 'perigástrica', viewValue: 'Perigástrica'},
    {value: 'periesplênica', viewValue: 'Periesplênica'},
    {value: 'periportal', viewValue: 'Periportal'},
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true
    };
  }

  clickAusente($event) {
    this.showAndHideTerms.sahPresente = false;

    this.catchIDs();
  }

  clickPresente($event) {
    this.showAndHideTerms.sahPresente = true;

    this.catchIDs();
  }

  changeLocalizacao($event) {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
        const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if (
      (this.slLocalizacao && this.slLocalizacao.value)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }
  }
}