import { Component, HostListener, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { Injuries, Term } from '../../../../models/BO/Term';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { TermResult } from '../../../../models/BO/TermResult';
import { Validacoes } from '../../../../utils/validacoes';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { TermosGenericos } from '../../modo-b/abdome-total/abdome-total.component';
import { BiradsNodulo, ListaBirads } from '../../modo-b/mama/mama.component';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';

@Component({
  selector: 'app-mama-doppler',
  templateUrl: './mama-doppler.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./mama-doppler.component.css']
})
export class MamaDopplerComponent implements OnInit {

  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  @HostListener('window:keyup', ['$event']) keyEvent(e: KeyboardEvent) {
    // console.log(e);
    // console.log(e.shiftKey);
    let arrayAtualizado;

    // console.log(e.keyCode);
    if (e.shiftKey) {
      switch (e.keyCode) {
        case 49:
          arrayAtualizado = this._selected.Selected('indicacao', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'INDICAÇÃO';

          this.indicacao = true;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = false;
          this.cirurgias = false;
          break;
        case 50:
          arrayAtualizado = this._selected.Selected('cisto', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'CISTO';

          this.indicacao = false;
          this.cisto = true;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = false;
          this.cirurgias = false;
          break;
        case 51:
          arrayAtualizado = this._selected.Selected('nodulo', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'NÓDULO';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = true;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = false;
          this.cirurgias = false;
          break;
        case 52:
          arrayAtualizado = this._selected.Selected('ectasia-ductal', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'ECTASIA DUCTAL';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = true;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = false;
          this.cirurgias = false;
          break;
        case 53:
          arrayAtualizado = this._selected.Selected('distorcao-arquitetural', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'DISTORÇÃO ARQUITETURAL';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = true;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = false;
          this.cirurgias = false;
        case 54:
          arrayAtualizado = this._selected.Selected('implante-mamario', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'IMPLANTE MAMÁRIO';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = true;
          this.linfonodomegalia = false;
          this.exameComparativo = false;
          this.cirurgias = false;
          break;
        case 55:
          arrayAtualizado = this._selected.Selected('linfonodomegalia', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'LINFONODOMEGALIA';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = true;
          this.exameComparativo = false;
          this.cirurgias = false;
          break;
        case 252:
          arrayAtualizado = this._selected.Selected('cirurgias', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'CIRURGIAS';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = true;
          this.cirurgias = false;
          break;
        case 56:
          arrayAtualizado = this._selected.Selected('exames-comparativos', this.blocos);
          this.blocos = arrayAtualizado;
          this.Subtitulo = 'EXAMES COMPARATIVOS';

          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = true;
          break;
        case 57:
          arrayAtualizado = this._selected.Selected('achados-adicionais', this.blocos);
          this.blocos = arrayAtualizado;

          this.Subtitulo = 'Preencha o campo achados adicionais'
          this.indicacao = false;
          this.cisto = false;
          this.nodulo = false;
          this.ectasiaDuctal = false;
          this.distArquitetural = false;
          this.implanteMamario = false;
          this.linfonodomegalia = false;
          this.exameComparativo = false;

          if (this.achadosTexto === false) {
            this.achadosTexto = true;
            this.htmlContent += '<br/><br/><b>Achados adicionais:</b><br/><br/>';
          }
          break;
      }
    }
  }

  listTermDefault=[
    {listTerm:[375],blockID:16},
    {listTerm:[511],blockID:12},
    {listTerm:[4653],blockID:321},
    {listTerm:[358],blockID:14},
    {listTerm:[478],blockID:13},
    {listTerm:[370],blockID:15},
    {listTerm:[381],blockID:17},
    {listTerm:[4615],blockID:25},
    {listTerm:[3982],blockID:252},
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Rastreamento.', blockID: 16, textTermID: 0, visivel: true },
    { value: '', blockID: 252, textTermID: 0, visivel: true },
    { value: 'Pele e tecido celular subcutâneo de aspecto preservado.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Tecido fibroglandular com ecotextura habitual.', blockID: 13, textTermID: 0, visivel: true },
    { value: 'Não há evidências nódulos sólidos ao método.', blockID: 321, textTermID: 0, visivel: true },
    { value: 'Ausência de lesões císticas.', blockID: 12, textTermID: 0, visivel: true },
    { value: 'Ausência de ectasias ductais.', blockID: 14, textTermID: 0, visivel: true },
    { value: '', blockID: 15, textTermID: 0, visivel: true },
    { value: 'Ausência de linfonodomegalias axilares.', blockID: 17, textTermID: 0, visivel: true },
    { value: 'Estudo Doppler sem alterações significativas.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Exames anteriores não disponíveis para análise comparativa.', blockID: 25, textTermID: 0, visivel: true },
  ];

  conclusoes: DescricaoConclusao[] = [
    {
      value: 'Estudo ultrassonográfico normal' +
        '</br>Classificação: BI-RADS 1' +
        '</br></br>A critério clínico, sugere-se controle de rotina de acordo com o indicado para a faixa etária.', blockID: 0, textTermID: 0, visivel: true
    },
    { value: '', blockID: 13, textTermID: 0, visivel: true },
    { value: '', blockID: 321, textTermID: 0, visivel: true },
    { value: '', blockID: 12, textTermID: 0, visivel: true },
    { value: '', blockID: 14, textTermID: 0, visivel: true },
    { value: '', blockID: 15, textTermID: 0, visivel: true },
    { value: '', blockID: 17, textTermID: 0, visivel: true },
    { value: '', blockID: 252, textTermID: 0, visivel: true },
    { value: '', blockID: 25, textTermID: 0, visivel: true }
  ];

  genericTermsLinfonodomegalia: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 381, termID: 383, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' },
    { value: '', selected: false, lesionID: 381, termID: 384, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedindo' }
  ];

  genericTermsDistArquitetural: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 357, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' },
    { value: '', selected: false, lesionID: 0, termID: 356, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' }
  ];

  genericTermsEctasiaDuctal: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 363, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' },
    { value: '', selected: false, lesionID: 0, termID: 362, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
    { value: '', selected: false, lesionID: 0, termID: 364, cenario: 0, posicao: 0, linha: 0, term: '[Calibre]', termView: 'inCalibre' },
    { value: '', selected: false, lesionID: 0, termID: 366, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slDoppler' },
    { value: '', selected: false, lesionID: 0, termID: 367, cenario: 0, posicao: 0, linha: 0, term: '[Medida do conteúdo]', termView: 'inMedidaConteudo' },
    { value: '', selected: false, lesionID: 0, termID: 368, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
    { value: '', selected: false, lesionID: 0, termID: 369, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' }
  ];

  genericTermsImplanteMamario: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 373, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' }
  ];

  genericTermsExamesComparativos: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 481, cenario: 0, posicao: 0, linha: 0, term: '[Data do ultimo exame]', termView: 'slLadoMana' },
    { value: '', selected: false, lesionID: 0, termID: 483, cenario: 0, posicao: 0, linha: 0, term: '[Meses]', termView: 'inMeses' },
    { value: '', selected: false, lesionID: 0, termID: 484, cenario: 0, posicao: 0, linha: 0, term: '[Meses maior]', termView: '' },
    { value: '', selected: false, lesionID: 0, termID: 486, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
    { value: '', selected: false, lesionID: 0, termID: 487, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' }
  ];

  genericTermsCirurgias: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 3984, cenario: 0, posicao: 0, linha: 0, term: '[Lado]', termView: 'slLado' },
    { value: '', selected: false, lesionID: 0, termID: 3985, cenario: 0, posicao: 0, linha: 0, term: '[Reconstrucao]', termView: 'slResconstrucao' },
    { value: '', selected: false, lesionID: 0, termID: 3989, cenario: 0, posicao: 0, linha: 0, term: '[Lado]', termView: 'slLadoBilateral' },
  ];

  genericTermsNodulo: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 4616, termID: 4618, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcoginicidade' },
    { value: '', selected: false, lesionID: 4616, termID: 4619, cenario: 0, posicao: 0, linha: 0, term: '[Forma]', termView: 'slForma' },
    { value: '', selected: false, lesionID: 4616, termID: 4620, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slMargem' },
    { value: '', selected: false, lesionID: 4616, termID: 4621, cenario: 0, posicao: 0, linha: 0, term: '[Eixo]', termView: 'slEixo' },
    { value: '', selected: false, lesionID: 4616, termID: 4622, cenario: 0, posicao: 0, linha: 0, term: '[Sombra]', termView: 'slSombra' },
    { value: '', selected: false, lesionID: 4616, termID: 4624, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
    { value: '', selected: false, lesionID: 4616, termID: 4630, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' },
    { value: '', selected: false, lesionID: 4616, termID: 4623, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedida' },
    { value: '', selected: false, lesionID: 4616, termID: 4625, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
    { value: '', selected: false, lesionID: 4616, termID: 4626, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
    { value: '', selected: false, lesionID: 4616, termID: 4627, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slFluxoDoppler' },

    { value: '', selected: false, lesionID: 4628, termID: 4631, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcoginicidade' },
    { value: '', selected: false, lesionID: 4628, termID: 4632, cenario: 0, posicao: 0, linha: 0, term: '[Forma]', termView: 'slForma' },
    { value: '', selected: false, lesionID: 4628, termID: 4633, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slMargem' },
    { value: '', selected: false, lesionID: 4628, termID: 4634, cenario: 0, posicao: 0, linha: 0, term: '[Eixo]', termView: 'slEixo' },
    { value: '', selected: false, lesionID: 4628, termID: 4635, cenario: 0, posicao: 0, linha: 0, term: '[Sombra]', termView: 'slSombra' },
    { value: '', selected: false, lesionID: 4628, termID: 4637, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
    { value: '', selected: false, lesionID: 4628, termID: 4640, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' },
    { value: '', selected: false, lesionID: 4628, termID: 4636, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedida' },
    { value: '', selected: false, lesionID: 4628, termID: 4638, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
    { value: '', selected: false, lesionID: 4628, termID: 4639, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
    { value: '', selected: false, lesionID: 4628, termID: 4641, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slFluxoDoppler' },

    { value: '', selected: false, lesionID: 4629, termID: 4642, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcoginicidade' },
    { value: '', selected: false, lesionID: 4629, termID: 4643, cenario: 0, posicao: 0, linha: 0, term: '[Forma]', termView: 'slForma' },
    { value: '', selected: false, lesionID: 4629, termID: 4644, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slMargem' },
    { value: '', selected: false, lesionID: 4629, termID: 4645, cenario: 0, posicao: 0, linha: 0, term: '[Eixo]', termView: 'slEixo' },
    { value: '', selected: false, lesionID: 4629, termID: 4646, cenario: 0, posicao: 0, linha: 0, term: '[Sombra]', termView: 'slSombra' },
    { value: '', selected: false, lesionID: 4629, termID: 4648, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
    { value: '', selected: false, lesionID: 4629, termID: 4651, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' },
    { value: '', selected: false, lesionID: 4629, termID: 4647, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedida' },
    { value: '', selected: false, lesionID: 4629, termID: 4649, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
    { value: '', selected: false, lesionID: 4629, termID: 4650, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
    { value: '', selected: false, lesionID: 4629, termID: 4652, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slFluxoDoppler' }
  ];

  genericTermsCisto: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 318, termID: 320, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaCistoSimples' },
    { value: '', selected: false, lesionID: 318, termID: 321, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: 'slLocalizacaoCistoSimples' },
    { value: '', selected: false, lesionID: 318, termID: 322, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama simples]', termView: 'slLadoMamaCistoSimples' },
    { value: '', selected: false, lesionID: 319, termID: 513, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: '' },
    { value: '', selected: false, lesionID: 319, termID: 514, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: '' },
    { value: '', selected: false, lesionID: 319, termID: 323, cenario: 0, posicao: 0, linha: 0, term: '[Maior na mama simples]', termView: '' },
    { value: '', selected: false, lesionID: 324, termID: 327, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaCistoComplicado' },
    { value: '', selected: false, lesionID: 324, termID: 328, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacaoCistoComplicado' },
    { value: '', selected: false, lesionID: 324, termID: 329, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama complicado]', termView: 'slLadoMamaCistoComplicado' },
    { value: '', selected: false, lesionID: 324, termID: 330, cenario: 0, posicao: 0, linha: 0, term: '[Conteúdo]', termView: 'slConteudoCistoComplicado' },
    { value: '', selected: false, lesionID: 324, termID: 331, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPeleCistoComplicado' },
    { value: '', selected: false, lesionID: 324, termID: 332, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapilaCistoComplicado' },
    { value: '', selected: false, lesionID: 319, termID: 515, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama simples]', termView: 'slLadoMamaCistoSimplesDinamico' },
    { value: '', selected: false, lesionID: 326, termID: 516, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: '' },
    { value: '', selected: false, lesionID: 326, termID: 517, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: '' },
    { value: '', selected: false, lesionID: 326, termID: 518, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama complicado]', termView: 'slLadoMamaCistoComplicadoDinamico' },
    { value: '', selected: false, lesionID: 326, termID: 519, cenario: 0, posicao: 0, linha: 0, term: '[Conteúdo]', termView: 'slConteudoCistoComplicadoDinamico' },
    { value: '', selected: false, lesionID: 326, termID: 520, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: '' },
    { value: '', selected: false, lesionID: 326, termID: 521, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: '' },
    { value: '', selected: false, lesionID: 326, termID: 333, cenario: 0, posicao: 0, linha: 0, term: '[Maior na mama complicado]', termView: 'slMaiorNaMama' },
    { value: '', selected: false, lesionID: 334, termID: 335, cenario: 0, posicao: 0, linha: 0, term: '[Paredes]', termView: 'slParedeCistoComplexo' },
    { value: '', selected: false, lesionID: 334, termID: 336, cenario: 0, posicao: 0, linha: 0, term: '[Septo]', termView: 'slSeptoCistoComplexo' },
    { value: '', selected: false, lesionID: 334, termID: 337, cenario: 0, posicao: 0, linha: 0, term: '[Nódulo mural]', termView: 'slnoduloMuralCistoComplexo' },
    { value: '', selected: false, lesionID: 334, termID: 338, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaCistoComplexo1' },
    { value: '', selected: false, lesionID: 334, termID: 339, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: 'slLocalizacaoCistoComplexo' },
    { value: '', selected: false, lesionID: 334, termID: 340, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama complexo]', termView: 'slLadoMamaCistoComplexo' },
    { value: '', selected: false, lesionID: 334, termID: 341, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPeleCistoComplexo' },
    { value: '', selected: false, lesionID: 334, termID: 342, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapilaCistoComplexo' },
    { value: '', selected: false, lesionID: 343, termID: 346, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaMicroAgrupados1' },
    { value: '', selected: false, lesionID: 343, termID: 347, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: 'slLocalizacaoMicroAgrupados' },
    { value: '', selected: false, lesionID: 343, termID: 348, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama micro]', termView: 'slLadoMamaMicroAgrupados' },
    { value: '', selected: false, lesionID: 343, termID: 349, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPeleMicroAgrupados' },
    { value: '', selected: false, lesionID: 343, termID: 350, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapilaMicroAgrupados' },
    { value: '', selected: false, lesionID: 343, termID: 353, cenario: 0, posicao: 0, linha: 0, term: '[Maior na mama micro]', termView: 'slMaiorNaMama' }
  ];

  listaBiradsNodulo: BiradsNodulo[] = [];

  listaBirads: ListaBirads[] = [
    { blockID: 13, value: 0 },
    { blockID: 321, value: 0 },
    { blockID: 12, value: 0 },
    { blockID: 14, value: 0 },
    { blockID: 15, value: 0 },
    { blockID: 17, value: 0 },
    { blockID: 25, value: 0 },
    { blockID: 252, value: 0 }
  ];

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosGenericos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  // ultimoBiRads = 0;
  cabecalhoTexto = '';
  medicaoNodulo = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  cabecalho = false;
  indicacao = true;
  cisto = false;
  nodulo = false;
  ectasiaDuctal = false;
  distArquitetural = false;
  implanteMamario = false;
  linfonodomegalia = false;
  exameComparativo = false;
  cirurgias = false;
  achadosTexto = false;
  rodape = false;

  medicalReportTypeID = 66;
  indicacaoID = 16;
  cistoID = 12;
  noduloID = 321;
  ectasiaDuctalID = 14;
  distArquiteturalID = 13;
  implanteMamarioID = 15;
  linfonodomegaliaID = 17;
  exameComparativoID = 25;
  cirurgiasID = 252;

  objetoGrupoLesao: any;
  cenarioCorrente = 0;
  public switchFormReport = true;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];
  unidadeMedida: string;
  editLaudoMode: boolean = false;

  constructor(public _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _copyPaste: CopyPaste,
    public _addText: AddText,
    private dataService:DataTermService,
    private _config: ConfigLaudoService) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("mamaDopplerAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Indicação'
    this.subtituloBloco = 'Preencha o campo indicação'
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.indicacaoID,
      this.cistoID,
      this.noduloID,
      this.ectasiaDuctalID,
      this.distArquiteturalID,
      this.implanteMamarioID,
      this.linfonodomegaliaID,
      this.exameComparativoID,
      this.cirurgiasID
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "mamaDopplerMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "mamaDoppler");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mama');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-mama');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("mamaDopplerAnalise").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 4642);
  };

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: this.indicacaoID,
      name: 'indicacao',
      subtitulo: 'Preencha o campo indicação',
      selected: true,
    },
    {
      titulo: 'Cirurgias',
      id: this.cirurgiasID,
      name: 'cirurgias',
      selected: false
    },
    {
      titulo: 'Cisto',
      id: this.cistoID,
      name: 'cisto',
      selected: false
    },
    {
      titulo: 'Nódulo',
      id: this.noduloID,
      name: 'nodulo',
      selected: false
    },
    {
      titulo: 'Ectasia Ductal',
      id: this.ectasiaDuctalID,
      name: 'ectasia-ductal',
      selected: false
    },
    {
      titulo: 'Distorção Arquitetural',
      id: this.distArquiteturalID,
      name: 'distorcao-arquitetural',
      selected: false
    },
    {
      titulo: 'Implante Mamário',
      id: this.implanteMamarioID,
      name: 'implante-mamario',
      selected: false
    },
    {
      titulo: 'Linfonodomegalia',
      id: this.linfonodomegaliaID,
      name: 'linfonodomegalia',
      selected: false
    },
    {
      titulo: 'Exames comparativos',
      id: this.exameComparativoID,
      name: 'exames-comparativos',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === this.indicacaoID);
    this.cisto = (itemSelecionado.id === this.cistoID);
    this.nodulo = (itemSelecionado.id === this.noduloID);
    this.ectasiaDuctal = (itemSelecionado.id === this.ectasiaDuctalID);
    this.distArquitetural = (itemSelecionado.id === this.distArquiteturalID);
    this.implanteMamario = (itemSelecionado.id === this.implanteMamarioID);
    this.linfonodomegalia = (itemSelecionado.id === this.linfonodomegaliaID);
    this.exameComparativo = (itemSelecionado.id === this.exameComparativoID);
    this.cirurgias = (itemSelecionado.id === this.cirurgiasID);
    this.achadosTexto = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');

    this.AdicionaTexto(this.newEditor);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;
    this.dinamicLesions = [];
    this.listaBiradsNodulo = [];
    this.lesionRow = 1;
    this.medicaoNodulo = '';

    this.genericTermsLinfonodomegalia = [
      { value: '', selected: false, lesionID: 381, termID: 383, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' },
      { value: '', selected: false, lesionID: 381, termID: 384, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedindo' }
    ];

    this.genericTermsDistArquitetural = [
      { value: '', selected: false, lesionID: 0, termID: 357, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' },
      { value: '', selected: false, lesionID: 0, termID: 356, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' }
    ];

    this.genericTermsEctasiaDuctal = [
      { value: '', selected: false, lesionID: 0, termID: 363, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' },
      { value: '', selected: false, lesionID: 0, termID: 362, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
      { value: '', selected: false, lesionID: 0, termID: 364, cenario: 0, posicao: 0, linha: 0, term: '[Calibre]', termView: 'inCalibre' },
      { value: '', selected: false, lesionID: 0, termID: 366, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slDoppler' },
      { value: '', selected: false, lesionID: 0, termID: 367, cenario: 0, posicao: 0, linha: 0, term: '[Medida do conteúdo]', termView: 'inMedidaConteudo' },
      { value: '', selected: false, lesionID: 0, termID: 368, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
      { value: '', selected: false, lesionID: 0, termID: 369, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
    ];

    this.genericTermsImplanteMamario = [
      { value: '', selected: false, lesionID: 0, termID: 373, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMana' }
    ];

    this.genericTermsExamesComparativos = [
      { value: '', selected: false, lesionID: 0, termID: 481, cenario: 0, posicao: 0, linha: 0, term: '[Data do ultimo exame]', termView: 'slLadoMana' },
      { value: '', selected: false, lesionID: 0, termID: 483, cenario: 0, posicao: 0, linha: 0, term: '[Meses]', termView: 'inMeses' },
      { value: '', selected: false, lesionID: 0, termID: 484, cenario: 0, posicao: 0, linha: 0, term: '[Meses maior]', termView: '' },
      { value: '', selected: false, lesionID: 0, termID: 486, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
      { value: '', selected: false, lesionID: 0, termID: 487, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' }
    ];

    this.genericTermsCirurgias = [
      { value: '', selected: false, lesionID: 0, termID: 3984, cenario: 0, posicao: 0, linha: 0, term: '[Lado]', termView: 'slLado' },
      { value: '', selected: false, lesionID: 0, termID: 3985, cenario: 0, posicao: 0, linha: 0, term: '[Reconstrucao]', termView: 'slResconstrucao' },
      { value: '', selected: false, lesionID: 0, termID: 3989, cenario: 0, posicao: 0, linha: 0, term: '[Lado]', termView: 'slLadoBilateral' },
    ];

    this.genericTermsNodulo = [
      { value: '', selected: false, lesionID: 4616, termID: 4618, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcoginicidade' },
      { value: '', selected: false, lesionID: 4616, termID: 4619, cenario: 0, posicao: 0, linha: 0, term: '[Forma]', termView: 'slForma' },
      { value: '', selected: false, lesionID: 4616, termID: 4620, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slMargem' },
      { value: '', selected: false, lesionID: 4616, termID: 4621, cenario: 0, posicao: 0, linha: 0, term: '[Eixo]', termView: 'slEixo' },
      { value: '', selected: false, lesionID: 4616, termID: 4622, cenario: 0, posicao: 0, linha: 0, term: '[Sombra]', termView: 'slSombra' },
      { value: '', selected: false, lesionID: 4616, termID: 4624, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
      { value: '', selected: false, lesionID: 4616, termID: 4630, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' },
      { value: '', selected: false, lesionID: 4616, termID: 4623, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedida' },
      { value: '', selected: false, lesionID: 4616, termID: 4625, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
      { value: '', selected: false, lesionID: 4616, termID: 4626, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
      { value: '', selected: false, lesionID: 4616, termID: 4627, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slFluxoDoppler' },

      { value: '', selected: false, lesionID: 4628, termID: 4631, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcoginicidade' },
      { value: '', selected: false, lesionID: 4628, termID: 4632, cenario: 0, posicao: 0, linha: 0, term: '[Forma]', termView: 'slForma' },
      { value: '', selected: false, lesionID: 4628, termID: 4633, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slMargem' },
      { value: '', selected: false, lesionID: 4628, termID: 4634, cenario: 0, posicao: 0, linha: 0, term: '[Eixo]', termView: 'slEixo' },
      { value: '', selected: false, lesionID: 4628, termID: 4635, cenario: 0, posicao: 0, linha: 0, term: '[Sombra]', termView: 'slSombra' },
      { value: '', selected: false, lesionID: 4628, termID: 4637, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
      { value: '', selected: false, lesionID: 4628, termID: 4640, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' },
      { value: '', selected: false, lesionID: 4628, termID: 4636, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedida' },
      { value: '', selected: false, lesionID: 4628, termID: 4638, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
      { value: '', selected: false, lesionID: 4628, termID: 4639, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
      { value: '', selected: false, lesionID: 4628, termID: 4641, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slFluxoDoppler' },

      { value: '', selected: false, lesionID: 4629, termID: 4642, cenario: 0, posicao: 0, linha: 0, term: '[Ecogenicidade]', termView: 'slEcoginicidade' },
      { value: '', selected: false, lesionID: 4629, termID: 4643, cenario: 0, posicao: 0, linha: 0, term: '[Forma]', termView: 'slForma' },
      { value: '', selected: false, lesionID: 4629, termID: 4644, cenario: 0, posicao: 0, linha: 0, term: '[Margens]', termView: 'slMargem' },
      { value: '', selected: false, lesionID: 4629, termID: 4645, cenario: 0, posicao: 0, linha: 0, term: '[Eixo]', termView: 'slEixo' },
      { value: '', selected: false, lesionID: 4629, termID: 4646, cenario: 0, posicao: 0, linha: 0, term: '[Sombra]', termView: 'slSombra' },
      { value: '', selected: false, lesionID: 4629, termID: 4648, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacao' },
      { value: '', selected: false, lesionID: 4629, termID: 4651, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama]', termView: 'slLadoMama' },
      { value: '', selected: false, lesionID: 4629, termID: 4647, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedida' },
      { value: '', selected: false, lesionID: 4629, termID: 4649, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPele' },
      { value: '', selected: false, lesionID: 4629, termID: 4650, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapila' },
      { value: '', selected: false, lesionID: 4629, termID: 4652, cenario: 0, posicao: 0, linha: 0, term: '[Doppler]', termView: 'slFluxoDoppler' }
    ];

    this.genericTermsCisto = [
      { value: '', selected: false, lesionID: 318, termID: 320, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaCistoSimples' },
      { value: '', selected: false, lesionID: 318, termID: 321, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: 'slLocalizacaoCistoSimples' },
      { value: '', selected: false, lesionID: 318, termID: 322, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama simples]', termView: 'slLadoMamaCistoSimples' },
      { value: '', selected: false, lesionID: 319, termID: 513, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: '' },
      { value: '', selected: false, lesionID: 319, termID: 514, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: '' },
      { value: '', selected: false, lesionID: 319, termID: 323, cenario: 0, posicao: 0, linha: 0, term: '[Maior na mama simples]', termView: '' },
      { value: '', selected: false, lesionID: 324, termID: 327, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaCistoComplicado' },
      { value: '', selected: false, lesionID: 324, termID: 328, cenario: 0, posicao: 0, linha: 0, term: '[Localização]', termView: 'slLocalizacaoCistoComplicado' },
      { value: '', selected: false, lesionID: 324, termID: 329, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama complicado]', termView: 'slLadoMamaCistoComplicado' },
      { value: '', selected: false, lesionID: 324, termID: 330, cenario: 0, posicao: 0, linha: 0, term: '[Conteúdo]', termView: 'slConteudoCistoComplicado' },
      { value: '', selected: false, lesionID: 324, termID: 331, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPeleCistoComplicado' },
      { value: '', selected: false, lesionID: 324, termID: 332, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapilaCistoComplicado' },
      { value: '', selected: false, lesionID: 319, termID: 515, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama simples]', termView: 'slLadoMamaCistoSimplesDinamico' },
      { value: '', selected: false, lesionID: 326, termID: 516, cenario: 0, posicao: 0, linha: 0, term: '[Medindo]', termView: '' },
      { value: '', selected: false, lesionID: 326, termID: 517, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: '' },
      { value: '', selected: false, lesionID: 326, termID: 518, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama complicado]', termView: 'slLadoMamaCistoComplicadoDinamico' },
      { value: '', selected: false, lesionID: 326, termID: 519, cenario: 0, posicao: 0, linha: 0, term: '[Conteúdo]', termView: 'slConteudoCistoComplicadoDinamico' },
      { value: '', selected: false, lesionID: 326, termID: 520, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: '' },
      { value: '', selected: false, lesionID: 326, termID: 521, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: '' },
      { value: '', selected: false, lesionID: 326, termID: 333, cenario: 0, posicao: 0, linha: 0, term: '[Maior na mama complicado]', termView: 'slMaiorNaMama' },
      { value: '', selected: false, lesionID: 334, termID: 335, cenario: 0, posicao: 0, linha: 0, term: '[Paredes]', termView: 'slParedeCistoComplexo' },
      { value: '', selected: false, lesionID: 334, termID: 336, cenario: 0, posicao: 0, linha: 0, term: '[Septo]', termView: 'slSeptoCistoComplexo' },
      { value: '', selected: false, lesionID: 334, termID: 337, cenario: 0, posicao: 0, linha: 0, term: '[Nódulo mural]', termView: 'slnoduloMuralCistoComplexo' },
      { value: '', selected: false, lesionID: 334, termID: 338, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaCistoComplexo1' },
      { value: '', selected: false, lesionID: 334, termID: 339, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: 'slLocalizacaoCistoComplexo' },
      { value: '', selected: false, lesionID: 334, termID: 340, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama complexo]', termView: 'slLadoMamaCistoComplexo' },
      { value: '', selected: false, lesionID: 334, termID: 341, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPeleCistoComplexo' },
      { value: '', selected: false, lesionID: 334, termID: 342, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapilaCistoComplexo' },
      { value: '', selected: false, lesionID: 343, termID: 346, cenario: 0, posicao: 0, linha: 0, term: '[Medida]', termView: 'inMedidaMicroAgrupados1' },
      { value: '', selected: false, lesionID: 343, termID: 347, cenario: 0, posicao: 0, linha: 0, term: '[Horas]', termView: 'slLocalizacaoMicroAgrupados' },
      { value: '', selected: false, lesionID: 343, termID: 348, cenario: 0, posicao: 0, linha: 0, term: '[Lado da mama micro]', termView: 'slLadoMamaMicroAgrupados' },
      { value: '', selected: false, lesionID: 343, termID: 349, cenario: 0, posicao: 0, linha: 0, term: '[Distância da pele]', termView: 'inDistPeleMicroAgrupados' },
      { value: '', selected: false, lesionID: 343, termID: 350, cenario: 0, posicao: 0, linha: 0, term: '[Distância da papila]', termView: 'inDistPapilaMicroAgrupados' },
      { value: '', selected: false, lesionID: 343, termID: 353, cenario: 0, posicao: 0, linha: 0, term: '[Maior na mama micro]', termView: 'slMaiorNaMama' }
    ];

  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto + '<br/><br/>';
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    debugger;
    const valueDinamic = this._validacoes.formataDecimal($event.elemento.value).split('|', 2);
    const lesoesValue = valueDinamic[0];

    if (valueDinamic[1]) {
      const objBirads = { id: $event.elemento.id, value: valueDinamic[0], isBirads4: valueDinamic[1] };
      this.listaBiradsNodulo.push(objBirads);
    }

    if ($event.elemento.id == 4636 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 4636,
          lesionID: 4628,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 4636;
            obj.lesionID = 4628;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
            obj.termView = 'inMedindo';
          }
        });
      }
    } else if ($event.elemento.id == 4647 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 4647,
          lesionID: 4629,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 4647;
            obj.lesionID = 4629;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
            obj.termView = 'inMedindo';
          }
        });
      }
    } else if ($event.elemento.id == 346 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 346,
          lesionID: 4681,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medida]',
          termView: 'inMedindo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 346;
            obj.lesionID = 4681;
            obj.cenario = $event.elemento.cenario,
              obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medida]';
            obj.termView = 'inMedindo';
          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericTermsNodulo.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      this.genericTermsCisto.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      //NODULO
      case '4631':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4631,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcoginicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4632':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4632,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Forma]',
          termView: 'slForma'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4633':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4633,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]',
          termView: 'slMargens'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4634':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4634,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Eixo]',
          termView: 'slEixo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4635':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4635,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Sombra]',
          termView: 'slSombra'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4637':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4637,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacao'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4638':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4638,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Distância da pele]',
          termView: 'inDistPele'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4639':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4639,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Distância da papila]',
          termView: 'inDistPapila'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4640':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4640,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado da mama]',
          termView: 'slLadoMama'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4641':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4641,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Doppler]',
          termView: 'slFluxoDoppler'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4642':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4642,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]',
          termView: 'slEcoginicidade'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4643':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4643,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Forma]',
          termView: 'slForma'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4644':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4644,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Margens]',
          termView: 'slMargens'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4645':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4645,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Eixo]',
          termView: 'slEixo'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4646':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4646,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Sombra]',
          termView: 'slSombra'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4648':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4648,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacao'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4649':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4649,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Distância da pele]',
          termView: 'inDistPele'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4650':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4650,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Distância da papila]',
          termView: 'inDistPapila'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4651':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4651,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado da mama]',
          termView: 'slLadoMama'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4652':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4652,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Doppler]',
          termView: 'slFluxoDoppler'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4654':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4654,
          lesionID: 4628,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4655':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4655,
          lesionID: 4629,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      //CISTO
      case '513':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 513,
          lesionID: 319,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medida]',
          termView: 'inMedidaCistoSimples'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '514':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 514,
          lesionID: 319,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacaoCistoSimples'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      // case '515':
      //   this.dinamicLesion = {
      //     value: lesoesValue,
      //     selected: true,
      //     termID: 515,
      //     lesionID: 319,
      //     linha: $event.linha,
      //     posicao: 0,
      //     cenario: $event.elemento.cenario,
      //     term: '[Lado da mama]',
      //     termView: 'slLadoMamaCistoSimples'
      //   };
      //   this.dinamicLesions.push(this.dinamicLesion);
      //   break;
      case '323':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 323,
          lesionID: 319,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Maior na mama simples]',
          termView: 'slMaiorMamaCistoSimples'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '516':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 516,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Medindo]',
          termView: 'inMedidaCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '517':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 517,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: 'slLocalizacaoCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '518':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 518,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado da mama complicado]',
          termView: 'slLadoMamaCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '519':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 519,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Conteúdo]',
          termView: 'slConteudoCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '520':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 520,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Distância da pele]',
          termView: 'slDistPeleCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '521':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 521,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Distância da papila]',
          termView: 'slDistPapilaCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '333':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 333,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Maior na mama complicado]',
          termView: 'slMaiorMamaCistoComplicado'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '4240':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 4240,
          lesionID: 326,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '347':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 347,
          lesionID: 4681,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localização]',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '348':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 348,
          lesionID: 4681,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Lado da mama micro]',
          termView: ''
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  adicionaSelecaoLinfonodomegalia($event) {
    this.genericTermsLinfonodomegalia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoDistArquitetural($event) {
    this.genericTermsDistArquitetural.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoEctasiaDuctal($event) {
    this.genericTermsEctasiaDuctal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoImplanteMamario($event) {
    this.genericTermsImplanteMamario.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoExameComparativo($event) {
    this.genericTermsExamesComparativos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoCirurgias($event) {
    this.genericTermsCirurgias.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoNodulo($event) {
    this.genericTermsNodulo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        const textoSplit = $event.elemento.value.split('|', 2);
        const value = textoSplit[0];
        if (textoSplit[1]) {
          const objBirads = { id: $event.elemento.id, value: textoSplit[0], isBirads4: textoSplit[1] };
          this.listaBiradsNodulo.push(objBirads);
        }
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal(value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoCisto($event) {
    this.genericTermsCisto.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.indicacao) {
        this.term.blockID = this.indicacaoID;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.indicacaoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.indicacaoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              if(!this.newEditor) {
                this.AdicionaTexto(this.newEditor);
              } else {
                this.atualizaLinha({
                  blockId: this.indicacaoID,
                  descricao: desc,
                })
              }
            }
          });
        });

      } else if (this.linfonodomegalia) {
        this.term.blockID = this.linfonodomegaliaID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.linfonodomegaliaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoLinfonodomegalia(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.linfonodomegaliaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.linfonodomegaliaID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoLinfonodomegalia(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.linfonodomegaliaID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.linfonodomegaliaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.linfonodomegaliaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.linfonodomegaliaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.distArquitetural) {
        this.term.blockID = this.distArquiteturalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.distArquiteturalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoDistArquitetural(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.distArquiteturalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.distArquiteturalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoDistArquitetural(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.distArquiteturalID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.distArquiteturalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.distArquiteturalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.distArquiteturalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.ectasiaDuctal) {
        this.term.blockID = this.ectasiaDuctalID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.ectasiaDuctalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEctasiaDuctal(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.ectasiaDuctalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.ectasiaDuctalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoEctasiaDuctal(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.ectasiaDuctalID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.ectasiaDuctalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.ectasiaDuctalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.ectasiaDuctalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.implanteMamario) {
        this.term.blockID = this.implanteMamarioID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.implanteMamarioID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoImplanteMamario(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.implanteMamarioID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.implanteMamarioID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoImplanteMamario(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.implanteMamarioID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.implanteMamarioID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.implanteMamarioID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.implanteMamarioID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.exameComparativo) {
        this.term.blockID = this.exameComparativoID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.exameComparativoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoExameComparativo(resultado.text, contadorTexto);
                espaco = '\n';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.exameComparativoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.exameComparativoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoExameComparativo(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.exameComparativoID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.exameComparativoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.exameComparativoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.exameComparativoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.nodulo) {
        this.term.blockID = this.noduloID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.noduloID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoNodulo(resultado.text, contadorTexto);
                espaco = '<br/>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.noduloID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // Ajusta frase cisto se nescessário
              // let indexCisto = this.descricoes.findIndex(x => x.blockID === this.cistoID);
              // if (indexCisto > -1) {
              //   if (this.descricoes[indexCisto].textTermID === 0) {
              //     // this.descricoes[indexCisto].value = 'Restante do tecido fibroglandular de aspecto preservado.';
              //     this.descricoes[indexCisto].textTermID = 0;
              //   }
              // }
            }
          });

          let obj = {
            value: '',
            blockID: this.noduloID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.noduloID;
              obj.value = obj.value + espaco + this.MontaConclusaoNodulo(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.noduloID);
                birads.value = 0;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.noduloID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.noduloID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.noduloID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      } else if (this.cisto) {
        this.term.blockID = this.cistoID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.cistoID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoCisto(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.cistoID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // Ajusta frase nódulo se nescessário
              // let indexNodulo = this.descricoes.findIndex(x => x.blockID === this.noduloID);
              // if (indexNodulo > -1) {
              //   if (this.descricoes[indexNodulo].textTermID === 0) {
              //     // this.descricoes[indexNodulo].value = 'Restante do tecido fibroglandular de aspecto preservado.';
              //     this.descricoes[indexNodulo].textTermID = 0;
              //   }
              // }
            }
          });

          let obj = {
            value: '',
            blockID: this.cistoID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoCisto(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.cistoID);
                birads.value = 0;
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.cistoID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cistoID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.cistoID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });
      } else if (this.cirurgias) {
        this.term.blockID = this.cirurgiasID;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.cirurgiasID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaCirurgias(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.cirurgiasID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.cirurgiasID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaCirurgias(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
                // Zera o Birads
                const birads = this.listaBirads.find(x => x.blockID === this.cirurgiasID);
                birads.value = 0;
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.cirurgiasID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cirurgiasID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.cirurgiasID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            })
          }
        });

      }
    }
  }

  MontaDescricaoLinfonodomegalia(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 383 || +term === 384) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoLinfonodomegalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 383 || +term === 384) {
        this.genericTermsLinfonodomegalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+textoSplit[1]) {
              // if (+textoSplit[1] > this.ultimoBiRads && this.conclusoes.length > 1) {
              //   this.ultimoBiRads = +textoSplit[1];
              // } else if (this.conclusoes.length <= 1 && this.conclusoes[0].blockID === this.linfonodomegaliaID) {
              //   this.ultimoBiRads = +textoSplit[1];
              // }
              const birads = this.listaBirads.find(x => x.blockID === this.linfonodomegaliaID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoDistArquitetural(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 356 || +term === 357) {
        this.genericTermsDistArquitetural.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoDistArquitetural(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 356 || +term === 357) {
        this.genericTermsDistArquitetural.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+textoSplit[1]) {
              const birads = this.listaBirads.find(x => x.blockID === this.distArquiteturalID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoEctasiaDuctal(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 362 || +term === 363 || +term === 364 || +term === 366
        || +term === 367 || +term === 368 || +term === 369) {
        this.genericTermsEctasiaDuctal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoEctasiaDuctal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 362 || +term === 363 || +term === 364 || +term === 366
        || +term === 367 || +term === 368 || +term === 369) {
        this.genericTermsEctasiaDuctal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+textoSplit[1]) {
              // if (+textoSplit[1] > this.ultimoBiRads && conclusaoAll.length > 1) {
              //   this.ultimoBiRads = +textoSplit[1];
              // } else if (conclusaoAll.length <= 1 && conclusaoAll[0].blockID === this.ectasiaDuctalID || conclusaoAll[0].blockID === 0) {
              //   this.ultimoBiRads = +textoSplit[1];
              // }
              const birads = this.listaBirads.find(x => x.blockID === this.ectasiaDuctalID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoImplanteMamario(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 373) {
        this.genericTermsImplanteMamario.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoImplanteMamario(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 373 || +term === 371) {
        const textoSplit = texto.split('|', 2);
        textoRetorno = textoSplit[0];
        this.genericTermsImplanteMamario.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
            generic.selected = true;
          }
        });
        // Verifica BI-RADS
        if (+textoSplit[1]) {
          // if (+textoSplit[1] > this.ultimoBiRads && conclusaoAll.length > 1) {
          //   this.ultimoBiRads = +textoSplit[1];
          // } else if (conclusaoAll.length <= 1 && conclusaoAll[0].blockID === this.implanteMamarioID || conclusaoAll[0].blockID === 0) {
          //   this.ultimoBiRads = +textoSplit[1];
          // }
          const birads = this.listaBirads.find(x => x.blockID === this.implanteMamarioID);
          birads.value = +textoSplit[1];
        }
      }
    });
    return textoRetorno;
  }

  MontaDescricaoExameComparativo(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 481 || +term === 483 || +term === 484 || +term === 486 || +term === 487) {
        this.genericTermsExamesComparativos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoExameComparativo(texto) {
    let textoRetorno = '';
    const textoSplit = texto.split('|', 2);
    texto = textoSplit[0];


    this.term.listTermID.forEach(term => {
      if (+term === 481 || +term === 483 || +term === 484 || +term === 486 || +term === 487) {
        this.genericTermsExamesComparativos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (+generic.termID === 483) {
              if (+generic.value >= 12) {
                texto = texto.replace('[Z]', '1 ano');
              } else {
                texto = texto.replace('[Z]', '6 meses');
              }
            }

            textoRetorno = texto.replace(generic.term, generic.value);
            // Verifica BI-RADS
            if (+generic.termID === 483 || +generic.termID === 484) {
              if (+generic.value >= 24) {
                textoSplit[1] = textoSplit[1].replace('[X]', '2');
              } else {
                textoSplit[1] = textoSplit[1].replace('[X]', '3');
              }
            }
            if (+textoSplit[1]) {
              const birads = this.listaBirads.find(x => x.blockID === this.exameComparativoID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoNodulo(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoNoduloDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoNoduloDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoNoduloDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4618 || +term === 4619 || +term === 4620 || +term === 4621 || +term === 4622 ||
        +term === 4623 || +term === 4624 || +term === 4625 || +term === 4626 || +term === 4627 || +term === 4630) {
        // +term === 4631 || +term === 4632 || +term === 4633 || +term === 4634 || +term === 4635 ||
        // +term === 4636 || +term === 4637 || +term === 4638 || +term === 4639 || +term === 4640) {
        this.genericTermsNodulo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            const genericValue = generic.value.split('|', 2);
            textoRetorno = texto.replace(generic.term, genericValue[0]);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  TextoNoduloDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let textoDinamico2 = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0] + '</br>';
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (+term === 4620 || +term === 4621 || +term === 4622 || +term === 4623 || +term === 4624 ||
        +term === 4631 || +term === 4632 || +term === 4633 || +term === 4634 || +term === 4635 ||
        +term === 4641 || +term === 4642 || +term === 4643 || +term === 4644 || +term === 4645 ||
        +term === 4646 || +term === 4652) {
        this.genericTermsNodulo.forEach(generic => {
          if (+generic.termID === +term) {
            const genericValue = generic.value.split('|', 2);
            textoRetorno = textoRetorno.replace(generic.term, genericValue[0]);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      //Pesquisa pelo item que indica que a frase deva ser a de Retroaurelar
      if (this.dinamicLesions.filter(lesao => lesao.linha === linha).find(les => les.termID === 4654 && les.linha == linha && les.lesionID == lesaoID) && textoDinamico2 === '' ) {
        textoDinamico2 = '</br>- [Localização] da mama [Lado da mama], medindo [Medida] cm, distando e [Distância da pele] cm da pele. [Doppler].';
        textoDinamico = textoDinamico2;
      } else if (this.dinamicLesions.filter(lesao => lesao.linha === linha).find(les => les.termID === 4655 && les.linha == linha && les.lesionID == lesaoID) && textoDinamico2 === '') {
        textoDinamico2 = 'Nódulo [Ecogenicidade], [Forma], [Margens], com orientação [Eixo] à pele, [Sombra] sombra acústica posterior, medindo [Medida] cm, localizado [Localização] da mama [Lado da mama] e distando [Distância da pele] cm da pele. [Doppler].';
        textoDinamico = textoDinamico2;
      }
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 4631 || item.termID == 4632 || item.termID == 4633 || item.termID == 4634
            || item.termID == 4635 || item.termID == 4636 || item.termID == 4637 || item.termID == 4638
            || item.termID == 4639 || item.termID == 4640 || item.termID == 4642 || item.termID == 4643
            || item.termID == 4644 || item.termID == 4645 || item.termID == 4646 || item.termID == 4647
            || item.termID == 4648 || item.termID == 4649 || item.termID == 4650 || item.termID == 4651
            || item.termID == 4641 || item.termID == 4642 || item.termID == 4643 || item.termID == 4644
            || item.termID == 4645 || item.termID == 4646 || item.termID == 4652) {
            const genericValue = item.value.split('|', 2);
            textoDinamico = textoDinamico.replace(item.term, genericValue[0]);
          }
        } else {
          linha++;
          textoDinamico2 = '';
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = '</br>' + textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }
          //Pesquisa pelo item que indica que a frase deva ser a de Retroaurelar
          if (this.dinamicLesions.filter(lesao => lesao.linha === linha).find(les => les.termID === 4654 && les.linha == linha && les.lesionID == lesaoID) && textoDinamico2 === '') {
            textoDinamico2 = '</br>- [Localização] da mama [Lado da mama], medindo [Medida] cm, distando e [Distância da pele] cm da pele. [Doppler].';
            textoDinamico = textoDinamico2;
          } else if (this.dinamicLesions.filter(lesao => lesao.linha === linha).find(les => les.termID === 4655 && les.linha == linha && les.lesionID == lesaoID) && textoDinamico2 === '') {
            textoDinamico2 = 'Nódulo [Ecogenicidade], [Forma], [Margens], com orientação [Eixo] à pele, [Sombra] sombra acústica posterior, medindo [Medida] cm, localizado [Localização] da mama [Lado da mama] e distando [Distância da pele] cm da pele. [Doppler].';
            textoDinamico = textoDinamico2;
          } else { textoDinamico = textoDinamico }

          if (item.termID == 4631 || item.termID == 4632 || item.termID == 4633 || item.termID == 4634
            || item.termID == 4635 || item.termID == 4636 || item.termID == 4637 || item.termID == 4638
            || item.termID == 4639 || item.termID == 4640 || item.termID == 4642 || item.termID == 4643
            || item.termID == 4644 || item.termID == 4645 || item.termID == 4646 || item.termID == 4647
            || item.termID == 4648 || item.termID == 4649 || item.termID == 4650 || item.termID == 4651
            || item.termID == 4641 || item.termID == 4642 || item.termID == 4643 || item.termID == 4644
            || item.termID == 4645 || item.termID == 4646 || item.termID == 4652) {
            const genericValue = item.value.split('|', 2);
            textoDinamico = textoDinamico.replace(item.term, genericValue[0]);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoNodulo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4631 || +term === 4632 || +term === 4633 || +term === 4634 ||
        +term === 4635 || +term === 4636 || +term === 4637 || +term === 4638 ||
        +term === 4639 || +term === 4640 || +term === 4642 || +term === 4643 ||
        +term === 4644 || +term === 4645 || +term === 4646 || +term === 4647 ||
        +term === 4648 || +term === 4649 || +term === 4650 || +term === 4651 ||
        +term === 4618 || +term === 4619 || +term === 4620 || +term === 4621 || +term === 4622 ||
        +term === 4623 || +term === 4624 || +term === 4625 || +term === 4626 || +term === 4639 ||
        +term === 4630) {
        this.genericTermsNodulo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            const genericValue = generic.value.split('|', 2);
            textoRetorno = texto.replace(generic.term, genericValue[0]);
            texto = textoRetorno;
          }
          // Lógica BIRADS nódulo
          let nodBirads = 0;
          const index = this.listaBiradsNodulo.findIndex(x => x.isBirads4 == 'true');
          if (index > -1) {
            nodBirads = 4;
          } else {
            nodBirads = 3;
          }
          const birads = this.listaBirads.find(x => x.blockID === this.noduloID);
          birads.value = nodBirads;
        });
      }
    });
    return texto;
  }

  MontaDescricaoCisto(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoCistoDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoCistoDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoCistoDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 320 || +term === 321 || +term === 322 || +term === 327 || +term === 328 ||
          +term === 329 || +term === 330 || +term === 331 || +term === 332 || +term === 333 ||
          +term === 335 || +term === 336 || +term === 337 || +term === 338 || +term === 339 ||
          +term === 340 || +term === 341 || +term === 342 || +term === 346 || +term === 347 ||
          +term === 348 || +term === 349 || +term === 350 || +term === 353 || +term === 513 ||
          +term === 514 || +term === 515 || +term === 323 || +term === 333 || +term === 516 ||
          +term === 517 || +term === 518 || +term === 519 || +term === 520 || +term === 521) {
          this.genericTermsCisto.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 320 || +term === 321 || +term === 322 || +term === 327 || +term === 328 ||
          +term === 329 || +term === 330 || +term === 331 || +term === 332 || +term === 333 ||
          +term === 335 || +term === 336 || +term === 337 || +term === 338 || +term === 339 ||
          +term === 340 || +term === 341 || +term === 342 || +term === 346 || +term === 347 ||
          +term === 348 || +term === 349 || +term === 350 || +term === 353 || +term === 513 ||
          +term === 514 || +term === 515 || +term === 323 || +term === 333 || +term === 516 ||
          +term === 517 || +term === 518 || +term === 519 || +term === 520 || +term === 521) {
          this.genericTermsCisto.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  TextoCistoDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let textoDinamico2 = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '\n';
      textoDinamico = textoSplit[1];
    }

    const indexComplicado = this.genericTermsCisto.findIndex(x => x.value === '' && x.termID === 518);
    const indexSimples = this.genericTermsCisto.findIndex(x => x.value === '' && x.termID === 515);

    // Lista term
    this.term.listTermID.forEach(term => {
      if (+term === 515 || +term === 519 || +term === 518 || +term === 330) {
        this.genericTermsCisto.forEach(generic => {
          if (+generic.termID === +term) {
            // if (indexComplicado > -1 && indexSimples === -1
            //   || indexComplicado === -1 && indexSimples > -1) {
            //   textoRetorno = textoRetorno.replace(generic.term, generic.value);
            // POG - estava em momento complicado da minha vida. Ass: Gabriel
            // } else if (+term === 515) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
            // }
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 519 || +term === 518 || +term === 330) {
          this.genericTermsCisto.forEach(generic => {
            if (generic.termID == term) {
              textoRetorno = textoRetorno.replace(generic.term, generic.value);
            }
          });
        }
      });
    }
    this.dinamicLesions.forEach(item => {
      //Pesquisa pelo item que indica que a frase deva ser a de Retroaurelar
      if (this.dinamicLesions.filter(lesao => lesao.linha === linha).find(les => les.termID === 4240 && les.linha == linha) && textoDinamico2 === '' ) {
        textoDinamico2 = '</br>- [Localização] [Maior na mama complicado], medindo [Medindo] cm e distando cerca de [Distância da pele] cm da pele.';
        textoDinamico = textoDinamico2;
      }
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID == 513 || item.termID == 514 || item.termID == 515 || item.termID == 323 ||
            item.termID == 333 || item.termID == 516 || item.termID == 517 || item.termID == 518 ||
            item.termID == 519 || item.termID == 520 || item.termID == 521 || item.termID == 330 ||
            item.termID === 346 || item.termID === 347 || item.termID === 348
            ) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoDinamico2 = '';
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else {
            textoDinamico = textoSplit[1];
          }
          //Pesquisa pelo item que indica que a frase deva ser a de Retroaurelar
          if (this.dinamicLesions.filter(lesao => lesao.linha === linha).find(les => les.termID === 4240 && les.linha == linha) && textoDinamico2 === '') {
            textoDinamico2 = '</br>- [Localização] [Maior na mama complicado], medindo [Medindo] cm e distando cerca de [Distância da pele] cm da pele.';
            textoDinamico = textoDinamico2;
          } else { textoDinamico = textoDinamico }

          if (item.termID == 513 || item.termID == 514 || item.termID == 515 || item.termID == 323 ||
            item.termID == 333 || item.termID == 516 || item.termID == 517 || item.termID == 518 ||
            item.termID == 519 || item.termID == 520 || item.termID == 521 || item.termID == 330 ||
            item.termID === 346 || item.termID === 347 || item.termID === 348
            ) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoCisto(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 320 || +term === 321 || +term === 322 || +term === 327 || +term === 328 ||
        +term === 329 || +term === 330 || +term === 331 || +term === 332 || +term === 333 ||
        +term === 335 || +term === 336 || +term === 337 || +term === 338 || +term === 339 ||
        +term === 340 || +term === 341 || +term === 342 || +term === 346 || +term === 347 ||
        +term === 348 || +term === 349 || +term === 350 || +term === 353 || +term === 513 ||
        +term === 514 || +term === 515 || +term === 323 || +term === 333 || +term === 516 ||
        +term === 517 || +term === 518 || +term === 519 || +term === 520 || +term === 521) {
        this.genericTermsCisto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (generic.value === 'da mama direita') {
              generic.value = 'na mama direita';
            } else if (generic.value === 'da mama esquerda') {
              generic.value = 'na mama esquerda';
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            const textoSplit = textoRetorno.split('|', 2);
            texto = textoSplit[0];
            // Verifica BI-RADS
            if (+textoSplit[1]) {
              const birads = this.listaBirads.find(x => x.blockID === this.cistoID);
              birads.value = +textoSplit[1];
            }
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 320 || +term === 321 || +term === 322 || +term === 327 || +term === 328 ||
          +term === 329 || +term === 330 || +term === 331 || +term === 332 || +term === 333 ||
          +term === 335 || +term === 336 || +term === 337 || +term === 338 || +term === 339 ||
          +term === 340 || +term === 341 || +term === 342 || +term === 346 || +term === 347 ||
          +term === 348 || +term === 349 || +term === 350 || +term === 353 || +term === 513 ||
          +term === 514 || +term === 515 || +term === 323 || +term === 333 || +term === 516 ||
          +term === 517 || +term === 518 || +term === 519 || +term === 520 || +term === 521) {
          this.genericTermsCisto.forEach(generic => {
            if (generic.termID == term) {
              generic.selected = true;
              if (generic.value === 'da mama direita') {
                generic.value = 'na mama direita';
              } else if (generic.value === 'da mama esquerda') {
                generic.value = 'na mama esquerda';
              }
              textoRetorno = texto.replace(generic.term, generic.value);
              const textoSplit = textoRetorno.split('|', 2);
              texto = textoSplit[0];
              // Verifica BI-RADS
              if (+textoSplit[1]) {
                const birads = this.listaBirads.find(x => x.blockID === this.cistoID);
                birads.value = +textoSplit[1];
              }
            }
          });
        }
      });
    }

    return texto;
  }

  MontaCirurgias(texto) {
    let textoRetorno = texto;
    this.term.listTermID.forEach(term => {
      if (+term === 3984 || +term === 3985 || +term === 3989 || +term === 3988 || +term === 3982) {
        this.genericTermsCirurgias.forEach(generic => {
          if (+generic.termID === +term) {
            if (generic.value == 'direita') {
              texto = texto.replace('[LadoContrario]', 'esquerda')
            } else if (generic.value == 'esquerda') {
              texto = texto.replace('[LadoContrario]', 'direita')
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
          }
          if (term === 3988) {
            this.descricoes[3].value = "";
          } else {
            this.descricoes[3].value = "Tecido fibroglandular com ecotextura habitual.";
          }

        });
        const textoSplit = textoRetorno.split('|', 2);
        texto = textoSplit[0];
        // Verifica BI-RADS
        if (+textoSplit[1]) {
          const birads = this.listaBirads.find(x => x.blockID === this.cirurgiasID);
          birads.value = +textoSplit[1];
        }
      }
    });
    return texto;
  }


  RetornaConclusaoNull(Texto) {
    let texto = '';
    // if (this.conclusoes.length <= 1) {
    texto = 'Estudo ultrassonográfico normal.</br>Classificação: BI-RADS 1' +
      '</br></br>A critério clínico, sugere-se controle de rotina de acordo com o indicado para a faixa etária.';
    // }
    return texto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
		conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape
	}: IAtualizaLinha): void {
    debugger;
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }
        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
            header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {

          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
            footer.innerHTML = rodape;
          }
        }

			} else if (indicacao || achados) {
				if(indicacao === undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados === undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor(){
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    // if(this.indica){
    //   this.laudoCompleto.push({
    //     'type': 'paragraph',
    //     'blockMenu': {
    //       'id': `e-indicacao`,
    //       'name': ''
    //     },
    //     'data': {
    //       'text': `<p>${this.indicacaoTexto}</p>`,
    //     }
    //   });
    // }

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      var newValue = descricao.value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      descricao.value = newValue2;
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });
  }

  adicionaTextoOldEditor() {
    let i;
    let ultimoBiRads = 0;
    let maskSplit = this.mask.split('|')

    //Monta texto exame
    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent += this.descricoes[0].value;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '</br></br><b>Técnica:</b></br>Exame realizado com transdutor linear de alta frequência.';

    this.htmlContent += maskSplit[1];

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        if (i > 0 && i < this.descricoes.length - 1) {
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
    }
    this.htmlContent += maskSplit[2];
    this.htmlContent += '<br/>' + this.descricoes[this.descricoes.length - 1].value + '<br/>';
    this.htmlContent += maskSplit[3];

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        if (i >= 0 && i < this.conclusoes.length - 1) {
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        }
      }
    }
    if (this.conclusoes[0].value === '') {
      this.listaBirads.forEach(obj => {
        if (obj.value > ultimoBiRads) {
          ultimoBiRads = obj.value;
        }
      });
      this.htmlContent += '<br/>' + 'BI-RADS: ' + ultimoBiRads + '<br/>';
    }
    this.htmlContent += '<br/>' + this.conclusoes[this.conclusoes.length - 1].value + '<br/>';
    this.htmlContent += this.achadosAdTexto;

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

}
