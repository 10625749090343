import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { AuthService } from '../../auth/auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.css']
})
export class EsqueceuSenhaComponent implements OnInit {

  formRecSenha: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(
      private fb: FormBuilder,
      private _auth: AuthService,
      private toastr: ToastrManager,
      private router: Router,
      public readonly dialog: MatDialog,
      private _firebase: FirebaseService
  ) { }

  ngOnInit() {
    this.formRecSenha = this.fb.group({
      Email: ['', Validators.email],
      ConfirmaEmail: ['', Validators.email]
    });

  }

  isFieldInvalidReset(field: string) {

    return (
      (!this.formRecSenha.get(field).valid && this.formRecSenha.get(field).touched) ||
      (this.formRecSenha.get(field).untouched && this.formSubmitAttempt)
    );

  }

  checkEmail() {
    let s1 = this.formRecSenha.get('Email').value;
    let s2 = this.formRecSenha.get('ConfirmaEmail').value;

    if (s1 !== s2) {
      this.toastr.errorToastr('Os emails precisam ser iguais', 'Coloque o mesmo email nos dois campos');
    }
  }

  onSubmit() {

    if (this.formRecSenha.valid) {
        this.formRecSenha.disable();
        const user = { Email: this.formRecSenha.get('Email').value};
        this._auth.recuperarSenha(user)
          .subscribe(
            res => {
              if (!res || res.toString() !== 'Email não cadastrado no sistema.') {
                this.toastr.successToastr('Enviamos uma email com instruções para alterar a senha.', 'Email enviado!');
                this.router.navigate(['/login']);
              } else {
                this.toastr.errorToastr('Certifique-se que suas credenciais estão corretas!', 'Email inválido');
                this.formRecSenha.enable();
              }
            },
            err => {
              this.toastr.errorToastr('Erro no serviço de emails', 'Erro');
              this.formRecSenha.enable();
            }
          );
    }

    this._firebase.logGenerateSenha();
    this.formSubmitAttempt = true;
  }
  backToLogin(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: 'info|Deseja realmente voltar?'
      });
      dialogRef.afterClosed().subscribe(result =>{
        if(result){
          this.router.navigate(['/login'])
        }
      });
  }
}
