import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-tendoes-flexores',
  templateUrl: './tendoes-flexores.component.html',
  styleUrls: ['./tendoes-flexores.component.css']
})
export class TendoesFlexoresComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTenossinovite') rdTenossinovite: MatRadioButton;
  @ViewChild('slTendao') slTendao: MatSelect;
  
  tendoes: ArrGenerico[] = [
    { value: 'flexor radial do carpo', viewValue: 'Flexor radial do carpo' },
    { value: 'flexor ulnar do carpo', viewValue: 'Flexor ulnar do carpo' },
    { value: 'flexor longo do polegar', viewValue: 'Flexor longo do polegar' },
    { value: 'flexor superficial dos dedos', viewValue: 'Flexor superficial dos dedos' },
    { value: 'flexor profundo dos dedos', viewValue: 'Flexor profundo dos dedos' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTendao: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahTendao = false;
    this.catchIDs();
  }

  clickTendinopatia() {
    this.showAndHideTerms.sahTendao = true;
    this.catchIDs();
  }

  clickTenossinovite() {
    this.showAndHideTerms.sahTendao = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }
  
  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTenossinovite.checked) {
      this.termoSelecionado.emit(this.rdTenossinovite.value);
    }

    this.listaCompleta.emit();
  }
}
