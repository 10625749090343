import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';
import { debug } from 'console';

@Component({
  selector: 'app-aoili-ateromatose',
  templateUrl: './aoili-ateromatose.component.html',
  styleUrls: ['./aoili-ateromatose.component.css']
})
export class AoiliAteromatoseComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdPlacas') rdPlacas: MatRadioButton;
  @ViewChild('rdIncipiente') rdIncipiente: MatRadioButton;
  @ViewChild('rdEsparsas') rdEsparsas: MatRadioButton;
  @ViewChild('rdMultPlacas') rdMultPlacas: MatRadioButton;

  constructor() { }

  ngOnInit() {
    this.clickNormal();
  }

  clickNormal() {
    this.catchIDs();
  }

  clickrdPlacas() {
    this.catchIDs();
  }

  clickIncipiente() {
    this.catchIDs();
  }

  clickEsparsas() {
    this.catchIDs();
  }

  clickMultPlacas() {
    this.catchIDs();
  }



  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdIncipiente && this.rdIncipiente.checked) {
      this.termoSelecionado.emit(this.rdIncipiente.id);
    }

    if (this.rdEsparsas && this.rdEsparsas.checked) {
      this.termoSelecionado.emit(this.rdEsparsas.id);
    }

    if (this.rdMultPlacas && this.rdMultPlacas.checked) {
      this.termoSelecionado.emit(this.rdMultPlacas.id);
    }

    this.listaCompleta.emit();

  }


}
