import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { CommonModule } from '@angular/common';
import { AbdFigadoComponent } from './TermosComponents/abd-figado/abd-figado.component';
import { AbdomeTotalRefatoradoComponent } from './abdome-total-refatorado.component';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { AbdLimitacoesTecnicasComponent } from './TermosComponents/abd-limitacoes-tecnicas/abd-limitacoes-tecnicas.component';
import { AbdViasBiliaresComponent } from './TermosComponents/abd-vias-biliares/abd-vias-biliares.component';
import { AbdPancreasComponent } from './TermosComponents/abd-pancreas/abd-pancreas.component';
import { AbdBacoComponent } from './TermosComponents/abd-baco/abd-baco.component';
import { AbdLinfonodoComponent } from './TermosComponents/abd-linfonodo/abd-linfonodo.component';
import { AbdBexigaComponent } from './TermosComponents/abd-bexiga/abd-bexiga.component';
import { AbdVesiculaBiliarComponent } from './TermosComponents/abd-vesicula-biliar/abd-vesicula-biliar.component';
import { AbdRimDireitoComponent } from './TermosComponents/abd-rim-direito/abd-rim-direito.component';
import { AbdRimEsquerdoComponent } from './TermosComponents/abd-rim-esquerdo/abd-rim-esquerdo.component';
import { AbdAsciteComponent } from './TermosComponents/abd-ascite/abd-ascite.component';
import { AbdAortaComponent } from './TermosComponents/abd-aorta/abd-aorta.component';
import { AdbVeiaPortaComponent } from './TermosComponents/adb-veia-porta/adb-veia-porta.component';
import { AbdAlcaIntestinalComponent } from './TermosComponents/abd-alca-intestinal/abd-alca-intestinal.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    SharedModule,
    LaudoFooterModule,
  ],
  exports: [
    AbdomeTotalRefatoradoComponent
  ],
  declarations: [
    AbdomeTotalRefatoradoComponent,
    AbdFigadoComponent,
    AbdLimitacoesTecnicasComponent,
    AbdViasBiliaresComponent,
    AbdPancreasComponent,
    AbdBacoComponent,
    AbdLinfonodoComponent,
    AbdBexigaComponent,
    AbdVesiculaBiliarComponent,
    AbdRimDireitoComponent,
    AbdRimEsquerdoComponent,
    AbdAsciteComponent,
    AbdAortaComponent,
    AdbVeiaPortaComponent,
    AbdAlcaIntestinalComponent
]
})
export class AbdomeTotalRefatoradoModule { }
