import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-safena-parva',
  templateUrl: './safena-parva.component.html',
  styleUrls: ['./safena-parva.component.css']
})
export class SafenaParvaComponent implements OnInit {
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckInsuficiente') ckInsuficiente: MatCheckbox;
  @ViewChild('ckHipoplasica') ckHipoplasica: MatCheckbox;
  @ViewChild('ckTromboflebite') ckTromboflebite: MatCheckbox;

  @ViewChild('slLocalHipo') slLocalHipo: MatSelect;
  @ViewChild('slCalibre') slCalibre: MatSelect;
  @ViewChild('slTerco') slTerco: MatSelect;
  @ViewChild('slTercoTro') slTercoTro: MatSelect;



  calibres: ArrayDropDown[] = [
    { value: 'preservado', viewValue: 'Preservado' },
    { value: 'aumentado', viewValue: 'Aumentado' },
  ];

  tercos: ArrayDropDown[] = [
    { value: 'proximal da perna', viewValue: 'Proximal da perna' },
    { value: 'médio da perna', viewValue: 'Médio da perna' },
    { value: 'distal da perna', viewValue: 'Distal da perna' }
  ];

  tercosTro: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' }
  ];

  locaishipos: ArrayDropDown[] = [
    { value: 'no terço proximal da perna', viewValue: 'Terço proximal da perna' },
    { value: 'no terço médio da perna', viewValue: 'Terço médio da perna' },
    { value: 'no terço distal da perna', viewValue: 'Terço distal da perna' },
    { value: 'em toda extensão da perna', viewValue: 'Toda extensão da perna' },
  ];

  countLesoes: number = 0;
  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahInsuf: false,
      sahHipop: false,
      sahTrombo: false

    }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahInsuf = false;
    this.showAndHideTerms.sahHipop = false;
    this.showAndHideTerms.sahTrombo = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
  }

  clickckInsuficiente() {
    this.verificaLesoes();
    if (this.showAndHideTerms.sahInsuf === false) {
      this.showAndHideTerms.sahInsuf = true;
    } else {
      this.showAndHideTerms.sahInsuf = false;
    }
    this.ckChecked();
    this.catchIDs();
  }

  clickckHipoplasica() {
    this.verificaLesoes();
    if (this.showAndHideTerms.sahHipop === false) {
      this.showAndHideTerms.sahHipop = true;
    } else {
      this.showAndHideTerms.sahHipop = false;
    }
    this.ckChecked();
    this.catchIDs();
  }

  clickckTromboflebite() {
    this.verificaLesoes();
    if (this.showAndHideTerms.sahTrombo === false) {
      this.showAndHideTerms.sahTrombo = true;
    } else {
      this.showAndHideTerms.sahTrombo = false;
    }
    this.ckChecked();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  
  verificaLesoes(){
    if((this.ckInsuficiente && this.ckInsuficiente.checked) || 
    (this.ckHipoplasica && this.ckHipoplasica.checked) || (this.ckTromboflebite && this.ckTromboflebite.checked)){
      this.rdAlterado.checked = true;
    }
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {
      this.termoSelecionado.emit(this.rdAlterado.id);

      if (this.ckInsuficiente && this.ckInsuficiente.checked) {
        if (this.countLesoes > 0)
          this.emitInsuficienteLesao();
        else
          this.emitInsuficiente();
      }

      if (this.ckHipoplasica && this.ckHipoplasica.checked) {
        if (this.countLesoes > 0)
          this.emitHipoplasicaLesao();
        else
          this.emitHipoplasica();
      }

      if (this.ckTromboflebite && this.ckTromboflebite.checked) {
        if (this.countLesoes > 0)
          this.emitTromboflebiteLesao();
        else
          this.emitTromboflebite();
      }

    }


    this.listaCompleta.emit();
  }

  emitInsuficiente() {
    if (this.ckInsuficiente && this.ckInsuficiente.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckInsuficiente.id);

      if (this.slCalibre && this.slCalibre.value) {
        const objeto = { elemento: this.slCalibre, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slTerco && this.slTerco.value) {
        const objeto = { elemento: this.slTerco, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitInsuficienteLesao() {
    if (this.ckInsuficiente && this.ckInsuficiente.checked) {
      this.countLesoes++;
      const objetoAusente = { id: this.rdAlterado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoAusente);
      const objeto = { id: this.ckInsuficiente.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slCalibre && this.slCalibre.value) {
        const obj = { elemento: this.slCalibre, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
      if (this.slTerco && this.slTerco.value) {
        const obj = { elemento: this.slTerco, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitHipoplasica() {
    if (this.ckHipoplasica && this.ckHipoplasica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHipoplasica.id);

      if (this.slLocalHipo && this.slLocalHipo.value) {
        const objeto = { elemento: this.slLocalHipo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHipoplasicaLesao() {
    if (this.ckHipoplasica && this.ckHipoplasica.checked) {
      this.countLesoes++;
      const objetoAusente = { id: this.rdAlterado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoAusente);
      const objeto = { id: this.ckHipoplasica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLocalHipo && this.slLocalHipo.value) {
        const obj = { elemento: this.slLocalHipo, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitTromboflebite() {
    if (this.ckTromboflebite && this.ckTromboflebite.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckTromboflebite.id);

      if (this.slTercoTro && this.slTercoTro.value) {
        const objeto = { elemento: this.slTercoTro, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitTromboflebiteLesao() {
    if (this.ckTromboflebite && this.ckTromboflebite.checked) {
      this.countLesoes++;
      const objetoAusente = { id: this.rdAlterado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoAusente);
      const objeto = { id: this.ckTromboflebite.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slTercoTro && this.slTercoTro.value) {
        const obj = { elemento: this.slTercoTro, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }


  ckChecked() {
    if (this.ckInsuficiente.checked === false &&
      this.ckHipoplasica.checked === false &&
      this.ckTromboflebite.checked === false) {
      this.rdAlterado.checked = false;
      this.showAndHideTerms.sahAlterado = false;
      this.rdNormal.checked = true;
    }
  }

}
