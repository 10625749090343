import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton, MatSelect} from '@angular/material';
// import {LadoMama} from '../linfonodomegalia/linfonodomegalia.component';
// import {Localizacao} from '../distorcao-arquitetural/distorcao-arquitetural.component';

export interface LadoMama {
  value: string;
  viewValue: string;
}

export interface Localizacao {
  value: string;
  viewValue: string;
}

export interface Doppler {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ectasia-ductal',
  templateUrl: './ectasia-ductal.component.html',
  styleUrls: ['./ectasia-ductal.component.css']
})
export class EctasiaDuctalComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('rdComConteudo') rdComConteudo: MatRadioButton;
  @ViewChild('rdSemConteudo') rdSemConteudo: MatRadioButton;

  @ViewChild('slLadoMama') slLadoMama: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slDoppler') slDoppler: MatSelect;
  @ViewChild('inCalibre') inCalibre: ElementRef;
  @ViewChild('inMedidaConteudo') inMedidaConteudo: ElementRef;
  @ViewChild('inDistPele') inDistPele: ElementRef;
  @ViewChild('inDistPapila') inDistPapila: ElementRef;

  ladosMama: LadoMama[] = [
    {value: 'da mama direita', viewValue: 'Direita'},
    {value: 'da mama esquerda', viewValue: 'Esquerda'},
    {value: 'das mamas', viewValue: 'Mamas'}
  ];

  localizacoes: Localizacao[] = [
    {value: 'na região retroareolar', viewValue: 'Região retroareolar'},
    {value: 'no quadrante superolateral', viewValue: 'Quadrante superolateral'},
    {value: 'no quadrante superomedial', viewValue: 'Quadrante superomedial'},
    {value: 'no quadrante inferolateral', viewValue: 'Quadrante inferolateral'},
    {value: 'no quadrante inferomedial', viewValue: 'Quadrante inferomedial'}
  ];

  dopplers: Doppler[] = [
    {value: 'sem', viewValue: 'Sem'},
    {value: 'com', viewValue: 'Com'}
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: true,
      sahComConteudo: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }


  clickAusente() {
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahComConteudo = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahAusente = true;
    this.catchIDs();
  }

  clickComConteudo() {
    this.showAndHideTerms.sahComConteudo = true;
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickSemConteudo() {
    this.showAndHideTerms.sahComConteudo = false;
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeLadoMama() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeLocalizacao() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeDoppler() {
    this.catchIDs();
  }

  blurMedindo() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);


      if (this.rdSemConteudo != undefined  && this.rdSemConteudo.checked) {
        this.termoSelecionado.emit(this.rdSemConteudo.id);
      }

      if (this.rdComConteudo != undefined  && this.rdComConteudo.checked) {
        this.termoSelecionado.emit(this.rdComConteudo.id);
      }

      if (this.slLadoMama != undefined && this.slLadoMama.value != undefined) {
        const objeto = {elemento: this.slLadoMama, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
        const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inCalibre != undefined &&
        this.inCalibre.nativeElement.id != undefined &&
        this.inCalibre.nativeElement.value != '') {

        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slDoppler != undefined && this.slDoppler.value != undefined) {
        const objeto = {elemento: this.slDoppler, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedidaConteudo != undefined &&
        this.inMedidaConteudo.nativeElement.id != undefined &&
        this.inMedidaConteudo.nativeElement.value != '') {

        const objeto = {elemento: this.inMedidaConteudo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPele != undefined &&
        this.inDistPele.nativeElement.id != undefined &&
        this.inDistPele.nativeElement.value != '') {

        const objeto = {elemento: this.inDistPele.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPapila != undefined &&
        this.inDistPapila.nativeElement.id != undefined &&
        this.inDistPapila.nativeElement.value != '') {

        const objeto = {elemento: this.inDistPapila.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.rdSemConteudo && this.rdSemConteudo.checked) ||
        (this.rdComConteudo && this.rdComConteudo.checked) ||
        (this.slLadoMama && this.slLadoMama.value) ||
        (this.slLocalizacao && this.slLocalizacao.value) ||
        (this.inCalibre && this.inCalibre.nativeElement.value != '')

    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }
  }
}
