import { ClipboardService } from 'ngx-clipboard';
import { Injectable } from '@angular/core';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';

@Injectable({
  providedIn: 'root'
})
export class CopyPaste {

  constructor(private _clipBoardService: ClipboardService,
    private _configLaudoService: ConfigLaudoService) { }

  copyFromId(id: string, isHtml: boolean) {
    let content;
    const element = document.getElementById(id);

    if (this.ruleActive() && !isHtml) {
      this.copyFormated();
      this.copyFormated();
    } else {
      if (isHtml) {
        content = element.innerHTML;

        content = this.removeToolsToCopy(content);
      } else {
        content = element.innerText;
      }
    }

    this._clipBoardService.copy(content);
  }

  /**
   * @param contentHTML Essa vairável recebe o html do textEditorJS
   * aqui removemos o header com as ferramentas do textEditor na hora de copiar
  */
   removeToolsToCopy(contentHTML: string): string {
    const [textHTML,] = contentHTML.split('<div class="codex-editor-overlay');

    const paragraphs = Array.from(document.getElementsByClassName('ce-paragraph'));
    paragraphs.forEach(paragraph => {
      paragraph.parentElement.parentElement.style.removeProperty('background');
      paragraph.parentElement.parentElement.style.removeProperty('border-left');
    });

    return textHTML;
  }

  getTextFromId(id: string) {
    const element = document.getElementById(id);
    const text = element.innerText;
    // const divContainer = element.getElementsByTagName('div');
    return text;
  }

  getHtmlFromId(id: string) {
    const element = document.getElementById(id);
    // const divContainer = element.getElementsByTagName('div');
    const html = element.innerHTML;
    return html;
  }

  ruleActive() {
    let active = false;
    let rule = JSON.parse(localStorage.getItem('copyRule'));
    if (rule && rule.medicalReportRuleID === 3 && rule.active) {
      active = true;
    } else {
      active = false;
    }
    return active;
  }

  copyFormated() {
    setTimeout(() => {
      let range = document.createRange();
      range.selectNode(document.getElementsByClassName("angular-editor-textarea")[0]);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }, 100);
  }


}
