import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { RiscoPreEclampsiaDialogComponent } from 'src/app/utils/dialog/table/risco-pre-eclampsia-dialog/risco-pre-eclampsia-dialog.component';

@Component({
  selector: 'app-risco-pre-eclampsia',
  templateUrl: './risco-pre-eclampsia.component.html',
  styleUrls: ['./risco-pre-eclampsia.component.css']
})
export class RiscoPreEclampsiaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();

  @ViewChild('inRiscoHistoriaArterias') inRiscoHistoriaArterias: ElementRef;
  @ViewChild('slRiscoPreEclampsia') slRiscoPreEclampsia: MatSelect;

  riscoPreEclampsia: ArrayDropDown[] = [
    { value: 'risco baixo', viewValue: 'Risco baixo' },
    { value: 'alto risco', viewValue: 'Alto risco' }
  ]

  constructor(public dialog: MatDialog,) { }

  ngOnInit() {
  }

  blurParametros() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }
  clickTabelaReferenciaRiscoPreEclampsia(){
    this.dialog.open(RiscoPreEclampsiaDialogComponent, {});
  }

  catchIDs() {
    this.enviaMedida.emit('Clean');
    this.termoSelecionado.emit('Clean');

    if (this.inRiscoHistoriaArterias && this.inRiscoHistoriaArterias.nativeElement.value) {
      const element = { value: this.inRiscoHistoriaArterias.nativeElement.value, id: this.inRiscoHistoriaArterias.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if(this.slRiscoPreEclampsia && this.slRiscoPreEclampsia.value){
      if(this.slRiscoPreEclampsia.value == 'risco baixo'){
        const objetoElemento = { id: '5590', value: 5590 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }else{
        const objetoElemento = { id: '5591', value: 5591 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      const objeto = { elemento: this.slRiscoPreEclampsia, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
    this.listaCompleta.emit();
  }
}
