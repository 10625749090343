import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-processos-espinhosos',
  templateUrl: './processos-espinhosos.component.html',
  styleUrls: ['./processos-espinhosos.component.css']
})
export class ProcessosEspinhososComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdHipertrofia') rdHipertrofia: MatRadioButton;
  @ViewChild('rdHipertrofiaMultipla') rdHipertrofiaMultipla: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;

  @ViewChild('slNivelHipertrofiaMultipla') slNivelHipertrofiaMultipla: MatSelect;
  @ViewChild('slNivelHipertrofia') slNivelHipertrofia: MatSelect;

  showAndHideTerms;
  countLesoes;
  nivelHipertrofia: ArrayDropDown[]=[
    { value: 'C7', viewValue: 'C7' },
    { value: 'T1', viewValue: 'T1' },
    { value: 'T2', viewValue: 'T2' },
    { value: 'T3', viewValue: 'T3' },
    { value: 'T4', viewValue: 'T4' },
    { value: 'T5', viewValue: 'T5' },
    { value: 'T6', viewValue: 'T6' },
    { value: 'T7', viewValue: 'T7' },
    { value: 'T8', viewValue: 'T8' },
    { value: 'T9', viewValue: 'T9' },
    { value: 'T10', viewValue: 'T10' },
    { value: 'T11', viewValue: 'T11' },
    { value: 'T12', viewValue: 'T12' },
    { value: 'L1', viewValue: 'L1' }]

    nivelHipertrofiaMultipla: ArrayDropDown[]=[
      { value: ' C7', viewValue: 'C7' },
      { value: ' T1', viewValue: 'T1' },
      { value: ' T2', viewValue: 'T2' },
      { value: ' T3', viewValue: 'T3' },
      { value: ' T4', viewValue: 'T4' },
      { value: ' T5', viewValue: 'T5' },
      { value: ' T6', viewValue: 'T6' },
      { value: ' T7', viewValue: 'T7' },
      { value: ' T8', viewValue: 'T8' },
      { value: ' T9', viewValue: 'T9' },
      { value: ' T10', viewValue: 'T10' },
      { value: ' T11', viewValue: 'T11' },
      { value: ' T12', viewValue: 'T12' },
      { value: ' L1', viewValue: 'L1' }]

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahHipertrofiaMultipla: false,
      sahHipertrofia: false
    }
  }

  changeSelect(){
    this.catchIDs();
  }

  clickHipertrofia(){
    this.rdNormal.checked=false;
    this.rdHipertrofia.checked=true;
    this.rdHipertrofiaMultipla.checked=false;

    this.showAndHideTerms.sahHipertrofiaMultipla = false;
    this.showAndHideTerms.sahHipertrofia = true;

    this.catchIDs();
  }

  clickHipertrofiaMultipla(){
    this.rdNormal.checked=false;
    this.rdHipertrofia.checked=false;
    this.rdHipertrofiaMultipla.checked=true;

    this.showAndHideTerms.sahHipertrofia = false;
    this.showAndHideTerms.sahHipertrofiaMultipla = true;

    this.catchIDs();
  }

  clickNormal(){
    this.rdNormal.checked=true;
    this.rdHipertrofiaMultipla.checked=false;
    this.rdHipertrofia.checked=false;

    this.showAndHideTerms.sahHipertrofiaMultipla=false;
    this.showAndHideTerms.sahHipertrofia=false;

    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.countLesoes > 0){
      this.emitHipertrofiaLesao();
    } else {
      this.emitHipertrofiaSimples();
    }

    if(this.countLesoes > 0){
      this.emitArtroseMultiLesao();
    } else {
      this.emitArtroseMultiSimples();
    }

    if(this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    this.listaCompleta.emit();
  }

  emitHipertrofiaSimples(){
    if(this.rdHipertrofia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdHipertrofia.id);

      if(this.slNivelHipertrofia && this.slNivelHipertrofia.value){
        const objeto = {elemento: this.slNivelHipertrofia, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
        }
      }
  }

  emitHipertrofiaLesao(){
    if(this.rdHipertrofia.checked){
      this.countLesoes++;
      const objeto = { id: this.rdHipertrofia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelHipertrofia && this.slNivelHipertrofia.value){
        const objeto = {elemento: this.slNivelHipertrofia, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitArtroseMultiSimples(){
    if(this.rdHipertrofiaMultipla.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdHipertrofiaMultipla.id);

      if(this.slNivelHipertrofiaMultipla && this.slNivelHipertrofiaMultipla.value){
        if(this.slNivelHipertrofiaMultipla.value.length>1){

          let objetoMultiplo= { id:this.slNivelHipertrofiaMultipla.id, value: this.replaceVirgulaMultSelect(this.slNivelHipertrofiaMultipla.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelHipertrofiaMultipla.value.length==1){
          const objeto = {elemento: this.slNivelHipertrofiaMultipla, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitArtroseMultiLesao(){
    if(this.rdHipertrofiaMultipla.checked){
      this.countLesoes++;
      const objeto = { id: this.rdHipertrofiaMultipla.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelHipertrofiaMultipla && this.slNivelHipertrofiaMultipla.value){
        if(this.slNivelHipertrofiaMultipla.value.length>1){

          let objetoMultiplo= { id:this.slNivelHipertrofiaMultipla.id, value: this.replaceVirgulaMultSelect(this.slNivelHipertrofiaMultipla.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelHipertrofiaMultipla.value.length==1){
          const objeto = {elemento: this.slNivelHipertrofiaMultipla, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  replaceVirgulaMultSelect(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
