import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ClipboardService } from 'ngx-clipboard';
import { User } from '../auth/user';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { UserMemberGetMember } from '../models/BO/UserMemberGetMember';
import { MemberGetMemberService } from '../services/member-get-member.service';
import { UserService } from '../services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-member-get-member',
  templateUrl: './member-get-member.component.html',
  styleUrls: ['./member-get-member.component.css']
})
export class MemberGetMemberComponent implements OnInit {
  formConvite: FormGroup;
  private formSubmitAttempt: boolean;
  $usuario: any = new User();
  $chave: string = 'https://homolog.nreport.ness.com.br/cadastro?token=';//'http://localhost:4200/cadastro?token=';
  public loading: boolean = true;
  MgMMail: any = new UserMemberGetMember();
  text: string = 'Nós do nReport estamos feliz em saber que você está recebendo esse convite.'+
                 'Gostaríamos que fizesse parte da melhor plataforma de laudos estruturados do'+
                 ' Brasil. Clique no link a seguir para efetuar seu cadastro:'
  totalScore: number;
  displayedColumns: string[] = ['Score', 'SumPoint', 'MinusPoint', 'Description'];
  dataSource: any;
  dataSourceTemp: any;
  selectedDataSource: any;
  title: string;
  description: string;
  createdOn: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private toastr: ToastrManager,
    private _memberService: MemberGetMemberService,
    private _clipBoardService: ClipboardService,
    ) { }

  ngOnInit() {
    this.formConvite = this.fb.group({
      id: ['', [Validators.required]],
      Name: ['', [Validators.required]],
      Email: ['', [Validators.required]],
    });

    this.carregarUsuario();
    this.carregarSaldo();
  }
  
  carregarUsuario() {
    const userID = +localStorage.getItem('userID');

    if (userID) {
      this._userService.buscarUsuario(userID)
      .pipe()
        .subscribe(
          (usuario) => {
            this.$usuario = usuario;
          }
        );

      this._memberService.gerarBuscarToken(userID)
        .pipe()
        .subscribe(
          (chave) =>{
            this.$chave += chave.token;
          }
        );

        this.loading = false;
    } else {
      this.toastr.errorToastr('Erro ao carregar usuário', 'Erro',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
    }
  }

  enviarConvite() {
    
    this.MgMMail.GuestName = this.formConvite.get('Name').value;
    this.MgMMail.MailGuest = this.formConvite.get('Email').value;
    this.MgMMail.LinkToken = this.$chave;
    this.MgMMail.UserName = this.$usuario.name;

    this._memberService.enviarEmail(this.MgMMail)
    .subscribe(
      res => {
        this.toastr.successToastr('Email Enviado com Sucesso!!', 'Sucesso!!',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
          this.limpaDadosForm();
      },
      err => {
        this.toastr.errorToastr('Erro ao Enviar o Email!!!', 'Falhou!!',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
      },
    );
    this.limpaDadosForm();
  }

  limpaDadosForm() {
    this.formConvite.patchValue({
      id: null,
      Name: null,
      Email: null
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formConvite.get(field).valid && this.formConvite.get(field).touched) ||
      (this.formConvite.get(field).untouched && this.formSubmitAttempt)
    );
  }

  enviarConviteButton() {
   this.text +=' '+ this.$chave;

    this._clipBoardService.copy(this.text);
    this.toastr.successToastr('Link Copiado com Sucesso!!', 'Sucesso!!',
      {
        position: 'top-right',
        toastTimeout: 4000
      });
  }

  @HostListener('window:keyup', ['$event']) keyEvent(e: KeyboardEvent) {
    if (e.keyCode == 67 && e.ctrlKey) {
      this.text +=' '+ this.$chave;
      this._clipBoardService.copy(this.text);
      this.toastr.successToastr('Link Copiado com Sucesso!!', 'Sucesso!!',
      {
        position: 'top-right',
        toastTimeout: 4000
      }
    );
    }
  }

  carregarSaldo() {
    debugger;
    const userID = +localStorage.getItem('userID');
    this._memberService.buscarSaldo(userID)
      .pipe(take(1))
      .subscribe(
        (dados: any) => {
          if (dados) {
            this.dataSource = new MatTableDataSource(dados);
            this.selectedDataSource = this.dataSource.data;
            this.dataSourceTemp = this.dataSource.data;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.dataSource.filterPredicate = (data:{title: string, reportCode:string}, filterValue: string) =>{
              let titlefilter = data.title.trim().toLowerCase().indexOf(filterValue) !== -1;
              let reportCodeFilter = data.reportCode.trim().toLowerCase().indexOf(filterValue) !== -1;

              if(titlefilter){
                return titlefilter;
              } else{
                return reportCodeFilter;
              }
            }
            this.totalScore = dados[dados.length-1].totalScore;
            this.paginator._intl.itemsPerPageLabel = 'Registros por página';
            this.loading = false;

          }
        },
        erro => {
          console.log(erro);
        }
      );
  }

 

}
