import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendao-subescapular',
  templateUrl: './tendao-subescapular.component.html',
  styleUrls: ['./tendao-subescapular.component.css']
})
export class TendaoSubescapularComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendiFissura') rdTendiFissura: MatRadioButton;

  @ViewChild('rdTendiSemRotura') rdTendiSemRotura: MatRadioButton;
  @ViewChild('ckLeveTendi') ckLeveTendi: MatCheckbox;

  @ViewChild('rdTendiRoturaParcial') rdTendiRoturaParcial: MatRadioButton;
  @ViewChild('slRoturaParcial') slRoturaParcial: MatSelect;
  @ViewChild('slTipoParcial') slTipoParcial: MatSelect;
  @ViewChild('slLocalizacaoParcial') slLocalizacaoParcial: MatSelect;
  @ViewChild('inExtencao') inExtencao: ElementRef;
  @ViewChild('inRetracao') inRetracao: ElementRef;

  @ViewChild('rdRoturaTransfixante') rdRoturaTransfixante: MatRadioButton;
  @ViewChild('slLocalizacaoTrans') slLocalizacaoTrans: MatSelect;
  @ViewChild('inExtencaoTrans') inExtencaoTrans: ElementRef;
  @ViewChild('inRetracaoTrans') inRetracaoTrans: ElementRef;

  @ViewChild('rdCalcarea') rdCalcarea: MatRadioButton;
  @ViewChild('inMedidaCalc') inMedidaCalc: ElementRef;

  roturas: ArrayDropDown[] = [
    { value: 'com rotura parcial justainsercional menor que 50% da sua espessura', viewValue: 'Menor que 50%' },
    { value: 'com rotura parcial justainsercional em cerca de 50% da sua espessura', viewValue: '50% da sua espessura' },
    { value: 'com rotura parcial justainsercional maior 50% da sua espessura', viewValue: 'Maior 50%' }
  ];

  tipos: ArrayDropDown[] = [
    { value: 'intrassubstancial', viewValue: 'Intrassubstancial' },
    { value: 'na face articular', viewValue: 'Face articular' },
    { value: 'na face bursal', viewValue: 'Face bursal' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'das fibras superiores', viewValue: 'Fibras superiores' },
    { value: 'das fibras médias', viewValue: 'Fibras médias' },
    { value: 'das fibras inferiores', viewValue: 'Fibras inferiores' }
  ];

  localizacoesTrans: ArrayDropDown[] = [
    { value: 'em toda a extensão', viewValue: 'Toda a extensão' },
    { value: 'em quase toda a extensão', viewValue: 'Quase toda a extensão' },
    { value: 'das fibras superiores', viewValue: 'Fibras superiores' },
    { value: 'das fibras médias', viewValue: 'Fibras médias' },
    { value: 'das fibras inferiores', viewValue: 'Fibras inferiores' }
  ];

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahSemRotura: false,
      sahRoturaParcial: false,
      sahRoturaTransfixante: false,
      sahCalcarea: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickDefault() {
    this.showAndHideTerms.sahSemRotura = false;
    this.showAndHideTerms.sahRoturaParcial = false;
    this.showAndHideTerms.sahRoturaTransfixante = false;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickTendiSemRotura() {
    this.showAndHideTerms.sahSemRotura = true;
    this.showAndHideTerms.sahRoturaParcial = false;
    this.showAndHideTerms.sahRoturaTransfixante = false;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickTendiRoturaParcial() {
    this.showAndHideTerms.sahSemRotura = false;
    this.showAndHideTerms.sahRoturaParcial = true;
    this.showAndHideTerms.sahRoturaTransfixante = false;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickRoturaTrans() {
    this.showAndHideTerms.sahSemRotura = false;
    this.showAndHideTerms.sahRoturaParcial = false;
    this.showAndHideTerms.sahRoturaTransfixante = true;
    this.showAndHideTerms.sahCalcarea = false;
    this.catchIDs();
  }

  clickCalcarea() {
    this.showAndHideTerms.sahCalcarea = true;
    this.showAndHideTerms.sahSemRotura = false;
    this.showAndHideTerms.sahRoturaParcial = false;
    this.showAndHideTerms.sahRoturaTransfixante = false;
    this.catchIDs();
  }

  clickCk() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendiSemRotura.checked) {
      this.termoSelecionado.emit(this.rdTendiSemRotura.value);

      if (this.ckLeveTendi && this.ckLeveTendi.checked) {
        this.termoSelecionado.emit(this.ckLeveTendi.value);
      }
    }

    if (this.rdTendiFissura.checked) {
      this.termoSelecionado.emit(this.rdTendiFissura.value);
    }

    if (this.rdTendiRoturaParcial.checked) {
      this.termoSelecionado.emit(this.rdTendiRoturaParcial.value);
      
      if (this.slRoturaParcial && this.slRoturaParcial.value) {
        const objeto = { elemento: this.slRoturaParcial, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slTipoParcial && this.slTipoParcial.value) {
        const objeto = { elemento: this.slTipoParcial, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacaoParcial && this.slLocalizacaoParcial.value) {
        const objeto = { elemento: this.slLocalizacaoParcial, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inExtencao && this.inExtencao.nativeElement.value) {
        const objeto = { elemento: this.inExtencao.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inRetracao && this.inRetracao.nativeElement.value) {
        const objeto = { elemento: this.inRetracao.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdRoturaTransfixante.checked) {
      this.termoSelecionado.emit(this.rdRoturaTransfixante.value);

      if (this.slLocalizacaoTrans && this.slLocalizacaoTrans.value) {
        const objeto = { elemento: this.slLocalizacaoTrans, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inExtencaoTrans && this.inExtencaoTrans.nativeElement.value) {
        const objeto = { elemento: this.inExtencaoTrans.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.inRetracaoTrans && this.inRetracaoTrans.nativeElement.value) {
        const objeto = { elemento: this.inRetracaoTrans.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }
    
    if (this.rdCalcarea.checked) {
      this.termoSelecionado.emit(this.rdCalcarea.value);

      if (this.inMedidaCalc && this.inMedidaCalc.nativeElement.value) {
        const objeto = { elemento: this.inMedidaCalc.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
