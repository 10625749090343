import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

import { ArrGenerico } from '../../../transvaginal/TermosComponents/endometrio/endometrio.component';

@Component({
  selector: 'app-ginecomastia',
  templateUrl: './ginecomastia.component.html',
  styleUrls: ['./ginecomastia.component.css']
})
export class GinecomastiaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('rdPseudoPresente') rdPseudoPresente: MatRadioButton;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;
  @ViewChild('inExtensaoDireita') inExtensaoDireita: ElementRef;
  @ViewChild('inExtensaoEsquerda') inExtensaoEsquerda: ElementRef;

  public unidadeMedida: string;
  public showLateralidade: boolean;
  public showAndHide: any = {
    bilateral: false,
    direito: false,
    esquerdo: false
  };

  public lateralidades: ArrGenerico[] = [
    { value: 'bilateral', viewValue: 'Bilateral' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  constructor() { }

  ngOnInit(): void {
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  ginecomastia(): void {
    if(this.rdPresente.checked) {
      this.showLateralidade = true;
    } else if(this.rdPseudoPresente.checked) {
      this.showLateralidade = false;
    } else {
      this.showLateralidade = false;
      this.showAndHide.direito = false;
      this.showAndHide.bilateral = false;
      this.showAndHide.esquerdo = false;
      this.slLateralidade.value = '';
    }

    this.catchIDs();
  }

  changeSelect(): void {
    if(!this.rdPseudoPresente.checked) {
      if(this.slLateralidade.value === 'bilateral') {
        this.showAndHide.bilateral = true;
        this.showAndHide.direito = false;
        this.showAndHide.esquerdo = false;
      } else if(this.slLateralidade.value === 'direita') {
        this.showAndHide.direito = true;
        this.showAndHide.bilateral = false;
        this.showAndHide.esquerdo = false;
      } else if(this.slLateralidade.value === 'esquerda') {
        this.showAndHide.esquerdo = true;
        this.showAndHide.bilateral = false;
        this.showAndHide.direito = false;
      }

      this.rdPresente.checked = true;
      this.showLateralidade = true;
    }

    this.catchIDs();
  }

  blurMedindo(): void {
    this.catchIDs();
  }

  catchIDs(): void  {
    this.termoSelecionado.emit('Clean');

    if(this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if(this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if(this.slLateralidade && this.slLateralidade.value) {
        if(this.slLateralidade.value === 'bilateral') {
          this.slLateralidade.id = '4684';
        } else if(this.slLateralidade.value === 'direita') {
          this.slLateralidade.id = '4688';
        } else if(this.slLateralidade.value === 'esquerda') {
          this.slLateralidade.id = '4687';
        }

        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if(this.rdPseudoPresente.checked) {
      this.termoSelecionado.emit(this.rdPseudoPresente.id);

      if(this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if(this.inExtensaoDireita && this.inExtensaoDireita.nativeElement.value) {
      const objeto = { elemento: this.inExtensaoDireita.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if(this.inExtensaoEsquerda && this.inExtensaoEsquerda.nativeElement.value) {
      const objeto = { elemento: this.inExtensaoEsquerda.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  }
}
