import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { Feedback } from '../models/BO/Feedback';
import { NPS } from '../models/BO/NPS';
import { NPSSurveys } from '../models/BO/NPSSurveys';

@Injectable({
  providedIn: 'root'
})
export class NPSService {

  constructor(private _http: HttpClient) { }

  getNps(userID: number): Observable<NPS> {
    const url = `${environment.nLaudoApi}NPS/BuscaPorUsuario/${userID}`;
    return this._http.get<NPS>(url);
    // return this._http.get<any>(environment.nLaudoApi + "NPS/BuscaPorUsuario/" + userID );
  }

  getListaNps(): Observable<NPSSurveys[]> {
    return this._http.get<NPSSurveys[]>(environment.nLaudoApi + "NPS/ListPesquisas");
  }

  insertUserNps(userID: number): Observable<NPS> {
    const url = `${environment.nLaudoApi}NPS/InsertUserNps/${userID}`;
    return this._http.get<NPS>(url);
  }
}
