import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { Validacoes } from '../../../../../../utils/validacoes';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-tecido-subcutaneo',
  templateUrl: './tecido-subcutaneo.component.html',
  styleUrls: ['./tecido-subcutaneo.component.css']
})
export class TecidoSubcutaneoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  // Normal
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  // Edema
  @ViewChild('ckEdema') ckEdema: MatCheckbox;

  // Lipoma
  @ViewChild('ckLipoma') ckLipoma: MatCheckbox;
  @ViewChild('ckContornosDefinido') ckContornosDefinido: MatCheckbox;
  @ViewChild('inMedidaLipoma1') inMedidaLipoma1: ElementRef;
  @ViewChild('inMedidaLipoma2') inMedidaLipoma2: ElementRef;
  @ViewChild('inMedidaLipoma3') inMedidaLipoma3: ElementRef;
  @ViewChild('inDistPeleLipoma') inDistPeleLipoma: ElementRef;

  // Nódulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('slPlanoNodulo') slPlanoNodulo: MatSelect;
  @ViewChild('slContornoNodulo') slContornoNodulo: MatSelect;
  @ViewChild('slEcotexturaNodulo') slEcotexturaNodulo: MatSelect;
  @ViewChild('slEcogenicidadeNodulo') slEcogenicidadeNodulo: MatSelect;
  @ViewChild('ckDopplerNodulo') ckDopplerNodulo: MatCheckbox;
  @ViewChild('inMedidaNodulo1') inMedidaNodulo1: ElementRef;
  @ViewChild('inMedidaNodulo2') inMedidaNodulo2: ElementRef;
  @ViewChild('inMedidaNodulo3') inMedidaNodulo3: ElementRef;
  @ViewChild('inDistPeleNodulo') inDistPeleNodulo: ElementRef;

  // Cisto
  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('slParedesCisto') slParedesCisto: MatSelect;
  @ViewChild('slConteudoCisto') slConteudoCisto: MatSelect;
  @ViewChild('inMedidaCisto1') inMedidaCisto1: ElementRef;
  @ViewChild('inMedidaCisto2') inMedidaCisto2: ElementRef;
  @ViewChild('inMedidaCisto3') inMedidaCisto3: ElementRef;
  @ViewChild('inDistPeleCisto') inDistPeleCisto: ElementRef;

  // Coleção organizada
  @ViewChild('ckColecaoOrg') ckColecaoOrg: MatCheckbox;
  @ViewChild('slPlanoColecaoOrg') slPlanoColecaoOrg: MatSelect;
  @ViewChild('slParedesColecaoOrg') slParedesColecaoOrg: MatSelect;
  @ViewChild('slConteudoColecaoOrg') slConteudoColecaoOrg: MatSelect;
  @ViewChild('inMedidaColecaoOrg1') inMedidaColecaoOrg1: ElementRef;
  @ViewChild('inMedidaColecaoOrg2') inMedidaColecaoOrg2: ElementRef;
  @ViewChild('inMedidaColecaoOrg3') inMedidaColecaoOrg3: ElementRef;
  @ViewChild('inVolumeColecaoOrg') inVolumeColecaoOrg: ElementRef;
  @ViewChild('inDistPeleColecaoOrg') inDistPeleColecaoOrg: ElementRef;
  @ViewChild('ckSeptosColecaoOrg') ckSeptosColecaoOrg: MatCheckbox;

  // Coleção não organizada
  @ViewChild('ckColecaoNaoOrg') ckColecaoNaoOrg: MatCheckbox;
  @ViewChild('slPlanoColecaoNaoOrg') slPlanoColecaoNaoOrg: MatSelect;
  @ViewChild('inMedidaColecaoNaoOrg1') inMedidaColecaoNaoOrg1: ElementRef;
  @ViewChild('inMedidaColecaoNaoOrg2') inMedidaColecaoNaoOrg2: ElementRef;
  @ViewChild('inMedidaColecaoNaoOrg3') inMedidaColecaoNaoOrg3: ElementRef;
  @ViewChild('inDistPeleColecaoNaoOrg') inDistPeleColecaoNaoOrg: ElementRef;

  // Fibrose
  @ViewChild('ckFibrose') ckFibrose: MatCheckbox;
  @ViewChild('slPlanoFibrose') slPlanoFibrose: MatSelect;
  @ViewChild('slEcogenicidadeFibrose') slEcogenicidadeFibrose: MatSelect;
  @ViewChild('slLimetesFibrose') slLimetesFibrose: MatSelect;
  @ViewChild('slSombraFibrose') slSombraFibrose: MatSelect;
  @ViewChild('inMedidaFibrose1') inMedidaFibrose1: ElementRef;
  @ViewChild('inMedidaFibrose2') inMedidaFibrose2: ElementRef;
  @ViewChild('inMedidaFibrose3') inMedidaFibrose3: ElementRef;
  @ViewChild('inDistPeleFibrose') inDistPeleFibrose: ElementRef;

  // Venolinfática
  @ViewChild('ckVenolinfatica') ckVenolinfatica: MatCheckbox;
  @ViewChild('slPlanoVenolinfatica') slPlanoVenolinfatica: MatSelect;
  @ViewChild('slLoculacaoVenolinfatica') slLoculacaoVenolinfatica: MatSelect;
  @ViewChild('slParedesVenolinfatica') slParedesVenolinfatica: MatSelect;
  @ViewChild('slConteudoVenolinfatica') slConteudoVenolinfatica: MatSelect;
  @ViewChild('slSeptosVenolinfatica') slSeptosVenolinfatica: MatSelect;
  @ViewChild('slFluxoVenolinfatica') slFluxoVenolinfatica: MatSelect;
  @ViewChild('inMedidaVenolinfatica1') inMedidaVenolinfatica1: ElementRef;
  @ViewChild('inMedidaVenolinfatica2') inMedidaVenolinfatica2: ElementRef;
  @ViewChild('inMedidaVenolinfatica3') inMedidaVenolinfatica3: ElementRef;
  @ViewChild('inDistPeleVenolinfatica') inDistPeleVenolinfatica: ElementRef;

  planos: ArrGenerico[] = [
    { value: 'da pele', viewValue: 'Pele' },
    { value: 'subcutâneo', viewValue: 'Subcutâneo' }
  ];

  contornos: ArrGenerico[] = [
    { value: 'bem definidos', viewValue: 'Bem definidos' },
    { value: 'parcialmente definidos', viewValue: 'Parcialmente definidos' },
    { value: 'indefinidos', viewValue: 'Indefinidos' },
    { value: 'irregulares', viewValue: 'Irregulares' }
  ];

  ecotexturas: ArrGenerico[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'heterogênea' }
  ];

  ecogenicidades: ArrGenerico[] = [
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' },
    { value: 'isoecogênica', viewValue: 'Isoecogênica' },
    { value: 'hiperecogênica', viewValue: 'Hiperecogênica' }
  ];

  paredes: ArrGenerico[] = [
    { value: 'finas', viewValue: 'Finas' },
    { value: 'espessas', viewValue: 'Espessas' }
  ];

  conteudos: ArrGenerico[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'hipoecoico/espesso', viewValue: 'Hipoecoico/Espesso' },
    { value: 'heterogêneo', viewValue: 'Heterogêneo' }
  ];

  conteudosVenolinfatico: ArrGenerico[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'hipoecoico', viewValue: 'Hipoecoico' }
  ];

  limites: ArrGenerico[] = [
    { value: 'indefinidos', viewValue: 'Indefinidos' },
    { value: 'parcialmente definidos', viewValue: 'Parcialmente definidos' },
    { value: 'definidos', viewValue: 'Definidos' }
  ];

  sombras: ArrGenerico[] = [
    { value: 'com', viewValue: 'Com' },
    { value: 'sem', viewValue: 'Sem' }
  ];

  loculacoes: ArrGenerico[] = [
    { value: 'uniloculada', viewValue: 'Uniloculada' },
    { value: 'multiloculada', viewValue: 'Multiloculada' }
  ];

  septos: ArrGenerico[] = [
    { value: 'finos', viewValue: 'Finos' },
    { value: 'espessos', viewValue: 'Espessos' }
  ];

  fluxoVascular: ArrGenerico[] = [
    { value: 'sem fluxo vascular', viewValue: 'Sem fluxo vascular' },
    { value: 'com baixo fluxo vascular', viewValue: 'Com baixo fluxo vascular' },
    { value: 'com alto fluxo vascular', viewValue: 'Com alto fluxo vascular' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  volumeColecao = 'Volume';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahLipoma: false,
      sahNodulo: false,
      sahCisto: false,
      sahColecaoOrg: false,
      sahColecaoNaoOrg: false,
      sahFibrose: false,
      sahVenolinfatica: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickEdema() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLipoma() {
    if (this.showAndHideTerms.sahLipoma == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahLipoma = true;
    } else {
      this.showAndHideTerms.sahLipoma = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahNodulo = true;
    } else {
      this.showAndHideTerms.sahNodulo = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickCisto() {
    if (this.showAndHideTerms.sahCisto == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahCisto = true;
    } else {
      this.showAndHideTerms.sahCisto = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickColecaoOrg() {
    if (this.showAndHideTerms.sahColecaoOrg == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahColecaoOrg = true;
    } else {
      this.showAndHideTerms.sahColecaoOrg = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickColecaoNaoOrg() {
    if (this.showAndHideTerms.sahColecaoNaoOrg == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahColecaoNaoOrg = true;
    } else {
      this.showAndHideTerms.sahColecaoNaoOrg = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickFibrose() {
    if (this.showAndHideTerms.sahFibrose == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahFibrose = true;
    } else {
      this.showAndHideTerms.sahFibrose = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickVenolinfatica() {
    if (this.showAndHideTerms.sahVenolinfatica == false) {
      this.rdAlterado.checked = true;
      this.showAndHideTerms.sahVenolinfatica = true;
    } else {
      this.showAndHideTerms.sahVenolinfatica = false;
      this.verificaAlterado();
    }
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

    } else if (this.rdAlterado.checked) {

      // Edema
      if (this.countLesoes > 0) {
        this.emitEdemaLesao();
      } else {
        this.emitEdemaSimples();
      }

      // Limpoma
      if (this.countLesoes > 0) {
        this.emitLipomaLesao();
      } else {
        this.emitLipomaSimples();
      }

      // Nodulo
      if (this.countLesoes > 0) {
        this.emitNoduloLesao();
      } else {
        this.emitNoduloSimples();
      }

      // Cisto
      if (this.countLesoes > 0) {
        this.emitCistoLesao();
      } else {
        this.emitCistoSimples();
      }

      // Coleção organizada
      if (this.countLesoes > 0) {
        this.emitColecaoOrgLesao();
      } else {
        this.emitColecaoOrgSimples();
      }

      // Coleção não organizada
      if (this.countLesoes > 0) {
        this.emitColecaoNaoOrgLesao();
      } else {
        this.emitColecaoNaoOrgSimples();
      }

      // Fibrose
      if (this.countLesoes > 0) {
        this.emitFibroseLesao();
      } else {
        this.emitFibroseSimples();
      }

      // Venolinfatica
      if (this.countLesoes > 0) {
        this.emitVenolinfaticaLesao();
      } else {
        this.emitVenolinfaticaSimples();
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitEdemaSimples() {
    if (this.ckEdema.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEdema.id);
    }
  }

  emitEdemaLesao() {
    if (this.ckEdema.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEdema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitLipomaSimples() {
    if (this.ckLipoma.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLipoma.id);

      if (this.ckContornosDefinido && this.ckContornosDefinido.checked) {
        this.termoSelecionado.emit(this.ckContornosDefinido.id);
      }

      if (this.inDistPeleLipoma !== undefined &&
        this.inDistPeleLipoma.nativeElement.id !== undefined &&
        this.inDistPeleLipoma.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleLipoma.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoLipoma(true);
    }
  }

  emitLipomaLesao() {
    if (this.ckLipoma.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckLipoma.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckContornosDefinido !== undefined && this.ckContornosDefinido.checked) {
        const objeto = { id: this.ckContornosDefinido.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.inDistPeleLipoma !== undefined &&
        this.inDistPeleLipoma.nativeElement.id !== undefined &&
        this.inDistPeleLipoma.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleLipoma.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoLipoma(false);
    }
  }

  emitNoduloSimples() {
    if (this.ckNodulo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);

      if (this.slPlanoNodulo != undefined && this.slPlanoNodulo.value != undefined) {
        const objeto = { elemento: this.slPlanoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoNodulo != undefined && this.slContornoNodulo.value != undefined) {
        const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaNodulo != undefined && this.slEcotexturaNodulo.value != undefined) {
        const objeto = { elemento: this.slEcotexturaNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcogenicidadeNodulo != undefined && this.slEcogenicidadeNodulo.value != undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcogenicidadeNodulo != undefined && this.slEcogenicidadeNodulo.value != undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckDopplerNodulo.checked) {
        this.termoSelecionado.emit(this.ckDopplerNodulo.id);
      }

      this.formataMedindoNodulo(true);

      if (this.inDistPeleNodulo !== undefined &&
        this.inDistPeleNodulo.nativeElement.id !== undefined &&
        this.inDistPeleNodulo.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleNodulo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitNoduloLesao() {
    if (this.ckNodulo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slPlanoNodulo != undefined && this.slPlanoNodulo.value != undefined) {
        const objeto = { elemento: this.slPlanoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoNodulo != undefined && this.slContornoNodulo.value != undefined) {
        const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcotexturaNodulo != undefined && this.slEcotexturaNodulo.value != undefined) {
        const objeto = { elemento: this.slEcotexturaNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcogenicidadeNodulo != undefined && this.slEcogenicidadeNodulo.value != undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcogenicidadeNodulo != undefined && this.slEcogenicidadeNodulo.value != undefined) {
        const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckDopplerNodulo.checked) {
        const objeto = { id: this.ckDopplerNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      this.formataMedindoNodulo(false);

      if (this.inDistPeleNodulo !== undefined &&
        this.inDistPeleNodulo.nativeElement.id !== undefined &&
        this.inDistPeleNodulo.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleNodulo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCistoSimples() {
    if (this.ckCisto.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCisto.id);

      if (this.slParedesCisto != undefined && this.slParedesCisto.value != undefined) {
        const objeto = { elemento: this.slParedesCisto, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoCisto != undefined && this.slConteudoCisto.value != undefined) {
        const objeto = { elemento: this.slConteudoCisto, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoCisto(true);

      if (this.inDistPeleCisto !== undefined &&
        this.inDistPeleCisto.nativeElement.id !== undefined &&
        this.inDistPeleCisto.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleCisto.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCistoLesao() {
    if (this.ckCisto.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slParedesCisto != undefined && this.slParedesCisto.value != undefined) {
        const objeto = { elemento: this.slParedesCisto, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoCisto != undefined && this.slConteudoCisto.value != undefined) {
        const objeto = { elemento: this.slConteudoCisto, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoCisto(false);

      if (this.inDistPeleCisto !== undefined &&
        this.inDistPeleCisto.nativeElement.id !== undefined &&
        this.inDistPeleCisto.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleCisto.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitColecaoOrgSimples() {
    if (this.ckColecaoOrg.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckColecaoOrg.id);

      if (this.slPlanoColecaoOrg != undefined && this.slPlanoColecaoOrg.value != undefined) {
        const objeto = { elemento: this.slPlanoColecaoOrg, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesColecaoOrg != undefined && this.slParedesColecaoOrg.value != undefined) {
        const objeto = { elemento: this.slParedesColecaoOrg, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoColecaoOrg != undefined && this.slConteudoColecaoOrg.value != undefined) {
        const objeto = { elemento: this.slConteudoColecaoOrg, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoColecaoOrg(true);

      if (this.inVolumeColecaoOrg !== undefined &&
        this.inVolumeColecaoOrg.nativeElement.id !== undefined) {

        // Volume coleção
        let elemento;
        if (!this.inVolumeColecaoOrg.nativeElement.value && this.volumeColecao !== 'Volume') {
          elemento = { id: 900, value: this.volumeColecao, cenario: this.countLesoes };
        } else {
          elemento = { id: 900, value: this.inVolumeColecaoOrg.nativeElement.value, cenario: this.countLesoes };
        }
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleColecaoOrg !== undefined &&
        this.inDistPeleColecaoOrg.nativeElement.value != undefined) {
        const objeto = { elemento: this.inDistPeleColecaoOrg.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckSeptosColecaoOrg && this.ckSeptosColecaoOrg.checked) {
        this.termoSelecionado.emit(this.ckSeptosColecaoOrg.id);
      }
    }
  }

  emitColecaoOrgLesao() {
    if (this.ckColecaoOrg.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckColecaoOrg.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slPlanoColecaoOrg != undefined && this.slPlanoColecaoOrg.value != undefined) {
        const objeto = { elemento: this.slPlanoColecaoOrg, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesColecaoOrg != undefined && this.slParedesColecaoOrg.value != undefined) {
        const objeto = { elemento: this.slParedesColecaoOrg, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoColecaoOrg != undefined && this.slConteudoColecaoOrg.value != undefined) {
        const objeto = { elemento: this.slConteudoColecaoOrg, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoColecaoOrg(false);

      if (this.inVolumeColecaoOrg !== undefined &&
        this.inVolumeColecaoOrg.nativeElement.id !== undefined) {

        // Volume coleção
        let elemento;
        if (!this.inVolumeColecaoOrg.nativeElement.value && this.volumeColecao !== 'Volume') {
          elemento = { id: 900, value: this.volumeColecao, cenario: this.countLesoes };
        } else {
          elemento = { id: 900, value: this.inVolumeColecaoOrg.nativeElement.value, cenario: this.countLesoes };
        }
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.inDistPeleColecaoOrg !== undefined &&
        this.inDistPeleColecaoOrg.nativeElement.value !== undefined) {

        const objeto = { elemento: this.inDistPeleColecaoOrg.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckSeptosColecaoOrg  && this.ckSeptosColecaoOrg.checked) {
        const objeto = { id: this.ckSeptosColecaoOrg.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    }
  }

  emitColecaoNaoOrgSimples() {
    if (this.ckColecaoNaoOrg.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckColecaoNaoOrg.id);

      if (this.slPlanoColecaoNaoOrg != undefined && this.slPlanoColecaoNaoOrg.value != undefined) {
        const objeto = { elemento: this.slPlanoColecaoNaoOrg, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoColecaoNaoOrg(true);

      if (this.inDistPeleColecaoNaoOrg !== undefined &&
        this.inDistPeleColecaoNaoOrg.nativeElement.id !== undefined &&
        this.inDistPeleColecaoNaoOrg.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleColecaoNaoOrg.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitColecaoNaoOrgLesao() {
    if (this.ckColecaoNaoOrg.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckColecaoNaoOrg.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slPlanoColecaoNaoOrg != undefined && this.slPlanoColecaoNaoOrg.value != undefined) {
        const objeto = { elemento: this.slPlanoColecaoNaoOrg, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoColecaoNaoOrg(false);

      if (this.inDistPeleColecaoNaoOrg !== undefined &&
        this.inDistPeleColecaoNaoOrg.nativeElement.id !== undefined &&
        this.inDistPeleColecaoNaoOrg.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleColecaoNaoOrg.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFibroseSimples() {
    if (this.ckFibrose.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFibrose.id);

      if (this.slPlanoFibrose != undefined && this.slPlanoFibrose.value != undefined) {
        const objeto = { elemento: this.slPlanoFibrose, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcogenicidadeFibrose != undefined && this.slEcogenicidadeFibrose.value != undefined) {
        const objeto = { elemento: this.slEcogenicidadeFibrose, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLimetesFibrose != undefined && this.slLimetesFibrose.value != undefined) {
        const objeto = { elemento: this.slLimetesFibrose, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSombraFibrose != undefined && this.slSombraFibrose.value != undefined) {
        const objeto = { elemento: this.slSombraFibrose, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFibrose(true);

      if (this.inDistPeleFibrose !== undefined &&
        this.inDistPeleFibrose.nativeElement.id !== undefined &&
        this.inDistPeleFibrose.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleFibrose.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFibroseLesao() {
    if (this.ckFibrose.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFibrose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slPlanoFibrose != undefined && this.slPlanoFibrose.value != undefined) {
        const objeto = { elemento: this.slPlanoFibrose, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slEcogenicidadeFibrose != undefined && this.slEcogenicidadeFibrose.value != undefined) {
        const objeto = { elemento: this.slEcogenicidadeFibrose, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLimetesFibrose != undefined && this.slLimetesFibrose.value != undefined) {
        const objeto = { elemento: this.slLimetesFibrose, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSombraFibrose != undefined && this.slSombraFibrose.value != undefined) {
        const objeto = { elemento: this.slSombraFibrose, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFibrose(false);

      if (this.inDistPeleFibrose !== undefined &&
        this.inDistPeleFibrose.nativeElement.id !== undefined &&
        this.inDistPeleFibrose.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleFibrose.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVenolinfaticaSimples() {
    if (this.ckVenolinfatica.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckVenolinfatica.id);

      if (this.slPlanoVenolinfatica != undefined && this.slPlanoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slPlanoVenolinfatica, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoculacaoVenolinfatica != undefined && this.slLoculacaoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slLoculacaoVenolinfatica, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesVenolinfatica != undefined && this.slParedesVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slParedesVenolinfatica, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoVenolinfatica != undefined && this.slConteudoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slConteudoVenolinfatica, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSeptosVenolinfatica != undefined && this.slSeptosVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slSeptosVenolinfatica, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slFluxoVenolinfatica != undefined && this.slFluxoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slFluxoVenolinfatica, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoVenolinfatica(true);

      if (this.inDistPeleVenolinfatica !== undefined &&
        this.inDistPeleVenolinfatica.nativeElement.id !== undefined &&
        this.inDistPeleVenolinfatica.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleVenolinfatica.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVenolinfaticaLesao() {
    if (this.ckVenolinfatica.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckVenolinfatica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slPlanoVenolinfatica != undefined && this.slPlanoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slPlanoVenolinfatica, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoculacaoVenolinfatica != undefined && this.slLoculacaoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slLoculacaoVenolinfatica, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesVenolinfatica != undefined && this.slParedesVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slParedesVenolinfatica, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudoVenolinfatica != undefined && this.slConteudoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slConteudoVenolinfatica, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSeptosVenolinfatica != undefined && this.slSeptosVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slSeptosVenolinfatica, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slFluxoVenolinfatica != undefined && this.slFluxoVenolinfatica.value != undefined) {
        const objeto = { elemento: this.slFluxoVenolinfatica, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoVenolinfatica(false);

      if (this.inDistPeleVenolinfatica !== undefined &&
        this.inDistPeleVenolinfatica.nativeElement.id !== undefined &&
        this.inDistPeleVenolinfatica.nativeElement.value != '') {

        const objeto = { elemento: this.inDistPeleVenolinfatica.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  formataMedindoLipoma(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaLipoma1 != undefined &&
      this.inMedidaLipoma1.nativeElement.id != undefined &&
      this.inMedidaLipoma1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLipoma1.nativeElement.value);
    }

    if (this.inMedidaLipoma2 != undefined &&
      this.inMedidaLipoma2.nativeElement.id != undefined &&
      this.inMedidaLipoma2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLipoma2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLipoma2.nativeElement.value);
      }
    }

    if (this.inMedidaLipoma3 != undefined &&
      this.inMedidaLipoma3.nativeElement.id != undefined &&
      this.inMedidaLipoma3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLipoma3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLipoma3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 880, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoNodulo(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaNodulo1 != undefined &&
      this.inMedidaNodulo1.nativeElement.id != undefined &&
      this.inMedidaNodulo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo1.nativeElement.value);
    }

    if (this.inMedidaNodulo2 != undefined &&
      this.inMedidaNodulo2.nativeElement.id != undefined &&
      this.inMedidaNodulo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      }
    }

    if (this.inMedidaNodulo3 != undefined &&
      this.inMedidaNodulo3.nativeElement.id != undefined &&
      this.inMedidaNodulo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 887, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoCisto(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaCisto1 != undefined &&
      this.inMedidaCisto1.nativeElement.id != undefined &&
      this.inMedidaCisto1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaCisto1.nativeElement.value);
    }

    if (this.inMedidaCisto2 != undefined &&
      this.inMedidaCisto2.nativeElement.id != undefined &&
      this.inMedidaCisto2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCisto2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCisto2.nativeElement.value);
      }
    }

    if (this.inMedidaCisto3 != undefined &&
      this.inMedidaCisto3.nativeElement.id != undefined &&
      this.inMedidaCisto3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCisto3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCisto3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 893, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoColecaoOrg(isPrimeiraLesao) {
    let medindoValor = '';
    let resultado = 0;


    if (this.inMedidaColecaoOrg1 != undefined &&
      this.inMedidaColecaoOrg1.nativeElement.id != undefined &&
      this.inMedidaColecaoOrg1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoOrg1.nativeElement.value);
      resultado = this.inMedidaColecaoOrg1.nativeElement.value;

    }

    if (this.inMedidaColecaoOrg2 != undefined &&
      this.inMedidaColecaoOrg2.nativeElement.id != undefined &&
      this.inMedidaColecaoOrg2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoOrg2.nativeElement.value);
        resultado = resultado * this.inMedidaColecaoOrg2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoOrg2.nativeElement.value);
        resultado = this.inMedidaColecaoOrg2.nativeElement.value;
      }
    }

    if (this.inMedidaColecaoOrg3 != undefined &&
      this.inMedidaColecaoOrg3.nativeElement.id != undefined &&
      this.inMedidaColecaoOrg3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoOrg3.nativeElement.value);
        resultado = resultado * this.inMedidaColecaoOrg3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoOrg3.nativeElement.value);
        resultado = this.inMedidaColecaoOrg3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume coleção
      resultado = resultado * 0.523;
      this.volumeColecao = resultado.toFixed(1).toString();

      const objetoElemento = { id: 899, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoColecaoNaoOrg(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaColecaoNaoOrg1 != undefined &&
      this.inMedidaColecaoNaoOrg1.nativeElement.id != undefined &&
      this.inMedidaColecaoNaoOrg1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg1.nativeElement.value);
    }

    if (this.inMedidaColecaoNaoOrg2 != undefined &&
      this.inMedidaColecaoNaoOrg2.nativeElement.id != undefined &&
      this.inMedidaColecaoNaoOrg2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg2.nativeElement.value);
      }
    }

    if (this.inMedidaColecaoNaoOrg3 != undefined &&
      this.inMedidaColecaoNaoOrg3.nativeElement.id != undefined &&
      this.inMedidaColecaoNaoOrg3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaColecaoNaoOrg3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 905, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoFibrose(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaFibrose1 != undefined &&
      this.inMedidaFibrose1.nativeElement.id != undefined &&
      this.inMedidaFibrose1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaFibrose1.nativeElement.value);
    }

    if (this.inMedidaFibrose2 != undefined &&
      this.inMedidaFibrose2.nativeElement.id != undefined &&
      this.inMedidaFibrose2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaFibrose2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaFibrose2.nativeElement.value);
      }
    }

    if (this.inMedidaFibrose3 != undefined &&
      this.inMedidaFibrose3.nativeElement.id != undefined &&
      this.inMedidaFibrose3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaFibrose3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaFibrose3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 912, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoVenolinfatica(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaVenolinfatica1 != undefined &&
      this.inMedidaVenolinfatica1.nativeElement.id != undefined &&
      this.inMedidaVenolinfatica1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaVenolinfatica1.nativeElement.value);
    }

    if (this.inMedidaVenolinfatica2 != undefined &&
      this.inMedidaVenolinfatica2.nativeElement.id != undefined &&
      this.inMedidaVenolinfatica2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaVenolinfatica2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaVenolinfatica2.nativeElement.value);
      }
    }

    if (this.inMedidaVenolinfatica3 != undefined &&
      this.inMedidaVenolinfatica3.nativeElement.id != undefined &&
      this.inMedidaVenolinfatica3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaVenolinfatica3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaVenolinfatica3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 920, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  verificaAlterado() {
    if (!this.ckEdema.checked && !this.ckLipoma.checked && !this.ckNodulo.checked &&
      !this.ckCisto.checked && !this.ckColecaoOrg.checked && !this.ckColecaoNaoOrg.checked &&
      !this.ckFibrose.checked && !this.ckVenolinfatica.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }

}
