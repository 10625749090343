import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-superficies-osseas',
  templateUrl: './superficies-osseas.component.html',
  styleUrls: ['./superficies-osseas.component.css']
})
export class SuperficiesOsseasComponent implements OnInit {


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.value);
    }
    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }    
    this.listaCompleta.emit();
  }
}
