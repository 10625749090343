import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-cordao-umbilical-gemelar',
  templateUrl: './cordao-umbilical-gemelar.component.html',
  styleUrls: ['./cordao-umbilical-gemelar.component.css']
})
export class CordaoUmbilicalGemelarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdArteria') rdArteria: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('ckInsercaoPlacentaria') ckInsercaoPlacentaria: MatCheckbox;
  @ViewChild('slInsercaoCordao') slInsercaoCordao: MatSelect;
  @ViewChild('rdNormal2') rdNormal2: MatRadioButton;
  @ViewChild('rdArteria2') rdArteria2: MatRadioButton;
  @ViewChild('rdNaoCitar2') rdNaoCitar2: MatRadioButton;
  @ViewChild('ckInsercaoPlacentaria2') ckInsercaoPlacentaria2: MatCheckbox;
  @ViewChild('slInsercaoCordao2') slInsercaoCordao2: MatSelect;
  @ViewChild('rdNormal3') rdNormal3: MatRadioButton;
  @ViewChild('rdArteria3') rdArteria3: MatRadioButton;
  @ViewChild('rdNaoCitar3') rdNaoCitar3: MatRadioButton;
  @ViewChild('ckInsercaoPlacentaria3') ckInsercaoPlacentaria3: MatCheckbox;
  @ViewChild('slInsercaoCordao3') slInsercaoCordao3: MatSelect;
  @ViewChild('rdNorma4l') rdNormal4: MatRadioButton;
  @ViewChild('rdArteria4') rdArteria4: MatRadioButton;
  @ViewChild('rdNaoCitar4') rdNaoCitar4: MatRadioButton;
  @ViewChild('ckInsercaoPlacentaria4') ckInsercaoPlacentaria4: MatCheckbox;
  @ViewChild('slInsercaoCordao4') slInsercaoCordao4: MatSelect;

  showAndHideTerms: any;
  fetoSelected: number = 1;
  showAndHideFetos: any;


  cordaoUmbilical: ArrayDropDown[] = [
    { value: 'central', viewValue: 'Central' },
    { value: 'paracentral', viewValue: 'Paracentral' },
    { value: 'marginal', viewValue: 'Marginal' },
    { value: 'velamentosa', viewValue: 'Velamentosa' }
  ];

  constructor(private _common: CommonService) { }

  ngOnInit() {
    this._common.posicaoFeto.subscribe((feto: any) => {
      this.fetoSelected = feto;
      this.showAndHideFetos = {
        feto1: feto === 1,
        feto2: feto === 2,
        feto3: feto === 3,
        feto4: feto === 4,
      };
    });

    this.showAndHideFetos = {
      feto1: true,
      feto2: false,
      feto3: false,
      feto4: false,
    };
    
    this.showAndHideTerms = {
      sahCkInsercaoCordUmb: true,
      sahInsercaoCordaoUmbilical: false,
      sahCkInsercaoCordUmb2: true,
      sahInsercaoCordaoUmbilical2: false,
      sahCkInsercaoCordUmb3: true,
      sahInsercaoCordaoUmbilical3: false,
      sahCkInsercaoCordUmb4: true,
      sahInsercaoCordaoUmbilical4: false,
    }
  }

  clickNormal(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical = false;
    this.showAndHideTerms.sahCkInsercaoCordUmb = false;
    this.ckInsercaoPlacentaria.checked = false;
    this.catchIDs();
  }

  clickInsercaoCordao(){
    this.showAndHideTerms.sahCkInsercaoCordUmb = true;
    this.catchIDs();
  }

  clickPlacentaria(){
    if(this.showAndHideTerms.sahInsercaoCordaoUmbilical == true){
      this.showAndHideTerms.sahInsercaoCordaoUmbilical = false;
    }
    else{
      this.showAndHideTerms.sahInsercaoCordaoUmbilical = true;
    }
    this.catchIDs();
  }

  clickNormal2(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical2 = false;
    this.showAndHideTerms.sahCkInsercaoCordUmb2 = false;
    this.ckInsercaoPlacentaria2.checked = false;
    this.catchIDs();
  }

  clickInsercaoCordao2(){
    this.showAndHideTerms.sahCkInsercaoCordUmb2 = true;
    this.catchIDs();
  }

  clickPlacentaria2(){
    if(this.showAndHideTerms.sahInsercaoCordaoUmbilical2 == true){
      this.showAndHideTerms.sahInsercaoCordaoUmbilical2 = false;
    }
    else{
      this.showAndHideTerms.sahInsercaoCordaoUmbilical2 = true;
    }
    this.catchIDs();
  }

  clickNormal3(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical3 = false;
    this.showAndHideTerms.sahCkInsercaoCordUmb3 = false;
    this.ckInsercaoPlacentaria3.checked = false;
    this.catchIDs();
  }

  clickInsercaoCordao3(){
    this.showAndHideTerms.sahCkInsercaoCordUmb3 = true;
    this.catchIDs();
  }

  clickPlacentaria3(){
    if(this.showAndHideTerms.sahInsercaoCordaoUmbilical3 == true){
      this.showAndHideTerms.sahInsercaoCordaoUmbilical3 = false;
    }
    else{
      this.showAndHideTerms.sahInsercaoCordaoUmbilical3 = true;
    }
    this.catchIDs();
  }

  clickNormal4(){
    this.showAndHideTerms.sahInsercaoCordaoUmbilical4 = false;
    this.showAndHideTerms.sahCkInsercaoCordUmb4 = false;
    this.ckInsercaoPlacentaria4.checked = false;
    this.catchIDs();
  }

  clickInsercaoCordao4(){
    this.showAndHideTerms.sahCkInsercaoCordUmb4 = true;
    this.catchIDs();
  }

  clickPlacentaria4(){
    if(this.showAndHideTerms.sahInsercaoCordaoUmbilical4 == true){
      this.showAndHideTerms.sahInsercaoCordaoUmbilical4 = false;
    }
    else{
      this.showAndHideTerms.sahInsercaoCordaoUmbilical4 = true;
    }
    this.catchIDs();
  }

  changeSelect(){
    this.showAndHideTerms.sahCkInsercaoCordUmb = true;
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.fetoSelected === 1) {
      if (this.rdNormal && this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
      }

      if (this.rdArteria && this.rdArteria.checked) {
        this.termoSelecionado.emit(this.rdArteria.id);
      }

      if (this.rdNaoCitar && this.rdNaoCitar.checked) {
        this.termoSelecionado.emit(this.rdNaoCitar.id);
      }

      if (this.slInsercaoCordao && this.slInsercaoCordao.value &&
        this.ckInsercaoPlacentaria.checked && !this.rdNaoCitar.checked) {
        const objeto = { elemento: this.slInsercaoCordao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    } else if (this.fetoSelected === 2) {
      if (this.rdNormal2 && this.rdNormal2.checked) {
        this.termoSelecionado.emit(this.rdNormal2.id);
      }

      if (this.rdArteria2 && this.rdArteria2.checked) {
        this.termoSelecionado.emit(this.rdArteria2.id);
      }

      if (this.rdNaoCitar2 && this.rdNaoCitar2.checked) {
        this.termoSelecionado.emit(this.rdNaoCitar2.id);
      }

      if (this.slInsercaoCordao2 && this.slInsercaoCordao2.value &&
        this.ckInsercaoPlacentaria2.checked && !this.rdNaoCitar2.checked) {
        const objeto = { elemento: this.slInsercaoCordao2, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    } else if (this.fetoSelected === 3) {
      if (this.rdNormal3 && this.rdNormal3.checked) {
        this.termoSelecionado.emit(this.rdNormal3.id);
      }

      if (this.rdArteria3 && this.rdArteria3.checked) {
        this.termoSelecionado.emit(this.rdArteria3.id);
      }

      if (this.rdNaoCitar3 && this.rdNaoCitar3.checked) {
        this.termoSelecionado.emit(this.rdNaoCitar3.id);
      }

      if (this.slInsercaoCordao3 && this.slInsercaoCordao3.value &&
        this.ckInsercaoPlacentaria3.checked && !this.rdNaoCitar3.checked) {
        const objeto = { elemento: this.slInsercaoCordao3, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    } else if (this.fetoSelected === 4) {
      if (this.rdNormal4 && this.rdNormal4.checked) {
        this.termoSelecionado.emit(this.rdNormal4.id);
      }

      if (this.rdArteria4 && this.rdArteria4.checked) {
        this.termoSelecionado.emit(this.rdArteria4.id);
      }

      if (this.rdNaoCitar4 && this.rdNaoCitar4.checked) {
        this.termoSelecionado.emit(this.rdNaoCitar4.id);
      }

      if (this.slInsercaoCordao4 && this.slInsercaoCordao4.value &&
        this.ckInsercaoPlacentaria4.checked && !this.rdNaoCitar4.checked) {
        const objeto = { elemento: this.slInsercaoCordao4, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    }
    this.listaCompleta.emit();
  }

}
