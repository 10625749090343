import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-abd-bexiga',
  templateUrl: './abd-bexiga.component.html',
  styleUrls: ['./abd-bexiga.component.css']
})
export class AbdBexigaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEspessada') rdEspessada: MatRadioButton;
  @ViewChild('rdParcial') rdParcial: MatRadioButton;
  @ViewChild('rdEsforco') rdEsforco: MatRadioButton;
  @ViewChild('rdSemReplecao') rdSemReplecao: MatRadioButton;

  @ViewChild('ckBalaoSonda') ckBalaoSonda: MatCheckbox;
  @ViewChild('ckDebris') ckDebris: MatCheckbox;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahSemReplecao: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickEpessada() {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickParcial() {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickEsforco() {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickSemReplecao() {
    this.showAndHideTerms.sahSemReplecao = false;
    this.catchIDs();
  }

  clickBalaoSonda() {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  clickDebris() {
    this.showAndHideTerms.sahSemReplecao = true;
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.ckBalaoSonda && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdEspessada.checked) {
      this.termoSelecionado.emit(this.rdEspessada.id);

      if (this.ckBalaoSonda && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdParcial.checked) {
      this.termoSelecionado.emit(this.rdParcial.id);

      if (this.ckBalaoSonda && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdEsforco.checked) {
      this.termoSelecionado.emit(this.rdEsforco.id);

      if (this.ckBalaoSonda && this.ckBalaoSonda.checked) {
        this.termoSelecionado.emit(this.ckBalaoSonda.id);
      }

      if (this.ckDebris && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }
    }

    if (this.rdSemReplecao.checked) {
      this.termoSelecionado.emit(this.rdSemReplecao.id);
    }

    this.listaCompleta.emit();
  }

}
