import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-integration',
  template: '<h2> Aguarde... <h2>'
})
export class IntegrationComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit() {
    this.authService.loggedIn.next(false);
    this.route.paramMap.subscribe(params => {
      this.verifyToken(params)
    });
  }

  verifyToken(params: any) {
    this.authService.checkReportToken(params.get('token'), params.get('userid')).subscribe(
      (integracao) => {
        if (integracao) {
          const objUser = {
            Email: integracao.login,
            Senha: integracao.partnerToken
          }
          this.authService.loginEmail(objUser).subscribe(
            (usuario) => {
              if (usuario.authenticated) {
                localStorage.setItem('token', usuario.accessToken);
                localStorage.setItem('userID', usuario.userID.toString());
                localStorage.setItem('companyID', usuario.companyID.toString());
                localStorage.setItem('postbackURL', integracao.postbackURL.toString());
                this.authService.loggedIn.next(true);
                this.authService.integrationRoute.next(false);
                this.router.navigate([`/laudos/${params.get('laudo')}`]);
              } else {
                this.authService.loggedIn.next(false);
                this.router.navigate([`/login`]);
              }
            });
        }
      },
      erro => {
        this.authService.loggedIn.next(false);
        this.router.navigate([`/login`]);
      }
    )
  }
}
