import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagamento-dialog',
  templateUrl: './pagamento-dialog.component.html',
  styleUrls: ['./pagamento-dialog.component.css']
})
export class PagamentoDialogComponent implements OnInit {
  nome : string = '';

  constructor(
    public dialogRef: MatDialogRef<PagamentoDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit() {
    this.nome = localStorage.getItem('nomeUsuario');
  }

  clickContinuar(): void {
    this.router.navigate(['/alteraPagamento']);
    this.dialogRef.close();
  }
}
