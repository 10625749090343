import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-processo-transverso',
  templateUrl: './processo-transverso.component.html',
  styleUrls: ['./processo-transverso.component.css']
})
export class ProcessoTransversoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdFraturaUnicaDireita') rdFraturaUnicaDireita: MatRadioButton;
  @ViewChild('rdFraturaMultiplaDireita') rdFraturaMultiplaDireita: MatRadioButton;
  @ViewChild('rdFraturaUnicaEsquerda') rdFraturaUnicaEsquerda: MatRadioButton;
  @ViewChild('rdFraturaMultiplaEsquerda') rdFraturaMultiplaEsquerda: MatRadioButton;
  @ViewChild('rdFraturaMultiplaBilateral') rdFraturaMultiplaBilateral: MatRadioButton;

  @ViewChild('slNivelFraturaUnicaDireita') slNivelFraturaUnicaDireita : MatSelect;
  @ViewChild('slNivelFraturaMultiplaDireita') slNivelFraturaMultiplaDireita : MatSelect;
  @ViewChild('slNivelFraturaUnicaEsquerda') slNivelFraturaUnicaEsquerda : MatSelect;
  @ViewChild('slNivelFraturaMultiplaEsquerda') slNivelFraturaMultiplaEsquerda : MatSelect;

  showAndHideTerms;
  countLesoes = 0;

  nivelFraturaEsquerda: ArrayDropDown[]=[
    { value: ' C7', viewValue: 'C7' },
    { value: ' T1', viewValue: 'T1' },
    { value: ' T2', viewValue: 'T2' },
    { value: ' T3', viewValue: 'T3' },
    { value: ' T4', viewValue: 'T4' },
    { value: ' T5', viewValue: 'T5' },
    { value: ' T6', viewValue: 'T6' },
    { value: ' T7', viewValue: 'T7' },
    { value: ' T8', viewValue: 'T8' },
    { value: ' T9', viewValue: 'T9' },
    { value: ' T10', viewValue: 'T10' },
    { value: ' T11', viewValue: 'T11' },
    { value: ' T12', viewValue: 'T12' },
    { value: ' L1', viewValue: 'L1' }]

    nivelFraturaDireita: ArrayDropDown[]=[
      { value: ' C7', viewValue: 'C7' },
      { value: ' T1', viewValue: 'T1' },
      { value: ' T2', viewValue: 'T2' },
      { value: ' T3', viewValue: 'T3' },
      { value: ' T4', viewValue: 'T4' },
      { value: ' T5', viewValue: 'T5' },
      { value: ' T6', viewValue: 'T6' },
      { value: ' T7', viewValue: 'T7' },
      { value: ' T8', viewValue: 'T8' },
      { value: ' T9', viewValue: 'T9' },
      { value: ' T10', viewValue: 'T10' },
      { value: ' T11', viewValue: 'T11' },
      { value: ' T12', viewValue: 'T12' },
      { value: ' L1', viewValue: 'L1' }]

  constructor() { }

  ngOnInit() {
      this.showAndHideTerms =[
      {id:'5084', show:false},
      {id:'5085', show:false},
      {id:'5087', show:false},
      {id:'5086', show:false},
      {id:'5088', show:false},
      {id:'5089', show:false}
      ]
    }

  clickDefault(event){
    this.showAndHideTerms.forEach(element => {
       if(element.id===event.value)element.show=true;
       else element.show=false;
    })

    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if(this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if(this.countLesoes > 0){
      this.emitFraturaDireitaLesao();
    } else {
      this.emitFraturaDireitaSimples();
    }

    if(this.countLesoes > 0){
      this.emitFraturaEsquerdaLesao();
    } else {
      this.emitFraturaEsquerdaSimples();
    }

    if(this.countLesoes > 0){
      this.emitFraturaBilateralLesao();
    } else {
      this.emitFraturaBilateralSimples();
    }

    this.listaCompleta.emit();
  }

  emitFraturaBilateralSimples() {
    if(this.rdFraturaMultiplaBilateral.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaMultiplaBilateral.id);

      if(this.slNivelFraturaMultiplaDireita && this.slNivelFraturaMultiplaDireita.value){
        if(this.slNivelFraturaMultiplaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }else if(this.slNivelFraturaMultiplaDireita.value.length==1){
          const objeto = {elemento: this.slNivelFraturaMultiplaDireita, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.slNivelFraturaMultiplaEsquerda && this.slNivelFraturaMultiplaEsquerda.value){
        if(this.slNivelFraturaMultiplaEsquerda.value.length>1){

          let objetoMultiplo= { id:this.slNivelFraturaMultiplaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaEsquerda.value.length==1){
          const objeto = {elemento: this.slNivelFraturaMultiplaEsquerda, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitFraturaBilateralLesao() {
    if(this.rdFraturaMultiplaBilateral.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaMultiplaBilateral.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelFraturaMultiplaDireita && this.slNivelFraturaMultiplaDireita.value){
        if(this.slNivelFraturaMultiplaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaDireita.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaDireita, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }

      if(this.slNivelFraturaMultiplaEsquerda && this.slNivelFraturaMultiplaEsquerda.value){
        if(this.slNivelFraturaMultiplaEsquerda.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaEsquerda.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaEsquerda, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }
    }
  }

  emitFraturaEsquerdaSimples() {
    if(this.rdFraturaMultiplaEsquerda.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaMultiplaEsquerda.id);

      if(this.slNivelFraturaMultiplaEsquerda && this.slNivelFraturaMultiplaEsquerda.value ){
        if(this.slNivelFraturaMultiplaEsquerda.value.length>1){

          let objetoMultiplo= { id:this.slNivelFraturaMultiplaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaEsquerda.value.length==1){
          const objeto = {elemento: this.slNivelFraturaMultiplaEsquerda, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
      }
    }else if(this.rdFraturaUnicaEsquerda.checked){
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdFraturaUnicaEsquerda.id);

        if(this.slNivelFraturaUnicaEsquerda && this.slNivelFraturaUnicaEsquerda.value){
          const objeto = {elemento: this.slNivelFraturaUnicaEsquerda, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);
        }
    }
  }

  emitFraturaEsquerdaLesao() {
    if(this.rdFraturaMultiplaEsquerda.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaMultiplaEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelFraturaMultiplaEsquerda && this.slNivelFraturaMultiplaEsquerda.value){
        if(this.slNivelFraturaMultiplaEsquerda.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaEsquerda.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaEsquerda.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaEsquerda.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaEsquerda, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }
    }else if(this.rdFraturaUnicaEsquerda.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaUnicaEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelFraturaUnicaEsquerda && this.slNivelFraturaUnicaEsquerda.value){
        const objeto = {elemento: this.slNivelFraturaUnicaEsquerda, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFraturaDireitaSimples() {
    if(this.rdFraturaMultiplaDireita.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaMultiplaDireita.id);

      if(this.slNivelFraturaMultiplaDireita && this.slNivelFraturaMultiplaDireita.value){
        if(this.slNivelFraturaMultiplaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaDireita.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaDireita, isPrimeiraLesao: true}
          this.termosGenericos.emit(objeto);

        }
      }
    }else if(this.rdFraturaUnicaDireita.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdFraturaUnicaDireita.id);

      if(this.slNivelFraturaUnicaDireita && this.slNivelFraturaUnicaDireita.value){
        const objeto = {elemento: this.slNivelFraturaUnicaDireita, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitFraturaDireitaLesao() {
    if(this.rdFraturaMultiplaDireita.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaMultiplaDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelFraturaMultiplaDireita && this.slNivelFraturaMultiplaDireita.value){
        if(this.slNivelFraturaMultiplaDireita.value.length>1){
          let objetoMultiplo= { id:this.slNivelFraturaMultiplaDireita.id, value: this.replaceVirgulaMultSelect(this.slNivelFraturaMultiplaDireita.value.toString())};
          const objeto = {elemento: objetoMultiplo, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }else if(this.slNivelFraturaMultiplaDireita.value.length==1){

          const objeto = {elemento: this.slNivelFraturaMultiplaDireita, isPrimeiraLesao: false}
          this.termosGenericos.emit(objeto);

        }
      }
    }else if(this.rdFraturaUnicaDireita.checked){
      this.countLesoes++;
      const objeto = { id: this.rdFraturaUnicaDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slNivelFraturaUnicaDireita && this.slNivelFraturaUnicaDireita.value){
        const objeto = {elemento: this.slNivelFraturaUnicaDireita, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  replaceVirgulaMultSelect(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
