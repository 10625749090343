import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface LadoMama {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-implante-mamario',
  templateUrl: './implante-mamario.component.html',
  styleUrls: ['./implante-mamario.component.css']
})
export class ImplanteMamarioComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckRotura') ckRotura: MatCheckbox;
  @ViewChild('rdRoturaIntracapsular') rdRoturaIntracapsular: MatRadioButton;
  @ViewChild('rdRoturaExtracapsular') rdRoturaExtracapsular: MatRadioButton;

  @ViewChild('slLadoMama') slLadoMama: MatSelect;

  ladosMama: LadoMama[] = [
    {value: 'direita', viewValue: 'Direita'},
    {value: 'esquerda', viewValue: 'Esquerda'}
  ];

  showAndHideTerms: any;
  Presente: any;

   constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: true,
      sahRotura: false
    };

    this.Presente = false;
  }


  clickAusente() {
    this.Presente = false;
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahRotura  = false;
    this.catchIDs();
  }

  clickPresente() {
    this.Presente = true;
    this.showAndHideTerms.sahAusente = true;
    this.showAndHideTerms.sahRotura  = false;
    this.catchIDs();
  }

  clickRotura() {
    if (this.showAndHideTerms.sahRotura == false) {
      this.showAndHideTerms.sahRotura = true;
      this.Presente = true;
    } else {
      this.showAndHideTerms.sahRotura = false;
    }
    this.catchIDs();
  }

  clickRoturaIntracapsular() {
    this.Presente = true;
    this.catchIDs();
  }

  clickRoturaExtracapsular() {
    this.Presente = true;
    this.catchIDs();
  }

  changeLadoMama() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.ckRotura !== undefined  && this.ckRotura.checked) {

        if (this.rdRoturaIntracapsular !== undefined  && this.rdRoturaIntracapsular.checked) {
          this.termoSelecionado.emit(this.rdRoturaIntracapsular.id);
        }

        if (this.rdRoturaExtracapsular !== undefined  && this.rdRoturaExtracapsular.checked) {
          this.termoSelecionado.emit(this.rdRoturaExtracapsular.id);
        }

        if (this.slLadoMama !== undefined && this.slLadoMama.value !== undefined) {
          const objeto = {elemento: this.slLadoMama, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }
}
