import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-placenta-gemelar',
  templateUrl: './placenta-gemelar.component.html',
  styleUrls: ['./placenta-gemelar.component.css']
})
export class PlacentaGemelarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdInsercaoBaixa') rdInsercaoBaixa: MatRadioButton;
  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slGrau') slGrau: MatSelect;

  @ViewChild('rdNormal2') rdNormal2: MatRadioButton;
  @ViewChild('rdInsercaoBaixa2') rdInsercaoBaixa2: MatRadioButton;
  @ViewChild('inEspessura2') inEspessura2: ElementRef;
  @ViewChild('slLocalizacao2') slLocalizacao2: MatSelect;
  @ViewChild('slGrau2') slGrau2: MatSelect;

  @ViewChild('rdNormal3') rdNormal3: MatRadioButton;
  @ViewChild('rdInsercaoBaixa3') rdInsercaoBaixa3: MatRadioButton;
  @ViewChild('inEspessura3') inEspessura3: ElementRef;
  @ViewChild('slLocalizacao3') slLocalizacao3: MatSelect;
  @ViewChild('slGrau3') slGrau3: MatSelect;

  @ViewChild('rdNormal4') rdNormal4: MatRadioButton;
  @ViewChild('rdInsercaoBaixa4') rdInsercaoBaixa4: MatRadioButton;
  @ViewChild('inEspessura4') inEspessura4: ElementRef;
  @ViewChild('slLocalizacao4') slLocalizacao4: MatSelect;
  @ViewChild('slGrau4') slGrau4: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'fúndica', viewValue: 'Fúndica' },
    { value: 'lateral direita', viewValue: 'Lateral direita' },
    { value: 'lateral esquerda', viewValue: 'Lateral esquerda' }
  ];

  graus: ArrayDropDown[] = [
    { value: '0', viewValue: '0' },
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' }
  ];

  showMedida = false;
  inGrauInvalid: boolean = true;
  inGrauInvalid2: boolean = true;
  inGrauInvalid3: boolean = true;
  inGrauInvalid4: boolean = true;
  inLocalizacaoInvalid: boolean = true;
  inLocalizacaoInvalid2: boolean = true;
  inLocalizacaoInvalid3: boolean = true;
  inLocalizacaoInvalid4: boolean = true;
  fetoSelected: number = 1;
  showAndHideFetos: any;


  constructor(private _common: CommonService) { }

  ngOnInit() {
    debugger;
    this.showAndHideFetos = {
      feto1: true,
      feto2: false,
      feto3: false,
      feto4: false,
    };

    this._common.posicaoFeto.subscribe((feto: any) => {
      this.fetoSelected = feto;
      this.showAndHideFetos = {
        feto1: feto === 1,
        feto2: feto === 2,
        feto3: feto === 3,
        feto4: feto === 4,
      };
    });


  }

  blurParametros(){
    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();

  }
  clickInsercao(){
    this.catchIDs();
  }

  clickDefault2(){
    this.catchIDs();

  }
  clickInsercao2(){
    this.catchIDs();
  }

  clickDefault3(){
    this.catchIDs();

  }
  clickInsercao3(){
    this.catchIDs();
  }

  clickDefault4(){
    this.catchIDs();

  }
  clickInsercao4(){
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs() {
    debugger;
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if (this.fetoSelected === 1) {
      if (this.rdNormal && this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
      }

      if (this.rdInsercaoBaixa && this.rdInsercaoBaixa.checked) {
        this.termoSelecionado.emit(this.rdInsercaoBaixa.id);
      }

      if (this.slLocalizacao && this.slLocalizacao.value) {
        let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slGrau && this.slGrau.value) {
        let objeto = { elemento: this.slGrau, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura && this.inEspessura.nativeElement.value) {
        let objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      //this.listaCompleta.emit();

      if ((this.slLocalizacao && this.slLocalizacao.value) && (this.slGrau && this.slGrau.value)) {
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'Placenta' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'Placenta' });
      }
    } else if (this.fetoSelected === 2) {
      if (this.rdNormal2 && this.rdNormal2.checked) {
        this.termoSelecionado.emit(this.rdNormal2.id);
      }

      if (this.rdInsercaoBaixa2 && this.rdInsercaoBaixa2.checked) {
        this.termoSelecionado.emit(this.rdInsercaoBaixa2.id);
      }

      if (this.slLocalizacao2 && this.slLocalizacao2.value) {
        let objeto = { elemento: this.slLocalizacao2, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slGrau2 && this.slGrau2.value) {
        let objeto = { elemento: this.slGrau2, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura2 && this.inEspessura2.nativeElement.value) {
        let objeto = { elemento: this.inEspessura2.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      //this.listaCompleta.emit();

      if ((this.slLocalizacao2 && this.slLocalizacao2.value) && (this.slGrau2 && this.slGrau2.value)) {
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'Placenta' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'Placenta' });
      }

    } else if (this.fetoSelected === 3) {
      if (this.rdNormal3 && this.rdNormal3.checked) {
        this.termoSelecionado.emit(this.rdNormal3.id);
      }

      if (this.rdInsercaoBaixa3 && this.rdInsercaoBaixa3.checked) {
        this.termoSelecionado.emit(this.rdInsercaoBaixa3.id);
      }

      if (this.slLocalizacao3 && this.slLocalizacao3.value) {
        let objeto = { elemento: this.slLocalizacao3, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slGrau3 && this.slGrau3.value) {
        let objeto = { elemento: this.slGrau3, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura3 && this.inEspessura3.nativeElement.value) {
        let objeto = { elemento: this.inEspessura3.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      //this.listaCompleta.emit();

      if ((this.slLocalizacao3 && this.slLocalizacao3.value) && (this.slGrau3 && this.slGrau3.value)) {
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'Placenta' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'Placenta' });
      }

    } else if (this.fetoSelected === 4) {
      if (this.rdNormal4 && this.rdNormal4.checked) {
        this.termoSelecionado.emit(this.rdNormal4.id);
      }

      if (this.rdInsercaoBaixa4 && this.rdInsercaoBaixa4.checked) {
        this.termoSelecionado.emit(this.rdInsercaoBaixa4.id);
      }

      if (this.slLocalizacao4 && this.slLocalizacao4.value) {
        let objeto = { elemento: this.slLocalizacao4, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slGrau4 && this.slGrau4.value) {
        let objeto = { elemento: this.slGrau4, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inEspessura4 && this.inEspessura4.nativeElement.value) {
        let objeto = { elemento: this.inEspessura4.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      //this.listaCompleta.emit();

      if ((this.slLocalizacao4 && this.slLocalizacao4.value) && (this.slGrau4 && this.slGrau4.value)) {
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'Placenta' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'Placenta' });
      }

    }

    this.listaCompleta.emit();

  }

  removeInvalidField() {

    if (this.fetoSelected === 1) {
      if (this.slLocalizacao && this.slLocalizacao.value) {
        this.inLocalizacaoInvalid = false;
      } else {
        this.inLocalizacaoInvalid = true;
      }
      if (this.slGrau && this.slGrau.value) {
        this.inGrauInvalid = false;
      } else {
        this.inGrauInvalid = true;
      }
    } else if (this.fetoSelected === 2) {
      if (this.slLocalizacao2 && this.slLocalizacao2.value) {
        this.inLocalizacaoInvalid2 = false;
      } else {
        this.inLocalizacaoInvalid2 = true;
      }
      if (this.slGrau2 && this.slGrau2.value) {
        this.inGrauInvalid2 = false;
      } else {
        this.inGrauInvalid2= true;
      }
    } else if (this.fetoSelected === 3) {
      if (this.slLocalizacao3 && this.slLocalizacao3.value) {
        this.inLocalizacaoInvalid3 = false;
      } else {
        this.inLocalizacaoInvalid3 = true;
      }
      if (this.slGrau3 && this.slGrau3.value) {
        this.inGrauInvalid3 = false;
      } else {
        this.inGrauInvalid3 = true;
      }
    } else if (this.fetoSelected === 4) {
      if (this.slLocalizacao4 && this.slLocalizacao4.value) {
        this.inLocalizacaoInvalid4 = false;
      } else {
        this.inLocalizacaoInvalid4 = true;
      }
      if (this.slGrau4 && this.slGrau4.value) {
        this.inGrauInvalid4 = false;
      } else {
        this.inGrauInvalid4 = true;
      }
    }

  }

}
