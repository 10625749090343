import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from '../../../../models/BO/TermResult';
import { SelectedItemEmiter } from '../../../../utils/SelectedItemEmiter';
import { LaudosService } from '../../../../services/laudos.service.spec';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validacoes } from '../../../../utils/validacoes';
import { CopyPaste } from '../../../../utils/textEditor/CopyPaste';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { AddText, IAtualizaLinha } from '../../../../utils/addText';
import { TextEditorJsComponent } from 'src/app/utils/text-editor-js/text-editor-js.component';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { UploadService } from 'src/app/services/upload.service';
import { Console } from 'console';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { DataTermService } from 'src/app/services/dataTerm.service';

export interface TermosGenericos {
  value: string;
  selected: boolean;
  term: string;
  termID: number;
  linha: number;
  cenario: number;
  posicao: number;
  lesionID: number;
}

@Component({
  selector: 'app-prostata',
  templateUrl: './prostata.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./prostata.component.css']
})
export class ProstataComponent implements OnInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;
  fileUpload;

  listTermDefault=[
    {listTerm:[680],blockID:33},
    {listTerm:[687],blockID:34},
    {listTerm:[703],blockID:35},
    {listTerm:[705],blockID:36},
    {listTerm:[4169],blockID:37}
  ]
  descricoes: DescricaoConclusao[] = [
    { value: 'Bexiga com adequada repleção, paredes regulares e finas. O conteúdo vesical é anecoico e homogêneo.', blockID: 33, textTermID: 0 },
    {
      value: 'Próstata de contornos regulares e ecotextura característica.</br>' +
        'Próstata mede cm, com volume estimado de cm.', blockID: 34, textTermID: 0
    },
    { value: 'Vesículas seminais simétricas, de ecotextura habitual.', blockID: 35, textTermID: 0 },
    { value: '', blockID: 36, textTermID: 0 },
    { value: 'Resíduo pós miccional estimado em ml.', blockID: 37, textTermID: 0, }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: 'Exame dentro dos padrões da normalidade.', blockID: 0, textTermID: 0 },
    { value: '', blockID: 33, textTermID: 0 },
    { value: '', blockID: 34, textTermID: 0 },
    { value: '', blockID: 35, textTermID: 0 },
    { value: '', blockID: 36, textTermID: 0 },
    { value: '', blockID: 37, textTermID: 0 }
  ];

  genericTermsProstata: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 689, termID: 690, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
    { value: '', selected: false, lesionID: 689, termID: 691, cenario: 0, posicao: 0, linha: 0, term: '[Volume]' },
    { value: '', selected: false, lesionID: 689, termID: 693, cenario: 0, posicao: 0, linha: 0, term: '[Índice de protrusão]' }
  ];

  genericTermsVolume: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 706, termID: 707, cenario: 0, posicao: 0, linha: 0, term: '[Volume do pré-miccional]' }
  ];

  genericTermsResiduo: TermosGenericos[] = [
    { value: '', selected: false, lesionID: 0, termID: 708, cenario: 0, posicao: 0, linha: 0, term: '[Volume do resíduo-pós]' },
    { value: '', selected: false, lesionID: 0, termID: 709, cenario: 0, posicao: 0, linha: 0, term: '[Volume do resíduo-pós maior]' }
  ];

  htmlContent: string;
  Subtitulo: string;
  subtituloBloco: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  lesionRow = 1;
  isPrimeiroVolumeMiccional = true;
  cabecalhoTexto = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  rodapeTexto = '';

  bexiga: boolean = true;
  prostata: boolean = false;
  vesiculasSeminais: boolean = false;
  volumeMiccional: boolean = false;
  residuoMiccional: boolean = false;
  indicacao: boolean = false;
  achadosTexto: boolean = false;
  cabecalho: boolean = false;
  rodape: boolean = false;

  medicalReportTypeID = 5;
  bexigaID = 33;
  prostataID = 34;
  vesiculasSeminaisID = 35;
  volumeMiccionalID = 36;
  residuoMiccionalID = 37;
  public showForm: Boolean = true;
  public laudoCompleto: any = [];
  public switchFormReport = true;
  public newEditor: boolean = false;
  public imageInsert: any = undefined;
  unidadeMedida: string;
  editLaudoMode: boolean = false;

  constructor(
    public _selected: SelectedItemEmiter,
    private _laudoService: LaudosService,
    private _toastr: ToastrManager,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _serviceMedicalReport: MedicalReportService,
    private _copyPaste: CopyPaste,
    private _dialog: MatDialog,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    public _addText: AddText,
    private _config: ConfigLaudoService,
    private _http: HttpClient,
    private dataService:DataTermService,
    private _upload: UploadService,
    private _domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this._pouchService.loadDatabase("prostata", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Bexiga';
    this.subtituloBloco = 'Bexiga';

    //const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });

    const listBlocks = [this.bexigaID,
    this.prostataID,
    this.vesiculasSeminaisID,
    this.volumeMiccionalID,
    this.residuoMiccionalID];

    this._pouchService.loadMask(this.medicalReportTypeID, "prostataMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;

      this.AdicionaTexto(this.newEditor)
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));
      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "prostata");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'prostata');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'prostata');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public carregaDescricaoConclusao() {
    this._pouchService.loadDatabase("prostataAnalise", this.descricoes, this.conclusoes);
  }

  selectImage(file) {
    this.fileUpload = file;
  }

  upload() {
    this._upload.uploadImageReport(this.fileUpload).subscribe(res => {
      this.imageInsert = this._domSanitizer.bypassSecurityTrustUrl(res.baseImage);
    }, error => {
      this.imageInsert = error;
      console.log(error);
    });
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("prostata").then((obj) => {
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo: 'Preencha o campo indicação',
      selected: false,
    },
    {
      titulo: 'Bexiga',
      id: this.bexigaID,
      name: 'bexiga',
      selected: true,
    },
    {
      titulo: 'Próstata',
      id: this.prostataID,
      name: 'prostata',
      selected: false,
    },
    {
      titulo: 'Vesiculas seminais',
      id: this.vesiculasSeminaisID,
      name: 'vesiculas-seminais',
      selected: false,
    },
    {
      titulo: 'Volume pré-miccional',
      id: this.volumeMiccionalID,
      name: 'volume-miccional',
      selected: false,
    },
    {
      titulo: 'Resíduo pós-miccional',
      id: this.residuoMiccionalID,
      name: 'residuo-miccional',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados_adicionais',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false,
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.bexiga = (itemSelecionado.id === this.bexigaID);
    this.prostata = (itemSelecionado.id === this.prostataID);
    this.vesiculasSeminais = (itemSelecionado.id === this.vesiculasSeminaisID);
    this.volumeMiccional = (itemSelecionado.id === this.volumeMiccionalID);
    this.residuoMiccional = (itemSelecionado.id === this.residuoMiccionalID);
    this.achadosTexto = (itemSelecionado.id === 'achados_adicionais');
    this.rodape = (itemSelecionado.id === 'rodape');

    if (this.volumeMiccional) {
      if (this.isPrimeiroVolumeMiccional) {
        this.enviaTermos('Clean');
        this.enviaTermos(705);
        this.consultarAPI();
        this.isPrimeiroVolumeMiccional = false;
      }
    }
    this.AdicionaTexto(this.newEditor);
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.lesionRow = 1;

    this.genericTermsProstata = [
      { value: '', selected: false, lesionID: 689, termID: 690, cenario: 0, posicao: 0, linha: 0, term: '[Medida]' },
      { value: '', selected: false, lesionID: 689, termID: 691, cenario: 0, posicao: 0, linha: 0, term: '[Volume]' },
      { value: '', selected: false, lesionID: 689, termID: 693, cenario: 0, posicao: 0, linha: 0, term: '[Índice de protrusão]' }
    ];

    this.genericTermsVolume = [
      { value: '', selected: false, lesionID: 706, termID: 707, cenario: 0, posicao: 0, linha: 0, term: '[Volume do pré-miccional]' }
    ];

    this.genericTermsResiduo = [
      { value: '', selected: false, lesionID: 0, termID: 708, cenario: 0, posicao: 0, linha: 0, term: '[Volume do resíduo-pós]' },
      { value: '', selected: false, lesionID: 0, termID: 709, cenario: 0, posicao: 0, linha: 0, term: '[Volume do resíduo-pós maior]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        indicacao: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><b>Achados adicionais:</b><br/>' + texto + '<br/>';
    else
      this.achadosAdTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoProstata($event) {
    this.genericTermsProstata.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoVolume($event) {
    this.genericTermsVolume.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoResiduo($event) {
    this.genericTermsResiduo.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.bexiga) {
        this.term.blockID = this.bexigaID;
        this._pouchService.getLaudos(this.term).then((result) => {
          //Realizar busca no local
        });
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.bexigaID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.bexigaID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.bexigaID,
            textTermID: 0
          };

          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;

          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.bexigaID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.bexigaID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.bexigaID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.prostata) {
        this.term.blockID = this.prostataID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.prostataID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoProstata(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.prostataID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.prostataID,
            textTermID: 0
          };

          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;

          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoProstata(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.prostataID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll.length === 0 || conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.prostataID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.prostataID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.vesiculasSeminais) {
        this.term.blockID = this.vesiculasSeminaisID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.vesiculasSeminaisID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = resultado.text;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.vesiculasSeminaisID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.vesiculasSeminaisID,
            textTermID: 0
          };

          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;

          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.vesiculasSeminaisID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.vesiculasSeminaisID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.vesiculasSeminaisID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.volumeMiccional) {
        this.term.blockID = this.volumeMiccionalID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.volumeMiccionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoVolume(resultado.text);
                desc = obj.value;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.volumeMiccionalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.volumeMiccionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.volumeMiccionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll.length === 0 || conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.volumeMiccionalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.volumeMiccionalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });

      } else if (this.residuoMiccional) {
        this.term.blockID = this.residuoMiccionalID;


        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.residuoMiccionalID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = this.MontaDescricaoResiduo(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.residuoMiccionalID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.residuoMiccionalID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = this.MontaConclusaoResiduo(resultado.conclusion);
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.residuoMiccionalID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll.length === 0 || conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.residuoMiccionalID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.residuoMiccionalID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoProstata(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 690 || +term === 691 || +term === 693) {
        this.genericTermsProstata.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  MontaConclusaoProstata(texto) {
    debugger;
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 693 || +term === 691) {
        this.genericTermsProstata.forEach(generic => {
          if (+generic.termID === +term) {
            if (+generic.termID === 693) {
              const valor = parseFloat(generic.value.replace(',', '.'));
              if (valor < 0.5) {
                texto = texto.replace('[X]', '1');
              } else if (valor >= 0.5 && valor < 1) {
                texto = texto.replace('[X]', '2');
              } else {
                texto = texto.replace('[X]', '3');
              }
            }

            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoVolume(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 707) {
        this.genericTermsVolume.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoResiduo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 708 || +term === 709) {
        this.genericTermsResiduo.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoResiduo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 709) {
        this.genericTermsResiduo.forEach(generic => {
          if (+generic.termID === +term) {
            let volume = '';
            const valor = parseInt(generic.value, 10);

            if (valor >= 30 && valor < 80) {
              volume = 'pequeno';
            } else if (valor >= 80 && valor < 150) {
              volume = 'moderado';
            } else if (valor >= 150 && valor < 300) {
              volume = 'acentuado';
            } else {
              volume = 'muito acentuado';
            }

            generic.selected = true;
            textoRetorno = texto.replace('[X]', volume);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }


  AdicionaTexto(isNewEditor: boolean) {
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    console.log(maskSplit);

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'data': {
          'text': '<p><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></p>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-${descricao.blockID}`,
          'name': ''
        },
        'data': {
          'text': `<p>${descricao.value}</p>`,
        }
      });
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao, index) => {
      if (conclusao.value && this.descricoes[index].value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    // this.laudoCompleto.push({
    //   'type': 'paragraph',
    //   'data': {
    //     'text': `<p>${maskSplit[4]}</p>`,
    //   }
    // });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados_adicionais`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|')

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += '<br/><br/><div style="text-align: center;"><b>ULTRASSONOGRAFIA DE PRÓSTATA VIA ABDOMINAL</b></div>';
    } else {
      this.htmlContent = '<br/><br/><div style="text-align: center;"><b>ULTRASSONOGRAFIA DE PRÓSTATA VIA ABDOMINAL</b></div>';
    }

    this.htmlContent += '<br/>';
    this.htmlContent += this.indicacaoTexto + '<br />';
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += maskSplit[1];
    this.htmlContent += 'Exame realizado via suprapúbica.';
    this.htmlContent += '<br/>';

    for (i = 0; i < this.descricoes.length; i++) {
      var newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      var newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].value !== '') {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += '<br/>';
    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }
    // this.htmlContent += '<br/>';
    // this.htmlContent += 'Observação: a avaliação do parênquima prostático é limitado pela via suprapúbica.';
    // this.htmlContent += '<br/>';

    this.htmlContent += this.achadosAdTexto;

    this.htmlContent += '<br/>';
    this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.descricoes.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if (cabecalho !== undefined) {
          let header = document.querySelector(`#e-${blockId}`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {
          let footer = document.querySelector(`#e-${blockId}`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }
      } else if (indicacao !== undefined || achados !== undefined) {
        if (indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Estruturas analisadas sem alterações significativas.';

    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }
}
