import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatDialog, MatSelect} from '@angular/material';
import {LesoesEsquerdaComponent} from '../dialog/lesoes-esquerda/lesoes-esquerda.component';
import {LesoesCarotidas} from '../../../../../../models/Helper/LesoesCarotidas';
import {Observable, of} from 'rxjs';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-carotida-esquerda',
  templateUrl: './carotida-esquerda.component.html',
  styleUrls: ['./carotida-esquerda.component.css']
})
export class CarotidaEsquerdaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() adicionaLesaoArray = new EventEmitter();
  @Output() removeLesoes = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  // CMI
  @ViewChild('ckNormalCmi') ckNormalCmi: MatCheckbox;
  @ViewChild('ckEspessamentoCmi') ckEspessamentoCmi: MatCheckbox;
  @ViewChild('ckNaoCaracterizadoCmi') ckNaoCaracterizadoCmi: MatCheckbox;
  @ViewChild('inCmiEsquerdo') inCmiEsquerdo: ElementRef;

  // Medidas
  @ViewChild('ACCSitEsquerda') ACCSitEsquerda: ElementRef;
  @ViewChild('ACCDistEsquerda') ACCDistEsquerda: ElementRef;
  @ViewChild('ACISistEsquerda') ACISistEsquerda: ElementRef;
  @ViewChild('ACIDistEsquerda') ACIDistEsquerda: ElementRef;
  @ViewChild('ckVelocidadeAce') ckVelocidadeAce: MatCheckbox;
  @ViewChild('ACESistEsquerda') ACESistEsquerda: ElementRef;
  @ViewChild('ACEDistEsquerda') ACEDistEsquerda: ElementRef;
  @ViewChild('ckVelocidadeVert') ckVelocidadeVert: MatCheckbox;
  @ViewChild('VertebralSistEsquerda') VertebralSistEsquerda: ElementRef;
  @ViewChild('VertebralDistEsquerda') VertebralDistEsquerda: ElementRef;

  // Vertebral
  @ViewChild('ckNormalVert') ckNormalVert: MatCheckbox;
  @ViewChild('ckNaoCaracterizadoVert') ckNaoCaracterizadoVert: MatCheckbox;
  @ViewChild('ckRouboVert') ckRouboVert: MatCheckbox;
  @ViewChild('slGraduacao') slGraduacao: MatSelect;

  graduacoes: ArrayDropDown[] = [
    {value: 'desaceleração sistólica', viewValue: 'Latente'},
    {value: 'inversão sistólica', viewValue: 'Parcial'},
    {value: 'inversão sisto-diastólica', viewValue: 'Total'}
  ];

  lesoes: LesoesCarotidas[];
  lesoes$: Observable<LesoesCarotidas[]>;

  showAndHideTerms: any;
  countLesoes = 0;
  countListaLesoes = 0;
  isItemExcluido = true;
  unidadeMedida: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    localStorage.removeItem('objLesoesEsquerda');
    this.showAndHideTerms = {
      sahCmiMedida: true,
      sahRouboVert: false,
      sahVelocidade: true,
      sahVelocidadeAce: false,
      sahVelocidadeVert: false
    };

    this.lesoes = [];

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormalCmi() {
    this.showAndHideTerms.sahCmiMedida = true;

    this.catchIDs();
  }

  clickEspessamentoCmi() {
    this.showAndHideTerms.sahCmiMedida = true;

    this.catchIDs();
  }

  clickNaoCaractCmi() {
    this.showAndHideTerms.sahCmiMedida = false;
    this.catchIDs();
  }

  clickNormalVert() {
    this.showAndHideTerms.sahRouboVert = false;
    this.showAndHideTerms.sahVelocidade = true;
    this.catchIDs();
  }

  clickNaoCaractVert() {
    this.showAndHideTerms.sahRouboVert = false;
    this.showAndHideTerms.sahVelocidade = false;
    this.showAndHideTerms.sahVelocidadeVert = false;
    this.catchIDs();
  }

  clickRouboVer() {
    this.showAndHideTerms.sahRouboVert = true;
    this.showAndHideTerms.sahVelocidade = false;
    this.showAndHideTerms.sahVelocidadeVert = false;
    this.catchIDs();
  }

  clickVelocidadeAce() {
    if (this.showAndHideTerms.sahVelocidadeAce === false) {
      this.showAndHideTerms.sahVelocidadeAce = true;
    } else {
      this.showAndHideTerms.sahVelocidadeAce = false;
    }
    this.catchIDs();
  }

  clickVelocidadeVert() {
    if (this.showAndHideTerms.sahVelocidadeVert === false) {
      this.showAndHideTerms.sahVelocidadeVert = true;
    } else {
      this.showAndHideTerms.sahVelocidadeVert = false;
    }
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  dialogLesoes() {
    const dialogRef = this.dialog.open(LesoesEsquerdaComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      //this.catchIDs();
      this.termoSelecionado.emit('Clean');
      this.countLesoes = 0;
      this.verificaLesao();
    });
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    // CMI
    this.emitNormalCmi();
    this.emitEspessamentoCmi();
    this.emitNaoCaracterizadoCmi();

    // Vertebral
    this.emitNormalVertebral();
    this.emitNaoCaractVertebral();
    this.emitRouboVertebral();

    // Busca lesões
    this.verificaLesao();
    
    // Medidas
    this.vericaMedidas();
  }

  emitNormalCmi() {
    if (this.ckNormalCmi !== undefined && this.ckNormalCmi.checked) {
      //this.countLesoes++;
      this.termoSelecionado.emit(1091);
      this.termoSelecionado.emit(this.ckNormalCmi.id);

      if (this.inCmiEsquerdo != undefined && this.inCmiEsquerdo.nativeElement.value) {

        const objeto = {elemento: this.inCmiEsquerdo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
      this.listaCompleta.emit(59); // CMI Esquerdo
      this.termoSelecionado.emit('CleanList');
    }
  }

  emitEspessamentoCmi() {
    if (this.ckEspessamentoCmi !== undefined && this.ckEspessamentoCmi.checked) {
      //this.countLesoes++;
      this.termoSelecionado.emit(1091);
      this.termoSelecionado.emit(this.ckEspessamentoCmi.id);

      if (this.inCmiEsquerdo != undefined && this.inCmiEsquerdo.nativeElement.value) {

        const objeto = {elemento: this.inCmiEsquerdo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
      this.listaCompleta.emit(59); // CMI Esquerdo
      this.termoSelecionado.emit('CleanList');
    }
  }

  emitNaoCaracterizadoCmi() {
    if (this.ckNaoCaracterizadoCmi !== undefined && this.ckNaoCaracterizadoCmi.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(1091);
      this.termoSelecionado.emit(this.ckNaoCaracterizadoCmi.id);

      this.listaCompleta.emit(59); // CMI Esquerdo
      this.termoSelecionado.emit('CleanList');
    }
  }

  emitNormalVertebral() {
    if (this.ckNormalVert !== undefined && this.ckNormalVert.checked === true) {
      //this.countLesoes++;
      this.termoSelecionado.emit(1096);
      this.termoSelecionado.emit(this.ckNormalVert.id);

      this.listaCompleta.emit(61); // Vertebral Esquerdo
    }
  }

  emitNaoCaractVertebral() {
    if (this.ckNaoCaracterizadoVert !== undefined && this.ckNaoCaracterizadoVert.checked === true) {
      //this.countLesoes++;
      this.termoSelecionado.emit(1096);
      this.termoSelecionado.emit(this.ckNaoCaracterizadoVert.id);

      this.listaCompleta.emit(61); // Vertebral Esquerdo
    }
  }

  emitRouboVertebral() {
    if (this.ckRouboVert !== undefined && this.ckRouboVert.checked === true) {
      //this.countLesoes++;
      this.termoSelecionado.emit(1096);
      this.termoSelecionado.emit(this.ckRouboVert.id);

      if (this.slGraduacao != undefined && this.slGraduacao.value != undefined) {
        const objeto = {elemento: this.slGraduacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
      this.listaCompleta.emit(61); // Vertebral Esquerdo
    }
  }

  vericaMedidas() {
    if (this.ACCSitEsquerda !== undefined &&
      this.ACCSitEsquerda.nativeElement.value) {

      const element = {value: this.ACCSitEsquerda.nativeElement.value, id: this.ACCSitEsquerda.nativeElement.id }
      this.enviaMedida.emit(element);
    } else if (!this.ACCSitEsquerda.nativeElement.value) {
      const element = {value: 0, id: this.ACCSitEsquerda.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ACCDistEsquerda !== undefined &&
      this.ACCDistEsquerda.nativeElement.value) {

      const element = {value: this.ACCDistEsquerda.nativeElement.value, id: this.ACCDistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ACCDistEsquerda.nativeElement.value) {
      const element = {value: 0, id: this.ACCDistEsquerda.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ACISistEsquerda !== undefined &&
      this.ACISistEsquerda.nativeElement.value) {

      const element = {value: this.ACISistEsquerda.nativeElement.value, id: this.ACISistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ACISistEsquerda.nativeElement.value) {
      const element = {value: 0, id: this.ACISistEsquerda.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ACIDistEsquerda !== undefined &&
      this.ACIDistEsquerda.nativeElement.value) {

      const element = {value: this.ACIDistEsquerda.nativeElement.value, id: this.ACIDistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ACIDistEsquerda.nativeElement.value) {
      const element = {value: 0, id: this.ACIDistEsquerda.nativeElement.id }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeAce.checked && this.ACESistEsquerda !== undefined &&
      this.ACESistEsquerda.nativeElement.value) {

      const element = {value: this.ACESistEsquerda.nativeElement.value, id: this.ACESistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeAce.checked || !this.ACESistEsquerda.nativeElement.value) {
      const element = {value: 0, id: "ACESistEsquerda" }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeAce.checked && this.ACEDistEsquerda !== undefined &&
      this.ACEDistEsquerda.nativeElement.value) {

      const element = {value: this.ACEDistEsquerda.nativeElement.value, id: this.ACEDistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeAce.checked || !this.ACEDistEsquerda.nativeElement.value) {
      const element = {value: 0, id: "ACEDistEsquerda" }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeVert.checked && this.VertebralSistEsquerda !== undefined &&
      this.VertebralSistEsquerda.nativeElement.value) {

      const element = {value: this.VertebralSistEsquerda.nativeElement.value, id: this.VertebralSistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeVert.checked || !this.VertebralSistEsquerda.nativeElement.value) {
      const element = {value: 0, id: "VertebralSistEsquerda" }
      this.enviaMedida.emit(element);
    }

    if (this.ckVelocidadeVert.checked && this.VertebralDistEsquerda !== undefined &&
      this.VertebralDistEsquerda.nativeElement.value) {

      const element = {value: this.VertebralDistEsquerda.nativeElement.value, id: this.VertebralDistEsquerda.nativeElement.id}
      this.enviaMedida.emit(element);
    } else if (!this.ckVelocidadeVert.checked || !this.VertebralDistEsquerda.nativeElement.value) {
      const element = {value: 0, id: "VertebralDistEsquerda" }
      this.enviaMedida.emit(element);
    }
  }

  verificaLesao() {
    const objLesao = JSON.parse(localStorage.getItem('objLesoesEsquerda'));
    localStorage.removeItem('objLesoesEsquerda');

    if (objLesao) {
      // Adiciona obj a lista
      this.lesoes.push(objLesao);
    }

    if (this.lesoes.length > 0 && this.lesoes.length > this.countListaLesoes
      || this.lesoes.length > 0 && this.isItemExcluido === true) {
      this.countListaLesoes++;
      this.isItemExcluido = false;

      // Percorre a lista de lesoes
      for (let i = 0; i < this.lesoes.length; i++) {
        const obj = this.lesoes[i];
        obj.position = this.countLesoes;

        const objeto = { id: obj.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        obj.element.forEach(objElemnt => {
          const objeto = { elemento: objElemnt, isPrimeiraLesao: false, posicao: this.countLesoes };
          this.termosGenericos.emit(objeto);

          const objetoEnv = { id: objElemnt.id, value: objElemnt.value, posicao: this.countLesoes };
          this.adicionaLesaoArray.emit(objetoEnv);
        });
        // Envia os resultados
        this.listaCompleta.emit(obj.blockID);

        // Adiciona lista a tela
        this.lesoes$ = of(this.lesoes);
        // Adiciona o count da lesão
        this.countLesoes++;
        this.countLesoes++;
      }
    } else if (this.lesoes.length === 0) {
      this.removeLesoes.emit('esquerda');
    }
  }

  removeLesao(event) {
    const index = this.lesoes.findIndex(x => x.position === +event);
    if (index > -1) {
      this.lesoes.splice(index, 1);
    }

    //  Ajusta o count da lista
    this.countListaLesoes = this.lesoes.length;
    this.isItemExcluido = true;

    this.catchIDs();
  }
}
