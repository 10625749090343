import { Injectable } from "@angular/core";
import { Plano } from "../models/BO/Plano";

@Injectable({
    providedIn: 'root'
})

export class User {
    id: number;
    Name: string;
    Senha: string;
    Email: string;
    profileID: Number;
    CompanyID: Number;
    DataNascimento: Date;
    RG: string;
    CPF: string;
    CRM: string;
    SUS: number;
    accessToken: string;
    Endereco: string;
    CEP: number;
    _endID: number;
    Logradouro: string;
    Numero: number;
    Bairro: string;
    Cidade: string;
    Estado: string;
    Complemento: string;
    ddiFoneFixo: number;
    dddFoneFixo: number;
    Telefone: string;
    foneFixo: number;
    ddiFoneCel: number;
    dddFoneCel: number;
    Phone: string;
    Celular: string;
    PasswordToken: string;
    trialEnd: number;
    payment: boolean;
    PlanList: Array<Plano>
    Pais: string;
    Country: string;
    Number: string;
    City: string;
    State: string;
    ZipCode: string;
    updatedUser?: boolean;
    confirmationCode: number;
    emailConfirmed: boolean;
    ComoConheceu: string;
    Profissao: string;
    ProfissaoText: string;
    Clinica: String;
    Ip: string;
    Coordinates: String;
    Browser: String;
    profession: string;
    Token?: string;
    name: string;

    getUser() {
        return localStorage.getItem('usuario');
    }

    getUserId() {
        this.id = parseInt(localStorage.getItem('usuarioID'));
        if (this.id > 0) {
            return this.id;
        } else {
            return 0;
        }
    }
    getUserCPF() {
        this.CPF = localStorage.getItem('usuarioCPF');
        if (this.CPF !== null || this.CPF !== '') {
            return this.CPF;
        } else {
            return 'sem cpf em localStorage';
        }
    }

    setUserId(id: any) {
        localStorage.setItem('usuarioID', id);
    }

    setUserCPF(cpf: any) {
        localStorage.setItem('usuarioCPF', cpf);
    }

    getUserID() {
        return this.id = parseInt(localStorage.getItem('userID'));
    }

    getUserToken() {
        return this.accessToken = localStorage.getItem('token');
    }

    getUserCompanyID() {
        return this.CompanyID = +localStorage.getItem('companyID');
    }

    setDataLocalStorage(_usuario) {
        localStorage.setItem('usuario', JSON.stringify(_usuario));
        localStorage.setItem('usuarioID', _usuario.id);
        localStorage.setItem('usuarioCPF', _usuario.cpf);
        localStorage.setItem('token', _usuario.accessToken);
    }

    setProtLocalStorage(Protocolo) {
        localStorage.setItem('protocoloRes', JSON.stringify(Protocolo));
    }

    getProtlocalStorage() {
        return localStorage.getItem('protocoloRes');
    }
}
