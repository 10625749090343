import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-regiao-anexal-doppler',
  templateUrl: './regiao-anexal-doppler.component.html',
  styleUrls: ['./regiao-anexal-doppler.component.css']
})
export class RegiaoAnexalDopplerComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckVarizes') ckVarizes: MatCheckbox;
  @ViewChild('ckVarizesDireita') ckVarizesDireita: MatCheckbox;
  @ViewChild('ckVarizesEsquerda') ckVarizesEsquerda: MatCheckbox;
  @ViewChild('inVarizesDireita') inVarizesDireita: ElementRef;
  @ViewChild('inVarizesEsquerda') inVarizesEsquerda: ElementRef;
 
  @ViewChild('ckHidrossalpinge') ckHidrossalpinge: MatCheckbox;
  @ViewChild('ckHidrossalpingeDireita') ckHidrossalpingeDireita: MatCheckbox;
  @ViewChild('ckHidrossalpingeEsquerda') ckHidrossalpingeEsquerda: MatCheckbox;
  @ViewChild('inHidrossalpingeDireita') inHidrossalpingeDireita: ElementRef;
  @ViewChild('inHidrossalpingeEsquerda') inHidrossalpingeEsquerda: ElementRef;

  @ViewChild('ckHemato') ckHemato: MatCheckbox;
  @ViewChild('ckHematoDireita') ckHematoDireita: MatCheckbox;
  @ViewChild('ckHematoEsquerda') ckHematoEsquerda: MatCheckbox;
  @ViewChild('inHematoDireita') inHematoDireita: ElementRef;
  @ViewChild('inHematoEsquerda') inHematoEsquerda: ElementRef;

  @ViewChild('ckAbscesso') ckAbscesso: MatCheckbox;
  @ViewChild('ckAbscessoDireita') ckAbscessoDireita: MatCheckbox;
  @ViewChild('ckAbscessoEsquerda') ckAbscessoEsquerda: MatCheckbox;
  @ViewChild('inAbscessoDireita1') inAbscessoDireita1: ElementRef;
  @ViewChild('inAbscessoDireita2') inAbscessoDireita2: ElementRef;
  @ViewChild('inAbscessoDireita3') inAbscessoDireita3: ElementRef;
  @ViewChild('inAbscessoEsquerda1') inAbscessoEsquerda1: ElementRef;
  @ViewChild('inAbscessoEsquerda2') inAbscessoEsquerda2: ElementRef;
  @ViewChild('inAbscessoEsquerda3') inAbscessoEsquerda3: ElementRef;

  @ViewChild('ckTeratoma') ckTeratoma: MatCheckbox;
  @ViewChild('ckTeratomaDireita') ckTeratomaDireita: MatCheckbox;
  @ViewChild('ckTeratomaEsquerda') ckTeratomaEsquerda: MatCheckbox;
  @ViewChild('inTeratomaDireita1') inTeratomaDireita1: ElementRef;
  @ViewChild('inTeratomaDireita2') inTeratomaDireita2: ElementRef;
  @ViewChild('inTeratomaDireita3') inTeratomaDireita3: ElementRef;
  @ViewChild('inTeratomaEsquerda1') inTeratomaEsquerda1: ElementRef;
  @ViewChild('inTeratomaEsquerda2') inTeratomaEsquerda2: ElementRef;
  @ViewChild('inTeratomaEsquerda3') inTeratomaEsquerda3: ElementRef;

  @ViewChild('ckFormacao') ckFormacao: MatCheckbox;
  @ViewChild('ckFormacaoDireita') ckFormacaoDireita: MatCheckbox;
  @ViewChild('ckFormacaoEsquerda') ckFormacaoEsquerda: MatCheckbox;
  @ViewChild('inFormacaoDireita1') inFormacaoDireita1: ElementRef;
  @ViewChild('inFormacaoDireita2') inFormacaoDireita2: ElementRef;
  @ViewChild('inFormacaoDireita3') inFormacaoDireita3: ElementRef;
  @ViewChild('inFormacaoEsquerda1') inFormacaoEsquerda1: ElementRef;
  @ViewChild('inFormacaoEsquerda2') inFormacaoEsquerda2: ElementRef;
  @ViewChild('inFormacaoEsquerda3') inFormacaoEsquerda3: ElementRef;
  @ViewChild('slContornoDir') slContornoDir: MatSelect;
  @ViewChild('slContornoEsq') slContornoEsq: MatSelect;
  @ViewChild('slParedesDir') slParedesDir: MatSelect;
  @ViewChild('slParedesEsq') slParedesEsq: MatSelect;
  @ViewChild('slComponentesDir') slComponentesDir: MatSelect;
  @ViewChild('slComponentesEsq') slComponentesEsq: MatSelect;
  @ViewChild('slDopplerDir') slDopplerDir: MatSelect;
  @ViewChild('slDopplerEsq') slDopplerEsq: MatSelect;

  @ViewChild('ckDoppler') ckDoppler: MatCheckbox;
  @ViewChild('ckDopplerFormacaoDireta') ckDopplerFormacaoDireita: MatCheckbox;
  @ViewChild('ckDopplerFormacaoEsquerda')ckDopplerFormacaoEsquerda: MatCheckbox;
  @ViewChild('rdDopplerSem') rdDopplerSem: MatRadioButton;
  @ViewChild('rdDopplerCom') rdDopplerCom: MatRadioButton;

  contornos: ArrayDropDown[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'irregulares', viewValue: 'Irregulares' }
  ];

  paredes: ArrayDropDown[] = [
    { value: 'finas', viewValue: 'Finas' },
    { value: 'espessadas', viewValue: 'Espessadas' }
  ];

  componentes: ArrayDropDown[] = [
    { value: 'sólido', viewValue: 'Sólido' },
    { value: 'cístico', viewValue: 'Cístico' }
  ];

  dopplers: ArrayDropDown[] = [
    { value: 'sem', viewValue: 'Sem' },
    { value: 'com', viewValue: 'Com' }
  ];

  countLesoes = 0;
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahVarizes: false,
      sahVarizesDireita: false,
      sahVarizesEsquerda: false,
      sahHidrossalpinge: false,
      sahHidrossalpingeDireita: false,
      sahHidrossalpingeEsquerda: false,
      sahHemato: false,
      sahHematoDireita: false,
      sahHematoEsquerda: false,
      sahAbscesso: false,
      sahAbscessoDireita: false,
      sahAbscessoEsquerda: false,
      sahTeratoma: false,
      sahTeratomaDireita: false,
      sahTeratomaEsquerda: false,
      sahFormacao: false,
      sahFormacaoDireita: false,
      sahFormacaoEsquerda: false,
      sahDopplerFormacaoDireita: false,
      sahDopplerFormacaoEsquerda: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahVarizes = false;
    this.showAndHideTerms.sahVarizesDireita = false;
    this.showAndHideTerms.sahVarizesEsquerda = false;
    this.showAndHideTerms.sahHidrossalpinge = false;
    this.showAndHideTerms.sahHidrossalpingeDireita = false;
    this.showAndHideTerms.sahHidrossalpingeEsquerda = false;
    this.showAndHideTerms.sahHemato = false;
    this.showAndHideTerms.sahHematoDireita = false;
    this.showAndHideTerms.sahHematoEsquerda = false;
    this.showAndHideTerms.sahAbscesso = false;
    this.showAndHideTerms.sahAbscessoDireita = false;
    this.showAndHideTerms.sahAbscessoEsquerda = false;
    this.showAndHideTerms.sahTeratoma = false;
    this.showAndHideTerms.sahTeratomaDireita = false;
    this.showAndHideTerms.sahTeratomaEsquerda = false;
    this.showAndHideTerms.sahFormacao = false;
    this.showAndHideTerms.sahFormacaoDireita = false;
    this.showAndHideTerms.sahFormacaoEsquerda = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickVarizes() {
    this.rdAlterado.checked = true;
    if (this.showAndHideTerms.sahVarizes == false) {
      this.showAndHideTerms.sahVarizes = true;
      this.showAndHideTerms.sahVarizesDireita = false;
      this.showAndHideTerms.sahVarizesEsquerda = false;
    } else {
      this.showAndHideTerms.sahVarizes = false;
      this.showAndHideTerms.sahVarizesDireita = false;
      this.showAndHideTerms.sahVarizesEsquerda = false;
    }
    this.catchIDs();
  }

  clickVarizesDireita() {
    if (this.showAndHideTerms.sahVarizesDireita == false) {
      this.showAndHideTerms.sahVarizesDireita = true;
    } else {
      this.showAndHideTerms.sahVarizesDireita = false;
    }
    this.catchIDs();
  }

  clickVarizesEsquerda() {
    if (this.showAndHideTerms.sahVarizesEsquerda == false) {
      this.showAndHideTerms.sahVarizesEsquerda = true;
    } else {
      this.showAndHideTerms.sahVarizesEsquerda = false;
    }
    this.catchIDs();
  }

  clickHidrossalpinge() {
    this.rdAlterado.checked = true;
    if (this.showAndHideTerms.sahHidrossalpinge == false) {
      this.showAndHideTerms.sahHidrossalpinge = true;
      this.showAndHideTerms.sahHidrossalpingeDireita = false;
      this.showAndHideTerms.sahHidrossalpingeEsquerda = false;
    } else {
      this.showAndHideTerms.sahHidrossalpinge = false;
      this.showAndHideTerms.sahHidrossalpingeDireita = false;
      this.showAndHideTerms.sahHidrossalpingeEsquerda = false;
    }
    this.catchIDs();
  }

  clickHidrossalpingeDireita() {
    if (this.showAndHideTerms.sahHidrossalpingeDireita == false) {
      this.showAndHideTerms.sahHidrossalpingeDireita = true;
    } else {
      this.showAndHideTerms.sahHidrossalpingeDireita = false;
    }
    this.catchIDs();
  }

  clickHidrossalpingeEsquerda() {
    if (this.showAndHideTerms.sahHidrossalpingeEsquerda == false) {
      this.showAndHideTerms.sahHidrossalpingeEsquerda = true;
    } else {
      this.showAndHideTerms.sahHidrossalpingeEsquerda = false;
    }
    this.catchIDs();
  }

  clickHemato() {
    this.rdAlterado.checked = true;
    if (this.showAndHideTerms.sahHemato == false) {
      this.showAndHideTerms.sahHemato = true;
      this.showAndHideTerms.sahHematoDireita = false;
      this.showAndHideTerms.sahHematoEsquerda = false;
    } else {
      this.showAndHideTerms.sahHemato = false;
      this.showAndHideTerms.sahHematoDireita = false;
      this.showAndHideTerms.sahHematoEsquerda = false;
    }
    this.catchIDs();
  }

  clickHematoDireita() {
    if (this.showAndHideTerms.sahHematoDireita == false) {
      this.showAndHideTerms.sahHematoDireita = true;
    } else {
      this.showAndHideTerms.sahHematoDireita = false;
    }
    this.catchIDs();
  }

  clickHematoEsquerda() {
    if (this.showAndHideTerms.sahHematoEsquerda == false) {
      this.showAndHideTerms.sahHematoEsquerda = true;
    } else {
      this.showAndHideTerms.sahHematoEsquerda = false;
    }
    this.catchIDs();
  }

  clickAbscesso() {
    this.rdAlterado.checked = true;
    if (this.showAndHideTerms.sahAbscesso == false) {
      this.showAndHideTerms.sahAbscesso = true;
      this.showAndHideTerms.sahAbscessoDireita = false;
      this.showAndHideTerms.sahAbscessoEsquerda = false;
    } else {
      this.showAndHideTerms.sahAbscesso = false;
      this.showAndHideTerms.sahAbscessoDireita = false;
      this.showAndHideTerms.sahAbscessoEsquerda = false;
    }
    this.catchIDs();
  }

  clickAbscessoDireita() {
    if (this.showAndHideTerms.sahAbscessoDireita == false) {
      this.showAndHideTerms.sahAbscessoDireita = true;
    } else {
      this.showAndHideTerms.sahAbscessoDireita = false;
    }
    this.catchIDs();
  }

  clickAbscessoEsquerda() {
    if (this.showAndHideTerms.sahAbscessoEsquerda == false) {
      this.showAndHideTerms.sahAbscessoEsquerda = true;
    } else {
      this.showAndHideTerms.sahAbscessoEsquerda = false;
    }
    this.catchIDs();
  }

  clickTeratoma() {
    this.rdAlterado.checked = true;
    if (this.showAndHideTerms.sahTeratoma == false) {
      this.showAndHideTerms.sahTeratoma = true;
      this.showAndHideTerms.sahTeratomaDireita = false;
      this.showAndHideTerms.sahTeratomaEsquerda = false;
    } else {
      this.showAndHideTerms.sahTeratoma = false;
      this.showAndHideTerms.sahTeratomaDireita = false;
      this.showAndHideTerms.sahTeratomaEsquerda = false;
    }
    this.catchIDs();
  }

  clickTeratomaDireita() {
    if (this.showAndHideTerms.sahTeratomaDireita == false) {
      this.showAndHideTerms.sahTeratomaDireita = true;
    } else {
      this.showAndHideTerms.sahTeratomaDireita = false;
    }
    this.catchIDs();
  }

  clickTeratomaEsquerda() {
    if (this.showAndHideTerms.sahTeratomaEsquerda == false) {
      this.showAndHideTerms.sahTeratomaEsquerda = true;
    } else {
      this.showAndHideTerms.sahTeratomaEsquerda = false;
    }
    this.catchIDs();
  }

  clickFormacao() {
    this.rdAlterado.checked = true;
    if (this.showAndHideTerms.sahFormacao == false) {
      this.showAndHideTerms.sahFormacao = true;
      this.showAndHideTerms.sahFormacaoDireita = false;
      this.showAndHideTerms.sahFormacaoEsquerda = false;
    } else {
      this.showAndHideTerms.sahFormacao = false;
      this.showAndHideTerms.sahFormacaoDireita = false;
      this.showAndHideTerms.sahFormacaoEsquerda = false;
    }
    this.catchIDs();
  }

  clickFormacaoDireita() {
    if (this.showAndHideTerms.sahFormacaoDireita == false) {
      this.showAndHideTerms.sahFormacaoDireita = true;
    } else {
      this.showAndHideTerms.sahFormacaoDireita = false;
    }
    this.catchIDs();
  }

  clickFormacaoEsquerda() {
    if (this.showAndHideTerms.sahFormacaoEsquerda == false) {
      this.showAndHideTerms.sahFormacaoEsquerda = true;
    } else {
      this.showAndHideTerms.sahFormacaoEsquerda = false;
    }
    this.catchIDs();
  }

  clickDopplerFormacaoDireita() {
    if (this.showAndHideTerms.sahDopplerFormacaoDireita == false) {
      this.showAndHideTerms.sahDopplerFormacaoDireita = true;
    } else {
      this.slDopplerDir.value = '';
      this.showAndHideTerms.sahDopplerFormacaoDireita = false;
    }
    this.catchIDs();
  }

  clickDopplerFormacaoEsquerda() {
    if (this.showAndHideTerms.sahDopplerFormacaoEsquerda == false) {
      this.showAndHideTerms.sahDopplerFormacaoEsquerda = true;
    } else {
      this.slDopplerEsq.value = '';
      this.showAndHideTerms.sahDopplerFormacaoEsquerda = false;
    }
    this.catchIDs();
  }

  clickDoppler() {
    this.catchIDs();
  }

  clickDopplerCom() {
    this.catchIDs();
  }

  clickDopplerSem() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;

    this.verifcaNormal();

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.ckVarizes.checked) {
        this.emitVarizes();
      }

      if (this.ckHidrossalpinge.checked) {
        if (this.countLesoes > 0) {
          this.emitHidrossalpingeLesao();
        } else {
          this.emitHidrossalpinge();
        }
      }

      if (this.ckHemato.checked) {
        if (this.countLesoes > 0) {
          this.emitHematoLesao();
        } else {
          this.emitHemato();
        }
      }

      if (this.ckAbscesso.checked) {
        if (this.countLesoes > 0) {
          this.emitAbscessoLesao();
        } else {
          this.emitAbscesso();
        }
      }

      if (this.ckTeratoma.checked) {
        if (this.countLesoes > 0) {
          this.emitTeratoLesao();
        } else {
          this.emitTerato();
        }
      }

      if (this.ckFormacao.checked) {
        if (this.countLesoes > 0) {
          this.emitFormacaoLesao();
        } else {
          this.emitFormacao();
        }
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitVarizes() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckVarizes.id);

    if (this.ckVarizesDireita && this.ckVarizesDireita.checked) {
      this.termoSelecionado.emit(this.ckVarizesDireita.id);

      if (this.inVarizesDireita != undefined &&
        this.inVarizesDireita.nativeElement.id != undefined &&
        this.inVarizesDireita.nativeElement.value != '') {

        const objeto = { elemento: this.inVarizesDireita.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckVarizesEsquerda && this.ckVarizesEsquerda.checked) {
      this.termoSelecionado.emit(this.ckVarizesEsquerda.id);

      if (this.inVarizesEsquerda != undefined &&
        this.inVarizesEsquerda.nativeElement.id != undefined &&
        this.inVarizesEsquerda.nativeElement.value != '') {

        const objeto = { elemento: this.inVarizesEsquerda.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHidrossalpinge() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckHidrossalpinge.id);

    if (this.ckDoppler && this.ckDoppler.checked) {
      this.termoSelecionado.emit(this.ckDoppler.id);
    }

    if (this.ckHidrossalpingeDireita && this.ckHidrossalpingeDireita.checked) {
      this.termoSelecionado.emit(this.ckHidrossalpingeDireita.id);

      if (this.inHidrossalpingeDireita != undefined &&
        this.inHidrossalpingeDireita.nativeElement.id != undefined &&
        this.inHidrossalpingeDireita.nativeElement.value != '') {

        const objeto = { elemento: this.inHidrossalpingeDireita.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckHidrossalpingeEsquerda && this.ckHidrossalpingeEsquerda.checked) {
      this.termoSelecionado.emit(this.ckHidrossalpingeEsquerda.id);

      if (this.inHidrossalpingeEsquerda != undefined &&
        this.inHidrossalpingeEsquerda.nativeElement.id != undefined &&
        this.inHidrossalpingeEsquerda.nativeElement.value != '') {

        const objeto = { elemento: this.inHidrossalpingeEsquerda.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHidrossalpingeLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckHidrossalpinge.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.ckDoppler && this.ckDoppler.checked) {
      const objeto = { id: this.ckDoppler.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    }


    if (this.ckHidrossalpingeDireita != undefined && this.ckHidrossalpingeDireita.checked) {
      const objeto = { id: this.ckHidrossalpingeDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inHidrossalpingeDireita != undefined &&
        this.inHidrossalpingeDireita.nativeElement.id != undefined &&
        this.inHidrossalpingeDireita.nativeElement.value != '') {

        const objeto = { elemento: this.inHidrossalpingeDireita.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckHidrossalpingeEsquerda != undefined && this.ckHidrossalpingeEsquerda.checked) {
      const objeto = { id: this.ckHidrossalpingeEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inHidrossalpingeEsquerda != undefined &&
        this.inHidrossalpingeEsquerda.nativeElement.id != undefined &&
        this.inHidrossalpingeEsquerda.nativeElement.value != '') {

        const objeto = { elemento: this.inHidrossalpingeEsquerda.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHemato() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckHemato.id);

    if (this.ckDoppler && this.ckDoppler.checked) {
      this.termoSelecionado.emit(this.ckDoppler.id);
    }

    if (this.ckHematoDireita && this.ckHematoDireita.checked) {
      this.termoSelecionado.emit(this.ckHematoDireita.id);

      if (this.inHematoDireita != undefined &&
        this.inHematoDireita.nativeElement.id != undefined &&
        this.inHematoDireita.nativeElement.value != '') {

        const objeto = { elemento: this.inHematoDireita.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckHematoEsquerda && this.ckHematoEsquerda.checked) {
      this.termoSelecionado.emit(this.ckHematoEsquerda.id);

      if (this.inHematoEsquerda != undefined &&
        this.inHematoEsquerda.nativeElement.id != undefined &&
        this.inHematoEsquerda.nativeElement.value != '') {

        const objeto = { elemento: this.inHematoEsquerda.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHematoLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckHemato.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.ckDoppler && this.ckDoppler.checked) {
      const objeto = { id: this.ckDoppler.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }

    if (this.ckHematoDireita != undefined && this.ckHematoDireita.checked) {
      const objeto = { id: this.ckHematoDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inHematoDireita != undefined &&
        this.inHematoDireita.nativeElement.id != undefined &&
        this.inHematoDireita.nativeElement.value != '') {

        const objeto = { elemento: this.inHematoDireita.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckHematoEsquerda != undefined && this.ckHematoEsquerda.checked) {
      const objeto = { id: this.ckHematoEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inHematoEsquerda != undefined &&
        this.inHematoEsquerda.nativeElement.id != undefined &&
        this.inHematoEsquerda.nativeElement.value != '') {

        const objeto = { elemento: this.inHematoEsquerda.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAbscesso() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckAbscesso.id);

    if (this.ckDoppler && this.ckDoppler.checked) {
      this.termoSelecionado.emit(this.ckDoppler.id);
    }

    if (this.ckAbscessoDireita && this.ckAbscessoDireita.checked) {
      this.termoSelecionado.emit(this.ckAbscessoDireita.id);

      this.formataMedindoAbscessoDireita();
    }

    if (this.ckAbscessoEsquerda && this.ckAbscessoEsquerda.checked) {
      this.termoSelecionado.emit(this.ckAbscessoEsquerda.id);

      this.formataMedindoAbscessoEsquerda();
    }
  }

  emitAbscessoLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckAbscesso.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.ckDoppler && this.ckDoppler.checked) {
      const objeto = { id: this.ckDoppler.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    }

    if (this.ckAbscessoDireita != undefined && this.ckAbscessoDireita.checked) {
      const objeto = { id: this.ckAbscessoDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoAbscessoDireita();
    }

    if (this.ckAbscessoEsquerda != undefined && this.ckAbscessoEsquerda.checked) {
      const objeto = { id: this.ckAbscessoEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoAbscessoEsquerda();
    }
  }

  emitTerato() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckTeratoma.id);

    if (this.rdDopplerSem && this.rdDopplerSem.checked) {
      this.termoSelecionado.emit(this.rdDopplerSem.id);
    }

    if (this.rdDopplerCom && this.rdDopplerCom.checked) {
      this.termoSelecionado.emit(this.rdDopplerCom.id);
    }

    if (this.ckTeratomaDireita && this.ckTeratomaDireita.checked) {
      this.termoSelecionado.emit(this.ckTeratomaDireita.id);

      this.formataMedindoTeratomaDireito();
    }

    if (this.ckTeratomaEsquerda && this.ckTeratomaEsquerda.checked) {
      this.termoSelecionado.emit(this.ckTeratomaEsquerda.id);

      this.formataMedindoTeratomaEsquerdo();
    }
  }

  emitTeratoLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckTeratoma.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rdDopplerSem && this.rdDopplerSem.checked) {
      const objeto = { id: this.rdDopplerSem.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }

    if (this.rdDopplerCom && this.rdDopplerCom.checked) {
      const objeto = { id: this.rdDopplerCom.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }

    if (this.ckTeratomaDireita != undefined && this.ckTeratomaDireita.checked) {
      const objeto = { id: this.ckTeratomaDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoTeratomaDireito();
    }

    if (this.ckTeratomaEsquerda != undefined && this.ckTeratomaEsquerda.checked) {
      const objeto = { id: this.ckTeratomaEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.formataMedindoTeratomaEsquerdo();
    }
  }

  emitFormacao() {


    if (this.ckFormacaoDireita && this.ckFormacaoDireita.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckFormacaoDireita.id);

      if (this.slContornoDir && this.slContornoDir.value) {
        const objeto = { elemento: this.slContornoDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesDir && this.slParedesDir.value) {
        const objeto = { elemento: this.slParedesDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slComponentesDir && this.slComponentesDir.value) {
        const objeto = { elemento: this.slComponentesDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerDir && this.slDopplerDir.value) {
        const objeto = { elemento: this.slDopplerDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFormacaoDireito();
    }
    let direitaTrue = this.countLesoes > 0;
    if (this.ckFormacaoEsquerda && this.ckFormacaoEsquerda.checked) {
      this.countLesoes++;
      if (direitaTrue) {        
      this.lesoesSelecionadas.emit(this.ckFormacaoEsquerda);
    } else {
      this.termoSelecionado.emit(this.ckFormacaoEsquerda.id);
    }

      if (this.slContornoEsq && this.slContornoEsq.value) {
        const objeto = { elemento: this.slContornoEsq, isPrimeiraLesao: !direitaTrue };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesEsq && this.slParedesEsq.value) {
        const objeto = { elemento: this.slParedesEsq, isPrimeiraLesao: !direitaTrue };
        this.termosGenericos.emit(objeto);
      }

      if (this.slComponentesEsq && this.slComponentesEsq.value) {
        const objeto = { elemento: this.slComponentesEsq, isPrimeiraLesao: !direitaTrue };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerEsq && this.slDopplerEsq.value) {
        const objeto = { elemento: this.slDopplerEsq, isPrimeiraLesao: !direitaTrue };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFormacaoEsquerdo();
    }
  }

  emitFormacaoLesao() {

    if (this.ckFormacaoDireita != undefined && this.ckFormacaoDireita.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFormacaoDireita.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slContornoDir && this.slContornoDir.value) {
        const objeto = { elemento: this.slContornoDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesDir && this.slParedesDir.value) {
        const objeto = { elemento: this.slParedesDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slComponentesDir && this.slComponentesDir.value) {
        const objeto = { elemento: this.slComponentesDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerDir && this.slDopplerDir.value) {
        const objeto = { elemento: this.slDopplerDir, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFormacaoDireito();
    }

    if (this.ckFormacaoEsquerda != undefined && this.ckFormacaoEsquerda.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckFormacaoEsquerda.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slContornoEsq && this.slContornoEsq.value) {
        const objeto = { elemento: this.slContornoEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slParedesEsq && this.slParedesEsq.value) {
        const objeto = { elemento: this.slParedesEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slComponentesEsq && this.slComponentesEsq.value) {
        const objeto = { elemento: this.slComponentesEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDopplerEsq && this.slDopplerEsq.value) {
        const objeto = { elemento: this.slDopplerEsq, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoFormacaoEsquerdo();
    }
  }

  formataMedindoAbscessoDireita() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inAbscessoDireita1 != undefined &&
      this.inAbscessoDireita1.nativeElement.id != undefined &&
      this.inAbscessoDireita1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inAbscessoDireita1.nativeElement.value);
    }

    if (this.inAbscessoDireita2 != undefined &&
      this.inAbscessoDireita2.nativeElement.id != undefined &&
      this.inAbscessoDireita2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inAbscessoDireita2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inAbscessoDireita2.nativeElement.value);
      }
    }

    if (this.inAbscessoDireita3 != undefined &&
      this.inAbscessoDireita3.nativeElement.id != undefined &&
      this.inAbscessoDireita3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inAbscessoDireita3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inAbscessoDireita3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 3892, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoAbscessoEsquerda() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inAbscessoEsquerda1 != undefined &&
      this.inAbscessoEsquerda1.nativeElement.id != undefined &&
      this.inAbscessoEsquerda1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inAbscessoEsquerda1.nativeElement.value);
    }

    if (this.inAbscessoEsquerda2 != undefined &&
      this.inAbscessoEsquerda2.nativeElement.id != undefined &&
      this.inAbscessoEsquerda2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inAbscessoEsquerda2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inAbscessoEsquerda2.nativeElement.value);
      }
    }

    if (this.inAbscessoEsquerda3 != undefined &&
      this.inAbscessoEsquerda3.nativeElement.id != undefined &&
      this.inAbscessoEsquerda3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inAbscessoEsquerda3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inAbscessoEsquerda3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 3894, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoTeratomaDireito() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inTeratomaDireita1 != undefined &&
      this.inTeratomaDireita1.nativeElement.id != undefined &&
      this.inTeratomaDireita1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inTeratomaDireita1.nativeElement.value);
    }

    if (this.inTeratomaDireita2 != undefined &&
      this.inTeratomaDireita2.nativeElement.id != undefined &&
      this.inTeratomaDireita2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inTeratomaDireita2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inTeratomaDireita2.nativeElement.value);
      }
    }

    if (this.inTeratomaDireita3 != undefined &&
      this.inTeratomaDireita3.nativeElement.id != undefined &&
      this.inTeratomaDireita3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inTeratomaDireita3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inTeratomaDireita3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 3897, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoTeratomaEsquerdo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inTeratomaEsquerda1 != undefined &&
      this.inTeratomaEsquerda1.nativeElement.id != undefined &&
      this.inTeratomaEsquerda1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inTeratomaEsquerda1.nativeElement.value);
    }

    if (this.inTeratomaEsquerda2 != undefined &&
      this.inTeratomaEsquerda2.nativeElement.id != undefined &&
      this.inTeratomaEsquerda2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inTeratomaEsquerda2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inTeratomaEsquerda2.nativeElement.value);
      }
    }

    if (this.inTeratomaEsquerda3 != undefined &&
      this.inTeratomaEsquerda3.nativeElement.id != undefined &&
      this.inTeratomaEsquerda3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inTeratomaEsquerda3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inTeratomaEsquerda3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 3899, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoFormacaoDireito() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inFormacaoDireita1 != undefined &&
      this.inFormacaoDireita1.nativeElement.id != undefined &&
      this.inFormacaoDireita1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inFormacaoDireita1.nativeElement.value);
    }

    if (this.inFormacaoDireita2 != undefined &&
      this.inFormacaoDireita2.nativeElement.id != undefined &&
      this.inFormacaoDireita2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inFormacaoDireita2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inFormacaoDireita2.nativeElement.value);
      }
    }

    if (this.inFormacaoDireita3 != undefined &&
      this.inFormacaoDireita3.nativeElement.id != undefined &&
      this.inFormacaoDireita3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inFormacaoDireita3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inFormacaoDireita3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 3942, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoFormacaoEsquerdo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inFormacaoEsquerda1 != undefined &&
      this.inFormacaoEsquerda1.nativeElement.id != undefined &&
      this.inFormacaoEsquerda1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inFormacaoEsquerda1.nativeElement.value);
    }

    if (this.inFormacaoEsquerda2 != undefined &&
      this.inFormacaoEsquerda2.nativeElement.id != undefined &&
      this.inFormacaoEsquerda2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inFormacaoEsquerda2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inFormacaoEsquerda2.nativeElement.value);
      }
    }

    if (this.inFormacaoEsquerda3 != undefined &&
      this.inFormacaoEsquerda3.nativeElement.id != undefined &&
      this.inFormacaoEsquerda3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inFormacaoEsquerda3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inFormacaoEsquerda3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes <= 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 3945, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  verifcaNormal() {
    if(!this.ckVarizes.checked &&
      !this.ckHidrossalpinge.checked &&
      !this.ckHemato.checked &&
      !this.ckAbscesso.checked &&
      !this.ckTeratoma.checked &&
      !this.ckFormacao.checked)
      this.rdNormal.checked = true;
  }
}
