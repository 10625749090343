import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { PeComponent } from './pe.component';
import { DerrameArticularPeComponent } from './TermosComponents/derrame-articular-pe/derrame-articular-pe.component';
import { EspacosIntermetatarsicosPeComponent } from './TermosComponents/espacos-intermetatarsicos-pe/espacos-intermetatarsicos-pe.component';
import { FasciaPlantarPeComponent } from './TermosComponents/fascia-plantar-pe/fascia-plantar-pe.component';
import { MusculaturaRegionalPeComponent } from './TermosComponents/musculatura-regional-pe/musculatura-regional-pe.component';
import { PeleTecidoPeComponent } from './TermosComponents/pele-tecido-pe/pele-tecido-pe.component';
import { SuperficieOsseaPeComponent } from './TermosComponents/superficie-ossea-pe/superficie-ossea-pe.component';
import { TendoesExtensoresPeComponent } from './TermosComponents/tendoes-extensores-pe/tendoes-extensores-pe.component';
import { TendoesFlexoresPeComponent } from './TermosComponents/tendoes-flexores-pe/tendoes-flexores-pe.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';




@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    PeComponent,
    DerrameArticularPeComponent,
    EspacosIntermetatarsicosPeComponent,
    FasciaPlantarPeComponent,
    MusculaturaRegionalPeComponent,
    PeleTecidoPeComponent,
    SuperficieOsseaPeComponent,
    TendoesExtensoresPeComponent,
    TendoesFlexoresPeComponent
  ],
  declarations: [
    PeComponent,
    DerrameArticularPeComponent,
    EspacosIntermetatarsicosPeComponent,
    FasciaPlantarPeComponent,
    MusculaturaRegionalPeComponent,
    PeleTecidoPeComponent,
    SuperficieOsseaPeComponent,
    TendoesExtensoresPeComponent,
    TendoesFlexoresPeComponent
  ]
})
export class PeModule { }
