import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { RiscoAneuploidiasDialogComponent } from 'src/app/utils/dialog/table/risco-aneuploidias-dialog/risco-aneuploidias-dialog.component';

@Component({
  selector: 'app-risco-fmf',
  templateUrl: './risco-fmf.component.html',
  styleUrls: ['./risco-fmf.component.css']
})
export class RiscoFmfComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();

  @ViewChild('inBasalTri21') inBasalTri21: ElementRef;
  @ViewChild('inBasalTri18') inBasalTri18: ElementRef;
  @ViewChild('inBasalTri13') inBasalTri13: ElementRef;
  @ViewChild('inCalculadoTri21') inCalculadoTri21: ElementRef;
  @ViewChild('inCalculadoTri18') inCalculadoTri18: ElementRef;
  @ViewChild('inCalculadoTri13') inCalculadoTri13: ElementRef;
  @ViewChild('rdBaixo') rdBaixo: MatRadioButton;
  @ViewChild('rdIntermediario') rdIntermediario: MatRadioButton;
  @ViewChild('rdAlto') rdAlto: MatRadioButton;

  riscoCromo: ArrayDropDown[] = [
    { value: 'risco baixo', viewValue: 'Risco baixo' },
    { value: 'risco intermediário', viewValue: 'Risco intermediário' },
    { value: 'alto risco', viewValue: 'Alto risco' }
  ]
  constructor(public dialog: MatDialog,) { }

  ngOnInit() {
  }

  blurParametros() {
    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickTabelaReferenciaRiscoAneuploidias(){
    this.dialog.open(RiscoAneuploidiasDialogComponent, {});
  }
  
  catchIDs() {
    this.enviaMedida.emit('Clean');
    this.termoSelecionado.emit('Clean');

    
    if (this.inBasalTri21 && this.inBasalTri21.nativeElement.value) {
      const element = { value: this.inBasalTri21.nativeElement.value, id: this.inBasalTri21.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if(this.inBasalTri18 && this.inBasalTri18.nativeElement.value){
      const element = { value: this.inBasalTri18.nativeElement.value, id: this.inBasalTri18.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if(this.inBasalTri13 && this.inBasalTri13.nativeElement.value){
      const element = { value: this.inBasalTri13.nativeElement.value, id: this.inBasalTri13.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if(this.inCalculadoTri21 && this.inCalculadoTri21.nativeElement.value){
      const element = { value: this.inCalculadoTri21.nativeElement.value, id: this.inCalculadoTri21.nativeElement.id };
      this.enviaMedida.emit(element);
    }
    
    if(this.inCalculadoTri18 && this.inCalculadoTri18.nativeElement.value){
      const element = { value: this.inCalculadoTri18.nativeElement.value, id: this.inCalculadoTri18.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if(this.inCalculadoTri13 && this.inCalculadoTri13.nativeElement.value){
      const element = { value: this.inCalculadoTri13.nativeElement.value, id: this.inCalculadoTri13.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if(this.rdBaixo.checked){
      this.termoSelecionado.emit(this.rdBaixo.id);
    }

    if(this.rdIntermediario.checked){
      this.termoSelecionado.emit(this.rdIntermediario.id);
    }

    if(this.rdAlto.checked){
      this.termoSelecionado.emit(this.rdAlto.id);
    }

    this.listaCompleta.emit();
  }
}
