import { Component, OnInit, ViewChild, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';



@Component({
  selector: 'app-derrame-articular',
  templateUrl: './derrame-articular.component.html',
  styleUrls: ['./derrame-articular.component.css']
})
export class DerrameArticularComponent implements OnInit {

  public showAndHideTerms: any;
  public addForm: FormGroup;
  public linha: number;
  public objeto: any;
  public objetofilho: any;



  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdpresente') rdpresente: MatRadioButton;
  @ViewChildren('VolumeChildren') VolumeChildren: QueryList<any>;


  public articulacao: ArrayDropDown[] = [
    { value: 'metacarpofalângico', viewValue: 'Metacarpofalângico' },
    { value: 'interfalângico proximal', viewValue: 'Interfalângico Proximal' },
    { value: 'interfalângico distal', viewValue: 'Interfalângico Distal' },
    { value: 'interfalângico', viewValue: 'Interfalângico' },
  ];

  public dedos: ArrayDropDown[] = [
    { value: '1° dedo', viewValue: '1° Dedo' },
    { value: '2° dedo', viewValue: '2° Dedo' },
    { value: '3° dedo', viewValue: '3° Dedo' },
    { value: '4° dedo', viewValue: '4° Dedo' },
    { value: '5° dedo', viewValue: '5° Dedo' },
  ];

  public Volume: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' },
  ];

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: false,
      sahPresente: true
    };
    this.addForm = this._fb.group({
      Presente: this._fb.array([this.addDedosGrup()]),
    });
  }

  public addDedosGrup(): any {
    return this._fb.group({
      articulacaos: [],
      dedo: [],
      Volumes: [],
    });
  }

  public addPresente(): any {
    this.PresenteArray.push(this.addDedosGrup());
    this.catchIDs();
  }

  public removePresente(index): any {
    this.PresenteArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }


  get PresenteArray() {
    return <FormArray>this.addForm.get('Presente');
  }

  public clickAusente(): any {
    this.showAndHideTerms.sahPresente = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public clickPresente(): any {
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahPresente = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.PresenteArray.value[0].articulacaos || this.PresenteArray.value[0].dedo || this.PresenteArray.value[0].Volumes)){
      this.rdpresente.checked = true;
    }
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.value);
    }
    if (this.rdpresente.checked) {
      this.termoSelecionado.emit(this.rdpresente.value);
      this.verificaVolume();
    }
    this.listaCompleta.emit();

  }
  public verificaVolume() {
    this.linha = 0;
    this.VolumeChildren.forEach(obj => {

      if (+obj.id === 1736) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if (this.rdpresente && !this.rdpresente.checked) {

      const arrayTotal = this.PresenteArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.PresenteArray.length - 1;

        if (index !== 0) {
          this.PresenteArray.removeAt(index);
        }
      }
      this.PresenteArray.reset();
    }
  }

}
