import { IWindow, webkitSpeechRecognition } from "src/app/models/Helper/IWindow";

class Interpretador {
  window: IWindow;
  gravandoVoz: string = 'Start';
  gravando: boolean = false;
  speechRecognition = new webkitSpeechRecognition();
  transcrito = '';

  constructor() {
    this.audioConfig();
  }

  audioConfig(): void {
    this.speechRecognition.continuous = true;
    this.speechRecognition.interimResults = true;
    this.speechRecognition.lang = 'pt-BR';
  }

  async gravadorDeVoz(click) {
    let transcript: any;

    if(click === 'Start' && !this.gravando) {
      click = 'Stop';
      this.gravandoVoz = click;

      if ('webkitSpeechRecognition' in window) {
       transcript = await this.interpretacao();

       return transcript;
      } else {
        console.log("Speech Recognition Not Available")
      }
    } else {
      click = 'Start';
      this.gravandoVoz = click;

      this.speechRecognition.stop();
      this.speechRecognition.onend = () => {
        this.gravando = false;

        return transcript;
      };
    }
  }

  async interpretacao() {
    let transcript = '';

    this.speechRecognition.start();
    this.speechRecognition.onstart = () => {};

    this.speechRecognition.onresult = (event) => {
      let interim_transcript = '';
      let final_transcript = '';
      this.gravando = true;

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          final_transcript += event.results[i][0].transcript;
        } else {
          interim_transcript += event.results[i][0].transcript;
        }
      }

      transcript = interim_transcript;
      this.transcrito = transcript;

      return transcript;
    };
  }
}

export default new Interpretador();
