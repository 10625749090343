import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface ArteriaRenal {
  value: string;
  selected: boolean;
  viewValue: string;
}

@Component({
  selector: 'app-aorta',
  templateUrl: './aorta.component.html',
  styleUrls: ['./aorta.component.css']
})
export class AortaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAteromatose') rdAteromatose: MatRadioButton;
  @ViewChild('rdEctasia') rdEctasia: MatRadioButton;
  @ViewChild('rdAneurismo') rdAneurismo: MatRadioButton;
  @ViewChild('rdNaoCaracterizada') rdNaoCaracterizada: MatRadioButton;

  @ViewChild('inCalibre') inCalibre: ElementRef;
  @ViewChild('ckAteromatose') ckAteromatose: MatCheckbox;
  @ViewChild('ckParcialmentCaract') ckParcialmentCaract: MatCheckbox;
  @ViewChild('slArteriasRenal') slArteriasRenal: MatSelect;

  arteriasRenal: ArteriaRenal[] = [
    {value: '219', selected: false, viewValue: 'Infrarrenal'},
    {value: '220', selected: false, viewValue: 'Suprarrenal'},
    {value: '221', selected: false, viewValue: 'Ao nível das artérias renais'},
  ];

  arteriaRenalSelected = '219';
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCalibre: true,
      sahParcialmentCaract: true,
      sahAteromatose: false,
      sahArteriaRenal: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal($event) {
    this.showAndHideTerms.sahCalibre = true;
    this.showAndHideTerms.sahParcialmentCaract = true;
    this.showAndHideTerms.sahAteromatose = false;
    this.showAndHideTerms.sahArteriaRenal = false;

    if (this.ckParcialmentCaract != undefined) {
      this.ckParcialmentCaract.checked = false;
    }

    this.catchIDs();
  }

  clickAteromatose($event) {
    this.showAndHideTerms.sahCalibre = true;
    this.showAndHideTerms.sahParcialmentCaract = true;
    this.showAndHideTerms.sahAteromatose = false;
    this.showAndHideTerms.sahArteriaRenal = false;

    if (this.ckParcialmentCaract != undefined) {
      this.ckParcialmentCaract.checked = false;
    }


    this.catchIDs();
  }

  clickEctasia($event) {
    this.showAndHideTerms.sahCalibre = true;
    this.showAndHideTerms.sahParcialmentCaract = false;
    this.showAndHideTerms.sahAteromatose = true;
    this.showAndHideTerms.sahArteriaRenal = false;

    if (this.inCalibre != undefined) {
      this.inCalibre.nativeElement.value = '';
    }

    this.catchIDs();
  }

  clickAneurismo($event) {
    this.showAndHideTerms.sahCalibre = true;
    this.showAndHideTerms.sahParcialmentCaract = false;
    this.showAndHideTerms.sahAteromatose = true;
    this.showAndHideTerms.sahArteriaRenal = true;

    if (this.inCalibre != undefined) {
      this.inCalibre.nativeElement.value = '';
    }

    this.catchIDs();
  }

  clickNaoCaraterizada($event) {
    this.showAndHideTerms.sahCalibre = false;
    this.showAndHideTerms.sahParcialmentCaract = false;
    this.showAndHideTerms.sahAteromatose = false;
    this.showAndHideTerms.sahArteriaRenal = false;

    this.catchIDs();
  }

  blurCalibre() {
    this.catchIDs();
  }

  clickAteromatoseCK() {
    this.catchIDs();
  }

  clickParcialmenteCaract() {
    this.catchIDs();
  }

  changeArteriaRenal() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.inCalibre != undefined && this.inCalibre.nativeElement.id != undefined &&
          this.inCalibre.nativeElement.value) {
        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.ckParcialmentCaract != undefined && this.ckParcialmentCaract.checked) {
        this.termoSelecionado.emit(this.ckParcialmentCaract.id);
      }
    }

    if (this.rdAteromatose.checked) {
      this.termoSelecionado.emit(this.rdAteromatose.id);

      if (this.inCalibre != undefined && this.inCalibre.nativeElement.id != undefined &&
        this.inCalibre.nativeElement.value) {
        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.ckParcialmentCaract != undefined && this.ckParcialmentCaract.checked) {
        this.termoSelecionado.emit(this.ckParcialmentCaract.id);
      }
    }

    if (this.rdEctasia.checked) {
      this.termoSelecionado.emit(this.rdEctasia.id);

      if (this.inCalibre != undefined && this.inCalibre.nativeElement.id != undefined) {
        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAteromatose != undefined && this.ckAteromatose.checked) {
        this.termoSelecionado.emit(this.ckAteromatose.id);
      }
    }

    if (this.rdAneurismo.checked) {
      this.termoSelecionado.emit(this.rdAneurismo.id);

      if (this.inCalibre != undefined && this.inCalibre.nativeElement.id != undefined) {
        const objeto = {elemento: this.inCalibre.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slArteriasRenal != undefined && this.slArteriasRenal.value != undefined) {
        this.termoSelecionado.emit(this.slArteriasRenal.value);
      } else {
        this.termoSelecionado.emit('219');
      }

      if (this.ckAteromatose != undefined && this.ckAteromatose.checked) {
        this.termoSelecionado.emit(this.ckAteromatose.id);
      }
    }

    if (this.rdNaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdNaoCaracterizada.id);
    }

    this.listaCompleta.emit();
  }

}
