import { ArrayDropDown } from './../../../../../models/Helper/ArrayDropDown';
import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-interapofisarias',
  templateUrl: './interapofisarias.component.html',
  styleUrls: ['./interapofisarias.component.css']
})
export class InterapofisariasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('ckArtrose') ckArtrose: MatCheckbox;
  @ViewChild('rdArtroseMult') rdArtroseMult: MatRadioButton;
  @ViewChild('slNivel') slNivel: MatSelect;

  nivelArtrose: ArrayDropDown[] = [
    { value: 'T12-L1', viewValue: 'T12-L1' },
    { value: 'L1-L2', viewValue: 'L1-L2' },
    { value: 'L2-L3', viewValue: 'L2-L3' },
    { value: 'L3-L4', viewValue: 'L3-L4' },
    { value: 'L4-L5', viewValue: 'L4-L5' },
    { value: 'L5-S1', viewValue: 'L5-S1' },
  ];

  showAndHideTerms = {
    sahArtrose: false,
  };

  constructor() { }

  ngOnInit() {
  }

  clickNormal(){
    this.showAndHideTerms.sahArtrose = false;
    this.ckArtrose.checked = false;
    this.rdArtroseMult.checked = false;
    this.catchIDs();
  }

  clickArtrose(){
    if (this.ckArtrose && this.ckArtrose.checked) {
      this.showAndHideTerms.sahArtrose = true;
    } else {
      this.showAndHideTerms.sahArtrose = false;
    }
    this.rdNormal.checked = false;
    this.rdArtroseMult.checked = false;
    this.catchIDs();
  }

  clickArtroseMult(){
    this.showAndHideTerms.sahArtrose = false;
    this.ckArtrose.checked = false;
    this.rdNormal.checked = false;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.ckArtrose.checked) {
      this.termoSelecionado.emit(this.ckArtrose.id);
      if(this.slNivel && this.slNivel.value){
        const objeto = {elemento: this.slNivel, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdArtroseMult.checked) {
      this.termoSelecionado.emit(this.rdArtroseMult.id);
    }

    this.listaCompleta.emit();
  }

}
