import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pos-cirurgico',
  templateUrl: './pos-cirurgico.component.html',
  styleUrls: ['./pos-cirurgico.component.css']
})
export class PosCirurgicoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('ckArtrodese') ckArtrodese: MatCheckbox;
  @ViewChild('ckArtrodeseSoltura') ckArtrodeseSoltura: MatCheckbox;
  @ViewChild('ckArtrodeseFraturaParafuso') ckArtrodeseFraturaParafuso: MatCheckbox;
  @ViewChild('ckArtrodeseFraturaHaste') ckArtrodeseFraturaHaste: MatCheckbox;
  @ViewChild('ckEspacadorDiscal') ckEspacadorDiscal: MatCheckbox;
  @ViewChild('ckEspacadorDiscalAncora') ckEspacadorDiscalAncora: MatCheckbox;
  @ViewChild('ckProteseDiscal') ckProteseDiscal: MatCheckbox;

  @ViewChild('slLateralidadeArtrodeseSoltura') slLateralidadeArtrodeseSoltura: MatSelect;
  @ViewChild('slLateralidadeArtrodeseFraturaParafuso') slLateralidadeArtrodeseFraturaParafuso: MatSelect;
  @ViewChild('slArtrodeseFraturaHaste') slArtrodeseFraturaHaste: MatSelect;

  lateralidadeArtrodeseSoltura: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
  ];

  lateralidadeArtrodeseFraturaParafuso: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
  ];

  lateralidadeArtrodeseFraturaHaste: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  artrodeseSolturaFormGroup: FormGroup;
  artrodeseFraturaParafusoFormGroup: FormGroup;
  ArtrodeseFraturaHasteFormGroup: FormGroup;
  EspacadorDiscalFormGroup: FormGroup;
  EspacadorDiscalAncoraFormGroup: FormGroup;
  ProteseDiscalFormGroup: FormGroup;

  lesaoArtrodeseSoltura: any;
  lesaoArtrodeseFraturaParafuso: any;
  lesaoArtrodeseFraturaHaste: any;
  lesaoEspacadorDiscal: any;
  lesaoEspacadorDiscalAncora: any;
  lesaoProteseDiscal: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.iniciaFormLesaoLitica();
    this.iniciaFormLesaoArtrodeseFraturaParafuso();
    this.iniciaFormLesaoArtrodeseFraturaHaste();
    this.iniciaFormLesaoEspacadorDiscal();
    this.iniciaFormLesaoEspacadorDiscalAncora();
    this.iniciaFormLesaoProteseDiscal();

    this.lesaoArtrodeseSoltura = [
      { id: 'artrodeseT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'artrodeseL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'artrodeseL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'artrodeseL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'artrodeseL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'artrodeseL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.lesaoArtrodeseFraturaParafuso = [
      { id: 'ArtrodeseFraturaParafusoT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'ArtrodeseFraturaParafusoL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'ArtrodeseFraturaParafusoL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'ArtrodeseFraturaParafusoL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'ArtrodeseFraturaParafusoL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'ArtrodeseFraturaParafusoL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.lesaoArtrodeseFraturaHaste = [
      { id: 'ArtrodeseFraturaHasteT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'ArtrodeseFraturaHasteL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'ArtrodeseFraturaHasteL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'ArtrodeseFraturaHasteL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'ArtrodeseFraturaHasteL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'ArtrodeseFraturaHasteL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.lesaoEspacadorDiscal = [
      { id: 'EspacadorDiscalT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'EspacadorDiscalL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'EspacadorDiscalL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'EspacadorDiscalL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'EspacadorDiscalL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'EspacadorDiscalL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.lesaoEspacadorDiscalAncora = [
      { id: 'EspacadorDiscalAncoraT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'EspacadorDiscalAncoraL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'EspacadorDiscalAncoraL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'EspacadorDiscalAncoraL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'EspacadorDiscalAncoraL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'EspacadorDiscalAncoraL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.lesaoProteseDiscal = [
      { id: 'lesaoProteseDiscalT12-L1', value: 'T12-L1', name: 'T12-L1' },
      { id: 'lesaoProteseDiscalL1-L2', value: 'L1-L2', name: 'L1-L2' },
      { id: 'lesaoProteseDiscalL2-L3', value: 'L2-L3', name: 'L2-L3' },
      { id: 'lesaoProteseDiscalL3-L4', value: 'L3-L4', name: 'L3-L4' },
      { id: 'lesaoProteseDiscalL4-L5', value: 'L4-L5', name: 'L4-L5' },
      { id: 'lesaoProteseDiscalL5-S1', value: 'L5-S1', name: 'L5-S1' },
    ];

    this.showAndHideTerms = {
      sahArtrodeseSoltura: false,
      sahArtrodeseFraturaParafuso: false,
      sahArtrodeseFraturaHaste: false,
      sahEspacadorDiscal: false,
      sahEspacadorDiscalAncora: false,
      sahProteseDiscal: false,
    };
  }

  clickArtrodese(){
    this.catchIDs();
  }

  clickckArtrodeseSoltura(){
    if (this.ckArtrodeseSoltura.checked) {
      this.showAndHideTerms.sahArtrodeseSoltura = true
    } else {
      this.showAndHideTerms.sahArtrodeseSoltura = false
    }

    this.catchIDs();
  }

  clickArtrodeseFraturaParafuso(){
    if (this.ckArtrodeseFraturaParafuso.checked) {
      this.showAndHideTerms.sahArtrodeseFraturaParafuso = true
    } else {
      this.showAndHideTerms.sahArtrodeseFraturaParafuso = false
    }

    this.catchIDs();
  }

  clickArtrodeseFraturaHaste(){
    if (this.ckArtrodeseFraturaHaste.checked) {
      this.showAndHideTerms.sahArtrodeseFraturaHaste = true
    } else {
      this.showAndHideTerms.sahArtrodeseFraturaHaste = false
    }

    this.catchIDs();
  }

  clickEspacadorDiscal(){
    if (this.ckEspacadorDiscal.checked) {
      this.showAndHideTerms.sahEspacadorDiscal = true
    } else {
      this.showAndHideTerms.sahEspacadorDiscal = false
    }

    this.catchIDs();
  }

  clickEspacadorDiscalAncora(){
    if (this.ckEspacadorDiscalAncora.checked) {
      this.showAndHideTerms.sahEspacadorDiscalAncora = true
    } else {
      this.showAndHideTerms.sahEspacadorDiscalAncora = false
    }

    this.catchIDs();
  }

  clickProteseDiscal(){
    if (this.ckProteseDiscal.checked) {
      this.showAndHideTerms.sahProteseDiscal = true
    } else {
      this.showAndHideTerms.sahProteseDiscal = false
    }

    this.catchIDs();
  }

  clickCheckboxArtrodeseSoltura(event) {
    let lesaoArtrodeseSoltura= <FormArray>this.artrodeseSolturaFormGroup.get('lesaoArtrodeseSoltura') as FormArray;

    if (event.checked) {
      lesaoArtrodeseSoltura.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoArtrodeseSoltura.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoArtrodeseSoltura.removeAt(iEsc);
    }

    this.catchIDs();
  }

  clickCheckboxArtrodeseFraturaParafuso(event) {
    let lesaoEsc= <FormArray>this.artrodeseFraturaParafusoFormGroup.get('lesaoArtrodeseFraturaParafuso') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  clickCheckboxArtrodeseFraturaHaste(event) {
    let lesaoEsc= <FormArray>this.ArtrodeseFraturaHasteFormGroup.get('lesaoArtrodeseFraturaHaste') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  clickCheckboxEspacadorDiscal(event) {
    let lesaoEsc= <FormArray>this.EspacadorDiscalFormGroup.get('lesaoEspacadorDiscal') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  clickCheckboxEspacadorDiscalAncora(event) {
    let lesaoEsc= <FormArray>this.EspacadorDiscalAncoraFormGroup.get('lesaoEspacadorDiscalAncora') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  clickCheckboxProteseDiscal(event) {
    let lesaoEsc= <FormArray>this.ProteseDiscalFormGroup.get('lesaoProteseDiscal') as FormArray;

    if (event.checked) {
      lesaoEsc.push(new FormControl(event.source.value.split(' ')[0]));
    } else {
      let iEsc = lesaoEsc.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);
      lesaoEsc.removeAt(iEsc);
    }

    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  iniciaFormLesaoLitica() {
    this.artrodeseSolturaFormGroup = this.fb.group({
      lesaoArtrodeseSoltura: this.fb.array([])
    });
  }

  iniciaFormLesaoArtrodeseFraturaParafuso() {
    this.artrodeseFraturaParafusoFormGroup = this.fb.group({
      lesaoArtrodeseFraturaParafuso: this.fb.array([])
    });
  }

  iniciaFormLesaoArtrodeseFraturaHaste() {
    this.ArtrodeseFraturaHasteFormGroup = this.fb.group({
      lesaoArtrodeseFraturaHaste: this.fb.array([])
    });
  }

  iniciaFormLesaoEspacadorDiscal() {
    this.EspacadorDiscalFormGroup = this.fb.group({
      lesaoEspacadorDiscal: this.fb.array([])
    });
  }

  iniciaFormLesaoEspacadorDiscalAncora() {
    this.EspacadorDiscalAncoraFormGroup = this.fb.group({
      lesaoEspacadorDiscalAncora: this.fb.array([])
    });
  }

  iniciaFormLesaoProteseDiscal() {
    this.ProteseDiscalFormGroup = this.fb.group({
      lesaoProteseDiscal: this.fb.array([])
    });
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.ckArtrodeseSoltura.checked) {
      this.iniciaFormLesaoLitica();
    }

    if (!this.ckArtrodeseFraturaParafuso.checked) {
      this.iniciaFormLesaoArtrodeseFraturaParafuso();;
    }

    if (!this.ckArtrodeseFraturaHaste.checked) {
      this.iniciaFormLesaoArtrodeseFraturaHaste();;
    }

    if (!this.ckEspacadorDiscal.checked) {
      this.iniciaFormLesaoEspacadorDiscal();;
    }

    if (!this.ckEspacadorDiscalAncora.checked) {
      this.iniciaFormLesaoEspacadorDiscalAncora();;
    }

    if (!this.ckProteseDiscal.checked) {
      this.iniciaFormLesaoProteseDiscal();;
    }

    this.emitArtrodese()

    if(this.countLesoes > 0){
      this.emitLesaoArtrodeseSoltura();
    } else {
      this.emitLesaoArtrodeseSolturaSimples();
    }

    if(this.countLesoes > 0){
      this.emitLesaoArtrodeseFraturaParafuso();
    } else {
      this.emitLesaoArtrodeseFraturaParafusoSimples();
    }

    if(this.countLesoes > 0){
      this.emitLesaoArtrodeseFraturaHaste();
    } else {
      this.emitLesaoArtrodeseFraturaHasteSimples();
    }

    if(this.countLesoes > 0){
      this.emitLesaoEspacadorDiscal();
    } else {
      this.emitLesaoEspacadorDiscalSimples();
    }

    if(this.countLesoes > 0){
      this.emitLesaoEspacadorDiscalAncora();
    } else {
      this.emitLesaoEspacadorDiscalAncoraSimples();
    }

    if(this.countLesoes > 0){
      this.emitLesaoProteseDiscal();
    } else {
      this.emitLesaoProteseDiscalSimples();
    }

    if(!this.ckArtrodese.checked && !this.ckArtrodeseSoltura.checked && !this.ckProteseDiscal.checked &&
      !this.ckArtrodeseFraturaParafuso.checked  && !this.ckArtrodeseFraturaHaste.checked
     && !this.ckEspacadorDiscal.checked && !this.ckEspacadorDiscalAncora.checked){
        this.termoSelecionado.emit(5336);
      }

    this.listaCompleta.emit();
  }

  emitArtrodese(){
    if(this.ckArtrodese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodese.id);
    }
  }

  emitLesaoArtrodeseSoltura(){
    if(this.ckArtrodeseSoltura && this.ckArtrodeseSoltura.checked){
      this.countLesoes++;

      const objeto = { id: this.ckArtrodeseSoltura.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.artrodeseSolturaFormGroup.value.lesaoArtrodeseSoltura;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5307, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5307, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.slLateralidadeArtrodeseSoltura && this.slLateralidadeArtrodeseSoltura.value){
      const objeto = {elemento: this.slLateralidadeArtrodeseSoltura, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
  }

  emitLesaoArtrodeseSolturaSimples(){
    if(this.ckArtrodeseSoltura && this.ckArtrodeseSoltura.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseSoltura.id);

      let posicao = this.artrodeseSolturaFormGroup.value.lesaoArtrodeseSoltura;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5307, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5307, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.slLateralidadeArtrodeseSoltura && this.slLateralidadeArtrodeseSoltura.value){
      const objeto = {elemento: this.slLateralidadeArtrodeseSoltura, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
  }

  emitLesaoArtrodeseFraturaParafuso(){
    if(this.ckArtrodeseFraturaParafuso && this.ckArtrodeseFraturaParafuso.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseFraturaParafuso.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.artrodeseFraturaParafusoFormGroup.value.lesaoArtrodeseFraturaParafuso;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5310, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5310, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.slLateralidadeArtrodeseFraturaParafuso && this.slLateralidadeArtrodeseFraturaParafuso.value){
      const objeto = {elemento: this.slLateralidadeArtrodeseFraturaParafuso, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
  }

  emitLesaoArtrodeseFraturaParafusoSimples(){
    if(this.ckArtrodeseFraturaParafuso && this.ckArtrodeseFraturaParafuso.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseFraturaParafuso.id);

      let posicao = this.artrodeseFraturaParafusoFormGroup.value.lesaoArtrodeseFraturaParafuso;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5310, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5310, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.slLateralidadeArtrodeseFraturaParafuso && this.slLateralidadeArtrodeseFraturaParafuso.value){
      const objeto = {elemento: this.slLateralidadeArtrodeseFraturaParafuso, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
  }

  emitLesaoArtrodeseFraturaHaste(){
    if(this.ckArtrodeseFraturaHaste && this.ckArtrodeseFraturaHaste.checked){
      this.countLesoes++;
      const objeto = { id: this.ckArtrodeseFraturaHaste.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.ArtrodeseFraturaHasteFormGroup.value.lesaoArtrodeseFraturaHaste;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5319, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5319, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.slArtrodeseFraturaHaste && this.slArtrodeseFraturaHaste.value){
      const objeto = {elemento: this.slArtrodeseFraturaHaste, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
  }

  emitLesaoArtrodeseFraturaHasteSimples(){
    if(this.ckArtrodeseFraturaHaste && this.ckArtrodeseFraturaHaste.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckArtrodeseFraturaHaste.id);

      let posicao = this.ArtrodeseFraturaHasteFormGroup.value.lesaoArtrodeseFraturaHaste;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5319, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5319, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }

    if(this.slArtrodeseFraturaHaste && this.slArtrodeseFraturaHaste.value){
      const objeto = {elemento: this.slArtrodeseFraturaHaste, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
  }

  emitLesaoEspacadorDiscal(){
    if(this.ckEspacadorDiscal && this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.EspacadorDiscalFormGroup.value.lesaoEspacadorDiscal;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5312, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5312, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitLesaoEspacadorDiscalSimples(){
    if(this.ckEspacadorDiscal && this.ckEspacadorDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscal.id);

      let posicao = this.EspacadorDiscalFormGroup.value.lesaoEspacadorDiscal;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5312, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5312, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitLesaoEspacadorDiscalAncora(){
    if(this.ckEspacadorDiscalAncora && this.ckEspacadorDiscalAncora.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspacadorDiscalAncora.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.EspacadorDiscalAncoraFormGroup.value.lesaoEspacadorDiscalAncora;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5314, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5314, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitLesaoEspacadorDiscalAncoraSimples(){
    if(this.ckEspacadorDiscalAncora && this.ckEspacadorDiscalAncora.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspacadorDiscalAncora.id);

      let posicao = this.EspacadorDiscalAncoraFormGroup.value.lesaoEspacadorDiscalAncora;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5314, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5314, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitLesaoProteseDiscal(){
    if(this.ckProteseDiscal && this.ckProteseDiscal.checked){
      this.countLesoes++;
      const objeto = { id: this.ckProteseDiscal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.ProteseDiscalFormGroup.value.lesaoProteseDiscal;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5316, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5316, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: false };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  emitLesaoProteseDiscalSimples(){
    if(this.ckProteseDiscal && this.ckProteseDiscal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckProteseDiscal.id);

      let posicao = this.ProteseDiscalFormGroup.value.lesaoProteseDiscal;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if (posicao.length > 1) {
        let objeto = { id: 5316, value: posicaoTratada };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);

      } else if (posicao.length == 1) {
        let objeto = { id: 5316, value: posicao };
        let elemento = { elemento: objeto, isPrimeiraLesao: true };
        this.termosGenericos.emit(elemento);
      }
    }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}
