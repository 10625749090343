import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-tendoes-pata',
  templateUrl: './tendoes-pata.component.html',
  styleUrls: ['./tendoes-pata.component.css']
})
export class TendoesPataComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdPeritendinobursite') rdPeritendinobursite: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickNormal() {
    this.catchIDs();
  }

  clickPeritendinobursite() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdPeritendinobursite.checked) {
      this.termoSelecionado.emit(this.rdPeritendinobursite.value);
    }

    this.listaCompleta.emit();
  }
}
