import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-superficie-ossea-ombro',
  templateUrl: './superficie-ossea-ombro.component.html',
  styleUrls: ['./superficie-ossea-ombro.component.css']
})
export class SuperficieOsseaOmbroComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdIrregularidades') rdIrregularidades: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'no tubérculo maior', viewValue: 'Tubérculo maior' },
    { value: 'no tubérculo menor', viewValue: 'Tubérculo menor' },
    { value: 'nos tubérculos', viewValue: 'Tubérculos' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahIrregularidades: true
    };
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahIrregularidades = false;
    this.catchIDs();
  }

  clickNormal() {
    this.showAndHideTerms.sahIrregularidades = false;
    this.catchIDs();
  }

  clickIrregularidade() {
    this.showAndHideTerms.sahIrregularidades = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slLocalizacao && this.slLocalizacao.value)){
      this.rdIrregularidades.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.value);
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdIrregularidades.checked) {
      this.termoSelecionado.emit(this.rdIrregularidades.value);
      
      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
