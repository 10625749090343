import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatCheckbox, MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { PercentilIpMediaDialogComponent } from 'src/app/utils/dialog/table/percentil-ip-medio-dialog/percentil-ip-medio-dialog.component';

@Component({
  selector: 'app-velocimetria-doppler',
  templateUrl: './velocimetria-doppler.component.html',
  styleUrls: ['./velocimetria-doppler.component.css']
})
export class VelocimetriaDopplerComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;

  @ViewChild('inIPDir') inIPDir: ElementRef;
  @ViewChild('slMorfologiaUtdDir') slMorfologiaUtdDir: MatSelect;
  @ViewChild('ckSemDir') ckSemDir: MatCheckbox;

  @ViewChild('inIPEsq') inIPEsq: ElementRef;
  @ViewChild('slMorfologiaUtdEsq') slMorfologiaUtdEsq: MatSelect;
  @ViewChild('ckSemEsq') ckSemEsq: MatCheckbox;

  @ViewChild('inIpMedio') inIpMedio: ElementRef;
  @ViewChild('inPercIpMedio') inPercIpMedio: ElementRef;

  showAndHideTerms: any;
  countLesoes: number;
  ipMedio = 'IP Médio';

  morfologias: ArrayDropDown[] = [
    { value: 'com incisura protodiastólica', viewValue: 'Com incisura' },
    { value: 'sem incisura protodiastólica', viewValue: 'Sem incisura' }
  ];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false,
      sahSemDir: false,
      sahSemEsq: false,
    }
  }

  ClickNaoCitar() {
    this.showAndHideTerms.sahCitar = false;
    this.showAndHideTerms.sahSemDir = false;
    this.showAndHideTerms.sahSemEsq = false;
    this.catchIDs()
  }

  clickCitar() {
    this.showAndHideTerms.sahCitar = true;
  }

  clickSemDir() {
    if (this.showAndHideTerms.sahSemDir == false) {
      this.showAndHideTerms.sahSemDir = true;
      this.rdCitar.checked = true;
    } else {
      this.showAndHideTerms.sahSemDir = false;
      this.slMorfologiaUtdDir.value = '';
    }
    this.catchIDs()
  }

  clickSemEsq() {
    if (this.showAndHideTerms.sahSemEsq == false) {
      this.showAndHideTerms.sahSemEsq = true;
      this.rdCitar.checked = true;
    } else {
      this.showAndHideTerms.sahSemEsq = false;
      this.slMorfologiaUtdEsq.value = '';
    }
    this.catchIDs()
  }

  changeSelect() {
    this.rdCitar.checked = true;
    this.catchIDs();
  }

  blurParametros() {
    this.rdCitar.checked = true;
    this.catchIDs();
  }

  clickTabelaReferencia() {
    this.dialog.open(PercentilIpMediaDialogComponent, {});
  }

  catchIDs() {
    debugger;
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar && this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);

    } else if (this.rdCitar && this.rdCitar.checked) {

      if (this.inIPDir && this.inIPDir.nativeElement.value) {
        const objeto = { elemento: this.inIPDir.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slMorfologiaUtdDir && this.slMorfologiaUtdDir.value) {
        const objeto = { elemento: this.slMorfologiaUtdDir, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inIPEsq && this.inIPEsq.nativeElement.value) {
        const objeto = { elemento: this.inIPEsq.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slMorfologiaUtdEsq && this.slMorfologiaUtdEsq.value) {
        const objeto = { elemento: this.slMorfologiaUtdEsq, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inPercIpMedio && this.inPercIpMedio.nativeElement.value) {
        const objeto = { elemento: this.inPercIpMedio.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.calculaIpMedio();
    }

    this.listaCompleta.emit();
  }

  calculaIpMedio() {
    debugger;
    let resultado = 0;
    // ipMedio
    resultado = parseFloat(this.inIPDir.nativeElement.value);
    resultado = resultado + parseFloat(this.inIPEsq.nativeElement.value);
    resultado = resultado / 2;
    this.ipMedio = resultado.toPrecision(3).toString();

    if (this.inIpMedio && this.inIpMedio.nativeElement.value) {
      const objeto = { elemento: this.inIpMedio.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    } else {
      const objetoElemento2 = { id: this.inIpMedio.nativeElement.id, value: this.ipMedio };
      const objeto2 = { elemento: objetoElemento2, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto2);
    }
  }

}
