import { ArrayDropDown } from './../../../../../models/Helper/ArrayDropDown';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-alinhamento',
  templateUrl: './alinhamento.component.html',
  styleUrls: ['./alinhamento.component.css']
})
export class AlinhamentoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckNormalManobra') ckNormalManobra: MatCheckbox;
  @ViewChild('ckAcentuacao') ckAcentuacao: MatCheckbox;
  @ViewChild('ckEscoliose') ckEscoliose: MatCheckbox;
  @ViewChild('ckRotacaoAxial') ckRotacaoAxial: MatCheckbox;
  @ViewChild('ckEscolioseSinuosa') ckEscolioseSinuosa: MatCheckbox;
  @ViewChild('ckAnterolistese') ckAnterolistese: MatCheckbox;
  @ViewChild('ckEspondilolise') ckEspondilolise: MatCheckbox;
  @ViewChild('ckEspondiloliseUnica') ckEspondiloliseUnica: MatCheckbox;
  @ViewChild('ckEspondiloliseMult') ckEspondiloliseMult: MatCheckbox;
  @ViewChild('ckRetrolistese') ckRetrolistese: MatCheckbox;

  @ViewChild('slLateralidadeEscoliose') slLateralidadeEscoliose: MatSelect;
  @ViewChild('slLateralidadeSinuosaUm') slLateralidadeSinuosaUm: MatSelect;
  @ViewChild('slNivelSinuosaUm') slNivelSinuosaUm: MatSelect;
  @ViewChild('slLateralidadeSinuosaDois') slLateralidadeSinuosaDois: MatSelect;
  @ViewChild('slNivelSinuosaDois') slNivelSinuosaDois: MatSelect;
  @ViewChild('slGrauAnterolistese') slGrauAnterolistese: MatSelect;
  @ViewChild('slNivelAnterolistese') slNivelAnterolistese: MatSelect;
  @ViewChild('slNivelEspondiloliseUnica') slNivelEspondiloliseUnica: MatSelect;
  @ViewChild('slLateralidadeEspondiloliseUnica') slLateralidadeEspondiloliseUnica: MatSelect;
  @ViewChild('slNivelEspondiloliseMultDireita') slNivelEspondiloliseMultDireita: MatSelect;
  @ViewChild('slNivelEspondiloliseMultEsquerda') slNivelEspondiloliseMultEsquerda: MatSelect;
  @ViewChild('slGrauRetrolistese') slGrauRetrolistese: MatSelect;
  @ViewChild('slNivelRetrolistese') slNivelRetrolistese: MatSelect;

  lateralidadeEscoliose: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
  ];

  lateralidadeSinuosa: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
  ];

  nivelSinuosaUm: ArrayDropDown[] = [
    { value: 'torácico', viewValue: 'Torácico' },
    { value: 'toracolombar', viewValue: 'Toracolombar' },
  ];

  nivelSinuosaDois: ArrayDropDown[] = [
    { value: 'toracolombar', viewValue: 'Toracolombar' },
    { value: 'lombar', viewValue: 'Lombar' },
  ];

  grauAnterolistese: ArrayDropDown[] = [
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' },
    { value: 'IV', viewValue: 'IV' },
    { value: 'V', viewValue: 'V' },
  ];

  nivelAnterolistese: ArrayDropDown[] = [
    { value: 'L1', viewValue: 'L1' },
    { value: 'L2', viewValue: 'L2' },
    { value: 'L3', viewValue: 'L3' },
    { value: 'L4', viewValue: 'L4' },
    { value: 'L5', viewValue: 'L5' },
  ];

  nivelEspondilolise: ArrayDropDown[] = [
    { value: 'L1', viewValue: 'L1' },
    { value: 'L2', viewValue: 'L2' },
    { value: 'L3', viewValue: 'L3' },
    { value: 'L4', viewValue: 'L4' },
    { value: 'L5', viewValue: 'L5' },
  ];

  lateralidadeEspondilolise: ArrayDropDown[] = [
    { value: 'à esquerda', viewValue: 'À esquerda' },
    { value: 'à direita', viewValue: 'À direita' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  grauRetrolistese: ArrayDropDown[] = [
    { value: 'Pequena', viewValue: 'Pequena' },
    { value: 'Moderada', viewValue: 'Moderada' },
    { value: 'Acentuada', viewValue: 'Acentuada' },
  ];

  nivelRetrolistese: ArrayDropDown[] = [
    { value: 'L1', viewValue: 'L1' },
    { value: 'L2', viewValue: 'L2' },
    { value: 'L3', viewValue: 'L3' },
    { value: 'L4', viewValue: 'L4' },
    { value: 'L5', viewValue: 'L5' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEscoliose: false,
      sahEscolioseSinuosa: false,
      sahAnterolistese: false,
      sahEspondiloliseUnica: false,
      sahEspondiloliseMult: false,
      sahRetrolistese: false,
    };
  }

  clickNormal(){
    this.showAndHideTerms.sahEscoliose = false;
    this.showAndHideTerms.sahEscolioseSinuosa = false;
    this.showAndHideTerms.sahAnterolistese = false;
    this.showAndHideTerms.sahEspondilolise = false;
    this.showAndHideTerms.sahEspondiloliseUnica = false;
    this.showAndHideTerms.sahEspondiloliseMult = false;
    this.showAndHideTerms.sahRetrolistese = false;
    this.ckNormalManobra.checked = false;
    this.ckAcentuacao.checked = false;
    this.ckEscoliose.checked = false;
    this.ckEscolioseSinuosa.checked = false;
    this.ckAnterolistese.checked = false;
    this.ckEspondiloliseUnica.checked = false;
    this.ckEspondiloliseMult.checked = false;
    this.ckRetrolistese.checked = false;
    this.catchIDs();
  }

  clickNormalManobra(){
    this.rdAlterado.checked = true;
    this.checkNormal();
    this.catchIDs();
  }

  clickAcentuacao(){
    this.rdAlterado.checked = true;
    this.checkNormal();
    this.catchIDs();
  }

  clickEscoliose(){
    this.rdAlterado.checked = true;
    this.showAndHideTerms.sahEscoliose = !this.showAndHideTerms.sahEscoliose;
    this.checkNormal();
    this.catchIDs();
  }

  clickRotacaoAxial(){
    this.catchIDs();
  }

  clickEscolioseSinuosa(){
    this.rdAlterado.checked = true;
    this.showAndHideTerms.sahEscolioseSinuosa = !this.showAndHideTerms.sahEscolioseSinuosa
    this.checkNormal();
    this.catchIDs();
  }

  clickAnterolistese(){
    this.rdAlterado.checked = true;
    this.showAndHideTerms.sahAnterolistese = !this.showAndHideTerms.sahAnterolistese
    this.checkNormal();
    this.catchIDs();
  }

  clickEspondiloliseUnica(){
    this.rdAlterado.checked = true;
    this.showAndHideTerms.sahEspondiloliseMult = false;
    this.ckEspondiloliseMult.checked = false;
    this.showAndHideTerms.sahEspondiloliseUnica = !this.showAndHideTerms.sahEspondiloliseUnica;
    this.checkNormal();
    this.catchIDs();
  }

  clickEspondiloliseMult(){
    this.rdAlterado.checked = true;
    this.showAndHideTerms.sahEspondiloliseUnica = false;
    this.ckEspondiloliseUnica.checked = false;
    this.showAndHideTerms.sahEspondiloliseMult = !this.showAndHideTerms.sahEspondiloliseMult;
    this.checkNormal();
    this.catchIDs();
  }

  clickRetrolistese(){
    this.rdAlterado.checked = true;
    this.showAndHideTerms.sahRetrolistese = !this.showAndHideTerms.sahRetrolistese
    this.checkNormal();
    this.catchIDs();
  }

  checkNormal() {
    if (this.ckNormalManobra.checked ||
      this.ckAcentuacao.checked ||
      this.ckEscoliose.checked ||
      this.ckEscolioseSinuosa.checked ||
      this.ckAnterolistese.checked ||
      this.ckEspondiloliseUnica.checked ||
      this.ckEspondiloliseMult.checked ||
      this.ckRetrolistese.checked ) {
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
    }
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitNormal();

    if(this.countLesoes > 0){
      this.emitNormalManobraLesao();
    } else {
      this.emitNormalManobraSimples();
    }

    if(this.countLesoes > 0){
      this.emitAcentuacaoLesao();
    } else {
      this.emitAcentuacaoSimples();
    }

    if(this.countLesoes > 0){
      this.emitEscolioseLesao();
    } else {
      this.emitEscolioseSimples();
    }

    if(this.countLesoes > 0){
      this.emitEscolioseSinuosaLesao();
    } else {
      this.emitEscolioseSinuosaSimples();
    }

    if(this.countLesoes > 0){
      this.emitAnterolisteseLesao();
    } else {
      this.emitAnterolisteseSimples();
    }

    if(this.countLesoes > 0){
      this.emitEspondiloliseLesao();
    } else {
      this.emitEspondiloliseSimples();
    }

    if(this.countLesoes > 0){
      this.emitEspondiloliseMultLesao();
    } else {
      this.emitEspondiloliseMultSimples();
    }

    if(this.countLesoes > 0){
      this.emitRetrolisteseLesao();
    } else {
      this.emitRetrolisteseSimples();
    }

    this.listaCompleta.emit();

  }

  emitNormal(){
    if(this.rdNormal.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdNormal.id);
    }
  }

  emitNormalManobraLesao(){
    if(this.ckNormalManobra.checked){
      this.countLesoes++;
      const objeto = { id: this.ckNormalManobra.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitNormalManobraSimples(){
    if(this.ckNormalManobra.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNormalManobra.id);
    }
  }

  emitAcentuacaoLesao(){
    if(this.ckAcentuacao.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAcentuacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitAcentuacaoSimples(){
    if(this.ckAcentuacao.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAcentuacao.id);
    }
  }

  emitEscolioseLesao(){
    if(this.ckEscoliose.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEscoliose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeEscoliose && this.slLateralidadeEscoliose.value){
        const objeto = {elemento: this.slLateralidadeEscoliose, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.ckRotacaoAxial.checked){
        let objetoMultiplo= { id:this.ckRotacaoAxial.id, value:this.ckRotacaoAxial.value };
        let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: false};
        this.termosGenericos.emit(elementoMultiplo);
      }
    }
  }

  emitEscolioseSimples(){
    if(this.ckEscoliose.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEscoliose.id);

      if(this.slLateralidadeEscoliose && this.slLateralidadeEscoliose.value){
        const objeto = {elemento: this.slLateralidadeEscoliose, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }

      if(this.ckRotacaoAxial.checked){
        let objetoMultiplo= {id:this.ckRotacaoAxial.id, value:this.ckRotacaoAxial.value };
        let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
        this.termosGenericos.emit(elementoMultiplo);
      }

    }
  }

  emitEscolioseSinuosaLesao(){
    if(this.ckEscolioseSinuosa.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEscolioseSinuosa.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slLateralidadeSinuosaUm && this.slLateralidadeSinuosaUm.value){
      const objeto = {elemento: this.slLateralidadeSinuosaUm, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelSinuosaUm && this.slNivelSinuosaUm.value){
      const objeto = {elemento: this.slNivelSinuosaUm, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slLateralidadeSinuosaDois && this.slLateralidadeSinuosaDois.value){
      const objeto = {elemento: this.slLateralidadeSinuosaDois, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelSinuosaDois && this.slNivelSinuosaDois.value){
      const objeto = {elemento: this.slNivelSinuosaDois, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitEscolioseSinuosaSimples(){
    if(this.ckEscolioseSinuosa.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEscolioseSinuosa.id);

    if(this.slLateralidadeSinuosaUm && this.slLateralidadeSinuosaUm.value){
      const objeto = {elemento: this.slLateralidadeSinuosaUm, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelSinuosaUm && this.slNivelSinuosaUm.value){
      const objeto = {elemento: this.slNivelSinuosaUm, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slLateralidadeSinuosaDois && this.slLateralidadeSinuosaDois.value){
      const objeto = {elemento: this.slLateralidadeSinuosaDois, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelSinuosaDois && this.slNivelSinuosaDois.value){
      const objeto = {elemento: this.slNivelSinuosaDois, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitAnterolisteseLesao(){
    if(this.ckAnterolistese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAnterolistese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slGrauAnterolistese && this.slGrauAnterolistese.value){
      const objeto = {elemento: this.slGrauAnterolistese, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelAnterolistese && this.slNivelAnterolistese.value){
      const objeto = {elemento: this.slNivelAnterolistese, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitAnterolisteseSimples(){
    if(this.ckAnterolistese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAnterolistese.id);

    if(this.slGrauAnterolistese && this.slGrauAnterolistese.value){
      const objeto = {elemento: this.slGrauAnterolistese, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelAnterolistese && this.slNivelAnterolistese.value){
      const objeto = {elemento: this.slNivelAnterolistese, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitEspondiloliseLesao(){
    if(this.ckEspondiloliseUnica.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspondiloliseUnica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelEspondiloliseUnica && this.slNivelEspondiloliseUnica.value){
      const objeto = {elemento: this.slNivelEspondiloliseUnica, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slLateralidadeEspondiloliseUnica && this.slLateralidadeEspondiloliseUnica.value){
      const objeto = {elemento: this.slLateralidadeEspondiloliseUnica, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitEspondiloliseSimples(){
    if(this.ckEspondiloliseUnica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspondiloliseUnica.id);

    if(this.slNivelEspondiloliseUnica && this.slNivelEspondiloliseUnica.value){
      const objeto = {elemento: this.slNivelEspondiloliseUnica, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slLateralidadeEspondiloliseUnica && this.slLateralidadeEspondiloliseUnica.value){
      const objeto = {elemento: this.slLateralidadeEspondiloliseUnica, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitEspondiloliseMultLesao(){
    if(this.ckEspondiloliseMult.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEspondiloliseMult.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slNivelEspondiloliseMultDireita && this.slNivelEspondiloliseMultDireita.value){
      const objeto = {elemento: this.slNivelEspondiloliseMultDireita, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelEspondiloliseMultEsquerda && this.slNivelEspondiloliseMultEsquerda.value){
      const objeto = {elemento: this.slNivelEspondiloliseMultEsquerda, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitEspondiloliseMultSimples(){
    if(this.ckEspondiloliseMult.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspondiloliseMult.id);

    if(this.slNivelEspondiloliseMultDireita && this.slNivelEspondiloliseMultDireita.value){
      const objeto = {elemento: this.slNivelEspondiloliseMultDireita, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelEspondiloliseMultEsquerda && this.slNivelEspondiloliseMultEsquerda.value){
      const objeto = {elemento: this.slNivelEspondiloliseMultEsquerda, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitRetrolisteseLesao(){
    if(this.ckRetrolistese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckRetrolistese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    if(this.slGrauRetrolistese && this.slGrauRetrolistese.value){
      const objeto = {elemento: this.slGrauRetrolistese, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelRetrolistese && this.slNivelRetrolistese.value){
      const objeto = {elemento: this.slNivelRetrolistese, isPrimeiraLesao: false}
      this.termosGenericos.emit(objeto);
    }
    }
  }

  emitRetrolisteseSimples(){
    if(this.ckRetrolistese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRetrolistese.id);

    if(this.slGrauRetrolistese && this.slGrauRetrolistese.value){
      const objeto = {elemento: this.slGrauRetrolistese, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }

    if(this.slNivelRetrolistese && this.slNivelRetrolistese.value){
      const objeto = {elemento: this.slNivelRetrolistese, isPrimeiraLesao: true}
      this.termosGenericos.emit(objeto);
    }
    }
  }


}
