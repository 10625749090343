import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';


@Component({
  selector: 'app-cirurgia',
  templateUrl: './cirurgia.component.html',
  styleUrls: ['./cirurgia.component.css']
})
export class CirurgiaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdSemCirur') rdSemCirur: MatRadioButton;
  @ViewChild('rdMastectomia') rdMastectomia: MatRadioButton;
  @ViewChild('rdQuadrantectomia') rdQuadrantectomia: MatRadioButton;
  @ViewChild('rdMamoplastia') rdMamoplastia: MatRadioButton;

  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('slReconstrucao') slReconstrucao: MatSelect;
  @ViewChild('slLadoBilateral') slLadoBilateral: MatSelect;

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  ladosBilateral: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  reconstrucoes: ArrayDropDown[] = [
    { value: 'prótese', viewValue: 'Prótese' },
    { value: 'retalho miocutâneo', viewValue: 'Retalho miocutâneo' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLesoes: false,
      sahMastectomia: false,
      sahMamoplastia: false
    };
  }

  clickSemCirurg() {
    this.showAndHideTerms.sahLesoes = false;
    this.catchIDs();
  }

  clickMastectomia() {
    this.showAndHideTerms.sahLesoes = true;
    this.showAndHideTerms.sahMastectomia = true;
    this.showAndHideTerms.sahMamoplastia = false;
    this.catchIDs();
  }

  clickQuadrantectomia() {
    this.showAndHideTerms.sahLesoes = true;
    this.showAndHideTerms.sahMastectomia = false;
    this.showAndHideTerms.sahMamoplastia = false;
    this.catchIDs();
  }

  clickMamoplastia() {
    this.showAndHideTerms.sahLesoes = true;
    this.showAndHideTerms.sahMastectomia = false;
    this.showAndHideTerms.sahMamoplastia = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdSemCirur.checked) {
      this.termoSelecionado.emit(this.rdSemCirur.id);
    }

    if (this.rdMastectomia.checked) {
      this.termoSelecionado.emit(this.rdMastectomia.id);

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slReconstrucao && this.slReconstrucao.value) {
        const objeto = { elemento: this.slReconstrucao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdQuadrantectomia.checked) {
      this.termoSelecionado.emit(this.rdQuadrantectomia.id);

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdMamoplastia.checked) {
      this.termoSelecionado.emit(this.rdMamoplastia.id);

      if (this.slLadoBilateral && this.slLadoBilateral.value) {
        if (this.slLadoBilateral.value !== 'bilateral') {
          const objeto = { elemento: this.slLadoBilateral, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          this.termoSelecionado.emit(3988);
        }
      }
    }

    this.listaCompleta.emit();
  }
}
