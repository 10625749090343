import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-mst-liquido-amniotico',
  templateUrl: './mst-liquido-amniotico.component.html',
  styleUrls: ['./mst-liquido-amniotico.component.css']
})
export class MstLiquidoAmnioticoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdOligo') rdOligo: MatRadioButton;
  @ViewChild('rdPoli') rdPoli: MatRadioButton;
  @ViewChild('rdAnid') rdAnid: MatRadioButton;

  @ViewChild('ckMbv') ckMbv: MatCheckbox;
  @ViewChild('inMbv') inMbv: ElementRef;

  showAndHideTerms: any;
  inMBVInvalid: boolean = true;

  constructor() { }

  ngOnInit() {
    // this.showAndHideTerms = {
    //   sahMbv: true,
    // }
  }

  clickDefault() {
    this.catchIDs();
  }

  // clickMbv() {
  //   if (this.showAndHideTerms.sahMbv == false) {
  //     this.showAndHideTerms.sahMbv = true;
  //   } else {
  //     this.showAndHideTerms.sahMbv = false;
  //   }
  // }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.removeInvalidField();

    if (this.inMbv && this.inMbv.nativeElement.value) {
      if(this.inMbv.nativeElement.value == 0){
        this.termoSelecionado.emit(this.rdAnid.id);
        this.changeChecked(this.rdAnid.id);
      }
      if (this.inMbv.nativeElement.value > 0 && this.inMbv.nativeElement.value < 2) {
        this.termoSelecionado.emit(this.rdOligo.id);
        this.changeChecked(this.rdOligo.id);
      } 
      if (this.inMbv.nativeElement.value > 8) {
        this.termoSelecionado.emit(this.rdPoli.id);
        this.changeChecked(this.rdPoli.id);
      } 
      if(this.inMbv.nativeElement.value <= 8 && this.inMbv.nativeElement.value >= 2) {
        this.termoSelecionado.emit(this.rdNormal.id);
        this.changeChecked(this.rdNormal.id);
      }
    }

    if (this.inMbv && this.inMbv.nativeElement.value) {
      const objetoElemento = { id: this.inMbv.nativeElement.id, value: this.inMbv.nativeElement.value + ' cm' };
      const objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
      this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'LiquidoAmniotico'});
    }else{
      this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'LiquidoAmniotico'});
    }

    this.listaCompleta.emit();
  }

  changeChecked(id) {
    if (id === '4710') {
      this.rdNormal.checked = true;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '4711') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = true;
      this.rdPoli.checked = false;
      this.rdAnid.checked = false;
    } else if (id === '4712') {
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = true;
      this.rdAnid.checked = false;
    }else if( id ==='5646'){
      this.rdNormal.checked = false;
      this.rdOligo.checked = false;
      this.rdPoli.checked = false;
      this.rdAnid.checked = true;
    }
  }

  removeInvalidField(){
    this.inMBVInvalid = this.inMbv && this.inMbv.nativeElement.value ? false : true;
  }
}
