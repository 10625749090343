import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import * as moment from 'moment';

export interface LadoMama {
  value: string;
  viewValue: string;
}

export interface Localizacao {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-exame-comparativo',
  templateUrl: './exame-comparativo.component.html',
  styleUrls: ['./exame-comparativo.component.css']
})
export class ExameComparativoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdNaoDisponivel') rdNaoDisponivel: MatRadioButton;
  @ViewChild('rdDisponivel') rdDisponivel: MatRadioButton;

  @ViewChild('rdSemAlteracao') rdSemAlteracao: MatRadioButton;
  @ViewChild('rdNoduloEstavel') rdNoduloEstavel: MatRadioButton;
  @ViewChild('rdCrescimentoNodulo') rdCrescimentoNodulo: MatRadioButton;

  @ViewChild('inDataUltimo') inDataUltimo: ElementRef;
  @ViewChild('inMeses') inMeses: ElementRef;
  @ViewChild('slLadoMama') slLadoMama: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  ladosMama: LadoMama[] = [
    { value: 'da mama direita', viewValue: 'Direita' },
    { value: 'da mama esquerda', viewValue: 'Esquerda' },
    { value: 'das mamas', viewValue: 'Mamas' }
  ];

  localizacoes: Localizacao[] = [
    { value: 'na região retroareolar', viewValue: 'Região retroareolar' },
    { value: 'no quadrante superolateral', viewValue: 'Quadrante superolateral' },
    { value: 'no quadrante superomedial', viewValue: 'Quadrante superomedial' },
    { value: 'no quadrante inferolateral', viewValue: 'Quadrante inferolateral' },
    { value: 'no quadrante inferomedial', viewValue: 'Quadrante inferomedial' }
  ];

  date = new Date();
  // maxDate = Date.now();
  dateSelected = false;
  showAndHideTerms: any;
  estavelHa = 'Estável há';

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNaoDisponivel: true,
      sahNoduloEstavel: false,
      sahCresNodulo: false
    };
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahNaoDisponivel = false;
    this.showAndHideTerms.sahNoduloEstavel = false;
    this.showAndHideTerms.sahCresNodulo = false;
    this.catchIDs();
  }

  clickNaoDisponivel() {
    this.showAndHideTerms.sahNaoDisponivel = false;
    this.showAndHideTerms.sahNoduloEstavel = false;
    this.showAndHideTerms.sahCresNodulo = false;
    this.catchIDs();
  }

  clickDisponivel() {
    this.showAndHideTerms.sahNaoDisponivel = true;
    this.showAndHideTerms.sahNoduloEstavel = false;
    this.catchIDs();
  }

  clickSemAlteracao() {
    this.showAndHideTerms.sahNoduloEstavel = false;
    this.showAndHideTerms.sahCresNodulo = false;
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickNoduloEstavel() {
    this.showAndHideTerms.sahNoduloEstavel = true;
    this.showAndHideTerms.sahCresNodulo = false;
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickCrescimentoNodulo() {
    this.showAndHideTerms.sahCresNodulo = true;
    this.showAndHideTerms.sahNoduloEstavel = false;
    this.alteraAusentePresente();
    this.catchIDs();
  }

  blurData() {
    this.dateSelected = true;
    this.alteraAusentePresente();
    this.catchIDs();
  }

  blurMeses() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeLadoMama() {
    this.catchIDs();
  }

  changeLocalizacao() {
    this.catchIDs();
  }

  catchIDs() {
    this.cdr.detectChanges();
    this.termoSelecionado.emit('Clean');


    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id)
    }

    if (this.rdNaoDisponivel.checked) {
      this.termoSelecionado.emit(this.rdNaoDisponivel.id);
    }

    if (this.rdDisponivel.checked) {
      this.termoSelecionado.emit(this.rdDisponivel.id);

      if (this.rdSemAlteracao != undefined && this.rdSemAlteracao.checked) {
        this.termoSelecionado.emit(this.rdSemAlteracao.id);
      }

      if (this.rdNoduloEstavel != undefined && this.rdNoduloEstavel.checked) {
        this.termoSelecionado.emit(this.rdNoduloEstavel.id);
      }

      if (this.rdCrescimentoNodulo != undefined && this.rdCrescimentoNodulo.checked) {
        this.termoSelecionado.emit(this.rdCrescimentoNodulo.id);
      }

      if (this.inDataUltimo != undefined &&
        this.inDataUltimo.nativeElement.id != undefined &&
        this.inDataUltimo.nativeElement.value != '') {

        let currDay = moment();
        let dtExame = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY');
        let diffMeses = currDay.diff(dtExame, 'months', true);
        let mesesEstavel = Math.floor(diffMeses);
        //mesesEstavel = +mesesEstavel + 1;

        if (mesesEstavel <= 0) {
          this.estavelHa = '0';
        } else {
          this.estavelHa = mesesEstavel.toString();
        }

        const objeto = { elemento: this.inDataUltimo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMeses != undefined &&
        this.inMeses.nativeElement.id != undefined &&
        this.inMeses.nativeElement.value != '' ||
        this.inMeses != undefined && this.estavelHa) {
        let objeto;

        if (!this.inMeses.nativeElement.value) {
          this.inMeses.nativeElement.value = '';
        }

        let quantidadeMeses = this.inMeses.nativeElement.value;
        let idMes = 484;

        // REGRA PARA DEFINIR A CONCLUSÃO DE BIRADS 3 e 2
        debugger
        if (this.inMeses && this.inMeses.nativeElement.value && +this.inMeses.nativeElement.value < 24) {
          idMes = this.inMeses.nativeElement.id;
        } else if (!this.inMeses.nativeElement.value && +this.estavelHa < 24) {
          idMes = this.inMeses.nativeElement.id;
        }

        if (quantidadeMeses === '') {
          quantidadeMeses = this.estavelHa
        }

        const elemento = { id: idMes, value: quantidadeMeses };
        objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMama != undefined && this.slLadoMama.value != undefined) {
        const objeto = { elemento: this.slLadoMama, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.rdSemAlteracao && this.rdSemAlteracao.checked) ||
      (this.rdNoduloEstavel && this.rdNoduloEstavel.checked) ||
      (this.rdCrescimentoNodulo && this.rdCrescimentoNodulo.checked) ||
      (this.inDataUltimo && this.inDataUltimo.nativeElement.value != '')
    ) {
      this.rdDisponivel.checked = true;
    } else {
      this.rdNaoDisponivel.checked = true;
      this.rdDisponivel.checked = false;
    }
  }
}
