import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-angulo-graf-dialog',
  templateUrl: './angulo-graf-dialog.component.html',
  styleUrls: ['./angulo-graf-dialog.component.css']
})
export class AnguloGrafDialogComponent {

  displayedColumns =
  ['tipo', 'tetoOsseo', 'rebordoOsseo', 'cartilagem', 'anguloTetoOsseo','anguloTetoCartilaginoso'];

  dataSource = ELEMENT_DATA;

  displayedFooter = ['*Antes dos 3 meses de idade', '**Após os 3 meses de idade'];

  constructor(
    public dialogRef: MatDialogRef<AnguloGrafDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }

} 

export interface AnguloRefElement {
  tipo: string; 
  tetoOsseo: string;
  rebordoOsseo: string;
  cartilagem: string;
  anguloTetoOsseo: string;
  anguloTetoCartilaginoso: string;
}

const ELEMENT_DATA: AnguloRefElement[] = [
  {tipo: '1a (quadril maduro)', tetoOsseo: 'Bom', rebordoOsseo: 'Angulado' , cartilagem:'Estreito envolvente cobrindo a cabeça femoral' , anguloTetoOsseo: '> 60º' ,anguloTetoCartilaginoso: '< 55º'},
  {tipo: '1b (quadril maduro)', tetoOsseo: 'Bom', rebordoOsseo: 'Em geral levemente arrendondado' , cartilagem:'Espessada; com a base larga; cobrindo a cabeça femoral' , anguloTetoOsseo: '> 60º' ,anguloTetoCartilaginoso: '> 55º'},
  {tipo: '2a + (*) (apropriado para idade)', tetoOsseo: 'Adequado', rebordoOsseo: 'Arredondado' , cartilagem:'Larga e cobrindo bem a cabeça femoral' , anguloTetoOsseo: '50º - 59º' ,anguloTetoCartilaginoso: '> 55º'},
  {tipo: '2a - (*) (deficit de maturaçao)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Arredondado' , cartilagem:'Larga e cobrindo bem a cabeça femoral' , anguloTetoOsseo: '50º - 59º' ,anguloTetoCartilaginoso: '> 55º'},
  {tipo: '2b (**) (retardo de ossificaçao)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Arredondado' , cartilagem:'Larga e cobrindo bem a cabeça femoral' , anguloTetoOsseo: '50º - 59º' ,anguloTetoCartilaginoso: '> 55º'},
  {tipo: '2c faixa crítica (qualquer idade)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Arredondado a oblíquo' , cartilagem:'Larga e cobrindo bem a cabeça femoral' , anguloTetoOsseo: '43º - 49º(intervalo crítico)' ,anguloTetoCartilaginoso: '70º - 77º'},
  {tipo: '2d (quadril em vias de descentraçao)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Arredondado a oblíquo' , cartilagem:'Deslocada para cima' , anguloTetoOsseo: '43º - 49º(intervalo crítico)' ,anguloTetoCartilaginoso: '> 77º'},
  {tipo: '3a (quadril excêntrico)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Oblíquo' , cartilagem:'Deslocada para cima sem alteraçao estrutural' , anguloTetoOsseo: '< 43º' ,anguloTetoCartilaginoso: '> 77º'},
  {tipo: '3b (quadril excêntrico)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Oblíquo' , cartilagem:'Deslocada para cima com alteraçao estrutural' , anguloTetoOsseo: '< 43º' ,anguloTetoCartilaginoso: '> 77º'},
  {tipo: '4 (quadril luxado)', tetoOsseo: 'Insuficiente', rebordoOsseo: 'Oblíquo' , cartilagem:'Deslocada inferiormente' , anguloTetoOsseo: ' - ' ,anguloTetoCartilaginoso: ' - '},
];

