import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-abd-linfonodo',
  templateUrl: './abd-linfonodo.component.html',
  styleUrls: ['./abd-linfonodo.component.css']
})
export class AbdLinfonodoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  // @ViewChild('ckLinfonodoRetro') ckLinfonodoRetro: MatCheckbox;
  @ViewChild('rdLinfonodoRetro') rdLinfonodoRetro: MatRadioButton;
  @ViewChild('rd1LinfonodoRetro') rd1LinfonodoRetro: MatRadioButton;
  @ViewChild('rd2LinfonodoRetro') rd2LinfonodoRetro: MatRadioButton;
  @ViewChild('inMedidaUmRetro1') inMedidaUmRetro1: ElementRef;
  @ViewChild('inMedidaUmRetro2') inMedidaUmRetro2: ElementRef;

  // @ViewChild('ckLinfonodoHiloHepatico') ckLinfonodoHiloHepatico: MatCheckbox;
  @ViewChild('rdLinfonodoHiloHepatico') rdLinfonodoHiloHepatico: MatRadioButton;
  @ViewChild('rd1LinfonodoHiloHepatico') rd1LinfonodoHiloHepatico: MatRadioButton;
  @ViewChild('rd2LinfonodoHiloHepatico') rd2LinfonodoHiloHepatico: MatRadioButton;
  @ViewChild('inMedidaUmHilo1') inMedidaUmHilo1: ElementRef;
  @ViewChild('inMedidaUmHilo2') inMedidaUmHilo2: ElementRef;

  // @ViewChild('ckAdenite') ckAdenite: MatCheckbox;
  @ViewChild('rdAdenite') rdAdenite: MatRadioButton;
  @ViewChild('rd1LinfoPro') rd1LinfoPro: MatRadioButton;
  @ViewChild('rd2Linfonodomegalias') rd2Linfonodomegalias: MatRadioButton;
  @ViewChild('inMedidaUmAde1') inMedidaUmAde1: ElementRef;
  @ViewChild('inMedidaUmAde2') inMedidaUmAde2: ElementRef;


  showAndHideTerms: any;
  obj: any;
  objetofilho: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: false,
      sahLinfonodoRetro: false,
      sahMedidasRetro: false,
      sahLinfonodoHiloHepatico: false,
      sahMedidasHilo: false,
      sahAdenite: false,
      sahMedidasAde: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahAdenite = false;
    this.showAndHideTerms.sahLinfonodoRetro = false;
    this.showAndHideTerms.sahLinfonodoHiloHepatico = false;
    this.catchIDs();
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahAdenite = false;
    this.showAndHideTerms.sahLinfonodoRetro = false;
    this.showAndHideTerms.sahLinfonodoHiloHepatico = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.clickLinfonodoRetro();
    this.click1LinfonodoRetro();
    this.catchIDs();
  }

  clickLinfonodoRetro() {
    if (this.showAndHideTerms.sahLinfonodoRetro === false) {
      this.showAndHideTerms.sahLinfonodoRetro = true;
      this.showAndHideTerms.sahMedidasRetro = true;
      this.showAndHideTerms.sahLinfonodoHiloHepatico = false;
      this.showAndHideTerms.sahAdenite = false;
    } else {
      this.showAndHideTerms.sahLinfonodoRetro = false;
      this.showAndHideTerms.sahMedidasRetro = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  click1LinfonodoRetro() {
    this.showAndHideTerms.sahMedidasRetro = true;
    this.catchIDs();
  }
  click2LinfonodoRetro() {
    this.showAndHideTerms.sahMedidasRetro = true;
    this.catchIDs();
  }

  clickLinfonodoHiloHepatico() {
    if (this.showAndHideTerms.sahLinfonodoHiloHepatico === false) {
      this.showAndHideTerms.sahLinfonodoHiloHepatico = true;
      this.showAndHideTerms.sahMedidasHilo = true;
      this.showAndHideTerms.sahAdenite = false;
      this.showAndHideTerms.sahLinfonodoRetro = false;
    } else {
      this.showAndHideTerms.sahLinfonodoHiloHepatico = false;
      this.showAndHideTerms.sahMedidasHilo = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  click1LinfonodoHiloHepatico() {
    this.showAndHideTerms.sahMedidasHilo = true;
    this.catchIDs();
  }

  click2LinfonodoHiloHepatico() {
    this.showAndHideTerms.sahMedidasHilo = true;
    this.catchIDs();
  }

  clickAdenite() {
    if (this.showAndHideTerms.sahAdenite === false) {
      this.showAndHideTerms.sahAdenite = true;
      this.showAndHideTerms.sahMedidasAde = true;
      this.showAndHideTerms.sahLinfonodoRetro = false;
      this.showAndHideTerms.sahLinfonodoHiloHepatico = false;
    } else {
      this.showAndHideTerms.sahAdenite = false;
      this.showAndHideTerms.sahMedidasAde = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  click1AdeniteLinfoPro() {
    this.showAndHideTerms.sahMedidasAde = true;
    this.catchIDs();
  }
  click2AdeniteLinfonodomegalias() {
    this.showAndHideTerms.sahMedidasAde = true;
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }
    
    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdAlterado.checked) {

      if (this.rdLinfonodoRetro && this.rdLinfonodoRetro.checked) {
        this.termoSelecionado.emit(this.rdLinfonodoRetro.id);

        if (this.rd1LinfonodoRetro && this.rd1LinfonodoRetro.checked) {
          this.termoSelecionado.emit(this.rd1LinfonodoRetro.id);
        } 
        
        if (this.rd2LinfonodoRetro && this.rd2LinfonodoRetro.checked) {
          this.termoSelecionado.emit(this.rd2LinfonodoRetro.id);
        }

        this.formataMedindoRetro();

      }

      if (this.rdLinfonodoHiloHepatico && this.rdLinfonodoHiloHepatico.checked) {
        this.termoSelecionado.emit(this.rdLinfonodoHiloHepatico.id);

        if (this.rd1LinfonodoHiloHepatico && this.rd1LinfonodoHiloHepatico.checked) {
          this.termoSelecionado.emit(this.rd1LinfonodoHiloHepatico.id);
        } 
        
        if (this.rd2LinfonodoHiloHepatico && this.rd2LinfonodoHiloHepatico.checked) {
          this.termoSelecionado.emit(this.rd2LinfonodoHiloHepatico.id);
        }

        this.formataMedindoHilo();

      }

      if (this.rdAdenite && this.rdAdenite.checked) {
        this.termoSelecionado.emit(this.rdAdenite.id);

        if (this.rd1LinfoPro && this.rd1LinfoPro.checked) {
          this.termoSelecionado.emit(this.rd1LinfoPro.id);
        } 
        
        if (this.rd2Linfonodomegalias && this.rd2Linfonodomegalias.checked) {
          this.termoSelecionado.emit(this.rd2Linfonodomegalias.id);
        }

        this.formataMedindoAde();

      }

    }

    this.listaCompleta.emit();
  }

  formataMedindoRetro() {
    let medindoValor = '';

    if (this.inMedidaUmRetro1 && this.inMedidaUmRetro1.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaUmRetro1.nativeElement.value);


    if (this.inMedidaUmRetro2 && this.inMedidaUmRetro2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmRetro2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmRetro2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 4120, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoHilo() {
    let medindoValor = '';

    if (this.inMedidaUmHilo1 && this.inMedidaUmHilo1.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaUmHilo1.nativeElement.value);


    if (this.inMedidaUmHilo2 && this.inMedidaUmHilo2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmHilo2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmHilo2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 4121, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoAde() {
    let medindoValor = '';

    if (this.inMedidaUmAde1 && this.inMedidaUmAde1.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedidaUmAde1.nativeElement.value);


    if (this.inMedidaUmAde2 && this.inMedidaUmAde2.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmAde2.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmAde2.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 4122, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  verificaAlterado() {
    if (!this.rdLinfonodoRetro.checked &&
      !this.rdLinfonodoHiloHepatico.checked &&
      !this.rdAdenite.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }

}
