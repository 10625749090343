import {Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Validacoes} from '../../../../../../utils/validacoes';
import {MatCheckbox, MatDialog, MatRadioButton, MatSelect} from '@angular/material';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { TireoideDopplerNivelCervicalComponent } from 'src/app/utils/dialog/imagens/tireoide-doppler-nivel-cervical/tireoide-doppler-nivel-cervical.component';


export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-linfonodomegalia',
  templateUrl: './linfonodomegalia.component.html',
  styleUrls: ['./linfonodomegalia.component.css']
})
export class LinfonodomegaliaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdLinfonodomegalia') rdLinfonodomegalia: MatRadioButton;
  @ViewChild('rdLinfonodosPre') rdLinfonodosPre: MatRadioButton;

  // Linfonodomegalia
  @ViewChild('rdUmaLinfonodomegalia') rdUmaLinfonodomegalia: MatRadioButton;
  @ViewChild('rdDuasLinfonodomegalia') rdDuasLinfonodomegalia: MatRadioButton;
  @ViewChild('rdMultiLinfonodomegalia') rdMultiLinfonodomegalia: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;
  @ViewChild('slMorfologia') slMorfologia: MatSelect;
  @ViewChild('inMedidaLinfo1') inMedidaLinfo1: ElementRef;
  @ViewChild('inMedidaLinfo2') inMedidaLinfo2: ElementRef;
  @ViewChild('slMorfologiaDuasLinfo') slMorfologiaDuasLinfo: MatSelect;
  @ViewChild('inMedidaLinfoDuas1') inMedidaLinfoDuas1: ElementRef;
  @ViewChild('inMedidaLinfoDuas2') inMedidaLinfoDuas2: ElementRef;

  // Linfonodos Predominantes
  @ViewChild('slLateralidadeLinfonodo') slLateralidadeLinfonodo: MatSelect;
  @ViewChild('slMorfologiaLinfonodo') slMorfologiaLinfonodo: MatSelect;
  @ViewChild('inMedidaLinfonodo1') inMedidaLinfonodo1: ElementRef;
  @ViewChild('inMedidaLinfonodo2') inMedidaLinfonodo2: ElementRef;

  @ViewChildren('linfonodomegaliaDuasChildren') linfonodomegaliaDuasChildren: QueryList<any>;

  localizacoes: ArrGenerico[] = [
    {value: 'Ia', viewValue: 'Ia'},
    {value: 'Ib', viewValue: 'Ib'},
    {value: 'IIa', viewValue: 'IIa'},
    {value: 'IIb', viewValue: 'IIb'},
    {value: 'III', viewValue: 'III'},
    {value: 'IV', viewValue: 'IV'},
    {value: 'Va', viewValue: 'Va'},
    {value: 'Vb', viewValue: 'Vb'},
    {value: 'VI', viewValue: 'VI'},
    {value: 'VII', viewValue: 'VII'}
  ];

  lateralidades: ArrGenerico[] = [
    {value: 'à direita', viewValue: 'Direita'},
    {value: 'à esquerda', viewValue: 'Esquerda'},
    {value: 'bilateral', viewValue: 'Bilateral'}
  ];

  lateralidadesMult: ArrGenerico[] = [
    {value: 'à direita', viewValue: 'Direita'},
    {value: 'à esquerda', viewValue: 'Esquerda'},
    {value: 'bilaterais', viewValue: 'Bilateral'}
  ];

  morfologias: ArrGenerico[] = [
    {value: 'de morfologia preservada', viewValue: 'Morfologia preservada'},
    {value: 'de aspecto reacional', viewValue: 'Aspecto reacional'},
    {value: 'com perda do padrão habitual', viewValue: 'Perda do padrão habitual'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  linha = 0;
  objeto: any;
  objetofilho: any;
  lesoesForm: FormGroup;
unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder, public dialog: MatDialog) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahLinfonodomegalia: false,
      sahLinfonodomegaliaNormal: false,
      sahLinfonodomegaliaDinamico: false,
      sahLinfonodomegaliaMultipla: false,
      sahLinfonodosPredominantes: false,
    };
    this.lesoesForm = this._fb.group({
      linfonodomegaliaDuas: this._fb.array([this.addLinfonodomegaliaDuasGrup()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  addLinfonodomegaliaDuasGrup() {
    return this._fb.group({
      localizacao: [],
      lateralidade: [],
      medindo: []
    });
  }

  addLinfonodomegaliaDuas() {
    this.LinfonodomegaliaDuasArray.push(this.addLinfonodomegaliaDuasGrup());
    this.catchIDs();
  }

  removeLinfonodomegaliaDuas(index) {
    this.LinfonodomegaliaDuasArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get LinfonodomegaliaDuasArray() {
    return <FormArray>this.lesoesForm.get('linfonodomegaliaDuas');
  }

  clickNormal() {
    this.showAndHideTerms.sahLinfonodomegalia = false;
    this.showAndHideTerms.sahLinfonodomegaliaNormal = false;
    this.showAndHideTerms.sahLinfonodomegaliaMultipla = false;
    this.showAndHideTerms.sahLinfonodosPredominantes = false;
    this.catchIDs();
  }

  clickLinfonodomegalia() {
    this.showAndHideTerms.sahLinfonodomegalia = true;
    this.showAndHideTerms.sahLinfonodomegaliaNormal = true;
    this.showAndHideTerms.sahLinfonodomegaliaMultipla = false;
    this.showAndHideTerms.sahLinfonodomegaliaDinamico = false;
    this.showAndHideTerms.sahLinfonodosPredominantes = false;
    this.catchIDs();
  }

  clickUmaLinfonodomegalia() {
    this.showAndHideTerms.sahLinfonodomegalia = true;
    this.showAndHideTerms.sahLinfonodomegaliaNormal = true;
    this.showAndHideTerms.sahLinfonodomegaliaMultipla = false;
    this.showAndHideTerms.sahLinfonodomegaliaDinamico = false;
    this.showAndHideTerms.sahLinfonodosPredominantes = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickDuasLinfonodomegalia() {
    this.showAndHideTerms.sahLinfonodomegalia = true;
    this.showAndHideTerms.sahLinfonodomegaliaNormal = false;
    this.showAndHideTerms.sahLinfonodomegaliaMultipla = false;
    this.showAndHideTerms.sahLinfonodomegaliaDinamico = true;
    this.showAndHideTerms.sahLinfonodosPredominantes = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickMultiLinfonodomegalia() {
    this.showAndHideTerms.sahLinfonodomegalia = true;
    this.showAndHideTerms.sahLinfonodomegaliaNormal = false;
    this.showAndHideTerms.sahLinfonodomegaliaMultipla = true;
    this.showAndHideTerms.sahLinfonodomegaliaDinamico = false;
    this.showAndHideTerms.sahLinfonodosPredominantes = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickLinfonodosPre() {
    this.showAndHideTerms.sahLinfonodomegalia = false;
    this.showAndHideTerms.sahLinfonodomegaliaNormal = false;
    this.showAndHideTerms.sahLinfonodomegaliaMultipla = false;
    this.showAndHideTerms.sahLinfonodomegaliaDinamico = false;
    this.showAndHideTerms.sahLinfonodosPredominantes = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }
  
  clickImagemNivelCervical(){
    this.dialog.open(TireoideDopplerNivelCervicalComponent, {});
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdLinfonodomegalia.checked) {

      if (this.rdUmaLinfonodomegalia != undefined && this.rdUmaLinfonodomegalia.checked) {
        this.termoSelecionado.emit(this.rdUmaLinfonodomegalia.id);

        if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
          const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidade != undefined && this.slLateralidade.value != undefined) {
          const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        if (this.slMorfologia != undefined && this.slMorfologia.value != undefined) {
          const objeto = {elemento: this.slMorfologia, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoLinfonodomeagaliaNormal();

      } else if (this.rdDuasLinfonodomegalia != undefined && this.rdDuasLinfonodomegalia.checked) {
        const objeto = {elemento: this.rdDuasLinfonodomegalia, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);

        if (this.slMorfologiaDuasLinfo != undefined && this.slMorfologiaDuasLinfo.value != undefined) {
          const objeto = {elemento: this.slMorfologiaDuasLinfo, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        let posicao = 0;
        this.linha = 0;

        this.linfonodomegaliaDuasChildren.forEach(obj => {

          if (obj.nativeElement != undefined) {

            if (obj.nativeElement.id == 574) {
              if (posicao < 2) {
                posicao++;
              } else {
                posicao = 1;
              }
            }

            this.objetofilho = {id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: 1};
            this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true};
            this.lesoesDinamicas.emit(this.objeto);
          } else {

            if (obj.id == 571) {
              this.linha++;
            }

            this.objetofilho = {id: obj.id, value: obj.value, cenario: 1};
            this.objeto = {elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true};
            this.lesoesDinamicas.emit(this.objeto);
          }
        });

      } else if (this.rdMultiLinfonodomegalia != undefined && this.rdMultiLinfonodomegalia.checked) {
        this.termoSelecionado.emit(this.rdMultiLinfonodomegalia.id);

        if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
          const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        if (this.slLateralidade != undefined && this.slLateralidade.value != undefined) {
          const objeto = {elemento: this.slLateralidade, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        if (this.slMorfologia != undefined && this.slMorfologia.value != undefined) {
          const objeto = {elemento: this.slMorfologia, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoLinfonodomeagaliaNormal();

      }
    }
    if (this.rdLinfonodosPre.checked) {
      this.termoSelecionado.emit(this.rdLinfonodosPre.id);

      if (this.slLateralidadeLinfonodo != undefined && this.slLateralidadeLinfonodo.value != undefined) {
        const objeto = {elemento: this.slLateralidadeLinfonodo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slMorfologiaLinfonodo != undefined && this.slMorfologiaLinfonodo.value != undefined) {
        const objeto = {elemento: this.slMorfologiaLinfonodo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoLinfonodo();
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  formataMedindoLinfonodomeagaliaNormal() {
    let medindoValor = '';

    if (this.inMedidaLinfo1 != undefined &&
      this.inMedidaLinfo1.nativeElement.id != undefined &&
      this.inMedidaLinfo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfo1.nativeElement.value);
    }

    if (this.inMedidaLinfo2 != undefined &&
      this.inMedidaLinfo2.nativeElement.id != undefined &&
      this.inMedidaLinfo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLinfo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfo2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 569, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoLinfonodo() {
    let medindoValor = '';

    if (this.inMedidaLinfonodo1 != undefined &&
      this.inMedidaLinfonodo1.nativeElement.id != undefined &&
      this.inMedidaLinfonodo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfonodo1.nativeElement.value);
    }

    if (this.inMedidaLinfonodo2 != undefined &&
      this.inMedidaLinfonodo2.nativeElement.id != undefined &&
      this.inMedidaLinfonodo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLinfonodo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLinfonodo2.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      const objetoElemento = {id: 569, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {

    if (!this.rdDuasLinfonodomegalia.checked || this.rdUmaLinfonodomegalia.checked
        || this.rdMultiLinfonodomegalia.checked || this.rdNormal.checked || this.rdLinfonodosPre.checked) {

      const arrayTotal = this.LinfonodomegaliaDuasArray.length;

      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.LinfonodomegaliaDuasArray.length - 1

        if (index != 0) {
          this.LinfonodomegaliaDuasArray.removeAt(index);
        }
      }
      this.LinfonodomegaliaDuasArray.reset();
    }

  }

}
