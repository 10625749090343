import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-seios-paranasais',
  templateUrl: './seios-paranasais.component.html',
  styleUrls: ['./seios-paranasais.component.css']
})
export class SeiosParanasaisComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('ckEspessamento') ckEspessamento: MatCheckbox;
  @ViewChild('ckVelamento') ckVelamento: MatCheckbox;
  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('ckCistoPolipo') ckCistoPolipo: MatCheckbox;

  showAndHideTerms: any;
  countLesoes = 0;

  espessamentoDirFormGroup: FormGroup;
  espessamentoEsqFormGroup: FormGroup;
  velamentoDirFormGroup: FormGroup;
  velamentoEsqFormGroup: FormGroup;
  cistoDirFormGroup: FormGroup;
  cistoEsqFormGroup: FormGroup;
  cistoPolipoDirFormGroup: FormGroup;
  cistoPolipoEsqFormGroup: FormGroup;

  seioDireitoEspessamento: any;
  seioEsquerdoEspessamento: any;
  seioDireitoVelamento: any;
  seioEsquerdoVelamento: any;
  seioDireitoCisto: any;
  seioEsquerdoCisto: any;
  seioDireitoCistoPolipo: any;
  seioEsquerdoCistoPolipo: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.iniciaFormEspessamento();
    this.iniciaFormVelamento();
    this.iniciaFormCisto();
    this.iniciaFormCistoPolipo();

    this.seioDireitoEspessamento = [
      { id: 'frontalEspesDir', value: 'frontal direito', name: 'Frontal Direito' },
      { id: 'maxilarEspesDir', value: 'maxilar direito', name: 'Maxilar Direito' },
      { id: 'etmoidalEspesDir', value: 'etmoidal direito', name: 'Etmoidal Direito' },
      { id: 'esfenoidalEspesDir', value: 'esfenoidal direito', name: 'Esfenoidal Direito' }
    ];

    this.seioEsquerdoEspessamento = [
      { id: 'frontalEspesEsq', value: 'frontal esquerdo', name: 'Frontal Esquerdo' },
      { id: 'maxilarEspesEsq', value: 'maxilar esquerdo', name: 'Maxilar Esquerdo' },
      { id: 'etmoidalEspesEsq', value: 'etmoidal esquerdo', name: 'Etmoidal Esquerdo' },
      { id: 'esfenoidalEspesEsq', value: 'esfenoidal esquerdo', name: 'Esfenoidal Esquerdo' }
    ];

    this.seioDireitoVelamento = [
      { id: 'frontalVelDir', value: 'frontal direito', name: 'Frontal Direito' },
      { id: 'maxilarVelDir', value: 'maxilar direito', name: 'Maxilar Direito' },
      { id: 'etmoidalVelDir', value: 'etmoidal direito', name: 'Etmoidal Direito' },
      { id: 'esfenoidalVelDir', value: 'esfenoidal direito', name: 'Esfenoidal Direito' },
    ]

    this.seioEsquerdoVelamento = [
      { id: 'frontalVelEsq', value: 'frontal esquerdo', name: 'Frontal Esquerdo' },
      { id: 'maxilarVelEsq', value: 'maxilar esquerdo', name: 'Maxilar Esquerdo' },
      { id: 'etmoidalVelEsq', value: 'etmoidal esquerdo', name: 'Etmoidal Esquerdo' },
      { id: 'esfenoidalVelEsq', value: 'esfenoidal esquerdo', name: 'Esfenoidal Esquerdo' },
    ];

    this.seioDireitoCisto = [
      { id: 'frontalCistoDir', value: 'frontal direito', name: 'Frontal Direito' },
      { id: 'maxilarCistoDir', value: 'maxilar direito', name: 'Maxilar Direito' },
      { id: 'etmoidalCistoDir', value: 'etmoidal direito', name: 'Etmoidal Direito' },
      { id: 'esfenoidalCistoDir', value: 'esfenoidal direito', name: 'Esfenoidal Direito' },
    ];

    this.seioEsquerdoCisto = [
      { id: 'frontalCistoEsq', value: 'frontal esquerdo', name: 'Frontal Esquerdo' },
      { id: 'maxilarCistoEsq', value: 'maxilar esquerdo', name: 'Maxilar Esquerdo' },
      { id: 'etmoidalCistoEsq', value: 'etmoidal esquerdo', name: 'Etmoidal Esquerdo' },
      { id: 'esfenoidalCistoEsq', value: 'esfenoidal esquerdo', name: 'Esfenoidal Esquerdo' },
    ];

    this.seioDireitoCistoPolipo = [
      { id: 'frontalCistoPolipoDir', value: 'frontal direito', name: 'Frontal Direito' },
      { id: 'maxilarCistoPolipoDir', value: 'maxilar direito', name: 'Maxilar Direito' },
      { id: 'etmoidalCistoPolipoDir', value: 'etmoidal direito', name: 'Etmoidal Direito' },
      { id: 'esfenoidalCistoPolipoDir', value: 'esfenoidal direito', name: 'Esfenoidal Direito' },
    ];

    this.seioEsquerdoCistoPolipo = [
      { id: 'frontalCistoPolipoEsq', value: 'frontal esquerdo', name: 'Frontal Esquerdo' },
      { id: 'maxilarCistoPolipoEsq', value: 'maxilar esquerdo', name: 'Maxilar Esquerdo' },
      { id: 'etmoidalCistoPolipoEsq', value: 'etmoidal esquerdo', name: 'Etmoidal Esquerdo' },
      { id: 'esfenoidalCistoPolipoEsq', value: 'esfenoidal esquerdo', name: 'Esfenoidal Esquerdo' },
    ];

    this.showAndHideTerms = {
      sahEspessamento: false,
      sahVelamento: false,
      sahCisto: false,
      sahCistoPolipo:false
    };

  }

  clickNormal() {
    this.showAndHideTerms.sahEspessamento = false;
    this.showAndHideTerms.sahVelamento = false;
    this.showAndHideTerms.sahCistoPolipo = false;
    this.showAndHideTerms.sahCisto = false;

    this.ckEspessamento.checked = false;
    this.ckVelamento.checked = false;
    this.ckCistoPolipo.checked = false;
    this.ckCisto.checked = false;
    this.checkNormal();
    this.catchIDs();
  }

  clickCheckbox() {
    this.catchIDs();
  }

  clickCheckboxEspessamentoDir(event) {
    let seioDireito = <FormArray>this.espessamentoDirFormGroup.get('seioDireitoEspessamento') as FormArray;
    let seioDireitoMult = <FormArray>this.espessamentoDirFormGroup.get('seioDireitoMultEspessamento') as FormArray;
    console.log(event.source);

    if (event.checked) {
      seioDireito.push(new FormControl(event.source.value));
      seioDireitoMult.push(new FormControl( " " + event.source.value.split(' ')[0]));
    } else {
      let iDir = seioDireito.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = seioDireitoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioDireito.removeAt(iDir);
      seioDireitoMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxEspessamentoEsq(event) {
    let seioEsquerdo = <FormArray>this.espessamentoEsqFormGroup.get('seioEsquerdoEspessamento') as FormArray;
    let seioEsquerdoMult = <FormArray>this.espessamentoEsqFormGroup.get('seioEsquerdoMultEspessamento') as FormArray;

    if (event.checked) {
      seioEsquerdo.push(new FormControl(event.source.value));
      seioEsquerdoMult.push(new FormControl(" " + event.source.value.split(' ')[0]));
    } else {
      let iEsq = seioEsquerdo.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = seioEsquerdoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioEsquerdo.removeAt(iEsq);
      seioEsquerdoMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxVelamentoDir(event) {
    let seioDireito = <FormArray>this.velamentoDirFormGroup.get('seioDireitoVelamento') as FormArray;
    let seioDireitoMult = <FormArray>this.velamentoDirFormGroup.get('seioDireitoMultVelamento') as FormArray;

    if (event.checked) {
      seioDireito.push(new FormControl(event.source.value));
      seioDireitoMult.push(new FormControl(" " +event.source.value.split(' ')[0]));
    } else {
      let iDir = seioDireito.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = seioDireitoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioDireito.removeAt(iDir);
      seioDireitoMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxVelamentoEsq(event) {
    let seioEsquerdo = <FormArray>this.velamentoEsqFormGroup.get('seioEsquerdoVelamento') as FormArray;
    let seioEsquerdoMult = <FormArray>this.velamentoEsqFormGroup.get('seioEsquerdoMultVelamento') as FormArray;

    if (event.checked) {
      seioEsquerdo.push(new FormControl(event.source.value));
      seioEsquerdoMult.push(new FormControl( " " +event.source.value.split(' ')[0]));
    } else {
      let iEsq = seioEsquerdo.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = seioEsquerdoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioEsquerdo.removeAt(iEsq);
      seioEsquerdoMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxCistoDir(event) {
    let seioDireito = <FormArray>this.cistoDirFormGroup.get('seioDireitoCisto') as FormArray;
    let seioDireitoMult = <FormArray>this.cistoDirFormGroup.get('seioDireitoMultCisto') as FormArray;

    if (event.checked) {
      seioDireito.push(new FormControl(event.source.value));
      seioDireitoMult.push(new FormControl(" " +event.source.value.split(' ')[0]));
    } else {
      let iDir = seioDireito.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = seioDireitoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioDireito.removeAt(iDir);
      seioDireitoMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxCistoEsq(event) {
    let seioEsquerdo = <FormArray>this.cistoEsqFormGroup.get('seioEsquerdoCisto') as FormArray;
    let seioEsquerdoMult = <FormArray>this.cistoEsqFormGroup.get('seioEsquerdoMultCisto') as FormArray;

    if (event.checked) {
      seioEsquerdo.push(new FormControl(event.source.value));
      seioEsquerdoMult.push(new FormControl(" " +event.source.value.split(' ')[0]));
    } else {
      let iEsq = seioEsquerdo.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = seioEsquerdoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioEsquerdo.removeAt(iEsq);
      seioEsquerdoMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  clickCheckboxCistoPolipoDir(event) {
    let seioDireito = <FormArray>this.cistoPolipoDirFormGroup.get('seioDireitoCistoPolipo') as FormArray;
    let seioDireitoMult = <FormArray>this.cistoPolipoDirFormGroup.get('seioDireitoMultCistoPolipo') as FormArray;

    if (event.checked) {
      seioDireito.push(new FormControl(event.source.value));
      seioDireitoMult.push(new FormControl(" " +event.source.value.split(' ')[0]));
    } else {
      let iDir = seioDireito.controls.findIndex(x => x.value === event.source.value);
      let iDirMult = seioDireitoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioDireito.removeAt(iDir);
      seioDireitoMult.removeAt(iDirMult);
    }

    this.catchIDs();
  }

  clickCheckboxCistoPolipoEsq(event) {
    let seioEsquerdo = <FormArray>this.cistoPolipoEsqFormGroup.get('seioEsquerdoCistoPolipo') as FormArray;
    let seioEsquerdoMult = <FormArray>this.cistoPolipoEsqFormGroup.get('seioEsquerdoMultCistoPolipo') as FormArray;

    if (event.checked) {
      seioEsquerdo.push(new FormControl(event.source.value));
      seioEsquerdoMult.push(new FormControl(" " +event.source.value.split(' ')[0]));
    } else {
      let iEsq = seioEsquerdo.controls.findIndex(x => x.value === event.source.value);
      let iEsqMult = seioEsquerdoMult.controls.findIndex(x => x.value === event.source.value.split(' ')[0]);

      seioEsquerdo.removeAt(iEsq);
      seioEsquerdoMult.removeAt(iEsqMult);
    }

    this.catchIDs();
  }

  checkNormal() {
    if (this.ckEspessamento.checked || this.ckVelamento.checked || this.ckCistoPolipo.checked || this.ckCisto.checked) {
      this.rdNormal.checked = false;
    } else {
      this.rdNormal.checked = true;
    }
    this.catchIDs();
  }

  clickEspessamento() {
    if (this.ckEspessamento && this.ckEspessamento.checked) {
      this.showAndHideTerms.sahEspessamento = true;
    } else {
      this.showAndHideTerms.sahEspessamento = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  clickVelamento() {
    if (this.ckVelamento.checked) {
      this.showAndHideTerms.sahVelamento = true;
    } else {
      this.showAndHideTerms.sahVelamento = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  clickCistoPolipo() {
    if (this.ckCistoPolipo.checked) {
      this.showAndHideTerms.sahCistoPolipo = true;
    } else {
      this.showAndHideTerms.sahCistoPolipo = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  clickCisto() {
    if (this.ckCisto.checked) {
      this.showAndHideTerms.sahCisto = true;
    } else {
      this.showAndHideTerms.sahCisto = false;
    }
    this.checkNormal();
    this.catchIDs();
  }

  iniciaFormEspessamento() {
    this.espessamentoDirFormGroup = this.fb.group({
      seioDireitoEspessamento: this.fb.array([]),
      seioDireitoMultEspessamento: this.fb.array([])
    });

    this.espessamentoEsqFormGroup = this.fb.group({
      seioEsquerdoEspessamento: this.fb.array([]),
      seioEsquerdoMultEspessamento: this.fb.array([])
    });
  }

  iniciaFormVelamento() {
    this.velamentoDirFormGroup = this.fb.group({
      seioDireitoVelamento: this.fb.array([]),
      seioDireitoMultVelamento: this.fb.array([])
    });

    this.velamentoEsqFormGroup = this.fb.group({
      seioEsquerdoVelamento: this.fb.array([]),
      seioEsquerdoMultVelamento: this.fb.array([])
    });
  }

  iniciaFormCisto() {
    this.cistoDirFormGroup = this.fb.group({
      seioDireitoCisto: this.fb.array([]),
      seioDireitoMultCisto: this.fb.array([])
    });

    this.cistoEsqFormGroup = this.fb.group({
      seioEsquerdoCisto: this.fb.array([]),
      seioEsquerdoMultCisto: this.fb.array([])
    });
  }

  iniciaFormCistoPolipo() {
    this.cistoPolipoDirFormGroup = this.fb.group({
      seioDireitoCistoPolipo: this.fb.array([]),
      seioDireitoMultCistoPolipo: this.fb.array([])
    });

    this.cistoPolipoEsqFormGroup = this.fb.group({
      seioEsquerdoCistoPolipo: this.fb.array([]),
      seioEsquerdoMultCistoPolipo: this.fb.array([])
    });
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (!this.ckEspessamento.checked) {
      this.iniciaFormEspessamento();
    }
    if (!this.ckVelamento.checked) {
      this.iniciaFormVelamento();
    }
    if (!this.ckCisto.checked) {
      this.iniciaFormCisto();
    }
    if (!this.ckCistoPolipo.checked) {
      this.iniciaFormCistoPolipo();
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    } else if(!this.rdNormal.checked) {

      this.emitEspessamentoSimples();

      // Velamento
      if (this.countLesoes > 0) {
        this.emitVelamentoLesao();
      } else {
        this.emitVelamentoSimples();
      }

      // Cisto
      if (this.countLesoes > 0) {
        this.emitCistoLesao();
      } else {
        this.emitCistoSimples();
      }

      // Cisto Polipo
      if (this.countLesoes > 0) {
        this.emitCistoPolipoLesao();
      } else {
        this.emitCistoPolipoSimples();
      }
    }
    this.listaCompleta.emit();
  }

  emitEspessamentoSimples(){
    if (this.ckEspessamento && this.ckEspessamento.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspessamento.id);

      let espessamentoDir = this.espessamentoDirFormGroup.value.seioDireitoEspessamento;
      let espessamentoDirMult = this.espessamentoDirFormGroup.value.seioDireitoMultEspessamento;
      let espessamentoEsq = this.espessamentoEsqFormGroup.value.seioEsquerdoEspessamento;
      let espessamentoEsqMult = this.espessamentoEsqFormGroup.value.seioEsquerdoMultEspessamento;
      console.log(espessamentoDir,espessamentoDirMult,espessamentoEsq,espessamentoEsqMult);
      //se todos os seios estiverem selecionados, o termo é emitido
      if (espessamentoDir.length == 4 && espessamentoEsq.length == 4) {
        this.termoSelecionado.emit(4812);
      } else {
        if ((espessamentoDir.length >= 1 && espessamentoEsqMult.length >= 1) || espessamentoDir.length > 1 || espessamentoEsq.length > 1) {
          if (espessamentoDir.length > 1) {
            let objeto = { id: 4810, value: this.tratamentoVirgula(espessamentoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (espessamentoEsq.length == 0) {
              //termo multiplo direito
              this.termoSelecionado.emit(4811);
            }
          } else if (espessamentoDir.length == 1) {
            let objeto = { id: 4810, value: espessamentoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }

          if (espessamentoEsq.length > 1) {
            let objeto = { id: 4809, value: this.tratamentoVirgula(espessamentoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (espessamentoDir.length == 0) {
              //termo multiplo esquerdo
              this.termoSelecionado.emit(4811);
            }
          } else if (espessamentoEsq.length == 1) {
            let objeto = { id: 4809, value: espessamentoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (espessamentoDir.length == 1) {
            let objeto = { id: 4810, value: espessamentoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
          if (espessamentoEsq.length == 1) {
            let objeto = { id: 4809, value: espessamentoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitEspessamentoLesao(){

    if (this.ckEspessamento && this.ckEspessamento.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckEspessamento.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let espessamentoDir = this.espessamentoDirFormGroup.value.seioDireitoEspessamento;
      let espessamentoDirMult = this.espessamentoDirFormGroup.value.seioDireitoMultEspessamento;
      let espessamentoEsq = this.espessamentoEsqFormGroup.value.seioEsquerdoEspessamento;
      let espessamentoEsqMult = this.espessamentoEsqFormGroup.value.seioEsquerdoMultEspessamento;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (espessamentoDir.length == 4 && espessamentoEsq.length == 4) {
        const objeto = { id: 4812, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      } else {
        if ((espessamentoDir.length >= 1 && espessamentoEsq.length >= 1) || espessamentoDir.length > 1 || espessamentoEsq.length > 1) {
          if (espessamentoDir.length > 1) {
            let objeto = { id: 4810, value: this.tratamentoVirgula(espessamentoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (espessamentoEsq.length == 0) {
              //termo multiplo direito
              const objeto = { id: 4811, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (espessamentoDir.length == 1) {
            let objeto = { id: 4810, value: espessamentoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }

          if (espessamentoEsq.length > 1) {
            let objeto = { id: 4809, value: this.tratamentoVirgula(espessamentoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (espessamentoDir.length == 0) {
              //termo multiplo esquerdo
              const objeto = { id: 4811, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (espessamentoEsq.length == 1) {
            let objeto = { id: 4809, value: espessamentoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (espessamentoDir.length == 1) {
            let objeto = { id: 4810, value: espessamentoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
          if (espessamentoEsq.length == 1) {
            let objeto = { id: 4809, value: espessamentoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitVelamentoSimples(){
    if (this.ckVelamento && this.ckVelamento.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckVelamento.id);

      let velamentoDir = this.velamentoDirFormGroup.value.seioDireitoVelamento;
      let velamentoDirMult = this.velamentoDirFormGroup.value.seioDireitoMultVelamento;
      let velamentoEsq = this.velamentoEsqFormGroup.value.seioEsquerdoVelamento;
      let velamentoEsqMult = this.velamentoEsqFormGroup.value.seioEsquerdoMultVelamento;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (velamentoDir.length == 4 && velamentoEsq.length == 4) {
        this.termoSelecionado.emit(4817);
      } else {
        if ((velamentoDir.length >= 1 && velamentoEsq.length >= 1) || velamentoDir.length > 1 || velamentoEsq.length > 1) {
          if (velamentoDir.length > 1) {
            let objeto = { id: 4815, value: this.tratamentoVirgula(velamentoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (velamentoEsq.length == 0) {
              //termo multiplo direito
              this.termoSelecionado.emit(4816);
            }
          } else if (velamentoDir.length == 1) {
            let objeto = { id: 4815, value: velamentoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }

          if (velamentoEsq.length > 1) {
            let objeto = { id: 4814, value: this.tratamentoVirgula(velamentoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (velamentoDir.length == 0) {
              //termo multiplo esquerdo
              this.termoSelecionado.emit(4816);
            }
          } else if (velamentoEsq.length == 1) {
            let objeto = { id: 4814, value: velamentoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (velamentoDir.length == 1) {
            let objeto = { id: 4815, value: velamentoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
          if (velamentoEsq.length == 1) {
            let objeto = { id: 4814, value: velamentoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitVelamentoLesao(){
    if (this.ckVelamento && this.ckVelamento.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckVelamento.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let velamentoDir = this.velamentoDirFormGroup.value.seioDireitoVelamento;
      let velamentoDirMult = this.velamentoDirFormGroup.value.seioDireitoMultVelamento;
      let velamentoEsq = this.velamentoEsqFormGroup.value.seioEsquerdoVelamento;
      let velamentoEsqMult = this.velamentoEsqFormGroup.value.seioEsquerdoMultVelamento;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (velamentoDir.length == 4 && velamentoEsq.length == 4) {
        const objeto = { id: 4817, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      } else {
        if ((velamentoDir.length >= 1 && velamentoEsq.length >= 1) || velamentoDir.length > 1 || velamentoEsq.length > 1) {
          if (velamentoDir.length > 1) {
            let objeto = { id: 4815, value: this.tratamentoVirgula(velamentoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (velamentoEsq.length == 0) {
              //termo multiplo direito
              const objeto = { id: 4816, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (velamentoDir.length == 1) {
            let objeto = { id: 4815, value: velamentoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }

          if (velamentoEsq.length > 1) {
            let objeto = { id: 4814, value: this.tratamentoVirgula(velamentoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (velamentoDir.length == 0) {
              //termo multiplo esquerdo
              const objeto = { id: 4816, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (velamentoEsq.length == 1) {
            let objeto = { id: 4814, value: velamentoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (velamentoDir.length == 1) {
            let objeto = { id: 4815, value: velamentoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
          if (velamentoEsq.length == 1) {
            let objeto = { id: 4814, value: velamentoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitCistoSimples(){
    if (this.ckCisto && this.ckCisto.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCisto.id);

      let cistoDir = this.cistoDirFormGroup.value.seioDireitoCisto;
      let cistoDirMult = this.cistoDirFormGroup.value.seioDireitoMultCisto;
      let cistoEsq = this.cistoEsqFormGroup.value.seioEsquerdoCisto;
      let cistoEsqMult = this.cistoEsqFormGroup.value.seioEsquerdoMultCisto;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (cistoDir.length == 4 && cistoEsq.length == 4) {
        this.termoSelecionado.emit(4822);
      } else {
        if ((cistoDir.length >= 1 && cistoEsq.length >= 1) || cistoDir.length > 1 || cistoEsq.length > 1) {
          if (cistoDir.length > 1) {
            let objeto = { id: 4820, value: this.tratamentoVirgula(cistoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (cistoEsq.length == 0) {
              //termo multiplo direito
              this.termoSelecionado.emit(4821);
            }
          } else if (cistoDir.length == 1) {
            let objeto = { id: 4820, value: cistoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }

          if (cistoEsq.length > 1) {
            let objeto = { id: 4819, value: this.tratamentoVirgula(cistoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (cistoDir.length == 0) {
              //termo multiplo esquerdo
              this.termoSelecionado.emit(4821);
            }
          } else if (cistoEsq.length == 1) {
            let objeto = { id: 4819, value: cistoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (cistoDir.length == 1) {
            let objeto = { id: 4820, value: cistoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
          if (cistoEsq.length == 1) {
            let objeto = { id: 4819, value: cistoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitCistoLesao(){
    if (this.ckCisto && this.ckCisto.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let cistoDir = this.cistoDirFormGroup.value.seioDireitoCisto;
      let cistoDirMult = this.cistoDirFormGroup.value.seioDireitoMultCisto;
      let cistoEsq = this.cistoEsqFormGroup.value.seioEsquerdoCisto;
      let cistoEsqMult = this.cistoEsqFormGroup.value.seioEsquerdoMultCisto;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (cistoDir.length == 4 && cistoEsq.length == 4) {
        const objeto = { id: 4822, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      } else {
        if ((cistoDir.length >= 1 && cistoEsq.length >= 1) || cistoDir.length > 1 || cistoEsq.length > 1) {
          if (cistoDir.length > 1) {
            let objeto = { id: 4820, value: this.tratamentoVirgula(cistoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (cistoEsq.length == 0) {
              //termo multiplo direito
              const objeto = { id: 4821, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (cistoDir.length == 1) {
            let objeto = { id: 4820, value: cistoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }

          if (cistoEsq.length > 1) {
            let objeto = { id: 4819, value: this.tratamentoVirgula(cistoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (cistoDir.length == 0) {
              //termo multiplo esquerdo
              const objeto = { id: 4821, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (cistoEsq.length == 1) {
            let objeto = { id: 4819, value: cistoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (cistoDir.length == 1) {
            let objeto = { id: 4820, value: cistoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
          if (cistoEsq.length == 1) {
            let objeto = { id: 4819, value: cistoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitCistoPolipoSimples(){
    if (this.ckCistoPolipo && this.ckCistoPolipo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCistoPolipo.id);

      let cistoPolipoDir = this.cistoPolipoDirFormGroup.value.seioDireitoCistoPolipo;
      let cistoPolipoDirMult = this.cistoPolipoDirFormGroup.value.seioDireitoMultCistoPolipo;
      let cistoPolipoEsq = this.cistoPolipoEsqFormGroup.value.seioEsquerdoCistoPolipo;
      let cistoPolipoEsqMult = this.cistoPolipoEsqFormGroup.value.seioEsquerdoMultCistoPolipo;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (cistoPolipoDir.length == 4 && cistoPolipoEsq.length == 4) {
        this.termoSelecionado.emit(4827);
      } else {
        if ((cistoPolipoDir.length >= 1 && cistoPolipoEsq.length >= 1) || cistoPolipoDir.length > 1 || cistoPolipoEsq.length > 1) {
          if (cistoPolipoDir.length > 1) {
            let objeto = { id: 4825, value: this.tratamentoVirgula(cistoPolipoDirMult.toString())  };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (cistoPolipoEsq.length == 0) {
              //termo multiplo direito
              this.termoSelecionado.emit(4826);
            }
          } else if (cistoPolipoDir.length == 1) {
            let objeto = { id: 4825, value: cistoPolipoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }

          if (cistoPolipoEsq.length > 1) {
            let objeto = { id: 4824, value: this.tratamentoVirgula(cistoPolipoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
            if (cistoPolipoDir.length == 0) {
              //termo multiplo esquerdo
              this.termoSelecionado.emit(4826);
            }
          } else if (cistoPolipoEsq.length == 1) {
            let objeto = { id: 4824, value: cistoPolipoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (cistoPolipoDir.length == 1) {
            let objeto = { id: 4825, value: cistoPolipoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
          if (cistoPolipoEsq.length == 1) {
            let objeto = { id: 4824, value: cistoPolipoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: true };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  emitCistoPolipoLesao(){
    if (this.ckCistoPolipo && this.ckCistoPolipo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistoPolipo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let cistoPolipoDir = this.cistoPolipoDirFormGroup.value.seioDireitoCistoPolipo;
      let cistoPolipoDirMult = this.cistoPolipoDirFormGroup.value.seioDireitoMultCistoPolipo;
      let cistoPolipoEsq = this.cistoPolipoEsqFormGroup.value.seioEsquerdoCistoPolipo;
      let cistoPolipoEsqMult = this.cistoPolipoEsqFormGroup.value.seioEsquerdoMultCistoPolipo;

      //se todos os seios estiverem selecionados, o termo é emitido
      if (cistoPolipoDir.length == 4 && cistoPolipoEsq.length == 4) {
        const objeto = { id: 4827, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      } else {
        if ((cistoPolipoDir.length >= 1 && cistoPolipoEsq.length >= 1) || cistoPolipoDir.length > 1 || cistoPolipoEsq.length > 1) {
          if (cistoPolipoDir.length > 1) {
            let objeto = { id: 4825, value: this.tratamentoVirgula(cistoPolipoDirMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (cistoPolipoEsq.length == 0) {
              //termo multiplo direito
              const objeto = { id: 4826, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (cistoPolipoDir.length == 1) {
            let objeto = { id: 4825, value: cistoPolipoDirMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }

          if (cistoPolipoEsq.length > 1) {
            let objeto = { id: 4824, value: this.tratamentoVirgula(cistoPolipoEsqMult.toString()) };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
            if (cistoPolipoDir.length == 0) {
              //termo multiplo esquerdo
              const objeto = { id: 4826, cenario: this.countLesoes };
              this.lesoesSelecionadas.emit(objeto);
            }
          } else if (cistoPolipoEsq.length == 1) {
            let objeto = { id: 4824, value: cistoPolipoEsqMult };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        } else {
          if (cistoPolipoDir.length == 1) {
            let objeto = { id: 4825, value: cistoPolipoDir };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
          if (cistoPolipoEsq.length == 1) {
            let objeto = { id: 4824, value: cistoPolipoEsq };
            let elemento = { elemento: objeto, isPrimeiraLesao: false };
            this.termosGenericos.emit(elemento);
          }
        }
      }
    }
  }

  tratamentoVirgula(string){
      const lastIndex = string.lastIndexOf(',');

      const replacement = ' e';

      let replaced;

      if (lastIndex !== -1) {
        replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
      }
      return replaced;
    }
}
