
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatRadioButton, MatDialog } from '@angular/material';
import { Calculadora } from 'src/app/utils/calculadora';
import { LiquidoAmnioticoDialogComponent } from 'src/app/utils/dialog/table/liquido-amniotico-dialog/liquido-amniotico-dialog.component';
import { ParametrosBiometricosDialogComponent } from 'src/app/utils/dialog/table/parametros-biometricos-dialog/parametros-biometricos-dialog.component';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-mst-parametros-biometricos',
  templateUrl: './mst-param-biometricos.component.html',
  styleUrls: ['./mst-param-biometricos.component.css']
})
export class MstParametrosBiometricosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('inCerebelo') inCerebelo: ElementRef;
  @ViewChild('inCisternaMagna') inCisternaMagna: ElementRef;
  @ViewChild('inPregaNucal') inPregaNucal: ElementRef;
  @ViewChild('inAtrioVentricular') inAtrioVentricular: ElementRef;
  @ViewChild('inInterorbitaria') inInterorbitaria: ElementRef;
  @ViewChild('inBiOrbitaria') inBiOrbitaria: ElementRef;
  @ViewChild('inOssoNasal') inOssoNasal: ElementRef;
  @ViewChild('inUmero') inUmero: ElementRef;
  @ViewChild('inRadio') inRadio: ElementRef;
  @ViewChild('inUlna') inUlna: ElementRef;
  @ViewChild('inTibia') inTibia: ElementRef;
  @ViewChild('inFibula') inFibula: ElementRef;
  @ViewChild('inPe') inPe: ElementRef;

  constructor(private _calculadora: Calculadora,
    public dialog: MatDialog,
    private _validacoes: Validacoes) { }

  ngOnInit() {
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.inCerebelo && this.inCerebelo.nativeElement.value) {
      let objeto = { elemento: this.inCerebelo.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inCisternaMagna && this.inCisternaMagna.nativeElement.value) {
      let objeto = { elemento: this.inCisternaMagna.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inPregaNucal && this.inPregaNucal.nativeElement.value) {
      let objeto = { elemento: this.inPregaNucal.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inAtrioVentricular && this.inAtrioVentricular.nativeElement.value) {
      let objeto = { elemento: this.inAtrioVentricular.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inInterorbitaria && this.inInterorbitaria.nativeElement.value) {
      let objeto = { elemento: this.inInterorbitaria.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inBiOrbitaria && this.inBiOrbitaria.nativeElement.value) {
      let objeto = { elemento: this.inBiOrbitaria.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inOssoNasal && this.inOssoNasal.nativeElement.value) {
      let objeto = { elemento: this.inOssoNasal.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inUmero && this.inUmero.nativeElement.value) {
      let objeto = { elemento: this.inUmero.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }
        if (this.inRadio && this.inRadio.nativeElement.value) {
      let objeto = { elemento: this.inRadio.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inUlna && this.inUlna.nativeElement.value) {
      let objeto = { elemento: this.inUlna.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inTibia && this.inTibia.nativeElement.value) {
      let objeto = { elemento: this.inTibia.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inFibula && this.inFibula.nativeElement.value) {
      let objeto = { elemento: this.inFibula.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    if (this.inPe && this.inPe.nativeElement.value) {
      let objeto = { elemento: this.inPe.nativeElement, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    this.listaCompleta.emit();
  }
}
