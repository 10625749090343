import { Component, OnInit, ViewChild, Output, EventEmitter, } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';


@Component({
  selector: 'app-derrame-articular',
  templateUrl: './derrame-articular.component.html',
  styleUrls: ['./derrame-articular.component.css']
})
export class DerrameArticularComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;
  @ViewChild('slVolume') slVolume: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;


  public Volume: ArrayDropDown[] = [
    { value: 'Pequeno', viewValue: 'Pequeno' },
    { value: 'Moderado', viewValue: 'Moderado' },
    { value: 'Acentuado', viewValue: 'Acentuado' },
  ];

  public Localizacao: ArrayDropDown[] = [
    { value: 'da articulação glenoumeral', viewValue: ' Articulação Glenoumeral' },
    { value: 'do cotovelo', viewValue: 'Cotovelo' },
    { value: 'do punho', viewValue: 'Punho' },
    { value: 'do quadril', viewValue: 'Quadril' },
    { value: 'do joelho', viewValue: 'Joelho' },
    { value: 'do tornozelo', viewValue: 'Tornozelo' },
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: false,
      sahPresente: false
    };
  }

  public clickAusente(): any {
    this.showAndHideTerms.sahPresente = false;
    this.rdAusente.checked = true;
    this.rdPresente.checked = false;
    this.catchIDs();

  }

  public clickPresente(): any {
    this.showAndHideTerms.sahAusente = false;
    this.showAndHideTerms.sahPresente = true;
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.sllocalizacao && this.sllocalizacao.value) || (this.slVolume && this.slVolume.value)){
      this.rdPresente.checked = true;
    }
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.value);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.value);

      if (this.slVolume && this.slVolume.value) {
        const objeto = { elemento: this.slVolume};
        this.termosGenericos.emit(objeto);
      }
      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao};
        this.termosGenericos.emit(objeto);
      }
    }
    this.listaCompleta.emit();

  }
}
