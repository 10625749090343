import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Validacoes } from '../../../../../utils/validacoes';

@Component({
  selector: 'app-parenquima-pulmonar',
  templateUrl: './parenquima-pulmonar.component.html',
  styleUrls: ['./parenquima-pulmonar.component.css']
})
export class ParenquimaPulmonarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  //REST PARENQUIMA
  @ViewChild('ckRestParenquima') ckRestParenquima: MatCheckbox;

  //COVID
  @ViewChild('ckCovid') ckCovid: MatCheckbox;
  @ViewChild('rdOpacidadesAus') rdOpacidadesAus: MatRadioButton;

  @ViewChild('rdFocoUni') rdFocoUni: MatRadioButton;
  @ViewChild('slLoboFocoUni') slLoboFocoUni: MatSelect;
  @ViewChild('slSegFocoUni') slSegFocoUni: MatSelect;

  @ViewChild('rdOpacidadesPres') rdOpacidadesPres: MatRadioButton;
  @ViewChild('slDistOpacidade') slDistOpacidade: MatSelect;
  @ViewChild('slLoboOpacidade') slLoboOpacidade: MatSelect;
  @ViewChild('slExtensaoOpacidade') slExtensaoOpacidade: MatSelect;
  @ViewChild('slAchadosOpacidade') slAchadosOpacidade: MatSelect;

  @ViewChild('rdRaro') rdRaro: MatRadioButton;
  @ViewChild('slLoboRaro') slLoboRaro: MatSelect;
  @ViewChild('slExtensaoRaro') slExtensaoRaro: MatSelect;

  @ViewChild('ckCorads') ckCorads: MatCheckbox;
  @ViewChild('slCorads') slCorads: MatSelect;

  //Opacidades em Vidro Fosco
  @ViewChild('ckOpacidadeVidro') ckOpacidadeVidro: MatCheckbox;
  @ViewChild('rdVidroLoca') rdVidroLoca: MatRadioButton;
  @ViewChild('slLoboVidroLoc') slLoboVidroLoc: MatSelect;
  @ViewChild('slSegVidroLoc') slSegVidroLoc: MatSelect;

  @ViewChild('rdVidroEsparso') rdVidroEsparso: MatRadioButton;
  @ViewChild('rdVidroDifuso') rdVidroDifuso: MatRadioButton;
  @ViewChild('slDistVidro') slDistVidro: MatSelect;
  @ViewChild('slLoboVidro') slLoboVidro: MatSelect;

  //Consolidacao
  @ViewChild('ckConsolidacao') ckConsolidacao: MatCheckbox;

  @ViewChild('rdConsLoc') rdConsLoc: MatRadioButton;
  @ViewChild('rdConsAte') rdConsAte: MatRadioButton;
  @ViewChild('slLoboConsOpt') slLoboConsOpt: MatSelect;
  @ViewChild('slSegConsOpt') slSegConsOpt: MatSelect;

  @ViewChild('rdConsEsp') rdConsEsp: MatRadioButton;
  @ViewChild('rdConsDif') rdConsDif: MatRadioButton;
  @ViewChild('slPadraoCons') slPadraoCons: MatSelect;
  @ViewChild('slDistCons') slDistCons: MatSelect;
  @ViewChild('slLoboCons') slLoboCons: MatSelect;

  //Atelectasia
  @ViewChild('ckAtelectasia') ckAtelectasia: MatCheckbox;

  @ViewChild('ckAteLoc') ckAteLoc: MatRadioButton;
  @ViewChild('slLoboAteLoc') slLoboAteLoc: MatSelect;
  @ViewChild('slSegAteLoc') slSegAteLoc: MatSelect;
  @ViewChild('ckAteSeg') ckAteSeg: MatRadioButton;
  @ViewChild('slLoboAteSeg') slLoboAteSeg: MatSelect;
  @ViewChild('slTipoAte') slTipoAte: MatSelect;
  @ViewChild('ckAteLam') ckAteLam: MatRadioButton;
  @ViewChild('slLoboAteLam') slLoboAteLam: MatSelect;
  @ViewChild('ckAteEsp') ckAteEsp: MatRadioButton;
  @ViewChild('ckAteEst') ckAteEst: MatRadioButton;
  @ViewChild('slLoboAteEst') slLoboAteEst: MatSelect;

  //Infarto
  @ViewChild('ckInfarto') ckInfarto: MatCheckbox;
  @ViewChild('slLoboInf') slLoboInf: MatSelect;
  @ViewChild('slSegInf') slSegInf: MatSelect;

  //Enfisema
  @ViewChild('ckEnfisema') ckEnfisema: MatCheckbox;
  @ViewChild('rdEnfLoc') rdEnfLoc: MatRadioButton;
  @ViewChild('rdEnfiPara') rdEnfiPara: MatRadioButton;
  @ViewChild('rdEnfCentPara') rdEnfCentPara: MatRadioButton;
  @ViewChild('rdEnfPan') rdEnfPan: MatRadioButton;
  @ViewChild('rdRarosEnf') rdRarosEnf: MatRadioButton;
  @ViewChild('slLoboEnf') slLoboEnf: MatSelect;
  @ViewChild('slTipoEnf') slTipoEnf: MatSelect;

  //Intersticiopatia
  @ViewChild('ckIntersticiopatia') ckIntersticiopatia: MatCheckbox;
  @ViewChild('rdIntGeral') rdIntGeral: MatRadioButton;
  @ViewChild('rdPine') rdPine: MatRadioButton;
  @ViewChild('rdPiu') rdPiu: MatRadioButton;
  @ViewChild('rdFibrosePul') rdFibrosePul: MatRadioButton;
  @ViewChild('rdPneumoniaOrg') rdPneumoniaOrg: MatRadioButton;
  @ViewChild('rdPneumoniteHip') rdPneumoniteHip: MatRadioButton;
  @ViewChild('slLoboInt') slLoboInt: MatSelect;
  @ViewChild('ckAreaInt') ckAreaInt: MatCheckbox;

  //Nodulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('ckNodSup') ckNodSup: MatCheckbox;

  @ViewChild('ckSubpleural') ckSubpleural: MatCheckbox;
  @ViewChild('ckSubpleuralMult') ckSubpleuralMult: MatCheckbox;
  @ViewChild('ckSubpleuralMet') ckSubpleuralMet: MatCheckbox;

  @ViewChild('ckNodCalcificado') ckNodCalcificado: MatCheckbox;
  
  @ViewChild('rd1NodSup') rd1NodSup: MatRadioButton;
  @ViewChild('slComposicao1Nod') slComposicao1Nod: MatSelect;
  @ViewChild('slContorno1Nod') slContorno1Nod: MatSelect;
  @ViewChild('slLobo1NodSup') slLobo1NodSup: MatSelect;
  @ViewChild('slSeg1NodSup') slSeg1NodSup: MatSelect;
  @ViewChild('inMedida1NodSup') inMedida1NodSup: ElementRef;
  @ViewChild('inMedida2NodSup') inMedida2NodSup: ElementRef;
  @ViewChild('inMedida3NodSup') inMedida3NodSup: ElementRef;

  @ViewChild('rd2NodSup') rd2NodSup: MatRadioButton;
  @ViewChild('slLobo2NodSup') slLobo2NodSup: MatSelect;
  @ViewChildren('NodSuspChildren') NodSuspChildren: QueryList<any>;

  @ViewChild('ckNodMult') ckNodMult: MatCheckbox;
  @ViewChild('slComposicaoNodMult') slComposicaoNodMult: MatSelect;
  @ViewChild('slContornosNodMult') slContornosNodMult: MatSelect;
  @ViewChild('slLoboNodMult') slLoboNodMult: MatSelect;
  @ViewChildren('NodMultChildren') NodMultChildren: QueryList<any>;

  @ViewChild('ckNodMet') ckNodMet: MatCheckbox;
  @ViewChild('slMetContorno') slMetContorno: MatSelect;
  @ViewChild('slLoboMetastase') slLoboMetastase: MatSelect;
  @ViewChildren('metastaseChildren') metastaseChildren: QueryList<any>;

  @ViewChild('ckNodDiminutos') ckNodDiminutos: MatCheckbox;
  @ViewChild('rdDimInc') rdDimInc: MatCheckbox;
  @ViewChild('rdDimRarosInc') rdDimRarosInc: MatCheckbox;
  @ViewChild('rdDimRarosRes') rdDimRarosRes: MatCheckbox;
  @ViewChild('rdDimCalc') rdDimCalc: MatCheckbox;
  @ViewChild('inMedidaDim') inMedidaDim: ElementRef;
  @ViewChild('inMedidaDimRarosInc') inMedidaDimRarosInc: ElementRef;
  @ViewChild('inMedidaDimRarosRes') inMedidaDimRarosRes: ElementRef;
  @ViewChild('inMedidaDimRes') inMedidaDimRes: ElementRef;

  @ViewChild('ckDimNcNc') ckDimNcNc: MatCheckbox;
  @ViewChild('inMedidaDimNcNc') inMedidaDimNcNc: ElementRef;

  // Nodulos calcificados
  @ViewChild('slLoboCalcif') slLoboCalcif: MatSelect;  
  @ViewChild('slSegCalcif') slSegCalcif: MatSelect;  
  @ViewChild('inMedidaCalcif') inMedidaCalcif: ElementRef;
  @ViewChild('ckSubpleuralCalc') ckSubpleuralCalc: MatCheckbox;

  
  

  //Massa Pulmonar
  @ViewChild('ckMassaPul') ckMassaPul: MatCheckbox;
  @ViewChild('slComposicaoMassa') slComposicaoMassa: MatSelect;
  @ViewChild('slContornoMassa') slContornoMassa: MatSelect;
  @ViewChild('slLoboMassa') slLoboMassa: MatSelect;
  @ViewChild('slSegMassa') slSegMassa: MatSelect;
  @ViewChild('inMedida1Massa') inMedida1Massa: ElementRef;
  @ViewChild('inMedida2Massa') inMedida2Massa: ElementRef;
  @ViewChild('inMedida3Massa') inMedida3Massa: ElementRef;
  @ViewChild('inVolumeMassa') inVolumeMassa: ElementRef;


  //Micronodulos
  @ViewChild('ckMicronodulos') ckMicronodulos: MatCheckbox;
  @ViewChild('rdMicroCentro') rdMicroCentro: MatRadioButton;
  @ViewChild('rdMicroCentroEsp') rdMicroCentroEsp: MatRadioButton;
  @ViewChild('rdMicroCentroDif') rdMicroCentroDif: MatRadioButton;
  @ViewChild('rdMicroPeri') rdMicroPeri: MatRadioButton;
  @ViewChild('rdMicroPeriEsp') rdMicroPeriEsp: MatRadioButton;
  @ViewChild('rdMicroPeriDif') rdMicroPeriDif: MatRadioButton;
  @ViewChild('rdMicroRanDif') rdMicroRanDif: MatRadioButton;
  @ViewChild('slLoboPreMicro') slLoboPreMicro: MatSelect;
  @ViewChild('slLoboMicro') slLoboMicro: MatSelect;
  @ViewChild('slSegMicro') slSegMicro: MatSelect;

  //Congestão Pulmonar
  @ViewChild('ckCongestao') ckCongestao: MatCheckbox;

  //Bronquiectasias
  @ViewChild('ckBronquiectasias') ckBronquiectasias: MatCheckbox;
  @ViewChild('rdBronLoc') rdBronLoc: MatRadioButton;
  @ViewChild('rdBronDif') rdBronDif: MatRadioButton;
  @ViewChild('rdBronSeq') rdBronSeq: MatRadioButton;
  @ViewChild('slTipoBron') slTipoBron: MatSelect;
  @ViewChild('slLoboBron') slLoboBron: MatSelect;

  //Cistos Aéreos
  @ViewChild('ckCistoAereo') ckCistoAereo: MatCheckbox;
  @ViewChild('rdComSeg') rdComSeg: MatRadioButton;
  @ViewChild('rdEsparsos') rdEsparsos: MatRadioButton;
  @ViewChild('rdSubpleural') rdSubpleural: MatRadioButton;
  @ViewChild('slLoboCisto') slLoboCisto: MatSelect;
  @ViewChild('slSegCisto') slSegCisto: MatSelect;
  @ViewChild('inMedidaCisto') inMedidaCisto: ElementRef;
  @ViewChild('slLoboEspar') slLoboEspar: MatSelect;
  @ViewChild('inMedidaEspar') inMedidaEspar: ElementRef;
  @ViewChild('slLoboSub') slLoboSub: MatSelect;
  @ViewChild('inMedidaSub') inMedidaSub: ElementRef;


  lobos: ArrayDropDown[] = [
    { value: 'no lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'no lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'no lobo médio', viewValue: 'Lobo médio' },
    { value: 'no lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'no lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
    { value: 'na língula', viewValue: 'Língula' }
  ];

  loboSimples: ArrayDropDown[] = [
    { value: 'lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'lobo médio', viewValue: 'Lobo médio' },
    { value: 'lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
  ];

  lobosOpacidades: ArrayDropDown[] = [
    { value: 'em todos os lobos pulmonares', viewValue: 'Todos os lobos pulmonares' },
    { value: 'no lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'no lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'no lobo médio', viewValue: 'Lobo médio' },
    { value: 'no lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'no lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
    { value: 'na língula', viewValue: 'Língula' },
  ];

  loboSupDir: ArrayDropDown[] = [
    { value: 'segmento apical', viewValue: 'Segmento apical' },
    { value: 'segmento anterior', viewValue: 'Segmento anterior' },
    { value: 'segmento posterior', viewValue: 'Segmento posterior' }
  ];

  loboMedio: ArrayDropDown[] = [
    { value: 'segmento medial', viewValue: 'Segmento medial' },
    { value: 'segmento lateral', viewValue: 'Segmento lateral' }
  ];

  loboInfDir: ArrayDropDown[] = [
    { value: 'segmento superior', viewValue: 'Segmento superior' },
    { value: 'segmento basal anterior', viewValue: 'Segmento basal anterior' },
    { value: 'segmento basal posterior', viewValue: 'Segmento basal posterior' },
    { value: 'segmento basal lateral', viewValue: 'Segmento basal lateral' },
    { value: 'segmento basal medial', viewValue: 'Segmento basal medial' }
  ];

  loboSupEsq: ArrayDropDown[] = [
    { value: 'segmento apicoposterior', viewValue: 'Segmento apicoposterior' },
    { value: 'segmento anterior', viewValue: 'Segmento anterior' },
    { value: 'segmento lingular superior', viewValue: 'Segmento lingular superior' },
    { value: 'segmento lingular inferior', viewValue: 'Segmento lingular inferior' },
  ];

  loboInfEsq: ArrayDropDown[] = [
    { value: 'segmento superior', viewValue: 'Segmento superior' },
    { value: 'segmento basal anteromedial', viewValue: 'Segmento basal anteromedial' },
    { value: 'segmento basal posterior', viewValue: 'Segmento basal posterior' },
    { value: 'segmento basal lateral', viewValue: 'Segmento basal lateral' }
  ];

  distribuicoes: ArrayDropDown[] = [
    { value: 'difusas e multifocais', viewValue: 'Difusas e multifocais' },
    { value: 'esparsas e bilaterais', viewValue: 'Esparsas e bilaterais' },
    { value: 'esparsas', viewValue: 'Esparsas' },
  ];

  extensoes: ArrayDropDown[] = [
    { value: 'menos de 25%', viewValue: 'Menos de 25%' },
    { value: 'entre 25-50%', viewValue: 'Entre 25-50%' },
    { value: 'mais de 50%', viewValue: 'Mais de 50%' }
  ];

  extensoesRaros: ArrayDropDown[] = [
    { value: 'menos de 10%', viewValue: 'Menos de 10%' },
    { value: 'menos de 25%', viewValue: 'Menos de 25%' }
  ];

  achados: ArrayDropDown[] = [
    { value: 'espessamento de septos interlobulares', viewValue: 'Espessamento de septos interlobulares' },
    { value: 'pequenos focos de consolidação', viewValue: 'Pequenos focos de consolidação' },
    { value: 'áreas confluentes de consolidação', viewValue: 'Áreas confluentes de consolidação' },
    { value: 'ectasia e espessamento parietal brônquico', viewValue: 'Ectasia e espessamento parietal brônquico' },
    { value: 'bandas subpleurais', viewValue: 'Bandas subpleurais' },
    { value: 'atelectasias laminares', viewValue: 'Atelectasias laminares' }
  ];

  corads: ArrayDropDown[] = [
    { value: 'CO-RADS 1 (Não suspeito)', viewValue: '1 - Não suspeito' },
    { value: 'CO-RADS 2 (Baixa suspeita)', viewValue: '2 - Baixa suspeita' },
    { value: 'CO-RADS 3 (Indeterminado)', viewValue: '3 - Indeterminado' },
    { value: 'CO-RADS 4 (Alta suspeita)', viewValue: '4 - Alta suspeita' },
    { value: 'CO-RADS 5 (Suspeita muito alta)', viewValue: '5 - Suspeita muito alta' },
    { value: 'CO-RADS 6 (PCR +)', viewValue: '6 - PCR +' }
  ];

  distribuicoesVidro: ArrayDropDown[] = [
    { value: 'periférica', viewValue: 'Periférica' },
    { value: 'peribroncovascular', viewValue: 'Peribroncovascular' },
    { value: 'central', viewValue: 'Central' }
  ];

  padroes: ArrayDropDown[] = [
    { value: 'Áreas', viewValue: 'Áreas' },
    { value: 'Focos', viewValue: 'Focos' }
  ];

  tipos: ArrayDropDown[] = [
    { value: 'segmentar', viewValue: 'Segmentar' },
    { value: 'subsegmentar', viewValue: 'Subsegmentar' }
  ];

  tiposEnf: ArrayDropDown[] = [
    { value: 'centrolobular', viewValue: 'Centrolobular' },
    { value: 'parasseptal', viewValue: 'Parasseptal' },
    { value: 'centrolobular e parasseptal', viewValue: 'Centrolobular e parasseptal' },
  ];

  graus: ArrayDropDown[] = [
    { value: 'esparso', viewValue: 'Esparso' },
    { value: 'difuso', viewValue: 'Difuso' }
  ];

  composicoes: ArrayDropDown[] = [
    { value: 'sólido', viewValue: 'Sólido' },
    { value: 'parcialmente sólido', viewValue: 'Parcialmente sólido' },
    { value: 'em vidro fosco', viewValue: 'Vidro fosco' }
  ];

  composicoesMassa: ArrayDropDown[] = [
    { value: 'sólida', viewValue: 'Sólida' },
    { value: 'parcialmente sólida', viewValue: 'Parcialmente sólida' },
    { value: 'em vidro fosco', viewValue: 'Vidro fosco' }
  ];

  contornos: ArrayDropDown[] = [
    { value: 'espiculado', viewValue: 'Espiculado' },
    { value: 'de contornos lobulados', viewValue: 'Contornos lobulados' },
    { value: 'de contornos regulares', viewValue: 'Contornos regulares' }
  ];

  contornosMassa: ArrayDropDown[] = [
    { value: 'espiculada', viewValue: 'Espiculada' },
    { value: 'de contornos lobulados', viewValue: 'Contornos lobulados' },
    { value: 'de contornos regulares', viewValue: 'Contornos regulares' }
  ];

  tiposBron: ArrayDropDown[] = [
    { value: 'cilíndricas', viewValue: 'Cilíndricas' },
    { value: 'varicosas', viewValue: 'Varicosas' },
    { value: 'císticas', viewValue: 'Císticas' },
    { value: 'irregulares', viewValue: 'irregulares' }
  ];

  showAndHideTerms: any;
  localizaCadeia: string;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  obj: any;
  objetofilho: any;
  volumeMassa = 'Volume';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahCovid: false,
      sahFocoUnico: false,
      sahOpacidadePresente: false,
      sahCorads: false,
      sahOpacidadeVidro: false,
      sahVidroLoc: false,
      sahVidro: false,
      sahCosolidacao: false,
      sahConsolidacaoOpt: false,
      sahCosolidacaoEspDif: false,
      sahCosolidacaoPadrao: false,
      sahCosolidacaoLobo: false,
      sahCosolidacaoLoboDif: false,
      sahAtelectasia: false,
      sahInfarto: false,
      sahEnfisema: false,
      sahEnfisemaGrau: false,
      sahEnfisemaTipo: false,
      sahIntersticiopatia: false,
      sahIntLoboPre: false,
      sahIntArea: false,
      sahNodulo: false,
      sahNodSup: false,
      sah1NodSup: false,
      sah2NodSup: false,
      sahNodMult: false,
      sahNodMet: false,
      sahNodDiminutos: false,
      sahNodDimInc: false,
      sahNodDimRarosInc: false,
      sahNodDimRarosRes: false,
      sahNodDimCalc: false,
      sahNodDimMedida: false,
      sahMassaPulmonar: false,
      sahMicronodulos: false,
      sahMicroLoboPre: false,
      sahMicroLoboSeg: false,
      sahBronquiectasia: false,
      sahBronqTipo: false,
      sahRaros: false,
      sahCistoAereo: false,
      sahComSeg: false,
      sahSemSeg: false,
      sahEsparsos: false,
      sahSubpleural: false,
      sahNodDimNcNc: false,
      sahNodCalcificado: false, 
    };

    this.lesoesForm = this._fb.group({
      nodSuspeitos: this._fb.array([this.addSuspGrup()]),
      nodMultiplos: this._fb.array([this.addMultGrup()]),
      metastase: this._fb.array([this.addMetastaseGrup()]),
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Nod Susp
  addSuspGrup() {
    return this._fb.group({
      composicao: [],
      contorno: [],
      lobo: [],
      segmento: [],
      medida: [],
    });
  }

  addSusp() {
    this.suspArray.push(this.addSuspGrup());
    this.catchIDs();
  }

  removeSusp(index) {
    this.suspArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get suspArray() {
    return <FormArray>this.lesoesForm.get('nodSuspeitos');
  }

  //Nod Multiplos
  addMultGrup() {
    return this._fb.group({
      lobo: [],
      segmento: [],
      medida: [],
    });
  }

  addMult() {
    this.multArray.push(this.addMultGrup());
    this.catchIDs();
  }

  removeMult(index) {
    this.multArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get multArray() {
    return <FormArray>this.lesoesForm.get('nodMultiplos');
  }

  //Metastase
  addMetastaseGrup() {
    return this._fb.group({
      lobo: [],
      segmento: [],
      medida: [],
    });
  }

  addMetastase() {
    this.metastaseArray.push(this.addMetastaseGrup());
    this.catchIDs();
  }

  removeMetastase(index) {
    this.metastaseArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get metastaseArray() {
    return <FormArray>this.lesoesForm.get('metastase');
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahCovid = false;
    this.showAndHideTerms.sahOpacidadeVidro = false;
    this.showAndHideTerms.sahCosolidacao = false;
    this.showAndHideTerms.sahAtelectasia = false;
    this.showAndHideTerms.sahEnfisema = false;
    this.showAndHideTerms.sahInfarto = false;
    this.showAndHideTerms.sahIntersticiopatia = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahNodDiminutos = false;
    this.showAndHideTerms.sahMassaPulmonar = false;
    this.showAndHideTerms.sahMicronodulos = false;
    this.showAndHideTerms.sahBronquiectasia = false;
    this.showAndHideTerms.sahCistoAereo = false;
    this.catchIDs();
  }

  clickCovid() {
    if (this.showAndHideTerms.sahCovid === false) {
      this.showAndHideTerms.sahCovid = true;
      this.showAndHideTerms.sahFocoUnico = false;
      this.showAndHideTerms.sahOpacidadePresente = false;
      this.showAndHideTerms.sahCorads = false;
    } else {
      this.showAndHideTerms.sahCovid = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickDefaultCovid() {
    this.showAndHideTerms.sahFocoUnico = false;
    this.showAndHideTerms.sahOpacidadePresente = false;
    this.showAndHideTerms.sahRaros = false;
    this.catchIDs();
  }

  clickOpPresente() {
    this.showAndHideTerms.sahFocoUnico = false;
    this.showAndHideTerms.sahOpacidadePresente = true;
    this.showAndHideTerms.sahRaros = false;
    this.catchIDs();
  }

  clickFocoUnico() {
    this.showAndHideTerms.sahFocoUnico = true;
    this.showAndHideTerms.sahOpacidadePresente = false;
    this.showAndHideTerms.sahRaros = false;
    this.catchIDs();
  }

  clickRaro() {
    this.showAndHideTerms.sahFocoUnico = false;
    this.showAndHideTerms.sahOpacidadePresente = false;
    this.showAndHideTerms.sahRaros = true;
    this.catchIDs();
  }

  clickCorads() {
    if (this.showAndHideTerms.sahCorads === false)
      this.showAndHideTerms.sahCorads = true;
    else
      this.showAndHideTerms.sahCorads = false;
    this.catchIDs();
  }

  clickOpacidadeVidro() {
    if (this.showAndHideTerms.sahOpacidadeVidro === false) {
      this.showAndHideTerms.sahOpacidadeVidro = true;
      this.showAndHideTerms.sahVidroLoc = true;
      this.showAndHideTerms.sahVidro = false;
    } else {
      this.showAndHideTerms.sahOpacidadeVidro = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickVidroLoc() {
    this.showAndHideTerms.sahVidroLoc = true;
    this.showAndHideTerms.sahVidro = false;
    this.catchIDs();
  }

  clickVidro() {
    this.showAndHideTerms.sahVidroLoc = false;
    this.showAndHideTerms.sahVidro = true;
    this.catchIDs();
  }

  clickConsolidacao() {
    if (this.showAndHideTerms.sahCosolidacao === false) {
      this.showAndHideTerms.sahCosolidacao = true;
      this.showAndHideTerms.sahConsolidacaoOpt = true;
      this.showAndHideTerms.sahCosolidacaoEsp = false;
      this.showAndHideTerms.sahVidroDif = false;
    } else {
      this.showAndHideTerms.sahCosolidacao = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickConsolidacaoOpt() {
    this.showAndHideTerms.sahConsolidacaoOpt = true;
    this.showAndHideTerms.sahCosolidacaoEspDif = false;
    this.showAndHideTerms.sahCosolidacaoPadrao = false;
    this.catchIDs();
  }

  clickNodCalcificado(){
    if (this.ckNodCalcificado && this.ckNodCalcificado.checked)
      this.showAndHideTerms.sahNodCalcificado = true;
    else
      this.showAndHideTerms.sahNodCalcificado = false;
      
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickConsolidacaoEsp() {
    this.showAndHideTerms.sahConsolidacaoOpt = false;
    this.showAndHideTerms.sahCosolidacaoEspDif = true;
    this.showAndHideTerms.sahCosolidacaoLobo = true;
    this.showAndHideTerms.sahCosolidacaoPadrao = true;
    this.showAndHideTerms.sahCosolidacaoLoboDif = false;

    this.catchIDs();
  }

  clickConsolidacaoDif() {
    this.showAndHideTerms.sahConsolidacaoOpt = false;
    this.showAndHideTerms.sahCosolidacaoEspDif = true;
    this.showAndHideTerms.sahCosolidacaoLoboDif = true;
    this.showAndHideTerms.sahCosolidacaoPadrao = false;
    this.showAndHideTerms.sahCosolidacaoLobo = false;
    this.catchIDs();
  }

  clickAtelectasia() {
    if (this.showAndHideTerms.sahAtelectasia === false) {
      this.showAndHideTerms.sahAtelectasia = true;
    } else {
      this.showAndHideTerms.sahAtelectasia = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickAtelectasiaLoc() {
    this.catchIDs();
  }

  clickAtelectasiaSeg() {
    this.catchIDs();
  }

  clickAtelectasiaLam() {
    this.catchIDs();
  }

  clickAtelectasiaEsp() {
    this.catchIDs();
  }

  clickAtelectasiaEst() {
    this.catchIDs();
  }

  clickInfarto() {
    if (this.showAndHideTerms.sahInfarto === false)
      this.showAndHideTerms.sahInfarto = true;
    else
      this.showAndHideTerms.sahInfarto = false;
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEnfisema() {
    if (this.showAndHideTerms.sahEnfisema === false) {
      this.showAndHideTerms.sahEnfisema = true;
      this.showAndHideTerms.sahEnfisemaGrau = true;
      this.showAndHideTerms.sahEnfisemaTipo = false;
    } else {
      this.showAndHideTerms.sahEnfisema = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEnfisemaGrau() {
    this.showAndHideTerms.sahEnfisemaGrau = true;
    this.showAndHideTerms.sahEnfisemaTipo = false;
    this.catchIDs();
  }

  clickEnfisemaDef() {
    this.showAndHideTerms.sahEnfisemaGrau = false;
    this.showAndHideTerms.sahEnfisemaTipo = false;
    this.catchIDs();
  }

  clickEnfisemaTipo() {
    this.showAndHideTerms.sahEnfisemaGrau = false;
    this.showAndHideTerms.sahEnfisemaTipo = true;
    this.catchIDs();
  }

  clickIntersticiopatia() {
    if (this.showAndHideTerms.sahIntersticiopatia === false) {
      this.showAndHideTerms.sahIntersticiopatia = true;
      this.showAndHideTerms.sahIntLoboPre = true;
      this.showAndHideTerms.sahIntArea = false;
    } else {
      this.showAndHideTerms.sahIntersticiopatia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickIntersticiopatiaLobo() {
    this.showAndHideTerms.sahIntLoboPre = true;
    this.showAndHideTerms.sahIntArea = false;
    this.catchIDs();
  }

  clickIntersticiopatiaDef() {
    this.showAndHideTerms.sahIntLoboPre = false;
    this.showAndHideTerms.sahIntArea = false;
    this.catchIDs();
  }

  clickIntersticiopatiaAll() {
    this.showAndHideTerms.sahIntLoboPre = true;
    this.showAndHideTerms.sahIntArea = true;
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo === false) {
      this.showAndHideTerms.sahNodulo = true;
      this.showAndHideTerms.sahNodSup = false;
      this.showAndHideTerms.sah1NodSup = false;
      this.showAndHideTerms.sah2NodSup = false;
      this.showAndHideTerms.sahNodMult = false;
      this.showAndHideTerms.sahNodMet = false;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickNodSup() {
    if (this.showAndHideTerms.sahNodSup === false) {
      this.showAndHideTerms.sahNodSup = true;
      this.showAndHideTerms.sah1NodSup = true;
    } else {
      this.showAndHideTerms.sahNodSup = false;
    }
    this.catchIDs();
  }

  click1NodSup() {
    this.showAndHideTerms.sah1NodSup = true;
    this.showAndHideTerms.sah2NodSup = false;
  }

  click2NodSup() {
    this.showAndHideTerms.sah1NodSup = false;
    this.showAndHideTerms.sah2NodSup = true;
    this.ajustaArrayDinamico();
  }

  clickNodMult() {
    if (this.showAndHideTerms.sahNodMult === false) {
      this.showAndHideTerms.sahNodMult = true;
    } else {
      this.showAndHideTerms.sahNodMult = false;
    }
    this.catchIDs();
  }

  clickNodMet() {
    if (this.showAndHideTerms.sahNodMet === false) {
      this.showAndHideTerms.sahNodMet = true;
    } else {
      this.showAndHideTerms.sahNodMet = false;
    }
    this.catchIDs();
  }

  clickNodDiminutos() {
    if (this.showAndHideTerms.sahNodDiminutos === false) {
      this.showAndHideTerms.sahNodDiminutos = true;
      this.showAndHideTerms.sahNodDimInc = false;
      this.showAndHideTerms.sahNodDimRarosInc = false;
      this.showAndHideTerms.sahNodDimRarosRes = false;
      this.showAndHideTerms.sahNodDimCalc = false;
      this.showAndHideTerms.sahNodDimNcNc = false;
    } else {
      this.showAndHideTerms.sahNodDiminutos = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  public clickNodDimInc() {
    if (this.showAndHideTerms.sahNodDimInc === false) {
      this.showAndHideTerms.sahNodDimInc = true;
    } else {
      this.showAndHideTerms.sahNodDimInc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  public clickNodDimRarosInc() {
    if (this.showAndHideTerms.sahNodDimRarosInc === false) {
      this.showAndHideTerms.sahNodDimRarosInc = true;
    } else {
      this.showAndHideTerms.sahNodDimRarosInc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }


  public clickNodDimRarosRes() {
    if (this.showAndHideTerms.sahNodDimRarosRes === false) {
      this.showAndHideTerms.sahNodDimRarosRes = true;
    } else {
      this.showAndHideTerms.sahNodDimRarosRes = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }


  public clickNodDimCalc() {
    if (this.showAndHideTerms.sahNodDimCalc === false) {
      this.showAndHideTerms.sahNodDimCalc = true;
    } else {
      this.showAndHideTerms.sahNodDimCalc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickNodDimNcNc() {
    if (this.showAndHideTerms.sahNodDimNcNc === false) {
      this.showAndHideTerms.sahNodDimNcNc = true;
    } else {
      this.showAndHideTerms.sahNodDimNcNc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickMassa() {
    if (this.showAndHideTerms.sahMassaPulmonar === false)
      this.showAndHideTerms.sahMassaPulmonar = true;
    else
      this.showAndHideTerms.sahMassaPulmonar = false;
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickMicronodulos() {
    if (this.showAndHideTerms.sahMicronodulos === false) {
      this.showAndHideTerms.sahMicronodulos = true;
      this.showAndHideTerms.sahMicroLoboSeg = true;
      this.showAndHideTerms.sahMicroLoboPre = false;
    } else {
      this.showAndHideTerms.sahMicronodulos = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickLoboSeg() {
    this.showAndHideTerms.sahMicroLoboSeg = true;
    this.showAndHideTerms.sahMicroLoboPre = false;
    this.catchIDs();
  }

  clickLoboPre() {
    this.showAndHideTerms.sahMicroLoboSeg = false;
    this.showAndHideTerms.sahMicroLoboPre = true;
    this.catchIDs();
  }

  clickMicroHide() {
    this.showAndHideTerms.sahMicroLoboSeg = false;
    this.showAndHideTerms.sahMicroLoboPre = false;
    this.catchIDs();

  }

  clickCongestao() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickBronquios() {
    if (this.showAndHideTerms.sahBronquiectasia === false) {
      this.showAndHideTerms.sahBronquiectasia = true;
      this.showAndHideTerms.sahBronqTipo = true;
    } else {
      this.showAndHideTerms.sahBronquiectasia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickBronqAll() {
    this.showAndHideTerms.sahBronqTipo = true;
    this.catchIDs();
  }

  clickSemTipo() {
    this.showAndHideTerms.sahBronqTipo = false;
  }

  clickCistoAereo() {
    if (this.showAndHideTerms.sahCistoAereo === false) {
      this.showAndHideTerms.sahCistoAereo = true;
      this.showAndHideTerms.sahComSeg = true;
      this.showAndHideTerms.sahEsparsos = false;
      this.showAndHideTerms.sahSubpleural = false;
    } else {
      this.showAndHideTerms.sahCistoAereo = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCsistoComSem() {
    this.showAndHideTerms.sahComSeg = true;
    this.showAndHideTerms.sahEsparsos = false;
    this.showAndHideTerms.sahSubpleural = false;
    this.catchIDs();
  }


  clickEsparcos() {
    this.showAndHideTerms.sahComSeg = false;
    this.showAndHideTerms.sahSemSeg = false;
    this.showAndHideTerms.sahEsparsos = true;
    this.showAndHideTerms.sahSubpleural = false;
    this.catchIDs();
  }

  clickSubpleural() {
    this.showAndHideTerms.sahComSeg = false;
    this.showAndHideTerms.sahSemSeg = false;
    this.showAndHideTerms.sahEsparsos = false;
    this.showAndHideTerms.sahSubpleural = true;
    this.catchIDs();
  }


  limpaSegmento(slSegmento: any) {
    //! Deixar metodo dinamico
    if (slSegmento.value)
      slSegmento.value = '';

    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  clickDefault() {
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  blurMedida() {
    this.catchIDs();
  }

  catchIDs() {

    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {

      if (this.countLesoes > 0)
        this.emitCovidLesao();
      else
        this.emitCovid();

      if (this.countLesoes > 0)
        this.emitOpacidadeLesao();
      else
        this.emitOpacidade();

      if (this.countLesoes > 0)
        this.emitConsolidacaoLesao();
      else
        this.emitConsolidacao();

      if (this.countLesoes > 0)
        this.emitAtelectasiaLesoes();
      else
        this.emitAtelectasia();

      if (this.countLesoes > 0)
        this.emitInfartoLesao();
      else
        this.emitInfarto();

      if (this.countLesoes > 0)
        this.emitEnfisemaLesao();
      else
        this.emitEnfisema();

      if (this.countLesoes > 0)
        this.emitIntersticiopatiaLesao();
      else
        this.emitIntersticiopatia();

      if (this.countLesoes > 0)
        this.emitMassaLesao();
      else
        this.emitMassa();

      if (this.countLesoes > 0)
        this.emitMicronoduloLesao();
      else
        this.emitMicronodulo();

      if (this.countLesoes > 0)
        this.emitCongestaoLesao();
      else
        this.emitCongestao();

      if (this.countLesoes > 0)
        this.emitBronquiectasiasLesao();
      else
        this.emitBronquiectasias();

      if (this.countLesoes > 0)
        this.emitNoduloLesao();
      else
        this.emitNodulo();
      
      if (this.countLesoes > 0)
        this.emitNoduloCalcificadoLesao();
      else
        this.emitNoduloCalcificado();

      if (this.countLesoes > 0)
        this.emitNodDiminutosLesao();
      else
        this.emitNodDiminutos();

      if (this.countLesoes > 0)
        this.emitCistoAereoLesao();
      else
        this.emitCistoAereo();

      //RESTANTE PARENQUIMA PRECISA SER O ULTIMO
      if (this.countLesoes > 0)
        this.emitParemquimaLesao();
      else
        this.emitParemquima();
    }

    this.listaCompleta.emit();
  }



  emitParemquima() {
    if (this.ckRestParenquima && this.ckRestParenquima.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRestParenquima.id);
    }
  }

  emitParemquimaLesao() {
    if (this.ckRestParenquima && this.ckRestParenquima.checked) {
      this.countLesoes++;
      const objetoPar = { id: this.ckRestParenquima.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoPar);
    }
  }

  emitCovid() {
    if (this.ckCovid && this.ckCovid.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCovid.id);

      if (this.rdOpacidadesAus && this.rdOpacidadesAus.checked) {
        this.termoSelecionado.emit(+this.rdOpacidadesAus.id);

      } else if (this.rdOpacidadesPres && this.rdOpacidadesPres.checked) {
        this.termoSelecionado.emit(this.rdOpacidadesPres.id);

        if (this.slDistOpacidade && this.slDistOpacidade.value) {
          const objeto = { elemento: this.slDistOpacidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLoboOpacidade && this.slLoboOpacidade.value) {
          const objeto = { elemento: this.slLoboOpacidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slExtensaoOpacidade && this.slExtensaoOpacidade.value) {
          const objeto = { elemento: this.slExtensaoOpacidade, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slAchadosOpacidade && this.slAchadosOpacidade.value) {
          if (this.slAchadosOpacidade.value.length > 0) {
            const objeto = { elemento: this.slAchadosOpacidade, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

      } else if (this.rdRaro && this.rdRaro.checked) {
        this.termoSelecionado.emit(this.rdRaro.id);

        if (this.slLoboRaro && this.slLoboRaro.value) {
          const objeto = { elemento: this.slLoboRaro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slExtensaoRaro && this.slExtensaoRaro.value) {
          const objeto = { elemento: this.slExtensaoRaro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rdFocoUni && this.rdFocoUni.checked) {
        this.termoSelecionado.emit(this.rdFocoUni.id);

        if (this.slLoboFocoUni && this.slLoboFocoUni.value) {
          if (this.slSegFocoUni && this.slSegFocoUni.value) {
            const lobo = this.formataLobo(this.slLoboFocoUni.value);

            const elemento = { id: this.slLoboFocoUni.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboFocoUni, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegFocoUni && this.slSegFocoUni.value) {
          const objeto = { elemento: this.slSegFocoUni, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      } else {
        // Envia Opacidade Ausente
        this.termoSelecionado.emit(1613);
      }

      if (this.ckCorads && this.ckCorads.checked) {
        this.countLesoes++;
        const objetoCov = { id: this.ckCovid.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        const objeto = { id: this.ckCorads.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slCorads && this.slCorads.value) {
          const objeto = { elemento: this.slCorads, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitCovidLesao() {
    if (this.ckCovid && this.ckCovid.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckCovid.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.rdOpacidadesAus && this.rdOpacidadesAus.checked) {
        const objetoCov = { id: this.rdOpacidadesAus.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

      } else if (this.rdOpacidadesPres && this.rdOpacidadesPres.checked) {
        const objetoCov = { id: this.rdOpacidadesPres.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slDistOpacidade && this.slDistOpacidade.value) {
          const objeto = { elemento: this.slDistOpacidade, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slLoboOpacidade && this.slLoboOpacidade.value) {
          const objeto = { elemento: this.slLoboOpacidade, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slExtensaoOpacidade && this.slExtensaoOpacidade.value) {
          const objeto = { elemento: this.slExtensaoOpacidade, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slAchadosOpacidade && this.slAchadosOpacidade.value) {
          if (this.slAchadosOpacidade.value.length > 0) {
            const objeto = { elemento: this.slAchadosOpacidade, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

      } else if (this.rdRaro && this.rdRaro.checked) {
        const objetoCov = { id: this.rdRaro.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboRaro && this.slLoboRaro.value) {
          const objeto = { elemento: this.slLoboRaro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slExtensaoRaro && this.slExtensaoRaro.value) {
          const objeto = { elemento: this.slExtensaoRaro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rdFocoUni && this.rdFocoUni.checked) {
        const objetoCov = { id: this.rdOpacidadesPres.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboFocoUni && this.slLoboFocoUni.value) {
          if (this.slSegFocoUni && this.slSegFocoUni.value) {
            const lobo = this.formataLobo(this.slLoboFocoUni.value);

            const elemento = { id: this.slLoboFocoUni.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboFocoUni, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegFocoUni && this.slSegFocoUni.value) {
          const objeto = { elemento: this.slSegFocoUni, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      } else {
        // Envia Opacidade Ausente
        const objetoCov = { id: 1613, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }

      if (this.ckCorads && this.ckCorads.checked) {
        this.countLesoes++;
        const objetoCov = { id: this.ckCovid.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        const objeto = { id: this.ckCorads.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.slCorads && this.slCorads.value) {
          const objeto = { elemento: this.slCorads, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitOpacidade() {
    if (this.ckOpacidadeVidro && this.ckOpacidadeVidro.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckOpacidadeVidro.id);

      if (this.rdVidroLoca && this.rdVidroLoca.checked) {
        this.termoSelecionado.emit(this.rdVidroLoca.id);

        if (this.slLoboVidroLoc && this.slLoboVidroLoc.value) {
          if (this.slSegVidroLoc && this.slSegVidroLoc.value) {
            const lobo = this.formataLobo(this.slLoboVidroLoc.value);

            const elemento = { id: this.slLoboVidroLoc.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboVidroLoc, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegVidroLoc && this.slSegVidroLoc.value) {
          const objeto = { elemento: this.slSegVidroLoc, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      } else if (this.rdVidroEsparso && this.rdVidroEsparso.checked) {
        this.termoSelecionado.emit(this.rdVidroEsparso.id);

        if (this.slLoboVidro && this.slLoboVidro.value) {
          const objeto = { elemento: this.slLoboVidro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDistVidro && this.slDistVidro.value) {
          const objeto = { elemento: this.slDistVidro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      } else if (this.rdVidroDifuso && this.rdVidroDifuso.checked) {
        this.termoSelecionado.emit(this.rdVidroDifuso.id);

        if (this.slLoboVidro && this.slLoboVidro.value) {
          const objeto = { elemento: this.slLoboVidro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDistVidro && this.slDistVidro.value) {
          const objeto = { elemento: this.slDistVidro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitOpacidadeLesao() {
    if (this.ckOpacidadeVidro && this.ckOpacidadeVidro.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckOpacidadeVidro.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.rdVidroLoca && this.rdVidroLoca.checked) {
        const objetoCov = { id: this.rdVidroLoca.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboVidroLoc && this.slLoboVidroLoc.value) {
          if (this.slSegVidroLoc && this.slSegVidroLoc.value) {
            const lobo = this.formataLobo(this.slLoboVidroLoc.value);

            const elemento = { id: this.slLoboVidroLoc.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboVidroLoc, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegVidroLoc && this.slSegVidroLoc.value) {
          const objeto = { elemento: this.slSegVidroLoc, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      } else if (this.rdVidroEsparso && this.rdVidroEsparso.checked) {
        const objetoCov = { id: this.rdVidroEsparso.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);;

        if (this.slLoboVidro && this.slLoboVidro.value) {
          const objeto = { elemento: this.slLoboVidro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDistVidro && this.slDistVidro.value) {
          const objeto = { elemento: this.slDistVidro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      } else if (this.rdVidroDifuso && this.rdVidroDifuso.checked) {
        const objetoCov = { id: this.rdVidroDifuso.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);;

        if (this.slLoboVidro && this.slLoboVidro.value) {
          const objeto = { elemento: this.slLoboVidro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slDistVidro && this.slDistVidro.value) {
          const objeto = { elemento: this.slDistVidro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitConsolidacao() {
    if (this.ckConsolidacao && this.ckConsolidacao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckConsolidacao.id);

      if (this.rdConsLoc && this.rdConsLoc.checked) {
        this.termoSelecionado.emit(this.rdConsLoc.id);

      } else if (this.rdConsAte && this.rdConsAte.checked) {
        this.termoSelecionado.emit(this.rdConsAte.id);

      } else if (this.rdConsEsp && this.rdConsEsp.checked) {
        this.termoSelecionado.emit(this.rdConsEsp.id);

      } else if (this.rdConsDif && this.rdConsDif.checked) {
        this.termoSelecionado.emit(this.rdConsDif.id);
      }

      if (this.slLoboConsOpt && this.slLoboConsOpt.value) {
        if (this.slSegConsOpt && this.slSegConsOpt.value) {
          const lobo = this.formataLobo(this.slLoboConsOpt.value);

          const elemento = { id: this.slLoboConsOpt.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboConsOpt, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegConsOpt && this.slSegConsOpt.value) {
        const objeto = { elemento: this.slSegConsOpt, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.slPadraoCons && this.slPadraoCons.value) {
        const objeto = { elemento: this.slPadraoCons, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDistCons && this.slDistCons.value) {
        const objeto = { elemento: this.slDistCons, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboCons && this.slLoboCons.value) {
        const objeto = { elemento: this.slLoboCons, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitConsolidacaoLesao() {
    if (this.ckConsolidacao && this.ckConsolidacao.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckConsolidacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.rdConsLoc && this.rdConsLoc.checked) {
        const objetoCov = { id: this.rdConsLoc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

      } else if (this.rdConsAte && this.rdConsAte.checked) {
        const objetoCov = { id: this.rdConsAte.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

      } else if (this.rdConsEsp && this.rdConsEsp.checked) {
        const objetoCov = { id: this.rdConsEsp.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

      } else if (this.rdConsDif && this.rdConsDif.checked) {
        const objetoCov = { id: this.rdConsDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }

      if (this.slLoboConsOpt && this.slLoboConsOpt.value) {
        if (this.slSegConsOpt && this.slSegConsOpt.value) {
          const lobo = this.formataLobo(this.slLoboConsOpt.value);

          const elemento = { id: this.slLoboConsOpt.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboConsOpt, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegConsOpt && this.slSegConsOpt.value) {
        const objeto = { elemento: this.slSegConsOpt, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.slPadraoCons && this.slPadraoCons.value) {
        const objeto = { elemento: this.slPadraoCons, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDistCons && this.slDistCons.value) {
        const objeto = { elemento: this.slDistCons, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboCons && this.slLoboCons.value) {
        const objeto = { elemento: this.slLoboCons, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAtelectasia() {
    if (this.ckAtelectasia && this.ckAtelectasia.checked) {

      if (this.ckAteLoc && this.ckAteLoc.checked) {
        if (this.countLesoes > 0) {
          this.emitCkckAteLocLesoes();
        } else {
          this.emitCkckAteLoc();
        }
      }

      if (this.ckAteSeg && this.ckAteSeg.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteSegLesao();
        } else {
          this.emitckAteSeg();
        }
      }
      if (this.ckAteLam && this.ckAteLam.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteLamLesoes();
        } else {
          this.emitckAteLam();
        }
      }
      if (this.ckAteEsp && this.ckAteEsp.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteEspLesoes();
        } else {
          this.emitckAteEsp();
        }
      }

      if (this.ckAteEst && this.ckAteEst.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteEstLesoes();
        } else {
          this.emitckAteEst();
        }
      }

    }

  }


  emitAtelectasiaLesoes() {
    if (this.ckAtelectasia && this.ckAtelectasia.checked) {

      if (this.ckAteLoc && this.ckAteLoc.checked) {
        this.emitCkckAteLocLesoes();
      }

      if (this.ckAteSeg && this.ckAteSeg.checked) {
        this.emitckAteSegLesao();
      }

      if (this.ckAteLam && this.ckAteLam.checked) {
        this.emitckAteLamLesoes();
      }
      if (this.ckAteEsp && this.ckAteEsp.checked) {
        this.emitckAteEspLesoes();
      }

      if (this.ckAteEst && this.ckAteEst.checked) {
        this.emitckAteEstLesoes();
      }
    }
  }

  emitCkckAteLoc() {

    if (this.ckAteLoc && this.ckAteLoc.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteLoc.id);

      if (this.slLoboAteLoc && this.slLoboAteLoc.value) {
        if (this.slSegAteLoc && this.slSegAteLoc.value) {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);

          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);
          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegAteLoc && this.slSegAteLoc.value) {
        const objeto = { elemento: this.slSegAteLoc, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCkckAteLocLesoes() {
    if (this.ckAteLoc && this.ckAteLoc.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteLoc.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLoboAteLoc && this.slLoboAteLoc.value) {
        if (this.slSegAteLoc && this.slSegAteLoc.value) {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);
          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const obj = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(obj);
        } else {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);
          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const obj = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(obj);
        }
      }

      if (this.slSegAteLoc && this.slSegAteLoc.value) {
        const obj = { elemento: this.slSegAteLoc, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitckAteSeg() {
    if (this.ckAteSeg && this.ckAteSeg.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteSeg.id);

      if (this.slTipoAte && this.slTipoAte.value) {
        const objeto = { elemento: this.slTipoAte, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboAteSeg && this.slLoboAteSeg.value) {
        const objeto = { elemento: this.slLoboAteSeg, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitckAteSegLesao() {
    if (this.ckAteSeg && this.ckAteSeg.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteSeg.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slTipoAte && this.slTipoAte.value) {
        const obj = { elemento: this.slTipoAte, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }

      if (this.slLoboAteSeg && this.slLoboAteSeg.value) {
        const obj = { elemento: this.slLoboAteSeg, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitckAteLam() {
    if (this.ckAteLam && this.ckAteLam.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteLam.id);

      if (this.slLoboAteLam && this.slLoboAteLam.value) {
        const objeto = { elemento: this.slLoboAteLam, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitckAteLamLesoes() {
    if (this.ckAteLam && this.ckAteLam.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteLam.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLoboAteLam && this.slLoboAteLam.value) {
        const obj = { elemento: this.slLoboAteLam, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitckAteEsp() {
    if (this.ckAteEsp && this.ckAteEsp.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteEsp.id);
    }
  }

  emitckAteEspLesoes() {
    if (this.ckAteEsp && this.ckAteEsp.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteEsp.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);
    }
  }

  emitckAteEst() {
    if (this.ckAteEst && this.ckAteEst.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteEst.id);

      if (this.slLoboAteEst && this.slLoboAteEst.value) {
        const objeto = { elemento: this.slLoboAteEst, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitckAteEstLesoes() {
    if (this.ckAteEst && this.ckAteEst.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteEst.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLoboAteEst && this.slLoboAteEst.value) {
        const obj = { elemento: this.slLoboAteEst, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitInfarto() {
    if (this.ckInfarto && this.ckInfarto.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(1648);
      this.termoSelecionado.emit(this.ckInfarto.id);

      if (this.slLoboInf && this.slLoboInf.value) {
        if (this.slSegInf && this.slSegInf.value) {
          const lobo = this.formataLobo(this.slLoboInf.value);

          const elemento = { id: this.slLoboInf.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboInf, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegInf && this.slSegInf.value) {
        const objeto = { elemento: this.slSegInf, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitInfartoLesao() {
    if (this.ckInfarto && this.ckInfarto.checked) {
      this.countLesoes++;
      const objetoCov = { id: 1648, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      const obj = { id: this.ckInfarto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slLoboInf && this.slLoboInf.value) {
        if (this.slSegInf && this.slSegInf.value) {
          const lobo = this.formataLobo(this.slLoboInf.value);

          const elemento = { id: this.slLoboInf.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboInf, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegInf && this.slSegInf.value) {
        const objeto = { elemento: this.slSegInf, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEnfisema() {
    if (this.ckEnfisema && this.ckEnfisema.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEnfisema.id);

      if (this.rdEnfLoc && this.rdEnfLoc.checked) {
        this.termoSelecionado.emit(this.rdEnfLoc.id);

      } else if (this.rdEnfiPara && this.rdEnfiPara.checked) {
        this.termoSelecionado.emit(this.rdEnfiPara.id);

      } else if (this.rdEnfCentPara && this.rdEnfCentPara.checked) {
        this.termoSelecionado.emit(this.rdEnfCentPara.id);

      } else if (this.rdRarosEnf && this.rdRarosEnf.checked) {
        this.termoSelecionado.emit(this.rdRarosEnf.id);

      } else if (this.rdEnfPan && this.rdEnfPan.checked) {
        this.termoSelecionado.emit(this.rdEnfPan.id);
      }

      if (this.slLoboEnf && this.slLoboEnf.value) {
        const objeto = { elemento: this.slLoboEnf, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slTipoEnf && this.slTipoEnf.value) {
        const objeto = { elemento: this.slTipoEnf, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEnfisemaLesao() {
    if (this.ckEnfisema && this.ckEnfisema.checked) {
      this.countLesoes++;
      const obj = { id: this.ckEnfisema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdEnfLoc && this.rdEnfLoc.checked) {
        const obj = { id: this.rdEnfLoc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdEnfiPara && this.rdEnfiPara.checked) {
        const obj = { id: this.rdEnfiPara.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdEnfCentPara && this.rdEnfCentPara.checked) {
        const obj = { id: this.rdEnfCentPara.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdRarosEnf && this.rdRarosEnf.checked) {
        const obj = { id: this.rdRarosEnf.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdEnfPan && this.rdEnfPan.checked) {
        const obj = { id: this.rdEnfPan.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slLoboEnf && this.slLoboEnf.value) {
        const objeto = { elemento: this.slLoboEnf, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slTipoEnf && this.slTipoEnf.value) {
        const objeto = { elemento: this.slTipoEnf, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitIntersticiopatia() {
    if (this.ckIntersticiopatia && this.ckIntersticiopatia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckIntersticiopatia.id);

      if (this.rdIntGeral && this.rdIntGeral.checked) {
        this.termoSelecionado.emit(this.rdIntGeral.id);

      } else if (this.rdPine && this.rdPine.checked) {
        this.termoSelecionado.emit(this.rdPine.id);

      } else if (this.rdPiu && this.rdPiu.checked) {
        this.termoSelecionado.emit(this.rdPiu.id);

      } else if (this.rdFibrosePul && this.rdFibrosePul.checked) {
        this.termoSelecionado.emit(this.rdFibrosePul.id);

      } else if (this.rdPneumoniaOrg && this.rdPneumoniaOrg.checked) {
        this.termoSelecionado.emit(this.rdPneumoniaOrg.id);

      } else if (this.rdPneumoniteHip && this.rdPneumoniteHip.checked) {
        this.termoSelecionado.emit(this.rdPneumoniteHip.id);
      }

      if (this.slLoboInt && this.slLoboInt.value) {
        const objeto = { elemento: this.slLoboInt, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAreaInt && this.ckAreaInt.checked) {
        this.termoSelecionado.emit(this.ckAreaInt.id);
      }
    }
  }

  emitIntersticiopatiaLesao() {
    if (this.ckIntersticiopatia && this.ckIntersticiopatia.checked) {
      this.countLesoes++;
      const obj = { id: this.ckIntersticiopatia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdIntGeral && this.rdIntGeral.checked) {
        const obj = { id: this.rdIntGeral.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPine && this.rdPine.checked) {
        const obj = { id: this.rdPine.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPiu && this.rdPiu.checked) {
        const obj = { id: this.rdPiu.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdFibrosePul && this.rdFibrosePul.checked) {
        const obj = { id: this.rdFibrosePul.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPneumoniaOrg && this.rdPneumoniaOrg.checked) {
        const obj = { id: this.rdPneumoniaOrg.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPneumoniteHip && this.rdPneumoniteHip.checked) {
        const obj = { id: this.rdPneumoniteHip.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slLoboInt && this.slLoboInt.value) {
        const objeto = { elemento: this.slLoboInt, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAreaInt && this.ckAreaInt.checked) {
        const obj = { id: this.ckAreaInt.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
    }
  }

  emitMassa() {
    if (this.ckMassaPul && this.ckMassaPul.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMassaPul.id);

      if (this.slComposicaoMassa && this.slComposicaoMassa.value) {
        const objeto = { elemento: this.slComposicaoMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoMassa && this.slContornoMassa.value) {
        const objeto = { elemento: this.slContornoMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboMassa && this.slLoboMassa.value) {
        const objeto = { elemento: this.slLoboMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegMassa && this.slSegMassa.value) {
        const objeto = { elemento: this.slSegMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoMassa(true);
    }
  }

  emitMassaLesao() {
    if (this.ckMassaPul && this.ckMassaPul.checked) {
      this.countLesoes++;
      const obj = { id: this.ckMassaPul.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slComposicaoMassa && this.slComposicaoMassa.value) {
        const objeto = { elemento: this.slComposicaoMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoMassa && this.slContornoMassa.value) {
        const objeto = { elemento: this.slContornoMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboMassa && this.slLoboMassa.value) {
        const objeto = { elemento: this.slLoboMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegMassa && this.slSegMassa.value) {
        const objeto = { elemento: this.slSegMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoMassa(false);
    }
  }

  emitMicronodulo() {
    if (this.ckMicronodulos && this.ckMicronodulos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMicronodulos.id);

      if (this.rdMicroCentro && this.rdMicroCentro.checked) {
        this.termoSelecionado.emit(this.rdMicroCentro.id);

      } else if (this.rdMicroCentroEsp && this.rdMicroCentroEsp.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroEsp.id);

      } else if (this.rdMicroCentroDif && this.rdMicroCentroDif.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroDif.id);

      } else if (this.rdMicroPeri && this.rdMicroPeri.checked) {
        this.termoSelecionado.emit(this.rdMicroPeri.id);

      } else if (this.rdMicroPeriEsp && this.rdMicroPeriEsp.checked) {
        this.termoSelecionado.emit(this.rdMicroPeriEsp.id);

      } else if (this.rdMicroPeriDif && this.rdMicroPeriDif.checked) {
        this.termoSelecionado.emit(this.rdMicroPeriDif.id);

      } else if (this.rdMicroRanDif && this.rdMicroRanDif.checked) {
        this.termoSelecionado.emit(this.rdMicroRanDif.id);
      }

      if (this.slLoboMicro && this.slLoboMicro.value) {
        if (this.slSegMicro && this.slSegMicro.value) {
          const lobo = this.formataLobo(this.slLoboMicro.value);

          const elemento = { id: this.slLoboMicro.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboMicro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegMicro && this.slSegMicro.value) {
        const objeto = { elemento: this.slSegMicro, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboPreMicro && this.slLoboPreMicro.value) {
        const objeto = { elemento: this.slLoboPreMicro, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitMicronoduloLesao() {
    if (this.ckMicronodulos && this.ckMicronodulos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckMicronodulos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdMicroCentro && this.rdMicroCentro.checked) {
        const obj = { id: this.rdMicroCentro.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdMicroCentroEsp && this.rdMicroCentroEsp.checked) {
        const obj = { id: this.rdMicroCentroEsp.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdMicroCentroDif && this.rdMicroCentroDif.checked) {
        const obj = { id: this.rdMicroCentroDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdMicroPeri && this.rdMicroPeri.checked) {
        const obj = { id: this.rdMicroPeri.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdMicroPeriEsp && this.rdMicroPeriEsp.checked) {
        const obj = { id: this.rdMicroPeriEsp.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdMicroPeriDif && this.rdMicroPeriDif.checked) {
        const obj = { id: this.rdMicroPeriDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdMicroRanDif && this.rdMicroRanDif.checked) {
        const obj = { id: this.rdMicroRanDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slLoboMicro && this.slLoboMicro.value) {
        if (this.slSegMicro && this.slSegMicro.value) {
          const lobo = this.formataLobo(this.slLoboMicro.value);

          const elemento = { id: this.slLoboMicro.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboMicro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }


      if (this.slSegMicro && this.slSegMicro.value) {
        const objeto = { elemento: this.slSegMicro, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }


      if (this.slLoboPreMicro && this.slLoboPreMicro.value) {
        const objeto = { elemento: this.slLoboPreMicro, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCongestao() {
    if (this.ckCongestao && this.ckCongestao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCongestao.id);
    }
  }

  emitCongestaoLesao() {
    if (this.ckCongestao && this.ckCongestao.checked) {
      this.countLesoes++;
      const obj = { id: this.ckCongestao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
    }
  }

  emitBronquiectasias() {
    if (this.ckBronquiectasias && this.ckBronquiectasias.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckBronquiectasias.id);

      if (this.rdBronLoc && this.rdBronLoc.checked) {
        this.termoSelecionado.emit(this.rdBronLoc.id);

      } else if (this.rdBronDif && this.rdBronDif.checked) {
        this.termoSelecionado.emit(this.rdBronDif.id);

      } else if (this.rdBronSeq && this.rdBronSeq.checked) {
        this.termoSelecionado.emit(this.rdBronSeq.id);
      }

      if (this.slTipoBron && this.slTipoBron.value) {
        const objeto = { elemento: this.slTipoBron, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboBron && this.slLoboBron.value) {
        const objeto = { elemento: this.slLoboBron, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitBronquiectasiasLesao() {
    if (this.ckBronquiectasias && this.ckBronquiectasias.checked) {
      this.countLesoes++;
      const obj = { id: this.ckBronquiectasias.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdBronLoc && this.rdBronLoc.checked) {
        const obj = { id: this.rdBronLoc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdBronDif && this.rdBronDif.checked) {
        const obj = { id: this.rdBronDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdBronSeq && this.rdBronSeq.checked) {
        const obj = { id: this.rdBronSeq.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slTipoBron && this.slTipoBron.value) {
        const objeto = { elemento: this.slTipoBron, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboBron && this.slLoboBron.value) {
        const objeto = { elemento: this.slLoboBron, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitNodulo() {
    if (this.ckNodulo && this.ckNodulo.checked) {

      if (this.ckNodSup && this.ckNodSup.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckNodulo.id);

        if (this.ckSubpleural && this.ckSubpleural.checked)
          this.termoSelecionado.emit(this.ckSubpleural.id);

        if (this.rd1NodSup && this.rd1NodSup.checked) {
          this.termoSelecionado.emit(this.rd1NodSup.id);

          if (this.slComposicao1Nod && this.slComposicao1Nod.value) {
            const objeto = { elemento: this.slComposicao1Nod, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slContorno1Nod && this.slContorno1Nod.value) {
            const objeto = { elemento: this.slContorno1Nod, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slLobo1NodSup && this.slLobo1NodSup.value) {
            const objeto = { elemento: this.slLobo1NodSup, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slSeg1NodSup && this.slSeg1NodSup.value) {
            const objeto = { elemento: this.slSeg1NodSup, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          this.formataMedindoNodulo(true);
        } else if (this.rd2NodSup && this.rd2NodSup.checked) {
          this.termoSelecionado.emit(this.rd2NodSup.id);
          let posicao = 0;
          this.linha = 0;

          this.NodSuspChildren.forEach(obj => {
            if (obj.nativeElement) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }

              this.objetofilho = { id: '1681', value: obj.nativeElement.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            } else {

              if (+obj.id === 1679)
                this.linha++;

              if (obj.value) {
                this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
                this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
                this.lesoesDinamicas.emit(this.obj);
              }
            }
          });
        }
      }

      if (this.countLesoes > 0)
        this.emitNodMultLesao();
      else
        this.emitNodMult();



      if (this.countLesoes > 0) {
        this.emitNodMetLesao();
      } else {
        this.emitNodMet();
      }
    }
  }

  public emitNodDiminutosDimInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.rdDimInc && this.rdDimInc.checked) {
        this.termoSelecionado.emit(this.rdDimInc.id);


        if (this.inMedidaDim && this.inMedidaDim.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDim.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }
    }
  }

  public emitNodDiminutosDimRarosInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);


      if (this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.termoSelecionado.emit(this.rdDimRarosInc.id);


        if (this.inMedidaDimRarosInc && this.inMedidaDimRarosInc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosInc.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosDimRarosRes() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.termoSelecionado.emit(this.rdDimRarosRes.id);


        if (this.inMedidaDimRarosRes && this.inMedidaDimRarosRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosRes.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosDimCalc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.rdDimCalc && this.rdDimCalc.checked) {
        this.termoSelecionado.emit(this.rdDimCalc.id);


        if (this.inMedidaDimRes && this.inMedidaDimRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRes.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitNodDiminutosDimNcNc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.termoSelecionado.emit(this.ckDimNcNc.id);


        if (this.inMedidaDimNcNc && this.inMedidaDimNcNc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimNcNc.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }



  public emitNodMet() {
    if (this.ckNodMet && this.ckNodMet.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodMet.id);

      if (this.ckSubpleuralMet && this.ckSubpleuralMet.checked){
        this.termoSelecionado.emit(this.ckSubpleuralMet.id);
      }

      if (this.slMetContorno && this.slMetContorno.value) {
        const objeto = { elemento: this.slMetContorno, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.linha = 0;

      this.metastaseChildren.forEach(obj => {
        if (obj.nativeElement) {

          this.objetofilho = { id: '1692', value: obj.nativeElement.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 1, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        } else {

          if (+obj.id === 1690)
            this.linha++;

          if (obj.value) {
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          }
        }
      });
    }
  }

  public emitNodMult() {
    if (this.ckNodMult && this.ckNodMult.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodMult.id);

      if (this.ckSubpleuralMult && this.ckSubpleuralMult.checked) {
        this.termoSelecionado.emit(this.ckSubpleuralMult.id);
      }
      

      if (this.slComposicaoNodMult && this.slComposicaoNodMult.value) {
        const objeto = { elemento: this.slComposicaoNodMult, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornosNodMult && this.slContornosNodMult.value) {
        const objeto = { elemento: this.slContornosNodMult, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      let posicao = 0;
      this.linha = 0;

      this.NodMultChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 2) {
            posicao++;
          } else {
            posicao = 1;
          }

          this.objetofilho = { id: '1687', value: obj.nativeElement.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.obj);
        } else {

          if (+obj.id === 1685)
            this.linha++;

          if (obj.value) {
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          }
        }
      });
    }

  }


  public emitNodDiminutosLesao(): any {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {


      if (this.countLesoes > 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosLesaoDimInc();
      } else if (this.countLesoes == 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosDimInc();
      }

      if (this.countLesoes > 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosLesaoDimRarosInc();
      } else if (this.countLesoes == 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosDimRarosInc();
      }


      if (this.countLesoes > 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosLesaoDimRarosRes();
      } else if (this.countLesoes == 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosDimRarosRes();
      }

      if (this.countLesoes > 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosLesaoDimCalc();
      } else if (this.countLesoes == 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosDimCalc();
      }

      if (this.countLesoes > 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosLesaoDimNcNc();
      } else if (this.countLesoes == 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosDimNcNc();
      }
    }

  }

  public emitNodDiminutos(): any {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {


      if (this.countLesoes > 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosLesaoDimInc();
      } else if (this.countLesoes == 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosDimInc();
      }

      if (this.countLesoes > 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosLesaoDimRarosInc();
      } else if (this.countLesoes == 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosDimRarosInc();
      }


      if (this.countLesoes > 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosLesaoDimRarosRes();
      } else if (this.countLesoes == 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosDimRarosRes();
      }

      if (this.countLesoes > 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosLesaoDimCalc();
      } else if (this.countLesoes == 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosDimCalc();
      }

      if (this.countLesoes > 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosLesaoDimNcNc();
      } else if (this.countLesoes == 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosDimNcNc();
      }

    }

  }
  emitNoduloCalcificado(){
    if (this.ckNodCalcificado && this.ckNodCalcificado.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckNodCalcificado.id);

      if (this.ckSubpleuralCalc && this.ckSubpleuralCalc.checked)
          this.termoSelecionado.emit(this.ckSubpleuralCalc.id);

      if (this.slLoboCalcif && this.slLoboCalcif.value) {
        const objeto = { elemento: this.slLoboCalcif, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegCalcif && this.slSegCalcif.value) {
        const objeto = { elemento: this.slSegCalcif, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if(this.inMedidaCalcif && this.inMedidaCalcif.nativeElement.value){
        const comp = this._validacoes.formataDecimal(this.inMedidaCalcif.nativeElement.value);
        const objetoElemento = { id: this.inMedidaCalcif.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }
  emitNoduloCalcificadoLesao(){
    if (this.ckNodCalcificado && this.ckNodCalcificado.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodCalcificado.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.ckSubpleuralCalc && this.ckSubpleuralCalc.checked){
        const obj = { id: this.ckSubpleuralCalc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.slLoboCalcif && this.slLoboCalcif.value) {
        const objeto = { elemento: this.slLoboCalcif, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegCalcif && this.slSegCalcif.value) {
        const objeto = { elemento: this.slSegCalcif, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if(this.inMedidaCalcif && this.inMedidaCalcif.nativeElement.value){
        const comp = this._validacoes.formataDecimal(this.inMedidaCalcif.nativeElement.value);
        const objetoElemento = { id: this.inMedidaCalcif.nativeElement.id, value: comp, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      

    }
  }

  emitNoduloLesao() {
    if (this.ckNodulo && this.ckNodulo.checked) {

      if (this.ckNodSup !== undefined && this.ckNodSup.checked) {
        this.countLesoes++;
        const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
        
        if (this.ckSubpleural && this.ckSubpleural.checked){
          const obj = { id: this.ckSubpleural.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(obj);
        }
          

        if (this.rd1NodSup && this.rd1NodSup.checked) {
          const obj = { id: this.rd1NodSup.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(obj);

          if (this.slComposicao1Nod && this.slComposicao1Nod.value) {
            const objeto = { elemento: this.slComposicao1Nod, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          if (this.slContorno1Nod && this.slContorno1Nod.value) {
            const objeto = { elemento: this.slContorno1Nod, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          if (this.slLobo1NodSup && this.slLobo1NodSup.value) {
            const objeto = { elemento: this.slLobo1NodSup, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          if (this.slSeg1NodSup && this.slSeg1NodSup.value) {
            const objeto = { elemento: this.slSeg1NodSup, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          this.formataMedindoNodulo(false);

        } else if (this.rd2NodSup && this.rd2NodSup.checked) {
          const obj = { id: this.rd2NodSup.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(obj);

          let posicao = 0;
          this.linha = 0;

          this.NodSuspChildren.forEach(obj => {
            if (obj.nativeElement) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }

              this.objetofilho = { id: '1681', value: obj.nativeElement.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.obj);
            } else {

              if (+obj.id === 1679)
                this.linha++;

              if (obj.value) {
                this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
                this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
                this.lesoesDinamicas.emit(this.obj);
              }
            }
          });
        }
      }

    }

    if (this.countLesoes > 0)
      this.emitNodMultLesao();
    else
      this.emitNodMult();

    if (this.countLesoes > 0) {
      this.emitNodMetLesao();
    } else {
      this.emitNodMet();
    }


  }

  public emitNodMultLesao() {

    if (this.ckNodMult && this.ckNodMult.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodMult.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckSubpleuralMult && this.ckSubpleuralMult.checked) {
        const objSub = { id: this.ckSubpleuralMult.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objSub);
      }

      if (this.slComposicaoNodMult && this.slComposicaoNodMult.value) {
        const objeto = { elemento: this.slComposicaoNodMult, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornosNodMult && this.slContornosNodMult.value) {
        const objeto = { elemento: this.slContornosNodMult, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      let posicao = 0;
      this.linha = 0;

      this.NodMultChildren.forEach(obj => {
        if (obj.nativeElement) {
          if (posicao < 2) {
            posicao++;
          } else {
            posicao = 1;
          }

          this.objetofilho = { id: '1687', value: obj.nativeElement.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        } else {

          if (+obj.id === 1685)
            this.linha++;

          if (obj.value) {
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        }
      });
    }
  }

  public emitNodMetLesao() {

    if (this.ckNodMet && this.ckNodMet.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodMet.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckSubpleuralMet && this.ckSubpleuralMet.checked){
        const obj = { id: this.ckSubpleuralMet.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slMetContorno && this.slMetContorno.value) {
        const objeto = { elemento: this.slMetContorno, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.linha = 0;

      this.metastaseChildren.forEach(obj => {
        if (obj.nativeElement) {

          this.objetofilho = { id: '1692', value: obj.nativeElement.value, cenario: this.countLesoes };
          this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 1, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.obj);
        } else {

          if (+obj.id === 1690)
            this.linha++;

          if (obj.value) {
            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          }
        }
      });
    }
  }

  public emitNodDiminutosLesaoDimInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);


      if (this.rdDimInc && this.rdDimInc.checked) {
        const obj = { id: this.rdDimInc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDim && this.inMedidaDim.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDim.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }
    }
  }

  public emitNodDiminutosLesaoDimRarosInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);


      if (this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        const obj = { id: this.rdDimRarosInc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimRarosInc && this.inMedidaDimRarosInc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosInc.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

    }

  }

  public emitNodDiminutosLesaoDimRarosRes() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);


      if (this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        const obj = { id: this.rdDimRarosRes.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimRarosRes && this.inMedidaDimRarosRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosRes.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosLesaoDimCalc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdDimCalc && this.rdDimCalc.checked) {
        const obj = { id: this.rdDimCalc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimRes && this.inMedidaDimRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRes.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitNodDiminutosLesaoDimNcNc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDimNcNc && this.ckDimNcNc.checked) {
        const obj = { id: this.ckDimNcNc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimNcNc && this.inMedidaDimNcNc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimNcNc.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitCistoAereo() {
    if (this.ckCistoAereo && this.ckCistoAereo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCistoAereo.id);

      if (this.rdComSeg && this.rdComSeg.checked) {
        this.termoSelecionado.emit(this.rdComSeg.id);

        if (this.slLoboCisto && this.slLoboCisto.value) {
          if (this.slSegCisto && this.slSegCisto.value) {
            const lobo = this.formataLobo(this.slLoboCisto.value);

            const elemento = { id: this.slLoboCisto.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          } else {

            const objeto = { elemento: this.slLoboCisto, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegCisto && this.slSegCisto.value) {
          const objeto = { elemento: this.slSegCisto, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaCisto && this.inMedidaCisto.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaCisto.nativeElement.value);
          const objetoElemento = { id: this.inMedidaCisto.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdEsparsos && this.rdEsparsos.checked) {
        this.termoSelecionado.emit(this.rdEsparsos.id);

        if (this.slLoboEspar && this.slLoboEspar.value) {
          const objeto = { elemento: this.slLoboEspar, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEspar && this.inMedidaEspar.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaEspar.nativeElement.value);
          const objetoElemento = { id: this.inMedidaEspar.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdSubpleural && this.rdSubpleural.checked) {
        this.termoSelecionado.emit(this.rdSubpleural.id);

        if (this.slLoboSub && this.slLoboSub.value) {
          const objeto = { elemento: this.slLoboSub, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaSub && this.inMedidaSub.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaSub.nativeElement.value);
          const objetoElemento = { id: this.inMedidaSub.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitCistoAereoLesao() {
    if (this.ckCistoAereo && this.ckCistoAereo.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckCistoAereo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.rdComSeg && this.rdComSeg.checked) {
        const objetoCov = { id: this.rdComSeg.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboCisto && this.slLoboCisto.value) {
          if (this.slSegCisto && this.slSegCisto.value) {
            const lobo = this.formataLobo(this.slLoboCisto.value);

            const elemento = { id: this.slLoboCisto.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {

            const objeto = { elemento: this.slLoboCisto, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegCisto && this.slSegCisto.value) {
          const objeto = { elemento: this.slSegCisto, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaCisto && this.inMedidaCisto.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaCisto.nativeElement.value);
          const objetoElemento = { id: this.inMedidaCisto.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdEsparsos && this.rdEsparsos.checked) {
        const objetoCov = { id: this.rdEsparsos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboEspar && this.slLoboEspar.value) {
          const objeto = { elemento: this.slLoboEspar, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEspar && this.inMedidaEspar.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaEspar.nativeElement.value);
          const objetoElemento = { id: this.inMedidaEspar.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

      if (this.rdSubpleural && this.rdSubpleural.checked) {
        const objetoCov = { id: this.rdSubpleural.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboSub && this.slLoboSub.value) {
          const objeto = { elemento: this.slLoboSub, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaSub && this.inMedidaSub.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaSub.nativeElement.value);
          const objetoElemento = { id: this.inMedidaSub.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  formataMedindoMassa(isPrimeiraLesao: boolean) {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedida1Massa && this.inMedida1Massa.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedida1Massa.nativeElement.value);


    if (this.inMedida2Massa && this.inMedida2Massa.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2Massa.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida2Massa.nativeElement.value);
    }

    if (this.inMedida3Massa && this.inMedida3Massa.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3Massa.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida3Massa.nativeElement.value);
    }

    //Esse else é necessario por que deve-se retirar o "cm" do texto da base de dados.
    medindoValor += ' cm'

    if ((this.inMedida3Massa && this.inMedida3Massa.nativeElement.value) &&
      (this.inMedida2Massa && this.inMedida2Massa.nativeElement.value) &&
      (this.inMedida1Massa && this.inMedida1Massa.nativeElement.value)) {

      resultado = this.inMedida1Massa.nativeElement.value * this.inMedida2Massa.nativeElement.value * this.inMedida3Massa.nativeElement.value * 0.523;
      this.volumeMassa = resultado.toFixed(1).toString();

      //Caso tenha as 3 medidas e tenha composição, mostre o volume.
      if (this.slComposicaoMassa && this.slComposicaoMassa.value) {
        if (this.inVolumeMassa.nativeElement.value) {
          medindoValor = medindoValor + '. Volume = ' + this._validacoes.formataDecimal(this.inVolumeMassa.nativeElement.value) + ' cm³';
        } else {
          medindoValor = medindoValor + '. Volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' cm³';
        }
      }

    }

    if (medindoValor) {

      const objetoElemento = { id: 1704, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoNodulo(isPrimeiraLesao: boolean) {
    let medindoValor = '';

    if (this.inMedida1NodSup && this.inMedida1NodSup.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedida1NodSup.nativeElement.value);


    if (this.inMedida2NodSup && this.inMedida2NodSup.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2NodSup.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida2NodSup.nativeElement.value);
    }

    if (this.inMedida3NodSup && this.inMedida3NodSup.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3NodSup.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida3NodSup.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: '1675', value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataLobo(loboValue: string) {
    let lobo = loboValue.split('no');
    if (lobo[1])
      return lobo[1];
    else
      return lobo[0];
  }

  getArraySimples(id: string): any[] {
    if (id) {
      if (id === 'lobo superior direito')
        return this.loboSupDir;
      else if (id === 'lobo inferior direito')
        return this.loboInfDir;
      else if (id === 'lobo médio')
        return this.loboMedio;
      else if (id === 'lobo superior esquerdo')
        return this.loboSupEsq;
      else if (id === 'lobo inferior esquerdo')
        return this.loboInfEsq;
      else if (id === 'língula')
        return;
    }
  }

  getArray(id: string): any[] {
    if (id) {
      if (id === 'no lobo superior direito')
        return this.loboSupDir;
      else if (id === 'no lobo inferior direito')
        return this.loboInfDir;
      else if (id === 'no lobo médio')
        return this.loboMedio;
      else if (id === 'no lobo superior esquerdo')
        return this.loboSupEsq;
      else if (id === 'no lobo inferior esquerdo')
        return this.loboInfEsq;
      else if (id === 'na língula')
        return;
    }
  }

  ajustaArrayDinamico() {
    const arraySup = this.suspArray.length;
    for (let i = 0; i <= arraySup; i++) {
      const index = this.suspArray.length - 1
      if (index != 0) {
        this.suspArray.removeAt(index);
      }
    }
    this.suspArray.reset();

    const arrayMult = this.multArray.length;
    for (let i = 0; i <= arrayMult; i++) {
      const index = this.multArray.length - 1
      if (index != 0) {
        this.multArray.removeAt(index);
      }
    }
    this.multArray.reset();

    const arrayMet = this.metastaseArray.length;
    for (let i = 0; i <= arrayMet; i++) {
      const index = this.metastaseArray.length - 1
      if (index != 0) {
        this.metastaseArray.removeAt(index);
      }
    }
    this.metastaseArray.reset();
  }

  verificaAlterado() {
    if (!this.ckCovid.checked &&
      !this.ckOpacidadeVidro.checked &&
      !this.ckConsolidacao.checked &&
      !this.ckAtelectasia.checked &&
      !this.ckInfarto.checked &&
      !this.ckEnfisema.checked &&
      !this.ckIntersticiopatia.checked &&
      !this.ckNodulo.checked &&
      !this.ckNodDiminutos.checked &&
      !this.ckMassaPul.checked &&
      !this.ckMicronodulos.checked &&
      !this.ckCongestao.checked &&
      !this.ckBronquiectasias.checked &&
      !this.ckCistoAereo.checked &&
      !this.ckNodCalcificado.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
