import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-cer-sludge',
  templateUrl: './cer-sludge.component.html',
  styleUrls: ['./cer-sludge.component.css']
})
export class CerSludgeComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickAusPres() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente && this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente && this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);
    }

    this.listaCompleta.emit();
  }

}
