import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';
import { MaoComponent } from './mao.component';
import { PeleTecidoSubcutaneoComponent } from './termosComponents/pele-tecido-subcutaneo/pele-tecido-subcutaneo.component';
import { DerrameArticularComponent } from './termosComponents/derrame-articular/derrame-articular.component';
import { SuperficiesOsseasComponent } from './termosComponents/superficies-osseas/superficies-osseas.component';
import { TendoesFlexoresComponent } from './termosComponents/tendoes-flexores/tendoes-flexores.component';
import { TendoesExtensoresComponent } from './termosComponents/tendoes-extensores/tendoes-extensores.component';
import { MusculaturaRegionalComponent } from './termosComponents/musculatura-regional/musculatura-regional.component';
import { FasciaPalmarComponent } from './termosComponents/fascia-palmar/fascia-palmar.component';

import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { CistosMaoComponent } from './termosComponents/cistos-mao/cistos-mao.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';


@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    MaoComponent,
    PeleTecidoSubcutaneoComponent,
    DerrameArticularComponent,
    SuperficiesOsseasComponent,
    TendoesFlexoresComponent,
    TendoesExtensoresComponent,
    MusculaturaRegionalComponent,
    FasciaPalmarComponent,
    CistosMaoComponent
  ],
  declarations: [
    MaoComponent,
    PeleTecidoSubcutaneoComponent,
    DerrameArticularComponent,
    SuperficiesOsseasComponent,
    TendoesFlexoresComponent,
    TendoesExtensoresComponent,
    MusculaturaRegionalComponent,
    FasciaPalmarComponent,
    CistosMaoComponent]
})
export class MaoModule { }
