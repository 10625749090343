import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import { PeleTecidoOmbroComponent } from './TermosComponents/pele-tecido-ombro/pele-tecido-ombro.component';
import { SuperficieOsseaOmbroComponent } from './TermosComponents/superficie-ossea-ombro/superficie-ossea-ombro.component';
import { VentresMuscularesComponent } from './TermosComponents/ventres-musculares/ventres-musculares.component';
import { TendaoCabecaLongaComponent } from './TermosComponents/tendao-cabeca-longa/tendao-cabeca-longa.component';
import { TendaoInfraespinalComponent } from './TermosComponents/tendao-infraespinal/tendao-infraespinal.component';
import { TendaoSupraespinalComponent } from './TermosComponents/tendao-supraespinal/tendao-supraespinal.component';
import { TendaoSubescapularComponent } from './TermosComponents/tendao-subescapular/tendao-subescapular.component';
import { TendaoRedondoComponent } from './TermosComponents/tendao-redondo/tendao-redondo.component';
import { BursaSubacromialComponent } from './TermosComponents/bursa-subacromial/bursa-subacromial.component';
import { ArticulacaoAcromioComponent } from './TermosComponents/articulacao-acromio/articulacao-acromio.component';
import { ArticulacaoGlenoumeralComponent } from './TermosComponents/articulacao-glenoumeral/articulacao-glenoumeral.component';
import { OmbroComponent } from './ombro.component';

import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    OmbroComponent, 
    PeleTecidoOmbroComponent, 
    SuperficieOsseaOmbroComponent,
    VentresMuscularesComponent, 
    TendaoCabecaLongaComponent, 
    TendaoInfraespinalComponent, 
    TendaoSupraespinalComponent, 
    TendaoSubescapularComponent, 
    TendaoRedondoComponent, 
    BursaSubacromialComponent, 
    ArticulacaoAcromioComponent, 
    ArticulacaoGlenoumeralComponent
  ],
  declarations: [
    OmbroComponent,
    PeleTecidoOmbroComponent, 
    SuperficieOsseaOmbroComponent,
    VentresMuscularesComponent, 
    TendaoCabecaLongaComponent, 
    TendaoInfraespinalComponent, 
    TendaoSupraespinalComponent, 
    TendaoSubescapularComponent, 
    TendaoRedondoComponent, 
    BursaSubacromialComponent, 
    ArticulacaoAcromioComponent, 
    ArticulacaoGlenoumeralComponent
  ]
})
export class OmbroModule { }
