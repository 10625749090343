import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material';
import { LoginProtocoloComponent } from './login-protocolo/login-protocolo.component';
import { Subscription } from 'rxjs';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formProtocolo: FormGroup;
  formEmail: FormGroup;
  watcher: Subscription;
  isPhone: boolean;

  private formSubmitAttempt: boolean;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private obsMedia: ObservableMedia
  ) {
    this.watcher = obsMedia
      .subscribe((change: MediaChange) => {
        (change.mqAlias === 'xs') ? this.isPhone = true : this.isPhone = false;
    });

  }

  ngOnInit() {
    this.formProtocolo = this.fb.group({
      protocolo: ['', Validators.required],
      password: ['', Validators.required]
    })

    this.formEmail = this.fb.group({
      Email: ['', Validators.email],
      Senha: ['', Validators.required]
    });
    this.formEmail.patchValue({
      Email: null,
      Senha: null
    });

    this.formProtocolo.patchValue({
      protocolo: null,
      password: null
    });

    //Alerta do Google Tradutor
    localStorage.setItem('goolTradutor', 'false');
    localStorage.removeItem('TipoImagem');
    this.authService.loggedIn.next(false);
    this.authService.integrationRoute.next(false);
    localStorage.clear();
    // Termo de aceitação temporário
    // localStorage.setItem('termoUso', 'false');
  }

  openDialog() {
    const dialogRef =  this.dialog.open(LoginProtocoloComponent)
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  isFieldInvalidPtc(field: string) {

    return (
      (!this.formProtocolo.get(field).valid && this.formProtocolo.get(field).touched) ||
      (this.formProtocolo.get(field).untouched && this.formSubmitAttempt)
    );

  }

  isFieldInvalidEml(field: string) {

    return (
      (!this.formEmail.get(field).valid && this.formEmail.get(field).touched) ||
      (this.formEmail.get(field).untouched && this.formSubmitAttempt)
    );

  }
}
