import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-transicao-abdominal',
  templateUrl: './transicao-abdominal.component.html',
  styleUrls: ['./transicao-abdominal.component.css']
})
export class TransicaoAbdominalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckEsteatosea') ckEsteatosea: MatCheckbox;
  @ViewChild('ckColecistectomia') ckColecistectomia: MatCheckbox;

  @ViewChild('ckColelitiase') ckColelitiase: MatCheckbox;

  @ViewChild('ckNefrolitiase') ckNefrolitiase: MatCheckbox;
  @ViewChild('slLateralidade') slLateralidade: MatSelect;

  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('slLateralidadeCisto') slLateralidadeCisto: MatSelect;

  @ViewChild('ckCistosRenais') ckCistosRenais: MatCheckbox;
  @ViewChild('slLateralidadeCistos') slLateralidadeCistos: MatSelect;

  @ViewChild('ckGastroplastia') ckGastroplastia: MatCheckbox;

  @ViewChild('ckDiverticulos') ckDiverticulos: MatCheckbox;

  lateralidadesSem: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' }
  ];

  lateralidades: ArrayDropDown[] = [
    { value: 'esquerda', viewValue: 'Esquerda' },
    { value: 'direita', viewValue: 'Direita' },
    { value: 'bilateral', viewValue: 'Bilateral' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahNefro: false,
      sahCisto: false,
      sahCistosRenais: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahNefro = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahCistosRenais = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahNefro = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahCistosRenais = false;
    this.catchIDs();
  }

  clickDefault() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickNefro() {
    if (this.showAndHideTerms.sahNefro === false)
      this.showAndHideTerms.sahNefro = true;
    else
      this.showAndHideTerms.sahNefro = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCisto() {
    if (this.showAndHideTerms.sahCisto === false)
      this.showAndHideTerms.sahCisto = true;
    else
      this.showAndHideTerms.sahCisto = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCistosRenais() {
    if (this.showAndHideTerms.sahCistosRenais === false)
      this.showAndHideTerms.sahCistosRenais = true;
    else
      this.showAndHideTerms.sahCistosRenais = false;

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      if (this.ckEsteatosea && this.ckEsteatosea.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckEsteatosea.id);
      }

      if (this.ckColecistectomia && this.ckColecistectomia.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckColecistectomia.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckColecistectomia.id);
        }
      }

      if (this.ckColelitiase && this.ckColelitiase.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckColelitiase.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckColelitiase.id);
        }
      }

      if (this.countLesoes > 0)
        this.emitNefrosLesao();
      else
        this.emitNefros();

      if (this.countLesoes > 0)
        this.emitCistosLesao();
      else
        this.emitCisto();

      if (this.countLesoes > 0)
        this.emitCistosRenaisLesao();
      else
        this.emitCistosRenais();

      if (this.ckGastroplastia && this.ckGastroplastia.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckGastroplastia.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckGastroplastia.id);
        }
      }

      if (this.ckDiverticulos && this.ckDiverticulos.checked) {
        if (this.countLesoes > 0) {
          this.countLesoes++;
          const objeto = { id: this.ckDiverticulos.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          this.countLesoes++;
          this.termoSelecionado.emit(this.ckDiverticulos.id);
        }
      }
    }

    this.listaCompleta.emit();
  }

  emitNefros() {
    if (this.ckNefrolitiase && this.ckNefrolitiase.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNefrolitiase.id);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitNefrosLesao() {
    if (this.ckNefrolitiase && this.ckNefrolitiase.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckNefrolitiase.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidade && this.slLateralidade.value) {
        const objeto = { elemento: this.slLateralidade, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCisto() {
    if (this.ckCisto && this.ckCisto.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCisto.id);

      if (this.slLateralidadeCisto && this.slLateralidadeCisto.value) {
        const objeto = { elemento: this.slLateralidadeCisto, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCistosLesao() {
    if (this.ckCisto && this.ckCisto.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidadeCisto && this.slLateralidadeCisto.value) {
        const objeto = { elemento: this.slLateralidadeCisto, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCistosRenais() {
    if (this.ckCistosRenais && this.ckCistosRenais.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCistosRenais.id);

      if (this.slLateralidadeCistos && this.slLateralidadeCistos.value) {
        const objeto = { elemento: this.slLateralidadeCistos, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCistosRenaisLesao() {
    if (this.ckCistosRenais && this.ckCistosRenais.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistosRenais.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralidadeCistos && this.slLateralidadeCistos.value) {
        const objeto = { elemento: this.slLateralidadeCistos, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  verificaAlterado() {
    if (!this.ckEsteatosea.checked &&
      !this.ckColecistectomia.checked &&
      !this.ckColelitiase.checked &&
      !this.ckNefrolitiase.checked &&
      !this.ckCisto.checked &&
      !this.ckCistosRenais.checked &&
      !this.ckGastroplastia.checked &&
      !this.ckDiverticulos.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }
}
