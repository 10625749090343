import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

export interface MurphyUltrassonico {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface Parede {
  value: string;
  selected: boolean;
  viewValue: string;
}

export interface MudancaDecubito {
  value: string;
  selected: boolean;
  viewValue: string;
}

@Component({
  selector: 'app-vesicula-biliar',
  templateUrl: './vesicula-biliar.component.html',
  styleUrls: ['./vesicula-biliar.component.css']
})
export class VesiculaBiliarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHipodistendida') rdHipodistendida: MatRadioButton;
  @ViewChild('rdHiperdistendida') rdHiperdistendida: MatRadioButton;
  @ViewChild('rdColecistite') rdColecistite: MatRadioButton;
  @ViewChild('rdAdenomiomatose') rdAdenomiomatose: MatRadioButton;
  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('slMurphyUltrassonico') slMurphyUltrassonico: MatSelect;
  @ViewChild('inEspessuraParede') inEspessuraParede: ElementRef;

  @ViewChild('ckLamaBiliar') ckLamaBiliar: MatCheckbox;

  @ViewChild('ckColelitiase') ckColelitiase: MatCheckbox;
  @ViewChild('rd1calculo') rd1calculo: MatRadioButton;
  @ViewChild('rd2calculos') rd2calculos: MatRadioButton;
  @ViewChild('rdRepletaCalculos') rdRepletaCalculos: MatRadioButton;
  @ViewChild('slCalculosDecubito') slCalculosDecubito: MatSelect;
  @ViewChild('inCalculosMedindo') inCalculosMedindo: ElementRef;

  @ViewChild('ckPolipos') ckPolipos: MatCheckbox;
  @ViewChild('rd1polipo') rd1polipo: MatRadioButton;
  @ViewChild('rd2polipos') rd2polipos: MatRadioButton;
  @ViewChild('slPoliposParedes') slPoliposParedes: MatSelect;
  @ViewChild('inPoliposMedindo') inPoliposMedindo: ElementRef;


  murphysUltrassonico: MurphyUltrassonico[] = [
    { value: 'com', selected: false, viewValue: 'com' },
    { value: 'sem', selected: false, viewValue: 'sem' },
  ];

  paredes: Parede[] = [
    { value: '98', selected: false, viewValue: 'Parede anterior' },
    { value: '99', selected: false, viewValue: 'Parede posterior' },
    { value: '100', selected: false, viewValue: 'Fundo' },
    { value: '101', selected: false, viewValue: 'Infundíbulo' },
  ];

  mudancasDecubito: MudancaDecubito[] = [
    { value: '89', selected: false, viewValue: 'Móvel' },
    { value: '90', selected: false, viewValue: 'Não Movél' },
  ];

  showAndHideTerms: any;
  countLesoes = 0;
  linha = 0;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEspessura: false,
      sahMurphy: false,
      sahLesoesVesiculaAlll: true,
      sahColelitiase: false,
      sahHipColetiase: false,
      sahPolipos: false,
      sahColelitiaseMudancaDecubito: false,
      sahDoisCalc: false,
      sahUmCalc: true,
      sahCheckPolipos: true
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal($event) {
    this.showAndHideTerms.sahEspessura = false;
    this.showAndHideTerms.sahMurphy = false;
    this.showAndHideTerms.sahLesoesVesiculaAlll = true;
    this.showAndHideTerms.sahCheckPolipos = true;
    this.showAndHideTerms.sahHipColetiase = true;
    this.catchIDs();
  }

  clickHipodistendida($event) {
    this.showAndHideTerms.sahEspessura = false;
    this.showAndHideTerms.sahMurphy = false;
    this.showAndHideTerms.sahLesoesVesiculaAlll = true;
    this.showAndHideTerms.sahHipColetiase = false;
    this.showAndHideTerms.sahCheckPolipos = true;
    this.catchIDs();
  }

  clickHiperdistendida($event) {
    this.showAndHideTerms.sahEspessura = false;
    this.showAndHideTerms.sahMurphy = false;
    this.showAndHideTerms.sahLesoesVesiculaAlll = true;
    this.showAndHideTerms.sahHipColetiase = false;
    this.showAndHideTerms.sahCheckPolipos = true;

    this.catchIDs();
  }

  clickColecistite($event) {
    this.showAndHideTerms.sahEspessura = true;
    this.showAndHideTerms.sahMurphy = true;
    this.showAndHideTerms.sahLesoesVesiculaAlll = true;
    this.showAndHideTerms.sahHipColetiase = true;
    this.showAndHideTerms.sahCheckPolipos = false;
    this.showAndHideTerms.sahPolipos = false;
    this.catchIDs();
  }

  clickAdenomiomatose($event) {
    this.showAndHideTerms.sahEspessura = true;
    this.showAndHideTerms.sahMurphy = false;
    this.showAndHideTerms.sahLesoesVesiculaAlll = true;
    this.showAndHideTerms.sahCheckPolipos = true;
    this.showAndHideTerms.sahHipColetiase = true;
    this.catchIDs();
  }
  clickAusente($event) {
    this.showAndHideTerms.sahEspessura = false;
    this.showAndHideTerms.sahMurphy = false;
    this.showAndHideTerms.sahLesoesVesiculaAlll = false;
    this.showAndHideTerms.sahColelitiase = false;
    this.showAndHideTerms.sahPolipos = false;
    this.ckLamaBiliar.checked = false;
    this.ckColelitiase.checked = false;
    this.ckPolipos.checked = false;
    this.catchIDs();
  }

  clickColelitiase() {
    if (this.showAndHideTerms.sahColelitiase === false) {
      this.showAndHideTerms.sahColelitiase = true;
      this.showAndHideTerms.sahColelitiaseMudancaDecubito = false;
      this.showAndHideTerms.sahUmCalc = false;
      this.showAndHideTerms.sahDoisCalc = false;

    } else {
      this.showAndHideTerms.sahColelitiase = false;
    }
    this.catchIDs();
  }

  clickPolipos() {
    if (this.showAndHideTerms.sahPolipos === false) {
      this.showAndHideTerms.sahPolipos = true;
    } else {
      this.showAndHideTerms.sahPolipos = false;
    }
    this.catchIDs();
  }

  clickUmColetiase($event) {
    this.showAndHideTerms.sahColelitiaseMudancaDecubito = true;
    this.showAndHideTerms.sahUmCalc = true;
    this.showAndHideTerms.sahDoisCalc = false;
    this.catchIDs();
  }

  clickDoisColetiase($event) {
    this.showAndHideTerms.sahColelitiaseMudancaDecubito = true;
    this.showAndHideTerms.sahUmCalc = false;
    this.showAndHideTerms.sahDoisCalc = true;
    this.catchIDs();
  }

  ClickRepletaColetiase($event) {
    this.showAndHideTerms.sahColelitiaseMudancaDecubito = false;
    this.showAndHideTerms.sahUmCalc = false;
    this.showAndHideTerms.sahDoisCalc = true;
    this.catchIDs();
  }



  clickOptPolipos() {
    this.catchIDs();
  }

  clickLama() {
    this.catchIDs();
  }

  changeMurphy($event) {
    this.catchIDs();
  }

  changeDecubito($event) {
    this.catchIDs();
  }

  changeParedes($event) {
    this.catchIDs();
  }

  blurMedindo($event) {
    this.catchIDs();
  }

  catchIDs() {
    this.linha = 1;
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      this.emitLesoes();
    }
    if (this.rdHipodistendida.checked) {
      this.termoSelecionado.emit(this.rdHipodistendida.id);

      this.emitLesoes();
    }
    if (this.rdHiperdistendida.checked) {
      this.termoSelecionado.emit(this.rdHiperdistendida.id);

      this.emitLesoes();
    }
    if (this.rdColecistite.checked) {
      this.termoSelecionado.emit(this.rdColecistite.id);
      this.countLesoes++;
      if (this.slMurphyUltrassonico != undefined && this.slMurphyUltrassonico.value != undefined) {
        const objeto = { elemento: this.slMurphyUltrassonico, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.inEspessuraParede != undefined && this.inEspessuraParede.nativeElement.id != undefined) {
        const objeto = { elemento: this.inEspessuraParede.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.emitLesoes();
    }

    if (this.rdAdenomiomatose.checked) {
      this.termoSelecionado.emit(this.rdAdenomiomatose.id);

      if (this.inEspessuraParede != undefined && this.inEspessuraParede.nativeElement.id != undefined) {
        const objeto = { elemento: this.inEspessuraParede.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.emitLesoes();
    }

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    this.listaCompleta.emit();
  }

  emitLesoes() {
    if (this.ckLamaBiliar != undefined && this.ckLamaBiliar.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLamaBiliar.id);
    }

    if (this.ckColelitiase != undefined && this.ckColelitiase.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiroColelitiase();
      } else {
        this.EmitOutroColelitiase();
      }
    }

    if (this.ckPolipos != undefined && this.ckPolipos.checked) {
      if (this.countLesoes == 0) {
        this.EmitPrimeiroPolipo();
      } else {
        this.EmitOutroPolipo();
      }
    }
  }

  EmitPrimeiroColelitiase() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckColelitiase.id);

    if (this.rd1calculo != undefined && this.rd1calculo.checked) {
      this.termoSelecionado.emit(this.rd1calculo.value);
    }
    if (this.rd2calculos != undefined && this.rd2calculos.checked) {
      this.termoSelecionado.emit(this.rd2calculos.value);
    }
    if (this.rdRepletaCalculos != undefined && this.rdRepletaCalculos.checked) {
      this.termoSelecionado.emit(this.rdRepletaCalculos.value);
    }

    if (this.inCalculosMedindo != undefined && this.inCalculosMedindo.nativeElement.id != undefined) {
      const objetoLesao = { elemento: this.inCalculosMedindo.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
    if (this.slCalculosDecubito != undefined && this.slCalculosDecubito.value != undefined) {
      this.termoSelecionado.emit(this.slCalculosDecubito.value);
    }
  }

  EmitOutroColelitiase() {
    this.countLesoes++;
    const objeto = { id: this.ckColelitiase.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rd1calculo != undefined && this.rd1calculo.checked) {
      const objetoGrupoLesao = { id: this.rd1calculo.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }
    if (this.rd2calculos != undefined && this.rd2calculos.checked) {
      const objetoGrupoLesao = { id: this.rd2calculos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }
    if (this.rdRepletaCalculos != undefined && this.rdRepletaCalculos.checked) {
      const objetoGrupoLesao = { id: this.rdRepletaCalculos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }

    if (this.inCalculosMedindo != undefined && this.inCalculosMedindo.nativeElement.id != undefined) {
      const objetoLesao = { elemento: this.inCalculosMedindo.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objetoLesao);
    }
    if (this.slCalculosDecubito != undefined && this.slCalculosDecubito.value != undefined) {
      const objetoGrupoLesao = { id: this.slCalculosDecubito.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }
  }

  EmitPrimeiroPolipo() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckPolipos.id);

    if (this.rd1polipo != undefined && this.rd1polipo.checked) {
      this.termoSelecionado.emit(this.rd1polipo.value);
    }
    if (this.rd2polipos != undefined && this.rd2polipos.checked) {
      this.termoSelecionado.emit(this.rd2polipos.value);
    }

    if (this.inPoliposMedindo != undefined && this.inPoliposMedindo.nativeElement.id != undefined) {
      const objetoLesao = { elemento: this.inPoliposMedindo.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
    if (this.slPoliposParedes != undefined && this.slPoliposParedes.value != undefined) {
      this.termoSelecionado.emit(this.slPoliposParedes.value);
    }
  }

  EmitOutroPolipo() {
    this.countLesoes++;
    const objeto = { id: this.ckPolipos.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rd1polipo != undefined && this.rd1polipo.checked) {
      const objetoGrupoLesao = { id: this.rd1polipo.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }
    if (this.rd2polipos != undefined && this.rd2polipos.checked) {
      const objetoGrupoLesao = { id: this.rd2polipos.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }

    if (this.inPoliposMedindo != undefined && this.inPoliposMedindo.nativeElement.id != undefined) {
      const objetoLesao = { elemento: this.inPoliposMedindo.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objetoLesao);
    }
    if (this.slPoliposParedes != undefined && this.slPoliposParedes.value != undefined) {
      const objetoGrupoLesao = { id: this.slPoliposParedes.value, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoGrupoLesao);
    }
  }
}
