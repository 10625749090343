import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagRelDetalhesComponent } from './pag-rel-detalhes/pag-rel-detalhes.component';
import { MatButtonModule, MatButtonToggleModule, MatCardModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSidenavModule, MatTableModule, MatToolbarModule } from '@angular/material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextEditorModule } from '../utils/textEditor/text-editor/text-editor.module';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatTableModule,
    FilterPipeModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    TextEditorModule,
    MatSidenavModule
  ],
  declarations: [PagRelDetalhesComponent]
})
export class PagamentosRealizadosModule { }
