
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { MatRadioButton, MatDialog } from '@angular/material';
import { Calculadora } from 'src/app/utils/calculadora';
import { LiquidoAmnioticoDialogComponent } from 'src/app/utils/dialog/table/liquido-amniotico-dialog/liquido-amniotico-dialog.component';
import { ParametrosBiometricosDialogComponent } from 'src/app/utils/dialog/table/parametros-biometricos-dialog/parametros-biometricos-dialog.component';
import { Validacoes } from 'src/app/utils/validacoes';
import { ObstetricoImagesReferenciaComponent } from 'src/app/utils/dialog/imagens/obstetrico-images-referencia/obstetrico-images-referencia.component';

@Component({
  selector: 'app-parametros-biometricos',
  templateUrl: './parametros-biometricos.component.html',
  styleUrls: ['./parametros-biometricos.component.css']
})
export class ParametrosBiometricosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdSemMedida') rdSemMedida: MatRadioButton;
  @ViewChild('rdComMedida') rdComMedida: MatRadioButton;
  @ViewChild('inDBP') inDBP: ElementRef;
  @ViewChild('inCC') inCC: ElementRef;
  @ViewChild('inCA') inCA: ElementRef;
  @ViewChild('inCF') inCF: ElementRef;
  @ViewChild('inPeso') inPeso: ElementRef;
  @ViewChild('inDOF') inDOF: ElementRef;
  @ViewChild('inCUM') inCUM: ElementRef;
  @ViewChild('inPercentil') inPercentil: ElementRef;

  @ViewChild('inSemanas') inSemanas: ElementRef;
  @ViewChild('inDias') inDias: ElementRef;

  showMedida = false;
  peso;
  semanas: string = 'Semanas';
  dias: string = 'Dias';

  inPesoInvalid: boolean = true;
  inCFInvalid: boolean = true;
  inDBPInvalid: boolean = true;
  inCAInvalid: boolean = true;
  inCCInvalid: boolean = true;

  constructor(private _calculadora: Calculadora,
    public dialog: MatDialog,
    private _validacoes: Validacoes) { }

  ngOnInit() {
  }

  blurParametros() {
    this.catchIDs();
  }

  clickSemMedida() {
    this.catchIDs();
    this.showMedida = false;

  }
  clickComMedida() {
    this.catchIDs();
    this.showMedida = true;
  }

  clickTabelaReferencia() {
    this.dialog.open(ParametrosBiometricosDialogComponent, {});
  }

  clickImagemReferencia() {
    this.dialog.open(ObstetricoImagesReferenciaComponent, {});
  }

  calculaIG(): number {
    debugger;
    return 10.85 +
      (0.06 * (parseFloat(this.inCC.nativeElement.value) / 10) * (parseFloat(this.inCF.nativeElement.value) / 10)) +
      (0.67 * (parseFloat(this.inDBP.nativeElement.value) / 10)) + (0.168 * (parseFloat(this.inCA.nativeElement.value) / 10));

    // return 10.85 +
    // (0.06 * this.inCC.nativeElement.value * this.inCF.nativeElement.value) +
    // (0.67 * this.inDBP.nativeElement.value) + (0.168 * this.inCA.nativeElement.value);
  }

  calculaPeso(): number {
    //fórmula: [Log10(PE)=1,3596+(0,00061xDBPxCA)+(0,424xCA)+(1,74xCF)+(0,0064xCC)-(0,00386xCAxCF)]
    let ca = this.inCA.nativeElement.value ? this.inCA.nativeElement.value : '0';
    let cf = this.inCF.nativeElement.value ? this.inCF.nativeElement.value : '0';
    let dbp = this.inDBP.nativeElement.value ? this.inDBP.nativeElement.value : '0';
    let cc = this.inCC.nativeElement.value ? this.inCC.nativeElement.value : '0'

    return 10 ** (1.3596 -
      0.00386 * parseFloat(ca)/10 * parseFloat(cf)/10 +
      0.0064 * parseFloat(cc)/10 +
      0.00061 * parseFloat(dbp)/10 *
      parseFloat(ca)/10 +
      0.0424 * parseFloat(ca)/10 +
      0.174 * parseFloat(cf)/10);
  }

  placeholderPeso(): string {
    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      this.peso = this.calculaPeso();
      return this.peso.toFixed(4);
    } else
      this.peso = 'Peso';
    this.peso = 'Peso';
    return this.peso;
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.removeInvalidField();
    if ((this.inDBP && this.inDBP.nativeElement.value) || (this.inCC && this.inCC.nativeElement.value)
      || (this.inCF && this.inCF.nativeElement.value) || (this.inCA && this.inCA.nativeElement.value)) {
      if (this.inPeso) {
        this.inPeso.nativeElement.value = this.calculaPeso().toFixed(2);
      }
    }

    if ((this.inDBP && this.inDBP.nativeElement.value) && (this.inCC && this.inCC.nativeElement.value) && (this.inCA && this.inCA.nativeElement.value)
      && (this.inCF && this.inCF.nativeElement.value) && (this.peso != 'Peso')
      && (this.inDOF && this.inDOF.nativeElement.value) && (this.inCUM && this.inCUM.nativeElement.value)) {

      //com medida
      this.termoSelecionado.emit("1984");

      let objetoElemento = { id: "1986", value: this.inDBP.nativeElement.value };
      let objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);

      objetoElemento = { id: "1988", value: this.inCC.nativeElement.value };
      objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);

      objetoElemento = { id: "1989", value: this.inCA.nativeElement.value };
      objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);

      objetoElemento = { id: "1991", value: this.inCF.nativeElement.value };
      objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);

      //Logica placeholder peso
      if (this.inPeso.nativeElement.value) {
        objetoElemento = { id: "1992", value: this.inPeso.nativeElement.value };
        objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);
      } else {
        objetoElemento = { id: "1992", value: this.peso };
        objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);
      }

      objetoElemento = { id: "1987", value: this.inDOF.nativeElement.value };
      objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);

      objetoElemento = { id: "1990", value: this.inCUM.nativeElement.value };
      objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);

      let idade = this.calculaIG();
      let semanas = parseInt(idade + '');
      let dias = parseInt(((idade - semanas) * 7) + '');

      if ((semanas >= 0) && (dias >= 0)) {

        if (this.inSemanas && this.inSemanas.nativeElement.value) {
          objetoCriado = { elemento: this.inSemanas.nativeElement };
          this.termosGenericos.emit(objetoCriado);
        } else {
          this.semanas = semanas.toString();
          objetoElemento = { id: "2035", value: semanas };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }

        if (this.inDias && this.inDias.nativeElement.value) {
          objetoCriado = { elemento: this.inDias.nativeElement };
          this.termosGenericos.emit(objetoCriado);
        } else {
          this.dias = dias.toString();
          objetoElemento = { id: "2036", value: dias };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }

      }

    } else
      if ((this.inDBP && this.inDBP.nativeElement.value)
        && (this.inCC && this.inCC.nativeElement.value) && (this.inCA && this.inCA.nativeElement.value)
        || (this.inCF && this.inCF.nativeElement.value) || (this.peso != 'Peso')) {

        //sem medida
        this.termoSelecionado.emit("1985");

        let objetoElemento = { id: "1986", value: this.inDBP.nativeElement.value };
        let objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);

        objetoElemento = { id: "1988", value: this.inCC.nativeElement.value };
        objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);

        objetoElemento = { id: "1989", value: this.inCA.nativeElement.value };
        objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);

        objetoElemento = { id: "1991", value: this.inCF.nativeElement.value };
        objetoCriado = { elemento: objetoElemento };
        this.termosGenericos.emit(objetoCriado);

        //Logica placeholder peso
        if (this.inPeso.nativeElement.value) {
          objetoElemento = { id: "1992", value: this.inPeso.nativeElement.value };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        } else {
          objetoElemento = { id: "1992", value: this.peso };
          objetoCriado = { elemento: objetoElemento };
          this.termosGenericos.emit(objetoCriado);
        }

        let idade = this.calculaIG();
        //      const teste = this._calculadora.semanasDiff(parseInt(idade))
        // let semanas = Math.floor(idade / 7);
        // let dias = Math.round(idade % 7);

        let semanas = parseInt(idade + '');
        let dias = parseInt(((idade - semanas) * 7) + '');

        if ((semanas >= 0) && (dias >= 0)) {

          if (this.inSemanas && this.inSemanas.nativeElement.value) {
            objetoCriado = { elemento: this.inSemanas.nativeElement };
            this.termosGenericos.emit(objetoCriado);
          } else {
            this.semanas = semanas.toString();
            objetoElemento = { id: "2035", value: semanas };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          }

          if (this.inDias && this.inDias.nativeElement.value) {
            objetoCriado = { elemento: this.inDias.nativeElement };
            this.termosGenericos.emit(objetoCriado);
          } else {
            this.dias = dias.toString();
            objetoElemento = { id: "2036", value: dias };
            objetoCriado = { elemento: objetoElemento };
            this.termosGenericos.emit(objetoCriado);
          }

        }
      }
    if (this.inPercentil && this.inPercentil.nativeElement.value) {
      let objetoElemento = { id: "4787", value: this.inPercentil.nativeElement.value };
      let objetoCriado = { elemento: objetoElemento };
      this.termosGenericos.emit(objetoCriado);
    }

    if ((this.inDBP && this.inDBP.nativeElement.value)
      && (this.inCC && this.inCC.nativeElement.value)
      && (this.inCF && this.inCF.nativeElement.value)
      && (this.inCA && this.inCA.nativeElement.value)) {
      this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'ParametrosBiometricos' });
    } else {
      this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'ParametrosBiometricos' });
    }

    this.listaCompleta.emit();
  }

  removeInvalidField() {
    if (this.inCF && this.inCF.nativeElement.value) {
      this.inCFInvalid = false;
    } else {
      this.inCFInvalid = true;
    }
    if (this.inDBP && this.inDBP.nativeElement.value) {
      this.inDBPInvalid = false;
    } else {
      this.inDBPInvalid = true;
    }
    if (this.inCA && this.inCA.nativeElement.value) {
      this.inCAInvalid = false;
    } else {
      this.inCAInvalid = true;
    }
    if (this.inCC && this.inCC.nativeElement.value) {
      this.inCCInvalid = false;
    } else {
      this.inCCInvalid = true;
    }
  }
}
