import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-alinhamento',
  templateUrl: './alinhamento.component.html',
  styleUrls: ['./alinhamento.component.css']
})
export class AlinhamentoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckAcentLordose') ckAcentLordose: MatCheckbox;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado : true,
    };
  }

  clickNormal() {
    if (this.rdNormal.checked){
      this.showAndHideTerms.sahAlterado = false;
    }
    this.catchIDs();
  }

  clickAlterado() {
    if (this.rdAlterado.checked){
      this.showAndHideTerms.sahAlterado = true;
    }
    this.catchIDs();
  }

  clickAcentLordose() {
    this.mudaAlterado();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.ckAcentLordose.checked = false;
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.ckAcentLordose.checked) {
      this.termoSelecionado.emit(this.ckAcentLordose.value);
    }

    this.listaCompleta.emit();
  }

  mudaAlterado(){
    if (this.ckAcentLordose.checked){
      this.rdAlterado.checked = true;
    }else{
      this.rdNormal.checked = true;
    }
  }
}
