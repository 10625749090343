import { Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import { MatCheckbox, MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-partes-moles',
  templateUrl: './partes-moles.component.html',
  styleUrls: ['./partes-moles.component.css']
})
export class PartesMolesComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('ckSemAlteracoes') ckSemAlteracoes: MatCheckbox;
  @ViewChild('rdSemCalcificacoes') rdSemCalcificacoes: MatRadioButton;

  constructor() { }
  showAndHideTerms: any;
  checkDefault = true;
  ngOnInit() {
    this.checkDefault = true;
  }

  clickNaoCitar(){

    this.catchIDs();
  }
  clickSemCalcificacao(){

    this.catchIDs();
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdSemCalcificacoes.checked) {
      this.termoSelecionado.emit(this.rdSemCalcificacoes.id);
      if (this.ckSemAlteracoes.checked) {
        this.termoSelecionado.emit(this.ckSemAlteracoes.id);
      }
    } 

    this.listaCompleta.emit();

  }
}
