import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-labio-acetabular',
  templateUrl: './labio-acetabular.component.html',
  styleUrls: ['./labio-acetabular.component.css']
})
export class LabioAcetabularComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdHipertrofia') rdHipertrofia: MatRadioButton;
  @ViewChild('rdNaoCaracterizado') rdNaoCaracterizado: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked)
      this.termoSelecionado.emit(this.rdNormal.value);

    if (this.rdHipertrofia.checked)
      this.termoSelecionado.emit(this.rdHipertrofia.value);
    
    if (this.rdNaoCaracterizado.checked)
      this.termoSelecionado.emit(this.rdNaoCaracterizado.value);

    this.listaCompleta.emit();
  }

}
