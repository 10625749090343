import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-complexos-ligamentares-cotovelo',
  templateUrl: './complexos-ligamentares-cotovelo.component.html',
  styleUrls: ['./complexos-ligamentares-cotovelo.component.css']
})
export class ComplexosLigamentaresCotoveloComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdLesaoParcial') rdLesaoParcial: MatRadioButton;
  @ViewChild('rdRoturaTotal') rdRoturaTotal: MatRadioButton;
  @ViewChild('rdAlteracaoFibrocicatricial') rdAlteracaoFibrocicatricial: MatRadioButton;

  @ViewChild('slLigamentos') slLigamentos: MatSelect;

  ligamentos: ArrayDropDown[] = [
    { value: 'medial', viewValue: 'medial'},
    { value: 'lateral', viewValue: 'lateral' }
  ];

  showLigamentos = false;

  constructor() { }


  ngOnInit() {
    this.showLigamentos = false;
  }

  clickNormal() {
    this.showLigamentos = false;
    this.catchIDs();
  }

  clickLesaoParcial() {
    this.showLigamentos = true;
    this.catchIDs();
  }
  clickRoturaTotal() {
    this.showLigamentos = true;
    this.catchIDs();
  }

  clickAlteracaoFibrocicatricial() {
    this.showLigamentos = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdLesaoParcial.checked) {
      this.termoSelecionado.emit(this.rdLesaoParcial.value);
      if (this.slLigamentos && this.slLigamentos.value) {
        const objeto = { elemento: this.slLigamentos};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdRoturaTotal.checked) {
      this.termoSelecionado.emit(this.rdRoturaTotal.value);
      if (this.slLigamentos && this.slLigamentos.value) {
        const objeto = { elemento: this.slLigamentos};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdAlteracaoFibrocicatricial.checked) {
      this.termoSelecionado.emit(this.rdAlteracaoFibrocicatricial.value);
      if (this.slLigamentos && this.slLigamentos.value) {
        const objeto = { elemento: this.slLigamentos};
        this.termosGenericos.emit(objeto);
      }
    }



    this.listaCompleta.emit();
  }


}
