import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { Validacoes } from '../../../../../../utils/validacoes';
import { d } from '@angular/core/src/render3';


@Component({
  selector: 'app-achado-tvp-inf',
  templateUrl: './achado-tvp-inf.component.html',
  styleUrls: ['./achado-tvp-inf.component.css']
})
export class AchadoTvpInfComponent implements OnInit {


  public showAndHideTerms: any;
  public countLesoes = 0;
  public ausenteID = 3569;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();



  @ViewChild('rdEdema') rdEdema: MatCheckbox;
  @ViewChild('rdCistopoplItea') rdCistopoplItea: MatCheckbox;
  @ViewChild('rdTennisLegRoturaParcial') rdTennisLegRoturaParcial: MatCheckbox;
  @ViewChild('sllaminasliquidas') sllaminasliquidas: MatSelect;
  @ViewChild('sllocalizacaoface') sllocalizacaoface: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slparedes') slparedes: MatSelect;
  @ViewChild('slconteudo') slconteudo: MatSelect;
  @ViewChild('slextensaonaperna') slextensaonaperna: MatSelect;
  @ViewChild('slgastrocnemio') slgastrocnemio: MatSelect;
  @ViewChild('InMedida') InMedida: ElementRef;



  public laminasliquidas: ArrayDropDown[] = [
    {
      value: 'com lâminas líquidas de permeio',
      viewValue: 'Com'
    },
    {
      value: 'sem lâminas líquidas de permeio',
      viewValue: 'Sem'
    },
  ];

  public localizacaoface: ArrayDropDown[] = [
    { value: 'na face anterior', viewValue: 'Na face anterior' },
    { value: 'na face posterior', viewValue: 'Na face posterior' },
    { value: 'na face lateral', viewValue: 'Na face lateral' },
    { value: 'na face medial', viewValue: 'Na face medial' },
    { value: 'difusa', viewValue: 'Difusa' },
  ];


  public localizacao: ArrayDropDown[] = [
    { value: 'coxa', viewValue: 'Coxa' },
    { value: 'perna', viewValue: 'Perna' }
  ];

  public paredes: ArrayDropDown[] = [
    { value: 'finas', viewValue: 'Finas' },
    { value: 'espessadas', viewValue: 'Espessadas' },

  ];

  public conteudo: ArrayDropDown[] = [
    { value: 'anecoico', viewValue: 'Anecoico' },
    { value: 'anecoico com septações internas', viewValue: 'Anecoico com septações internas' },
    { value: 'hipoecoico', viewValue: 'Hipoecoico' },
  ];

  public extensaonaperna: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' },
  ];

  public gastrocnemio: ArrayDropDown[] = [
    { value: 'lateral', viewValue: 'Lateral' },
    { value: 'medial', viewValue: 'Medial' },

  ];

  unidadeMedida: string;

  constructor(public readonly _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: false,
      sahCistopoplItea: false,
      sahTennisLegRoturaParcial: false,
      sahExtensaoNaPerna: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public ClickEdema(): any {
    if (this.showAndHideTerms.sahEdema === false) {
      this.showAndHideTerms.sahEdema = true;
    } else {
      this.showAndHideTerms.sahEdema = false;
    }
    this.catchIDs();
  }

  public ClickCistopoplItea(): any {
    if (this.showAndHideTerms.sahCistopoplItea === false) {
      this.showAndHideTerms.sahCistopoplItea = true;
    } else {
      this.showAndHideTerms.sahCistopoplItea = false;
    }
    this.catchIDs();
  }


  public ClickTennisLegRoturaParcial(): any {
    if (this.showAndHideTerms.sahTennisLegRoturaParcial === false) {
      this.showAndHideTerms.sahTennisLegRoturaParcial = true;
    } else {
      this.showAndHideTerms.sahTennisLegRoturaParcial = false;
    }
    this.catchIDs();
  }

  public ClickCistopopRotura(): any {
    if(this.showAndHideTerms.sahExtensaoNaPerna === false){
      this.showAndHideTerms.sahExtensaoNaPerna = true;
    } else {
      this.showAndHideTerms.sahExtensaoNaPerna = false;
    }
    this.catchIDs();
  }

  public blurMedindo(): any {
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }


  public catchIDs(): any {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');


    // Edema
    if (this.countLesoes > 0) {
      this.emitrdEdemaLesao();
    } else {
      this.emitrdEdema();
    }

    // Cisto
    if (this.countLesoes > 0) {
      this.emitrdCistopoplIteaLesao();
    } else {
      this.emitCistopoplItea();
    }

    // Tennis
    if (this.countLesoes > 0) {
      this.emitrdTennisLegRoturaParcialLesao();
    } else {
      this.emitrdTennisLegRoturaParcial();
    }


    this.listaCompleta.emit();

  }

  public emitrdEdema() {
    if (this.rdEdema && this.rdEdema.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdEdema.id);

      if (this.sllaminasliquidas && this.sllaminasliquidas.value) {
        const objeto = { elemento: this.sllaminasliquidas, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacaoface && this.sllocalizacaoface.value) {
        const objeto = { elemento: this.sllocalizacaoface, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if (!this.rdEdema.checked && !this.rdCistopoplItea.checked && !this.rdTennisLegRoturaParcial.checked) {
      this.termoSelecionado.emit(this.ausenteID);
    }

  }

  public emitrdEdemaLesao() {
    if (this.rdEdema !== undefined && this.rdEdema.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdEdema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.sllaminasliquidas && this.sllaminasliquidas.value) {
        const objeto = { elemento: this.sllaminasliquidas, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacaoface && this.sllocalizacaoface.value) {
        const objeto = { elemento: this.sllocalizacaoface, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    } else if (!this.rdEdema.checked && !this.rdCistopoplItea.checked && !this.rdTennisLegRoturaParcial.checked) {
      this.termoSelecionado.emit(this.ausenteID);
    }
  }

  public emitCistopoplItea() {
    if (this.rdCistopoplItea && this.rdCistopoplItea.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdCistopoplItea.id);

      if (this.slparedes && this.slparedes.value) {
        const objeto = { elemento: this.slparedes, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slconteudo && this.slconteudo.value) {
        const objeto = { elemento: this.slconteudo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }


      //  colocar medida aqui

      if (this.InMedida && this.InMedida.nativeElement.value) {
        const objeto = { elemento: this.InMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slextensaonaperna && this.slextensaonaperna.value) {
        const objeto = { elemento: this.slextensaonaperna, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
        this.termoSelecionado.emit('2491');
      } else {
        this.termoSelecionado.emit('2487');
      }
    } else if (!this.rdEdema.checked && !this.rdCistopoplItea.checked && !this.rdTennisLegRoturaParcial.checked) {
      this.termoSelecionado.emit(this.ausenteID);
    }
  }

  public emitrdCistopoplIteaLesao() {
    if (this.rdCistopoplItea !== undefined && this.rdCistopoplItea.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdCistopoplItea.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);


      if (this.slparedes && this.slparedes.value) {
        const objeto = { elemento: this.slparedes, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slconteudo && this.slconteudo.value) {
        const objeto = { elemento: this.slconteudo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }


      //  colocar medida aqui

      if (this.InMedida && this.InMedida.nativeElement.value) {
        const objeto = { elemento: this.InMedida.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slextensaonaperna && this.slextensaonaperna.value) {
        const objeto = { elemento: this.slextensaonaperna, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const objetoFix = { id: 2491, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoFix);
      } else {
        const objeto = { id: 2487, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }
    } else if (!this.rdEdema.checked && !this.rdCistopoplItea.checked && !this.rdTennisLegRoturaParcial.checked) {
      this.termoSelecionado.emit(this.ausenteID);
    }
  }



  public emitrdTennisLegRoturaParcial() {
    if (this.rdTennisLegRoturaParcial && this.rdTennisLegRoturaParcial.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdTennisLegRoturaParcial.id);

      if (this.slconteudo && this.slconteudo.value) {
        const objeto = { elemento: this.slconteudo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slgastrocnemio && this.slgastrocnemio.value) {
        const objeto = { elemento: this.slgastrocnemio, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      //  colocar medida aqui
      if (this.InMedida && this.InMedida.nativeElement.value) {
        const objeto = { elemento: this.InMedida.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    } else if (!this.rdEdema.checked && !this.rdCistopoplItea.checked && !this.rdTennisLegRoturaParcial.checked) {
      this.termoSelecionado.emit(this.ausenteID);
    }
  }

  public emitrdTennisLegRoturaParcialLesao() {
    if (this.rdTennisLegRoturaParcial !== undefined && this.rdTennisLegRoturaParcial.checked) {
      this.countLesoes++;
      const objeto = { id: this.rdTennisLegRoturaParcial.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);


      if (this.slconteudo && this.slconteudo.value) {
        const objeto = { elemento: this.slconteudo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slgastrocnemio && this.slgastrocnemio.value) {
        const objeto = { elemento: this.slgastrocnemio, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      //  colocar medida aqui
      if (this.InMedida && this.InMedida.nativeElement.value) {
        const objeto = { elemento: this.InMedida.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    } else if (!this.rdEdema.checked && !this.rdCistopoplItea.checked && !this.rdTennisLegRoturaParcial.checked) {
      this.termoSelecionado.emit(this.ausenteID);
    }
  }



}

