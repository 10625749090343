import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';

import { CommonModule } from '@angular/common';
import { CotoveloComponent } from './cotovelo.component';
import { PeleTecidoCotoveloComponent } from './TermosComponents/pele-tecido-cotovelo/pele-tecido-cotovelo.component';
import { SuperficieOsseaCotoveloComponent } from './TermosComponents/superficie-ossea-cotovelo/superficie-ossea-cotovelo.component';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { DerrameArticularCotoveloComponent } from './TermosComponents/derrame-articular-cotovelo/derrame-articular-cotovelo.component';
import { TendoesExtensoresCotoveloComponent } from './TermosComponents/tendoes-extensores-cotovelo/tendoes-extensores-cotovelo.component';
import { TendoesFlexoresCotoveloComponent } from './TermosComponents/tendoes-flexores-cotovelo/tendoes-flexores-cotovelo.component';
import { TendaoBicipitalComponent } from './TermosComponents/tendao-bicipital/tendao-bicipital.component';
import { TendaoTricepsComponent } from './TermosComponents/tendao-triceps/tendao-triceps.component';
import { BursaOlecranianaComponent } from './TermosComponents/bursa-olecraniana/bursa-olecraniana.component';
import { ComplexosLigamentaresCotoveloComponent } from './TermosComponents/complexos-ligamentares-cotovelo/complexos-ligamentares-cotovelo.component';
import { NervoUlnarComponent } from './TermosComponents/nervo-ulnar/nervo-ulnar.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';


@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    LateralidadeModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule, 
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    CotoveloComponent,
    PeleTecidoCotoveloComponent,
    SuperficieOsseaCotoveloComponent,
    DerrameArticularCotoveloComponent,
    TendoesExtensoresCotoveloComponent,
    TendoesFlexoresCotoveloComponent,
    TendaoBicipitalComponent,
    TendaoTricepsComponent,
    BursaOlecranianaComponent,
    ComplexosLigamentaresCotoveloComponent,
    NervoUlnarComponent
  ],
  declarations: [CotoveloComponent,
    PeleTecidoCotoveloComponent,
    SuperficieOsseaCotoveloComponent,
    DerrameArticularCotoveloComponent,
    TendoesExtensoresCotoveloComponent,
    TendoesFlexoresCotoveloComponent,
    TendaoBicipitalComponent,
    TendaoTricepsComponent,
    BursaOlecranianaComponent,
    ComplexosLigamentaresCotoveloComponent,
    NervoUlnarComponent]
})
export class CotoveloModule { }
