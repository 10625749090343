import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material';

@Component({
  selector: 'app-vesiculas-seminais',
  templateUrl: './vesiculas-seminais.component.html',
  styleUrls: ['./vesiculas-seminais.component.css']
})
export class VesiculasSeminaisComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNaoCaracterizadas') rdNaoCaracterizadas: MatRadioButton;

  constructor() { }

  ngOnInit() {
  }

  clickGeneric() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdNaoCaracterizadas.checked) {
      this.termoSelecionado.emit(this.rdNaoCaracterizadas.id);
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

}
