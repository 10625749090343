import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-anatomia-fetal',
  templateUrl: './anatomia-fetal.component.html',
  styleUrls: ['./anatomia-fetal.component.css']
})
export class AnatomiaFetalComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('slCranio') slCranio: MatSelect;
  @ViewChild('slColuna') slColuna: MatSelect;
  @ViewChild('slCoracao') slCoracao: MatSelect;
  @ViewChild('slAbdome') slAbdome: MatSelect;
  @ViewChild('slEstomago') slEstomago: MatSelect;
  @ViewChild('slBexRins') slBexRins: MatSelect;
  @ViewChild('slMaos') slMaos: MatSelect;
  @ViewChild('slPes') slPes: MatSelect;
  @ViewChild('slSistema') slSistema: MatSelect;
  @ViewChild('slFace') slFace: MatSelect;

  @ViewChild('inOutrasCra') inOutrasCra: ElementRef;
  @ViewChild('inAlteradoCol') inAlteradoCol: ElementRef;
  @ViewChild('inAlteradoCor') inAlteradoCor: ElementRef;
  @ViewChild('inAlteradoAbd') inAlteradoAbd: ElementRef;
  @ViewChild('inAlteradoEst') inAlteradoEst: ElementRef;
  @ViewChild('inOutrasBexRins') inOutrasBexRins: ElementRef;
  @ViewChild('inOutrasMaos') inOutrasMaos: ElementRef;
  @ViewChild('inOutrasPes') inOutrasPes: ElementRef;
  @ViewChild('inOutrasSistema') inOutrasSistema: ElementRef;
  @ViewChild('inOutrasFace') inOutrasFace: ElementRef;


  showAndHideTerms: any;
  countLesoes = 0;

  cranioDefault: string;
  colunaDefault: string;
  coracaoDefault: string;
  abdDefault: string;
  estomagoDefault: string;
  bexigaDefault: string;
  maoDefault: string;
  peDefault: string;
  sistemaDefault: string;
  faceDefault: string;

  cranios: ArrayDropDown[] = [
    { value: 'Aparentemente normal', viewValue: 'Aparentemente normal' },
    { value: 'Acrania', viewValue: 'Acrania' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  colunas: ArrayDropDown[] = [
    { value: 'Aparentemente normal', viewValue: 'Aparentemente normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  coracoes: ArrayDropDown[] = [
    { value: 'Corte de 4 câmaras aparentemente normal', viewValue: 'Corte de 4 câmaras aparentemente normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  abdomes: ArrayDropDown[] = [
    { value: 'Parede abdominal aparentemente íntegra, inserção do cordão normal', viewValue: 'Aparentemente normal' },
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  estomagos: ArrayDropDown[] = [
    { value: 'Bem caracterizado em sua topografia habitual', viewValue: 'Aparentemente normal' },
    { value: 'Bolha gástrica não visualizada', viewValue: 'Bolha gástrica não visualizada'},
    { value: 'Alterado: ', viewValue: 'Alterado' }
  ];

  bexigas: ArrayDropDown[] = [
    { value: 'Visíveis', viewValue: 'Visíveis' },
    { value: 'Bexiga não visualizada', viewValue: 'Bexiga não visualizada' },
    { value: 'Rins não visualizados', viewValue: 'Rins não visualizados' },
    { value: 'Ambos não visualizados', viewValue: 'Ambos não visualizados' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  maos: ArrayDropDown[] = [
    { value: 'Ambas visualizadas', viewValue: 'Ambas visualizadas' },
    { value: 'Apenas uma mão visualizada', viewValue: 'Apenas uma mão visualizada' },
    { value: 'Não visualizadas', viewValue: 'Não visualizadas' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  pes: ArrayDropDown[] = [
    { value: 'Ambos visualizados', viewValue: 'Ambos visualizados' },
    { value: 'Apenas um pé visualizado', viewValue: 'Apenas um pé visualizado' },
    { value: 'Não visualizados', viewValue: 'Não visualizados' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  sistemas: ArrayDropDown[] = [
    { value: 'Estruturas da linha média aparentemente preservada, plexos coróides de aspecto típico.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  faces: ArrayDropDown[] = [
    { value: 'Osso nasal de tamanho normal, ossos maxilar e mandibular com ecogenicidade normal.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  constructor() { }

  ngOnInit() {
    this.slCranio.value = '**';
    this.slColuna.value = '**';
    this.slCoracao.value = '**';
    this.slAbdome.value = '**';
    this.slEstomago.value = '**';
    this.slBexRins.value = '**';
    this.slMaos.value = '**';
    this.slPes.value = '**';
    this.slSistema.value = '**';
    this.slFace.value = '**';

    this.showAndHideTerms = {
      sahAlteradoCra: false,
      sahAlteradoCol: false,
      sahAlteradoCor: false,
      sahAlteradoAbd: false,
      sahAlteradoEst: false,
      sahAlteradoBexRins: false,
      sahAlteradoMaos: false,
      sahAlteradoPes: false,
      sahAlteradoSistema: false,
      sahAlteradoFace: false
    }
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur(){
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitCranioSimples();

    if(this.countLesoes > 0){
      this.emitSistemaLesao();
    } else {
      this.emitSistemaSimples();
    }

    if(this.countLesoes > 0){
      this.emitFaceLesao();
    } else {
      this.emitFaceSimples();
    }
    
    if(this.countLesoes > 0){
      this.emitColunaLesao();
    } else {
      this.emitColunaSimples();
    }

    if(this.countLesoes > 0){
      this.emitCoracaoLesao();
    } else {
      this.emitCoracaoSimples();
    }

    if(this.countLesoes > 0){
      this.emitAbdomeLesao();
    } else {
      this.emitAbdomeSimples();
    }

    if(this.countLesoes > 0){
      this.emitEstomagoLesao();
    } else {
      this.emitEstomagoSimples();
    }

    if(this.countLesoes > 0){
      this.emitBexRinsLesao();
    } else {
      this.emitBexRinsSimples();
    }

    if(this.countLesoes > 0){
      this.emitMaosLesao();
    } else {
      this.emitMaosSimples();
    }

    if(this.countLesoes > 0){
      this.emitPesLesao();
    } else {
      this.emitPesSimples();
    }

    this.listaCompleta.emit();
  }

  emitCranioLesao(){
    let elemento;

    if (this.slCranio && this.slCranio.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slCranio.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasCra && this.slCranio.value == 'Outras alterações: '){
        elemento = { id: this.slCranio.id, value: this.inOutrasCra.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      } else {
        elemento = { id: this.slCranio.id, value: this.slCranio.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCranioSimples(){
    let elemento;

    if (this.slCranio && this.slCranio.value != '**') {
      this.countLesoes++;

      if (this.inOutrasCra && this.slCranio.value == 'Outras alterações: '){
        elemento = { id: this.slCranio.id, value: this.inOutrasCra.nativeElement.value };

        const cranioalt = { elemento: { id: 5596, value: this.inOutrasCra.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(cranioalt);
      }else{
        elemento = { id: this.slCranio.id, value: this.slCranio.value };
      }
      if(this.slCranio.value == 'Acrania'){
        this.termoSelecionado.emit(5595);
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);

    }
  }

  emitColunaLesao(){
    let elemento;

    if (this.slColuna && this.slColuna.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slColuna.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inAlteradoCol && this.slColuna.value == 'Alterado: '){
        elemento = { id: this.slColuna.id, value: this.inAlteradoCol.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const colunaAlt = { elemento: { id: 5597, value: this.inAlteradoCol.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(colunaAlt);
      }
      else {
        elemento = { id: this.slColuna.id, value: this.slColuna.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitColunaSimples(){
    let elemento;

    if (this.slColuna && this.slColuna.value != '**') {
      this.countLesoes++;

      if (this.inAlteradoCol && this.slColuna.value == 'Alterado: '){
        elemento = { id: this.slColuna.id, value: this.inAlteradoCol.nativeElement.value };

        const colunaAlt = { elemento: { id: 5597, value: this.inAlteradoCol.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(colunaAlt);
      }else{
        elemento = { id: this.slColuna.id, value: this.slColuna.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitCoracaoLesao(){
    let elemento;

    if (this.slCoracao && this.slCoracao.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slCoracao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inAlteradoCor && this.slCoracao.value == 'Alterado: '){
        elemento = { id: this.slCoracao.id, value: this.inAlteradoCor.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const coracaoAlt = { elemento: { id: 5598, value: this.inAlteradoCor.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(coracaoAlt);
      }
      else {
        elemento = { id: this.slCoracao.id, value: this.slCoracao.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCoracaoSimples(){
    let elemento;

    if (this.slCoracao && this.slCoracao.value != '**') {
      this.countLesoes++;

      if (this.inAlteradoCor && this.slCoracao.value == 'Alterado: '){
        elemento = { id: this.slCoracao.id, value: this.inAlteradoCor.nativeElement.value };

        const coracaoAlt = { elemento: { id: 5598, value: this.inAlteradoCor.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(coracaoAlt);
      }else{
        elemento = { id: this.slCoracao.id, value: this.slCoracao.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitAbdomeLesao(){
    let elemento;

    if (this.slAbdome && this.slAbdome.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slAbdome.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inAlteradoAbd && this.slAbdome.value == 'Alterado: '){
        elemento = { id: this.slAbdome.id, value: this.inAlteradoAbd.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const abdomeAlt = { elemento: { id: 5599, value: this.inAlteradoAbd.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(abdomeAlt);
      }
      else {
        elemento = { id: this.slAbdome.id, value: this.slAbdome.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAbdomeSimples(){
    let elemento;

    if (this.slAbdome && this.slAbdome.value != '**') {
      this.countLesoes++;

      if (this.inAlteradoAbd && this.slAbdome.value == 'Alterado: '){
        elemento = { id: this.slAbdome.id, value: this.inAlteradoAbd.nativeElement.value };

        const abdomeAlt = { elemento: { id: 5599, value: this.inAlteradoAbd.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(abdomeAlt);
      }else{
        elemento = { id: this.slAbdome.id, value: this.slAbdome.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitEstomagoLesao(){
    let elemento;

    if (this.slEstomago && this.slEstomago.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slEstomago.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inAlteradoEst && this.slEstomago.value == 'Alterado: '){
        elemento = { id: this.slEstomago.id, value: this.inAlteradoEst.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const estomagoAlt = { elemento: { id: 5600, value: this.inAlteradoEst.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(estomagoAlt);
      }
      else {
        elemento = { id: this.slEstomago.id, value: this.slEstomago.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEstomagoSimples(){
    let elemento;

    if (this.slEstomago && this.slEstomago.value != '**') {
      this.countLesoes++;

      if (this.inAlteradoEst && this.slEstomago.value == 'Alterado: '){
        elemento = { id: this.slEstomago.id, value: this.inAlteradoEst.nativeElement.value };

        const estomagoAlt = { elemento: { id: 5600, value: this.inAlteradoEst.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(estomagoAlt);
      }else{
        elemento = { id: this.slEstomago.id, value: this.slEstomago.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitBexRinsLesao(){
    let elemento;

    if (this.slBexRins && this.slBexRins.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slBexRins.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasBexRins && this.slBexRins.value == 'Outras alterações: '){
        elemento = { id: this.slBexRins.id, value: this.inOutrasBexRins.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const bexigaAlt = { elemento: { id: 5601, value: this.inOutrasBexRins.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(bexigaAlt);
      }
      else {
        elemento = { id: this.slBexRins.id, value: this.slBexRins.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if(this.slBexRins.value == 'Bexiga não visualizada'){
        const bexNaoVisualizada = { id: 5604, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(bexNaoVisualizada);
      } else if(this.slBexRins.value == 'Rins não visualizados'){
        const rinsNaoVisualizados = { id: 5605, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(rinsNaoVisualizados);
      } else if(this.slBexRins.value == 'Ambos não visualizados'){
        const ambosNaoVisualizados = { id: 5606, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(ambosNaoVisualizados);
      }
    }
  }

  emitBexRinsSimples(){
    let elemento;

    if (this.slBexRins && this.slBexRins.value != '**') {
      this.countLesoes++;

      if (this.inOutrasBexRins && this.slBexRins.value == 'Outras alterações: '){
        elemento = { id: this.slBexRins.id, value: this.inOutrasBexRins.nativeElement.value };

        const bexigaAlt = { elemento: { id: 5601, value: this.inOutrasBexRins.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(bexigaAlt);
      }else{
        elemento = { id: this.slBexRins.id, value: this.slBexRins.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);

      if(this.slBexRins.value == 'Bexiga não visualizada'){
        this.termoSelecionado.emit(5604);
      } else if(this.slBexRins.value == 'Rins não visualizados'){
        this.termoSelecionado.emit(5605);
      } else if(this.slBexRins.value == 'Ambos não visualizados'){
        this.termoSelecionado.emit(5606);
      }
    }
  }

  emitMaosLesao(){
    let elemento;

    if (this.slMaos && this.slMaos.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slMaos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasMaos && this.slMaos.value == 'Outras alterações: '){
        elemento = { id: this.slMaos.id, value: this.inOutrasMaos.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const maosAlt = { elemento: { id: 5602, value: this.inOutrasMaos.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(maosAlt);
      }
      else {
        elemento = { id: this.slMaos.id, value: this.slMaos.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if(this.slMaos.value == 'Apenas uma mão visualizada'){
        const umaMao = { id: 5607, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(umaMao);
      } else if(this.slMaos.value == 'Não visualizadas'){
        const naoVisualizadas = { id: 5608, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(naoVisualizadas);
      }
    }
  }

  emitMaosSimples(){
    let elemento;

    if (this.slMaos && this.slMaos.value != '**') {
      this.countLesoes++;

      if (this.inOutrasMaos && this.slMaos.value == 'Outras alterações: '){
        elemento = { id: this.slMaos.id, value: this.inOutrasMaos.nativeElement.value };

        const maosAlt = { elemento: { id: 5602, value: this.inOutrasMaos.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(maosAlt);
      }else{
        elemento = { id: this.slMaos.id, value: this.slMaos.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      if(this.slMaos.value == 'Apenas uma mão visualizada'){
        this.termoSelecionado.emit(5607);
      } else if (this.slMaos.value == 'Não visualizadas'){
        this.termoSelecionado.emit(5608);
      }
    }
  }

  emitPesLesao(){
    let elemento;

    if (this.slPes && this.slPes.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slPes.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasPes && this.slPes.value == 'Outras alterações: '){
        elemento = { id: this.slPes.id, value: this.inOutrasPes.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const pesAlt = { elemento: { id: 5603, value: this.inOutrasPes.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(pesAlt);
      }
      else {
        elemento = { id: this.slPes.id, value: this.slPes.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if(this.slPes.value == 'Apenas um pé visualizado'){
        const umPe = { id: 5609, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(umPe);
      } else if (this.slPes.value == 'Não visualizados'){
        const naoVisualizados = { id: 5610, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(naoVisualizados);
      }
    }
  }

  emitPesSimples(){
    let elemento;

    if (this.slPes && this.slPes.value != '**') {
      this.countLesoes++;

      if (this.inOutrasPes && this.slPes.value == 'Outras alterações: '){
        elemento = { id: this.slPes.id, value: this.inOutrasPes.nativeElement.value };

        const pesAlt = { elemento: { id: 5603, value: this.inOutrasPes.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(pesAlt);
      }else{
        elemento = { id: this.slPes.id, value: this.slPes.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      if(this.slPes.value == 'Apenas um pé visualizado'){
        this.termoSelecionado.emit(5609);
      } else if (this.slPes.value == 'Não visualizados'){
        this.termoSelecionado.emit(5610);
      }
    }
  }

  emitSistemaLesao(){
    let elemento;

    if (this.slSistema && this.slSistema.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slSistema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasSistema && this.slSistema.value == 'Outras alterações: '){
        elemento = { id: this.slSistema.id, value: this.inOutrasSistema.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const sistemaAlt = { elemento: { id: 5648, value: this.inOutrasSistema.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(sistemaAlt);
      }
      else {
        elemento = { id: this.slSistema.id, value: this.slSistema.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitSistemaSimples(){
    let elemento;

    if (this.slSistema && this.slSistema.value != '**') {
      this.countLesoes++;

      if (this.inOutrasSistema && this.slSistema.value == 'Outras alterações: '){
        elemento = { id: this.slSistema.id, value: this.inOutrasSistema.nativeElement.value };

        const sistemaAlt = { elemento: { id: 5648, value: this.inOutrasSistema.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(sistemaAlt);
      }else{
        elemento = { id: this.slSistema.id, value: this.slSistema.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitFaceLesao(){
    let elemento;

    if (this.slFace && this.slFace.value != '**') {
      this.countLesoes++;

      const objeto = { id: this.slFace.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inOutrasFace && this.slFace.value == 'Outras alterações: '){
        elemento = { id: this.slFace.id, value: this.inOutrasFace.nativeElement.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        const faceAlt = { elemento: { id: 5650, value: this.inOutrasFace.nativeElement.value }, isPrimeiraLesao: false };
        this.termosGenericos.emit(faceAlt);
      }
      else {
        elemento = { id: this.slFace.id, value: this.slFace.value };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitFaceSimples(){
    let elemento;

    if (this.slFace && this.slFace.value != '**') {
      this.countLesoes++;

      if (this.inOutrasFace && this.slFace.value == 'Outras alterações: '){
        elemento = { id: this.slFace.id, value: this.inOutrasFace.nativeElement.value };

        const faceAlt = { elemento: { id: 5650, value: this.inOutrasFace.nativeElement.value }, isPrimeiraLesao: true };
        this.termosGenericos.emit(faceAlt);
      }else{
        elemento = { id: this.slFace.id, value: this.slFace.value };
      }
      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  verificaAlterado() {
    if (this.slCranio.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoCra = true;
    } else {
      this.showAndHideTerms.sahAlteradoCra = false;
    }

    if (this.slColuna.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoCol = true;
    } else {
      this.showAndHideTerms.sahAlteradoCol = false;
    }

    if (this.slCoracao.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoCor = true;
    } else {
      this.showAndHideTerms.sahAlteradoCor = false;
    }

    if (this.slAbdome.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoAbd = true;
    } else {
      this.showAndHideTerms.sahAlteradoAbd = false;
    }

    if (this.slEstomago.value === 'Alterado: ') {
      this.showAndHideTerms.sahAlteradoEst = true;
    } else {
      this.showAndHideTerms.sahAlteradoEst = false;
    }

    if (this.slBexRins.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoBexRins = true;
    } else {
      this.showAndHideTerms.sahAlteradoBexRins = false;
    }

    if (this.slMaos.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoMaos = true;
    } else {
      this.showAndHideTerms.sahAlteradoMaos = false;
    }

    if (this.slPes.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoPes = true;
    } else {
      this.showAndHideTerms.sahAlteradoPes = false;
    }

    if (this.slSistema.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoSistema = true;
    } else {
      this.showAndHideTerms.sahAlteradoSistema = false;
    }

    if (this.slFace.value === 'Outras alterações: ') {
      this.showAndHideTerms.sahAlteradoFace = true;
    } else {
      this.showAndHideTerms.sahAlteradoFace = false;
    }

  }

}
