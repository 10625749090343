import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pele-tecido',
  templateUrl: './pele-tecido.component.html',
  styleUrls: ['./pele-tecido.component.css']
})
export class PeleTecidoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  @ViewChild('slFacePunho') slFacePunho: MatSelect;

  faces: ArrayDropDown[] = [
    { value: 'palmar', viewValue: 'Palmar' },
    { value: 'dorsal', viewValue: 'Dorsal' }
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahEdema = false;
    this.catchIDs();
  }

  clickEdema() {
    this.showAndHideTerms.sahEdema = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.slFacePunho && this.slFacePunho.value)){
      this.rdEdema.checked = true;
    }
  }
  
  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.value);
      
      if (this.slFacePunho && this.slFacePunho.value) {
        const objeto = { elemento: this.slFacePunho, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
