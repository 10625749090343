import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-gemelar-dialog',
  templateUrl: './gemelar-dialog.component.html',
  styleUrls: ['./gemelar-dialog.component.css']
})
export class GemelarDialogComponent implements OnInit {
  

  constructor(public dialogRef: MatDialogRef<GemelarDialogComponent>) { }

  ngOnInit() {}

  SelectFetos(fetos: number): void {
    this.dialogRef.close(fetos);
  }
}
