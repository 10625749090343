import { Component, OnInit, ViewChild, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';


@Component({
  selector: 'app-tendoes-flexores',
  templateUrl: './tendoes-flexores.component.html',
  styleUrls: ['./tendoes-flexores.component.css']
})
export class TendoesFlexoresComponent implements OnInit {

  public showAndHideTerms: any;
  public addForm: FormGroup;
  public linha: number;
  public objeto: any;
  public objetofilho: any;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTenossinovite') rdTenossinovite: MatRadioButton;
  @ViewChild('rdTendinopatiaeTenossinovite') rdTendinopatiaeTenossinovite: MatRadioButton;
  @ViewChild('sltendinopatia') sltendinopatia: MatSelect;
  @ViewChild('sltenossinovite') sltenossinovite: MatSelect;
  @ViewChild('slTendinopatiaeTenossinovite') slTendinopatiaeTenossinovite: MatSelect;
  @ViewChildren('TendinopatiaChildren') TendinopatiaChildren: QueryList<any>;



  public tendinopatia: ArrayDropDown[] = [
    { value: '1° dedo', viewValue: '1° Dedo' },
    { value: '2° dedo', viewValue: '2° Dedo' },
    { value: '3° dedo', viewValue: '3° Dedo' },
    { value: '4° dedo', viewValue: '4° Dedo' },
    { value: '5° dedo', viewValue: '5° Dedo' },
  ];

  public tenossinovite: ArrayDropDown[] = [
    { value: '1° dedo', viewValue: '1° dedo' },
    { value: '2° dedo', viewValue: '2° dedo' },
    { value: '3° dedo', viewValue: '3° dedo' },
    { value: '4° dedo', viewValue: '4° dedo' },
    { value: '5° dedo', viewValue: '5° dedo' },
  ];


  public TendinopatiaeTenossinovite: ArrayDropDown[] = [
    { value: '1° dedo', viewValue: '1° dedo' },
    { value: '2° dedo', viewValue: '2° dedo' },
    { value: '3° dedo', viewValue: '3° dedo' },
    { value: '4° dedo', viewValue: '4° dedo' },
    { value: '5° dedo', viewValue: '5° dedo' },
  ];

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormal: false,
      sahTendinopatia: false,
      sahTenossinovite: false,
      sahTendinopatiaeTenossinovite: false,
    };
    this.addForm = this._fb.group({
      Presente: this._fb.array([this.addDedosGrup()]),
    });
  }
  public addDedosGrup(): any {
    return this._fb.group({
      dedo: [],
    });
  }

  public addPresente(): any {
    this.PresenteArray.push(this.addDedosGrup());
    this.catchIDs();
  }

  public removePresente(index): any {
    this.PresenteArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }


  get PresenteArray() {
    return <FormArray>this.addForm.get('Presente');
  }

  public ClickNormal(): any {
    this.showAndHideTerms.sahTendinopatia = false;
    this.showAndHideTerms.sahTenossinovite = false;
    this.showAndHideTerms.sahTendinopatiaeTenossinovite = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public ClickTendinopatia(): any {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahTendinopatia = true;
    this.showAndHideTerms.sahTenossinovite = false;
    this.showAndHideTerms.sahTendinopatiaeTenossinovite = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public ClickTenossinovite(): any {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahTendinopatia = false;
    this.showAndHideTerms.sahTenossinovite = true;
    this.showAndHideTerms.sahTendinopatiaeTenossinovite = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }


  public ClickTendinopatiaeTenossinovite(): any {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahTendinopatia = false;
    this.showAndHideTerms.sahTenossinovite = false;
    this.showAndHideTerms.sahTendinopatiaeTenossinovite = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }

  public verificaTendinopatiaeTenossinovite(): any {
    this.linha = 0;
    this.TendinopatiaChildren.forEach(obj => {

      if (+obj.id === 1745) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');


    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }
    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      this.verificaTendinopatiaeTenossinovite();
    }
    if (this.rdTenossinovite.checked) {
      this.termoSelecionado.emit(this.rdTenossinovite.value);
      this.verificaTendinopatiaeTenossinovite();
    }
    if (this.rdTendinopatiaeTenossinovite.checked) {
      this.termoSelecionado.emit(this.rdTendinopatiaeTenossinovite.value);
      this.verificaTendinopatiaeTenossinovite();
    }

    this.listaCompleta.emit();
  }

  ajustaArrayDinamico() {
    if (this.rdTendinopatia && !this.rdTendinopatia.checked ||
      this.rdTenossinovite && !this.rdTenossinovite.checked ||
      this.rdTendinopatiaeTenossinovite && !this.rdTendinopatiaeTenossinovite.checked) {

      const arrayTotal = this.PresenteArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.PresenteArray.length - 1;

        if (index !== 0) {
          this.PresenteArray.removeAt(index);
        }
      }
      this.PresenteArray.reset();
    }
  }

}
