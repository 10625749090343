import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ImprimirComponent } from './imprimir.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule, MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    AngularEditorModule,
    FormsModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [ImprimirComponent],
  exports: [ImprimirComponent]
})
export class ImprimirModule { }
