import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-manejo-rciu-dialog',
  templateUrl: './manejo-rciu-dialog.component.html',
  styleUrls: ['./manejo-rciu-dialog.component.css']
})
export class ManejoRcuiDialogComponent {
  displayedColumns =
    ['estado', 'correlacao', 'criterios', 'monitorizacao', 'parto'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialogRef: MatDialogRef<ManejoRcuiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }
}

export interface ParamRefElement {
  estado: string;
  correlacao: any;
  criterios: any;
  monitorizacao: any;
  parto: any;
}

const ELEMENT_DATA: ParamRefElement[] = [
  {estado: 'I', correlacao: 'Insuficiência placentária', criterios: 'AU >p95 | ACM <p5 | RCP <p5 | PFE <p3', monitorizacao: 'Semanal', parto: '40 semanas'},
  {estado:'II', correlacao:'Insuficiência placentária severa', criterios:'AU Diastole zero', monitorizacao:'Cada 2 - 3 dias', parto:'37 semanas'},
  {estado:'III', correlacao:'Suspeita de acidose', criterios:'AU Diastole reversa | DV >p95', monitorizacao:'Diário', parto:'30 semanas'},
  {estado:'IV', correlacao:'Alta suspeita de acidose', criterios:'DV onda a reversa | CTB categoria 3', monitorizacao:'Cada 12h', parto:'>26 semanas'}];
