import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-ateromatose',
  templateUrl: './ateromatose.component.html',
  styleUrls: ['./ateromatose.component.css']
})
export class AteromatoseComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdPlacas') rdPlacas: MatRadioButton;
  @ViewChild('ckAteromatoseincipiente') ckAteromatoseincipiente: MatRadioButton;
  @ViewChild('ckAteromatoseesparsa') ckAteromatoseesparsa: MatRadioButton;
  @ViewChild('ckMultiplasplacasateromatosassemestenose') ckMultiplasplacasateromatosassemestenose: MatRadioButton;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTerms: false,
    };
  }

  public clickPlacas(): any {
    if (this.rdPlacas.checked) {
      this.showAndHideTerms.sahTerms = true;
    } else {
      this.showAndHideTerms.sahTerms = false;
    }

    this.catchIDs();
  }

  public clickAteromatoseincipiente(): any {
    if (this.ckAteromatoseincipiente.checked) {
      this.ckAteromatoseesparsa.checked = false;
      this.ckMultiplasplacasateromatosassemestenose.checked = false;
    }

    this.catchIDs();
  }

  public clickAteromatoseesparsa(): any {
    if (this.ckAteromatoseesparsa.checked) {
      this.ckAteromatoseincipiente.checked = false;
      this.ckMultiplasplacasateromatosassemestenose.checked = false;
    }

    this.catchIDs();
  }

  public clickMultiplasplacasateromatosassemestenose(): any {
    if (this.ckMultiplasplacasateromatosassemestenose.checked) {
      this.ckAteromatoseincipiente.checked = false;
      this.ckAteromatoseesparsa.checked = false;

    }

    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');
 
    // Ateromatose incipiente
    if (this.ckAteromatoseincipiente && this.ckAteromatoseincipiente.checked) {
      this.termoSelecionado.emit(this.rdPlacas.id);
      this.termoSelecionado.emit(this.ckAteromatoseincipiente.id);
    }

    // Ateromatose esparsa
    if (this.ckAteromatoseesparsa && this.ckAteromatoseesparsa.checked) {
      this.termoSelecionado.emit(this.rdPlacas.id);
      this.termoSelecionado.emit(this.ckAteromatoseesparsa.id);
    }

    // Múltiplas placas ateromatosas, sem estenose
    if (this.ckMultiplasplacasateromatosassemestenose && this.ckMultiplasplacasateromatosassemestenose.checked) {
      this.termoSelecionado.emit(this.rdPlacas.id);
      this.termoSelecionado.emit(this.ckMultiplasplacasateromatosassemestenose.id);
    }

    this.listaCompleta.emit();
  }
}

