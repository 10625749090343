import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FirebaseService } from '../services/firebase.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.css', '../app.component.css']
})

export class SuporteComponent implements OnInit {
  formContato: FormGroup;
  private formSubmitAttempt: boolean;
  public loading: boolean = true;

  constructor(private fb: FormBuilder,
    private toastr: ToastrManager,
    private _userService: UserService,
    private _firebase: FirebaseService
  ) { }

  ngOnInit() {
    const id = +localStorage.getItem('userID');

    this.formContato = this.fb.group({
      userId: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      description: ['', Validators.required],
    });
    this.formContato.patchValue({
      userId: id
    });
    
    this.loading = false;
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formContato.get(field).valid && this.formContato.get(field).touched) ||
      (this.formContato.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    this._userService.enviaContato(this.formContato.value)
      .subscribe(
        res => {
          this.formContato.enable();
          this.toastr.successToastr('Contato enviado com sucesso', 'Sucesso!',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        },
        err => {
          console.log(err);
          this.formContato.enable();
          this.toastr.errorToastr('Erro ao enviar contato', 'Desculpe!',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        },
      );

    this._firebase.logSendEmail(this.formContato.value.subject);
    this.formSubmitAttempt = true;
  }

  logEventWhatsapp() {
    this._firebase.logSendWhatsapp("contatoPage");
  }
  
}
