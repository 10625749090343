import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Grau} from '../ascite/ascite.component';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface InterposicaoAcentuada {
  value: string;
  selected: boolean;
  viewValue: string;
}

@Component({
  selector: 'app-veia-porta',
  templateUrl: './veia-porta.component.html',
  styleUrls: ['./veia-porta.component.css']
})
export class VeiaPortaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rbNormal') rbNormal: MatRadioButton;
  @ViewChild('rbAumentada') rbAumentada: MatRadioButton;
  @ViewChild('rbTrombose') rbTrombose: MatRadioButton;
  @ViewChild('rbNaoCaracterizada') rbNaoCaracterizada: MatRadioButton;

  @ViewChild('slInterposicaoAcentuada') slInterposicaoAcentuada: MatSelect;
  @ViewChild('inMedindo') inMedindo: ElementRef;

  interposicoesAcentuada: InterposicaoAcentuada[] = [
    {value: '223', selected: false, viewValue: 'Interposição gasosa'},
    {value: '224', selected: false, viewValue: 'Esteatose'}
  ];

  showAndHideTerms: any;
  @Input() medindo: string;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {

    this.showAndHideTerms = {
      sahMedindo: false,
      sahCaracterizada: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal(): void {
    this.showAndHideTerms = {
      sahMedindo: false,
      sahCaracterizada: false
    };

    this.catchIDs();
  }

  clickAumentada(): void {
    this.showAndHideTerms = {
      sahMedindo: true,
      sahCaracterizada: false
    };

    this.catchIDs();
  }

  clickTrombose(): void {
    this.showAndHideTerms = {
      sahMedindo: true,
      sahCaracterizada: false
    };

    this.catchIDs();
  }

  clickNaoCaracterizada(): void {
    this.showAndHideTerms = {
      sahMedindo: false,
      sahCaracterizada: true
    };

    this.catchIDs();
  }

  changeInterposicao($event) {
    this.catchIDs();
  }

  blurMedindo($event) {
    this.catchIDs();
  }


  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rbNormal.checked) {
      this.termoSelecionado.emit(this.rbNormal.id);
    }

    if (this.rbAumentada.checked) {
      this.termoSelecionado.emit(this.rbAumentada.id);

      if (this.inMedindo != undefined && this.inMedindo.nativeElement.id != undefined) {
        const objeto = {elemento: this.inMedindo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rbTrombose.checked) {
      this.termoSelecionado.emit(this.rbTrombose.id);

      if (this.inMedindo != undefined && this.inMedindo.nativeElement.id != undefined) {
        const objeto = {elemento: this.inMedindo.nativeElement, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rbNaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rbNaoCaracterizada.id);
      
      if (this.slInterposicaoAcentuada != undefined && this.slInterposicaoAcentuada.value != undefined) {
        this.termoSelecionado.emit(this.slInterposicaoAcentuada.value);
      }
    }


    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }
}
