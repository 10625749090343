import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule, 
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { EndometrioDopplerComponent } from './TermosComponents/endometrio-doppler/endometrio-doppler.component';
import { OvarioDireitoDopplerComponent } from './TermosComponents/ovario-direito-doppler/ovario-direito-doppler.component';
import { OvarioEsquerdoDopplerComponent } from './TermosComponents/ovario-esquerdo-doppler/ovario-esquerdo-doppler.component';
import { RegiaoAnexalDopplerComponent } from './TermosComponents/regiao-anexal-doppler/regiao-anexal-doppler.component';
import { UteroDopplerComponent } from './TermosComponents/utero-doppler/utero-doppler.component';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { LiquidoLivreComponent } from '../../modo-b/transvaginal/TermosComponents/liquido-livre/liquido-livre.component';
import { TransvDopplerComponent } from './transv-doppler.component';
import { TransvaginalModule } from '../../modo-b/transvaginal/transvaginal.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';


@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    TransvaginalModule,
    SharedModule, 
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  declarations: [
    TransvDopplerComponent,
    EndometrioDopplerComponent,
    OvarioDireitoDopplerComponent,
    OvarioEsquerdoDopplerComponent,
    RegiaoAnexalDopplerComponent,
    UteroDopplerComponent
  ]
})
export class TransvDopplerModule { }
