import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatSelect} from '@angular/material';

export interface Grau {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ascite',
  templateUrl: './ascite.component.html',
  styleUrls: ['./ascite.component.css']
})
export class AsciteComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckSeptada') ckSeptada: MatCheckbox;
  @ViewChild('ckDebris') ckDebris: MatCheckbox;
  @ViewChild('slGrau') slGrau: MatSelect;

  graus: Grau[] = [
    {value: 'Pequena', viewValue: 'Pequena'},
    {value: 'Moderada', viewValue: 'Moderada'},
    {value: 'Acentuada', viewValue: 'Acentuada'},
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresenteOpt: true
    };
  }

  clickAusente($event) {
    this.showAndHideTerms.sahPresenteOpt = false;

    this.catchIDs();
  }

  clickPresente($event) {
    this.showAndHideTerms.sahPresenteOpt = true;

    this.catchIDs();
  }

  clickSeptada() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  clickDebris() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  changeGrau($event) {
    this.rdPresente.checked = true;
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.ckDebris !== undefined && this.ckDebris.checked) {
        this.termoSelecionado.emit(this.ckDebris.id);
      }

      if (this.ckSeptada != undefined && this.ckSeptada.checked) {
        this.termoSelecionado.emit(this.ckSeptada.id);
      }

      if (this.slGrau != undefined && this.slGrau.value != undefined) {
        const objeto = {elemento: this.slGrau, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

      this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.ckDebris !== undefined && this.ckDebris.checked) ||
        (this.ckSeptada != undefined && this.ckSeptada.checked)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = false;
    }

  }
}
