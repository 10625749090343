import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DescricaoConclusao } from 'src/app/models/Helper/DescricaoConclusao';
import { CommonService } from 'src/app/services/common.service';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { Injuries, Term } from 'src/app/models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { DataTermService } from 'src/app/services/dataTerm.service';


@Component({
  selector: 'app-rx-cranio',
  templateUrl: './rx-cranio.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./rx-cranio.component.css']
})
export class RxCranioComponent implements OnInit {

  public showForm: Boolean = true;
  public unidadeMedida: string;
  public switchFormReport = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();
  cenarioCorrente = 0;
  objetoGrupoLesao: any;

  Subtitulo: string;
  subtituloBloco: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  indicacaoTexto = '';
  achadosAdTexto = '';
  cabecalhoTexto = '';
  rodapeTexto = '';


  medicalReportTypeID = 75;

  tecnicaId = 337;
  aspectoId = 338;
  medidaId = 339;
  selaTurcaId = 342;
  partesMolesId = 340;
  estruturasOsseasId = 341;

  cabecalho = false;
  indicacao = false;
  tecnica = true;
  aspecto = false;
  medida = false;
  selaTurca = false;
  partesMoles = false;
  estruturasOsseas = false;
  editLaudoMode: boolean = false;


  achados = false;
  rodape = false;

  listTermDefault=[
    {listTerm:[4933],blockID:337},
    {listTerm:[4936],blockID:338},
    {listTerm:[4973],blockID:339},
    {listTerm:[4983],blockID:342},
    {listTerm:[4942],blockID:340},
    {listTerm:[0],blockID:341}
  ]

  genericTermsTecnica: TermosGenericos[] = [
    { value: '', selected: false, termID: 4935, term: '[incidencia]' }
  ];

  genericTermsMedida: TermosGenericos[] = [
    { value: '', selected: false, termID: 4976, term: '[indice craniano]' },
    { value: '', selected: false, termID: 4977, term: '[tamanho craniano]' }
  ];

  genericTermsSelaTurca: TermosGenericos[] = [
    { value: '', selected: false, termID: 4988, term: '[erosão]' },
  ];

  genericTermsEstruturasOsseas: TermosGenericos[] = [
    { value: '', selected: false, termID: 5006, term: '[FraturaOssosDireita]' },
    { value: '', selected: false, termID: 5008, term: '[FraturaOssosEsquerda]' },
    { value: '', selected: false, termID: 5011, term: '[LesoesOssosDireita]' },
    { value: '', selected: false, termID: 5013, term: '[LesoesOssosEsquerda]' },
    { value: '', selected: false, termID: 5017, term: '[LesoesLiticasOssosDireita]' },
    { value: '', selected: false, termID: 5019, term: '[LesoesLiticasOssosEsquerda]' }
  ];

  descricoes: DescricaoConclusao[] = [
    {
      value: '',
      blockID: 337, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 338, textTermID: 0, visivel: true
    },
    {
      value: '',
      blockID: 339, textTermID: 0, visivel: true
    },
    {
      value: 'Partes moles sem alterações radiológicas significativas. <br> Ausência de calcificações intracranianas.',
      blockID: 340, textTermID: 0, visivel: true
    },
    {
      value: 'Estruturas ósseas de aspecto preservado.',
      blockID: 341, textTermID: 0, visivel: true
    },
    {
      value: 'Sela turca com aspecto habitual.',
      blockID: 342, textTermID: 0, visivel: true
    }
  ];

  conclusoes: DescricaoConclusao[] = [
    { value: '', blockID: 337, textTermID: 0, visivel: true },
    { value: '', blockID: 338, textTermID: 0, visivel: true },
    { value: '', blockID: 339, textTermID: 0, visivel: true },
    { value: '', blockID: 340, textTermID: 0, visivel: true },
    { value: '', blockID: 341, textTermID: 0, visivel: true },
    { value: '', blockID: 342, textTermID: 0, visivel: true }
  ]
  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public _dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    public _addText: AddText,
    private dataService:DataTermService
  ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._pouchService.loadDatabase("rxCranioAnalise", this.descricoes, this.conclusoes);
    this.subtituloBloco = this.Subtitulo;
    this.Subtitulo = 'Técnica';
    this.term.listTermID = new Array<number>();
    this.resetaDialog();

    const listBlocks = [
      this.tecnicaId,
      this.aspectoId,
      this.medidaId,
      this.selaTurcaId,
      this.aspectoId,
      this.partesMolesId,
      this.estruturasOsseasId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "rxCranioMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "torax");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-cranio');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'rx-cranio');
      }
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }



  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo: 'preencha o campo indicação',
      selected: false
    },
    {
      titulo: 'Técnica',
      id: this.tecnicaId,
      name: 'tecnica',
      selected: true
    },
    {
      titulo: 'Aspecto',
      id: this.aspectoId,
      name: 'aspecto',
      selected: false
    },
    {
      titulo: 'Medida',
      id: this.medidaId,
      name: 'medida',
      selected: false
    },
    {
      titulo: 'Sela Túrca',
      id: this.selaTurcaId,
      name: 'sela turca',
    },
    {
      titulo: 'Partes Moles',
      id: this.partesMolesId,
      name: 'partesMoles',
      selected: false
    },
    {
      titulo: 'Estruturas Osseas',
      id: this.estruturasOsseasId,
      name: 'estruturasOsseas',
      selected: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
      let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
    }


    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.tecnica = (itemSelecionado.id === this.tecnicaId);
    this.aspecto = (itemSelecionado.id === this.aspectoId);
    this.medida = (itemSelecionado.id === this.medidaId);
    this.selaTurca = (itemSelecionado.id === this.selaTurcaId);
    this.partesMoles = (itemSelecionado.id === this.partesMolesId);
    this.estruturasOsseas = (itemSelecionado.id === this.estruturasOsseasId);
    this.achados = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();
    this.cenarioCorrente = 0;

    this.genericTermsTecnica = [
      { value: '', selected: false, termID: 4935, term: '[incidencia]' }
    ];

    this.genericTermsMedida = [
      { value: '', selected: false, termID: 4976, term: '[indice craniano]' },
      { value: '', selected: false, termID: 4977, term: '[tamanho craniano]' }
    ];

    this.genericTermsSelaTurca = [
      { value: '', selected: false, termID: 4988, term: '[erosão]' },
    ];

    this.genericTermsEstruturasOsseas = [
      { value: '', selected: false, termID: 5006, term: '[FraturaOssosDireita]' },
      { value: '', selected: false, termID: 5008, term: '[FraturaOssosEsquerda]' },
      { value: '', selected: false, termID: 5011, term: '[LesoesOssosDireita]' },
      { value: '', selected: false, termID: 5013, term: '[LesoesOssosEsquerda]' },
      { value: '', selected: false, termID: 5017, term: '[LesoesLiticasOssosDireita]' },
      { value: '', selected: false, termID: 5019, term: '[LesoesLiticasOssosEsquerda]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  adicionaSelecaoTecnica($event) {
    this.genericTermsTecnica.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });
    this.term.listTermID.push($event.elemento.id);

  }

  adicionaSelecaoMedida($event) {
    this.genericTermsMedida.forEach(obj => {
      if (obj.termID == $event.id) {
        obj.selected = true;
        obj.value = $event.value;
      }
    });
    this.term.listTermID.push($event.id);
  }

  adicionaSelecaoSelaTurca($event) {
    this.genericTermsSelaTurca.forEach(obj => {

      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        if ($event.elemento.cenario) {
          this.objetoGrupoLesao = { id: $event.elemento.id, cenario: $event.elemento.cenario };
        } else {
          this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
        }
        this.enviaLesoes(this.objetoGrupoLesao);
      }
    });
  }

  adicionaSelecaoEstruturas($event) {
    this.genericTermsEstruturasOsseas.forEach(obj => {
      if (obj.termID === +$event.elemento.id) {
        if (Array.isArray($event.elemento.value)) {
          const arrLength = $event.elemento.value.length;
          $event.elemento.value.forEach(function (objArr, index) {
            if (obj.value) {
              if (arrLength == index + 1)
                obj.value = obj.value + ' e ' + objArr;
              else
                obj.value = obj.value + ', ' + objArr;
            } else {
              obj.value = objArr;
            }
          });
        } else {
          obj.selected = true;
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
        }
      }
    });

    this.term.listTermID.push($event.elemento.id);
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente === 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario === 0 || $event.cenario === this.cenarioCorrente) {
      // Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      // Adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  public consultarAPI(): any {
    let desc;
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      if (this.tecnica) {
        this.term.blockID = this.tecnicaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.tecnicaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoConclusaoTecnica(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.tecnicaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: '',
              conclusaoNull: ''
            });
          }
        });

      } else if (this.aspecto) {
        this.term.blockID = this.aspectoId;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.aspectoId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.aspectoId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: '',
              conclusaoNull: ''
            });
          }
        });

      } else if (this.medida) {
        debugger
        this.term.blockID = this.medidaId;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          console.log(termResults);
          this.descricoes.forEach(obj => {
            console.log(obj.blockID);
            if (obj.blockID === this.medidaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoMedida(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });
              let index = this.descricoes.findIndex(x => x.blockID === this.medidaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: '',
              conclusaoNull: ''
            });
          }
        });
      } else if (this.partesMoles) {
        this.term.blockID = this.partesMolesId;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.partesMolesId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
                contadorTexto++;
              });


              let index = this.descricoes.findIndex(x => x.blockID === this.partesMolesId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: '',
              conclusaoNull: ''
            });
          }
        });

      } else if (this.estruturasOsseas) {
        this.term.blockID = this.estruturasOsseasId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.estruturasOsseasId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.estruturasOsseasId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          let obj = {
            value: '',
            blockID: this.estruturasOsseasId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaDescricaoEstruturasOsseas(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.estruturasOsseasId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.selaTurca) {
        this.term.blockID = this.selaTurcaId;
        this._pouchService.getLaudos(this.term).then((termResults) => {
          console.log(termResults);
          this.descricoes.forEach(obj => {
            console.log(obj.blockID);
            if (obj.blockID === this.selaTurcaId && termResults[0] !== undefined) {

              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoSelaTurca(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.selaTurcaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });

          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.estruturasOsseasId);

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  MontaDescricaoConclusaoTecnica(texto): any {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4935) {
        this.genericTermsTecnica.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoMedida(texto): any {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4976 || +term === 4977) {
        this.genericTermsMedida.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoSelaTurca(texto): any {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4988) {
        this.genericTermsSelaTurca.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
  }

  MontaDescricaoEstruturasOsseas(texto, contadorTexto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5006 || +term === 5008 || +term === 5011 || +term === 5013 || +term === 5017 || +term === 5019) {
        this.genericTermsEstruturasOsseas.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    // Genérico
    if (this.term.injuries[0].listInjuries != undefined
      && this.term.injuries[0].listInjuries.length != 0 && contadorTexto > 0) {
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5006 || +term === 5008 || +term === 5011 || +term === 5013 || +term === 5017 || +term === 5019) {
          this.genericTermsEstruturasOsseas.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
          }
        });
      }
    });
  };

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = '<p>' + Texto + '</p>';
    return texto;
  }

  public resetaLaudo() {
    this._pouchService.getAll("rxSeiosDaFaceAnalise").then((obj) => {
      console.log(obj)
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });



    this.showForm = false;
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };


  AdicionaTexto(isNewEditor: boolean) {
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    //Lógica Rules
    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.htmlContent += '<br/><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';
    // }

    for (i = 0; i < this.descricoes.length; i++) {
      let newValue = this.descricoes[i].value.replace(/mm/g, this.unidadeMedida);
      let newValue2 = newValue.replace(/cm/g, this.unidadeMedida);
      this.descricoes[i].value = newValue2;
    }

    this.htmlContent += this.indicacaoTexto + ' </br>';
    this.htmlContent += maskSplit[1];
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }

    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].value) {
        this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
      }
    }

    this.htmlContent += this.achadosAdTexto;

    if (this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
    calculadora,
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.descricoes.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if (cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }

        if (calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if (calculo) {
            calculo.innerHTML = calculadora;
          }
        }

      } else if (indicacao !== undefined || achados !== undefined) {
        if (indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }
}
