import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { PesquisaComponent } from '../pesquisa/pesquisa.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PagamentoService } from '../services/pagamento.service';
import { StatusPagamentoService } from '../services/status-pagamento.service';
import { first, take } from 'rxjs/operators';
import { ConfigLaudoService } from '../services/config-laudo.service';
import { PouchDBService } from '../services/pouchdb.service';
import { AlertDialogComponent } from '../utils/dialog/alert-dialog/alert-dialog.component';
import { UserService } from '../services/user.service';
import { ListaExamesService } from '../services/lista-exames.service';
import { CircleProgressOptions } from 'ng-circle-progress';
import { HeaderComponent } from '../header/header.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NPSService } from '../services/nps.service';
import { from, Subscription } from 'rxjs';
import { User } from '../auth/user';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-laudos',
  templateUrl: './laudos.component.html',
  styleUrls: ['./laudos.component.css']
})

export class LaudosComponent implements OnInit, OnDestroy {
  userID: number;
  nomeUsuario: string;
  showAndHide: any;
  BindsURL: SafeResourceUrl;
  status: number;
  tipoExame: number;
  laudos: any;
  listBlocks: number[];
  blocosPorLaudo: any;
  blocos: any;
  loadingProgress: number = 0;
  options = new CircleProgressOptions();
  showloading: boolean = false;
  respondeu: boolean = false;

  private userDataSubscription: Subscription;
  private userSubscription: Subscription;
  private configLaudoSubscription: Subscription;
  private npsSubscription: Subscription;
  private npsListaSubscription: Subscription;
  private statusPagamentoSubscription: Subscription;
  private userData: User;
  laudosList: any[] = [];

  constructor(
    private router: Router,
    private _userService: UserService,
    private _configLaudoService: ConfigLaudoService,
    private _dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private _pgto: PagamentoService,
    private _statusPgto: StatusPagamentoService,
    private _pouchdb: PouchDBService,
    private _header: HeaderComponent,
    private _listaExames: ListaExamesService,
    private toastr: ToastrManager,
    private _nps: NPSService,
    private _firebase: FirebaseService
  ) { }

  async ngOnInit() {
    this.options.subtitle = [
      'Carregando',
      'laudos'
    ];

    this.showAndHide = {
      sahSelecionado: false,
      sahTomografia: false,
      sahUltrassom: false,
      sahMamografia: false,
      sahRadiografia: false,
    };

    //Remove o item clicado
    localStorage.removeItem('updateUserClick');

    const errorMessage = JSON.parse(localStorage.getItem('errorMessage'));
    if (errorMessage) {
      this.toastr.errorToastr(errorMessage.message, 'Desculpe',
        {
          position: 'top-right',
          toastTimeout: 4000
        }
      );
      localStorage.removeItem('errorMessage');
    }
    this.userData = await from(this._userService.getUserData()).pipe(first()).toPromise();
    this.userID = this.userData.id;
    this._header.carregarFavoritos();

    if (this.userID) {

      const updatedUser = localStorage.getItem('updatedUser');
      // if (updatedUser == null || updatedUser == 'false') {
      //   localStorage.setItem('updatedUser', this.userData.updatedUser.toString());
      //   if (this.userData.updatedUser == false) {
      //     const dialogRef = this._dialog.open(AlertDialogComponent, {
      //       width: '450px',
      //       data: 'info|Olá ' + this.userData.name + ', matenha seus dados cadastrais atualizados. Clique no botão continuar para atualiza-los!'
      //     });
      //     const result = dialogRef.afterClosed().toPromise();
      //     if (result) {
      //       localStorage.setItem('updateUserClick', 'true');
      //       this.router.navigate(['/perfil']);
      //     }
      //   }
      // }

      // this.verificarStatusPagamento();

      const tipoImg = localStorage.getItem('TipoImagem');
      if (tipoImg) {
        switch (tipoImg) {
          case 'RX':
            this.goTo(4);
            break;
          case 'MG':
            this.goTo(3);
            break;
          case 'TC':
            this.goTo(2);
            break;
          case 'US':
            this.goTo(1);
            break;
          default:
            break;
        }
      }
      setTimeout(() => this.verificaSeUsuarioRespondeu());

      this.buscaConfig()
      this._firebase.setUserProperties();
    }
  }

  ngAfterViewInit() {
    this._firebase.verificaAcessoDiario();
  }

  buscaConfig() {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._configLaudoService.buscaConfig(this.userID.toString()).subscribe((config) => {
        if (config && config.length > 0) {
          localStorage.setItem('tecnicaRule', JSON.stringify(config[0]));
          localStorage.setItem('cmRule', JSON.stringify(config[1]));
          localStorage.setItem('copyRule', JSON.stringify(config[2]));
          localStorage.setItem('loadLaudosRule', JSON.stringify(config[3]));
          localStorage.setItem('configClinicaRule', JSON.stringify(config[5]));
          localStorage.setItem('ruleUpdated', 'true');
        }
      });
    }

    // if (loadLaudosRule.active && localStorage.getItem('laudosLoaded') == null || localStorage.getItem('laudosLoaded') == 'false') {
    //   this.showloading = true;
    //   this.carregaBlocosdelaudo().then((res) => {
    //     this.blocos = res;
    //     this.carregaLaudoTemp();
    //   });
    // };

    let reloadLaudos = JSON.parse(localStorage.getItem('reloadlaudos'));

    if (reloadLaudos == true) {
      this.showloading = true;
      this.carregaBlocosdelaudo().then((res) => {
        this.blocos = res;
        this.carregaLaudoTemp();
      });
      localStorage.setItem('reloadlaudos', 'false');
    };
  }

  goTo(where: number) {
    switch (where) {
      case 1:
        localStorage.setItem('TipoImagem', 'US');
        this.showAndHide.sahUltrassom = true;
        this._firebase.logExamType("US");
        break;
      case 2:
        localStorage.setItem('TipoImagem', 'TC');
        this.showAndHide.sahTomografia = true;
        this._firebase.logExamType("TC");
        break;
      case 3:
        localStorage.setItem('TipoImagem', 'MG');
        this.showAndHide.sahMamografia = true;
        this._firebase.logExamType("MG");
        break;
      case 4:
        localStorage.setItem('TipoImagem', 'RX');
        this.showAndHide.sahRadiografia = true;
        this._firebase.logExamType("RX");
        break;
      default:
        break;
    }

    this.showAndHide.sahSelecionado = true;
    this.showAndHide.sahUltrassom = where === 1;
    this.showAndHide.sahTomografia = where === 2;
    this.showAndHide.sahMamografia = where === 3;
    this.showAndHide.sahRadiografia = where === 4;
    this.tipoExame = where;
  }




  abrirAvaliacao() {
    this.npsSubscription = this._nps.getNps(this.userID).subscribe((nps) => {
      const modal = this._dialog.open(PesquisaComponent, {
        data: {
          url: nps.link +
            '?fields=metadata.usuarioid,false,' + this.userID,
          userID: this.userID,
        },
        width: '80%',
        minHeight: '90%'
      });
    });
  }

  verificaSeUsuarioRespondeu() {
    this.npsListaSubscription = this._nps.getListaNps().subscribe(res => {
      const userResponses = res.filter(e => e.metadata.usuarioid === this.userID.toString());
      if (userResponses.length === 0) {
        this.abrirAvaliacao();
      }
    });
  }

  verificarStatusPagamento() {
    this.statusPagamentoSubscription = this._statusPgto.getStatusPagamento()
      .pipe(
        take(1)
      )
      .subscribe((novoStatus) => this.status = novoStatus);

    if (this.status === 3) {
      setTimeout(() => this._pgto.alterarStatus(), 5000); //para efeito de Testes
      // } else if (this.status === 1 || this.status === 2) {
      //   this._statusPgto.resolverStatusPgto();
    }
  }

  async carregaBlocosdelaudo() {
    const laudos = await this._listaExames.getLaudos().toPromise();
    this.laudosList = laudos;

    const resultado = laudos
      .map(laudo => this._listaExames.getBlocoPorLaudo(laudo.id).toPromise())
      .reduce(async (prevPromise, nextPromise) => {
        const prevResult = await prevPromise;
        const nextResult = await nextPromise;
        return [...prevResult, ...nextResult];
      }, Promise.resolve([]));

    return resultado;
  }

  carregaLaudoTemp() {
    this.listBlocks = [];
    this.laudosList.forEach(laudo => {
      if (laudo.parentMedicalReportTypeID == 0) {
        this.blocos.forEach(bloco => {
          if (bloco.medicalReportTypeID == laudo.id) {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 1 && laudo.url == "abdomeDoppler") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 6 && laudo.url == "testiculoDoppler") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 38 && laudo.url == "varizes") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 36 && laudo.url == "obsTransSegTercDoppler") {
            this.listBlocks.push(
              bloco.id, 316 //Artérias uterinas - Morfo doppler 1
            );
          }
          if (bloco.medicalReportTypeID == 36 && laudo.url == "morfoPrim") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 53 && laudo.url == "morfoPrimDoppler") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 14 && laudo.url == "ombroBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 13 && laudo.url == "punhoBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 21 && laudo.url == "peBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 15 && laudo.url == "joelhoBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 22 && laudo.url == "maoBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 19 && laudo.url == "tornozeloBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 18 && laudo.url == "musculosTendoesBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 16 && laudo.url == "cotoveloBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 17 && laudo.url == "quadrilBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 48 && laudo.url == "quadrilInfantilBilateral") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 2 && laudo.url == "mamaDoppler") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 1 && laudo.url == "abdomeTotalPelvico") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if ((bloco.medicalReportTypeID == 1 || bloco.medicalReportTypeID == 5) && laudo.url == "abdomeTotalProstata") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 5 && laudo.url == "rinsProstata") {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 73 && laudo.url == "morfoSegDoppler") {
            this.listBlocks.push(
              309, 310, 311, 312, 324, 325, 326, 327, 328, 329, 330, 313, 306, 315, 316
            );
          }
        });

      } else {
        this.blocos.forEach(bloco => {
          if (bloco.medicalReportTypeID == laudo.parentMedicalReportTypeID) {
            this.listBlocks.push(
              bloco.id
            );
          }
          if (bloco.medicalReportTypeID == 1 && laudo.url == "abdomeSuperiorDoppler") {
            this.listBlocks.push(
              bloco.id
            );
          }
        });
      }
      if (laudo.url == "cervical-doppler") {
        this.listBlocks.push(
          27, 53
        );
      }
      if (laudo.url == "tireoideDoppler") {
        this.listBlocks.push(
          27
        );
      }
      if (laudo.url == "transv-doppler") {
        this.listBlocks.push(
          21
        );
      }
      if (laudo.url == "partesMoles") {
        this.listBlocks.push(
          48, 49
        );
      }
      if (laudo.url == "pelvico") {
        this.listBlocks.push(
          9
        );
      }
      if (laudo.url == "penis") {
        this.listBlocks.push(
          275
        );
      }
      if (laudo.url == "arteriasRenais") {
        this.listBlocks.push(
          9, 10, 11
        );
      }
      if (laudo.url == "cranio") {
        this.listBlocks.push(
          140
        );
      }
      if (laudo.url == "morfoPrimDoppler") {
        this.listBlocks.push(
          181, 182
        );
      }
      if (laudo.url == "mamaDoppler") {
        this.listBlocks.push(
          321
        );
      }
      if (laudo.url == "abdomeTotalProstata") {
        this.listBlocks.push(
          33, 34, 35, 36, 37
        );
      }
      if (laudo.url == "abdomeTotalPelvico") {
        this.listBlocks.push(
          20, 21, 22, 23, 24, 26
        );
      }
      if (laudo.url == "rinsPelvico") {
        this.listBlocks.push(
          9, 10, 11, 20, 21, 22, 23, 24, 26
        );
      }
      if (laudo.url == "rinsProstata") {
        this.listBlocks.push(
          9, 10, 11, 33, 34, 35, 36, 37
        );
      }
      this._pouchdb.loadLaudosDatabaseByBlocks(this.listBlocks, laudo.url);
      this.listBlocks = [];

      this.loadingProgress += 1;
      this.options.percent = Math.floor((this.loadingProgress / this.laudosList.length) * 100);
      if (this.options.percent == 100) {
        setTimeout(() => {
          this.showloading = false
        }, 500);
      }

    });
    localStorage.setItem('laudosLoaded', 'true');
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription && !this.userDataSubscription.closed) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.userSubscription && !this.userSubscription.closed) {
      this.userSubscription.unsubscribe();
    }
    if (this.configLaudoSubscription && !this.configLaudoSubscription.closed) {
      this.configLaudoSubscription.unsubscribe();
    }
    if (this.npsSubscription && !this.npsSubscription.closed) {
      this.npsSubscription.unsubscribe();
    }
    if (this.npsListaSubscription && !this.npsListaSubscription.closed) {
      this.npsListaSubscription.unsubscribe();
    }
    if (this.statusPagamentoSubscription && !this.statusPagamentoSubscription.closed) {
      this.statusPagamentoSubscription.unsubscribe();
    }
  }
}
