import {NgModule} from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';

import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
          MatExpansionModule,
          MatFormFieldModule,
          MatToolbarModule,
          MatButtonModule,
          MatSidenavModule,
          MatIconModule,
          MatListModule,
          MatInputModule,
          MatTableModule,
          MatPaginatorModule,
          MatSortModule,
          MatGridListModule,
          MatCardModule,
          MatMenuModule,
          MatTabsModule,
          MatDatepickerModule,
          MatProgressBarModule,
          MatProgressSpinnerModule,
          MatNativeDateModule,
          MatTooltipModule,
          MatRadioModule,
          MatCheckboxModule,
          MatSelectModule,
          MatSlideToggleModule
        } from '@angular/material';
import {TextEditorModule} from '../../../../utils/textEditor/text-editor/text-editor.module';
import {ClipboardModule} from 'ngx-clipboard';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { AbdomeTotalModule } from '../abdome-total/abdome-total.module';
import { PelvicoModule } from '../pelvico/pelvico.module';
import { AbdomeTotalComponent } from '../abdome-total/abdome-total.component';
import { PelvicoComponent } from '../pelvico/pelvico.component';
import { TransvaginalModule } from '../transvaginal/transvaginal.module';
import { RinsPelvicoComponent } from './rins-pelvico.component';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    ClipboardModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule, 
    AbdomeTotalModule,
    PelvicoModule,
    TransvaginalModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    DragDropModule,
    RinsPelvicoComponent
  ],
  declarations: [
    RinsPelvicoComponent
  ],
  providers:[
    AbdomeTotalComponent,
    PelvicoComponent
  ],
})
export class RinsPelvicoModule { }
