import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-veia-musculares',
  templateUrl: './veia-musculares.component.html',
  styleUrls: ['./veia-musculares.component.css']
})
export class VeiaMuscularesComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdTVPAguda') rdTVPAguda: MatRadioButton;
  @ViewChild('slmotivo') slmotivo: MatSelect;



  public motivo: ArrayDropDown[] = [
    { value: 'edema', viewValue: 'Edema' },
    { value: 'atenuação do feixe acústico', viewValue: 'Atenuação do feixe acústico' },
  ];


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahnaoCaracterizada: false,
      sahTVPAguda: false,
    };
  }

  public ClickNormal(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTVPAguda = false;
    this.catchIDs();
  }

  public ClicknaoCaracterizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = true;
    this.showAndHideTerms.sahTVPAguda = false;
    this.catchIDs();
  }

  public ClickTVPAguda(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahTVPAguda = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdnaoCaracterizada && this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);

      if (this.slmotivo && this.slmotivo.value) {
        const objeto = { elemento: this.slmotivo };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTVPAguda && this.rdTVPAguda.checked) {
      this.termoSelecionado.emit(this.rdTVPAguda.id);

    }

    this.listaCompleta.emit();

  }

}
