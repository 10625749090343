import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface LadoMama {
  value: string;
  viewValue: string;
}

export interface Localizacao {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-distorcao-arquitetural',
  templateUrl: './distorcao-arquitetural.component.html',
  styleUrls: ['./distorcao-arquitetural.component.css']
})
export class DistorcaoArquiteturalComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdEmSitio') rdEmSitio: MatRadioButton;
  @ViewChild('rdForaSitio') rdForaSitio: MatRadioButton;

  @ViewChild('slLadoMana') slLadoMana: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  ladosMama: LadoMama[] = [
    {value: 'direita', viewValue: 'Direita'},
    {value: 'esquerda', viewValue: 'Esquerda'},
    {value: 'bilateral', viewValue: 'Bilateral'}
  ];

  localizacoes: Localizacao[] = [
    {value: 'na região retroareolar', viewValue: 'Região retroareolar'},
    {value: 'no quadrante superolateral', viewValue: 'Quadrante superolateral'},
    {value: 'no quadrante superomedial', viewValue: 'Quadrante superomedial'},
    {value: 'no quadrante inferolateral', viewValue: 'Quadrante inferolateral'},
    {value: 'no quadrante inferomedial', viewValue: 'Quadrante inferomedial'},
    {value: 'na junção dos quadrantes inferiores', viewValue: 'Junção dos quadrantes inferiores'}
  ];

  showAndHideTerms: any;

  ladoMamadeSelected = this.ladosMama[2].value;
  localizacaoSelected = this.localizacoes[5].value;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: false
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahAusente = false;
    this.catchIDs();
  }

  clickEmSitio() {
    this.showAndHideTerms.sahAusente = true;
    this.catchIDs();
  }

  clickForaSitio() {
    this.showAndHideTerms.sahAusente = true;
    this.catchIDs();
  }

  changeLadoMama() {
    this.catchIDs();
  }

  changeLocalizacao() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdEmSitio.checked) {
      this.termoSelecionado.emit(this.rdEmSitio.id);

      this.selectSitio();
    }

    if (this.rdForaSitio.checked) {
      this.termoSelecionado.emit(this.rdForaSitio.id);

      this.selectSitio();
    }

    // if (this.slLadoMana != undefined && this.slLadoMana.value != undefined) {
    //   const objeto = {elemento: this.slLadoMana, isPrimeiraLesao: true};
    //   this.termosGenericos.emit(objeto);
    // }
    //
    // if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
    //   const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
    //   this.termosGenericos.emit(objeto);
    // }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  selectSitio() {
    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slLadoMana != undefined) {
      if (this.slLadoMana.value != undefined) {
        const objeto = {elemento: this.slLadoMana, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 357, value: this.ladoMamadeSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    } else {
      const elemento = { id: 357, value: this.ladoMamadeSelected }
      const objeto = {elemento: elemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }

    // POG - Ajustar isso e pegar pelo form. Ass: Gabs
    if (this.slLocalizacao != undefined) {
      if (this.slLocalizacao.value != undefined) {
        const objeto = {elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 356, value: this.localizacaoSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    } else {
      const elemento = { id: 356, value: this.localizacaoSelected }
      const objeto = {elemento: elemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objeto);
    }
  }
}
