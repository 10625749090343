import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { Validacoes } from '../../../../../../../app/utils/validacoes';

@Component({
  selector: 'app-fossa-poplitea',
  templateUrl: './fossa-poplitea.component.html',
  styleUrls: ['./fossa-poplitea.component.css']
})
export class FossaPopliteaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdCisto') rdCisto: MatRadioButton;

  @ViewChild('rdSemRotura') rdSemRotura: MatRadioButton;
  @ViewChild('inMedindo1') inMedindo1: ElementRef;
  @ViewChild('inMedindo2') inMedindo2: ElementRef;
  @ViewChild('inMedindo3') inMedindo3: ElementRef;
  @ViewChild('rdRoto') rdRoto: MatRadioButton;

  showAndHideTerms: any;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCisto: false,
      sahSemRotura: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahSemRotura = false;
    this.catchIDs();
  }

  clickCisto() {
    this.showAndHideTerms.sahCisto = true;
    this.showAndHideTerms.sahSemRotura = true;
    this.catchIDs();
  }

  clickSemRotura() {
    this.showAndHideTerms.sahSemRotura = true;
    this.catchIDs();
  }

  clickRoto() {
    this.showAndHideTerms.sahSemRotura = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }
  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdCisto.checked) {
      this.termoSelecionado.emit(this.rdCisto.value);

      if (this.rdSemRotura && this.rdSemRotura.checked) {
        this.termoSelecionado.emit(this.rdSemRotura.value);
        
        this.emitMedindo();
      }

      if(this.rdRoto && this.rdRoto.checked){
        this.termoSelecionado.emit(this.rdRoto.value);
      }
    }

    this.listaCompleta.emit();
  }

  emitMedindo() {
    let medindoValor = '';

    if (this.inMedindo1 && this.inMedindo1.nativeElement.value) {

      medindoValor = this._validacoes.formataDecimal(this.inMedindo1.nativeElement.value);
    }

    if (this.inMedindo2 && this.inMedindo2.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedindo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedindo2.nativeElement.value);
      }
    }

    if (this.inMedindo3 && this.inMedindo3.nativeElement.value) {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedindo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedindo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = {id: 1291, value: medindoValor};
      const objetoLesao = {elemento: objetoElemento, isPrimeiraLesao: true};
      this.termosGenericos.emit(objetoLesao);
    }

  }
}
