import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransvPrimeiroTrimestreComponent } from './transv-primeiro-trimestre.component';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule,
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { ObsCalculadoraComponent } from '../obs-calculadora/obs-calculadora.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { OptSacoGestacionalComponent } from './termosComponents/opt-saco-gestacional/opt-saco-gestacional.component';
import { OptUteroComponent } from './termosComponents/opt-utero/opt-utero.component';
import { OptEmbriaoComponent } from './termosComponents/opt-embriao/opt-embriao.component';
import { OptVesiculaVinelinicaComponent } from './termosComponents/opt-vesicula-vinelinica/opt-vesicula-vinelinica.component';
import { OptTrofoblastoComponent } from './termosComponents/opt-trofoblasto/opt-trofoblasto.component';
import { OptColoUterinoComponent } from './termosComponents/opt-colo-uterino/opt-colo-uterino.component';
import { OptOvariosComponent } from './termosComponents/opt-ovarios/opt-ovarios.component';
import { OptCorpoLuteoComponent } from './termosComponents/opt-corpo-luteo/opt-corpo-luteo.component';
import { OptLiquidoLivreComponent } from './termosComponents/opt-liquido-livre/opt-liquido-livre.component';
import { OptTecnicaComponent } from './termosComponents/opt-tecnica/opt-tecnica.component';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    ObsCalculadoraModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule
  ],
  exports: [
    TransvPrimeiroTrimestreComponent
  ],
  declarations: [
    TransvPrimeiroTrimestreComponent,
    OptUteroComponent,
    OptSacoGestacionalComponent,
    OptEmbriaoComponent,
    OptVesiculaVinelinicaComponent,
    OptTrofoblastoComponent,
    OptColoUterinoComponent,
    OptOvariosComponent,
    OptCorpoLuteoComponent,
    OptLiquidoLivreComponent,
    OptTecnicaComponent
  ],
  providers: [
    OptEmbriaoComponent
  ]
})
export class TransvPrimeiroTrimestreModule { }
