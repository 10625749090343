import { Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import { MatRadioButton } from '@angular/material';

@Component({
  selector: 'app-aspecto',
  templateUrl: './aspecto.component.html',
  styleUrls: ['./aspecto.component.css']
})
export class AspectoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdBraquiocefalico') rdBraquiocefalico: MatRadioButton;
  @ViewChild('rdDolicocefalico') rdDolicocefalico: MatRadioButton;
  @ViewChild('rdMicrocefalico') rdMicrocefalico: MatRadioButton;

  constructor() { }

  ngOnInit() {

  }

  clickDefault(){
    this.catchIDs();
  }
 

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdBraquiocefalico.checked) {
      this.termoSelecionado.emit(this.rdBraquiocefalico.id);
    }
    if (this.rdDolicocefalico.checked) {
      this.termoSelecionado.emit(this.rdDolicocefalico.id);
    }
    if (this.rdMicrocefalico.checked) {
      this.termoSelecionado.emit(this.rdMicrocefalico.id);
    }    
    
    this.listaCompleta.emit();

  }

}
