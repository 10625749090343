import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-tecnica',
  templateUrl: './tecnica.component.html',
  styleUrls: ['./tecnica.component.css']
})
export class TecnicaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdPa') rdPa: MatRadioButton;
  @ViewChild('rdPaPerfil') rdPaPerfil: MatRadioButton;
  
  
  constructor() { }

  ngOnInit() {
  }

  clickDefault(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdPa.checked) {
      this.termoSelecionado.emit(this.rdPa.id);
    }
    if (this.rdPaPerfil.checked) {
      this.termoSelecionado.emit(this.rdPaPerfil.id);
    }
   
    this.listaCompleta.emit();
  }
}
