import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule,
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from '../../../../directives/custom-directive.module';
import { TransvSegTercTrimestreComponent } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.component';
import { ObsCalculadoraModule } from '../obs-calculadora/obs-calculadora.module';
import { TransvSegTercDopplerComponent } from './transv-seg-terc-doppler.component';
import { TransvSegTercTrimestreModule } from '../transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { UsgMorfoPrimDopplerModule } from '../usg-morfo-prim-doppler/usg-morfo-prim-doppler.module';
import { DopplervelocimetriaComponent } from './TermosComponents/dopplervelocimetria/dopplervelocimetria.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    ObsCalculadoraModule,
    TransvSegTercTrimestreModule,
    UsgMorfoPrimDopplerModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports:[
    TransvSegTercDopplerComponent,
    DopplervelocimetriaComponent
  ],
  declarations: [
    TransvSegTercDopplerComponent,
    DopplervelocimetriaComponent
  ],
  providers: [
    TransvSegTercTrimestreComponent
  ]
})
export class TransvSegTercDopplerModule { }
