import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-parenquima-cerebral',
  templateUrl: './parenquima-cerebral.component.html',
  styleUrls: ['./parenquima-cerebral.component.css']
})
export class ParenquimaCerebralComponent implements OnInit {

  public showAndHideTerms: any;
  public countLesoes: number;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;
  @ViewChild('ckRestParenquima') ckRestParenquima: MatCheckbox;
  @ViewChild('rdParênquimacerebralHMGI') rdParênquimacerebralHMGI: MatRadioButton;
  @ViewChild('rdParênquimacerebralHMGII') rdParênquimacerebralHMGII: MatRadioButton;
  @ViewChild('rdParênquimacerebralHMGIII') rdParênquimacerebralHMGIII: MatRadioButton;
  @ViewChild('rdParênquimacerebralHMGIV') rdParênquimacerebralHMGIV: MatRadioButton;
  @ViewChild('rdParênquimacerebralLPI') rdParênquimacerebralLPI: MatRadioButton;
  @ViewChild('rdParênquimacerebralLPISIM') rdParênquimacerebralLPISIM: MatRadioButton;
  @ViewChild('rdParênquimacerebralLPINÃO') rdParênquimacerebralLPINÃO: MatRadioButton;
  @ViewChild('rdParênquimacerebralLPII') rdParênquimacerebralLPII: MatRadioButton;
  @ViewChild('rdParênquimacerebralLPIII') rdParênquimacerebralLPIII: MatRadioButton;
  @ViewChild('rdParênquimacerebralLPIV') rdParênquimacerebralLPIV: MatRadioButton;
  @ViewChild('slladosHMG') slladosHMG: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slladosLP') slladosLP: MatSelect;
  @ViewChild('inTamanho') inTamanho: ElementRef;

  public LadoHMG: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'Bilateral' },
  ];

  public Localizacao: ArrayDropDown[] = [
    { value: 'na região frontal', viewValue: 'Região Frontal' },
    { value: 'na região parietal', viewValue: 'Região Parietal' },
    { value: 'na região temporal', viewValue: 'Região Temporal' },
    { value: 'na região occipital', viewValue: 'Região Occipital' }
  ];

  public LadoLp: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahHMG1: false,
      sahHMG2: false,
      sahHMG3: false,
      sahHMG4: false,
      sahLP1: false,
      sahLPSIM1: false,
      sahLPNAO1: false,
      sahLP2: false,
      sahLP3: false,
      sahLP4: false,
    };
  }

  public ClickNormal(): any {
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahHMG1 = false;
    this.showAndHideTerms.sahHMG2 = false;
    this.showAndHideTerms.sahHMG3 = false;
    this.showAndHideTerms.sahHMG4 = false;
    this.showAndHideTerms.sahLP1 = false;
    this.showAndHideTerms.sahLPSIM1 = false;
    this.showAndHideTerms.sahLPNAO1 = false;
    this.showAndHideTerms.sahLP2 = false;
    this.showAndHideTerms.sahLP3 = false;
    this.showAndHideTerms.sahLP4 = false;
    this.catchIDs();
  }

  ClickAlterado(): any {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahHMG1 = false;
    this.showAndHideTerms.sahHMG2 = false;
    this.showAndHideTerms.sahHMG3 = false;
    this.showAndHideTerms.sahHMG4 = false;
    this.showAndHideTerms.sahLP1 = false;
    this.showAndHideTerms.sahLPSIM1 = false;
    this.showAndHideTerms.sahLPNAO1 = false;
    this.showAndHideTerms.sahLP2 = false;
    this.showAndHideTerms.sahLP3 = false;
    this.showAndHideTerms.sahLP4 = false;
    this.catchIDs();
  }

  public ClickHMG1(): any {
    if (this.rdParênquimacerebralHMGI && this.rdParênquimacerebralHMGI.checked) {
      this.showAndHideTerms.sahHMG1 = true;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = false;
    } else {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralHMGII.checked = false;
    this.rdParênquimacerebralHMGIII.checked = false;
    this.rdParênquimacerebralHMGIV.checked = false;
    this.catchIDs();
  }

  public ClickHMG2(): any {
    if (this.rdParênquimacerebralHMGII && this.rdParênquimacerebralHMGII.checked) {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = true;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = false;
    } else {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralHMGI.checked = false;
    this.rdParênquimacerebralHMGIII.checked = false;
    this.rdParênquimacerebralHMGIV.checked = false;
    this.catchIDs();
  }

  public ClickHMG3(): any {
    if (this.rdParênquimacerebralHMGIII && this.rdParênquimacerebralHMGIII.checked) {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = true;
      this.showAndHideTerms.sahHMG4 = false;
    } else {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralHMGI.checked = false;
    this.rdParênquimacerebralHMGII.checked = false;
    this.rdParênquimacerebralHMGIV.checked = false;
    this.catchIDs();
  }

  public ClickHMG4(): any {
    if (this.rdParênquimacerebralHMGIV && this.rdParênquimacerebralHMGIV.checked) {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = true;
    } else {
      this.showAndHideTerms.sahHMG1 = false;
      this.showAndHideTerms.sahHMG2 = false;
      this.showAndHideTerms.sahHMG3 = false;
      this.showAndHideTerms.sahHMG4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralHMGI.checked = false;
    this.rdParênquimacerebralHMGII.checked = false;
    this.rdParênquimacerebralHMGIII.checked = false;
    this.catchIDs();
  }

  public ClickLP1(): any {
    if (this.rdParênquimacerebralLPI && this.rdParênquimacerebralLPI.checked) {
      this.showAndHideTerms.sahLP1 = true;
      this.showAndHideTerms.sahLPSIM1 = true;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    } else {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralLPII.checked = false;
    this.rdParênquimacerebralLPIII.checked = false;
    this.rdParênquimacerebralLPIV.checked = false;
    this.catchIDs();
  }

  public ClickLPSIM1(): any {
    if (this.rdParênquimacerebralLPISIM && this.rdParênquimacerebralLPISIM.checked) {
      this.showAndHideTerms.sahLP1 = true;
      this.showAndHideTerms.sahLPSIM1 = true;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    } else {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    }
    this.catchIDs();
  }

  public ClickLPNAO1(): any {
    if (this.rdParênquimacerebralLPINÃO && this.rdParênquimacerebralLPINÃO.checked) {
      this.showAndHideTerms.sahLP1 = true;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = true;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    } else {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    }
    this.catchIDs();
  }

  public ClickLP2(): any {
    if (this.rdParênquimacerebralLPII && this.rdParênquimacerebralLPII.checked) {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = true;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    } else {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralLPI.checked = false;
    this.rdParênquimacerebralLPIII.checked = false;
    this.rdParênquimacerebralLPIV.checked = false;
    this.catchIDs();
  }

  public ClickLP3(): any {
    if (this.rdParênquimacerebralLPIII && this.rdParênquimacerebralLPIII.checked) {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = true;
      this.showAndHideTerms.sahLP4 = false;
    } else {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralLPI.checked = false;
    this.rdParênquimacerebralLPII.checked = false;
    this.rdParênquimacerebralLPIV.checked = false;
    this.catchIDs();
  }

  public ClickLP4(): any {
    if (this.rdParênquimacerebralLPIV && this.rdParênquimacerebralLPIV.checked) {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = true;
    } else {
      this.showAndHideTerms.sahLP1 = false;
      this.showAndHideTerms.sahLPSIM1 = false;
      this.showAndHideTerms.sahLPNAO1 = false;
      this.showAndHideTerms.sahLP2 = false;
      this.showAndHideTerms.sahLP3 = false;
      this.showAndHideTerms.sahLP4 = false;
    }
    this.rdAlterado.checked = true;
    this.rdParênquimacerebralLPI.checked = false;
    this.rdParênquimacerebralLPII.checked = false;
    this.rdParênquimacerebralLPIII.checked = false;
    this.catchIDs();
  }

  public clickDefault(): any {
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }

  public changeDefault(): any {
    this.catchIDs();
  }

  public blurTamanho(): any {
    this.catchIDs();
  }

  public catchIDs(): any {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado && this.rdAlterado.checked) {

      if (this.rdParênquimacerebralHMGI && this.rdParênquimacerebralHMGI.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdParênquimacerebralHMGI.id);

        if (this.slladosHMG && this.slladosHMG.value) {
          const objeto = { elemento: this.slladosHMG, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdParênquimacerebralHMGII && this.rdParênquimacerebralHMGII.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdParênquimacerebralHMGII.id);

        if (this.slladosHMG && this.slladosHMG.value) {
          const objeto = { elemento: this.slladosHMG, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdParênquimacerebralHMGIII && this.rdParênquimacerebralHMGIII.checked) {
        this.countLesoes++;

        this.termoSelecionado.emit(this.rdParênquimacerebralHMGIII.id);

        if (this.slladosHMG && this.slladosHMG.value) {
          const objeto = { elemento: this.slladosHMG, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdParênquimacerebralHMGIV && this.rdParênquimacerebralHMGIV.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdParênquimacerebralHMGIV.id);

        if (this.slladosHMG && this.slladosHMG.value) {
          const objeto = { elemento: this.slladosHMG, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.countLesoes > 0)
        this.emitLPLesao();
      else
        this.emitLP();

      if (this.countLesoes > 0) {
        if (this.ckRestParenquima && this.ckRestParenquima.checked) {
          this.countLesoes++;
          const obj = { id: this.ckRestParenquima.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(obj);
        }
      } else {
        if (this.ckRestParenquima && this.ckRestParenquima.checked)
          this.termoSelecionado.emit(this.ckRestParenquima.id);
      }

    }
    this.listaCompleta.emit();
  }

  emitLP() {
    if (this.rdParênquimacerebralLPI && this.rdParênquimacerebralLPI.checked) {
      this.termoSelecionado.emit(this.rdParênquimacerebralLPI.id);

      if (this.rdParênquimacerebralLPISIM && this.rdParênquimacerebralLPISIM.checked) {
        this.termoSelecionado.emit(this.rdParênquimacerebralLPISIM.id);

        if (this.slladosLP && this.slladosLP.value) {
          const objeto = { elemento: this.slladosLP, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdParênquimacerebralLPINÃO && this.rdParênquimacerebralLPINÃO.checked) {
        this.termoSelecionado.emit(this.rdParênquimacerebralLPINÃO.id);

        if (this.slladosLP && this.slladosLP.value) {
          const objeto = { elemento: this.slladosLP, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
    if (this.rdParênquimacerebralLPII && this.rdParênquimacerebralLPII.checked) {
      this.termoSelecionado.emit(this.rdParênquimacerebralLPII.id);

      if (this.slladosLP && this.slladosLP.value) {
        const objeto = { elemento: this.slladosLP, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdParênquimacerebralLPIII && this.rdParênquimacerebralLPIII.checked) {
      this.termoSelecionado.emit(this.rdParênquimacerebralLPIII.id);

      if (this.slladosLP && this.slladosLP.value) {
        const objeto = { elemento: this.slladosLP, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
      if (this.inTamanho && this.inTamanho.nativeElement.value) {
        const objeto = { elemento: this.inTamanho.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdParênquimacerebralLPIV && this.rdParênquimacerebralLPIV.checked) {
      this.termoSelecionado.emit(this.rdParênquimacerebralLPIV.id);

      if (this.slladosLP && this.slladosLP.value) {
        const objeto = { elemento: this.slladosLP, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitLPLesao() {
    if (this.rdParênquimacerebralLPI && this.rdParênquimacerebralLPI.checked) {
      this.countLesoes++;
      const obj = { id: this.rdParênquimacerebralLPI.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdParênquimacerebralLPISIM && this.rdParênquimacerebralLPISIM.checked) {
        const obj = { id: this.rdParênquimacerebralLPISIM.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

        if (this.slladosLP && this.slladosLP.value) {
          const objeto = { elemento: this.slladosLP, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdParênquimacerebralLPINÃO && this.rdParênquimacerebralLPINÃO.checked) {
        const obj = { id: this.rdParênquimacerebralLPINÃO.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

        if (this.slladosLP && this.slladosLP.value) {
          const objeto = { elemento: this.slladosLP, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
    if (this.rdParênquimacerebralLPII.checked) {
      this.countLesoes++;
      const obj = { id: this.rdParênquimacerebralLPII.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slladosLP && this.slladosLP.value) {
        const objeto = { elemento: this.slladosLP, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdParênquimacerebralLPIII.checked) {
      this.countLesoes++;
      const obj = { id: this.rdParênquimacerebralLPIII.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slladosLP && this.slladosLP.value) {
        const objeto = { elemento: this.slladosLP, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
      if (this.inTamanho && this.inTamanho.nativeElement.value) {
        const objeto = { elemento: this.inTamanho.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdParênquimacerebralLPIV.checked) {
      this.countLesoes++;
      const obj = { id: this.rdParênquimacerebralLPIV.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slladosLP && this.slladosLP.value) {
        const objeto = { elemento: this.slladosLP, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }
}
