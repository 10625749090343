import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../utils/dialog/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatusPagamentoService {

  private statusPagamento$ = new BehaviorSubject<number>(0);

  emitStatusPgto(status: number) {
    this.statusPagamento$.next(status);
  }

  getStatusPagamento() {
    return this.statusPagamento$.asObservable();
  }

  constructor(private _router: Router,
              private _dialog: MatDialog) {}

  resolverStatusPgto() {
    let mensage: string;
    this.getStatusPagamento().pipe(
            take(1)
            ).subscribe(status => {
              if (status === 1) {
                mensage = 'check_circle|Seus Planos foram Adquiridos com sucesso!';
                this.alertStatusMensage(mensage);
                this._router.navigateByUrl('/laudos');
              } else if (status === 3) {
                mensage = 'check_circle|Seus Planos foram Adquiridos. Por favor aguarde a liberação por parte de sua operadora de Cartão.';
                this.alertStatusMensage(mensage);
                this._router.navigateByUrl('/laudos');
              } else {
                mensage = 'cancel|Pagamento com problema, revise os dados do cartão ou contacte sua operadora.';
                this.alertStatusMensage(mensage);
                this._router.navigateByUrl('/planos');
              }
            });
  }

  alertStatusMensage(mensage: string) {
    const dialogRef = this._dialog.open(AlertDialogComponent, {
      width: '450px',
      data: mensage
    });
  }


}
