import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css']
})
export class DispositivosComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('ckCanula') ckCanula: MatCheckbox;
  @ViewChild('ckSonda') ckSonda: MatCheckbox;
  @ViewChild('ckCateter') ckCateter: MatCheckbox;
  @ViewChild('ckMaterial') ckMaterial: MatCheckbox;
  @ViewChild('ckClipe') ckClipe: MatCheckbox;
  @ViewChild('ckStent') ckStent: MatCheckbox;
  @ViewChild('ckAuditivo') ckAuditivo: MatCheckbox;
  
  @ViewChild('slLateralSonNasal') slLateralSonNasal: MatSelect;
  @ViewChild('slEntrada') slEntrada: MatSelect;  
  @ViewChild('slLadosCateter') slLadosCateter: MatSelect;
  @ViewChild('slExtremidades') slExtremidades: MatSelect;
  @ViewChild('slTopografia') slTopografia: MatSelect;
  @ViewChild('slLado') slLado: MatSelect;
  @ViewChild('slTopografiaMaterial') slTopografiaMaterial: MatSelect;
  @ViewChild('slLadoMaterial') slLadoMaterial: MatSelect;
  @ViewChild('slTopografiaStent') slTopografiaStent: MatSelect;
  @ViewChild('slLadoStent') slLadoStent: MatSelect;
  @ViewChild('slLadoAuditivo') slLadoAuditivo: MatSelect;

  entradas: ArrayDropDown[] = [
    { value: 'frontal', viewValue: 'Frontal' },
    { value: 'parietal', viewValue: 'Parietal' },
    { value: 'temporal', viewValue: 'Temporal' },
    { value: 'occipital', viewValue: 'Occipital' }
  ];

  extremidades: ArrayDropDown[] = [
    { value: 'ventrículo lateral direito', viewValue: 'Ventrículo direito' },
    { value: 'ventrículo lateral esquerdo', viewValue: 'ventrículo esquerdo' },
    { value: 'terceiro ventrículo', viewValue: 'Terceiro ventrículo' }
  ];

  lateralidades: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' }
  ];

  lados: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' }
  ];

  topografias: ArrayDropDown[] = [
    { value: 'artéria cerebral média', viewValue: 'Artéria cerebral média' },
    { value: 'artéria cerebral anterior', viewValue: 'Artéria cerebral anterior' },
    { value: 'artéria pericalosa', viewValue: 'Artéria pericalosa' },
    { value: 'artéria comunicante posterior', viewValue: 'artéria comunicante posterior' },
    { value: 'artéria cerebral posterior', viewValue: 'Artéria cerebral posterior' },
    { value: 'artéria vertebral', viewValue: 'Artéria vertebral' },
    { value: 'artéria carótida interna', viewValue: 'Artéria carótida interna' },
    { value: 'artéria comunicante anterior', viewValue: 'Artéria comunicante anterior' },
    { value: 'artéria basilar', viewValue: 'Artéria basilar' }
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahPresente: true,
      sahSonNasal: false,
      sahCateter: false,
      sahClipe: false,
      sahMaterial: false,
      sahStent: false,
      sahAuditivo: false,
      sahSemLado: true,
      sahSemLadoMaterial: true,
      sahSemLadoStent: true,
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahPresente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahPresente = true;
    this.catchIDs();
  }

  clickCanula() {
    this.mudaPresente();
    this.catchIDs();
  }

  clickSonda() {
    if (this.showAndHideTerms.sahSonNasal === false) {
      this.showAndHideTerms.sahSonNasal = true;
    } else {
      this.showAndHideTerms.sahSonNasal = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickCateter() {
    if (this.showAndHideTerms.sahCateter === false) {
      this.showAndHideTerms.sahCateter = true;
    } else {
      this.showAndHideTerms.sahCateter = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickClipe() {
    if (this.showAndHideTerms.sahClipe === false) {
      this.showAndHideTerms.sahClipe = true;
    } else {
      this.showAndHideTerms.sahClipe = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickMaterial() {
    if (this.showAndHideTerms.sahMaterial === false) {
      this.showAndHideTerms.sahMaterial = true;
    } else {
      this.showAndHideTerms.sahMaterial = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }


  clickStent() {
    if (this.showAndHideTerms.sahStent === false) {
      this.showAndHideTerms.sahStent = true;
    } else {
      this.showAndHideTerms.sahStent = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  clickAuditivo() {
    if (this.showAndHideTerms.sahAuditivo === false) {
      this.showAndHideTerms.sahAuditivo = true;
    } else {
      this.showAndHideTerms.sahAuditivo = false;
    }
    this.mudaPresente();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {

      if (this.ckCanula && this.ckCanula.checked) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckCanula.id);
      }

      if (this.countLesoes > 0) {
        this.emitSondaLesao();
      } else {
        this.emitSonda();
      }

      if (this.countLesoes > 0) {
        this.emitCateterLesao();
      } else {
        this.emitCateter();
      }

      if (this.countLesoes > 0) {
        this.emitClipeLesao();
      } else {
        this.emitClipe();
      }

      if (this.countLesoes > 0) {
        this.emitMaterialLesao();
      } else {
        this.emitMaterial();
      }

      if (this.countLesoes > 0) {
        this.emitStentLesao();
      } else {
        this.emitStent();
      }

      
      if (this.countLesoes > 0) {
        this.emitAuditivoLesao();
      } else {
        this.emitAuditivo();
      }

    }

    this.listaCompleta.emit();
  }

  emitSonda() {
    if (this.ckSonda && this.ckSonda.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckSonda.id);

      if (this.slLateralSonNasal && this.slLateralSonNasal.value) {
        const objeto = { elemento: this.slLateralSonNasal, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitSondaLesao() {
  if (this.ckSonda && this.ckSonda.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckSonda.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLateralSonNasal && this.slLateralSonNasal.value) {
        const objeto = { elemento: this.slLateralSonNasal, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }


  emitCateter() {
    if (this.ckCateter && this.ckCateter.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCateter.id);

      if (this.slEntrada && this.slEntrada.value) {
        const objeto = { elemento: this.slEntrada, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadosCateter && this.slLadosCateter.value) {
        const objeto = { elemento: this.slLadosCateter, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slExtremidades && this.slExtremidades.value) {
        const objeto = { elemento: this.slExtremidades, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitCateterLesao() {
    if (this.ckCateter && this.ckCateter.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckCateter.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slEntrada && this.slEntrada.value) {
        const objeto = { elemento: this.slEntrada, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadosCateter && this.slLadosCateter.value) {
        const objeto = { elemento: this.slLadosCateter, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slExtremidades && this.slExtremidades.value) {
        const objeto = { elemento: this.slExtremidades, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitClipe() {
    if (this.ckClipe && this.ckClipe.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckClipe.id);

      if (this.slTopografia && this.slTopografia.value) {
        if (this.slTopografia.value === 'artéria comunicante anterior' ||
           this.slTopografia.value === 'artéria basilar') {
            this.showAndHideTerms.sahSemLado = false;
           } else { 
            this.showAndHideTerms.sahSemLado = true;
           }
        const objeto = { elemento: this.slTopografia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

      }

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitClipeLesao() {
    if (this.ckClipe && this.ckClipe.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckClipe.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slTopografia && this.slTopografia.value) {
        if (this.slTopografia.value === 'artéria comunicante anterior' ||
           this.slTopografia.value === 'artéria basilar') {
            this.showAndHideTerms.sahSemLado = false;
           } else { 
            this.showAndHideTerms.sahSemLado = true;
           }
        const objeto = { elemento: this.slTopografia, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLado && this.slLado.value) {
        const objeto = { elemento: this.slLado, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }


  emitMaterial() {
    if (this.ckMaterial && this.ckMaterial.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMaterial.id);

      if (this.slTopografiaMaterial && this.slTopografiaMaterial.value) {
        if (this.slTopografiaMaterial.value === 'artéria comunicante anterior' ||
           this.slTopografiaMaterial.value === 'artéria basilar') {
            this.showAndHideTerms.sahSemLadoMaterial = false;
           } else { 
            this.showAndHideTerms.sahSemLadoMaterial = true;
           }
        const objeto = { elemento: this.slTopografiaMaterial, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

      }

      if (this.slLadoMaterial && this.slLadoMaterial.value) {
        const objeto = { elemento: this.slLadoMaterial, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitMaterialLesao() {
    if (this.ckMaterial && this.ckMaterial.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckMaterial.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slTopografiaMaterial && this.slTopografiaMaterial.value) {
        if (this.slTopografiaMaterial.value === 'artéria comunicante anterior' ||
           this.slTopografiaMaterial.value === 'artéria basilar') {
            this.showAndHideTerms.sahSemLadoMaterial = false;
           } else { 
            this.showAndHideTerms.sahSemLadoMaterial = true;
           }
        const objeto = { elemento: this.slTopografiaMaterial, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoMaterial && this.slLadoMaterial.value) {
        const objeto = { elemento: this.slLadoMaterial, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitStent() {
    if (this.ckStent && this.ckStent.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckStent.id);

      if (this.slTopografiaStent && this.slTopografiaStent.value) {
        if (this.slTopografiaStent.value === 'artéria basilar') {
            this.showAndHideTerms.sahSemLadoStent = false;
           } else { 
            this.showAndHideTerms.sahSemLadoStent = true;
           }
        const objeto = { elemento: this.slTopografiaStent, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoStent && this.slLadoStent.value) {
        const objeto = { elemento: this.slLadoStent, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitStentLesao() {
    if (this.ckStent && this.ckStent.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckStent.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slTopografiaStent && this.slTopografiaStent.value) {
        if (this.slTopografiaStent.value === 'artéria basilar') {
          this.showAndHideTerms.sahSemLadoStent = false;
         } else { 
          this.showAndHideTerms.sahSemLadoStent = true;
         }
        const objeto = { elemento: this.slTopografiaStent, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLadoStent && this.slLadoStent.value) {
        const objeto = { elemento: this.slLadoStent, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitAuditivo() {
    if (this.ckAuditivo && this.ckAuditivo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAuditivo.id);

      if (this.slLadoAuditivo && this.slLadoAuditivo.value) {
        const objeto = { elemento: this.slLadoAuditivo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }
  emitAuditivoLesao() {
  if (this.ckAuditivo && this.ckAuditivo.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckAuditivo.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);

      if (this.slLadoAuditivo && this.slLadoAuditivo.value) {
        const objeto = { elemento: this.slLadoAuditivo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  mudaPresente() {

    if ((this.ckCanula && this.ckCanula.checked) ||
        (this.ckSonda && this.ckSonda.checked) ||
        (this.ckCateter && this.ckCateter.checked) ||
        (this.ckClipe && this.ckClipe.checked) ||
        (this.ckMaterial && this.ckMaterial.checked) ||
        (this.ckStent && this.ckStent.checked) ||
        (this.ckAuditivo && this.ckAuditivo.checked) 
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
    }
  }

}
