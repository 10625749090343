import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../auth/user';
import { environment } from './../../environments/environment';
import { Term } from '../models/BO/Term';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ConnectionService } from './connection.service';

var PouchDB = require('pouchdb').default;
PouchDB.plugin(require('pouchdb-find'));
PouchDB.plugin(require('pouchdb-upsert'));

interface termPouchDB {
    id: number,
    termID: number,
    conclusion: string,
    text: string,
    medicalReportType: number,
    blockID: number,
    terms: Array<string>
}

@Injectable({
    providedIn: 'root'
})
export class PouchDBService {

    db: any;
  laudoAtual: { listBlocks: any; nomeBanco: any; languageID: any; };
    constructor(private _http: HttpClient, private _user: User, private _toastr: ToastrManager, private _connection: ConnectionService) { }

    carregarPorDia(nomeBanco: any): boolean {
        if (!this._connection.isOnline) {
            this._toastr.errorToastr('Off-line. Conexão perdida.', 'Desculpe',
                {
                    position: 'top-right',
                    toastTimeout: 4000,
                    maxShown: 1
                }
            )
        }

        var data = new Date().toLocaleDateString();
        var dataAplicacao = localStorage.getItem('dataAplicacao');

        if (dataAplicacao != data) {
            let bancos = [];
            localStorage.setItem("bancosCarregados", JSON.stringify(bancos));
            return true
        } else if (dataAplicacao == data) {
            let bancosSalvos = [];
            if (localStorage.getItem("bancosCarregados")) {
                bancosSalvos = JSON.parse(localStorage.getItem("bancosCarregados"));
            }
            if (bancosSalvos.filter(x => x === nomeBanco).length > 0) {
                return false;
            } else {
                return true;
            }
        }
    }

    loadLaudosDatabase(medicalReportType, nomeBanco) {
        if (this.carregarPorDia(nomeBanco)) {
            let companyID = this._user.getUserCompanyID();
            const _url = environment.nLaudoApi + "MedicalReport/BuscaMedicalReportClientDB/" + medicalReportType + '/' + companyID;

            this._http.get<any>(_url).toPromise().then((result) => {
                this.db = new PouchDB(nomeBanco);
                for (let element of result) {
                    this.putLaudo(element);
                }
            });
        } else {
            this.db = new PouchDB(nomeBanco);
        }
    }

    async loadLaudosDatabaseByBlocks(listBlocks, nomeBanco, languageID?) {
        this.laudoAtual={listBlocks:listBlocks, nomeBanco:nomeBanco, languageID:languageID};
        let bancos = JSON.parse(localStorage.getItem("bancosCarregados"));
        let loadLaudosRule = JSON.parse(localStorage.getItem("loadLaudosRule"));
        let data = new Date().toLocaleDateString();
        let langID: any;

        if (languageID == null) {
            langID = 1
        } else {
            langID = languageID;
        }

        if (this.carregarPorDia(nomeBanco)) {
            let deleted = false;
            if (!this._connection.isOnline) {

                this.db = new PouchDB(nomeBanco);
            } else {
                deleted = await this.deleteDaabase(nomeBanco);
            }

            if (deleted) {
                if (!loadLaudosRule || loadLaudosRule.active != true) {
                  this._toastr.infoToastr('Aguarde enquanto seu laudo é carregado. Esse processo acontece uma vez ao dia e você será avisado ao final.', 'Aguarde um instante',
                    {
                        position: 'top-right',
                        toastTimeout: 10000,
                        maxShown: 1
                    }
                  )
                }

                let companyID = this._user.getUserCompanyID();
                let userID = this._user.getUserID();
                const _url = environment.nLaudoApi + "MedicalReport/BuscaMedicalReportClientDB";
                let term = {
                    UserID: userID,
                    CompanyID: companyID,
                    ListBlock: listBlocks,
                    LanguageID: langID
                }
                console.log(term);
                this._http.post<Array<termPouchDB>>(_url, term).toPromise().then((result) => {
                    this.db = new PouchDB(nomeBanco);
                    for (let element of result) {
                        this.putLaudo(element);
                    }

                    localStorage.setItem('dataAplicacao', data);
                    bancos.push(nomeBanco);
                    localStorage.setItem('bancosCarregados', JSON.stringify(bancos));

                    if (!loadLaudosRule || loadLaudosRule.active != true) {
                        this._toastr.successToastr('Exame carregado e pronto para uso.', 'Exame carregado!',
                            {
                                position: 'top-right',
                                toastTimeout: 4000,
                                maxShown: 1
                            }
                        )
                    }
                }).catch((error) => console.log(error));
            }
        } else {
            this.db = new PouchDB(nomeBanco);
        }
    }

    async deleteDaabase(banco) {
        this.db = new PouchDB(banco);

        try {
            await this.db.destroy();
            //   console.log ('Banco '+banco+' apagado');
            return true;
        } catch (err) {
            console.log(err);
        }
    }

    async createDatabase(listBlocks, nomeBanco, languageID?) {
      this.laudoAtual={listBlocks:listBlocks, nomeBanco:nomeBanco, languageID:languageID};
      let promise = new Promise((resolve, reject) => {
          this.db = new PouchDB(nomeBanco);
          resolve(this.db);
      });
      let result: any = await promise;
      return result
    }

    closeDatabase() {
        this.db.close().then(function (result) {
            console.log(result);
        }).catch(function (error) {
            console.log(error);
        });
    }

    putLaudo(doc) {
        doc._id = String(doc._id);
        doc.id = String(doc._id);
        this.db.put(doc).then(function (response) {
            console.log();
        }).catch(function (err) {
            console.log(err, doc);
        });
    }

    getLaudos(term?: Term): Promise<any> {
        const loDash = require('lodash');
        let obj: termPouchDB[] = [];
        let termos: number[] = [];
        let injuries: any[] = [];
        console.log(term);
        for (let element of term.listTermID) {
            if (typeof element == 'string') {
                element = parseInt(element);
            }
            if (!termos.includes(element)) {
                termos.push(element);
            }
        }

        if (term.injuries != undefined) {
            for (let arrInjurie of term.injuries) {
                let temp = [];
                for (let element of arrInjurie.listInjuries) {
                    if (typeof element == 'string') {
                        element = parseInt(element);
                    }
                    if (!temp.includes(element)) {
                        temp.push(element);
                    }
                }
                injuries.push(temp)
            }
        }
        console.log(this.db);
        return this.db.allDocs({ include_docs: true }).then(function (docs) {

            for (let element of docs.rows) {
                if (loDash.isEqual(loDash.sortBy(element.doc.terms), loDash.sortBy(termos))) {
                    obj.push({
                        id: element.doc.id,
                        termID: element.doc.termID,
                        medicalReportType: element.doc.medicalReportType,
                        blockID: element.doc.blockID,
                        conclusion: element.doc.conclusion,
                        text: element.doc.text,
                        terms: element.doc.terms
                    });
                }
            }

            if (term.injuries != undefined) {
                for (let injurie of injuries) {
                    for (let element of docs.rows) {
                        if (loDash.isEqual(loDash.sortBy(element.doc.terms), loDash.sortBy(injurie))) {
                            obj.push({
                                id: element.doc.id,
                                termID: element.doc.termID,
                                medicalReportType: element.doc.medicalReportType,
                                blockID: element.doc.blockID,
                                conclusion: element.doc.conclusion,
                                text: element.doc.text,
                                terms: element.doc.terms
                            });
                        }
                    }
                }
            }
            return obj;
        }).then((reponse) => {
            return reponse;
        });

    }

    async loadDatabase(nomeBanco, descricao, conclusao) {
        this.db = new PouchDB(nomeBanco);
        console.log(this.db);
        let id = 0;
        for (let element of descricao) {
            this.putDescricao(element, id);
            id++;
        }
        for (let element of conclusao) {
            this.putConclusao(element, id);
            id++
        }
    }

    putDescricao(doc, id) {
        doc._id = String(id)
        doc.type = "descricao"
        this.db.putIfNotExists(doc).then(function (response) {
            console.log('PUT:' + response.updated);
        }).catch(function (err) {
            console.log(err, doc);
        });
    }

    putConclusao(doc, id) {
        doc._id = String(id)
        doc.type = "conclusao"
        this.db.putIfNotExists(doc).then(function (response) {
            console.log('PUT:' + response.updated);
        }).catch(function (err) {
            console.log(err, doc);
        });
    }

    getAll(nome): Promise<any> {
        let db = new PouchDB(nome);
        return db.allDocs({ include_docs: true }).then(function (result) {
            var list = result.rows.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
            return list;
        }).catch(function (err) {
            console.log(err);
        });

    }

    putMask(doc) {
        doc._id = String(doc.id);
        doc.id = String(doc._id);
        this.db.put(doc).then(function (response) {
            console.log();
        }).catch(function (err) {
            console.log(err, doc);
        });
    }

    async loadMask(medicalReportTypeID, nomeBanco, languageID?) {
        let langID: any;

        if (languageID == null) {
            langID = 1
        } else {
            langID = languageID;
        }
        return new Promise(resolve => {
            if (this.carregarPorDia(nomeBanco) && this._connection.isOnline) {
                const userId = +localStorage.getItem('userID')
                const companyID = this._user.getUserCompanyID();
                const _url = environment.nLaudoApi + "MedicalReport/BuscaMedicalReportMascara/" + medicalReportTypeID + '/' + companyID + '/' + userId + '/' + langID;

                this._http.get<any>(_url).toPromise().then((result) => {
                    let db = new PouchDB(nomeBanco);

                    result._id = String(result.id);
                    if (result != undefined) {
                        resolve(result);

                        db.putIfNotExists(
                            result
                        ).then(function () {
                            return db.allDocs({ include_docs: true });
                        }).catch(function (err) {
                            console.log(err);
                        });
                    }

                }).catch((error) => console.log(error));

            } else {
                resolve(this.db = new PouchDB(nomeBanco));
            }
        })
    }

    saveLaudo(obj, nomeBanco) {

        let db = new PouchDB(nomeBanco);
        obj._id = String(Date.now());
        db.put(obj).then(function (response) {
            console.log(response);
        }).catch(function (err) {
            console.log(err, obj);
        });
    }
    async getAllMask(nomeBanco) {

        let promise = new Promise(resolve => {
            let db = new PouchDB(nomeBanco)
            return db.allDocs({ include_docs: true }).then(function (result) {
                resolve(result.rows[0].doc);
            }).catch(function (err) {
                console.log(err);
            });
        }).catch(function (err) {
            console.log(err);
        });
        let result: any = await promise;
        return result;
    }
}
