import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-excursao-condilar',
  templateUrl: './excursao-condilar.component.html',
  styleUrls: ['./excursao-condilar.component.css']
})
export class ExcursaoCondilarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('ckHipoexcursao') ckHipoexcursao: MatCheckbox;
  @ViewChild('ckHiperexcursao') ckHiperexcursao: MatCheckbox;
  @ViewChild('slHipoexcursao') slHipoexcursao: MatSelect;
  @ViewChild('slHiperexcursao') slHiperexcursao: MatSelect;

  Lateralidade: ArrayDropDown[] = [
    { value: 'direito', viewValue: 'Direito' },
    { value: 'esquerdo', viewValue: 'Esquerdo'},
    { value: 'bilateral', viewValue: 'Bilateral'}
  ];

  showAndHideTerms: any;
  countLesoes = 0;

  constructor() { }

  ngOnInit() {

    this.showAndHideTerms = {
      sahAlterado : true,
      sahHiperexcursao : false,
      sahHipoexcursao : false
    };
  }

  clickNormal(){
    this.showAndHideTerms.sahAlterado = false;
    this.showAndHideTerms.sahHiperexcursao = false;
    this.showAndHideTerms.sahHipoexcursao = false;
    this.catchIDs();
  }

  clickAlterado(){
    this.showAndHideTerms.sahAlterado = true;
    this.catchIDs();
  }

  clickHiperexcursao(){
    if (this.showAndHideTerms.sahHiperexcursao === false) {
      this.showAndHideTerms.sahHiperexcursao = true;
    } else {
      this.showAndHideTerms.sahHiperexcursao = false;
    }
    this.alteraNormal();
    this.catchIDs();
  }

  clickHipoexcursao(){
    if (this.showAndHideTerms.sahHipoexcursao === false) {
      this.showAndHideTerms.sahHipoexcursao = true;
    } else {
      this.showAndHideTerms.sahHipoexcursao = false;
    }
    this.alteraNormal();
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked){
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked){
      this.emitHipoexcursao();

      if(this.countLesoes > 0){
        this.emitHiperexcursaoLesao();
      } else {
        this.emitHiperexcursao();
      }

    }

    this.listaCompleta.emit();

  }

  alteraNormal() {
    if (this.ckHiperexcursao && this.ckHiperexcursao.checked || this.ckHipoexcursao && this.ckHipoexcursao.checked){
      this.rdAlterado.checked = true;
    }else {
      this.rdNormal.checked = true;
    }
  }

  emitHipoexcursao(){
    if(this.ckHipoexcursao.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHipoexcursao.id);
      if(this.slHipoexcursao && this.slHipoexcursao.value){
        const objeto = {elemento: this.slHipoexcursao, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHiperexcursao(){
    if(this.ckHiperexcursao.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckHiperexcursao.id);
      if(this.slHiperexcursao && this.slHiperexcursao.value){
        const objeto = {elemento: this.slHiperexcursao, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHiperexcursaoLesao(){
    if(this.ckHiperexcursao.checked){
      this.countLesoes++;
      const objeto = { id: this.ckHiperexcursao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if(this.slHiperexcursao && this.slHiperexcursao.value){
        const objeto = {elemento: this.slHiperexcursao, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }


}
