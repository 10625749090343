import { LaudosComponent } from './laudos.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbdomeTotalComponent } from './us/modo-b/abdome-total/abdome-total.component';
import { AbdomeTotalModule } from './us/modo-b/abdome-total/abdome-total.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MamaComponent } from './us/modo-b/mama/mama.component';
import { MamaModule } from './us/modo-b/mama/mama.module';
import { TextEditorModule } from '../utils/textEditor/text-editor/text-editor.module';
import { TransvaginalComponent } from './us/modo-b/transvaginal/transvaginal.component';
import { TransvaginalModule } from './us/modo-b/transvaginal/transvaginal.module';
import { CervicalModule } from './us/modo-b/cervical/cervical.module';
import { CervicalComponent } from './us/modo-b/cervical/cervical.component';
import { ProstataModule } from './us/modo-b/prostata/prostata.module';
import { ProstataComponent } from './us/modo-b/prostata/prostata.component';
import { AbdomeSuperiorComponent } from './us/modo-b/abdome-superior/abdome-superior.component';
import { AbdomeSuperiorModule } from './us/modo-b/abdome-superior/abdome-superior.module';
import { HipocondrioDireitoComponent } from './us/modo-b/hipocondrio-direito/hipocondrio-direito.component';
import { HipocondrioDireitoModule } from './us/modo-b/hipocondrio-direito/hipocondrio-direito.module';
import { RinsViasComponent } from './us/modo-b/rins-vias/rins-vias.component';
import { RinsViasModule } from './us/modo-b/rins-vias/rins-vias.module';
import { TireoideComponent } from './us/modo-b/tireoide/tireoide.component';
import { TireoideModule } from './us/modo-b/tireoide/tireoide.module';
import { GlandulasSalivaresComponent } from './us/modo-b/glandulas-salivares/glandulas-salivares.component';
import { GlandulasSalivaresModule } from './us/modo-b/glandulas-salivares/glandulas-salivares.module';
import { PelvicoComponent } from './us/modo-b/pelvico/pelvico.component';
import { PelvicoModule } from './us/modo-b/pelvico/pelvico.module';
import { TesticuloComponent } from './us/modo-b/testiculo/testiculo.component';
import { TesticuloModule } from './us/modo-b/testiculo/testiculo.module';
import { TesticuloDireitoComponent } from './us/modo-b/testiculo/TermosComponents/testiculo-direito/testiculo-direito.component';
import { TesticuloDopplerModule } from './us/doppler/testiculo-doppler/testiculo-doppler.module';
import { ParedeAbdominalComponent } from './us/modo-b/parede-abdominal/parede-abdominal.component';
import { TesticuloDopplerComponent } from './us/doppler/testiculo-doppler/testiculo-doppler.component';
import { ParedeAbdominalModule } from './us/modo-b/parede-abdominal/parede-abdominal.module';
import { RegiaoInguinalComponent } from './us/modo-b/regiao-inguinal/regiao-inguinal.component';
import { RegiaoInguinalModule } from './us/modo-b/regiao-inguinal/regiao-inguinal.module';
import { PartesMolesComponent } from './us/modo-b/partes-moles/partes-moles.component';
import { PartesMolesModule } from './us/modo-b/partes-moles/partes-moles.module';
import { TireoideDopplerComponent } from './us/doppler/tireoide-doppler/tireoide-doppler.component';
import { TireoideDopplerModule } from './us/doppler/tireoide-doppler/tireoide-doppler.module';
import { CarotidasComponent } from './us/doppler/carotidas/carotidas.component';
import { CarotidasModule } from './us/doppler/carotidas/carotidas.module';
import { AbdomeDopplerComponent } from './us/doppler/abdome-doppler/abdome-doppler.component';
import { AbdomeDopplerModule } from './us/doppler/abdome-doppler/abdome-doppler.module';
import { PunhoComponent } from './us/musculoesqueletico/punho/punho.component';
import { PunhoModule } from './us/musculoesqueletico/punho/punho.module';
import { PunhoBilateralModule } from './us/musculoesqueletico/punho-bilateral/punho-bilateral.module';
import { AbdomeSuperiorDopplerComponent } from './us/doppler/abdome-superior-doppler/abdome-superior-doppler.component';
import { AbdomeSuperiorDopplerModule } from './us/doppler/abdome-superior-doppler/abdome-superior-doppler.module';
import { OmbroComponent } from './us/musculoesqueletico/ombro/ombro.component';
import { OmbroModule } from './us/musculoesqueletico/ombro/ombro.module';
import { OmbroBilateralModule } from './us/musculoesqueletico/ombro-bilateral/ombro-bilateral.module';
import { JoelhoComponent } from './us/musculoesqueletico/joelho/joelho.component';
import { JoelhoModule } from './us/musculoesqueletico/joelho/joelho.module';
import { QuadrilComponent } from './us/musculoesqueletico/quadril/quadril.component';
import { QuadrilModule } from './us/musculoesqueletico/quadril/quadril.module';
import { TornozeloComponent } from './us/musculoesqueletico/tornozelo/tornozelo.component';
import { TornozeloModule } from './us/musculoesqueletico/tornozelo/tornozelo.module';
import { MusculosTendoesComponent } from './us/musculoesqueletico/musculos-tendoes/musculos-tendoes.component';
import { MusculoTendoesModule } from './us/musculoesqueletico/musculos-tendoes/musculos-tendoes.module';
import { MaoComponent } from './us/musculoesqueletico/mao/mao.component';
import { MaoModule } from './us/musculoesqueletico/mao/mao.module';
import { CotoveloComponent } from "./us/musculoesqueletico/cotovelo/cotovelo.component";
import { CotoveloModule } from "./us/musculoesqueletico/cotovelo/cotovelo.module";
import { ToraxComponent } from './tc/torax/torax.component';
import { ToraxModule } from './tc/torax/torax.module';
import { PeComponent } from './us/musculoesqueletico/pe/pe.component';
import { PeModule } from './us/musculoesqueletico/pe/pe.module';
import { MenuUsComponent } from './us/menu-us/menu-us.component';
import { MenuTcComponent } from './tc/menu-tc/menu-tc.component';
import { TransfontanelarComponent } from './us/modo-b/transfontanelar/transfontanelar.component';
import { TransfontanelarModule } from './us/modo-b/transfontanelar/Transfontanelar.module';
import { ToraxUsModule } from './us/modo-b/torax-us/torax-us.module';
import { ToraxUsComponent } from './us/modo-b/torax-us/torax-us.component';
import { AuditBlurDirective } from '../directives/audit-blur.directive';
import { AuditFocusDirective } from '../directives/audit-focus.directive';
import { AuditChangeDirective } from '../directives/audit-change.directive';
import { ToraxRsnaComponent } from './tc/torax-rsna/torax-rsna.component';
import { ToraxRsnaModule } from './tc/torax-rsna/torax-rsna.module';
import { CustomDirectiveModule } from '../directives/custom-directive.module';
import { MenuLateralModule } from '../menu-lateral/menu-lateral.module';
import { MenuLateralComponent } from '../menu-lateral/menu-lateral.component';
import { TransvPrimeiroTrimestreComponent } from './us/obstetrico/transv-primeiro-trimestre/transv-primeiro-trimestre.component';
import { TransvPrimeiroTrimestreModule } from './us/obstetrico/transv-primeiro-trimestre/transv-primeiro-trimestre.module';
import { TransvSegTercTrimestreModule } from './us/obstetrico/transv-seg-terc-trimestre/transv-seg-terc-trimestre.module';
import { TransvSegTercTrimestreComponent } from './us/obstetrico/transv-seg-terc-trimestre/transv-seg-terc-trimestre.component';
import { ArteriAortaComponent } from './us/doppler/arteri-aorta/arteri-aorta.component';
import { ArteriAortaModule } from './us/doppler/arteri-aorta/arteri-aorta.module';
import { TransvSegTercDopplerComponent } from './us/obstetrico/transv-seg-terc-doppler/transv-seg-terc-doppler.component';
import { TransvSegTercDopplerModule } from './us/obstetrico/transv-seg-terc-doppler/transv-seg-terc-doppler.module';
import { ArterialMembroSuperiorComponent } from './us/doppler/arterial-membro-superior/arterial-membro-superior.component';
import { ArterialMembroSuperiorModule } from './us/doppler/arterial-membro-superior/arterial-membro-superior.module';
import { VenosoMembroComponent } from './us/doppler/venoso-membro/venoso-membro.component';
import { VenosoMembroModule } from './us/doppler/venoso-membro/venoso-membro.module';
import { AortaIliacaComponent } from './us/doppler/aorta-iliaca/aorta-iliaca.component';
import { AortaIliacaModule } from './us/doppler/aorta-iliaca/aorta-iliaca.module';
import { ArteriaDoMembroInferiorComponent } from './us/doppler/arteria-do-membro-inferior/arteria-do-membro-inferior.component';
import { ArteriaDoMembroInferiorModule } from './us/doppler/arteria-do-membro-inferior/arteria-do-membro-inferior.module';
import { VenosoMembroSuperiorComponent } from './us/doppler/venoso-membro-superior/venoso-membro-superior.component';
import { VenosoMembroSuperiorModule } from './us/doppler/venoso-membro-superior/venoso-membro-superior.module';
import { VarizesComponent } from './us/doppler/varizes/varizes.component';
import { VarizesModule } from './us/doppler/varizes/varizes.module';
import { TransvDopplerComponent } from './us/doppler/transv-doppler/transv-doppler.component';
import { TransvDopplerModule } from './us/doppler/transv-doppler/transv-doppler.module';
import { CervicalDopplerComponent } from './us/doppler/cervical-doppler/cervical-doppler.component';
import { CervicalDopplerModule } from './us/doppler/cervical-doppler/cervical-doppler.module';
import { TireoideOrgaoComponent } from './us/modo-b/cervical/TermosComponents/tireoide-orgao/tireoide-orgao.component';
import { TireoideDopplerOrgaoComponent } from './us/doppler/tireoide-doppler/TermosComponents/tireoide-doppler-orgao/tireoide-doppler-orgao.component';
import { PenisComponent } from './us/modo-b/penis/penis.component';
import { PenisModule } from './us/modo-b/penis/penis.module';
import { AlteraLaudoComponent } from '../altera-laudo/altera-laudo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MatFormFieldModule, MatOptionModule, MatSelectModule, MAT_DIALOG_DATA } from '@angular/material';
import { MamografiaComponent } from './mg/mamografico/mamografia/mamografia.component';
import { MamografiaModule } from './mg/mamografico/mamografia/mamografia.module';
import { MenuMgComponent } from './mg/menu-mg/menu-mg.component';
import { SharedModule } from './shared/shared.module';
import { QuadrilInfantilComponent } from './us/musculoesqueletico/quadril-infantil/quadril-infantil.component';
import { QuadrilInfantilModule } from './us/musculoesqueletico/quadril-infantil/quadril-infantil.module';
import { AxilasComponent } from './us/modo-b/axilas/axilas.component';
import { CervicometriaModule } from './us/obstetrico/cervicometria/cervicometria.module';
import { CervicometriaComponent } from './us/obstetrico/cervicometria/cervicometria.component';
import { AxilasModule } from './us/modo-b/axilas/axilas.module';
import { CranioComponent } from './tc/cranio/cranio.component';
import { CranioModule } from './tc/cranio/cranio.module';
import { MorfologicoSegundoTrimestreComponent } from './us/obstetrico/morfologico-segundo-trimestre/morfologico-segundo-trimestre.component';
import { UsgMorfologicaPrimeiroTrimestreModule } from './us/obstetrico/usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.module';
import { UsgMorfologicaPrimeiroTrimestreComponent } from './us/obstetrico/usg-morfologica-primeiro-trimestre/usg-morfologica-primeiro-trimestre.component';
import { UsgMorfoPrimDopplerComponent } from './us/obstetrico/usg-morfo-prim-doppler/usg-morfo-prim-doppler.component';
import { UsgMorfoPrimDopplerModule } from './us/obstetrico/usg-morfo-prim-doppler/usg-morfo-prim-doppler.module';
import { MorfologicoSegundoTrimestreModule } from './us/obstetrico/morfologico-segundo-trimestre/morfologico-segundo-trimestre.module';
import { OmbroBilateralComponent } from './us/musculoesqueletico/ombro-bilateral/ombro-bilateral.component';
import { PunhoBilateralComponent } from './us/musculoesqueletico/punho-bilateral/punho-bilateral.component';
import { PeBilateralComponent } from './us/musculoesqueletico/pe-bilateral/pe-bilateral.component';
import { PeBilateralModule } from './us/musculoesqueletico/pe-bilateral/pe-bilateral.module';
import { JoelhoBilateralComponent } from './us/musculoesqueletico/joelho-bilateral/joelho-bilateral.component';
import { JoelhoBilateralModule } from './us/musculoesqueletico/joelho-bilateral/joelho-bilateral.module';
import { MaoBilateralComponent } from './us/musculoesqueletico/mao-bilateral/mao-bilateral.component';
import { MaoBilateralModule } from './us/musculoesqueletico/mao-bilateral/mao-bilateral.module';
import { TornozeloBilateralComponent } from './us/musculoesqueletico/tornozelo-bilateral/tornozelo-bilateral.component';
import { TornozeloBilateralModule } from './us/musculoesqueletico/tornozelo-bilateral/tornozelo-bilateral.module';
import { MusculosTendoesBilateralComponent } from './us/musculoesqueletico/musculos-tendoes-bilateral/musculos-tendoes-bilateral.component';
import { MusculosTendoesBilateralModule } from './us/musculoesqueletico/musculos-tendoes-bilateral/musculos-tendoes-bilateral.module';
import { CotoveloBilateralComponent } from './us/musculoesqueletico/cotovelo-bilateral/cotovelo-bilateral.component';
import { CotoveloBilateralModule } from './us/musculoesqueletico/cotovelo-bilateral/cotovelo-bilateral.module';
import { QuadrilBilateralComponent } from './us/musculoesqueletico/quadril-bilateral/quadril-bilateral.component';
import { QuadrilBilateralModule } from './us/musculoesqueletico/quadril-bilateral/quadril-bilateral.module';
import { QuadrilInfantilBilateralComponent } from './us/musculoesqueletico/quadril-infantil-bilateral/quadril-infantil-bilateral.component';
import { QuadrilInfantilBilateralModule } from './us/musculoesqueletico/quadril-infantil-bilateral/quadril-infantil-bilateral.module';
import { HeaderComponent } from '../header/header.component';
import { AbdomeTotalPelvicoModule } from './us/modo-b/abdome-total-pelvico/abdome-total-pelvico.module';
import { AbdomeTotalPelvicoComponent } from './us/modo-b/abdome-total-pelvico/abdome-total-pelvico.component';
import { AbdomeTotalProstataComponent } from './us/modo-b/abdome-total-prostata/abdome-total-prostata.component';
import { MamaDopplerComponent } from './us/doppler/mama-doppler/mama-doppler.component';
import { MamaDopplerModule } from './us/doppler/mama-doppler/mama-doppler.module';
import { AbdomeTotalProstataModule } from './us/modo-b/abdome-total-prostata/abdome-total-prostata.module';
import { RinsPelvicoComponent } from './us/modo-b/rins-pelvico/rins-pelvico.component';
import { RinsPelvicoModule } from './us/modo-b/rins-pelvico/rins-pelvico.module';
import { RinsProstataComponent } from './us/modo-b/rins-prostata/rins-prostata.component';
import { RinsProstataModule } from './us/modo-b/rins-prostata/rins-prostata.module';
import { MamaMasculinaComponent } from './us/modo-b/mama-masculina/mama-masculina.component';
import { MamaMasculinaModule } from './us/modo-b/mama-masculina/mama-masculina.module';
import { AbdomeTotalRefatoradoComponent } from './us/modo-b/abdome-total-refatorado/abdome-total-refatorado.component';
import { AbdomeTotalRefatoradoModule } from './us/modo-b/abdome-total-refatorado/abdome-total-refatorado.module';
import { MorfologicoSegundoTrimestreDopplerComponent } from './us/doppler/morfologico-segundo-trimestre-doppler/morfologico-segundo-trimestre-doppler.component';
import { MorfologicoSegundoTrimestreDopplerModule } from './us/doppler/morfologico-segundo-trimestre-doppler/morfologico-segundo-trimestre-doppler.module';
import { PenisDopplerComponent } from './us/doppler/penis-doppler/penis-doppler.component';
import { PenisDopplerModule } from './us/doppler/penis-doppler/penis-doppler.module';
import { MenuRxComponent } from './rx/menu-rx/menu-rx.component';
import { SeiosDaFaceComponent } from './rx/seios-da-face/seios-da-face.component';
import { SeiosDaFaceModule } from './rx/seios-da-face/seios-da-face.module';
import { RxCranioComponent } from './rx/rx-cranio/rx-cranio.component';
import { RxCranioModule } from './rx/rx-cranio/rx-cranio.module';
import { RxToraxComponent } from './rx/rx-torax/rx-torax.component';
import { RxToraxModule } from './rx/rx-torax/rx-torax.module';
import { RxArcosComponent } from './rx/rx-arcos/rx-arcos.component';
import { RxArcosModule } from './rx/rx-arcos/rx-arcos.module';
import { RxColunaLombarComponent } from './rx/rx-coluna-lombar/rx-coluna-lombar.component';
import { RxColunaLombarModule } from './rx/rx-coluna-lombar/rx-coluna-lombar.module';
import { RxAbdomeModule } from './rx/rx-abdome/rx-abdome.module';
import { RxAbdomeComponent } from './rx/rx-abdome/rx-abdome.component';
import { RxAtmComponent } from './rx/rx-atm/rx-atm.component';
import { RxAtmModule } from './rx/rx-atm/rx-atm.module';
import { RxColunaCervicalComponent } from './rx/rx-coluna-cervical/rx-coluna-cervical.component';
import { RxColunaCervicalModule } from './rx/rx-coluna-cervical/rx-coluna-cervical.module';
import { RxColunaToracicaModule } from './rx/rx-coluna-toracica/rx-coluna-toracica.module';
import { RxColunaToracicaComponent } from './rx/rx-coluna-toracica/rx-coluna-toracica.component';
import { RxSacroComponent } from './rx/rx-sacro/rx-sacro.component';
import { RxSacroModule } from './rx/rx-sacro/rx-sacro.module';
import { ObsSegTercGemelarComponent } from './us/obstetrico/obs-seg-terc-gemelar/obs-seg-terc-gemelar.component';
import { ObsSegTercGemelarModule } from './us/obstetrico/obs-seg-terc-gemelar/obs-seg-terc-gemelar.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonToggleModule,
    TextEditorModule,
    AbdomeTotalModule,
    AbdomeSuperiorModule,
    MamaModule,
    MamaDopplerModule,
    TransvaginalModule,
    CervicalModule,
    ProstataModule,
    HipocondrioDireitoModule,
    RinsViasModule,
    TireoideModule,
    GlandulasSalivaresModule,
    PelvicoModule,
    TesticuloModule,
    TesticuloDopplerModule,
    ParedeAbdominalModule,
    RegiaoInguinalModule,
    PartesMolesModule,
    TireoideDopplerModule,
    CarotidasModule,
    AbdomeDopplerModule,
    AbdomeSuperiorDopplerModule,
    PunhoModule,
    PunhoBilateralModule,
    OmbroModule,
    OmbroBilateralModule,
    JoelhoModule,
    JoelhoBilateralModule,
    QuadrilModule,
    QuadrilBilateralModule,
    TornozeloModule,
    TornozeloBilateralModule,
    MusculoTendoesModule,
    MusculosTendoesBilateralModule,
    MaoModule,
    MaoBilateralModule,
    CotoveloModule,
    CotoveloBilateralModule,
    PeModule,
    PeBilateralModule,
    ToraxModule,
    TransfontanelarModule,
    ToraxUsModule,
    ToraxRsnaModule,
    MenuLateralModule,
    TransvPrimeiroTrimestreModule,
    TransvSegTercTrimestreModule,
    TransvSegTercDopplerModule,
    ArteriAortaModule,
    ArterialMembroSuperiorModule,
    VenosoMembroModule,
    AortaIliacaModule,
    ArteriaDoMembroInferiorModule,
    VenosoMembroSuperiorModule,
    VarizesModule,
    TransvDopplerModule,
    CervicalDopplerModule,
    TireoideDopplerModule,
    PenisModule,
    MamografiaModule,
    QuadrilInfantilModule,
    QuadrilInfantilBilateralModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    CervicometriaModule,
    AxilasModule,
    CranioModule,
    UsgMorfologicaPrimeiroTrimestreModule,
    MorfologicoSegundoTrimestreModule,
    MorfologicoSegundoTrimestreDopplerModule,
    UsgMorfoPrimDopplerModule,
    AbdomeTotalPelvicoModule,
    AbdomeTotalProstataModule,
    RinsPelvicoModule,
    RinsProstataModule,
    MamaMasculinaModule,
    AbdomeTotalRefatoradoModule,
    PenisDopplerModule,
    SeiosDaFaceModule,
    RxCranioModule,
    RxToraxModule,
    RxArcosModule,
    RxColunaLombarModule,
    RxAbdomeModule,
    RxAtmModule,
    RxColunaCervicalModule,
    RxColunaToracicaModule,
    RxSacroModule,
    ObsSegTercGemelarModule
  ],
  exports: [
    MatCardModule,
    MatButtonToggleModule,
    TireoideOrgaoComponent,
    TireoideDopplerOrgaoComponent,
  ],
  entryComponents: [
    AbdomeTotalComponent,
    AbdomeSuperiorComponent,
    MamaComponent,
    TransvaginalComponent,
    CervicalComponent,
    ProstataComponent,
    HipocondrioDireitoComponent,
    RinsViasComponent,
    TireoideComponent,
    TireoideDopplerOrgaoComponent,
    GlandulasSalivaresComponent,
    PelvicoComponent,
    TesticuloComponent,
    TesticuloDopplerComponent,
    ParedeAbdominalComponent,
    RegiaoInguinalComponent,
    PartesMolesComponent,
    TireoideDopplerComponent,
    CarotidasComponent,
    AbdomeDopplerComponent,
    AbdomeSuperiorDopplerComponent,
    PunhoComponent,
    OmbroComponent,
    JoelhoComponent,
    QuadrilComponent,
    TornozeloComponent,
    MusculosTendoesComponent,
    MaoComponent,
    CotoveloComponent,
    PeComponent,
    ToraxComponent,
    ToraxRsnaComponent,
    MenuTcComponent,
    MenuUsComponent,
    MenuMgComponent,
    MenuRxComponent,
    TransfontanelarComponent,
    ToraxUsComponent,
    MenuLateralComponent,
    TransvPrimeiroTrimestreComponent,
    TransvSegTercTrimestreComponent,
    TransvSegTercDopplerComponent,
    ArteriAortaComponent,
    ArterialMembroSuperiorComponent,
    VenosoMembroComponent,
    AortaIliacaComponent,
    ArteriaDoMembroInferiorComponent,
    VenosoMembroSuperiorComponent,
    VarizesComponent,
    TransvDopplerComponent,
    CervicalDopplerComponent,
    MamografiaComponent,
    QuadrilInfantilComponent,
    CervicometriaComponent,
    AxilasComponent,
    PenisComponent,
    CranioComponent,
    UsgMorfologicaPrimeiroTrimestreComponent,
    JoelhoBilateralComponent,
    OmbroBilateralComponent,
    PunhoBilateralComponent,
    MorfologicoSegundoTrimestreComponent,
    MorfologicoSegundoTrimestreDopplerComponent,
    UsgMorfoPrimDopplerComponent,
    PeBilateralComponent,
    MaoBilateralComponent,
    TornozeloBilateralComponent,
    MusculosTendoesBilateralComponent,
    CotoveloBilateralComponent,
    QuadrilBilateralComponent,
    QuadrilInfantilBilateralComponent,
    AbdomeTotalPelvicoComponent,
    AbdomeTotalProstataComponent,
    MamaDopplerComponent,
    RinsPelvicoComponent,
    RinsProstataComponent,
    MamaMasculinaComponent,
    AbdomeTotalRefatoradoComponent,
    PenisDopplerComponent,
    SeiosDaFaceComponent,
    RxCranioComponent,
    RxToraxComponent,
    RxArcosComponent,
    RxColunaLombarComponent,
    RxAbdomeComponent,
    RxAtmComponent,
    RxColunaCervicalComponent,
    RxColunaToracicaComponent,
    RxSacroComponent,
    ObsSegTercGemelarComponent
  ],
  declarations: [],
  providers:[
    HeaderComponent,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ]
})
export class LaudosModule { }
