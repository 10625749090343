import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditBlurDirective } from './audit-blur.directive';
import { AuditFocusDirective } from './audit-focus.directive';
import { AuditChangeDirective } from './audit-change.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AuditBlurDirective,
    AuditFocusDirective,
    AuditChangeDirective
  ],
  exports: [
    AuditBlurDirective,
    AuditFocusDirective,
    AuditChangeDirective
  ]
})
export class CustomDirectiveModule { }
