import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { MamografiaComponent } from './mamografia.component';
import { PeleMamilosComponent } from './TermosComponents/pele-mamilos/pele-mamilos.component';
import { ParenquimaMamarioComponent } from './TermosComponents/parenquima-mamario/parenquima-mamario.component';
import { VascularizacaoParenquimaComponent } from './TermosComponents/vascularizacao-parenquima/vascularizacao-parenquima.component';
import { DistorcaoArquiteturalComponent } from './TermosComponents/distorcao-arquitetural/distorcao-arquitetural.component';
import { AssimetriaComponent } from './TermosComponents/assimetria/assimetria.component';
import { MgNodulosComponent } from './TermosComponents/mg-nodulos/mg-nodulos.component';
import { MgCalcificacoesComponent } from './TermosComponents/mg-calcificacoes/mg-calcificacoes.component';
import { MgLinfonodomegaliasComponent } from './TermosComponents/mg-linfonodomegalias/mg-linfonodomegalias.component';
import { MgIndicacaoComponent } from './TermosComponents/mg-indicacao/mg-indicacao.component';
import { LateralidadeModule } from 'src/app/utils/lateralidade/lateralidade.module';
import { MgTecnicaComponent } from './TermosComponents/mg-tecnica/mg-tecnica.component';
import { MgRecomendacaoComponent } from './TermosComponents/mg-recomendacao/mg-recomendacao.component';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';
import { MgHistoriaClinicaComponent } from './TermosComponents/mg-historia-clinica/mg-historia-clinica.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    LateralidadeModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  exports: [
    MamografiaComponent
  ],
  declarations: [
    MamografiaComponent,
    PeleMamilosComponent,
    ParenquimaMamarioComponent,
    VascularizacaoParenquimaComponent,
    DistorcaoArquiteturalComponent,
    AssimetriaComponent,
    MgNodulosComponent,
    MgCalcificacoesComponent,
    MgLinfonodomegaliasComponent,
    MgIndicacaoComponent,
    MgTecnicaComponent,
    MgRecomendacaoComponent,
    MgHistoriaClinicaComponent
  ]
})
export class MamografiaModule { }
