import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-alinhamento',
  templateUrl: './alinhamento.component.html',
  styleUrls: ['./alinhamento.component.css']
})
export class AlinhamentoComponent implements OnInit {

    @Output() termoSelecionado = new EventEmitter();
    @Output() termosGenericos = new EventEmitter();
    @Output() lesoesSelecionadas = new EventEmitter();
    @Output() listaCompleta = new EventEmitter();

    @ViewChild('rdNormal') rdNormal: MatRadioButton;
    @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

    @ViewChild('ckAcentuacaoCifose') ckAcentuacaoCifose: MatCheckbox;
    @ViewChild('ckEscoliose') ckEscoliose: MatCheckbox;
    @ViewChild('ckRotacaoAxial') ckRotacaoAxial: MatCheckbox;
    @ViewChild('ckEscolioseSinuosa') ckEscolioseSinuosa: MatCheckbox;
    @ViewChild('ckRetrolistese') ckRetrolistese: MatCheckbox;
    @ViewChild('ckAnterolistese') ckAnterolistese: MatCheckbox;

    @ViewChild('slLateralidadeEscoliose') slLateralidadeEscoliose: MatSelect;
    @ViewChild('slLateralidadeEscolioseSinuosaInicial') slLateralidadeEscolioseSinuosaInicial: MatSelect;
    @ViewChild('slLateralidadeEscolioseSinuosaSecundaria') slLateralidadeEscolioseSinuosaSecundaria: MatSelect;
    @ViewChild('slNivelEscolioseSinuosaInicial') slNivelEscolioseSinuosaInicial: MatSelect;
    @ViewChild('slNivelEscolioseSinuosaSecundaria') slNivelEscolioseSinuosaSecundaria: MatSelect;
    @ViewChild('slGrauAnterolistese') slGrauAnterolistese: MatSelect;
    @ViewChild('slNivelAnterolistese') slNivelAnterolistese: MatSelect;
    @ViewChild('slGrauRetrolistese') slGrauRetrolistese: MatSelect;
    @ViewChild('slNivelRetrolistese') slNivelRetrolistese: MatSelect;

    lateralidadeEscoliose: ArrayDropDown[] = [
      { value: 'direita', viewValue: 'Direita' },
      { value: 'esquerda', viewValue: 'Esquerda' }];

    lateralidadeEscolioseSinuosaInicial: ArrayDropDown[] = [
      { value: 'direita', viewValue: 'Direita' },
      { value: 'esquerda', viewValue: 'Esquerda' }];

    lateralidadeEscolioseSinuosaSecundaria: ArrayDropDown[] = [
      { value: 'direita', viewValue: 'Direita' },
      { value: 'esquerda', viewValue: 'Esquerda' }];

    nivelEscolioseSinuosaInicial: ArrayDropDown[] = [
      { value: 'torácico', viewValue: 'Torácico' },
      { value: 'toracolombar', viewValue: 'Toracolombar' }];

    nivelEscolioseSinuosaSecundaria: ArrayDropDown[] = [
      { value: 'toracolombar', viewValue: 'Toracolombar' },
      { value: 'lombar', viewValue: 'Lombar' }];

    grauAnterolistese: ArrayDropDown[]=[
      { value: 'I', viewValue: 'I' },
      { value: 'II', viewValue: 'II' },
      { value: 'III', viewValue: 'III' },
      { value: 'IV', viewValue: 'IV' },
      { value: 'V', viewValue: 'II' }]

    nivelAnterolistese: ArrayDropDown[]=[
      { value: 'C7', viewValue: 'C7' },
      { value: 'T1', viewValue: 'T1' },
      { value: 'T2', viewValue: 'T2' },
      { value: 'T3', viewValue: 'T3' },
      { value: 'T4', viewValue: 'T4' },
      { value: 'T5', viewValue: 'T5' },
      { value: 'T6', viewValue: 'T6' },
      { value: 'T7', viewValue: 'T7' },
      { value: 'T8', viewValue: 'T8' },
      { value: 'T9', viewValue: 'T9' },
      { value: 'T10', viewValue: 'T10' },
      { value: 'T11', viewValue: 'T11' },
      { value: 'T12', viewValue: 'T12' },
      { value: 'L1', viewValue: 'L1' }]

      grauRetrolistese: ArrayDropDown[]=[
        { value: 'Pequena', viewValue: 'Pequena' },
        { value: 'Moderada', viewValue: 'Moderada' },
        { value: 'Acentuada', viewValue: 'Acentuada' }]

      nivelRetrolistese: ArrayDropDown[]=[
        { value: 'C7', viewValue: 'C7' },
        { value: 'T1', viewValue: 'T1' },
        { value: 'T2', viewValue: 'T2' },
        { value: 'T3', viewValue: 'T3' },
        { value: 'T4', viewValue: 'T4' },
        { value: 'T5', viewValue: 'T5' },
        { value: 'T6', viewValue: 'T6' },
        { value: 'T7', viewValue: 'T7' },
        { value: 'T8', viewValue: 'T8' },
        { value: 'T9', viewValue: 'T9' },
        { value: 'T10', viewValue: 'T10' },
        { value: 'T11', viewValue: 'T11' },
        { value: 'T12', viewValue: 'T12' },
        { value: 'L1', viewValue: 'L1' }]

    showAndHideTerms: any;
    countLesoes = 0;
    isDisabled=true;

    constructor() { }

    ngOnInit() {
      this.showAndHideTerms = {
        sahEscoliose: false,
        sahEscolioseSinuosa: false,
        sahAnterolistese:false,
        sahRetrolistese:false
      }
    }

    clickDefault(){
      this.catchIDs();
    }
    clickNormal(){
        this.showAndHideTerms.sahEscoliose=false;
        this.showAndHideTerms.sahEscolioseSinuosa=false;
        this.showAndHideTerms.sahAnterolistese=false;
        this.showAndHideTerms.sahRetrolistese=false;
        this.ckEscoliose.checked=false;
        this.ckAcentuacaoCifose.checked=false;
        this.ckEscolioseSinuosa.checked=false;
        this.ckAnterolistese.checked=false;
        this.ckRetrolistese.checked=false;

        this.catchIDs();
    }

    changeSelect(){
      this.catchIDs();
    }

    clickEscoliose(){
      this.showAndHideTerms.sahEscoliose = !this.showAndHideTerms.sahEscoliose;
      this.catchIDs();
    }

    clickEscolioseSinuosa(){
      this.showAndHideTerms.sahEscolioseSinuosa = !this.showAndHideTerms.sahEscolioseSinuosa;
      this.catchIDs();
    }

    clickAnterolistese(){
      this.showAndHideTerms.sahAnterolistese = !this.showAndHideTerms.sahAnterolistese;
      this.catchIDs();
    }

    clickRetrolistese(){
      this.showAndHideTerms.sahRetrolistese = !this.showAndHideTerms.sahRetrolistese;
      this.catchIDs();
    }

    catchIDs(){
      this.countLesoes = 0;
      this.termoSelecionado.emit('Clean');

      if(this.ckAcentuacaoCifose.checked ||
        this.ckEscoliose.checked ||
        this.ckEscolioseSinuosa.checked ||
        this.ckRetrolistese.checked ||
        this.ckAnterolistese.checked){
          this.rdAlterado.checked=true;
          this.rdNormal.checked=false;
        }

      if(this.countLesoes > 0){
        this.emitAcentuacaoCifoseLesao();
      } else {
        this.emitAcentuacaoCifoseSimples();
      }
      if(this.countLesoes > 0){
        this.emitEscolioseLesao();
      } else {
        this.emitEscolioseSimples();
      }
      if(this.countLesoes > 0){
        this.emitEscolioseSinuosaLesao();
      } else {
        this.emitEscolioseSinuosaSimples();
      }
      if(this.countLesoes > 0){
        this.emitAnterolisteseLesao();
      } else {
        this.emitAnterolisteseSimples();
      }
      if(this.countLesoes > 0){
        this.emitRetrolisteseLesao();
      } else {
        this.emitRetrolisteseSimples();
      }
      if(!this.ckAcentuacaoCifose.checked &&
        !this.ckEscoliose.checked &&
        !this.ckEscolioseSinuosa.checked &&
        !this.ckRetrolistese.checked &&
        !this.ckAnterolistese.checked){
        this.termoSelecionado.emit(this.rdNormal.id);
        this.rdNormal.checked=true;
        this.rdAlterado.checked=false;
      }
      this.listaCompleta.emit();
    }

  emitAcentuacaoCifoseLesao(){
    if(this.ckAcentuacaoCifose.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAcentuacaoCifose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitAcentuacaoCifoseSimples(){
    if(this.ckAcentuacaoCifose.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAcentuacaoCifose.id);
    }
  }

  emitEscolioseLesao(){
    if(this.ckEscoliose.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEscoliose.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeEscoliose && this.slLateralidadeEscoliose.value){
        const objeto = {elemento: this.slLateralidadeEscoliose, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.ckRotacaoAxial && this.ckRotacaoAxial.value && this.ckRotacaoAxial.checked ){
        const objeto = {elemento: this.ckRotacaoAxial, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEscolioseSimples(){
    if(this.ckEscoliose.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEscoliose.id);

      if(this.slLateralidadeEscoliose && this.slLateralidadeEscoliose.value){
        const objeto = {elemento: this.slLateralidadeEscoliose, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }

      if(this.ckRotacaoAxial && this.ckRotacaoAxial.value && this.ckRotacaoAxial.checked ){
        const objeto = {elemento: this.ckRotacaoAxial, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitEscolioseSinuosaLesao(){
    if(this.ckEscolioseSinuosa.checked){
      this.countLesoes++;
      const objeto = { id: this.ckEscolioseSinuosa.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slLateralidadeEscolioseSinuosaInicial && this.slLateralidadeEscolioseSinuosaInicial.value){
        const objeto = {elemento: this.slLateralidadeEscolioseSinuosaInicial, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelEscolioseSinuosaInicial && this.slNivelEscolioseSinuosaInicial.value ){
        const objeto = {elemento: this.slNivelEscolioseSinuosaInicial, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slLateralidadeEscolioseSinuosaSecundaria && this.slLateralidadeEscolioseSinuosaSecundaria.value){
        const objeto = {elemento: this.slLateralidadeEscolioseSinuosaSecundaria, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelEscolioseSinuosaSecundaria && this.slNivelEscolioseSinuosaSecundaria.value){
        const objeto = {elemento: this.slNivelEscolioseSinuosaSecundaria, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEscolioseSinuosaSimples(){
    if(this.ckEscolioseSinuosa.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEscolioseSinuosa.id);

      if(this.slLateralidadeEscolioseSinuosaInicial && this.slLateralidadeEscolioseSinuosaInicial.value){
        const objeto = {elemento: this.slLateralidadeEscolioseSinuosaInicial, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelEscolioseSinuosaInicial && this.slNivelEscolioseSinuosaInicial.value ){
        const objeto = {elemento: this.slNivelEscolioseSinuosaInicial, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slLateralidadeEscolioseSinuosaSecundaria && this.slLateralidadeEscolioseSinuosaSecundaria.value){
        const objeto = {elemento: this.slLateralidadeEscolioseSinuosaSecundaria, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelEscolioseSinuosaSecundaria && this.slNivelEscolioseSinuosaSecundaria.value){
        const objeto = {elemento: this.slNivelEscolioseSinuosaSecundaria, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAnterolisteseLesao(){
    if(this.ckAnterolistese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckAnterolistese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slGrauAnterolistese && this.slGrauAnterolistese.value){
        const objeto = {elemento: this.slGrauAnterolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelAnterolistese && this.slNivelAnterolistese.value){
        const objeto = {elemento: this.slNivelAnterolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAnterolisteseSimples(){
    if(this.ckAnterolistese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAnterolistese.id);

      if(this.slGrauAnterolistese && this.slGrauAnterolistese.value){
        const objeto = {elemento: this.slGrauAnterolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelAnterolistese && this.slNivelAnterolistese.value ){
        const objeto = {elemento: this.slNivelAnterolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitRetrolisteseLesao(){
    if(this.ckRetrolistese.checked){
      this.countLesoes++;
      const objeto = { id: this.ckRetrolistese.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if(this.slGrauRetrolistese && this.slGrauRetrolistese.value){
        const objeto = {elemento: this.slGrauRetrolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelRetrolistese && this.slNivelRetrolistese.value){
        const objeto = {elemento: this.slNivelRetrolistese, isPrimeiraLesao: false}
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitRetrolisteseSimples(){
    if(this.ckRetrolistese.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRetrolistese.id);

      if(this.slGrauRetrolistese && this.slGrauRetrolistese.value){
        const objeto = {elemento: this.slGrauRetrolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
      if(this.slNivelRetrolistese && this.slNivelRetrolistese.value ){
        const objeto = {elemento: this.slNivelRetrolistese, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }
  }

}

