import { Component, EventEmitter, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-indicacao-cranio',
  templateUrl: './indicacao-cranio.component.html',
  styleUrls: ['./indicacao-cranio.component.css']
})
export class IndicacaoCranioComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCefaleia') rdCefaleia: MatRadioButton;
  @ViewChild('rdTrauma') rdTrauma: MatRadioButton;
  @ViewChild('rdNivelCon') rdNivelCon: MatRadioButton;
  @ViewChild('rdCrise') rdCrise: MatRadioButton;
  @ViewChild('rdAVC') rdAVC: MatRadioButton;
  @ViewChild('rdOutro') rdOutro: MatRadioButton;
  @ViewChild('inCampoLivre') inCampoLivre: ElementRef;

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahOutro: true,
    };
  }

  clickRadio() {
    this.showAndHideTerms.sahOutro = false;
    this.catchIDs();
  }

  clickOutro() {
    this.showAndHideTerms.sahOutro = true;
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdCefaleia.checked) {
      this.termoSelecionado.emit(this.rdCefaleia.id);
    }

    if (this.rdTrauma.checked) {
      this.termoSelecionado.emit(this.rdTrauma.id);
    }

    if (this.rdNivelCon.checked) {
      this.termoSelecionado.emit(this.rdNivelCon.id);
    }

    if (this.rdCrise.checked) {
      this.termoSelecionado.emit(this.rdCrise.id);
    }

    if (this.rdAVC.checked) {
      this.termoSelecionado.emit(this.rdAVC.id);
    }

    if (this.rdOutro.checked) {
      this.termoSelecionado.emit(this.rdOutro.id);

      if (this.inCampoLivre && this.inCampoLivre.nativeElement.value) {
        const objeto = { elemento: this.inCampoLivre.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
