import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {LadoMama, Localizacao} from '../../../mama/TermosComponents/exame-comparativo/exame-comparativo.component';
import {MatRadioButton, MatSelect} from '@angular/material';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-liquido-livre',
  templateUrl: './liquido-livre.component.html',
  styleUrls: ['./liquido-livre.component.css']
})
export class LiquidoLivreComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdAusente') rdAusente: MatRadioButton;
  @ViewChild('rdPresente') rdPresente: MatRadioButton;

  @ViewChild('slQuantidade') slQuantidade: MatSelect;
  @ViewChild('slConteudo') slConteudo: MatSelect;
  @ViewChild('slLocal') slLocal: MatSelect;

  quantidades: ArrGenerico[] = [
    {value: 'Mínima', viewValue: 'Mínima'},
    {value: 'Pequena', viewValue: 'Pequena'},
    {value: 'Moderada', viewValue: 'Moderada'},
    {value: 'Acentuada', viewValue: 'Acentuada'}
  ];

  conteudos: ArrGenerico[] = [
    {value: 'anecóico', viewValue: 'Anecóico'},
    {value: 'com debris', viewValue: 'Debris'},
    {value: 'espesso', viewValue: 'Espesso'}
  ];

  locais: ArrGenerico[] = [
    {value: 'no fundo de saco', viewValue: 'Fundo de saco'},
    {value: 'predominando na região anexial direita', viewValue: 'Região anexial direita'},
    {value: 'predominando na região anexial à esquerda', viewValue: 'Região anexial esquerda'}
  ];

  showAndHideTerms: any;

  quantidadeSelected = this.quantidades[0].value;
  conteudoSelected = this.conteudos[0].value;
  localSelected = this.locais[0].value;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAusente: true
    };
  }

  clickAusente() {
    this.showAndHideTerms.sahAusente = false;
    this.catchIDs();
  }

  clickPresente() {
    this.showAndHideTerms.sahAusente = true;
    this.catchIDs();
  }

  changeSelect() {
    this.alteraAusentePresente();
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdAusente.checked) {
      this.termoSelecionado.emit(this.rdAusente.id);
    }

    if (this.rdPresente.checked) {
      this.termoSelecionado.emit(this.rdPresente.id);

      if (this.slQuantidade != undefined && this.slQuantidade.value != undefined) {
        const objeto = {elemento: this.slQuantidade, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 419, value: this.quantidadeSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slConteudo != undefined && this.slConteudo.value != undefined) {
        const objeto = {elemento: this.slConteudo, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 420, value: this.conteudoSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slLocal != undefined && this.slLocal.value != undefined) {
        const objeto = {elemento: this.slLocal, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 421, value: this.localSelected }
        const objeto = {elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  alteraAusentePresente() {
    if ((this.slQuantidade && this.slQuantidade.value) ||
        (this.slConteudo && this.slConteudo.value) ||
        (this.slLocal && this.slLocal.value)
    ) {
      this.rdPresente.checked = true;
    } else {
      this.rdAusente.checked = true;
      this.rdPresente.checked = true;
    }
  }

}
