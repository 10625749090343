import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderServiceService } from './loader-service.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorServiceService {

  constructor(private loaderService: LoaderServiceService, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>>{
    this.showLoader();

    return next.handle(req).pipe(tap((event: HttpEvent<any>) =>{
      if(event instanceof HttpResponse){
        this.onEnd();
      }
      },
      (err: any) => {
        this.onEnd();
        if (err.status == 401) {
          this.authService.logout();
        }
    }))

  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
