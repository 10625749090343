import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-lig-colateral-medial',
  templateUrl: './lig-colateral-medial.component.html',
  styleUrls: ['./lig-colateral-medial.component.css']
})
export class LigColateralMedialComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  @ViewChild('rdFibroLcm') rdFibroLcm: MatRadioButton;
  @ViewChild('rdParcialLcm') rdParcialLcm: MatRadioButton;
  @ViewChild('rdCompletaLcm') rdCompletaLcm: MatRadioButton;

  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slLocalizacaoSimples') slLocalizacaoSimples: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço proximal' },
    { value: 'no terço médio', viewValue: 'Terço médio' },
    { value: 'no terço distal', viewValue: 'Terço distal' },
    { value: 'difusa', viewValue: 'Difusa' }
  ];

  localizacoesSimples: ArrayDropDown[] = [
    { value: 'proximais', viewValue: 'Proximais' },
    { value: 'médias', viewValue: 'Médias' },
    { value: 'distais', viewValue: 'Distais' }
  ];


  showAndHideTerms: any;
  countLesoes = 0;


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedial: true,
      sahLocalizacao: true,
      sahLocalizacaoSimples: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahMedial = false;
    this.showAndHideTerms.sahLocalizacao = false;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahMedial = true;
    this.showAndHideTerms.sahLocalizacao = true;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.catchIDs();
  }

  
  clickLocalizacao() {
    this.showAndHideTerms.sahLocalizacao = true;
    this.showAndHideTerms.sahLocalizacaoSimples = false;
    this.verificaLesoes();
    this.catchIDs();
  }

  clickLocalizacaoSimples(){
    this.showAndHideTerms.sahLocalizacaoSimples = true;
    this.showAndHideTerms.sahLocalizacao = false;
    this.verificaLesoes();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    //MEDIAL
    if (this.rdAlterado.checked) {

      if(this.rdFibroLcm && this.rdFibroLcm.checked){
        this.termoSelecionado.emit(this.rdFibroLcm.value);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.rdParcialLcm && this.rdParcialLcm.checked){
        this.termoSelecionado.emit(this.rdParcialLcm.value);

        if (this.slLocalizacao && this.slLocalizacao.value) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }

      if(this.rdCompletaLcm && this.rdCompletaLcm.checked){
        this.termoSelecionado.emit(this.rdCompletaLcm.value);

        if (this.slLocalizacaoSimples && this.slLocalizacaoSimples.value) {
          const objeto = { elemento: this.slLocalizacaoSimples, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    }

    this.listaCompleta.emit();
  }

  verificaLesoes() {
    if ((this.rdCompletaLcm && this.rdCompletaLcm.checked) || 
        (this.rdFibroLcm && this.rdFibroLcm.checked)  || 
        (this.rdParcialLcm && this.rdParcialLcm.checked)) {
          this.rdAlterado.checked = true;
    }
  }

}
