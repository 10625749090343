import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatRadioButton } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'app-ovario-direito-doppler',
  templateUrl: './ovario-direito-doppler.component.html',
  styleUrls: ['./ovario-direito-doppler.component.css']
})
export class OvarioDireitoDopplerComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAumentado') rdAumentado: MatRadioButton;
  @ViewChild('rdNaoCaracterizado') rdNaoCaracterizado: MatRadioButton;
  @ViewChild('rdPolicistico') rdPolicistico: MatRadioButton;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  @ViewChild('inMedida3') inMedida3: ElementRef;
  @ViewChild('inVolume') inVolume: ElementRef;

  @ViewChild('ckCistoSimples') ckCistoSimples: MatCheckbox;
  @ViewChild('ckDoppler') ckDoppler: MatCheckbox;
  @ViewChild('inMedidaCistoSimples') inMedidaCistoSimples: ElementRef;

  @ViewChild('ckCistoLuteo') ckCistoLuteo: MatCheckbox;
  @ViewChild('ckDopplerLuteo') ckDopplerLuteo: MatCheckbox;
  @ViewChild('rdDopplerSem') rdDopplerSem: MatRadioButton;
  @ViewChild('rdDopplerCom') rdDopplerCom: MatRadioButton;
  @ViewChild('inMedidaCistoLuteo') inMedidaCistoLuteo: ElementRef;

  @ViewChild('ckCistoFuncional') ckCistoFuncional: MatCheckbox;
  @ViewChild('ckDopplerFun') ckDopplerFun: MatCheckbox;
  @ViewChild('inMedidaCistoFuncional') inMedidaCistoFuncional: ElementRef;

  @ViewChild('ckCistoHemorragico') ckCistoHemorragico: MatCheckbox;
  @ViewChild('ckDopplerHemorragico') ckDopplerHemorragico: MatCheckbox;
  @ViewChild('rdDopplerSemHem') rdDopplerSemHem: MatRadioButton;
  @ViewChild('rdDopplerComHem') rdDopplerComHem: MatRadioButton;
  @ViewChild('inMedidaCistoHemorragico1') inMedidaCistoHemorragico1: ElementRef;
  @ViewChild('inMedidaCistoHemorragico2') inMedidaCistoHemorragico2: ElementRef;
  @ViewChild('inMedidaCistoHemorragico3') inMedidaCistoHemorragico3: ElementRef;

  @ViewChild('ckCistoEndometrioma') ckCistoEndometrioma: MatCheckbox;
  @ViewChild('ckDopplerEndometrioma') ckDopplerEndometrioma: MatCheckbox;
  @ViewChild('rdDopplerSemEnd') rdDopplerSemEnd: MatRadioButton;
  @ViewChild('rdDopplerComEnd') rdDopplerComEnd: MatRadioButton;
  @ViewChild('inMedidaCistoEndometrioma1') inMedidaCistoEndometrioma1: ElementRef;
  @ViewChild('inMedidaCistoEndometrioma2') inMedidaCistoEndometrioma2: ElementRef;
  @ViewChild('inMedidaCistoEndometrioma3') inMedidaCistoEndometrioma3: ElementRef;

  countLesoes = 0;
  showAndHideTerms: any;
  phVolume = 'Volume';
  unidadeMedida: string;

  constructor(
    private _validacoes: Validacoes,
    private _toastr: ToastrManager) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true,
      sahCisto: true,
      sahCistoSimples: false,
      sahCistoLuteo: false,
      sahCistoFuncional: false,
      sahCistoHemorragico: false,
      sahCistoEndometrioma: false,
      sahDopplerLuteo: false,
      sahDopplerHemorragico: false,
      sahDopplerEndometrioma: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahCisto = true;
    this.catchIDs();
  }

  clickAumentado() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahCisto = true;
    this.catchIDs();
  }

  clickNaoCaracterizado() {
    this.showAndHideTerms.sahMedida = false;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahCistoSimples = false;
    this.showAndHideTerms.sahCistoLuteo = false;
    this.showAndHideTerms.sahCistoFuncional = false;
    this.showAndHideTerms.sahCistoHemorragico = false;
    this.showAndHideTerms.sahCistoEndometrioma = false;
    this.catchIDs();
  }

  clickPolicistico() {
    this.showAndHideTerms.sahMedida = true;
    this.showAndHideTerms.sahCisto = false;
    this.showAndHideTerms.sahCistoSimples = false;
    this.showAndHideTerms.sahCistoLuteo = false;
    this.showAndHideTerms.sahCistoFuncional = false;
    this.showAndHideTerms.sahCistoHemorragico = false;
    this.showAndHideTerms.sahCistoEndometrioma = false;
    this.catchIDs();
  }

  clickCistoSimples() {
    if (this.showAndHideTerms.sahCistoSimples == false) {
      this.showAndHideTerms.sahCistoSimples = true;
    } else {
      this.showAndHideTerms.sahCistoSimples = false;
    }
    this.catchIDs();
  }

  clickCistoLuteo() {
    if (this.showAndHideTerms.sahCistoLuteo == false) {
      this.showAndHideTerms.sahCistoLuteo = true;
    } else {
      this.showAndHideTerms.sahCistoLuteo = false;
    }
    this.catchIDs();
  }

  clickCistoFuncional() {
    if (this.showAndHideTerms.sahCistoFuncional == false) {
      this.showAndHideTerms.sahCistoFuncional = true;
    } else {
      this.showAndHideTerms.sahCistoFuncional = false;
    }
    this.catchIDs();
  }

  clickCistoHemorragico() {
    if (this.showAndHideTerms.sahCistoHemorragico == false) {
      this.showAndHideTerms.sahCistoHemorragico = true;
    } else {
      this.showAndHideTerms.sahCistoHemorragico = false;
    }
    this.catchIDs();
  }

  clickCistoEndometrioma() {
    if (this.showAndHideTerms.sahCistoEndometrioma == false) {
      this.showAndHideTerms.sahCistoEndometrioma = true;
    } else {
      this.showAndHideTerms.sahCistoEndometrioma = false;
    }
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  clickDopplerLuteo() {
    if (this.showAndHideTerms.sahDopplerLuteo == false) {
      this.showAndHideTerms.sahDopplerLuteo = true;
    } else {
      this.showAndHideTerms.sahDopplerLuteo = false;
    }
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  clickDopplerHemorragico() {
    if (this.showAndHideTerms.sahDopplerHemorragico == false) {
      this.showAndHideTerms.sahDopplerHemorragico = true;
    } else {
      this.showAndHideTerms.sahDopplerHemorragico = false;
    }
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }
  
  clickDopplerEndometrioma() {
    if (this.showAndHideTerms.sahDopplerEndometrioma == false) {
      this.showAndHideTerms.sahDopplerEndometrioma = true;
    } else {
      this.showAndHideTerms.sahDopplerEndometrioma = false;
    }
    this.catchIDs();
  }

  clickDoppler() {
    this.catchIDs();
  }

  clickDopplerSem() {
    this.catchIDs();
  }

  clickDopplerCom() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;
    this.phVolume = 'Volume';

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      this.formataMedindo();
      this.emitCistoLesao();
    }

    if (this.rdAumentado.checked) {
      this.termoSelecionado.emit(this.rdAumentado.id);

      this.formataMedindo();
      //this.emitCistoLesao();
      this.emitCistoAumentado();
    }

    if (this.rdNaoCaracterizado.checked) {
      this.termoSelecionado.emit(this.rdNaoCaracterizado.id);
    }

    if (this.rdPolicistico.checked) {
      this.termoSelecionado.emit(this.rdPolicistico.id);

      this.formataMedindo();
    }


    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitCistoAumentado() {
    if (this.ckCistoSimples !== undefined && this.ckCistoSimples.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCistoSimples.id);

      if (this.ckDoppler && this.ckDoppler.checked) {
        this.termoSelecionado.emit(3919);
      }

      if (this.inMedidaCistoSimples != undefined &&
        this.inMedidaCistoSimples.nativeElement.id != undefined &&
        this.inMedidaCistoSimples.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoSimples.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckCistoLuteo !== undefined && this.ckCistoLuteo.checked) {
      if (this.countLesoes < 1) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckCistoLuteo.id);

        if (this.rdDopplerSem && this.rdDopplerSem.checked) {
          this.termoSelecionado.emit(3920);
        }
  
        if (this.rdDopplerCom && this.rdDopplerCom.checked) {
          this.termoSelecionado.emit(3921);
        }

        if (this.inMedidaCistoLuteo != undefined &&
          this.inMedidaCistoLuteo.nativeElement.id != undefined &&
          this.inMedidaCistoLuteo.nativeElement.value != '') {

          const objeto = { elemento: this.inMedidaCistoLuteo.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      } else {
        this.countLesoes++;
        const objeto = { id: this.ckCistoLuteo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.rdDopplerSem && this.rdDopplerSem.checked) {
          const objeto = { id: 3920, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          const objeto = { id: 3921, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

        if (this.inMedidaCistoLuteo != undefined &&
          this.inMedidaCistoLuteo.nativeElement.id != undefined &&
          this.inMedidaCistoLuteo.nativeElement.value != '') {

          const objeto = { elemento: this.inMedidaCistoLuteo.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }

    if (this.ckCistoFuncional !== undefined && this.ckCistoFuncional.checked) {
      if (this.countLesoes < 1) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckCistoFuncional.id);

        if (this.ckDopplerFun && this.ckDopplerFun.checked) {
          this.termoSelecionado.emit(3919);
        }

        if (this.inMedidaCistoFuncional != undefined &&
          this.inMedidaCistoFuncional.nativeElement.id != undefined &&
          this.inMedidaCistoFuncional.nativeElement.value != '') {

          const objeto = { elemento: this.inMedidaCistoFuncional.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      } else {
        this.countLesoes++;
        const objeto = { id: this.ckCistoFuncional.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.ckDopplerFun && this.ckDopplerFun.checked) {
          const objeto = { id: 3919, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

        if (this.inMedidaCistoFuncional != undefined &&
          this.inMedidaCistoFuncional.nativeElement.id != undefined &&
          this.inMedidaCistoFuncional.nativeElement.value != '') {

          const objeto = { elemento: this.inMedidaCistoFuncional.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }

    if (this.ckCistoHemorragico !== undefined && this.ckCistoHemorragico.checked) {
      if (this.countLesoes < 1) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckCistoHemorragico.id);

        if (this.rdDopplerSemHem && this.rdDopplerSemHem.checked) {
          this.termoSelecionado.emit(3920);
        } else {
          this.termoSelecionado.emit(3921);
        }

        this.formataMedindoCistoHemorragico(true);
      } else {
        this.countLesoes++;
        const objeto = { id: this.ckCistoHemorragico.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.rdDopplerSemHem && this.rdDopplerSemHem.checked) {
          const objeto = { id: 3920, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          const objeto = { id: 3921, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

        this.formataMedindoCistoHemorragico(false);
      }
    }

    if (this.ckCistoEndometrioma !== undefined && this.ckCistoEndometrioma.checked) {
      if (this.countLesoes < 1) {
        this.countLesoes++;
        this.termoSelecionado.emit(this.ckCistoEndometrioma.id);

        if (this.rdDopplerSemEnd && this.rdDopplerSemEnd.checked) {
          this.termoSelecionado.emit(3920);
        } else {
          this.termoSelecionado.emit(3921);
        }

        this.formataMedindoCistoEndometrioma(true);
      } else {
        this.countLesoes++;
        const objeto = { id: this.ckCistoEndometrioma.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.rdDopplerSemEnd && this.rdDopplerSemEnd.checked) {
          const objeto = { id: 3920, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        } else {
          const objeto = { id: 3921, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

        this.formataMedindoCistoEndometrioma(false);
      }
    }
  }

  emitCistoLesao() {
    if (this.ckCistoSimples !== undefined && this.ckCistoSimples.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistoSimples.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDoppler && this.ckDoppler.checked) {
        const objeto = { id: 3919, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.inMedidaCistoSimples != undefined &&
        this.inMedidaCistoSimples.nativeElement.id != undefined &&
        this.inMedidaCistoSimples.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoSimples.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckCistoLuteo !== undefined && this.ckCistoLuteo.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistoLuteo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdDopplerSem && this.rdDopplerSem.checked) {
        const objeto = { id: 3920, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.rdDopplerCom && this.rdDopplerCom.checked) {
        const objeto = { id: 3921, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.inMedidaCistoLuteo != undefined &&
        this.inMedidaCistoLuteo.nativeElement.id != undefined &&
        this.inMedidaCistoLuteo.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoLuteo.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckCistoFuncional !== undefined && this.ckCistoFuncional.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistoFuncional.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDopplerFun && this.ckDopplerFun.checked) {
        const objeto = { id: 3919, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.inMedidaCistoFuncional != undefined &&
        this.inMedidaCistoFuncional.nativeElement.id != undefined &&
        this.inMedidaCistoFuncional.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaCistoFuncional.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.ckCistoHemorragico !== undefined && this.ckCistoHemorragico.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistoHemorragico.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdDopplerSemHem && this.rdDopplerSemHem.checked) {
        const objeto = { id: 3920, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.rdDopplerComHem && this.rdDopplerComHem.checked) {
        const objeto = { id: 3921, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      this.formataMedindoCistoHemorragico(false);
    }

    if (this.ckCistoEndometrioma !== undefined && this.ckCistoEndometrioma.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckCistoEndometrioma.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdDopplerSemEnd && this.rdDopplerSemEnd.checked) {
        const objeto = { id: 3920, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      if (this.rdDopplerComEnd && this.rdDopplerComEnd.checked) {
        const objeto = { id: 3921, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
      }

      this.formataMedindoCistoEndometrioma(false);
    }
  }

  formataMedindo() {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedida1 != undefined &&
      this.inMedida1.nativeElement.id != undefined &&
      this.inMedida1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedida1.nativeElement.value);
      resultado = this.inMedida1.nativeElement.value;
    }

    if (this.inMedida2 != undefined &&
      this.inMedida2.nativeElement.id != undefined &&
      this.inMedida2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
        resultado = resultado * this.inMedida2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida2.nativeElement.value);
        resultado = this.inMedida2.nativeElement.value;
      }
    }

    if (this.inMedida3 != undefined &&
      this.inMedida3.nativeElement.id != undefined &&
      this.inMedida3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
        resultado = resultado * this.inMedida3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedida3.nativeElement.value);
        resultado = this.inMedida3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;
      this.phVolume = resultado.toFixed(2).toString();

       //Alerta de volume aumentado
       if (this.rdNormal && this.rdNormal.checked && +resultado > 12 || +this.inVolume.nativeElement.value > 12) {
        this._toastr.infoToastr('O volume do ovário esquerdo é maior que 12cm³');
      }

      if (this.inVolume.nativeElement.value) {
        medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(this.inVolume.nativeElement.value) + ' cm³)';
      } else {
        medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' cm³)';
      }

      const objetoElemento = { id: 3908, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoCistoHemorragico(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaCistoHemorragico1 != undefined &&
      this.inMedidaCistoHemorragico1.nativeElement.id != undefined &&
      this.inMedidaCistoHemorragico1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoHemorragico1.nativeElement.value);
    }

    if (this.inMedidaCistoHemorragico2 != undefined &&
      this.inMedidaCistoHemorragico2.nativeElement.id != undefined &&
      this.inMedidaCistoHemorragico2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCistoHemorragico2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoHemorragico2.nativeElement.value);
      }
    }

    if (this.inMedidaCistoHemorragico3 != undefined &&
      this.inMedidaCistoHemorragico3.nativeElement.id != undefined &&
      this.inMedidaCistoHemorragico3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCistoHemorragico3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoHemorragico3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 3916, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoCistoEndometrioma(isPrimeiraLesao) {
    let medindoValor = '';

    if (this.inMedidaCistoEndometrioma1 != undefined &&
      this.inMedidaCistoEndometrioma1.nativeElement.id != undefined &&
      this.inMedidaCistoEndometrioma1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoEndometrioma1.nativeElement.value);
    }

    if (this.inMedidaCistoEndometrioma2 != undefined &&
      this.inMedidaCistoEndometrioma2.nativeElement.id != undefined &&
      this.inMedidaCistoEndometrioma2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCistoEndometrioma2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoEndometrioma2.nativeElement.value);
      }
    }

    if (this.inMedidaCistoEndometrioma3 != undefined &&
      this.inMedidaCistoEndometrioma3.nativeElement.id != undefined &&
      this.inMedidaCistoEndometrioma3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaCistoEndometrioma3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaCistoEndometrioma3.nativeElement.value);
      }
    }

    if (medindoValor != '') {

      const objetoElemento = { id: 3918, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }
}
