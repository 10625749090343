import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-anatomia-fetal-gemelar',
  templateUrl: './anatomia-fetal-gemelar.component.html',
  styleUrls: ['./anatomia-fetal-gemelar.component.css']
})
export class AnatomiaFetalGemelarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('slCranio') slCranio: MatSelect;
  @ViewChild('slSistema') slSistema: MatSelect;
  @ViewChild('slTorax') slTorax: MatSelect;
  @ViewChild('slCoracao') slCoracao: MatSelect;
  @ViewChild('slPrdAbdominal') slPrdAbdominal: MatSelect;
  @ViewChild('slAbdome') slAbdome: MatSelect;
  @ViewChild('slAparelho') slAparelho: MatSelect;
  @ViewChild('slCordao') slCordao: MatSelect;

  @ViewChild('inOutrasCra') inOutrasCra: ElementRef;
  @ViewChild('inOutrasSis') inOutrasSis: ElementRef;
  @ViewChild('inOutrasTor') inOutrasTor: ElementRef;
  @ViewChild('inOutrasCor') inOutrasCor: ElementRef;
  @ViewChild('inOutrasPrd') inOutrasPrd: ElementRef;
  @ViewChild('inOutrasAbd') inOutrasAbd: ElementRef;
  @ViewChild('inOutrasApr') inOutrasApr: ElementRef;
  @ViewChild('inOutrasCrd') inOutrasCrd: ElementRef;

  //2

  @ViewChild('slCranio2') slCranio2: MatSelect;
  @ViewChild('slSistema2') slSistema2: MatSelect;
  @ViewChild('slTorax2') slTorax2: MatSelect;
  @ViewChild('slCoracao2') slCoracao2: MatSelect;
  @ViewChild('slPrdAbdominal2') slPrdAbdominal2: MatSelect;
  @ViewChild('slAbdome2') slAbdome2: MatSelect;
  @ViewChild('slAparelho2') slAparelho2: MatSelect;
  @ViewChild('slCordao2') slCordao2: MatSelect;

  @ViewChild('inOutrasCra2') inOutrasCra2: ElementRef;
  @ViewChild('inOutrasSis2') inOutrasSis2: ElementRef;
  @ViewChild('inOutrasTor2') inOutrasTor2: ElementRef;
  @ViewChild('inOutrasCor2') inOutrasCor2: ElementRef;
  @ViewChild('inOutrasPrd2') inOutrasPrd2: ElementRef;
  @ViewChild('inOutrasAbd2') inOutrasAbd2: ElementRef;
  @ViewChild('inOutrasApr2') inOutrasApr2: ElementRef;
  @ViewChild('inOutrasCrd2') inOutrasCrd2: ElementRef;

  //3

  @ViewChild('slCranio3') slCranio3: MatSelect;
  @ViewChild('slSistema3') slSistema3: MatSelect;
  @ViewChild('slTorax3') slTorax3: MatSelect;
  @ViewChild('slCoracao3') slCoracao3: MatSelect;
  @ViewChild('slPrdAbdominal3') slPrdAbdominal3: MatSelect;
  @ViewChild('slAbdome3') slAbdome3: MatSelect;
  @ViewChild('slAparelho3') slAparelho3: MatSelect;
  @ViewChild('slCordao3') slCordao3: MatSelect;

  @ViewChild('inOutrasCra3') inOutrasCra3: ElementRef;
  @ViewChild('inOutrasSis3') inOutrasSis3: ElementRef;
  @ViewChild('inOutrasTor3') inOutrasTor3: ElementRef;
  @ViewChild('inOutrasCor3') inOutrasCor3: ElementRef;
  @ViewChild('inOutrasPrd3') inOutrasPrd3: ElementRef;
  @ViewChild('inOutrasAbd3') inOutrasAbd3: ElementRef;
  @ViewChild('inOutrasApr3') inOutrasApr3: ElementRef;
  @ViewChild('inOutrasCrd3') inOutrasCrd3: ElementRef;

  //4

  @ViewChild('slCranio4') slCranio4: MatSelect;
  @ViewChild('slSistema4') slSistema4: MatSelect;
  @ViewChild('slTorax4') slTorax4: MatSelect;
  @ViewChild('slCoracao4') slCoracao4: MatSelect;
  @ViewChild('slPrdAbdominal4') slPrdAbdominal4: MatSelect;
  @ViewChild('slAbdome4') slAbdome4: MatSelect;
  @ViewChild('slAparelho4') slAparelho4: MatSelect;
  @ViewChild('slCordao4') slCordao4: MatSelect;

  @ViewChild('inOutrasCra4') inOutrasCra4: ElementRef;
  @ViewChild('inOutrasSis4') inOutrasSis4: ElementRef;
  @ViewChild('inOutrasTor4') inOutrasTor4: ElementRef;
  @ViewChild('inOutrasCor4') inOutrasCor4: ElementRef;
  @ViewChild('inOutrasPrd4') inOutrasPrd4: ElementRef;
  @ViewChild('inOutrasAbd4') inOutrasAbd4: ElementRef;
  @ViewChild('inOutrasApr4') inOutrasApr4: ElementRef;
  @ViewChild('inOutrasCrd4') inOutrasCrd4: ElementRef;


  showAndHideTerms: any;
  showAndHideTerms2: any;
  showAndHideTerms3: any;
  showAndHideTerms4: any;
  countLesoes = 0;
  fetoSelected: number = 1;
  showAndHideFetos: any;

  cranioDefault: string;
  sistemaDefault: string;
  toraxDefault: string;
  coracaoDefault: string;
  prdDefault: string;
  abdDefault: string;
  aparelhoDefault: string;
  cordaoDefault: string;

  cranioDefault2: string;
  sistemaDefault2: string;
  toraxDefault2: string;
  coracaoDefault2: string;
  prdDefault2: string;
  abdDefault2: string;
  aparelhoDefault2: string;
  cordaoDefault2: string;

  cranioDefault3: string;
  sistemaDefault3: string;
  toraxDefault3: string;
  coracaoDefault3: string;
  prdDefault3: string;
  abdDefault3: string;
  aparelhoDefault3: string;
  cordaoDefault3: string;

  cranioDefault4: string;
  sistemaDefault4: string;
  toraxDefault4: string;
  coracaoDefault4: string;
  prdDefault4: string;
  abdDefault4: string;
  aparelhoDefault4: string;
  cordaoDefault4: string;

  cranio: ArrayDropDown[] = [
    { value: 'de forma normal, contorno regular.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  sistema: ArrayDropDown[] = [
    { value: 'estruturas da linha média presentes, córtex de aspecto habitual, ventrículos cerebrais sem dilatações.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  torax: ArrayDropDown[] = [
    { value: 'pulmões homogêneos, com ecogenicidade habitual. Formação costal simétrica e proporcional.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  coracao: ArrayDropDown[] = [
    { value: 'Situs solitus, quatro câmaras cardíacas de aspecto habitual; vias de saída sem alterações; septo interventricular aparentemente íntegro.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  paredeAbdominal: ArrayDropDown[] = [
    { value: 'íntegra.', viewValue: 'Íntegra' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  abdome: ArrayDropDown[] = [
    { value: 'estômago identificado em topografia habitual.', viewValue: 'Aparentemente Normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  aparelhoUrinario: ArrayDropDown[] = [
    { value: 'rins com parênquima normal, sem dilatações das pelves renais, bexiga urinária em topografia usual e de dimensões normais.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  cordaoUmbilical: ArrayDropDown[] = [
    { value: 'identificam-se 3 vasos no cordão umbilical.', viewValue: 'Aparentemente normal' },
    { value: 'Outras alterações: ', viewValue: 'Outras alterações' }
  ];

  constructor(private _common: CommonService) { }

  ngOnInit() {

    this.showAndHideTerms = {
      sahAlteradoCra: false,
      sahAlteradoSis: false,
      sahAlteradoTor: false,
      sahAlteradoCor: false,
      sahAlteradoPrd: false,
      sahAlteradoAbd: false,
      sahAlteradoApr: false,
      sahAlteradoCrd: false
    }

    this.showAndHideTerms2 = {
      sahAlteradoCra: false,
      sahAlteradoSis: false,
      sahAlteradoTor: false,
      sahAlteradoCor: false,
      sahAlteradoPrd: false,
      sahAlteradoAbd: false,
      sahAlteradoApr: false,
      sahAlteradoCrd: false
    }

    this.showAndHideTerms3 = {
      sahAlteradoCra: false,
      sahAlteradoSis: false,
      sahAlteradoTor: false,
      sahAlteradoCor: false,
      sahAlteradoPrd: false,
      sahAlteradoAbd: false,
      sahAlteradoApr: false,
      sahAlteradoCrd: false
    }

    this.showAndHideTerms4 = {
      sahAlteradoCra: false,
      sahAlteradoSis: false,
      sahAlteradoTor: false,
      sahAlteradoCor: false,
      sahAlteradoPrd: false,
      sahAlteradoAbd: false,
      sahAlteradoApr: false,
      sahAlteradoCrd: false
    }

    this.showAndHideFetos = {
      feto1: true,
      feto2: false,
      feto3: false,
      feto4: false,
    };

    this.slCranio.value = '**';
    this.slSistema.value = '**';
    this.slTorax.value = '**';
    this.slCoracao.value = '**';
    this.slPrdAbdominal.value = '**';
    this.slAbdome.value = '**';
    this.slAparelho.value = '**';
    this.slCordao.value = '**';

    this.slCranio2.value = '**';
    this.slSistema2.value = '**';
    this.slTorax2.value = '**';
    this.slCoracao2.value = '**';
    this.slPrdAbdominal2.value = '**';
    this.slAbdome2.value = '**';
    this.slAparelho2.value = '**';
    this.slCordao2.value = '**';

    this.slCranio3.value = '**';
    this.slSistema3.value = '**';
    this.slTorax3.value = '**';
    this.slCoracao3.value = '**';
    this.slPrdAbdominal3.value = '**';
    this.slAbdome3.value = '**';
    this.slAparelho3.value = '**';
    this.slCordao3.value = '**';

    this.slCranio4.value = '**';
    this.slSistema4.value = '**';
    this.slTorax4.value = '**';
    this.slCoracao4.value = '**';
    this.slPrdAbdominal4.value = '**';
    this.slAbdome4.value = '**';
    this.slAparelho4.value = '**';
    this.slCordao4.value = '**';

    this._common.posicaoFeto.subscribe((feto: any) => {
      this.fetoSelected = feto;
      this.showAndHideFetos = {
        feto1: feto === 1,
        feto2: feto === 2,
        feto3: feto === 3,
        feto4: feto === 4,
      };
    });

    
    this.catchIDs();
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    this.emitCranioSimples();

    if(this.countLesoes > 0){
      this.emitSistemaLesao();
    } else {
      this.emitSistemaSimples();
    }

    if(this.countLesoes > 0){
      this.emitToraxLesao();
    } else {
      this.emitToraxSimples();
    }

    if(this.countLesoes > 0){
      this.emitCoracaoLesao();
    } else {
      this.emitCoracaoSimples();
    }

    if(this.countLesoes > 0){
      this.emitParedeLesao();
    } else {
      this.emitParedeSimples();
    }

    if(this.countLesoes > 0){
      this.emitAbdomeLesao();
    } else {
      this.emitAbdomeSimples();
    }

    if(this.countLesoes > 0){
      this.emitAparelhoLesao();
    } else {
      this.emitAparelhoSimples();
    }

    if(this.countLesoes > 0){
      this.emitCordaoLesao();
    } else {
      this.emitCordaoSimples();
    }

    this.listaCompleta.emit();
  }

  emitCranioSimples(){
    let elemento;

    const handleCranio = (index, slCranio, inOutrasCra) => {
      if (this.fetoSelected === index && slCranio && slCranio.value !== '**') {
        this.countLesoes++;
  
        if (inOutrasCra && slCranio.value === 'Outras alterações: ') {
          elemento = { id: slCranio.id, value: inOutrasCra.nativeElement.value };
  
          const cranioAlt = { elemento: { id: `5652f${index}`, value: inOutrasCra.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(cranioAlt);
        } else {
          elemento = { id: slCranio.id, value: slCranio.value };
        }
  
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };
  
    handleCranio(1, this.slCranio, this.inOutrasCra);
    handleCranio(2, this.slCranio2, this.inOutrasCra2);
    handleCranio(3, this.slCranio3, this.inOutrasCra3);
    handleCranio(4, this.slCranio4, this.inOutrasCra4);
  }

  emitSistemaLesao(){
    let elemento;

    const processSistema = (index, slSistema, inOutrasSis) => {
      if (this.fetoSelected === index && slSistema && slSistema.value !== '**') {
        this.countLesoes++;

        const objeto = { id: slSistema.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (inOutrasSis && slSistema.value === 'Outras alterações: ') {
          elemento = { id: slSistema.id, value: inOutrasSis.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);

          const sistemaAlt = { elemento: { id: `5654f${index}`, value: inOutrasSis.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slSistema.id, value: slSistema.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };

    processSistema(1, this.slSistema, this.inOutrasSis);
    processSistema(2, this.slSistema2, this.inOutrasSis2);
    processSistema(3, this.slSistema3, this.inOutrasSis3);
    processSistema(4, this.slSistema4, this.inOutrasSis4);
  }

  emitSistemaSimples(){
    let elemento;
  
    const processSistema = (index, slSistema, inOutrasSis) => {
      if (this.fetoSelected === index && slSistema && slSistema.value !== '**') {
        this.countLesoes++;
  
        if (inOutrasSis && slSistema.value === 'Outras alterações: ') {
          elemento = { id: slSistema.id, value: inOutrasSis.nativeElement.value };
  
          const colunaAlt = { elemento: { id: `5654f${index}`, value: inOutrasSis.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slSistema.id, value: slSistema.value };
        }
  
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };
  
    processSistema(1, this.slSistema, this.inOutrasSis);
    processSistema(2, this.slSistema2, this.inOutrasSis2);
    processSistema(3, this.slSistema3, this.inOutrasSis3);
    processSistema(4, this.slSistema4, this.inOutrasSis4);
  }

  emitToraxLesao(){
    let elemento;

    const processTorax = (index, slTorax, inOutrasTor) => {
      if (this.fetoSelected === index && slTorax && slTorax.value !== '**') {
        this.countLesoes++;
  
        const objeto = { id: slTorax.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
  
        if (inOutrasTor && slTorax.value === 'Outras alterações: ') {
          elemento = { id: slTorax.id, value: inOutrasTor.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
  
          const sistemaAlt = { elemento: { id: `5656f${index}`, value: inOutrasTor.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slTorax.id, value: slTorax.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };
  
    processTorax(1, this.slTorax, this.inOutrasTor);
    processTorax(2, this.slTorax2, this.inOutrasTor2);
    processTorax(3, this.slTorax3, this.inOutrasTor3);
    processTorax(4, this.slTorax4, this.inOutrasTor4);
  }

  emitToraxSimples(){
    let elemento;

    const processTorax = (index, slTorax, inOutrasTor) => {
      if (this.fetoSelected === index && slTorax && slTorax.value !== '**') {
        this.countLesoes++;

        if (inOutrasTor && slTorax.value === 'Outras alterações: ') {
          elemento = { id: slTorax.id, value: inOutrasTor.nativeElement.value };

          const colunaAlt = { elemento: { id: `5656f${index}`, value: inOutrasTor.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slTorax.id, value: slTorax.value };
        }

        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };

    processTorax(1, this.slTorax, this.inOutrasTor);
    processTorax(2, this.slTorax2, this.inOutrasTor2);
    processTorax(3, this.slTorax3, this.inOutrasTor3);
    processTorax(4, this.slTorax4, this.inOutrasTor4);
  }

  emitCoracaoLesao(){
    let elemento;
  
    const processCoracao = (index, slCoracao, inOutrasCor) => {
      if (this.fetoSelected === index && slCoracao && slCoracao.value !== '**') {
        this.countLesoes++;
  
        const objeto = { id: slCoracao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
  
        if (inOutrasCor && slCoracao.value === 'Outras alterações: ') {
          elemento = { id: slCoracao.id, value: inOutrasCor.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
  
          const sistemaAlt = { elemento: { id: `5658f${index}`, value: inOutrasCor.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slCoracao.id, value: slCoracao.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };
    
    processCoracao(1, this.slCoracao, this.inOutrasCor);
    processCoracao(2, this.slCoracao2, this.inOutrasCor2);
    processCoracao(3, this.slCoracao3, this.inOutrasCor3);
    processCoracao(4, this.slCoracao4, this.inOutrasCor4);

  }

  emitCoracaoSimples(){
    let elemento;
  
    const processCoracao = (index, slCoracao, inOutrasCor) => {
      if (this.fetoSelected === index && slCoracao && slCoracao.value !== '**') {
        this.countLesoes++;
  
        if (inOutrasCor && slCoracao.value === 'Outras alterações: ') {
          elemento = { id: slCoracao.id, value: inOutrasCor.nativeElement.value };
  
          const colunaAlt = { elemento: { id: `5658f${index}`, value: inOutrasCor.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slCoracao.id, value: slCoracao.value };
        }
  
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };
  
    processCoracao(1, this.slCoracao, this.inOutrasCor);
    processCoracao(2, this.slCoracao2, this.inOutrasCor2);
    processCoracao(3, this.slCoracao3, this.inOutrasCor3);
    processCoracao(4, this.slCoracao4, this.inOutrasCor4);
  }

  emitParedeLesao(){
    let elemento;

    const processParede = (index, slParede, inOutrasPrd) => {
      if (this.fetoSelected === index && slParede && slParede.value !== '**') {
        this.countLesoes++;

        const objeto = { id: slParede.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (inOutrasPrd && slParede.value === 'Outras alterações: ') {
          elemento = { id: slParede.id, value: inOutrasPrd.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);

          const sistemaAlt = { elemento: { id: `5660f${index}`, value: inOutrasPrd.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slParede.id, value: slParede.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };

    processParede(1, this.slPrdAbdominal, this.inOutrasPrd);
    processParede(2, this.slPrdAbdominal2, this.inOutrasPrd2);
    processParede(3, this.slPrdAbdominal3, this.inOutrasPrd3);
    processParede(4, this.slPrdAbdominal4, this.inOutrasPrd4);
  }

  emitParedeSimples(){
    let elemento;

    const processParede = (index, slParede, inOutrasPrd) => {
      if (this.fetoSelected === index && slParede && slParede.value !== '**') {
        this.countLesoes++;
  
        if (inOutrasPrd && slParede.value === 'Outras alterações: ') {
          elemento = { id: slParede.id, value: inOutrasPrd.nativeElement.value };
  
          const colunaAlt = { elemento: { id: `5660f${index}`, value: inOutrasPrd.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slParede.id, value: slParede.value };
        }
  
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };
  
    processParede(1, this.slPrdAbdominal, this.inOutrasPrd);
    processParede(2, this.slPrdAbdominal2, this.inOutrasPrd2);
    processParede(3, this.slPrdAbdominal3, this.inOutrasPrd3);
    processParede(4, this.slPrdAbdominal4, this.inOutrasPrd4);  
  }

  emitAbdomeLesao(){
    let elemento;

    const processAbdome = (index, slAbdome, inOutrasAbd) => {
      if (this.fetoSelected === index && slAbdome && slAbdome.value !== '**') {
        this.countLesoes++;

        const objeto = { id: slAbdome.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (inOutrasAbd && slAbdome.value === 'Outras alterações: ') {
          elemento = { id: slAbdome.id, value: inOutrasAbd.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);

          const sistemaAlt = { elemento: { id: `5662f${index}`, value: inOutrasAbd.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slAbdome.id, value: slAbdome.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };

    processAbdome(1, this.slAbdome, this.inOutrasAbd);
    processAbdome(2, this.slAbdome2, this.inOutrasAbd2);
    processAbdome(3, this.slAbdome3, this.inOutrasAbd3);
    processAbdome(4, this.slAbdome4, this.inOutrasAbd4);    
  }

  emitAbdomeSimples(){
    let elemento;

    const processAbdome = (index, slAbdome, inOutrasAbd) => {
      if (this.fetoSelected === index && slAbdome && slAbdome.value !== '**') {
        this.countLesoes++;

        if (inOutrasAbd && slAbdome.value === 'Outras alterações: ') {
          elemento = { id: slAbdome.id, value: inOutrasAbd.nativeElement.value };

          const colunaAlt = { elemento: { id: `5662f${index}`, value: inOutrasAbd.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slAbdome.id, value: slAbdome.value };
        }

        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };

    processAbdome(1, this.slAbdome, this.inOutrasAbd);
    processAbdome(2, this.slAbdome2, this.inOutrasAbd2);
    processAbdome(3, this.slAbdome3, this.inOutrasAbd3);
    processAbdome(4, this.slAbdome4, this.inOutrasAbd4);
  }

  emitAparelhoLesao(){
    let elemento;

    const processAparelho = (index, slAparelho, inOutrasApr) => {
      if (this.fetoSelected === index && slAparelho && slAparelho.value !== '**') {
        this.countLesoes++;
  
        const objeto = { id: slAparelho.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
  
        if (inOutrasApr && slAparelho.value === 'Outras alterações: ') {
          elemento = { id: slAparelho.id, value: inOutrasApr.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
  
          const sistemaAlt = { elemento: { id: `5664f${index}`, value: inOutrasApr.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slAparelho.id, value: slAparelho.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };
  
    processAparelho(1, this.slAparelho, this.inOutrasApr);
    processAparelho(2, this.slAparelho2, this.inOutrasApr2);
    processAparelho(3, this.slAparelho3, this.inOutrasApr3);
    processAparelho(4, this.slAparelho4, this.inOutrasApr4);    
  }

  emitAparelhoSimples(){
    let elemento;

    const processAparelho = (index, slAparelho, inOutrasApr) => {
      if (this.fetoSelected === index && slAparelho && slAparelho.value !== '**') {
        this.countLesoes++;
  
        if (inOutrasApr && slAparelho.value === 'Outras alterações: ') {
          elemento = { id: slAparelho.id, value: inOutrasApr.nativeElement.value };
  
          const colunaAlt = { elemento: { id: `5664f${index}`, value: inOutrasApr.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slAparelho.id, value: slAparelho.value };
        }
  
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };
  
    processAparelho(1, this.slAparelho, this.inOutrasApr);
    processAparelho(2, this.slAparelho2, this.inOutrasApr2);
    processAparelho(3, this.slAparelho3, this.inOutrasApr3);
    processAparelho(4, this.slAparelho4, this.inOutrasApr4);   
  }

  emitCordaoLesao(){
    let elemento;

    const processCordao = (index, slCordao, inOutrasCrd) => {
      if (this.fetoSelected === index && slCordao && slCordao.value !== '**') {
        this.countLesoes++;
  
        const objeto = { id: slCordao.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);
  
        if (inOutrasCrd && slCordao.value === 'Outras alterações: ') {
          elemento = { id: slCordao.id, value: inOutrasCrd.nativeElement.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
  
          const sistemaAlt = { elemento: { id: `5666f${index}`, value: inOutrasCrd.nativeElement.value }, isPrimeiraLesao: false };
          this.termosGenericos.emit(sistemaAlt);
        } else {
          elemento = { id: slCordao.id, value: slCordao.value };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    };

    processCordao(1, this.slCordao, this.inOutrasCrd);
    processCordao(2, this.slCordao2, this.inOutrasCrd2);
    processCordao(3, this.slCordao3, this.inOutrasCrd3);
    processCordao(4, this.slCordao4, this.inOutrasCrd4);    
  }

  emitCordaoSimples(){
    let elemento;

    const processCordao = (index, slCordao, inOutrasCrd) => {
      if (this.fetoSelected === index && slCordao && slCordao.value !== '**') {
        this.countLesoes++;
  
        if (inOutrasCrd && slCordao.value === 'Outras alterações: ') {
          elemento = { id: slCordao.id, value: inOutrasCrd.nativeElement.value };
  
          const colunaAlt = { elemento: { id: `5666f${index}`, value: inOutrasCrd.nativeElement.value }, isPrimeiraLesao: true };
          this.termosGenericos.emit(colunaAlt);
        } else {
          elemento = { id: slCordao.id, value: slCordao.value };
        }
  
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    };
  
    processCordao(1, this.slCordao, this.inOutrasCrd);
    processCordao(2, this.slCordao2, this.inOutrasCrd2);
    processCordao(3, this.slCordao3, this.inOutrasCrd3);
    processCordao(4, this.slCordao4, this.inOutrasCrd4);
  }

  verificaAlterado() {
    if(this.fetoSelected === 1){
      if (this.slCranio.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoCra = true;
      } else {
        this.showAndHideTerms.sahAlteradoCra = false;
      }

      if (this.slSistema.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoSis = true;
      } else {
        this.showAndHideTerms.sahAlteradoSis = false;
      }

      if (this.slTorax.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoTor = true;
      } else {
        this.showAndHideTerms.sahAlteradoTor = false;
      }

      if (this.slCoracao.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoCor = true;
      } else {
        this.showAndHideTerms.sahAlteradoCor = false;
      }

      if (this.slPrdAbdominal.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoPrd = true;
      } else {
        this.showAndHideTerms.sahAlteradoPrd = false;
      }

      if (this.slAbdome.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoAbd = true;
      } else {
        this.showAndHideTerms.sahAlteradoAbd = false;
      }

      if (this.slAparelho.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoApr = true;
      } else {
        this.showAndHideTerms.sahAlteradoApr = false;
      }

      if (this.slCordao.value === 'Outras alterações: ') {
        this.showAndHideTerms.sahAlteradoCrd = true;
      } else {
        this.showAndHideTerms.sahAlteradoCrd = false;
      }
    } else if(this.fetoSelected === 2){
      if (this.slCranio2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoCra = true;
      } else {
        this.showAndHideTerms2.sahAlteradoCra = false;
      } 
  
      if (this.slSistema2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoSis = true;
      } else {
        this.showAndHideTerms2.sahAlteradoSis = false;
      }
  
      if (this.slTorax2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoTor = true;
      } else {
        this.showAndHideTerms2.sahAlteradoTor = false;
      }
  
      if (this.slCoracao2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoCor = true;
      } else {
        this.showAndHideTerms2.sahAlteradoCor = false;
      }
  
      if (this.slPrdAbdominal2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoPrd = true;
      } else {
        this.showAndHideTerms2.sahAlteradoPrd = false;
      }
  
      if (this.slAbdome2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoAbd = true;
      } else {
        this.showAndHideTerms2.sahAlteradoAbd = false;
      }
  
      if (this.slAparelho2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoApr = true;
      } else {
        this.showAndHideTerms2.sahAlteradoApr = false;
      }
  
      if (this.slCordao2.value === 'Outras alterações: ') {
        this.showAndHideTerms2.sahAlteradoCrd = true;
      } else {
        this.showAndHideTerms2.sahAlteradoCrd = false;
      }
    } else if(this.fetoSelected === 3){
      if (this.slCranio3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoCra = true;
      } else {
        this.showAndHideTerms3.sahAlteradoCra = false;
      }
  
      if (this.slSistema3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoSis = true;
      } else {
        this.showAndHideTerms3.sahAlteradoSis = false;
      }
  
      if (this.slTorax3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoTor = true;
      } else {
        this.showAndHideTerms3.sahAlteradoTor = false;
      }
  
      if (this.slCoracao3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoCor = true;
      } else {
        this.showAndHideTerms3.sahAlteradoCor = false;
      }
  
      if (this.slPrdAbdominal3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoPrd = true;
      } else {
        this.showAndHideTerms3.sahAlteradoPrd = false;
      }
  
      if (this.slAbdome3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoAbd = true;
      } else {
        this.showAndHideTerms3.sahAlteradoAbd = false;
      }
  
      if (this.slAparelho3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoApr = true;
      } else {
        this.showAndHideTerms3.sahAlteradoApr = false;
      }
  
      if (this.slCordao3.value === 'Outras alterações: ') {
        this.showAndHideTerms3.sahAlteradoCrd = true;
      } else {
        this.showAndHideTerms3.sahAlteradoCrd = false;
      }
    } else if(this.fetoSelected === 4){
      if (this.slCranio4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoCra = true;
      } else {
        this.showAndHideTerms4.sahAlteradoCra = false;
      }
  
      if (this.slSistema4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoSis = true;
      } else {
        this.showAndHideTerms4.sahAlteradoSis = false;
      }
  
      if (this.slTorax4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoTor = true;
      } else {
        this.showAndHideTerms4.sahAlteradoTor = false;
      }
  
      if (this.slCoracao4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoCor = true;
      } else {
        this.showAndHideTerms4.sahAlteradoCor = false;
      }
  
      if (this.slPrdAbdominal4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoPrd = true;
      } else {
        this.showAndHideTerms4.sahAlteradoPrd = false;
      }
  
      if (this.slAbdome4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoAbd = true;
      } else {
        this.showAndHideTerms4.sahAlteradoAbd = false;
      }
  
      if (this.slAparelho4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoApr = true;
      } else {
        this.showAndHideTerms4.sahAlteradoApr = false;
      }
  
      if (this.slCordao4.value === 'Outras alterações: ') {
        this.showAndHideTerms4.sahAlteradoCrd = true;
      } else {
        this.showAndHideTerms4.sahAlteradoCrd = false;
      }
    }
  }

}
