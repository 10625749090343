import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

export interface ArrGenerico {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-utero',
  templateUrl: './utero.component.html',
  styleUrls: ['./utero.component.css']
})
export class UteroComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  // @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAumentado') rdAumentado: MatRadioButton;
  @ViewChild('rdHisterectomia') rdHisterectomia: MatRadioButton;
  @ViewChild('rdMiometrio') rdMiometrio: MatRadioButton;
  @ViewChild('rdColoUterino') rdColoUterino: MatRadioButton;

  @ViewChild('slPosicao') slPosicao: MatSelect;
  @ViewChild('slContornos') slContornos: MatSelect;
  @ViewChild('inMedidaNormalAumentada1') inMedidaNormalAumentada1: ElementRef;
  @ViewChild('inMedidaNormalAumentada2') inMedidaNormalAumentada2: ElementRef;
  @ViewChild('inMedidaNormalAumentada3') inMedidaNormalAumentada3: ElementRef;
  @ViewChild('inVolumeNormalAumentada') inVolumeNormalAumentada: ElementRef;

  @ViewChild('rdHisterectomiaTotal') rdHisterectomiaTotal: MatRadioButton;
  @ViewChild('rdHisterectomiaParcial') rdHisterectomiaParcial: MatRadioButton;
  @ViewChild('inMedidaHisterectomia1') inMedidaHisterectomia1: ElementRef;
  @ViewChild('inMedidaHisterectomia2') inMedidaHisterectomia2: ElementRef;
  @ViewChild('inMedidaHisterectomia3') inMedidaHisterectomia3: ElementRef;
  @ViewChild('inVolumeHisterectomia') inVolumeHisterectomia: ElementRef;

  @ViewChild('rdMiometrioHomogeneo') rdMiometrioHomogeneo: MatRadioButton;
  @ViewChild('rdMiometrioHeteregeneoSem') rdMiometrioHeteregeneoSem: MatRadioButton;
  @ViewChild('rdMiometrioHeteregeneo') rdMiometrioHeteregeneo: MatRadioButton;

  @ViewChild('rdColoUterinoNormal') rdColoUterinoNormal: MatRadioButton;
  @ViewChild('rdColoUterinoCisto') rdColoUterinoCisto: MatRadioButton;
  @ViewChild('inMedidaColoUterino') inMedidaColoUterino: ElementRef;

  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('rdUmNodulo') rdUmNodulo: MatRadioButton;
  @ViewChild('rdDoisNodulos') rdDoisNodulos: MatRadioButton;
  @ViewChild('rdCaractSemelhantes') rdCaractSemelhantes: MatRadioButton;
  @ViewChild('rdCaractNaoSemelhantes') rdCaractNaoSemelhantes: MatRadioButton;
  @ViewChild('rdMultiploNodulos') rdMultiploNodulos: MatRadioButton;
  @ViewChild('slEcoginicidadeNodMulti') slEcoginicidadeNodMulti: MatSelect;
  @ViewChild('slContornoNodMulti') slContornoNodMulti: MatSelect;
  @ViewChild('slEcogenicidade') slEcogenicidade: MatSelect;
  @ViewChild('slContornoNodulo') slContornoNodulo: MatSelect;
  @ViewChild('slSubtipo') slSubtipo: MatSelect;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('inMedidaUmNodulo1') inMedidaUmNodulo1: ElementRef;
  @ViewChild('inMedidaUmNodulo2') inMedidaUmNodulo2: ElementRef;
  @ViewChild('inMedidaUmNodulo3') inMedidaUmNodulo3: ElementRef;

  @ViewChild('slEcogenicidadeDoisNodSem') slEcogenicidadeDoisNodSem: MatSelect;
  @ViewChild('slContornoDoisNodSem') slContornoDoisNodSem: MatSelect;

  @ViewChildren('doisNodulosChildren') doisNodulosChildren: QueryList<any>;
  @ViewChildren('multiplosNodulosChildren') multiplosNodulosChildren: QueryList<any>;

  posicoes: ArrGenerico[] = [
    { value: 'em anteversoflexão', viewValue: 'Anteversoflexão' },
    { value: 'em retroversoflexão', viewValue: 'Retroversoflexão' },
    { value: 'em medioversão', viewValue: 'Em medioversão' },
    { value: 'em anteversão e retroflexão', viewValue: 'Anteversão e retroflexão' },
    { value: 'em retroversão e anteflexão', viewValue: 'Retroversão e anteflexão' }
  ];

  contornos: ArrGenerico[] = [
    { value: 'regulares', viewValue: 'Regulares' },
    { value: 'lobulados', viewValue: 'Lobulados' }
  ];

  ecogenicidadeSingular: ArrGenerico[] = [
    { value: 'hiperecogênico', viewValue: 'Hiperecogênico' },
    { value: 'hipoecogênico', viewValue: 'Hipoecogênico' },
    { value: 'isoecogênico', viewValue: 'Isoecogênico' }
  ];

  ecogenicidadePlural: ArrGenerico[] = [
    { value: 'hiperecogênicos', viewValue: 'Hiperecogênicos' },
    { value: 'hipoecogênicos', viewValue: 'Hipoecogênicos' },
    { value: 'isoecogênicos', viewValue: 'Isoecogênicos' }
  ];

  contornosNodulo: ArrGenerico[] = [
    { value: 'regulares', viewValue: 'regulares' },
    { value: 'lobulados', viewValue: 'Lobulados' },
    { value: 'indefinidos', viewValue: 'Indefinidos' },
    { value: 'irregulares', viewValue: 'Irregulares' }
  ];

  subtipos: ArrGenerico[] = [
    { value: 'intramural', viewValue: 'Intramural' },
    { value: 'subseroso', viewValue: 'Subseroso' },
    { value: 'submucoso', viewValue: 'Submucoso' },
    { value: 'intramural/subseroso', viewValue: 'Intramural/Subseroso' },
    { value: 'intramural/submucoso', viewValue: 'Intramural/Submucoso' },
    { value: 'transmural', viewValue: 'Transmural' }
  ];

  localizacoes: ArrGenerico[] = [
    { value: 'região fúndica', viewValue: 'Região fúndica' },
    { value: 'parede corporal anterior', viewValue: 'Parede corporal anterior' },
    { value: 'parede corporal posterior', viewValue: 'Parede corporal posterior' },
    { value: 'parede lateral direita', viewValue: 'Parede lateral direita' },
    { value: 'parede lateral esquerda', viewValue: 'Parede lateral esquerda' }
  ];

  lesoesForm: FormGroup;
  linha = 0;
  objeto: any;
  objetofilho: any;
  countLesoes = 0;
  showAndHideTerms: any;
  phVolume = 'Volume';
  posicaoSelected: string;
  contornoSelected: string;
  contornoNoduloSelected: string;
  ecogenicidadeSelected: string;
  ecogenicidadePluralSelected: string;
  subtipoSelected: string;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormaAumentado: true,
      sahHisterectomia: false,
      sahHisterectomiaParcial: false,
      sahMiometrio: true,
      sahColoUterino: true,
      sahColoUterinoCisto: false,
      sahHeteregeneoSem: true,
      sahNodulo: false,
      sahUmNodulo: false,
      sahDoisNodulos: false,
      sahDoisNodulosSem: false,
      sahDoisNodulosNaoSem: false,
      sahsahNoduloSimplesCaract: false,
      sahPlusDoisNodulos: false,
      sahMultiploNodulos: false,
      sahPlusMultiploNodulos: false
    };
    this.lesoesForm = this._fb.group({
      doisNodulos: this._fb.array([this.addDoisNodulosGrup()]),
      multiplosNodulos: this._fb.array([this.addMultiplosNodulosGrup()])
    });

    this.posicaoSelected = this.posicoes[0].value;
    this.contornoSelected = this.contornos[0].value;
    this.contornoNoduloSelected = this.contornosNodulo[0].value;
    this.ecogenicidadeSelected = this.ecogenicidadeSingular[1].value;
    this.ecogenicidadePluralSelected = this.ecogenicidadePlural[1].value;
    this.subtipoSelected = this.subtipos[0].value;

    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  // DOIS NODULOS
  addDoisNodulosGrup() {
    return this._fb.group({
      ecogenicidade: [],
      contorno: [],
      subtipo: [],
      localizacao: [],
      medindo: []
    });
  }

  addDoisNodulos() {
    this.doisNodulosArray.push(this.addDoisNodulosGrup());
    this.catchIDs();
  }

  removeDoisNodulos(index) {
    this.doisNodulosArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get doisNodulosArray() {
    return <FormArray>this.lesoesForm.get('doisNodulos');
  }

  // MULTI NODULOS
  addMultiplosNodulosGrup() {
    return this._fb.group({
      // ecogenicidade: [],
      // contorno: [],
      subtipo: [],
      localizacao: [],
      medindo: []
    });
  }

  addMultiplosNodulos() {
    this.multiplosNodulosArray.push(this.addMultiplosNodulosGrup());
    this.catchIDs();
  }

  removeMultiplosNodulos(index) {
    this.multiplosNodulosArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get multiplosNodulosArray() {
    return <FormArray>this.lesoesForm.get('multiplosNodulos');
  }

  clickNormal() {
    this.showAndHideTerms.sahNormaAumentado = true;
    this.showAndHideTerms.sahHisterectomia = false;
    this.showAndHideTerms.sahMiometrio = true;
    this.showAndHideTerms.sahColoUterino = true;
    this.catchIDs();
  }

  clickAumentado() {
    this.showAndHideTerms.sahNormaAumentado = true;
    this.showAndHideTerms.sahHisterectomia = false;
    this.showAndHideTerms.sahMiometrio = true;
    this.showAndHideTerms.sahColoUterino = true;
    this.showAndHideTerms.sahHisterectomiaParcial = false;
    this.catchIDs();
  }

  clickHisterectomia() {
    this.showAndHideTerms.sahNormaAumentado = false;
    this.showAndHideTerms.sahHisterectomia = true;
    this.showAndHideTerms.sahMiometrio = false;
    this.showAndHideTerms.sahColoUterino = false;
    this.showAndHideTerms.sahHisterectomiaParcial = false;
    this.showAndHideTerms.sahHeteregeneoSem = false;
    this.catchIDs();
  }

  clickHisterectomiaTotal() {
    this.showAndHideTerms.sahHisterectomiaParcial = false;
    this.catchIDs();
  }

  clickHisterectomiaParcial() {
    this.showAndHideTerms.sahHisterectomiaParcial = true;
    this.catchIDs();
  }

  clickMiometrioHomogeneo() {
    this.showAndHideTerms.sahHeteregeneoSem = true;
    this.catchIDs();
  }

  clickMiometrioHeteregeneoSem() {
    this.showAndHideTerms.sahHeteregeneoSem = false;
    this.showAndHideTerms.sahNodulo = false;
    this.catchIDs();
  }

  clickMiometrioHeteregeneo() {
    this.showAndHideTerms.sahHeteregeneoSem = true;
    this.catchIDs();
  }

  clickColoUterinoNormal() {
    this.showAndHideTerms.sahColoUterinoCisto = false;
    this.catchIDs();
  }

  clickColoUterinoCisto() {
    this.showAndHideTerms.sahColoUterinoCisto = true;
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo == false) {
      this.showAndHideTerms.sahNodulo = true;
      this.showAndHideTerms.sahUmNodulo = true;
      this.showAndHideTerms.sahDoisNodulos = false;
      this.showAndHideTerms.sahMultiploNodulos = false;
    } else {
      this.showAndHideTerms.sahNodulo = false;
      this.showAndHideTerms.sahUmNodulo = false;
      this.showAndHideTerms.sahDoisNodulos = false;
      this.showAndHideTerms.sahMultiploNodulos = false;
      this.showAndHideTerms.sahPlusDoisNodulos = false;
    }
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickUmNodulo() {
    this.showAndHideTerms.sahUmNodulo = true;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.showAndHideTerms.sahMultiploNodulos = false;
    this.catchIDs();
    this.ajustaArrayDinamico();
  }

  clickDoisNodulos() {
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = true;
    this.showAndHideTerms.sahMultiploNodulos = false;
    this.showAndHideTerms.sah = true;
    this.showAndHideTerms.sahPlusDoisNodulos = true;
    this.showAndHideTerms.sahDoisNodulosSem = true;
    this.showAndHideTerms.sahDoisNodulosNaoSem = false;
    this.catchIDs();
    this.ajustaArrayDinamico();
  }

  clickCaractSemelhantes() {
    this.showAndHideTerms.sahDoisNodulosSem = true;
    this.showAndHideTerms.sahDoisNodulosNaoSem = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickCaractNaoSemelhantes() {
    this.showAndHideTerms.sahDoisNodulosNaoSem = true;
    this.showAndHideTerms.sahDoisNodulosSem = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickMultiploNodulos() {
    this.showAndHideTerms.sahUmNodulo = false;
    this.showAndHideTerms.sahDoisNodulos = false;
    this.showAndHideTerms.sahMultiploNodulos = true;
    this.showAndHideTerms.sahPlusMultiploNodulos = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;
    this.phVolume = 'Volume';

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      this.emitColoUterino();
      this.emitMiometrio();
      // this.emitNoduloLesao();
    }

    if (this.rdAumentado.checked) {
      this.termoSelecionado.emit(this.rdAumentado.id);

      this.emitColoUterino();
      this.emitMiometrio();
      // this.emitNoduloLesao();
    }


    if (this.rdNormal.checked || this.rdAumentado.checked) {
      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slPosicao != undefined) {
        if (this.slPosicao.value != undefined) {
          const objeto = { elemento: this.slPosicao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 453, value: this.posicaoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slContornos != undefined) {
        if (this.slContornos.value != undefined) {
          const objeto = { elemento: this.slContornos, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 454, value: this.contornoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      this.formataMedindoNormalAumentada();
    }

    if (this.rdHisterectomia.checked) {
      if (this.rdHisterectomiaTotal != undefined && this.rdHisterectomiaTotal.checked) {
        this.termoSelecionado.emit(this.rdHisterectomiaTotal.id);

        this.emitNoduloLesao();
      } else if (this.rdHisterectomiaParcial != undefined && this.rdHisterectomiaParcial.checked) {
        this.termoSelecionado.emit(this.rdHisterectomiaParcial.id);

        this.formataMedindoHisterectomia();
        this.emitNoduloLesao();
      } else {
        // ID do rdHisterectomiaTotal
        this.termoSelecionado.emit(456);
        this.emitNoduloLesao();
      }
    }

    // this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitMiometrio() {
    this.countLesoes++;
    this.countLesoes++;

    if (this.rdMiometrioHomogeneo != undefined && this.rdMiometrioHomogeneo.checked) {
      const objeto = { id: this.rdMiometrioHomogeneo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdMultiploNodulos != undefined && this.rdMultiploNodulos.checked) {
        this.emitNoduloLesao();
      } else {
        this.emitNodulo();
      }
    } else if (this.rdMiometrioHeteregeneoSem != undefined && this.rdMiometrioHeteregeneoSem.checked) {
      const objeto = { id: this.rdMiometrioHeteregeneoSem.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      // this.emitNoduloLesao();
    } else if (this.rdMiometrioHeteregeneo != undefined && this.rdMiometrioHeteregeneo.checked) {
      const objeto = { id: this.rdMiometrioHeteregeneo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.emitNodulo();
    } else {
      // ID do rdMiometrioHomogeneo
      const objeto = { id: 460, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      if (this.rdMultiploNodulos != undefined && this.rdMultiploNodulos.checked) {
        this.emitNoduloLesao();
      } else {
        this.emitNodulo();
      }
    }
  }

  emitColoUterino() {
    this.countLesoes++;

    if (this.rdColoUterinoNormal != undefined && this.rdColoUterinoNormal.checked) {
      const objeto = { id: this.rdColoUterinoNormal.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

    } else if (this.rdColoUterinoCisto != undefined && this.rdColoUterinoCisto.checked) {
      const objeto = { id: this.rdColoUterinoCisto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.inMedidaColoUterino != undefined &&
        this.inMedidaColoUterino.nativeElement.id != undefined &&
        this.inMedidaColoUterino.nativeElement.value != '') {

        const objeto = { elemento: this.inMedidaColoUterino.nativeElement, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    } else {
      // ID do rdColoUterinoNormal
      const objeto = { id: 464, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitNodulo() {
    let posicao = 0;
    this.linha = 0;

    if (this.ckNodulo != undefined && this.ckNodulo.checked) {
      const objeto = { id: this.ckNodulo.id, cenario: 3 };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
        const objeto = { id: this.rdUmNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slEcogenicidade != undefined) {
          if (this.slEcogenicidade.value != undefined) {
            const objeto = { elemento: this.slEcogenicidade, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 469, value: this.ecogenicidadeSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slContornoNodulo != undefined) {
          if (this.slContornoNodulo.value != undefined) {
            const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 470, value: this.contornoNoduloSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slSubtipo != undefined) {
          if (this.slSubtipo.value != undefined) {
            const objeto = { elemento: this.slSubtipo, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 471, value: this.subtipoSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoUmNodulo();
      } else if (this.rdDoisNodulos != undefined && this.rdDoisNodulos.checked) {
        const objeto = { id: this.rdDoisNodulos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.rdCaractSemelhantes != undefined && this.rdCaractSemelhantes.checked) {
          const objeto = { id: this.rdCaractSemelhantes.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);

          // POG - Ajustar isso e pegar pelo form. Ass: Gabs
          if (this.slEcogenicidadeDoisNodSem != undefined) {
            if (this.slEcogenicidadeDoisNodSem.value != undefined) {
              const objeto = { elemento: this.slEcogenicidadeDoisNodSem, isPrimeiraLesao: false };
              this.termosGenericos.emit(objeto);
            } else {
              const elemento = { id: 522, value: this.ecogenicidadeSelected }
              const objeto = { elemento: elemento, isPrimeiraLesao: false };
              this.termosGenericos.emit(objeto);
            }
          }

          // POG - Ajustar isso e pegar pelo form. Ass: Gabs
          if (this.slContornoDoisNodSem != undefined) {
            if (this.slContornoDoisNodSem.value != undefined) {
              const objeto = { elemento: this.slContornoDoisNodSem, isPrimeiraLesao: false };
              this.termosGenericos.emit(objeto);
            } else {
              const elemento = { id: 523, value: this.contornoNoduloSelected }
              const objeto = { elemento: elemento, isPrimeiraLesao: false };
              this.termosGenericos.emit(objeto);
            }
          }

          this.doisNodulosChildren.forEach(obj => {

            if (obj.nativeElement != undefined) {

              if (obj.nativeElement.id == 526) {
                if (posicao < 3) {
                  posicao++;
                } else {
                  posicao = 1;
                }
              }

              this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
              this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.objeto);
            } else {

              if (obj.id == 524) {
                this.linha++;
              }

              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.objeto);
            }
          });
        }

        if (this.rdCaractNaoSemelhantes != undefined && this.rdCaractNaoSemelhantes.checked) {
          const objeto = { id: this.rdCaractNaoSemelhantes.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);

          this.doisNodulosChildren.forEach(obj => {

            if (obj.nativeElement != undefined) {

              if (obj.nativeElement.id == 526) {
                if (posicao < 3) {
                  posicao++;
                } else {
                  posicao = 1;
                }
              }

              this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
              this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.objeto);
            } else {

              if (obj.id == 522) {
                this.linha++;
              }

              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.objeto);
            }
          });
        }

      } else if (this.rdMultiploNodulos != undefined && this.rdMultiploNodulos.checked) {
        const objeto = { id: this.rdMultiploNodulos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slEcoginicidadeNodMulti != undefined) {
          if (this.slEcoginicidadeNodMulti.value != undefined) {
            const objeto = { elemento: this.slEcoginicidadeNodMulti, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 527, value: this.ecogenicidadePluralSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slContornoNodMulti != undefined) {
          if (this.slContornoNodMulti.value != undefined) {
            const objeto = { elemento: this.slContornoNodMulti, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 528, value: this.contornoNoduloSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        this.multiplosNodulosChildren.forEach(obj => {

          if (obj.nativeElement != undefined) {

            if (obj.nativeElement.id == 531) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }
            }

            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          } else {

            if (obj.id == 529) {
              this.linha++;
            }

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          }
        });
      }
    }
  }

  emitNoduloLesao() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    if (this.ckNodulo != undefined && this.ckNodulo.checked) {
      const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
        const objeto = { id: this.rdUmNodulo.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slEcogenicidade != undefined) {
          if (this.slEcogenicidade.value != undefined) {
            const objeto = { elemento: this.slEcogenicidade, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 469, value: this.ecogenicidadeSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slContornoNodulo != undefined) {
          if (this.slContornoNodulo.value != undefined) {
            const objeto = { elemento: this.slContornoNodulo, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 470, value: this.contornoNoduloSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slSubtipo != undefined) {
          if (this.slSubtipo.value != undefined) {
            const objeto = { elemento: this.slSubtipo, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 471, value: this.subtipoSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slLocalizacao != undefined && this.slLocalizacao.value != undefined) {
          const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.formataMedindoUmNodulo();
      } else if (this.rdDoisNodulos != undefined && this.rdDoisNodulos.checked) {
        const objeto = { id: this.rdDoisNodulos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        if (this.rdCaractSemelhantes != undefined && this.rdCaractSemelhantes.checked) {
          const objeto = { id: this.rdCaractSemelhantes.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

        if (this.rdCaractNaoSemelhantes != undefined && this.rdCaractNaoSemelhantes.checked) {
          const objeto = { id: this.rdCaractNaoSemelhantes.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(objeto);
        }

        this.doisNodulosChildren.forEach(obj => {

          if (obj.nativeElement != undefined) {

            if (obj.nativeElement.id == 526) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }
            }

            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          } else {

            if (obj.id == 522) {
              this.linha++;
            }

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          }
        });

      } else if (this.rdMultiploNodulos != undefined && this.rdMultiploNodulos.checked) {
        const objeto = { id: this.rdMultiploNodulos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objeto);

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slEcoginicidadeNodMulti != undefined) {
          if (this.slEcoginicidadeNodMulti.value != undefined) {
            const objeto = { elemento: this.slEcoginicidadeNodMulti, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 527, value: this.ecogenicidadePluralSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        // POG - Ajustar isso e pegar pelo form. Ass: Gabs
        if (this.slContornoNodMulti != undefined) {
          if (this.slContornoNodMulti.value != undefined) {
            const objeto = { elemento: this.slContornoNodMulti, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const elemento = { id: 528, value: this.contornoNoduloSelected }
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        this.multiplosNodulosChildren.forEach(obj => {

          if (obj.nativeElement != undefined) {

            if (obj.nativeElement.id == 531) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }
            }

            this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          } else {

            if (obj.id == 529) {
              this.linha++;
            }

            this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
            this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.objeto);
          }
        });
      }
    }
  }

  formataMedindoNormalAumentada() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inMedidaNormalAumentada1 != undefined &&
      this.inMedidaNormalAumentada1.nativeElement.id != undefined &&
      this.inMedidaNormalAumentada1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaNormalAumentada1.nativeElement.value);
      resultado = this.inMedidaNormalAumentada1.nativeElement.value;
    }

    if (this.inMedidaNormalAumentada2 != undefined &&
      this.inMedidaNormalAumentada2.nativeElement.id != undefined &&
      this.inMedidaNormalAumentada2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNormalAumentada2.nativeElement.value);
        resultado = resultado * this.inMedidaNormalAumentada2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNormalAumentada2.nativeElement.value);
        resultado = this.inMedidaNormalAumentada2.nativeElement.value;

      }
    }

    if (this.inMedidaNormalAumentada3 != undefined &&
      this.inMedidaNormalAumentada3.nativeElement.id != undefined &&
      this.inMedidaNormalAumentada3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNormalAumentada3.nativeElement.value);
        resultado = resultado * this.inMedidaNormalAumentada3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNormalAumentada3.nativeElement.value);
        resultado = this.inMedidaNormalAumentada3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // if (this.countLesoes <= 1) {
      //   isPrimeiraLesao = true;
      // }

      // Volume
      resultado = resultado * 0.523;
      this.phVolume = resultado.toFixed(1).toString();

      if (this.inVolumeNormalAumentada.nativeElement.value) {
        medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(this.inVolumeNormalAumentada.nativeElement.value) + ' cm³)';
      } else {
        medindoValor = medindoValor + ' cm (volume = ' + +resultado.toFixed(1) + ' cm³)';
      }

      const objetoElemento = { id: 455, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoHisterectomia() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inMedidaHisterectomia1 != undefined &&
      this.inMedidaHisterectomia1.nativeElement.id != undefined &&
      this.inMedidaHisterectomia1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaHisterectomia1.nativeElement.value);
      resultado = this.inMedidaHisterectomia1.nativeElement.value;
    }

    if (this.inMedidaHisterectomia2 != undefined &&
      this.inMedidaHisterectomia2.nativeElement.id != undefined &&
      this.inMedidaHisterectomia2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaHisterectomia2.nativeElement.value);
        resultado = resultado * this.inMedidaHisterectomia2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaHisterectomia2.nativeElement.value);
        resultado = this.inMedidaHisterectomia2.nativeElement.value;
      }
    }

    if (this.inMedidaHisterectomia3 != undefined &&
      this.inMedidaHisterectomia3.nativeElement.id != undefined &&
      this.inMedidaHisterectomia3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaHisterectomia3.nativeElement.value);
        resultado = resultado * this.inMedidaHisterectomia3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaHisterectomia3.nativeElement.value);
        resultado = this.inMedidaHisterectomia3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // if (this.countLesoes <= 1) {
      //   isPrimeiraLesao = true;
      // }

      resultado = resultado * 0.523;
      this.phVolume = resultado.toFixed(1).toString();

      if (this.inVolumeHisterectomia.nativeElement.value) {
        medindoValor = medindoValor + ' cm (volume = ' + this._validacoes.formataDecimal(this.inVolumeHisterectomia.nativeElement.value) + ' cm³)';
      } else {
        medindoValor = medindoValor + ' cm (volume = ' + +resultado.toFixed(1) + ' cm³)';
      }

      const objetoElemento = { id: 458, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoUmNodulo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedidaUmNodulo1 != undefined &&
      this.inMedidaUmNodulo1.nativeElement.id != undefined &&
      this.inMedidaUmNodulo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaUmNodulo1.nativeElement.value);
    }

    if (this.inMedidaUmNodulo2 != undefined &&
      this.inMedidaUmNodulo2.nativeElement.id != undefined &&
      this.inMedidaUmNodulo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmNodulo2.nativeElement.value);
      }
    }

    if (this.inMedidaUmNodulo3 != undefined &&
      this.inMedidaUmNodulo3.nativeElement.id != undefined &&
      this.inMedidaUmNodulo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaUmNodulo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaUmNodulo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes < 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 473, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {

    if (this.ckNodulo !== undefined && !this.ckNodulo.checked
      || this.rdDoisNodulos !== undefined && !this.rdDoisNodulos.checked) {
      // this.showAndHideTerms.sahDoisNoduloSem = false;

      const arrayTotal = this.doisNodulosArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.doisNodulosArray.length - 1

        if (index != 0) {
          this.doisNodulosArray.removeAt(index);
        }
      }
      this.doisNodulosArray.reset();
    }

    if (this.ckNodulo !== undefined && !this.ckNodulo.checked
      || this.rdMultiploNodulos !== undefined && !this.rdMultiploNodulos.checked) {
      // this.showAndHideTerms.sahDoisNodulosNaoSem = false;

      const arrayTotal = this.multiplosNodulosArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.multiplosNodulosArray.length - 1

        if (index != 0) {
          this.multiplosNodulosArray.removeAt(index);
        }
      }
      this.multiplosNodulosArray.reset();
    }

  }

}
