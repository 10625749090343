import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Validacoes } from '../../../../../../utils/validacoes';
import { MatCheckbox, MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { TireoideDialogComponent } from '../../../../../../utils/dialog/table/tireoide-dialog/tireoide-dialog.component';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { ParametrosBiometricosDialogComponent } from 'src/app/utils/dialog/table/parametros-biometricos-dialog/parametros-biometricos-dialog.component';
import { LiquidoAmnioticoDialogComponent } from 'src/app/utils/dialog/table/liquido-amniotico-dialog/liquido-amniotico-dialog.component';
import { TireoideTiradDialogComponent } from 'src/app/utils/dialog/table/tireoide-tirad-dialog/tireoide-tirad-dialog.component';
import { TireoideTuringDialogComponent } from 'src/app/utils/dialog/table/tireoide-pontos-dialog/tireoide-turing-dialog.component';
import { TireoideDopplerNivelCervicalComponent } from 'src/app/utils/dialog/imagens/tireoide-doppler-nivel-cervical/tireoide-doppler-nivel-cervical.component';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';

@Component({
  selector: 'app-tireoide-orgao',
  templateUrl: './tireoide-orgao.component.html',
  styleUrls: ['./tireoide-orgao.component.css']
})
export class TireoideOrgaoComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() tabelaReferencia = new EventEmitter();
  @Output() tabelaReferenciaAcr = new EventEmitter();

  // Parenquima
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTireoidopatia') rdTireoidopatia: MatRadioButton;
  @ViewChild('rdTireoidectomia') rdTireoidectomia: MatRadioButton;
  @ViewChild('inVolumeTotal') inVolumeTotal: ElementRef;

  // Direita
  @ViewChild('inMedidaLoboDireito1') inMedidaLoboDireito1: ElementRef;
  @ViewChild('inMedidaLoboDireito2') inMedidaLoboDireito2: ElementRef;
  @ViewChild('inMedidaLoboDireito3') inMedidaLoboDireito3: ElementRef;
  @ViewChild('inVolumeLoboDireito') inVolumeLoboDireito: ElementRef;
  // Esquerda
  @ViewChild('inMedidaLoboEsquerdo1') inMedidaLoboEsquerdo1: ElementRef;
  @ViewChild('inMedidaLoboEsquerdo2') inMedidaLoboEsquerdo2: ElementRef;
  @ViewChild('inMedidaLoboEsquerdo3') inMedidaLoboEsquerdo3: ElementRef;
  @ViewChild('inVolumeLoboEsquerdo') inVolumeLoboEsquerdo: ElementRef;
  // Istmo
  @ViewChild('inMedidaIstmo1') inMedidaIstmo1: ElementRef;
  @ViewChild('inMedidaIstmo2') inMedidaIstmo2: ElementRef;
  @ViewChild('inMedidaIstmo3') inMedidaIstmo3: ElementRef;
  @ViewChild('inVolumeLoboIstmo') inVolumeLoboIstmo: ElementRef;
  // Tireoidopatia
  @ViewChild('slDimensaoTireoidopatia') slDimensaoTireoidopatia: MatSelect;
  @ViewChild('slContornoTireoidopatia') slContornoTireoidopatia: MatSelect;
  @ViewChild('ckEsbocosNodulares') ckEsbocosNodulares: MatCheckbox;

  // Tireoidectomia
  @ViewChild('rdTotal') rdTotal: MatRadioButton;
  @ViewChild('rdParcial') rdParcial: MatRadioButton;
  @ViewChild('rdDireito') rdDireito: MatRadioButton;
  @ViewChild('rdEsquerdo') rdEsquerdo: MatRadioButton;
  @ViewChild('inMedidaLoboParcial1') inMedidaLoboParcial1: ElementRef;
  @ViewChild('inMedidaLoboParcial2') inMedidaLoboParcial2: ElementRef;
  @ViewChild('inMedidaLoboParcial3') inMedidaLoboParcial3: ElementRef;
  @ViewChild('inVolumeLoboParcial') inVolumeLoboParcial: ElementRef;

  // Cisto
  @ViewChild('ckCisto') ckCisto: MatCheckbox;
  @ViewChild('rdCistoColoide') rdCistoColoide: MatRadioButton;
  @ViewChild('rdCistoEsparsos') rdCistoEsparsos: MatRadioButton;
  @ViewChild('slLocalizacaoCisto') slLocalizacaoCisto: MatSelect;
  @ViewChild('inMedidaCisto') inMedidaCisto: ElementRef;

  // Nodulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('rdUmNodulo') rdUmNodulo: MatRadioButton;
  @ViewChild('rdDoisNodulo') rdDoisNodulo: MatRadioButton;
  @ViewChild('slComposicaoNodulo') slComposicaoNodulo: MatSelect;
  @ViewChild('slEcogenicidadeNodulo') slEcogenicidadeNodulo: MatSelect;
  @ViewChild('slFormatoNodulo') slFormatoNodulo: MatSelect;
  @ViewChild('slMargemNodulo') slMargemNodulo: MatSelect;
  @ViewChild('slFocosEcogenicosNodulo') slFocosEcogenicosNodulo: MatSelect;
  @ViewChild('slLocalizacaoNodulo') slLocalizacaoNodulo: MatSelect;
  @ViewChild('inMedidaNodulo1') inMedidaNodulo1: ElementRef;
  @ViewChild('inMedidaNodulo2') inMedidaNodulo2: ElementRef;
  @ViewChild('inMedidaNodulo3') inMedidaNodulo3: ElementRef;

  @ViewChild('ckNodulosNaoSem') ckNodulosNaoSem: MatCheckbox;
  @ViewChild('ckNodulosSem') ckNodulosSem: MatCheckbox;
  @ViewChild('slMargemNoduloSem') slMargemNoduloSem: MatSelect;
  @ViewChild('slFocoEcogenicoNoduloSem') slFocoEcogenicoNoduloSem: MatSelect;
  @ViewChild('slFormatoNoduloSem') slFormatoNoduloSem: MatSelect;


  @ViewChildren('nodulosNaoSemChildren') nodulosNaoSemChildren: QueryList<any>;
  @ViewChildren('nodulosSemChildren') nodulosSemChildren: QueryList<any>;


  dimensoes: ArrayDropDown[] = [
    { value: 'normais', viewValue: 'Normais' },
    { value: 'aumentadas', viewValue: 'Aumentadas' },
    { value: 'reduzidas', viewValue: 'Reduzidas' }
  ];

  contornos: ArrayDropDown[] = [
    { value: 'preservados', viewValue: 'Preservados' },
    { value: 'lobulados', viewValue: 'Lobulados' }
  ];

  localizacoes: ArrayDropDown[] = [
    { value: 'terço superior do lobo direito', viewValue: 'Terço superior do lobo direito' },
    { value: 'terço médio do lobo direito', viewValue: 'Terço médio do lobo direito' },
    { value: 'terço inferior do lobo direito', viewValue: 'Terço inferior do lobo direito' },
    { value: 'istmo da glândula tireóide', viewValue: 'Istmo' },
    { value: 'terço superior do lobo esquerdo', viewValue: 'Terço superior do lobo esquerdo' },
    { value: 'terço médio do lobo esquerdo', viewValue: 'Terço médio do lobo esquerdo' },
    { value: 'terço inferior do lobo esquerdo', viewValue: 'Terço inferior do lobo esquerdo' }
  ];

  localizacoesDireita: ArrayDropDown[] = [
    { value: 'terço superior do lobo direito', viewValue: 'Terço superior do lobo direito' },
    { value: 'terço médio do lobo direito', viewValue: 'Terço médio do lobo direito' },
    { value: 'terço inferior do lobo direito', viewValue: 'Terço inferior do lobo direito' },
  ];

  localizacoesEsquerda: ArrayDropDown[] = [
    { value: 'terço superior do lobo esquerdo', viewValue: 'Terço superior do lobo esquerdo' },
    { value: 'terço médio do lobo esquerdo', viewValue: 'Terço médio do lobo esquerdo' },
    { value: 'terço inferior do lobo esquerdo', viewValue: 'Terço inferior do lobo esquerdo' }
  ];

  composicaoes: ArrayDropDown[] = [
    { value: 'cística|0', viewValue: 'Cística' },
    { value: 'quase completamente cística|0', viewValue: 'Quase completamente cística' },
    { value: 'espongiforme|0', viewValue: 'Espongiforme' },
    { value: 'sólido-cística|1', viewValue: 'Sólido-cística' },
    { value: 'sólida|2', viewValue: 'Sólida' },
    { value: 'quase completamente sólida|2', viewValue: 'Quase completamente sólida' }
  ];

  composicaoesMaiusculo: ArrayDropDown[] = [
    { value: 'Cística|0', viewValue: 'Cística' },
    { value: 'Quase completamente cística|0', viewValue: 'Quase completamente cística' },
    { value: 'Espongiforme|0', viewValue: 'Espongiforme' },
    { value: 'Sólido-cística|1', viewValue: 'Sólido-cística' },
    { value: 'Sólida|2', viewValue: 'Sólida' },
    { value: 'Quase completamente sólida|2', viewValue: 'Quase completamente sólida' }
  ];

  ecogenicidades: ArrayDropDown[] = [
    { value: 'anecoica|0', viewValue: 'Anecoica' },
    { value: 'hiperecoica|1', viewValue: 'Hiperecoica' },
    { value: 'isoecoica|1', viewValue: 'Isoecoica' },
    { value: 'hipoecoica|2', viewValue: 'Hipoecoica' },
    { value: 'marcadamente hipoecoica|3', viewValue: 'Marcadamente hipoecoica' }
  ];

  formatos: ArrayDropDown[] = [
    { value: 'paralela à pele|0', viewValue: 'Paralela à pele (horizontal)' },
    { value: 'perpendicular à pele|3', viewValue: 'Perpendicular à pele (vertical)' }
  ];

  margens: ArrayDropDown[] = [
    { value: 'margens bem definidas|0', viewValue: 'Bem definidas' },
    { value: 'margens mal definidas|0', viewValue: 'Mal definidas' },
    { value: 'margens lobuladas|2', viewValue: 'lobuladas' },
    { value: 'margens irregulares|2', viewValue: 'Irregulares' },
    { value: 'com extensão extra-tireoidiana|3', viewValue: 'Extensão extra-tireoidiana' }
  ];

  focosEcogenicos: ArrayDropDown[] = [
    { value: 'sem focos ecogênicos no seu interior|0', viewValue: 'Sem focos ecogênicos no seu interior' },
    { value: 'com formação de artefatos em cauda de cometa|0', viewValue: 'Formação de artefatos em cauda de cometa' },
    { value: 'com macrocalcificações de permeio|1', viewValue: 'Macrocalcificações de permeio' },
    { value: 'com calcificações periféricas|2', viewValue: 'Calcificações periféricas' },
    { value: 'com múltiplos focos ecogênicos no seu interior|3', viewValue: 'Múltiplos focos ecogênicos no seu interior' }
  ];

  lesoesForm: FormGroup;
  linha = 0;
  objeto: any;
  objetofilho: any;
  countLesoes = 0;
  showAndHideTerms: any;
  volumeLoboDireito = 'Volume';
  medindoLoboDireito = 0;
  volumeLoboEsquerdo = 'Volume';
  medindoLoboEsquerdo = 0;
  volumeIstmo = 'Volume';
  medindoIstmo = 0;
  medidaLoboParcial = 'Medida lobo esquerdo';
  volumeLoboParcial = 'Volume lobo esquerdo';

  cistoEsparsosLocalizacao = 'Localização';
  cistoEsparsosMedida = 'Medida';

  dimensaoSelected: string;
  contornoSelected: string;
  ecogenicidadeSelected: string;
  formatoSelected: string;
  margemSelected: string;
  focoEcogenicoSelected: string;
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private _config: ConfigLaudoService) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormalTireoidopatia: true,
      sahTireoidopatia: false,
      sahTireoidectomia: false,
      sahLobos: true,
      sahParcial: false,
      sahLesao: true,
      sahCisto: false,
      sahNodulo: false,
      sahUmNodulo: false,
      sahDoisNodulo: false,
      sahNoduloNaoCaractSem: false,
      sahNoduloCaractSem: false
    };
    this.lesoesForm = this._fb.group({
      nodulosNaoSem: this._fb.array([this.addNodulosNaoSemGrup()]),
      nodulosSem: this._fb.array([this.addNodulosSemGrup()]),
    });

    this.dimensaoSelected = this.dimensoes[0].value;
    this.contornoSelected = this.contornos[0].value;
    this.ecogenicidadeSelected = this.ecogenicidades[3].value;
    this.formatoSelected = this.formatos[0].value;
    this.margemSelected = this.margens[0].value;
    // this.focoEcogenicoSelected = this.focosEcogenicos[0].value;
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  // NODULOS NÃO SEM
  addNodulosNaoSemGrup() {
    return this._fb.group({
      composicao: [],
      ecogenicidade: [],
      formato: [],
      margens: [],
      focoEcoginico: [],
      localizacao: [],
      medindo: []
    });
  }

  addNodulosNaoSem() {
    this.nodulosNaoSemArray.push(this.addNodulosNaoSemGrup());
    this.catchIDs();
  }

  removeNodulosNaoSem(index) {
    this.nodulosNaoSemArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get nodulosNaoSemArray() {
    return <FormArray>this.lesoesForm.get('nodulosNaoSem');
  }

  // NODULOS SEM
  addNodulosSemGrup() {
    return this._fb.group({
      composicao: [],
      ecogenicidade: [],
      localizacao: [],
      medindo: []
    });
  }

  addNodulosSem() {
    this.nodulosSemArray.push(this.addNodulosSemGrup());
    this.catchIDs();
  }

  removeNodulosSem(index) {
    this.nodulosSemArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get nodulosSemArray() {
    return <FormArray>this.lesoesForm.get('nodulosSem');
  }

  clickNormal() {
    this.showAndHideTerms.sahNormalTireoidopatia = true;
    this.showAndHideTerms.sahTireoidopatia = false;
    this.showAndHideTerms.sahTireoidectomia = false;
    this.showAndHideTerms.sahLesao = true;
    this.showAndHideTerms.sahLobos = true;
    this.catchIDs();
  }

  clickTireoidopatia() {
    this.showAndHideTerms.sahNormalTireoidopatia = true;
    this.showAndHideTerms.sahTireoidopatia = true;
    this.showAndHideTerms.sahTireoidectomia = false;
    this.showAndHideTerms.sahLesao = true;
    this.showAndHideTerms.sahLobos = true;
    this.catchIDs();
  }

  clickEsbocoNodulares() {
    if (this.ckEsbocosNodulares != undefined && this.ckEsbocosNodulares.checked) {
      this.showAndHideTerms.sahLesao = false;
      this.showAndHideTerms.sahLobos = true;
      this.showAndHideTerms.sahCisto = false;
      this.showAndHideTerms.sahNodulo = false;
    } else {
      this.showAndHideTerms.sahLesao = true;
    }
    this.catchIDs();
  }

  clickTireoidectomia() {
    this.showAndHideTerms.sahTireoidectomia = true;
    this.showAndHideTerms.sahNormalTireoidopatia = true;
    this.showAndHideTerms.sahTireoidopatia = false;
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahLesao = false;
    this.showAndHideTerms.sahLobos = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahCisto = false;
    this.catchIDs();
  }

  clickTotal() {
    this.showAndHideTerms.sahParcial = false;
    this.showAndHideTerms.sahLesao = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahLobos = false;
    this.showAndHideTerms.sahCisto = false;
    this.catchIDs();
  }

  clickParcial() {
    this.medidaLoboParcial = 'Medida lobo esquerdo';
    this.volumeLoboParcial = 'Volume lobo esquerdo';
    this.showAndHideTerms.sahParcial = true;
    this.showAndHideTerms.sahLesao = true;
    this.showAndHideTerms.sahLobos = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahCisto = false;
    this.catchIDs();
  }

  clickDireito() {
    this.medidaLoboParcial = 'Medida lobo esquerdo';
    this.volumeLoboParcial = 'Volume lobo esquerdo';
    this.catchIDs();
  }

  clickEsquerdo() {
    this.medidaLoboParcial = 'Medida lobo direito';
    this.volumeLoboParcial = 'Volume lobo direito';
    this.catchIDs();
  }

  clickCisto() {
    if (this.showAndHideTerms.sahCisto == false) {
      this.showAndHideTerms.sahCisto = true;
    } else {
      this.showAndHideTerms.sahCisto = false;
    }
    this.catchIDs();
  }

  clickCistoColoide() {
    this.cistoEsparsosLocalizacao = 'Localização';
    this.cistoEsparsosMedida = 'Medida';
    this.catchIDs();
  }

  clickCistoEsparso() {
    this.cistoEsparsosLocalizacao = 'Localização do Maior';
    this.cistoEsparsosMedida = 'Maior Medida';
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo == false) {
      this.showAndHideTerms.sahNodulo = true;
      this.showAndHideTerms.sahUmNodulo = true;
      this.showAndHideTerms.sahDoisNodulo = false;
      this.showAndHideTerms.sahNoduloCaractSem = false;
      this.showAndHideTerms.sahNoduloNaoCaractSem = false;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickUmNodulo() {
    this.showAndHideTerms.sahUmNodulo = true;
    this.showAndHideTerms.sahDoisNodulo = false;
    this.showAndHideTerms.sahNoduloCaractSem = false;
    this.showAndHideTerms.sahNoduloNaoCaractSem = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickDoisNodulo() {
    this.showAndHideTerms.sahDoisNodulo = true;
    this.showAndHideTerms.sahNoduloNaoCaractSem = false;
    this.showAndHideTerms.sahNoduloCaractSem = false;
    this.showAndHideTerms.sahUmNodulo = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickNodulosNaoSem() {
    if (this.showAndHideTerms.sahNoduloNaoCaractSem == false) {
      this.showAndHideTerms.sahNoduloNaoCaractSem = true;
    } else {
      this.showAndHideTerms.sahNoduloNaoCaractSem = false;
    }
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickNodulosSem() {
    if (this.showAndHideTerms.sahNoduloCaractSem == false) {
      this.showAndHideTerms.sahNoduloCaractSem = true;
    } else {
      this.showAndHideTerms.sahNoduloCaractSem = false;
    }
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  clickTabelaReferencia() {
    this.dialog.open(TireoideDialogComponent, {});
  }

  clickTabelaReferenciaTuring() {
    this.tabelaReferenciaAcr.emit(null);
    let dialogRef = this.dialog.open(TireoideTuringDialogComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      this.tabelaReferenciaAcr.emit(result);
    });
  }


  clickImagemNivelCervical() {
    this.dialog.open(TireoideDopplerNivelCervicalComponent, {});
  }


  clickTabelaReferenciaTirad() {
    this.tabelaReferencia.emit(null);
    let dialogRef = this.dialog.open(TireoideTiradDialogComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      this.tabelaReferencia.emit(result);
    });
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.countLesoes = 0;
    this.volumeLoboDireito = 'Volume';
    this.volumeLoboEsquerdo = 'Volume';
    this.volumeIstmo = 'Volume';

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);

      if (this.ckCisto != undefined && this.ckCisto.checked) {
        this.emitCisto();
      }

      if (this.ckNodulo != undefined && this.ckNodulo.checked) {
        if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked && this.countLesoes >= 1) {
          this.emitUmNoduloLesao();
        } else if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
          this.emitUmNodulo();
        }

        if (this.rdDoisNodulo != undefined && this.rdDoisNodulo.checked) {
          if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked && this.countLesoes >= 1) {
            this.emitNodulosNaoSemLesao();
          } else if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked) {
            this.emitNodulosNaoSem();
          }
          if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked && this.countLesoes >= 1) {
            this.emitNodulosSemLesao();
          } else if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked) {
            this.emitNodulosSem();
          }
        }
      }
      this.emitMedidas();
    }

    if (this.rdTireoidopatia.checked) {
      this.termoSelecionado.emit(this.rdTireoidopatia.id);

      if (this.slContornoTireoidopatia != undefined && this.slContornoTireoidopatia.value != undefined) {
        const objeto = { elemento: this.slContornoTireoidopatia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 648, value: this.contornoSelected }
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slDimensaoTireoidopatia != undefined && this.slDimensaoTireoidopatia.value != undefined) {
        const objeto = { elemento: this.slDimensaoTireoidopatia, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } else {
        const elemento = { id: 647, value: this.dimensaoSelected }
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckEsbocosNodulares != undefined && this.ckEsbocosNodulares.checked) {
        this.termoSelecionado.emit(this.ckEsbocosNodulares.id);
      }

      if (this.ckCisto != undefined && this.ckCisto.checked &&
        (this.ckEsbocosNodulares == undefined || !this.ckEsbocosNodulares.checked)) {
        this.emitCisto();
      }

      if (this.ckNodulo != undefined && this.ckNodulo.checked &&
        (!this.ckEsbocosNodulares || !this.ckEsbocosNodulares.checked)) {
        if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked && this.countLesoes >= 1) {
          this.emitUmNoduloLesao();
        } else if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
          this.emitUmNodulo();
        }

        if (this.rdDoisNodulo != undefined && this.rdDoisNodulo.checked) {
          if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked && this.countLesoes >= 1) {
            this.emitNodulosNaoSemLesao();
          } else if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked) {
            this.emitNodulosNaoSem();
          }
          if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked && this.countLesoes >= 1) {
            this.emitNodulosSemLesao();
          } else if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked) {
            this.emitNodulosSem();
          }
        }
      }

      this.emitMedidas();

    }

    if (this.rdTireoidectomia.checked) {
      this.termoSelecionado.emit(this.rdTireoidectomia.id);

      if (this.rdTotal != undefined && this.rdTotal.checked) {
        this.termoSelecionado.emit(this.rdTotal.id);

      } else if (this.rdParcial != undefined && this.rdParcial.checked) {
        this.termoSelecionado.emit(this.rdParcial.id);

        if (this.rdDireito != undefined && this.rdDireito.checked) {
          this.termoSelecionado.emit(this.rdDireito.id);
        }

        if (this.rdEsquerdo != undefined && this.rdEsquerdo.checked) {
          this.termoSelecionado.emit(this.rdEsquerdo.id);
        }

        this.formataMedindoLoboParcial();

        if (this.ckCisto != undefined && this.ckCisto.checked) {
          this.emitCisto();
        }

        if (this.ckNodulo != undefined && this.ckNodulo.checked) {
          if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked && this.countLesoes >= 1) {
            this.emitUmNoduloLesao();
          } else if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
            this.emitUmNodulo();
          }

          if (this.rdDoisNodulo != undefined && this.rdDoisNodulo.checked) {
            if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked && this.countLesoes >= 1) {
              this.emitNodulosNaoSemLesao();
            } else if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked) {
              this.emitNodulosNaoSem();
            }
            if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked && this.countLesoes >= 1) {
              this.emitNodulosSemLesao();
            } else if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked) {
              this.emitNodulosSem();
            }
          }
        }


      } else {
        // rdTotal
        this.termoSelecionado.emit(636);
      }

      // if (this.ckCisto != undefined && this.ckCisto.checked) {
      //   this.emitCistoLesao();
      // }

      // if (this.ckNodulo != undefined && this.ckNodulo.checked) {
      //   if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
      //     this.emitUmNoduloLesao();
      //   }

      //   if (this.rdDoisNodulo != undefined && this.rdDoisNodulo.checked) {
      //     if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked) {
      //       this.countLesoes++;
      //       this.emitNodulosNaoSemLesao();
      //     }
      //     if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked && this.countLesoes >= 1) {
      //       this.emitNodulosSemLesao();
      //     } else {
      //       this.countLesoes++;
      //       this.emitNodulosSemLesao();
      //     }
      //   }
      // }
    }

    this.atualizaTexto.emit();
    this.listaCompleta.emit();
  }

  emitMedidas() {
    this.countLesoes++;
    this.formataMedindoLoboDireito();
    this.formataMedindoLoboEsquerdo();
    this.formataMedindoIstmo();

    if (this.inVolumeTotal && this.medindoLoboDireito > 0 || this.medindoLoboEsquerdo > 0 || this.medindoIstmo > 0) {

      const volumeTotal = this.medindoLoboDireito + this.medindoLoboEsquerdo + this.medindoIstmo;
      this.inVolumeTotal.nativeElement.value = volumeTotal.toFixed(1);

      const objeto = { elemento: this.inVolumeTotal.nativeElement, isPrimeiraLesao: false, cenario: this.countLesoes };
      this.termosGenericos.emit(objeto);
    }
  }

  emitCisto() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckCisto.id);

    if (this.rdCistoColoide != undefined && this.rdCistoColoide.checked) {
      this.termoSelecionado.emit(this.rdCistoColoide.id);
    }

    if (this.rdCistoEsparsos != undefined && this.rdCistoEsparsos.checked) {
      this.termoSelecionado.emit(this.rdCistoEsparsos.id);
    }

    if (this.slLocalizacaoCisto != undefined && this.slLocalizacaoCisto.value != undefined) {
      const objeto = { elemento: this.slLocalizacaoCisto, isPrimeiraLesao: true, iscisto: true, isnodsem: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inMedidaCisto != undefined &&
      this.inMedidaCisto.nativeElement.id != undefined &&
      this.inMedidaCisto.nativeElement.value != '') {

      const objeto = { elemento: this.inMedidaCisto.nativeElement, isPrimeiraLesao: true, iscisto: true, isnodsem: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitCistoLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckCisto.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rdCistoColoide != undefined && this.rdCistoColoide.checked) {
      const objeto = { id: this.rdCistoColoide.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }

    if (this.rdCistoEsparsos != undefined && this.rdCistoEsparsos.checked) {
      const objeto = { id: this.rdCistoEsparsos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
    }

    if (this.slLocalizacaoCisto != undefined && this.slLocalizacaoCisto.value != undefined) {
      const objeto = { elemento: this.slLocalizacaoCisto, isPrimeiraLesao: false, iscisto: true, isnodsem: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inMedidaCisto != undefined &&
      this.inMedidaCisto.nativeElement.id != undefined &&
      this.inMedidaCisto.nativeElement.value != '') {

      const objeto = { elemento: this.inMedidaCisto.nativeElement, isPrimeiraLesao: false, iscisto: true, isnodsem: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitUmNodulo() {
    this.termoSelecionado.emit(this.ckNodulo.id);

    if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
      this.termoSelecionado.emit(this.rdUmNodulo.id);

      if (this.slComposicaoNodulo != undefined && this.slComposicaoNodulo.value != undefined) {
        const objeto = { elemento: this.slComposicaoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slEcogenicidadeNodulo != undefined) {
        if (this.slEcogenicidadeNodulo.value != undefined) {
          const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 658, value: this.ecogenicidadeSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slFormatoNodulo != undefined) {
        if (this.slFormatoNodulo.value != undefined) {
          const objeto = { elemento: this.slFormatoNodulo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 659, value: this.formatoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slMargemNodulo != undefined) {
        if (this.slMargemNodulo.value != undefined) {
          const objeto = { elemento: this.slMargemNodulo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 660, value: this.margemSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slFocosEcogenicosNodulo != undefined) {
        if (this.slFocosEcogenicosNodulo.value != undefined) {
          const objeto = { elemento: this.slFocosEcogenicosNodulo, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slLocalizacaoNodulo != undefined && this.slLocalizacaoNodulo.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo();
    }
  }

  emitUmNoduloLesao() {
    this.countLesoes++;
    const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.rdUmNodulo != undefined && this.rdUmNodulo.checked) {
      const objeto = { id: this.rdUmNodulo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      if (this.slComposicaoNodulo != undefined && this.slComposicaoNodulo.value != undefined) {
        const objeto = { elemento: this.slComposicaoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slEcogenicidadeNodulo != undefined) {
        if (this.slEcogenicidadeNodulo.value != undefined) {
          const objeto = { elemento: this.slEcogenicidadeNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 658, value: this.ecogenicidadeSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slFormatoNodulo != undefined) {
        if (this.slFormatoNodulo.value != undefined) {
          const objeto = { elemento: this.slFormatoNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 659, value: this.formatoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slMargemNodulo != undefined) {
        if (this.slMargemNodulo.value != undefined) {
          const objeto = { elemento: this.slMargemNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 660, value: this.margemSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slFocosEcogenicosNodulo != undefined) {
        if (this.slFocosEcogenicosNodulo.value != undefined) {
          const objeto = { elemento: this.slFocosEcogenicosNodulo, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slLocalizacaoNodulo != undefined && this.slLocalizacaoNodulo.value != undefined) {
        const objeto = { elemento: this.slLocalizacaoNodulo, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoNodulo();

    }
  }

  emitNodulosNaoSem() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    this.termoSelecionado.emit(this.ckNodulo.id);

    if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked) {
      this.termoSelecionado.emit(this.ckNodulosNaoSem.id);

      this.nodulosNaoSemChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 671) {
            if (posicao < 3) {
              posicao++;
            } else {
              posicao = 1;
            }
          }

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {

          if (obj.id == 665) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }

  emitNodulosNaoSemLesao() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.ckNodulosNaoSem != undefined && this.ckNodulosNaoSem.checked) {
      const objeto = { id: this.ckNodulosNaoSem.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      this.nodulosNaoSemChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 671) {
            if (posicao < 3) {
              posicao++;
            } else {
              posicao = 1;
            }
          }

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        } else {

          if (obj.id == 665) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }

  emitNodulosSem() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    this.termoSelecionado.emit(this.ckNodulo.id);

    if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked) {
      this.termoSelecionado.emit(this.ckNodulosSem.id);

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slFormatoNoduloSem != undefined) {
        if (this.slFormatoNoduloSem.value != undefined) {
          const objeto = { elemento: this.slFormatoNoduloSem, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 675, value: this.formatoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slMargemNoduloSem != undefined) {
        if (this.slMargemNoduloSem.value != undefined) {
          const objeto = { elemento: this.slMargemNoduloSem, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 676, value: this.margemSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slFocoEcogenicoNoduloSem != undefined) {
        if (this.slFocoEcogenicoNoduloSem.value != undefined) {
          const objeto = { elemento: this.slFocoEcogenicoNoduloSem, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 677, value: this.focoEcogenicoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      this.nodulosSemChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 679) {
            if (posicao < 3) {
              posicao++;
            } else {
              posicao = 1;
            }
          }

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        } else {

          if (obj.id == 673) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }

  emitNodulosSemLesao() {
    this.countLesoes++;
    let posicao = 0;
    this.linha = 0;
    const objeto = { id: this.ckNodulo.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objeto);

    if (this.ckNodulosSem != undefined && this.ckNodulosSem.checked) {
      const objeto = { id: this.ckNodulosSem.id, cenario: this.countLesoes };

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slFormatoNoduloSem != undefined) {
        if (this.slFormatoNoduloSem.value != undefined) {
          const objeto = { elemento: this.slFormatoNoduloSem, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 675, value: this.formatoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slMargemNoduloSem != undefined) {
        if (this.slMargemNoduloSem.value != undefined) {
          const objeto = { elemento: this.slMargemNoduloSem, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 676, value: this.margemSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      // POG - Ajustar isso e pegar pelo form. Ass: Gabs
      if (this.slFocoEcogenicoNoduloSem != undefined) {
        if (this.slFocoEcogenicoNoduloSem.value != undefined) {
          const objeto = { elemento: this.slFocoEcogenicoNoduloSem, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const elemento = { id: 677, value: this.focoEcogenicoSelected }
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
      this.lesoesSelecionadas.emit(objeto);

      this.nodulosSemChildren.forEach(obj => {

        if (obj.nativeElement != undefined) {

          if (obj.nativeElement.id == 679) {
            if (posicao < 3) {
              posicao++;
            } else {
              posicao = 1;
            }
          }

          this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        } else {

          if (obj.id == 673) {
            this.linha++;
          }

          this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
          this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
          this.lesoesDinamicas.emit(this.objeto);
        }
      });
    }
  }

  formataMedindoLoboDireito() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inMedidaLoboDireito1 != undefined &&
      this.inMedidaLoboDireito1.nativeElement.id != undefined &&
      this.inMedidaLoboDireito1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboDireito1.nativeElement.value);
      resultado = this.inMedidaLoboDireito1.nativeElement.value;
    }

    if (this.inMedidaLoboDireito2 != undefined &&
      this.inMedidaLoboDireito2.nativeElement.id != undefined &&
      this.inMedidaLoboDireito2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLoboDireito2.nativeElement.value);
        resultado = resultado * this.inMedidaLoboDireito2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboDireito2.nativeElement.value);
        resultado = this.inMedidaLoboDireito2.nativeElement.value;

      }
    }

    if (this.inMedidaLoboDireito3 != undefined &&
      this.inMedidaLoboDireito3.nativeElement.id != undefined &&
      this.inMedidaLoboDireito3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLoboDireito3.nativeElement.value);
        resultado = resultado * this.inMedidaLoboDireito3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboDireito3.nativeElement.value);
        resultado = this.inMedidaLoboDireito3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;
      this.volumeLoboDireito = resultado.toFixed(1).toString();

      if (this.inVolumeLoboDireito.nativeElement.value) {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(this.inVolumeLoboDireito.nativeElement.value) + ' ' + this.unidadeMedida + '³)';
        // Atribuindo a variavel para realizar o calculo
        this.medindoLoboDireito = +this.inVolumeLoboDireito.nativeElement.value;
      } else {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' ' + this.unidadeMedida + '³)';
        // Atribuindo a variavel para realizar o calculo
        this.medindoLoboDireito = +resultado.toFixed(1);
      }

      const objetoElemento = { id: 643, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: false, cenario: this.countLesoes };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoLoboEsquerdo() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inMedidaLoboEsquerdo1 != undefined &&
      this.inMedidaLoboEsquerdo1.nativeElement.id != undefined &&
      this.inMedidaLoboEsquerdo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboEsquerdo1.nativeElement.value);
      resultado = this.inMedidaLoboEsquerdo1.nativeElement.value;
    }

    if (this.inMedidaLoboEsquerdo2 != undefined &&
      this.inMedidaLoboEsquerdo2.nativeElement.id != undefined &&
      this.inMedidaLoboEsquerdo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLoboEsquerdo2.nativeElement.value);
        resultado = resultado * this.inMedidaLoboEsquerdo2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboEsquerdo2.nativeElement.value);
        resultado = this.inMedidaLoboEsquerdo2.nativeElement.value;

      }
    }

    if (this.inMedidaLoboEsquerdo3 != undefined &&
      this.inMedidaLoboEsquerdo3.nativeElement.id != undefined &&
      this.inMedidaLoboEsquerdo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLoboEsquerdo3.nativeElement.value);
        resultado = resultado * this.inMedidaLoboEsquerdo3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboEsquerdo3.nativeElement.value);
        resultado = this.inMedidaLoboEsquerdo3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;
      this.volumeLoboEsquerdo = resultado.toFixed(1).toString();

      if (this.inVolumeLoboEsquerdo.nativeElement.value) {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(this.inVolumeLoboEsquerdo.nativeElement.value) + ' ' + this.unidadeMedida + '³)';
        // Atribuindo a variavel para realizar o calculo
        this.medindoLoboEsquerdo = +this.inVolumeLoboEsquerdo.nativeElement.value;

      } else {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' ' + this.unidadeMedida + '³)';
        // Atribuindo a variavel para realizar o calculo
        this.medindoLoboEsquerdo = +resultado.toFixed(1);
      }

      const objetoElemento = { id: 644, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: false };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoIstmo() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;
    let countMedida = 0;

    if (this.inMedidaIstmo1 != undefined &&
      this.inMedidaIstmo1.nativeElement.id != undefined &&
      this.inMedidaIstmo1.nativeElement.value != '') {
      countMedida++

      medindoValor = this._validacoes.formataDecimal(this.inMedidaIstmo1.nativeElement.value);
      resultado = this.inMedidaIstmo1.nativeElement.value;
    }

    if (this.inMedidaIstmo2 != undefined &&
      this.inMedidaIstmo2.nativeElement.id != undefined &&
      this.inMedidaIstmo2.nativeElement.value != '') {
      countMedida++

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaIstmo2.nativeElement.value);
        resultado = resultado * this.inMedidaIstmo2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaIstmo2.nativeElement.value);
        resultado = this.inMedidaIstmo2.nativeElement.value;

      }
    }

    if (this.inMedidaIstmo3 != undefined &&
      this.inMedidaIstmo3.nativeElement.id != undefined &&
      this.inMedidaIstmo3.nativeElement.value != '') {
      countMedida++

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaIstmo3.nativeElement.value);
        resultado = resultado * this.inMedidaIstmo3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaIstmo3.nativeElement.value);
        resultado = this.inMedidaIstmo3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;

      this.volumeIstmo = resultado.toFixed(1).toString();

      if (countMedida == 1) {
        this.volumeIstmo = 'Volume';
        medindoValor = medindoValor + ' ' + this.unidadeMedida;
        // Atribuindo a variavel para realizar o calculo
        this.medindoIstmo = +this.inVolumeLoboIstmo.nativeElement.value;
      } else if (this.inVolumeLoboIstmo.nativeElement.value) {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(this.inVolumeLoboIstmo.nativeElement.value) + ' ' + this.unidadeMedida + '³)';
        // Atribuindo a variavel para realizar o calculo
        this.medindoIstmo = +this.inVolumeLoboIstmo.nativeElement.value;
      } else {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' ' + this.unidadeMedida + '³)';
        // Atribuindo a variavel para realizar o calculo
        this.medindoIstmo = +resultado.toFixed(1);
      }

      const objetoElemento = { id: 645, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: false, cenario: this.countLesoes };
      this.termosGenericos.emit(objetoLesao);
    }

  }

  formataMedindoLoboParcial() {
    let medindoValor = '';
    // let isPrimeiraLesao = false;
    let resultado = 0;

    if (this.inMedidaLoboParcial1 != undefined &&
      this.inMedidaLoboParcial1.nativeElement.id != undefined &&
      this.inMedidaLoboParcial1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboParcial1.nativeElement.value);
      resultado = this.inMedidaLoboParcial1.nativeElement.value;
    }

    if (this.inMedidaLoboParcial2 != undefined &&
      this.inMedidaLoboParcial2.nativeElement.id != undefined &&
      this.inMedidaLoboParcial2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLoboParcial2.nativeElement.value);
        resultado = resultado * this.inMedidaLoboParcial2.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboParcial2.nativeElement.value);
        resultado = this.inMedidaLoboParcial2.nativeElement.value;

      }
    }

    if (this.inMedidaLoboParcial3 != undefined &&
      this.inMedidaLoboParcial3.nativeElement.id != undefined &&
      this.inMedidaLoboParcial3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaLoboParcial3.nativeElement.value);
        resultado = resultado * this.inMedidaLoboParcial3.nativeElement.value;
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaLoboParcial3.nativeElement.value);
        resultado = this.inMedidaLoboParcial3.nativeElement.value;
      }
    }

    if (medindoValor != '') {
      // Volume
      resultado = resultado * 0.523;
      this.volumeLoboParcial = resultado.toFixed(1).toString();

      if (this.inVolumeLoboParcial.nativeElement.value) {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(this.inVolumeLoboParcial.nativeElement.value) + ' ' + this.unidadeMedida + '³)';
      } else {
        medindoValor = medindoValor + ' ' + this.unidadeMedida + ' (volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' ' + this.unidadeMedida + '³)';
      }

      const objetoElemento = { id: 640, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: true, cenario: this.countLesoes };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoNodulo() {
    let medindoValor = '';
    let isPrimeiraLesao = false;

    if (this.inMedidaNodulo1 != undefined &&
      this.inMedidaNodulo1.nativeElement.id != undefined &&
      this.inMedidaNodulo1.nativeElement.value != '') {

      medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo1.nativeElement.value);
    }

    if (this.inMedidaNodulo2 != undefined &&
      this.inMedidaNodulo2.nativeElement.id != undefined &&
      this.inMedidaNodulo2.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo2.nativeElement.value);
      }
    }

    if (this.inMedidaNodulo3 != undefined &&
      this.inMedidaNodulo3.nativeElement.id != undefined &&
      this.inMedidaNodulo3.nativeElement.value != '') {

      if (medindoValor != '') {
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      } else {
        medindoValor = this._validacoes.formataDecimal(this.inMedidaNodulo3.nativeElement.value);
      }
    }

    if (medindoValor != '') {
      if (this.countLesoes < 1) {
        isPrimeiraLesao = true;
      }

      const objetoElemento = { id: 663, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  ajustaArrayDinamico() {

    if (this.ckNodulosNaoSem != undefined && !this.ckNodulosNaoSem.checked
      || this.rdDoisNodulo != undefined && !this.rdDoisNodulo.checked
      || !this.ckNodulo.checked) {

      const arrayTotal = this.nodulosNaoSemArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.nodulosNaoSemArray.length - 1

        if (index != 0) {
          this.nodulosNaoSemArray.removeAt(index);
        }
      }
      this.nodulosNaoSemArray.reset();
    }

    if (this.ckNodulosSem != undefined && !this.ckNodulosSem.checked
      || this.rdDoisNodulo != undefined && !this.rdDoisNodulo.checked
      || !this.ckNodulo.checked) {

      const arrayTotal = this.nodulosSemArray.length;

      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.nodulosSemArray.length - 1

        if (index != 0) {
          this.nodulosSemArray.removeAt(index);
        }
      }
      this.nodulosSemArray.reset();
    }

  }

  getArray(element): any[] {
    if (element) {
      if (element.checked && element.id === '638')
        return this.localizacoesEsquerda;
      else
        return this.localizacoesDireita;
    } else {
      return this.localizacoes;
    }
  }

}
