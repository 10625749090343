import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './../auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertDialogComponent } from '../utils/dialog/alert-dialog/alert-dialog.component';
import { NotificationDialogComponent } from '../utils/dialog/notification-dialog/notification-dialog.component';
import { Notificacao } from '../models/BO/Notificacao';
import { NotificationService } from '../services/notification-service.service';
import { take } from 'rxjs/operators';
import { ArrayDropDown } from '../models/Helper/ArrayDropDown';
import { FeedbackDialogComponent } from '../utils/dialog/feedback-dialog/feedback-dialog.component';
import { PouchDBService } from '../services/pouchdb.service';
import { ConnectionService } from '../services/connection.service';
import { MedicalReportService } from '../services/medicalReport.service';
import { MedicalReportLogService } from '../services/MedicalReportLog.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ListaPlanosService } from '../services/lista-planos.service';
import { Speciality } from '../models/BO/Speciality';
import { ListaPlanosFavoritosService } from '../services/lista-planos-favoritos.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmDialogComponent } from '../utils/dialog/confirm-dialog/confirm-dialog.component';
import { FirebaseService } from '../services/firebase.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {

  isLoggedIn$: Observable<boolean>;
  isIntegration$: Observable<boolean>;
  badgeCounter: number;
  favCounter: number;
  hideMatBadge: boolean;
  notificacao: Notificacao[] = [];
  favoritos: Speciality[] = [];
  loading: boolean;
  trialEnd: number = -1;
  rate: number = 1;
  subscription: Subscription
  removable: true;
  qtdFav: number;
  qtdFavShow: number;
  constructor(private authService: AuthService,
    private router: Router,
    private _dialog: MatDialog,
    private _service: NotificationService,
    private _connection: ConnectionService,
    private _pouch: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _servicePlanosFavoritos: ListaPlanosFavoritosService,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _toastr: ToastrManager,
    private _firebase: FirebaseService
  ) {

  }

  ngOnInit() {
    this.carregarNotificacoes();
    this.carregarFavoritos();

    const userStorage: any = JSON.parse(localStorage.getItem('usuario'));
    const path = window.location.pathname;

    if (userStorage) {
      if (userStorage.authenticated) {
        if (path === '/login' || path === '/') {
          this.authService.loggedIn.next(false);
          this.authService.integrationRoute.next(false);
        } else {
          this.authService.loggedIn.next(true);
          this.authService.integrationRoute.next(true);
          //this.carregarNotificacoes();
        }
      } else {
        this.authService.loggedIn.next(false);
        this.authService.integrationRoute.next(false);
      }
    }
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isIntegration$ = this.authService.isIntegrationRoute;
    // Alert Badge
    this.badgeCounter = 1;
    this.hideMatBadge = false;
    this.checkConnection();
  }

  dialogFeedback() {
    this._dialog.open(FeedbackDialogComponent, {});
  }

  onLogout() {
    this.authService.logout();
  }

  clickButton(url) {
    this.router.navigateByUrl(url);
  }

  clickAlert() {
    //this.comunicaTradutor();
    //this.badgeCounter;
    this.hideMatBadge = true;
  }

  carregarNotificacoes() {
    this._service.buscarNotificacao()
      .pipe(take(1)).toPromise().then(notificacao => {
        this.notificacao = notificacao;
        this.badgeCounter = this.notificacao.length;
      },
        erro => {
          console.log(erro);
        });
  }

  carregarFavoritos() {
    const userId =+ localStorage.getItem('userID');
    if (userId) {
      this._servicePlanosFavoritos.buscarFavoritosPorUsuario(userId);
      this.favoritos = this._servicePlanosFavoritos.favoritos;
    }
  }

  getfavoritos(): Speciality[] {
    this.adicionaRemoveMenuFav();
    this.favoritos = this._servicePlanosFavoritos.favoritos;
    return this.favoritos;
  }

  adicionaRemoveMenuFav() {
    let windowWidth = window.innerWidth;
    this.qtdFav = this._servicePlanosFavoritos.favoritos.length;

    if (windowWidth <= 800) {
      this.qtdFavShow = 2;
    } else if (windowWidth >= 801 && windowWidth <= 1025) {
      this.qtdFavShow = 3;
    } else if (windowWidth >= 1026 && windowWidth <= 1300) {
      this.qtdFavShow = 4;
    } else if (windowWidth >= 1301 && windowWidth <= 1500) {
      this.qtdFavShow = 5;
    }else if (windowWidth >= 1501) {
      this.qtdFavShow = 7;
    }
  }

  favMenuScrollBottom() {
    const menuFav = document.querySelector(".menu-favorite");
    menuFav.scrollTop = menuFav.scrollHeight;
  }

  removeFav(exame: Speciality) {
    const userId = localStorage.getItem('userID');

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      data: `info|Deseja excluir ${exame.description.substring(0, 1).toUpperCase() + exame.description.substring(1).toLowerCase()} dos exames favoritos?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this._servicePlanosFavoritos.isFavorito(exame)) {
          exame.favorite = false;
          this._servicePlanosFavoritos.apagarFavoritoUsuario(+userId, exame)
        }
      }
    });
  }

  openDialog(data) {
    const dialogRef = this._dialog.open(NotificationDialogComponent, {
      width: '450px',
      data: data.icon + "|" + data.title + "|" + data.message
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goTo(where: string) {
    this._firebase.logEnterMedicalReport("Favoritos");
    this.router.navigate([`/laudos/${where}`]);
  }

  checkConnection() {
    this.subscription = this._connection.connectionChanged.subscribe(result => {
      if (result == "online") {
        this.saveMedicalReportFromOffline();
        this.saveLogMedicalReportFromOffline();
        this._toastr.successToastr('Conexão restabelecida.', 'Últimas alterações salvas',
          {
            position: 'top-right',
            toastTimeout: 4000,
            maxShown: 1
          }
        )
      } else {
        this._toastr.errorToastr('Conexão Perdida.', 'Últimas alterações não salvas',
          {
            position: 'top-right',
            toastTimeout: 4000,
            maxShown: 1
          }
        )
      }
    });
  }

  saveMedicalReportFromOffline() {
    this._pouch.getAll("tempLaudo").then((laudo) => {
      for (let i = 0; i < laudo.length; i++) {
        const obj = {
          UserID: laudo[i].doc.UserID,
          Content: laudo[i].doc.Content,
          ContentHtml: laudo[i].doc.ContentHtml,
          ReportCode: laudo[i].doc.ReportCode,
          MedicalReportTypeID: laudo[i].doc.MedicalReportTypeID,
        };
        this._serviceMedicalReport.criaLaudo(obj, true);
      }
      this._pouch.deleteDaabase("tempLaudo");
    });
  }

  saveLogMedicalReportFromOffline() {
    this._pouch.getAll("LogMedicalReport").then((log) => {
      for (let i = 0; i < log.length; i++) {
        const obj = {
          UserID: log[i].doc.UserID,
          MedicalReportTypeID: log[i].doc.MedicalReportTypeID,
          ContentReport: log[i].doc.ContentReport,
          MedicalReportLogTypeID: log[i].doc.MedicalReportLogTypeID,//1
        };
        this._serviceMedicalReportLog.saveLogMedicalReport(obj);
      }
      this._pouch.deleteDaabase("LogMedicalReport");
      this._pouch.loadLaudosDatabaseByBlocks(this._pouch.laudoAtual.listBlocks, this._pouch.laudoAtual.nomeBanco, this._pouch.laudoAtual.languageID);
    });
  }
  // IMPLEMENTAR A PARTIR DO ANG MAT 8

  // async drop(event: CdkDragDrop<string[]>) {
  //   if(this._servicePlanosFavoritos.inclusaoFinalizada){
  //     moveItemInArray(this.favoritos, event.previousIndex, event.currentIndex);
  //     this._servicePlanosFavoritos.ApagarTodosFavoritosUsuario(this.favoritos);
  //     this._servicePlanosFavoritos.IncluirTodosFavoritosUsuario(this.favoritos);
  //   }
  // }
}
