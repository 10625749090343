import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IterableChangeRecord_ } from '@angular/core/src/change_detection/differs/default_iterable_differ';
import { MatCheckbox, MatDialog, MatRadioButton } from '@angular/material';
import { AnguloGrafDialogComponent } from 'src/app/utils/dialog/table/angulo-graf-dialog/angulo-graf-dialog.component';
import { FetoDialogComponent } from 'src/app/utils/dialog/table/feto-dialog/feto-dialog.component';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-angulos-graf',
  templateUrl: './angulos-graf.component.html',
  styleUrls: ['./angulos-graf.component.css']
})
export class AngulosGrafComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdMenorTres') rdMenorTres: MatRadioButton;
  @ViewChild('rdMaiorTres') rdMaiorTres: MatRadioButton;
  @ViewChild('rdCabecaLux') rdCabecaLux: MatRadioButton;

  @ViewChild('inAnguloAlfa') inAnguloAlfa: ElementRef;
  @ViewChild('inAnguloBeta') inAnguloBeta: ElementRef;

  @ViewChild('ckAlteracao') ckAlteracao: MatCheckbox;


  constructor(private _validacoes: Validacoes, public dialog: MatDialog) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahTabela1: false,
      sahTabela2: false,
      sahCheck: false,
    };
  }

  clickTabelaReferencia() {
    this.dialog.open(AnguloGrafDialogComponent, {});
  }

  clickMenor() {
    this.showAndHideTerms.sahTabela1 = true;
    this.showAndHideTerms.sahTabela2 = false;
    this.catchIDs();
    this.showAndHideTerms.sahCheck = false;
  }

  clickMaior() {
    this.showAndHideTerms.sahTabela2 = true;
    this.showAndHideTerms.sahTabela1 = false;
    this.catchIDs();
    this.showAndHideTerms.sahCheck = false;
  }

  clickCabeca() {
    this.showAndHideTerms.sahTabela1 = false;
    this.showAndHideTerms.sahTabela2 = false;
    this.showAndHideTerms.sahCheck = false;
    this.catchIDs();
  }

  blurMedida() {
    this.catchIDs();
  }

  clickAlteracao() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');
    this.enviaMedida.emit('Clean');

    if (this.rdMenorTres.checked) {
      this.termoSelecionado.emit(this.rdMenorTres.value);

      if (this.inAnguloAlfa && this.inAnguloAlfa.nativeElement.value) {
        const objeto = { value: this.inAnguloAlfa.nativeElement.value, id: this.inAnguloAlfa.nativeElement.id }
        this.enviaMedida.emit(objeto);
      }

      if (this.inAnguloBeta && this.inAnguloBeta.nativeElement.value) {
        const objeto = { value: this.inAnguloBeta.nativeElement.value, id: this.inAnguloBeta.nativeElement.id }
        this.enviaMedida.emit(objeto);
      }
      this.exibeCheckBox();

      this.calculaAngulo();
    }

    if (this.rdMaiorTres.checked) {
      this.termoSelecionado.emit(this.rdMaiorTres.value);

      if (this.inAnguloAlfa && this.inAnguloAlfa.nativeElement.value) {
        const objeto = { value: this.inAnguloAlfa.nativeElement.value, id: this.inAnguloAlfa.nativeElement.id }
        this.enviaMedida.emit(objeto);
      }

      if (this.inAnguloBeta && this.inAnguloBeta.nativeElement.value) {
        const objeto = { value: this.inAnguloBeta.nativeElement.value, id: this.inAnguloBeta.nativeElement.id }
        this.enviaMedida.emit(objeto);
      }
      this.exibeCheckBox();

      this.calculaAngulo();

    }

    if (this.rdCabecaLux.checked) {
      this.termoSelecionado.emit(+this.rdCabecaLux.value);

      if (this.inAnguloAlfa === undefined || this.inAnguloAlfa.nativeElement.valeu === undefined) {
        const objeto = { value: '-', id: '4162' }
        this.enviaMedida.emit(objeto);
      }

      if (this.inAnguloBeta === undefined || this.inAnguloBeta.nativeElement.valeu === undefined) {
        const objeto = { value: '-', id: '4163' }
        this.enviaMedida.emit(objeto);
      }
    }
    this.listaCompleta.emit();

  }


  exibeCheckBox() {
    if (this.inAnguloBeta && this.inAnguloAlfa) {
      if (this.inAnguloAlfa.nativeElement.value < 44 &&
        this.inAnguloBeta.nativeElement.value > 76) {
        this.showAndHideTerms.sahCheck = true;
      } else {
        this.showAndHideTerms.sahCheck = false;
      }
    }
  }

  calculaAngulo() {
    if (this.inAnguloBeta && this.inAnguloAlfa) {

      if (
        (this.rdMenorTres.checked || this.rdMaiorTres.checked) &&
        this.inAnguloAlfa.nativeElement.value > 60 &&
        this.inAnguloBeta.nativeElement.value < 55) {
        const objeto = { value: '1a', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);

      } else if ((this.rdMenorTres.checked || this.rdMaiorTres.checked) &&
        this.inAnguloAlfa.nativeElement.value > 60 &&
        this.inAnguloBeta.nativeElement.value > 55) {
        const objeto = { value: '1b', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);

      } else if (this.rdMenorTres.checked &&
        this.inAnguloAlfa.nativeElement.value > 49 &&
        this.inAnguloAlfa.nativeElement.value < 60 &&
        this.inAnguloBeta.nativeElement.value > 55) {
        const objeto = { value: '2a', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);

      } else if (this.rdMaiorTres.checked &&
        this.inAnguloAlfa.nativeElement.value > 49 &&
        this.inAnguloAlfa.nativeElement.value < 60 &&
        this.inAnguloBeta.nativeElement.value > 55) {
        const objeto = { value: '2b', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);

      } else if ((this.rdMenorTres.checked || this.rdMaiorTres.checked) &&
        this.inAnguloAlfa.nativeElement.value > 42 &&
        this.inAnguloAlfa.nativeElement.value < 50 &&
        this.inAnguloBeta.nativeElement.value > 69 &&
        this.inAnguloBeta.nativeElement.value < 78) {
        const objeto = { value: '2c', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);

      } else if ((this.rdMenorTres.checked || this.rdMaiorTres.checked) &&
        this.inAnguloAlfa.nativeElement.value > 42 &&
        this.inAnguloAlfa.nativeElement.value < 50 &&
        this.inAnguloBeta.nativeElement.value > 77) {
        const objeto = { value: '2d', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);

      } else if ((this.rdMenorTres.checked || this.rdMaiorTres.checked) &&
        this.inAnguloAlfa.nativeElement.value < 43 &&
        this.inAnguloBeta.nativeElement.value > 77) {

        if (this.ckAlteracao && this.ckAlteracao.checked) {
          this.termoSelecionado.emit(this.ckAlteracao.id);
          const objeto = { value: '3b', id: 4167 }
          let objetoCriado = { elemento: objeto };
          this.termosGenericos.emit(objetoCriado);

        } else {
          const objeto = { value: '3a', id: 4167 }
          let objetoCriado = { elemento: objeto };
          this.termosGenericos.emit(objetoCriado);
        }

      } else {
        const objeto = { value: '-', id: 4167 }
        let objetoCriado = { elemento: objeto };
        this.termosGenericos.emit(objetoCriado);
      }
    }
  }


}
