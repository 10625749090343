import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-senha-confirmacao',
  templateUrl: './senha-confirmacao.component.html',
  styleUrls: ['./senha-confirmacao.component.css']
})
export class SenhaConfirmacaoComponent implements OnInit {
  formRecSenha: FormGroup;
  private formSubmitAttempt: boolean;
  private token: string;

  constructor(
    private fb: FormBuilder,
    private _auth: AuthService,
    private toastr: ToastrManager,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.formRecSenha = this.fb.group({
      Senha: ['', Validators.nullValidator],
      ConfirmaSenha: ['', Validators.nullValidator]
    });

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.token = params.get('id');
        const user = { PasswordToken: this.token };
        this._auth.checkPasswordToken(user)
          .subscribe(res => {
            if (res && res.toString() === 'Token incorreto.') {
              this.toastr.errorToastr('Ocorreu um erro inesperado!', 'Erro');
              this.router.navigate(['/login']);
            }
          },
            err => {
              this.toastr.errorToastr('Erro no serviço de email', 'Erro');
              this.formRecSenha.enable();
            }
          );
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  isFieldInvalidReset(field: string) {

    return (
      (!this.formRecSenha.get(field).valid && this.formRecSenha.get(field).touched) ||
      (this.formRecSenha.get(field).untouched && this.formSubmitAttempt)
    );

  }

  checkEmail() {
    let s1 = this.formRecSenha.get('Senha').value;
    let s2 = this.formRecSenha.get('ConfirmaSenha').value;

    if (s1 !== s2) {
      this.toastr.errorToastr('As Senhas precisam ser iguais', 'Senhas diferentes');
    }
  }

  onSubmit() {
    if (this.formRecSenha.valid) {
      this.formRecSenha.disable();
      const user = { Senha: this.formRecSenha.get('Senha').value, PasswordToken: this.token };
      this._auth.updatePassword(user)
        .subscribe(
          res => {
            if (!res) {
              this.toastr.successToastr('Sua nova senha foi cadastrada com sucesso.', 'Sucesso!');
              this.router.navigate(['/login']);
            } else {
              this.toastr.errorToastr('Ocorreu um erro inesperado, tente novamente!', 'Erro');
              this.formRecSenha.enable();
            }
          },
          err => {
            this.toastr.errorToastr('Erro no serviço de emails', 'Erro');
            console.log(err);
            this.formRecSenha.enable();
          }
        );
    }

    this.formSubmitAttempt = true;
  }
}
