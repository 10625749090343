import { Component, OnInit, EventEmitter, ElementRef, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-espacos-intermetatarsicos-pe',
  templateUrl: './espacos-intermetatarsicos-pe.component.html',
  styleUrls: ['./espacos-intermetatarsicos-pe.component.css']
})
export class EspacosIntermetatarsicosPeComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdNeuroma') rdNeuroma: MatRadioButton;
  @ViewChild('rdBursite') rdBursite: MatRadioButton;

  @ViewChild('slEspaco') slEspaco: MatSelect;
  @ViewChild('inMedindo') inMedindo: ElementRef;

  @ViewChildren('neuromaChildren') neuromaChildren: QueryList<any>;

  espacos: ArrayDropDown[] = [
    { value: '1°', viewValue: '1°' },
    { value: '2°', viewValue: '2°' },
    { value: '3°', viewValue: '3°' },
    { value: '4°', viewValue: '4°' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;
  unidadeMedida: string;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNeuroma: false,
      sahBursite: false
    };
    this.lesoesForm = this._fb.group({
      neuroma: this._fb.array([this.addNeuromaDin()]),
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Dinamico
  addNeuromaDin() {
    return this._fb.group({
      espaco: [],
      medida: []
    });
  }

  addNeuroma() {
    this.neuromaArray.push(this.addNeuromaDin());
    this.catchIDs();
  }

  get neuromaArray() {
    return <FormArray>this.lesoesForm.get('neuroma');
  }

  removeNeuroma(index) {
    this.neuromaArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  clickNormal() {
    this.showAndHideTerms.sahNeuroma = false;
    this.showAndHideTerms.sahBursite = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickNeuroma() {
    this.showAndHideTerms.sahNeuroma = true;
    this.showAndHideTerms.sahBursite = false;
    this.catchIDs();
  }

  clickBursite() {
    this.showAndHideTerms.sahNeuroma = false;
    this.showAndHideTerms.sahBursite = true;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdNeuroma.checked) {
      this.termoSelecionado.emit(this.rdNeuroma.value);
      this.verificaNeuroma();
    }

    if (this.rdBursite && this.rdBursite.checked) {
      this.termoSelecionado.emit(this.rdBursite.value);

      if (this.slEspaco && this.slEspaco.value) {
        const objeto = { elemento: this.slEspaco, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.inMedindo && this.inMedindo.nativeElement.value) {
        const objeto = { elemento: this.inMedindo.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

  verificaNeuroma() {
    this.linha = 0;
    this.neuromaChildren.forEach(obj => {

      if (obj.nativeElement) {

        this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: this.countLesoes };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);

      } else {
        if(+obj.id === 1774) {
          this.linha++;
        }

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdNormal && this.rdNormal.checked) ||
        (this.rdBursite && this.rdBursite.checked)
       ) {
      const arrayTotal = this.neuromaArray.length;
      for (let i = 0; i <= arrayTotal; i++) {
        const index = this.neuromaArray.length - 1
        if (index != 0) {
          this.neuromaArray.removeAt(index);
        }
      }
      this.neuromaArray.reset();
    }
  }

}
