import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';

import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FeedbackService } from 'src/app/services/feedback.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {
  rate: number = 1;
  feedbackForm: FormGroup;
  submitted = false;
  showAndHide: any;

  assuntos: ArrayDropDown[] = [
    { value: 'Reportar um bug', viewValue: 'Reportar um bug' },
    { value: 'Feedback', viewValue: 'Feedback' },
    { value: 'Solicitar funcionalidade', viewValue: 'Solicitar funcionalidade' },
    { value: 'Melhoria na estruturação do laudo', viewValue: 'Melhoria na estruturação do laudo' },
    { value: 'Outro', viewValue: 'Outro' },
  ];

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private toastr: ToastrManager,
    private formBuilder: FormBuilder,
    private _feedbackService: FeedbackService,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private _firebase: FirebaseService) { }

  ngOnInit() {
    this.submitted = false;

    this.feedbackForm = this.formBuilder.group({
      assunto: ['', Validators.required],
      descricao: ['', Validators.required],
    });

    this.showAndHide = {
      sahStar1Full: true,
      sahStar2: true,
      sahStar2Full: false,
      sahStar3: true,
      sahStar3Full: false,
      sahStar4: true,
      sahStar4Full: false,
      sahStar5: true,
      sahStar5Full: false,
    };
  }

  isFieldInvalid(field: string) {
    return (
      (!this.feedbackForm.get(field).valid && this.feedbackForm.get(field).touched) ||
      (this.feedbackForm.get(field).untouched && this.submitted)
    );
  }

  enviaClassificacao(value: number) {
    switch (value) {
      case 1:
        this.showAndHide.sahStar1Full = true;
        this.showAndHide.sahStar2 = true;
        this.showAndHide.sahStar2Full = false;
        this.showAndHide.sahStar3 = true;
        this.showAndHide.sahStar3Full = false;
        this.showAndHide.sahStar4 = true;
        this.showAndHide.sahStar4Full = false;
        this.showAndHide.sahStar5 = true;
        this.showAndHide.sahStar5Full = false;

        this.rate = 1;
        break;
      case 2:
        this.showAndHide.sahStar1Full = true;
        this.showAndHide.sahStar2 = false;
        this.showAndHide.sahStar2Full = true;
        this.showAndHide.sahStar3 = true;
        this.showAndHide.sahStar3Full = false;
        this.showAndHide.sahStar4 = true;
        this.showAndHide.sahStar4Full = false;
        this.showAndHide.sahStar5 = true;
        this.showAndHide.sahStar5Full = false;

        this.rate = 2;
        break;
      case 3:
        this.showAndHide.sahStar1Full = true;
        this.showAndHide.sahStar2 = false;
        this.showAndHide.sahStar2Full = true;
        this.showAndHide.sahStar3 = false;
        this.showAndHide.sahStar3Full = true;
        this.showAndHide.sahStar4 = true;
        this.showAndHide.sahStar4Full = false;
        this.showAndHide.sahStar5 = true;
        this.showAndHide.sahStar5Full = false;

        this.rate = 3;
        break;
      case 4:
        this.showAndHide.sahStar1Full = true;
        this.showAndHide.sahStar2 = false;
        this.showAndHide.sahStar2Full = true;
        this.showAndHide.sahStar3 = false;
        this.showAndHide.sahStar3Full = true;
        this.showAndHide.sahStar4 = false;
        this.showAndHide.sahStar4Full = true;
        this.showAndHide.sahStar5 = true;
        this.showAndHide.sahStar5Full = false;

        this.rate = 4;
        break;
      case 5:
        this.showAndHide.sahStar1Full = true;
        this.showAndHide.sahStar2 = false;
        this.showAndHide.sahStar2Full = true;
        this.showAndHide.sahStar3 = false;
        this.showAndHide.sahStar3Full = true;
        this.showAndHide.sahStar4 = false;
        this.showAndHide.sahStar4Full = true;
        this.showAndHide.sahStar5 = false;
        this.showAndHide.sahStar5Full = true;

        this.rate = 5;
        break;
      default:
        break;
    }
  }

  clickCancelar(): void {
    this.dialogRef.close();
  }

  clickEnviar(): void {
    const userId = parseInt(localStorage.getItem('userID'));
    this.submitted = true;

    this._feedbackService.rating(this.feedbackForm.value, this.rate, userId)
      .subscribe(res => {
        this.toastr.successToastr('Seu feedback foi enviado com sucesso.', 'Ok!',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
      }, error => {
        this.toastr.errorToastr('Ops! houve um erro ao enviar seu feedback.', 'Erro!',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
      });

    this._firebase.logFeedback(this.feedbackForm.value.assunto, this.rate);

    this.submitted = false;
    this.dialogRef.close();
  }
}
