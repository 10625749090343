import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-baco-dialog',
  templateUrl: './baco-dialog.component.html',
  styleUrls: ['./baco-dialog.component.css']
})
export class BacoDialogComponent {

  displayedColumns =
  ['idade', 'limite'];

  dataSource = ELEMENT_DATA;

  displayedFooter = ['*Medida longitudinal no plano coronal'];

  constructor(
    public dialogRef: MatDialogRef<BacoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ClickNao(): void {
    this.dialogRef.close();
  }

} 

export interface RinsRefElement {
  idade: string;
  limite: string;
}

const ELEMENT_DATA: RinsRefElement[] = [
  {idade: '0-3 meses',      limite: '6 cm'},
  {idade: '3-6 meses',      limite: '6,5 cm'},
  {idade: '6-12 meses',     limite: '7 cm'},
  {idade: '1-2 anos',       limite: '8 cm'},
  {idade: '2-4 anos',       limite: '9 cm'},
  {idade: '4-6 anos',       limite: '9,5 cm'},
  {idade: '6-8 anos',       limite: '10 cm'},
  {idade: '8-10 anos',      limite: '11 cm'},
  {idade: '10-12 anos',     limite: '11,5 cm'},
  {idade: '12 - 15 anos',   limite: '12 cm'},
  {idade: '15-20 anos (F)', limite: '12 cm'},
  {idade: '15-20 anos (M)', limite: '13 cm'},
];
