import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-cer-colo-uterino',
  templateUrl: './cer-colo-uterino.component.html',
  styleUrls: ['./cer-colo-uterino.component.css']
})
export class CerColoUterinoComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdCurto') rdCurto: MatRadioButton;
  @ViewChild('rdFechado') rdFechado: MatRadioButton;
  @ViewChild('rdAberto') rdAberto: MatRadioButton;
  @ViewChild('slOpcao') slOpcao: MatSelect;

  @ViewChild('inMedida') inMedida: ElementRef;

  showAndHideTerms: any;
  inMedidaInvalid : boolean= true;

  opcoes: ArrayDropDown[] = [
    { value: 'fechado', viewValue: 'Fechado' },
    { value: 'aberto', viewValue: 'Aberto' }
  ];

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahMedida: true
    }
  }

  clickNormal() {
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  clickCurto(){
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  clickFechado(){
    this.showAndHideTerms.sahMedida = true;
    this.catchIDs();
  }

  clickAberto(){
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurMedindo() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if (this.inMedida &&
      this.inMedida.nativeElement.value !== '') {
        const Diam = this._validacoes.formataDecimal(this.inMedida.nativeElement.value);
        const objetoElemento = { id: this.inMedida.nativeElement.id, value: Diam, cenario: 0 };
        const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

    if (this.rdNormal && this.rdNormal.checked || this.rdCurto && this.rdCurto.checked) {

      if (this.inMedida && this.inMedida.nativeElement.value !== '') {

          if(this.inMedida.nativeElement.value <= 25){
            const objetoElemento = { id: this.rdCurto.id, value: '', cenario: 0 };
            const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else if(this.inMedida.nativeElement.value > 25){
            const objetoElemento = { id: this.rdNormal.id, value: '', cenario: 0 };
            const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

        if(this.inMedida.nativeElement.value <= 25){
          this.rdCurto.checked = true;
        }else{
          this.rdNormal.checked = true;
        }
      }
      if (this.slOpcao && this.slOpcao.value) {
        if(this.slOpcao.value == 'fechado'){
          this.termoSelecionado.emit(4270);
        } else if(this.slOpcao.value == 'aberto'){
          this.termoSelecionado.emit(4269);
        }
      }
    }

    if(this.rdNormal && this.rdNormal.checked || this.rdCurto && this.rdCurto.checked){
      if((this.inMedida && this.inMedida.nativeElement.value)){
        this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'avaliacaoColoUterino'});
      }else{
        this.camposObrigatoriosPreenchidos.emit({ativo: false, nome:'avaliacaoColoUterino'});
      }
    } else{
      this.camposObrigatoriosPreenchidos.emit({ativo: true, nome:'avaliacaoColoUterino'});
    }

    this.listaCompleta.emit();
  }
  removeInvalidField(){
    if(this.inMedida && this.inMedida.nativeElement.value){
      this.inMedidaInvalid = false;
    }else{
      this.inMedidaInvalid = true;
    }
  }
}
