import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckbox, MatRadioButton, MatDialog} from '@angular/material';
import { CarotidaDialogComponent } from 'src/app/utils/dialog/table/carotida-dialog/carotida-dialog.component';

@Component({
  selector: 'app-ateromatose',
  templateUrl: './ateromatose.component.html',
  styleUrls: ['./ateromatose.component.css']
})
export class AteromatoseComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() tabelaReferencia = new EventEmitter();

  @ViewChild('ckAteromatose') ckAteromatose: MatCheckbox;
  @ViewChild('rdIncipiente') rdIncipiente: MatRadioButton;
  @ViewChild('rdEsparsa') rdEsparsa: MatRadioButton;
  @ViewChild('rdMultiPlacas') rdMultiPlacas: MatRadioButton;

  showAndHideTerms: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAteromatose: false
    };
  }

  clickAteromatose() {
    if (this.showAndHideTerms.sahAteromatose === false) {
      this.showAndHideTerms.sahAteromatose = true;
    } else {
      this.showAndHideTerms.sahAteromatose = false;
    }

    this.catchIDs();
  }

  clickDefault() {
    this.catchIDs();
  }

  clickTabelaReferencia() {
    this.tabelaReferencia.emit(null);
    let dialogRef = this.dialog.open(CarotidaDialogComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      this.tabelaReferencia.emit(result);
    });
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.ckAteromatose !== undefined && this.ckAteromatose.checked) {

      if (this.rdIncipiente != undefined && this.rdIncipiente.checked) {
        this.termoSelecionado.emit(this.rdIncipiente.id);
      } else if (this.rdEsparsa != undefined && this.rdEsparsa.checked) {
        this.termoSelecionado.emit(this.rdEsparsa.id);
      } else if (this.rdMultiPlacas != undefined && this.rdMultiPlacas.checked) {
        this.termoSelecionado.emit(this.rdMultiPlacas.id);
      } else {
        this.termoSelecionado.emit(1045);
      }

    } else {
      // Sem Ateromatose
      this.termoSelecionado.emit(1101);
    }

    this.listaCompleta.emit(62); // Ateromatose
  }
}
