import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef} from '@angular/core';
import { MatRadioButton } from '@angular/material';
@Component({
  selector: 'app-medidas',
  templateUrl: './medidas.component.html',
  styleUrls: ['./medidas.component.css']
})
export class MedidasComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdIndiceCraniano') rdIndiceCraniano: MatRadioButton;
  @ViewChild('rdTamanhoCraniano') rdTamanhoCraniano: MatRadioButton;

  @ViewChild('inMedida1') inMedida1: ElementRef;
  @ViewChild('inMedida2') inMedida2: ElementRef;
  
  showAndHideTerms: any;
  unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      indiceCraniano: false,
      tamanhoCraniano: false
    };
  }

  blurMedindo() {
    this.catchIDs();
  }

  clickDefault(){
    if (this.rdNaoCitar.checked) {
      this.showAndHideTerms.indiceCraniano = false;
      this.showAndHideTerms.tamanhoCraniano = false;
    } else if (this.rdIndiceCraniano.checked) {
      this.showAndHideTerms.indiceCraniano = true;
      this.showAndHideTerms.tamanhoCraniano = false;
    } else if (this.rdTamanhoCraniano.checked) {
      this.showAndHideTerms.indiceCraniano = false;
      this.showAndHideTerms.tamanhoCraniano = true;
    }

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }

    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdIndiceCraniano.checked) {
        this.termoSelecionado.emit(this.rdIndiceCraniano.id);

      if (this.inMedida1 && this.inMedida1.nativeElement.value) {
        const objeto = { value: this.inMedida1.nativeElement.value, id: this.inMedida1.nativeElement.id }
        this.termosGenericos.emit(objeto);
      }
    }
    if (this.rdTamanhoCraniano.checked) {
        this.termoSelecionado.emit(this.rdTamanhoCraniano.id);

      if (this.inMedida2 && this.inMedida2.nativeElement.value) {
        const objeto = { value: this.inMedida2.nativeElement.value, id: this.inMedida2.nativeElement.id }
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
