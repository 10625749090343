import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';


@Component({
  selector: 'app-fascia-palmar',
  templateUrl: './fascia-palmar.component.html',
  styleUrls: ['./fascia-palmar.component.css']
})
export class FasciaPalmarComponent implements OnInit {

  public showAndHideTerms: any;
  public addForm: FormGroup;
  public linha: number;
  public objeto: any;
  public objetofilho: any;


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdFibromatose') rdFibromatose: MatRadioButton;
  @ViewChild('rdFibromatose1nodulo') rdFibromatose1nodulo: MatRadioButton;
  @ViewChild('rdFibromatose2nodulo') rdFibromatose2nodulo: MatRadioButton;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('inMedida') inMedida: ElementRef;
  @ViewChildren('fibromatose2Children') fibromatose2Children: QueryList<any>;



  public localizacao: ArrayDropDown[] = [
    { value: 'proximal', viewValue: 'Proximal' },
    { value: 'médio', viewValue: 'Médio' },
    { value: 'distal', viewValue: 'Distal' },
  ];

  unidadeMedida: string;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahFibromatose: true,
      sahFibromatose1nodulo: true,
      sahFibromatose2nodulo: false,
    };
    this.addForm = this._fb.group({
      Presente: this._fb.array([this.addDedosGrup()])
    });
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }
  public addDedosGrup(): any {
    return this._fb.group({
      dedo: [],
      medida: [],
    });
  }

  public addPresente(): any {
    this.PresenteArray.push(this.addDedosGrup());
    this.catchIDs();
  }

  public removePresente(index): any {
    this.PresenteArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }


  get PresenteArray() {
    return <FormArray>this.addForm.get('Presente');
  }


  public ClickNormal(): any {
    this.showAndHideTerms.sahFibromatose = false;
    this.showAndHideTerms.sahFibromatose1nodulo = false;
    this.showAndHideTerms.sahFibromatose2nodulo = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public ClickFibromatose(): any {
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahFibromatose = true;
    this.showAndHideTerms.sahFibromatose1nodulo = true;
    this.showAndHideTerms.sahFibromatose2nodulo = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public ClickFibromatose1nodulo(): any {
    this.verificaLesoes();
    this.showAndHideTerms.sahFibromatose1nodulo = true;
    this.showAndHideTerms.sahFibromatose2nodulo = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  public ClickFibromatose2nodulo(): any {
    this.verificaLesoes();
    this.showAndHideTerms.sahFibromatose2nodulo = true;
    this.showAndHideTerms.sahFibromatose1nodulo = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.sllocalizacao && this.sllocalizacao.value) || (this.inMedida && this.inMedida.nativeElement.value)
    || (this.rdFibromatose2nodulo && this.rdFibromatose2nodulo.checked)){
      this.rdFibromatose.checked = true;
    }
  }

  public blurMedindo(): any {
    this.verificaLesoes();
    this.catchIDs();
  }

  public changeSelect(): any {
    this.verificaLesoes();
    this.catchIDs();
  }

  public verificafibromatose(): any {
    this.linha = 0;
    this.fibromatose2Children.forEach(obj => {

      if (obj.nativeElement) {

        this.objetofilho = { id: obj.nativeElement.id, value: obj.nativeElement.value, cenario: 1 };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);
      } else {

        if (+obj.id === 1754) this.linha++;

        this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
        this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
        this.lesoesDinamicas.emit(this.objeto);
      }
    });
  }

  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }
    if (this.rdFibromatose.checked) {
      this.termoSelecionado.emit(this.rdFibromatose.value);

      // fibromatose 1 nodulo
      if (this.rdFibromatose1nodulo && this.rdFibromatose1nodulo.checked) {
        this.termoSelecionado.emit(this.rdFibromatose1nodulo.value);

        if (this.sllocalizacao && this.sllocalizacao.value) {
          const objeto = { elemento: this.sllocalizacao };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedida && this.inMedida.nativeElement.value) {
          const objeto = { elemento: this.inMedida.nativeElement };
          this.termosGenericos.emit(objeto);
        }
      }
      // fibromatose 2 ou mais nodulos
      if (this.rdFibromatose2nodulo && this.rdFibromatose2nodulo.checked) {
        this.termoSelecionado.emit(this.rdFibromatose2nodulo.value);
        this.verificafibromatose();
      }
    }
    this.listaCompleta.emit();

  }
  ajustaArrayDinamico() {

    const arrayTotal = this.PresenteArray.length;

    for (let i = 0; i <= arrayTotal; i++) {
      const index = this.PresenteArray.length - 1;

      if (index !== 0) {
        this.PresenteArray.removeAt(index);
      }
    }
    this.PresenteArray.reset();
  }


}
