import { Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tecnica',
  templateUrl: './tecnica.component.html',
  styleUrls: ['./tecnica.component.css']
})
export class TecnicaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;
  @ViewChild('slIncidencia') slIncidencia: MatSelect;

  showAndHideTerms: any;

  incidencias: ArrayDropDown[] = [
    { value: 'AP', viewValue: 'AP' },
    { value: 'Perfil', viewValue: 'Perfil' },
    { value: 'Ossos Nasais', viewValue: 'Ossos Nasais' },
    { value: 'Waters', viewValue: 'Waters' },
    { value: 'Caldwell', viewValue: 'Caldwell' },
    { value: 'Hirtz', viewValue: 'Hirtz' },
    { value: 'Townev', viewValue: 'Townev' }
  ];

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false,
    };
  }

  clickDefault(){
    this.showAndHideTerms.sahCitar = !this.showAndHideTerms.sahCitar;
    this.catchIDs();
  }

  changeSelect(){
    this.catchIDs();
  } 

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }
    if (this.rdCitar.checked) {
      this.termoSelecionado.emit(this.rdCitar.id);

      if(this.slIncidencia && this.slIncidencia.value){
        const objeto = {elemento: this.slIncidencia, isPrimeiraLesao: true}
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
