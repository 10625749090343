import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { log } from 'console';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-feto-gemelar',
  templateUrl: './feto-gemelar.component.html',
  styleUrls: ['./feto-gemelar.component.css']
})
export class FetoGemelarComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdBatCardAusentes') rdBatCardAusentes: MatRadioButton;
  @ViewChild('slSituacao') slSituacao: MatSelect;
  @ViewChild('slApresentacao') slApresentacao: MatSelect;
  @ViewChild('slDorso') slDorso: MatSelect;
  @ViewChild('slPoloCefalico') slPoloCefalico: MatSelect;
  @ViewChild('inBatimentos') inBatimentos: ElementRef;

  @ViewChild('rdNormal2') rdNormal2: MatRadioButton;
  @ViewChild('rdBatCardAusentes2') rdBatCardAusentes2: MatRadioButton;
  @ViewChild('slSituacao2') slSituacao2: MatSelect;
  @ViewChild('slApresentacao2') slApresentacao2: MatSelect;
  @ViewChild('slDorso2') slDorso2: MatSelect;
  @ViewChild('slPoloCefalico2') slPoloCefalico2: MatSelect;
  @ViewChild('inBatimentos2') inBatimentos2: ElementRef;

  @ViewChild('rdNormal3') rdNormal3: MatRadioButton;
  @ViewChild('rdBatCardAusentes3') rdBatCardAusentes3: MatRadioButton;
  @ViewChild('slSituacao3') slSituacao3: MatSelect;
  @ViewChild('slApresentacao3') slApresentacao3: MatSelect;
  @ViewChild('slDorso3') slDorso3: MatSelect;
  @ViewChild('slPoloCefalico3') slPoloCefalico3: MatSelect;
  @ViewChild('inBatimentos3') inBatimentos3: ElementRef;

  @ViewChild('rdNormal4') rdNormal4: MatRadioButton;
  @ViewChild('rdBatCardAusentes4') rdBatCardAusentes4: MatRadioButton;
  @ViewChild('slSituacao4') slSituacao4: MatSelect;
  @ViewChild('slApresentacao4') slApresentacao4: MatSelect;
  @ViewChild('slDorso4') slDorso4: MatSelect;
  @ViewChild('slPoloCefalico4') slPoloCefalico4: MatSelect;
  @ViewChild('inBatimentos4') inBatimentos4: ElementRef;


  showBatimentos = true;

  showAndHideTerms: any;
  showAndHideFetos: any;

  fetoSelected: number = 1;


  situacoes: ArrayDropDown[] = [
    { value: 'longitudinal', viewValue: 'Longitudinal' },
    { value: 'transversa', viewValue: 'Transversa' }
  ];

  apresentacoes: ArrayDropDown[] = [
    { value: 'cefálica', viewValue: 'Cefálica' },
    { value: 'pélvica', viewValue: 'Pélvica' },
    // { value: 'córmica', viewValue: 'Córmica' }
  ];

  apresentacoesAusente: ArrayDropDown[] = [
    { value: 'cefálica', viewValue: 'Cefálica' },
    { value: 'pélvica', viewValue: 'Pélvica' },
    { value: 'córmica', viewValue: 'Córmica' }
  ]

  apresentacoesTransversa: ArrayDropDown[] = [
    { value: 'córmica', viewValue: 'Córmica' }
  ];

  dorso: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
  ];

  dorsoTransverso: ArrayDropDown[] = [
    { value: 'superior', viewValue: 'Superior' },
    { value: 'inferior', viewValue: 'Inferior' },
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
  ];

  polos: ArrayDropDown[] = [
    { value: 'direita', viewValue: 'Direita' },
    { value: 'esquerda', viewValue: 'Esquerda' },
  ];


  constructor(private _common: CommonService) { }

  ngOnInit() {
    this._common.posicaoFeto.subscribe((feto: any) => {
      this.fetoSelected = feto;
      this.showAndHideFetos = {
        feto1: feto === 1,
        feto2: feto === 2,
        feto3: feto === 3,
        feto4: feto === 4,
      };
    });

    this.showAndHideTerms = {
      sahNormal: true,
      sahAusente: false,
      sahNormal2: true,
      sahAusente2: false,
      sahNormal3: true,
      sahAusente3: false,
      sahNormal4: true,
      sahAusente4: false,
    };

    this.showAndHideFetos = {
      feto1: true,
      feto2: false,
      feto3: false,
      feto4: false,
    };
  }

  isTransversa(){
    if(this.slSituacao && this.slSituacao.value){
      if(this.slSituacao.value === 'transversa'){
        return true;
      }
    }
    return false;
  }

  isTransversa2(){
    if(this.slSituacao2 && this.slSituacao2.value){
      if(this.slSituacao2.value === 'transversa'){
        return true;
      }
    }
    return false;
  }

  isTransversa3(){
    if(this.slSituacao3 && this.slSituacao3.value){
      if(this.slSituacao3.value === 'transversa'){
        return true;
      }
    }
    return false;
  }

  isTransversa4(){
    if(this.slSituacao4 && this.slSituacao4.value){
      if(this.slSituacao4.value === 'transversa'){
        return true;
      }
    }
    return false;
  }

  clickNormal() {
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahAusente = false;
    this.catchIDs();
  }
  
  clickNormal2() {
    this.showAndHideTerms.sahNormal2 = true;
    this.showAndHideTerms.sahAusente2 = false;
    this.catchIDs();
  }

  clickNormal3() {
    this.showAndHideTerms.sahNormal3 = true;
    this.showAndHideTerms.sahAusente3 = false;
    this.catchIDs();
  }

  clickNormal4() {
    this.showAndHideTerms.sahNormal4 = true;
    this.showAndHideTerms.sahAusente4 = false;
    this.catchIDs();
  }

  clickrdBatCard(){
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahAusente = true;
    this.catchIDs();
  }

  clickrdBatCard2(){
    this.showAndHideTerms.sahNormal2 = false;
    this.showAndHideTerms.sahAusente2 = true;
    this.catchIDs();
  }

  clickrdBatCard3(){
    this.showAndHideTerms.sahNormal3 = false;
    this.showAndHideTerms.sahAusente3 = true;
    this.catchIDs();
  }

  clickrdBatCard4(){
    this.showAndHideTerms.sahNormal4 = false;
    this.showAndHideTerms.sahAusente4 = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurBatimentos(){
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if(this.fetoSelected === 1){
      if (this.rdNormal.checked) {
        this.termoSelecionado.emit(this.rdNormal.id);
  
        if ((this.slApresentacao && this.slApresentacao.value) &&
            (this.slDorso && this.slDorso.value) &&
            (this.slSituacao && this.slSituacao.value) &&
            (this.inBatimentos && this.inBatimentos.nativeElement.value)) {
  
          let objeto = { elemento: this.slApresentacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          let objetoElemento;

          objetoElemento = {id: "1983f1", value: this.inBatimentos.nativeElement.value};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);
  
          if (this.slPoloCefalico && this.slPoloCefalico.value) {
            objeto = { elemento: this.slPoloCefalico, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          }
  
        }
      }
  
      if(this.rdBatCardAusentes.checked){
        this.termoSelecionado.emit(this.rdBatCardAusentes.id);
  
        if ((this.slApresentacao && this.slApresentacao.value) &&
            (this.slDorso && this.slDorso.value) &&
            (this.slSituacao && this.slSituacao.value)) {
  
          let objeto = { elemento: this.slApresentacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    } else if(this.fetoSelected === 2){
      if (this.rdNormal2.checked) {
        this.termoSelecionado.emit(this.rdNormal2.id);
    
        if ((this.slApresentacao2 && this.slApresentacao2.value) &&
            (this.slDorso2 && this.slDorso2.value) &&
            (this.slSituacao2 && this.slSituacao2.value) &&
            (this.inBatimentos2 && this.inBatimentos2.nativeElement.value)) {

          let objeto = { elemento: this.slApresentacao2, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso2, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao2, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          let objetoElemento;

          objetoElemento = {id: "1983f2", value: this.inBatimentos2.nativeElement.value};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);

          if (this.slPoloCefalico2 && this.slPoloCefalico2.value) {
            objeto = { elemento: this.slPoloCefalico2, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          }

        }
      }

      if(this.rdBatCardAusentes2.checked){
        this.termoSelecionado.emit(this.rdBatCardAusentes2.id);
  
        if ((this.slApresentacao2 && this.slApresentacao2.value) &&
            (this.slDorso2 && this.slDorso2.value) &&
            (this.slSituacao2 && this.slSituacao2.value)) {
  
          let objeto = { elemento: this.slApresentacao2, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso2, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao2, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    } else if(this.fetoSelected === 3){
      if (this.rdNormal3.checked) {
        this.termoSelecionado.emit(this.rdNormal3.id);
    
        if ((this.slApresentacao3 && this.slApresentacao3.value) &&
            (this.slDorso3 && this.slDorso3.value) &&
            (this.slSituacao3 && this.slSituacao3.value) &&
            (this.inBatimentos3 && this.inBatimentos3.nativeElement.value)) {

          let objeto = { elemento: this.slApresentacao3, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso3, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao3, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          let objetoElemento;

          objetoElemento = {id: "1983f3", value: this.inBatimentos3.nativeElement.value};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);

          if (this.slPoloCefalico3 && this.slPoloCefalico3.value) {
            objeto = { elemento: this.slPoloCefalico3, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if(this.rdBatCardAusentes3.checked){
        this.termoSelecionado.emit(this.rdBatCardAusentes3.id);
  
        if ((this.slApresentacao3 && this.slApresentacao3.value) &&
            (this.slDorso3 && this.slDorso3.value) &&
            (this.slSituacao3 && this.slSituacao3.value)) {
  
          let objeto = { elemento: this.slApresentacao3, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso3, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao3, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    } else if(this.fetoSelected === 4){
      if (this.rdNormal4.checked) {
        this.termoSelecionado.emit(this.rdNormal4.id);
    
        if ((this.slApresentacao4 && this.slApresentacao4.value) &&
            (this.slDorso4 && this.slDorso4.value) &&
            (this.slSituacao4 && this.slSituacao4.value) &&
            (this.inBatimentos4 && this.inBatimentos4.nativeElement.value)) {

          let objeto = { elemento: this.slApresentacao4, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso4, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao4, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          let objetoElemento;

          objetoElemento = {id: "1983f4", value: this.inBatimentos4.nativeElement.value};
          const objetoCriado = { elemento: objetoElemento};
          this.termosGenericos.emit(objetoCriado);

          if (this.slPoloCefalico4 && this.slPoloCefalico4.value) {
            objeto = { elemento: this.slPoloCefalico4, isPrimeiraLesao: true};
            this.termosGenericos.emit(objeto);
          }
        }
      }

      if(this.rdBatCardAusentes4.checked){
        this.termoSelecionado.emit(this.rdBatCardAusentes4.id);
  
        if ((this.slApresentacao4 && this.slApresentacao4.value) &&
            (this.slDorso4 && this.slDorso4.value) &&
            (this.slSituacao4 && this.slSituacao4.value)) {
  
          let objeto = { elemento: this.slApresentacao4, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slDorso4, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
          objeto = { elemento: this.slSituacao4, isPrimeiraLesao: true};
          this.termosGenericos.emit(objeto);
        }
      }
    }
    
    this.listaCompleta.emit();
  }
}
