import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { ListaPlanosService } from '../services/lista-planos.service';
import { UserService } from './../services/user.service';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

  nomeUsuario: string;
  url: string;
  planoAtivo: boolean;
  isExpand = false;
  adminUser: boolean;
  sahLogoRule: boolean;
  private planoData: any;
  private userData: any;

  constructor(private router: Router,
    private authService: AuthService,
    private _listaPlanosService: ListaPlanosService,
    private userService: UserService,
    private _firebase: FirebaseService
    ) { }

  ngOnInit() {
    this.buscaPlanoDoUsuario();
    this.nomeUsuario = localStorage.getItem('nomeUsuario'); 
    this.verificarAdmin();
  }

  async verificarAdmin(){
    this.userData = await from(this.userService.getUserData()).pipe(first()).toPromise();
    if(this.userData.profileID === 3){
      this.adminUser = true;
    } else{
      this.adminUser = false;
    }
  }

  clickButton(url) {
    if(url == "/dashboard"){
      this._firebase.logDefault("access_dashboard");
    } else if(url == "/alteraLaudo"){
      this._firebase.logDefault("access_edit_scenarios");
    } else if(url == "/versao"){
      this._firebase.logDefault("access_version");
    } else if(url == "/manual"){
      this._firebase.logDefault("access_user_manual");
    }
    this.router.navigateByUrl(url);
  }
  clickExpand() {
    this.isExpand = !this.isExpand;
  }

  onLogout() {
    this.authService.logout();
  }

  async buscaPlanoDoUsuario() {
    this.planoData = await from(this._listaPlanosService.getPlanoDousuario()).pipe().toPromise();
    if(this.planoData.length > 0) {
      for(let i = 0; i < this.planoData.length; i++) {
        if(this.planoData[i].planID != 7 && this.planoData[i].planID != 2) {
          this.planoAtivo = true;
        } else {
          this.planoAtivo = false;
        }
      }
    } else{
      this.planoAtivo = false;
    }
  }
}
