import { Component, OnInit } from '@angular/core';

import { ManualService } from "../services/manual.service";

@Component({
  selector: 'app-manual-de-uso',
  templateUrl: './manual-de-uso.component.html',
  styleUrls: ['./manual-de-uso.component.css', '../app.component.css']
})
export class MenuExpansivo implements OnInit {
  public manual: any = [];
  public steper: any;
  public loading: boolean = true;

  panelOpenState = false;

  constructor(
    private _manualService: ManualService,
  ) { }

  ngOnInit() {
    this.carregaManual();
  }

  carregaManual() {
    this._manualService.getManual()
      .toPromise()
      .then((res: any) => {
        res.map(item => {
          this.manual.push(item);
          this.carregarSteps(item);
        });
      });
  }

  carregarSteps(item: any) {
    this._manualService.getStep(item.id)
      .subscribe((res: any) => {
        item.step = res;
      });

    if (item != null)
      this.loading = false;
  }
}
