import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TermResult } from 'src/app/models/BO/TermResult';
import { TermosGenericos } from 'src/app/models/Helper/TermosGenericos';
import { CommonService } from 'src/app/services/common.service';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { Validacoes } from 'src/app/utils/validacoes';
import { Injuries, Term } from '../../../../models/BO/Term';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorImprimirService } from 'src/app/services/controladorImprimir.service';
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-morfologico-segundo-trimestre-doppler',
  templateUrl: './morfologico-segundo-trimestre-doppler.component.html',
  styleUrls: ['./morfologico-segundo-trimestre-doppler.component.css']
})
export class MorfologicoSegundoTrimestreDopplerComponent implements OnInit, AfterViewInit {

  listTermDefault = [
    { listTerm: [4719], blockID: 179 },
    { listTerm: [4725, 4726, 4727, 4728, 4729, 4730, 4731, 4777], blockID: 310 },
    { listTerm: [4732, 4733, 5567, 4734, 4735, 4736, 4737, 4738, 4739, 4740, 4741, 4742, 5568], blockID: 311 },
    { listTerm: [4751, 4752, 4753, 4754, 4755], blockID: 312 },
    { listTerm: [4767, 4768, 4769, 4770, 4771, 4773], blockID: 324 },
    { listTerm: [4772, 4774, 4775], blockID: 325 },
    { listTerm: [4756, 4757, 4758, 4759], blockID: 326 },
    { listTerm: [4744, 4745, 4746, 4747, 4748, 4749, 4750], blockID: 327 },
    { listTerm: [], blockID: 328 },
    { listTerm: [], blockID: 329 },
    { listTerm: [], blockID: 330 },
    { listTerm: [4716, 4714, 4715], blockID: 313 },
    { listTerm: [], blockID: 316 },
  ]
  descricoes: DescricaoConclusao[] = [
    {
      value: 'Data da última menstruação: ***/***/***<br/>' +
        'IG (menstrual): *** semanas e *** dias.',
      blockID: 0, textTermID: 0, visivel: true
    },
    {
      value: 'Feto localizado em situação ***, apresentação ***, com posição de dorso à ***. </br>' +
        'Movimentos corpóreos e batimentos cardíacos presentes.</br>' +
        'Frequência cardíaca em torno de *** bpm.',
      blockID: 179, textTermID: 0, visivel: true
    },
    {
      value: '<b>BIOMETRIA FETAL</b></br>' +
        'Diâmetro biparietal (DBP): *** mm.</br>' +
        'Diâmetro occiptofrontal (DOF): *** mm.</br>' +
        'Circunferência cefálica (CC): *** mm.</br>' +
        'Circunferência abdominal (CA): *** mm.</br>' +
        'Comprimento umeral (CUM): ***mm.</br>' +
        'Comprimento femoral (CF): *** mm.</br>' +
        'Peso fetal em torno de **** gramas (+/- 15%).',
      blockID: 310, textTermID: 0, visivel: true
    },
    {
      value: '<b>BIOMETRIA COMPLEMENTAR</b></br>' +
        'Cerebelo: *** mm.</br>' +
        'Cisterna magna: *** mm.</br>' +
        'Prega nucal: *** mm.</br>' +
        'Átrio ventricular: *** mm.</br>' +
        'Distância interorbitária: *** mm.</br>' +
        'Distância bi-orbitária: *** mm.</br>' +
        'Osso nasal: *** mm.</br>' +
        'Úmero: *** mm.</br>' +
        'Rádio: *** mm.</br>' +
        'Ulna: *** mm.</br>' +
        'Tíbia: *** mm.</br>' +
        'Fíbula: *** mm.</br>' +
        'Pé: *** mm.', blockID: 311, textTermID: 0, visivel: true
    },
    {
      value: '<b>CRÂNIO</b></br>' +
        'Estruturas da linha média: presentes e normais.</br>' +
        'Córtex: de aspecto habitual.</br>' +
        'Ventrículos cerebrais: sem dilatações.</br>' +
        'Cerebelo e fossa posterior: de forma habitual.</br>' +
        'Nuca: sem edemas ou cistos.',
      blockID: 312, textTermID: 0, visivel: true
    },
    {
      value: '<b>FACE</b></br>' +
        'Órbitas oculares: identificadas.</br>' +
        'Cristalinos: identificados e de aspecto habitual.</br>' +
        'Osso nasal: presente, de dimensões normais.</br>' +
        'Lábios: sem soluções de continuidade.</br>' +
        'Perfil da face: sem alterações.',
      blockID: 324, textTermID: 0, visivel: true
    },
    {
      value: '<b>TÓRAX</b></br>' +
        'Pulmões: homogêneos, com ecogenicidade habitual.</br>' +
        'Formação costal: simétrica e proporcional.</br>' +
        'Diafragma: identificado.',
      blockID: 325, textTermID: 0, visivel: true
    },
    {
      value: '<b>CORAÇÃO</b></br>' +
        'Situs solitus.</br>' +
        'Quatro câmaras de aspecto habitual.</br>' +
        'Septo interventricular aparentemente íntegro.</br>' +
        'Saída dos grandes vasos visibilizadas e proporcionais.',
      blockID: 326, textTermID: 0, visivel: true
    },
    {
      value: '<b>ABDOME</b></br>' +
        'Estômago identificado em topografia habitual.</br>' +
        'Parede abdominal íntegra.</br>' +
        'Fígado com ecotextura homogênea, sem calcificações.</br>' +
        'Vesícula biliar visibilizada.</br>' +
        'Intestino de aspecto normal.pa</br>' +
        'Rins com parênquima normal, sem dilatações das pelves renais.</br>' +
        'Bexiga urinária em topografia usual e de dimensões normais.',
      blockID: 327, textTermID: 0, visivel: true
    },
    {
      value: '<b>GENITÁLIA</b></br>' +
        'Compatível com o sexo ***.',
      blockID: 328, textTermID: 0, visivel: true
    },
    {
      value: '<b>COLUNA</b></br>' +
        'Sem alterações nos três planos visualizados.',
      blockID: 329, textTermID: 0, visivel: true
    },
    {
      value: '<b>EXTREMIDADES</b></br>' +
        'Segmentos proporcionais e simétricos.',
      blockID: 330, textTermID: 0, visivel: true
    },
    {
      value: '<b>LÍQUIDO AMNIÓTICO</b></br>' +
        'Volume normal de líquido amniótico (maior bolsão vertical: ** cm).',
      blockID: 315, textTermID: 0, visivel: true
    },
    {
      value: '<b>PLACENTA</b></br>' +
        'Localização ***, com aspecto compatível com grau *** de Grannum.',
      blockID: 313, textTermID: 0, visivel: true
    },
    {
      value: '<b>CORDÃO UMBILICAL</b></br>' +
        'Inserção fetal e placentária, preservadas. Presença de 2 artérias e 1 veia.',
      blockID: 306, textTermID: 0, visivel: true
    },
    {
      value: '<b>ESTUDO DOPPLERVELOCIMETRIA - ÍNDICE DE PULSATILIDADE</b>', blockID: 437, textTermID: 0, visivel: true
    },
    { value: '<b>AVALIAÇÃO DO COLO UTERINO</b></br>Colo uterino medindo *** mm de comprimento<br/><br/>Orifício interno fechado', blockID: 287, textTermID: 0, visivel: true },
    { value: 'Sludge em líquido amniótico ausente.', blockID: 288, textTermID: 0, visivel: true },
  ]

  conclusoes: DescricaoConclusao[] = [
    { value: '', blockID: 179, textTermID: 0, visivel: true },
    { value: 'Gestação única, tópica, de concepto vivo.', blockID: 0, textTermID: 0, visivel: true },
    { value: 'Avaliação da morfologia fetal dentro da normalidade para atual idade gestacional', blockID: 1, textTermID: 0, visivel: true },
    { value: '', blockID: 310, textTermID: 0, visivel: true },
    { value: '', blockID: 311, textTermID: 0, visivel: true },
    { value: '', blockID: 312, textTermID: 0, visivel: true },
    { value: '', blockID: 324, textTermID: 0, visivel: true },
    { value: '', blockID: 325, textTermID: 0, visivel: true },
    { value: '', blockID: 326, textTermID: 0, visivel: true },
    { value: '', blockID: 327, textTermID: 0, visivel: true },
    { value: '', blockID: 328, textTermID: 0, visivel: true },
    { value: '', blockID: 329, textTermID: 0, visivel: true },
    { value: '', blockID: 330, textTermID: 0, visivel: true },
    { value: '', blockID: 315, textTermID: 0, visivel: true },
    { value: '', blockID: 313, textTermID: 0, visivel: true },
    { value: '', blockID: 306, textTermID: 0, visivel: true },
    { value: 'Estudo Dopplerfluxométrico dentro dos limites da normalidade para a idade gestacional.', blockID: 437, textTermID: 0, visivel: true },
    { value: '', blockID: 287, textTermID: 0, visivel: true },
    { value: '', blockID: 288, textTermID: 0, visivel: true },
    //{ value: '', blockID: 316, textTermID: 0, visivel: true  },
  ];

  genericCoracao: TermosGenericos[] = [
    { value: '', selected: false, termID: 4756, term: '[Situs]' },
    { value: '', selected: false, termID: 4757, term: '[Camaras]' },
    { value: '', selected: false, termID: 4758, term: '[Septo]' },
    { value: '', selected: false, termID: 4759, term: '[Grandes Vasos]' }
  ];

  genericVelocimetria: TermosGenericos[] = [
    { value: '', selected: false, termID: 4419, term: '[IPDir]' },
    { value: '', selected: false, termID: 4707, term: '[Morfologia UTD Dir]' },
    { value: '', selected: false, termID: 4401, term: '[IPEsq]' },
    { value: '', selected: false, termID: 4708, term: '[Morfologia UTD Esq]' },
    { value: '', selected: false, termID: 4709, term: '[IP Medio]' },
    { value: '', selected: false, termID: 4786, term: '[Percentil IP Medio]' }
  ];

  genericAbdome: TermosGenericos[] = [
    { value: '', selected: false, termID: 4744, term: '[Estomago]' },
    { value: '', selected: false, termID: 4745, term: '[Parede]' },
    { value: '', selected: false, termID: 4746, term: '[Figado]' },
    { value: '', selected: false, termID: 4747, term: '[Vesicula]' },
    { value: '', selected: false, termID: 4748, term: '[Intestino]' },
    { value: '', selected: false, termID: 4749, term: '[Rins]' },
    { value: '', selected: false, termID: 4750, term: '[Bexiga]' }
  ];

  genericFeto: TermosGenericos[] = [
    { value: '', selected: false, termID: 1980, term: '[situacao]' },
    { value: '', selected: false, termID: 1981, term: '[apresentacao]' },
    { value: '', selected: false, termID: 1982, term: '[dorso]' },
    { value: '', selected: false, termID: 1983, term: '[frequencia cardiaca]' },
    { value: '', selected: false, termID: 4168, term: '[polo cefalico]' },
    { value: '', selected: false, termID: 5570, term: '[SituacaoAusente]' },
    { value: '', selected: false, termID: 5571, term: '[ApresentacaoAusente]' },
    { value: '', selected: false, termID: 5572, term: '[DorsoAusente]' },
  ];

  genericCrescimentoFetal: TermosGenericos[] = [
    { value: '', selected: false, termID: 4725, term: '[DBP]' },
    { value: '', selected: false, termID: 4726, term: '[DOF]' },
    { value: '', selected: false, termID: 4727, term: '[CC]' },
    { value: '', selected: false, termID: 4728, term: '[CA]' },
    { value: '', selected: false, termID: 4729, term: '[CUM]' },
    { value: '', selected: false, termID: 4730, term: '[CF]' },
    { value: '', selected: false, termID: 4731, term: '[peso]' },
    { value: '', selected: false, termID: 4788, term: '[Percentil]' },
    { value: '', selected: false, termID: 4777, term: '[Calculo]' }
  ];

  genericBiometriaComplementar: TermosGenericos[] = [
    { value: '', selected: false, termID: 4732, term: '[Cerebelo]' },
    { value: '', selected: false, termID: 4733, term: '[Cisterna magna]' },
    { value: '', selected: false, termID: 5567, term: '[PregaNucal]' },
    { value: '', selected: false, termID: 4734, term: '[Atrio ventricular]' },
    { value: '', selected: false, termID: 4735, term: '[Distancia interorbitaria]' },
    { value: '', selected: false, termID: 4736, term: '[Distancia bi-orbitaria]' },
    { value: '', selected: false, termID: 4737, term: '[Osso nasal]' },
    { value: '', selected: false, termID: 4738, term: '[Umero]' },
    { value: '', selected: false, termID: 4739, term: '[Radio]' },
    { value: '', selected: false, termID: 4740, term: '[Ulna]' },
    { value: '', selected: false, termID: 4741, term: '[Tibia]' },
    { value: '', selected: false, termID: 4742, term: '[Fibula]' },
    { value: '', selected: false, termID: 5568, term: '[Pe]' },
  ];

  genericCranio: TermosGenericos[] = [
    { value: '', selected: false, termID: 4751, term: '[estruturas da linha media]' },
    { value: '', selected: false, termID: 4752, term: '[cortex]' },
    { value: '', selected: false, termID: 4753, term: '[ventriculos cerebrais]' },
    { value: '', selected: false, termID: 4754, term: '[cerebelo e fossa posterior]' },
    { value: '', selected: false, termID: 4755, term: '[nuca]' },
  ];

  genericFace: TermosGenericos[] = [
    { value: '', selected: false, termID: 4767, term: '[orbitas oculares]' },
    { value: '', selected: false, termID: 4768, term: '[cristalinos]' },
    { value: '', selected: false, termID: 4769, term: '[osso nasal]' },
    { value: '', selected: false, termID: 4770, term: '[labios]' },
    { value: '', selected: false, termID: 4771, term: '[perfil da face]' },
    { value: '', selected: false, termID: 4773, term: '[formacao costal]' },
  ];

  genericTorax: TermosGenericos[] = [
    { value: '', selected: false, termID: 4772, term: '[pulmoes]' },
    { value: '', selected: false, termID: 4774, term: '[formacao costal]' },
    { value: '', selected: false, termID: 4775, term: '[diafragma]' },
  ];

  genericGenitalia: TermosGenericos[] = [
    { value: '', selected: false, termID: 4743, term: '[Genitalia]' }
  ];

  genericColuna: TermosGenericos[] = [
    { value: '', selected: false, termID: 4718, term: '[Coluna]' }
  ];

  genericExtremidades: TermosGenericos[] = [
    { value: '', selected: false, termID: 4717, term: '[Extremidades]' }
  ];

  genericPlacenta: TermosGenericos[] = [
    { value: '', selected: false, termID: 4714, term: '[localizacao]' },
    { value: '', selected: false, termID: 4715, term: '[grau]' },
    { value: '', selected: false, termID: 4791, term: '[espessura]' }
  ];

  genericCordaoUmbilical: TermosGenericos[] = [
    { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
  ];

  genericLiquidoAmniotico: TermosGenericos[] = [
    { value: '', selected: false, termID: 4713, term: '[MBV]' }
  ];

  genericDopplervelocimetria: TermosGenericos[] = [
    { value: '', selected: false, termID: 5575, term: '[ArteriaUterinaDireita]' },
    { value: '', selected: false, termID: 5576, term: '[ArteriaUterinaEsquerda]' },
    { value: '', selected: false, termID: 5577, term: '[ArteriasUterinasIPMedio]' },
    { value: '', selected: false, termID: 5578, term: '[PercentilArteriasUterinas]' },
    { value: '', selected: false, termID: 5579, term: '[ArteriasUmbilicais]' },
    { value: '', selected: false, termID: 5580, term: '[PercentilArteriasUmbilicais]' },
    { value: '', selected: false, termID: 5581, term: '[ArteriaCerebralMedia]' },
    { value: '', selected: false, termID: 5582, term: '[PercentilArteriaCerebralMedia]' },
    { value: '', selected: false, termID: 5583, term: '[RelacaoCerebroplacentaria]' },
    { value: '', selected: false, termID: 5584, term: '[PercentilRelacaoCerebroplacentaria]' },
    { value: '', selected: false, termID: 5678, term: '[PicoSistolico]' },
    { value: '', selected: false, termID: 5679, term: '[Mom]' },
    { value: '', selected: false, termID: 5585, term: '[DuctoVenoso]' },
    { value: '', selected: false, termID: 5586, term: '[ArteriasUmbilicaisAlteradas]' },
  ];

  genericColoUterino: TermosGenericos[] = [
    { value: '', selected: false, termID: 4268, term: '[Medida]' }
  ];

  editLaudoMode: boolean = false;
  impressaoAtivaCrescimentoFetal: boolean = false;
  impressaoAtivaPlacenta: boolean = false;
  impressaoAtivaLiquidoAmniotico: boolean = false;
  percentil!: any;

  constructor(
    private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public _dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    private dataService: DataTermService,
    public _addText: AddText,
    private _controlImprimir: ControladorImprimirService
  ) { }

  htmlContent: string;
  mask: string;
  term = new Term();
  injuries = new Injuries();

  Subtitulo: string;
  subtituloBloco: string;
  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  medicaoNodulo = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  calcFrase = '';
  cabecalhoTexto = '';
  rodapeTexto = '';
  calcConclusao = '';
  objetoGrupoLesao: any;
  cenarioCorrente = 0;

  cabecalho = false;
  indicacao = false;
  calculadora = true;
  feto = false;
  avaliacaoCrescimentoFetal = false;
  biometriaComplementar = false;
  cranio = false;
  face = false;
  torax = false;
  coracao = false;
  abdome = false;
  genitalia = false;
  coluna = false;
  extremidades = false;
  placenta = false;
  cordaoUmbilical = false;
  liquidoAmniotico = false;
  //arteriasUterinas = false;
  showDopplervelocimetria = false;
  coloUterino = false;
  sludge = false;
  exibeTabelaDoppler = false;
  tabelaValor = new Array<string>();

  achados = false;
  rodape = false;
  public showForm: Boolean = true;
  public switchFormReport = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  medicalReportTypeID = 73;
  //calculadoraId = true;
  fetoId = 179;
  avaliacaoCrescimentoFetalId = 310;
  biometriaComplementarId = 311;
  cranioId = 312;
  faceId = 324;
  toraxId = 325;
  coracaoId = 326;
  abdomeId = 327;
  genitaliaId = 328;
  colunaId = 329;
  extremidadesId = 330;
  placentaId = 313;
  cordaoUmbilicalId = 306;
  liquidoAmnioticoId = 315;
  //arteriasUterinasId = 316;
  dopplervelocimetriaID = 437;
  coloUterinoID = 287;
  sludgeID = 288;

  ngOnInit() {
    this.utilizarEditor();
    this._toastr.warningToastr('Atenção, este exame passou por uma atualização para receber a última versão, pressione CTRL + SHIFT + DEL para excluir os dados de navegação e recarregue a página posteriormente. Para mais informações, entre em contato com nosso suporte.', 'Atualização realizada!',
      {
        position: 'top-right',
        toastTimeout: 8000,
        maxShown: 1
      });
    this._pouchService.loadDatabase("usgMorfologicaSegundoDopplerAnalise", this.descricoes, this.conclusoes);
    this.Subtitulo = 'Calculadora IG';
    this.subtituloBloco = this.Subtitulo;
    this.term.listTermID = new Array<number>();
    this.resetaDialog();

    const listBlocks = [
      //this.calculadoraId = true,
      this.fetoId,
      this.avaliacaoCrescimentoFetalId,
      this.biometriaComplementarId,
      this.cranioId,
      this.faceId,
      this.toraxId,
      this.coracaoId,
      this.abdomeId,
      this.genitaliaId,
      this.colunaId,
      this.extremidadesId,
      this.placentaId,
      this.cordaoUmbilicalId,
      this.liquidoAmnioticoId,
      this.dopplervelocimetriaID,
      this.coloUterinoID,
      this.sludgeID
      //this.arteriasUterinasId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "usg-morfologica-segDopplerMask").then((obj: any) => {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "morfoSegDoppler");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-morfoSegDoppler');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-morfoSegDoppler');
      }
    });
  }

  ngAfterViewInit(): void {
    this._controlImprimir.desabilitarImprimir();
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  blocos = [
    {
      titulo: 'Informações',
      id: 'cabecalho',
      name: 'cabecalho',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Indicação',
      id: 'indicacao',
      name: 'indicacao',
      subtitulo: 'preencha o campo indicação',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Calculadora IG',
      id: 'calculadora',
      name: 'calc-idade-gestacional',
      selected: true,
      blocked: false
    },
    {
      titulo: 'Feto',
      id: this.fetoId,
      name: 'feto',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Biometria Fetal',
      id: this.avaliacaoCrescimentoFetalId,
      name: 'crescimento-fetal',
      selected: false,
      blocked: true
    },
    {
      titulo: 'Biometria complementar',
      id: this.biometriaComplementarId,
      name: 'bio-complementar',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Crânio',
      id: this.cranioId,
      name: 'cranio',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Face',
      id: this.faceId,
      name: 'face',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Tórax',
      id: this.toraxId,
      name: 'torax',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Coração',
      id: this.coracaoId,
      name: 'coracao',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Abdome',
      id: this.abdomeId,
      name: 'abdome',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Genitália',
      id: this.genitaliaId,
      name: 'genitalia',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Coluna',
      id: this.colunaId,
      name: 'coluna',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Extremidades',
      id: this.extremidadesId,
      name: 'extremidades',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Líquido Amniótico',
      id: this.liquidoAmnioticoId,
      name: 'liquido-amniotico',
      selected: false,
      blocked: true
    },
    {
      titulo: 'Placenta',
      id: this.placentaId,
      name: 'placenta',
      selected: false,
      blocked: true
    },
    {
      titulo: 'Cordão umbilical',
      id: this.cordaoUmbilicalId,
      name: 'cordao-umbilical',
      selected: false,
      blocked: false
    },
    // {
    //   titulo: 'Dopplervelocimetria',
    //   id: this.arteriasUterinasId,
    //   name: 'arterias-uterinas',
    //   selected: false,
    //   blocked:false
    // },
    {
      titulo: 'Dopplervelocimetria',
      id: this.dopplervelocimetriaID,
      name: 'dopplervelocimetria',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Colo Uterino',
      id: this.coloUterinoID,
      name: 'coloUterino',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Sludge',
      subtitulo: 'Sludge',
      id: this.sludgeID,
      name: 'sludge',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Achados adicionais',
      id: 'achados',
      name: 'achados-adicionais',
      subtitulo: 'Preencha o campo achados adicionais',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
      blocked: false
    }
  ]

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if (itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

    if (this.editLaudoMode && Number.isInteger(itemSelecionado.id)) {
      let seachTerm = this.listTermDefault.filter(item => item.blockID === itemSelecionado.id);
      this.dataService.setTermos(seachTerm[0].listTerm, seachTerm[0].blockID);
    }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.calculadora = (itemSelecionado.id === 'calculadora');
    this.feto = (itemSelecionado.id === this.fetoId);
    this.avaliacaoCrescimentoFetal = (itemSelecionado.id === this.avaliacaoCrescimentoFetalId);
    this.biometriaComplementar = (itemSelecionado.id === this.biometriaComplementarId);
    this.cranio = (itemSelecionado.id === this.cranioId);
    this.face = (itemSelecionado.id === this.faceId);
    this.torax = (itemSelecionado.id === this.toraxId);
    this.coracao = (itemSelecionado.id === this.coracaoId);
    this.abdome = (itemSelecionado.id === this.abdomeId);
    this.genitalia = (itemSelecionado.id === this.genitaliaId);
    this.coluna = (itemSelecionado.id === this.colunaId);
    this.extremidades = (itemSelecionado.id === this.extremidadesId);
    this.placenta = (itemSelecionado.id === this.placentaId);
    this.cordaoUmbilical = (itemSelecionado.id === this.cordaoUmbilicalId);
    this.liquidoAmniotico = (itemSelecionado.id === this.liquidoAmnioticoId);
    //this.arteriasUterinas = (itemSelecionado.id === this.arteriasUterinasId);
    this.showDopplervelocimetria = (itemSelecionado.id === this.dopplervelocimetriaID);
    this.coloUterino = (itemSelecionado.id === this.coloUterinoID);
    this.sludge = (itemSelecionado.id === this.sludgeID);
    this.achados = (itemSelecionado.id === 'achados');
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  atualizaTabela() {
    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.AdicionaTexto(true);
    }
  }

  showTabela($event) {
    this.exibeTabelaDoppler = $event;
    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    }
  }

  atribuiValorTabela($element) {
    let value = '-';
    let elementID = '';

    this.exibeTabelaDoppler = true;

    if ($element.elemento)
      value = this._validacoes.formataDecimal($element.elemento.value);

    if ($element.elemento)
      elementID = $element.elemento.id;

    if (elementID === '5577') {
      this.tabelaValor[0] = value;
    } else if (elementID === '5577') {
      this.tabelaValor[0] = value;
    } else if (elementID === '5578') {
      this.tabelaValor[1] = value;
    } else if (elementID === '5578') {
      this.tabelaValor[1] = value;
    } else if (elementID === '5579') {
      this.tabelaValor[2] = value;
    } else if (elementID === '5579') {
      this.tabelaValor[2] = value;
    } else if (elementID === '5580') {
      this.tabelaValor[3] = value;
    } else if (elementID === '5580') {
      this.tabelaValor[3] = value;
    } else if (elementID === '5581') {
      this.tabelaValor[4] = value;
    } else if (elementID === '5581') {
      this.tabelaValor[4] = value;
    } else if (elementID === '5582') {
      this.tabelaValor[5] = value;
    } else if (elementID === '5582') {
      this.tabelaValor[5] = value;
    } else if (elementID === '5583') {
      this.tabelaValor[6] = value;
    } else if (elementID === '5583') {
      this.tabelaValor[6] = value;
    } else if (elementID === '5584') {
      this.tabelaValor[7] = value;
    } else if (elementID === '5584') {
      this.tabelaValor[7] = value;
    } else if (elementID === '5585') {
      this.tabelaValor[8] = value;
    } else if (elementID === '5585') {
      this.tabelaValor[8] = value;
    }

    let tabeDoppler = this.TabelaDoppler();
    if (tabeDoppler !== '') {
      if (!this.newEditor) {
        this.AdicionaTexto(this.newEditor);
      } else {
        this.atualizaLinha({
          blockId: 'tabelaDoppler',
          tabelaDoppler: tabeDoppler,
        });
      }
    }

  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();

    this.genericVelocimetria = [
      { value: '', selected: false, termID: 4419, term: '[IPDir]' },
      { value: '', selected: false, termID: 4707, term: '[Morfologia UTD Dir]' },
      { value: '', selected: false, termID: 4401, term: '[IPEsq]' },
      { value: '', selected: false, termID: 4708, term: '[Morfologia UTD Esq]' },
      { value: '', selected: false, termID: 4709, term: '[IP Medio]' },
      { value: '', selected: false, termID: 4786, term: '[Percentil IP Medio]' }
    ];

    this.genericCoracao = [
      { value: '', selected: false, termID: 4756, term: '[Situs]' },
      { value: '', selected: false, termID: 4757, term: '[Camaras]' },
      { value: '', selected: false, termID: 4758, term: '[Septo]' },
      { value: '', selected: false, termID: 4759, term: '[Grandes Vasos]' }
    ];

    this.genericAbdome = [
      { value: '', selected: false, termID: 4744, term: '[Estomago]' },
      { value: '', selected: false, termID: 4745, term: '[Parede]' },
      { value: '', selected: false, termID: 4746, term: '[Figado]' },
      { value: '', selected: false, termID: 4747, term: '[Vesicula]' },
      { value: '', selected: false, termID: 4748, term: '[Intestino]' },
      { value: '', selected: false, termID: 4749, term: '[Rins]' },
      { value: '', selected: false, termID: 4750, term: '[Bexiga]' }
    ];

    this.genericGenitalia = [
      { value: '', selected: false, termID: 4743, term: '[Genitalia]' }
    ];

    this.genericFeto = [
      { value: '', selected: false, termID: 1980, term: '[situacao]' },
      { value: '', selected: false, termID: 1981, term: '[apresentacao]' },
      { value: '', selected: false, termID: 1982, term: '[dorso]' },
      { value: '', selected: false, termID: 1983, term: '[frequencia cardiaca]' },
      { value: '', selected: false, termID: 4168, term: '[polo cefalico]' },
      { value: '', selected: false, termID: 5570, term: '[SituacaoAusente]' },
      { value: '', selected: false, termID: 5571, term: '[ApresentacaoAusente]' },
      { value: '', selected: false, termID: 5572, term: '[DorsoAusente]' },
    ];

    this.genericCrescimentoFetal = [
      { value: '', selected: false, termID: 4725, term: '[DBP]' },
      { value: '', selected: false, termID: 4726, term: '[DOF]' },
      { value: '', selected: false, termID: 4727, term: '[CC]' },
      { value: '', selected: false, termID: 4728, term: '[CA]' },
      { value: '', selected: false, termID: 4729, term: '[CUM]' },
      { value: '', selected: false, termID: 4730, term: '[CF]' },
      { value: '', selected: false, termID: 4731, term: '[peso]' },
      { value: '', selected: false, termID: 4788, term: '[Percentil]' },
      { value: '', selected: false, termID: 4777, term: '[Calculo]' }
    ];

    this.genericBiometriaComplementar = [
      { value: '', selected: false, termID: 4732, term: '[Cerebelo]' },
      { value: '', selected: false, termID: 4733, term: '[Cisterna magna]' },
      { value: '', selected: false, termID: 5567, term: '[PregaNucal]' },
      { value: '', selected: false, termID: 4734, term: '[Atrio ventricular]' },
      { value: '', selected: false, termID: 4735, term: '[Distancia interorbitaria]' },
      { value: '', selected: false, termID: 4736, term: '[Distancia bi-orbitaria]' },
      { value: '', selected: false, termID: 4737, term: '[Osso nasal]' },
      { value: '', selected: false, termID: 4738, term: '[Umero]' },
      { value: '', selected: false, termID: 4739, term: '[Radio]' },
      { value: '', selected: false, termID: 4740, term: '[Ulna]' },
      { value: '', selected: false, termID: 4741, term: '[Tibia]' },
      { value: '', selected: false, termID: 4742, term: '[Fibula]' },
      { value: '', selected: false, termID: 5568, term: '[Pe]' },
    ];

    this.genericCranio = [
      { value: '', selected: false, termID: 4751, term: '[estruturas da linha media]' },
      { value: '', selected: false, termID: 4752, term: '[cortex]' },
      { value: '', selected: false, termID: 4753, term: '[ventriculos cerebrais]' },
      { value: '', selected: false, termID: 4754, term: '[cerebelo e fossa posterior]' },
      { value: '', selected: false, termID: 4755, term: '[nuca]' },
    ];

    this.genericFace = [
      { value: '', selected: false, termID: 4767, term: '[orbitas oculares]' },
      { value: '', selected: false, termID: 4768, term: '[cristalinos]' },
      { value: '', selected: false, termID: 4769, term: '[osso nasal]' },
      { value: '', selected: false, termID: 4770, term: '[labios]' },
      { value: '', selected: false, termID: 4771, term: '[perfil da face]' },
      { value: '', selected: false, termID: 4773, term: '[formacao costal]' },
    ];

    this.genericTorax = [
      { value: '', selected: false, termID: 4772, term: '[pulmoes]' },
      { value: '', selected: false, termID: 4774, term: '[formacao costal]' },
      { value: '', selected: false, termID: 4775, term: '[diafragma]' },
    ];

    this.genericColuna = [
      { value: '', selected: false, termID: 4718, term: '[Coluna]' }
    ];

    this.genericExtremidades = [
      { value: '', selected: false, termID: 4717, term: '[Extremidades]' }
    ];

    this.genericPlacenta = [
      { value: '', selected: false, termID: 4714, term: '[localizacao]' },
      { value: '', selected: false, termID: 4715, term: '[grau]' },
      { value: '', selected: false, termID: 4791, term: '[espessura]' }

    ];

    this.genericCordaoUmbilical = [
      { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
    ];

    this.genericLiquidoAmniotico = [
      { value: '', selected: false, termID: 4713, term: '[MBV]' }
    ];

    this.genericDopplervelocimetria = [
      { value: '', selected: false, termID: 5575, term: '[ArteriaUterinaDireita]' },
      { value: '', selected: false, termID: 5576, term: '[ArteriaUterinaEsquerda]' },
      { value: '', selected: false, termID: 5577, term: '[ArteriasUterinasIPMedio]' },
      { value: '', selected: false, termID: 5578, term: '[PercentilArteriasUterinas]' },
      { value: '', selected: false, termID: 5579, term: '[ArteriasUmbilicais]' },
      { value: '', selected: false, termID: 5580, term: '[PercentilArteriasUmbilicais]' },
      { value: '', selected: false, termID: 5581, term: '[ArteriaCerebralMedia]' },
      { value: '', selected: false, termID: 5582, term: '[PercentilArteriaCerebralMedia]' },
      { value: '', selected: false, termID: 5583, term: '[RelacaoCerebroplacentaria]' },
      { value: '', selected: false, termID: 5584, term: '[PercentilRelacaoCerebroplacentaria]' },
      { value: '', selected: false, termID: 5678, term: '[PicoSistolico]' },
      { value: '', selected: false, termID: 5679, term: '[Mom]' },
      { value: '', selected: false, termID: 5585, term: '[DuctoVenoso]' },
      { value: '', selected: false, termID: 5586, term: '[ArteriasUmbilicaisAlteradas]' },
    ];

    this.genericColoUterino = [
      { value: '', selected: false, termID: 4268, term: '[Medida]' }
    ];
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/>';
    else
      this.indicacaoTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaCalculadora($event) {
    if ($event[0] !== undefined) {
      this.calcFrase = $event[0].toString();
    } else {
      this.calcFrase = '';
    }
    if ($event[1] !== undefined) {
      this.calcConclusao = $event[1].toString();
    } else {
      this.calcConclusao = '';
    }

    //Substitui a frase padrão de conclusão apenas para morfológicos. 62714
    if (this.calcConclusao == 'Gestação única, tópica, de concepto vivo.' && this.genericCrescimentoFetal.filter(item => item.termID === 4777)[0].value != '') {
      this.calcConclusao = 'Gestação única, tópica, de concepto vivo, compatível com ** semanas e ** dias, segundo a (DUM / ultrassonografia precoce).<br/>';
      this.calcConclusao += 'Biometria fetal do presente exame compatível com ' + this.genericCrescimentoFetal.filter(item => item.termID === 4777)[0].value;
    }

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'calculadora',
        calculadora: this.calcFrase
      });
    }

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'conclusaoCalculadora',
        calculadora: this.calcConclusao
      });
    }
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  enviaCranio() { }
  enviaFace() { }
  enviaTorax() { }


  adicionaSelecaoVelocimetria($event) {
    this.genericVelocimetria.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCoracao($event) {
    this.genericCoracao.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAbdome($event) {
    this.genericAbdome.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoGenitalia($event) {
    this.genericGenitalia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFeto($event) {
    this.genericFeto.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  adicionaSelecaoCrescimentoFetal($event) {
    this.genericCrescimentoFetal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoParametrosBiometricos($event) {
    this.genericBiometriaComplementar.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCranio($event) {
    this.genericCranio.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoFace($event) {
    this.genericFace.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoTorax($event) {
    this.genericTorax.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoColuna($event) {
    this.genericColuna.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoExtremidades($event) {
    this.genericExtremidades.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoPlacenta($event) {
    this.genericPlacenta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCordaoUmbilical($event) {
    this.genericCordaoUmbilical.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoLiquido($event) {
    this.genericLiquidoAmniotico.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoDopplervelocimetria($event) {
    this.genericDopplervelocimetria.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoColo($event) {
    this.genericColoUterino.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = '<br/><br/><b>Achados adicionais:</b><br/>' + texto;
    else
      this.achadosAdTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'achados',
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if (!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  consultarAPI() {
    let desc;
    if (this.feto) {
      this.term.blockID = this.fetoId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.fetoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoFeto(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.fetoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.fetoId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoFeto(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.dopplervelocimetriaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fetoId || conclusaoAll[0].blockID === this.dopplervelocimetriaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.avaliacaoCrescimentoFetal) {
      this.term.blockID = this.avaliacaoCrescimentoFetalId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.avaliacaoCrescimentoFetalId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoCrecimentoFetal(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.avaliacaoCrescimentoFetalId);
            if (index > -1) {
              this.descricoes[index].value = '<b>BIOMETRIA FETAL</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.avaliacaoCrescimentoFetalId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclucaoCrecimentoFetal(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.avaliacaoCrescimentoFetalId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.avaliacaoCrescimentoFetalId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }
        // else if (obj.value == '') {
        //   conclusaoNull.value = '';
        //   conclusao.value = this.ajustaConclusaoCrescimentoFetal();
        // }

        if (this.calcConclusao == 'Gestação única, tópica, de concepto vivo.' && this.genericCrescimentoFetal.filter(item => item.termID === 4777)[0].value != '') {
          this.calcConclusao = 'Gestação única, tópica, de concepto vivo, compatível com ** semanas e ** dias, segundo a (DUM / ultrassonografia precoce).<br/>';
          this.calcConclusao += 'Biometria fetal do presente exame compatível com ' + this.genericCrescimentoFetal.filter(item => item.termID === 4777)[0].value;
        } else {
          conclusaoNull.value = this.calcConclusao;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.biometriaComplementar) {
      this.term.blockID = this.biometriaComplementarId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.biometriaComplementarId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoBiometria(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.biometriaComplementarId);
            if (index > -1) {
              this.descricoes[index].value = '<b>BIOMETRIA COMPLEMENTAR</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.biometriaComplementarId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoBiometria(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.biometriaComplementarId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.biometriaComplementarId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.cranio) {
      this.term.blockID = this.cranioId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.cranioId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoCranio(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.cranioId);
            if (index > -1) {
              this.descricoes[index].value = '<b>CRÂNIO</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.cranioId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoCranio(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.cranioId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cranioId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoCranio(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.face) {
      this.term.blockID = this.faceId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.faceId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoFace(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.faceId);
            if (index > -1) {
              this.descricoes[index].value = '<b>FACE</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.faceId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoFace(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.faceId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.faceId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoFace(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.torax) {
      this.term.blockID = this.toraxId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.toraxId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoTorax(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.toraxId);
            if (index > -1) {
              this.descricoes[index].value = '<b>TÓRAX</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.toraxId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoTorax(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.toraxId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.toraxId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoTorax(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.liquidoAmniotico) {
      this.term.blockID = this.liquidoAmnioticoId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.liquidoAmnioticoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = '<b>LÍQUIDO AMNIÓTICO:</b><br>' + obj.value + espaco + this.MontaDescricaoConclusaoLiquidoAmniotico(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.liquidoAmnioticoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.liquidoAmnioticoId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoConclusaoLiquidoAmniotico(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.liquidoAmnioticoId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoAmnioticoId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.cordaoUmbilical) {
      this.term.blockID = this.cordaoUmbilicalId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.cordaoUmbilicalId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoCordaoUmbilical(resultado.text);
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.cordaoUmbilicalId);
            if (index > -1) {
              if (obj.value)
                this.descricoes[index].value = '<b>CORDÃO UMBILICAL</b></br>' + obj.value;
              else
                this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });
        let obj = {
          value: '',
          blockID: this.cordaoUmbilicalId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.cordaoUmbilicalId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cordaoUmbilicalId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.placenta) {
      this.term.blockID = this.placentaId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.placentaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + espaco + this.MontaDescricaoPlacenta(resultado.text);
              espaco = '</br>';
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.placentaId);
            if (index > -1) {
              this.descricoes[index].value = '<b>PLACENTA:</b><br>' + obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.placentaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoPlacenta(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.placentaId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.placentaId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.coluna) {
      this.term.blockID = this.colunaId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.colunaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoColuna(resultado.text);
              contadorTexto++;
            });

            const index = this.descricoes.findIndex(x => x.blockID === this.colunaId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });
        let obj = {
          value: '',
          blockID: this.colunaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoColuna(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.colunaId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.colunaId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoColuna(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.extremidades) {
      this.term.blockID = this.extremidadesId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.extremidadesId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoExtremidades(resultado.text);
              contadorTexto++;
            });

            const index = this.descricoes.findIndex(x => x.blockID === this.extremidadesId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });
        let obj = {
          value: '',
          blockID: this.extremidadesId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoExtremidades(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.extremidadesId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.extremidadesId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoExtremidades(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.abdome) {
      this.term.blockID = this.abdomeId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.abdomeId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoAbdome(resultado.text);
              contadorTexto++;
            });

            const index = this.descricoes.findIndex(x => x.blockID === this.abdomeId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });
        let obj = {
          value: '',
          blockID: this.abdomeId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoAbdome(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.abdomeId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.abdomeId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoAbdome(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.coracao) {
      this.term.blockID = this.coracaoId;
      if (this.editLaudoMode) {
        let termList = this.term.listTermID;
        termList.push(4756);
        this.dataService.setTermos(termList, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.coracaoId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoCoracao(resultado.text);
              contadorTexto++;
            });

            const index = this.descricoes.findIndex(x => x.blockID === this.coracaoId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });
        let obj = {
          value: '',
          blockID: this.coracaoId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoCoracao(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.coracaoId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.coracaoId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = this.ajustaConclusaoCoracao(obj.value);
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.genitalia) {
      this.term.blockID = this.genitaliaId;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID)
      }
      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.genitaliaId && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = obj.value + this.MontaDescricaoGenitalia(resultado.text);
              contadorTexto++;
            });

            const index = this.descricoes.findIndex(x => x.blockID === this.genitaliaId);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });
        let obj = {
          value: '',
          blockID: this.genitaliaId,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoGenitalia(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.genitaliaId);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.genitaliaId) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });

    } else if (this.showDopplervelocimetria) {
      this.term.blockID = this.dopplervelocimetriaID;
      if (this.editLaudoMode) {
        this.dataService.setTermos(this.term.listTermID, this.term.blockID);
      }
      this.term.injuries.push(this.injuries);

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.dopplervelocimetriaID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            let espaco = '';
            let contadorTexto = 0;
            this.termResultsAPI.forEach(resultado => {
              obj.value = '<b>ESTUDO DOPPLERVELOCIMETRIA - ÍNDICE DE PULSATILIDADE</b></br>' + obj.value + espaco + this.MontaDescricaoDopplervelocimetria(resultado.text);
              contadorTexto++;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.dopplervelocimetriaID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto(this.term, obj.value);
          }
        });

        let obj = {
          value: '',
          blockID: this.dopplervelocimetriaID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaConclusaoDopplervelocimetria(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.dopplervelocimetriaID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.dopplervelocimetriaID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.coloUterino) {
      this.term.blockID = this.coloUterinoID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.coloUterinoID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              if (resultado.text) {
                obj.value = '<b>AVALIAÇÃO DO COLO UTERINO</b></br>' + this.MontaDescricaoConclusaoColo(resultado.text);
              }
            });

            const index = this.descricoes.findIndex(x => x.blockID === this.coloUterinoID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.coloUterinoID,
          textTermID: 0
        };
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        obj.value = '';
        let espaco = '';
        let contadorTexto = 0;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = obj.value + espaco + this.MontaDescricaoConclusaoColo(resultado.conclusion);
            espaco = '</br>';
            contadorTexto++;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.coloUterinoID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.coloUterinoID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    } else if (this.sludge) {
      debugger
      this.term.blockID = this.sludgeID;

      this._pouchService.getLaudos(this.term).then((termResults) => {
        this.descricoes.forEach(obj => {
          if (obj.blockID === this.sludgeID && termResults[0] !== undefined) {
            this.termResultsAPI = termResults;
            obj.textTermID = this.termResultsAPI[0].id;
            obj.value = '';
            this.termResultsAPI.forEach(resultado => {
              obj.value = resultado.text;
            });

            let index = this.descricoes.findIndex(x => x.blockID === this.sludgeID);
            if (index > -1) {
              this.descricoes[index].value = obj.value;
              this.descricoes[index].textTermID = obj.textTermID;
              desc = obj.value;
            }
            // this.AdicionaTexto();
          }
        });

        let obj = {
          value: '',
          blockID: this.sludgeID,
          textTermID: 0
        };
        let espaco = '';
        const indexConclusion = termResults.filter(x => x.conclusion !== null);
        this.termConclusionAPI = termResults;
        this.termConclusionAPI.forEach(resultado => {
          if (resultado.conclusion) {
            obj.value = resultado.conclusion;
          } else {
            if (indexConclusion.length <= 0) {
              obj.blockID = 0;
              obj.value = this.RetornaConclusaoNull(resultado.conclusion);
            } else {
              if (!obj.value) {
                obj.blockID = 0;
                obj.value = '';
              }
            }
          }
        });

        const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
        const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
        const conclusao = this.conclusoes.find(x => x.blockID === this.sludgeID);

        if (obj.blockID === 0) {
          if (conclusaoAll.length <= 1) {
            if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.sludgeID) {
              conclusaoNull.value = obj.value;
              conclusao.value = '';
            } else {
              conclusaoNull.value = '';
            }
          } else {
            conclusao.value = '';
          }
        } else if (obj.value) {
          conclusaoNull.value = '';
          conclusao.value = obj.value;
        }

        if (!this.newEditor) {
          this.AdicionaTexto(this.newEditor);
        } else {
          this.atualizaLinha({
            blockId: this.term.blockID,
            descricao: desc,
            conclusao: conclusao.value,
            conclusaoNull: conclusaoNull.value
          });
        }
      });
    }
  }

  MontaDescricaoDopplervelocimetria(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 5575 || +term === 5576 || +term === 5577
        || +term === 5578 || +term === 5579 || +term === 5580
        || +term === 5581 || +term === 5582 || +term === 5583
        || +term === 5584 || +term === 5585 || +term === 5586
        || +term === 5678 || +term === 5679 ) {
        this.genericDopplervelocimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;

            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoDopplervelocimetria(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 5586) {
        this.genericDopplervelocimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  // MontaConclusaoDopplervelocimetria(texto) {
  //   let textoRetorno = '';
  //   this.term.listTermID.forEach(term => {
  //     if(+term === 5586 ){
  //       this.genericDopplervelocimetria.forEach(generic => {
  //         if (+generic.termID === +term) {
  //           generic.selected = true;
  //           textoRetorno = texto.replace(generic.term, generic.value);
  //           texto = textoRetorno;
  //         }
  //       });
  //     }
  //   });

  //   return texto;
  // }

  MontaDescricaoDopplerVelocimetria(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 4401 || +term === 4419 || +term === 4707
        || +term === 4708 || +term === 4709 || +term === 4786) {
        this.genericVelocimetria.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCoracao(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4756 || +term === 4757 || +term === 4758 || +term === 4759) {
        this.genericCoracao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoCoracao(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4756 || +term === 4757 || +term === 4758 || +term === 4759) {
        this.genericCoracao.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoAbdome(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4744 || +term === 4745 || +term === 4746 || +term === 4747 || +term === 4748 || +term === 4749 || +term === 4750) {
        this.genericAbdome.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoAbdome(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4744 || +term === 4745 || +term === 4746 || +term === 4747 || +term === 4748 || +term === 4749 || +term === 4750) {
        this.genericAbdome.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoGenitalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4743) {
        this.genericGenitalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoGenitalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4743) {
        this.genericGenitalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }
  MontaDescricaoFeto(texto) {
    let textoRetorno = '';
    this.term.listTermID.forEach(term => {
      if (+term === 1980 || +term === 1981 || +term === 1982 || +term === 1983 ||
        +term === 4168 || +term === 5570 || +term === 5571 || +term === 5572) {
        this.genericFeto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoFeto(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1980 || +term === 1981 || +term === 1982 || +term === 1983 ||
        +term === 4168 || +term === 5570 || +term === 5571 || +term === 5572) {
        this.genericFeto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            //Apresentacao
            if (+term === 4721) {
              if (generic.value === 'pélvica') {
                textoRetorno = textoRetorno.slice(0, -1) + '. Apresentação pélvica.';
              } else if (generic.value === 'córmica') {
                textoRetorno = textoRetorno.slice(0, -1) + '. Apresentação córmica.';
              }
            }
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  handleGetPercentil(value) {
    let number = value.replace(',', '.');
    this.percentil = Number(number);
  }

  MontaDescricaoCrecimentoFetal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4725 || +term === 4726 || +term === 4727 || +term === 4728 || +term === 4729 || +term === 4730 || +term === 4731 || +term === 4788 || +term === 4777) {
        this.genericCrescimentoFetal.forEach(generic => {
          if (+generic.termID === +term) {
            if (+term === 4788) {
              this.handleGetPercentil(generic.value);
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclucaoCrecimentoFetal(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4725 || +term === 4726 || +term === 4727 || +term === 4728 || +term === 4729 || +term === 4730 || +term === 4731 || +term === 4789 || +term === 4788) {
        this.genericCrescimentoFetal.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoBiometria(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      console.log(term);
      if (+term === 4732 || +term === 4733 || +term === 4734 || +term === 4735 || +term === 4736 || +term === 4737 || +term === 4738 || +term === 4739 || +term === 4740 || +term === 4741 || +term === 4742 || +term === 5567 || +term === 5568) {
        this.genericBiometriaComplementar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclucaoBiometria(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      console.log(term);
      if (+term === 4732 || +term === 4733 || +term === 4734 || +term === 4735 || +term === 4736 || +term === 4737 || +term === 4738 || +term === 4739 || +term === 4740 || +term === 4741 || +term === 4742 || +term === 5567 || +term === 5568) {
        this.genericBiometriaComplementar.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCranio(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4751 || +term === 4752 || +term === 4753 || +term === 4754 || +term === 4755) {
        this.genericCranio.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclucaoCranio(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4751 || +term === 4752 || +term === 4753 || +term === 4754 || +term === 4755) {
        this.genericCranio.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoFace(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4767 || +term === 4768 || +term === 4769 || +term === 4770 || +term === 4771 || +term === 4773) {
        this.genericFace.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclucaoFace(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4767 || +term === 4768 || +term === 4769 || +term === 4770 || +term === 4771 || +term === 4773) {
        this.genericFace.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoTorax(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4772 || +term === 4774 || +term === 4775) {
        this.genericTorax.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclucaoTorax(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4772 || +term === 4774 || +term === 4775) {
        this.genericTorax.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoColuna(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4718) {
        this.genericColuna.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoColuna(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4718) {
        this.genericColuna.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (generic.value === 'Sem alterações nos três planos visualizados') {
              generic.value = '';
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoExtremidades(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4717) {
        this.genericExtremidades.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoExtremidades(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4717) {
        this.genericExtremidades.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            if (generic.value === 'proporcionais e simétricos') {
              generic.value = '';
            }
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4714 || +term === 4715 || +term === 4791) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCordaoUmbilical(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4703 || +term === 4704 || +term === 4705 || +term === 4706) {
        this.genericCordaoUmbilical.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoConclusaoLiquidoAmniotico(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4713) {
        this.genericLiquidoAmniotico.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoConclusaoColo(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4268) {
        this.genericColoUterino.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  ajustaConclusaoCoracao(texto: string): string {
    if (texto.includes('Situs solitus.')) {
      texto = texto.replace("Situs solitus.<br/>", "");
    }
    if (texto.includes('Quatro câmaras de aspecto habitual.')) {
      texto = texto.replace("Quatro câmaras de aspecto habitual.<br/>", "");
    }
    if (texto.includes('Quatro câmaras de aspecto habitual. Sugere-se ecocardiografia fetal.')) {
      texto = texto.replace("Quatro câmaras de aspecto habitual. Sugere-se ecocardiografia fetal.<br/>", "");
    }
    if (texto.includes('Septo interventricular íntegro.')) {
      texto = texto.replace("Septo interventricular íntegro.<br/>", "");
    }
    if (texto.includes('Septo interventricular íntegro. Sugere-se ecocardiografia fetal.')) {
      texto = texto.replace("Septo interventricular íntegro. Sugere-se ecocardiografia fetal.<br/>", "");
    }
    if (texto.includes('Vias de saída visibilizadas e proporcionais. Sugere-se ecocardiografia fetal.')) {
      texto = texto.replace("Vias de saída visibilizadas e proporcionais. Sugere-se ecocardiografia fetal.", "");
    }
    return texto;
  }

  ajustaConclusaoCranio(texto: string): string {
    if (texto.includes('Estruturas da linha média: presentes e normais.')) {
      texto = texto.replace("Estruturas da linha média: presentes e normais.</br>", "");
    }
    if (texto.includes('Córtex: de aspecto habitual.')) {
      texto = texto.replace("Córtex: de aspecto habitual.</br>", "");
    }
    if (texto.includes('Ventrículos cerebrais: sem dilatações.')) {
      texto = texto.replace("Ventrículos cerebrais: sem dilatações.</br>", "");
    }
    if (texto.includes('Cerebelo e fossa posterior: de forma habitual.</br>')) {
      texto = texto.replace("Cerebelo e fossa posterior: de forma habitual.</br>", "");
    }
    if (texto.includes('Nuca: sem edemas ou cistos.')) {
      texto = texto.replace("Nuca: sem edemas ou cistos.", "");
    }
    return texto;
  }

  ajustaConclusaoFace(texto: string): string {
    if (texto.includes('Órbitas oculares: identificadas.')) {
      texto = texto.replace("Órbitas oculares: identificadas.</br>", "");
    }
    if (texto.includes('Cristalinos: identificados e de aspecto habitual.')) {
      texto = texto.replace("Cristalinos: identificados e de aspecto habitual.</br>", "");
    }
    if (texto.includes('Osso nasal: presente.')) {
      texto = texto.replace("Osso nasal: presente.</br>", "");
    }
    if (texto.includes('Lábios: sem soluções de continuidade.</br>')) {
      texto = texto.replace("Lábios: sem soluções de continuidade.</br>", "");
    }
    if (texto.includes('Perfil da face: sem alterações.')) {
      texto = texto.replace("Perfil da face: sem alterações.", "");
    }
    return texto;
  }

  ajustaConclusaoTorax(texto: string): string {
    if (texto.includes('Pulmões: homogêneos.')) {
      texto = texto.replace("Pulmões: homogêneos.</br>", "");
    }
    if (texto.includes('Formação costal: simétrica e proporcional.')) {
      texto = texto.replace("Formação costal: simétrica e proporcional.</br>", "");
    }
    if (texto.includes('Diafragma: identificado.')) {
      texto = texto.replace("Diafragma: identificado.", "");
    }
    return texto;
  }

  ajustaConclusaoAbdome(texto: string): string {
    if (texto.includes('Estômago identificado em topografia habitual.')) {
      texto = texto.replace("Estômago identificado em topografia habitual.<br/>", "");
    }
    if (texto.includes('Parede abdominal íntegra.')) {
      texto = texto.replace("Parede abdominal íntegra.<br/>", "");
    }
    if (texto.includes('Fígado com ecotextura homogênea, sem calcificações.')) {
      texto = texto.replace("Fígado com ecotextura homogênea, sem calcificações.<br/>", "");
    }
    if (texto.includes('Vesícula biliar visibilizada.')) {
      texto = texto.replace("Vesícula biliar visibilizada.<br/>", "");
    }
    if (texto.includes('Intestino de aspecto normal.')) {
      texto = texto.replace("Intestino de aspecto normal.<br/>", "");
    }
    if (texto.includes('Rins com parênquima normal, sem dilatações das pelves renais.')) {
      texto = texto.replace("Rins com parênquima normal, sem dilatações das pelves renais.<br/>", "");
    }
    if (texto.includes('Bexiga urinária em topografia usual e de dimensões normais.')) {
      texto = texto.replace("Bexiga urinária em topografia usual e de dimensões normais.", "");
    }
    return texto;
  }

  ajustaConclusaoColuna(texto: string): string {
    if (texto.includes('.')) {
      texto = texto.replace(".", "");
    }
    return texto;
  }

  ajustaConclusaoExtremidades(texto: string): string {
    if (texto.includes('.')) {
      texto = texto.replace(".", "");
    }
    return texto;
  }

  ajustaConclusaoCrescimentoFetal(): string {
    this.term.listTermID.forEach(term => {
      if (+term === 4788) {
        this.genericCrescimentoFetal.forEach(generic => {
          if (+generic.termID === +term) {
            if (+term === 4788) {
              this.handleGetPercentil(generic.value);
            }
          }
        });
      }
    });

    let texto = ''
    if (this.percentil < 3) {
      texto = "Restrição de crescimento fetal grave para idade gestacional. <br>" + texto;
    } else if (this.percentil >= 3 && this.percentil <= 10) {
      texto = "Restrição de crescimento fetal para idade gestacional. <br>" + texto;
    } else if (this.percentil > 10 && this.percentil <= 90) {
      texto = "Crescimento fetal adequado para a idade gestacional. <br>" + texto;
    } else if (this.percentil > 90) {
      texto = "Crescimento fetal grande para idade gestacional. <br>" + texto;
    }
    return texto;
  }

  TabelaDoppler(): any {
    let strTable = '<style>' +
      'table {' +
      'width: 80%;' +
      '}' +
      'table, th, td {' +
      'border: 1px solid #c2c0c2;' +
      'border-collapse: collapse;' +
      'th, td {' +
      '  padding: 5px;' +
      '  text-align: left;' +
      '}' +
      '</style>' +
      '<table>';
    if (this.tabelaValor[0]) {
      strTable += '  <tr>';
      strTable += '    <th><b></b></th>';
      strTable += '    <th><b>IP</b></th>';
      strTable += '    <th><b>Percentil</b></th>';
      strTable += '    <th><b>Normalidade</b></th>';
      strTable += '  </tr>';

    }
    if (this.tabelaValor[0] || this.tabelaValor[1]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Artérias Uterinas (IP Médio)</b></td>';
      strTable += '    <td>' + this.tabelaValor[0] + '</td>';
      const perc1 = this.tabelaValor[1] ? this.tabelaValor[1] : '-';
      strTable += '    <td>' + perc1 + '</td>';
      strTable += '    <td>&lt;p95</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[2] || this.tabelaValor[3]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Artérias Umbilicais</b></td>';
      strTable += '    <td>' + this.tabelaValor[2] + '</td>';
      const perc3 = this.tabelaValor[3] ? this.tabelaValor[3] : '-';
      strTable += '    <td>' + perc3 + '</td>';
      strTable += '    <td>&lt;p95</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[4] || this.tabelaValor[5]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Artéria Cerebral Média</b></td>';
      strTable += '    <td>' + this.tabelaValor[4] + '</td>';
      const perc5 = this.tabelaValor[5] ? this.tabelaValor[5] : '-';
      strTable += '    <td>' + perc5 + '</td>';
      strTable += '    <td>>p5</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[6] || this.tabelaValor[7]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Relação cérebro-placentária</b></td>';
      strTable += '    <td>' + this.tabelaValor[6] + '</td>';
      const perc7 = this.tabelaValor[7] ? this.tabelaValor[7] : '-';
      strTable += '    <td>' + perc7 + '</td>';
      strTable += '    <td>>p5</td>';
      strTable += '  </tr>';
    }
    if (this.tabelaValor[8]) {
      strTable += '  <tr>';
      strTable += '    <td><b>Ducto venoso</b></td>';
      strTable += '    <td>' + this.tabelaValor[8] + '</td>';
      strTable += '    <td>-</td>';
      strTable += '    <td><1,0</td>';
      strTable += '  </tr>';
    }

    strTable += '</tr>' +
      '</table>';

    return strTable;
  }

  verificaAnatomiaNormal() {
    let anatomiaNormal = true;
    for (let i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].blockID === 312 || this.conclusoes[i].blockID === 324 || this.conclusoes[i].blockID === 325 ||
        this.conclusoes[i].blockID === 326 || this.conclusoes[i].blockID === 327 ||
        this.conclusoes[i].blockID === 329 || this.conclusoes[i].blockID === 330) {
        if (this.conclusoes[i].value !== '') {
          anatomiaNormal = false;
          break;
        }
      }
    }
    if (anatomiaNormal) {
      this.conclusoes[2].visivel = true;
    } else {
      this.conclusoes[2].visivel = false;
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    this.verificaAnatomiaNormal();
    if (isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-calculadora`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.calcFrase}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-calculadoraConclusao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.calcConclusao}</p>`,
      }
    });

    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.laudoCompleto.push({
    //   'type': 'paragraph',
    //     'data': {
    //       'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>',
    //     }
    //   });
    // }

    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '') {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.conclusoes.forEach((conclusao) => {
      if (conclusao.value !== '' && conclusao.visivel == true) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-observacao`
      },
      'data': {
        'text': `<p>${this.RetornaConclusaoLista()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }
    //Lógica Rules
    // if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
    //   this.htmlContent += '<br/><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';
    // }

    this.htmlContent += this.indicacaoTexto + ' </br>';
    
    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].blockID == 437) {
        this.descricoes[i].visivel = !this.exibeTabelaDoppler;
      }
    }

    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value) {
        if (this.descricoes[i].blockID == 437) {
          this.descricoes[i].visivel = !this.exibeTabelaDoppler;
          if (this.exibeTabelaDoppler) {
            this.htmlContent += '</br><b>ESTUDO DOPPLERVELOCIMETRIA - ÍNDICE DE PULSATILIDADE:</b></br>';
            this.htmlContent += this.TabelaDoppler();
          }
        }
        if (this.descricoes[i].blockID == 0 && this.calcFrase) {
          this.htmlContent += '<br/>' + this.calcFrase + '<br/>';
        } else if (this.descricoes[i].value && this.descricoes[i].visivel){
          this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
        }
      }
    }

    this.htmlContent += maskSplit[2];
    for (i = 0; i < this.conclusoes.length; i++) {
      if (this.conclusoes[i].blockID == 0 && this.calcConclusao && this.conclusoes[1].value !== '') {
        this.htmlContent += '<br/>' + this.calcConclusao + '<br/>';
      } else {
        if (this.conclusoes[i].value && this.conclusoes[i].visivel == true) {
          this.htmlContent += '<br/>' + this.conclusoes[i].value + '<br/>';
        }
      }
    }

    this.htmlContent += this.RetornaConclusaoLista();

    this.htmlContent += this.achadosAdTexto;

    if (this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;

    const obs = maskSplit[4];
    if (obs) {
      this.htmlContent += maskSplit[4];
    }
  }

  atualizaLinha({
    blockId,
    descricao,
    conclusao,
    conclusaoNull,
    indicacao,
    achados,
    cabecalho,
    rodape,
    calculadora,
    tabelaDoppler
  }: IAtualizaLinha): void {
    if (blockId !== undefined) {
      if (indicacao === undefined && achados === undefined) {
        if (descricao === undefined) {
          this.descricoes.forEach(desc => {
            if (desc.blockID === blockId) {
              descricao = desc.value;
            }
          });
        }

        const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

        if (conclusao !== '' || conclusaoNull !== '') {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        } else {
          if (conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                  ? conclusaoNull !== ''
                    ? conclusaoNull
                    : ''
                  : conclusao;
              }
            });
          }
        }

        if (description) {
          description.innerHTML = descricao;
        } else if (!description) {
          this.AdicionaTexto(true);
        } else if (!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if (cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if (header) {
            header.innerHTML = cabecalho;
          }
        }

        if (rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if (footer) {
            footer.innerHTML = rodape;
          }
        }

        if (tabelaDoppler !== undefined) {
          const footer = document.querySelector(`#e-${blockId} p`);

          if (footer) {
            footer.innerHTML = `<b>Dopplervelocimetria:</b></br> ${tabelaDoppler}`;
          }
        }

        if (calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if (calculo) {
            calculo.innerHTML = calculadora;
          }
        }

      } else if (indicacao !== undefined || achados !== undefined) {
        if (indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if (recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
        } else if (achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if (findings) {
            findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
        }
      }
      this._addText.lineHeight();
    }
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';

    this.genericDopplervelocimetria.forEach(obj => {
      if (obj.termID === 5585) {
        var objValue = parseFloat(obj.value.replace(',', '.'));
        if (objValue >= 1.0) {
          texto = 'Doppler do Ducto Venoso acima do percentil 95';
        } else {
          texto = 'Gestação única, tópica, de concepto vivo.';
        }
      }
    });

    return texto;
  }

  RetornaConclusaoLista() {
    let texto = `<br/>
            <div style='margin-left: 20px;'><p><small>
              1. A paciente foi informada que nem todas as anormalidades que um bebê pode vir a apresentar após o nascimento podem ser visualizadas ao ultra-som.<br/>
              2. Alguns fatores como a estática fetal, a espessura do tecido subcutâneo, a idade gestacional e o aparelho utilizado podem limitar a acurácia do exame;<br/>
              3. Na avaliação morfológica realiza-se o rastreio para síndromes cromossômicas, porém o diagnóstico de certeza somente é dado através de exame invasivo (biópsia de vilo coriônico ou amniocentese para a análise do cariótipo fetal);<br/>
            </small></p></div>`;
    return texto;
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  public resetaDialog() {
    this._common.invokeEvent.subscribe(event => {
      if (event) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetaLaudo();
            this._controlImprimir.desabilitarImprimir();
          }
        });
      }
    });
  };

  public resetaLaudo() {
    this._pouchService.getAll("usgMorfologicaSegundoDopplerAnalise").then((obj) => {
      console.log(obj)
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].doc.value != "" || obj[i].doc.value != undefined) {
          if (obj[i].doc.type == "descricao") {
            this.descricoes[i].value = obj[i].doc.value;
          } else if (obj[i].doc.type == "conclusao") {
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.showForm = false;
    this.medicaoNodulo = "";
    this.indicacaoTexto = "";
    this.achadosAdTexto = "";
    this.calcFrase = "";
    this.cabecalhoTexto = "";
    this.rodapeTexto = "";
    this.calcConclusao = "";
    this.exibeTabelaDoppler = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };
  handleSwitchFormReport(formReport: string) {
    if (formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  ativarImpressaoCrescimentoFetal($event) {
    if ($event.ativo) {
      this.impressaoAtivaCrescimentoFetal = true;
      this.blocos[4].blocked = false;
    } else {
      this.impressaoAtivaCrescimentoFetal = false;
      this.blocos[4].blocked = true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoPlacenta($event) {
    if ($event.ativo) {
      this.impressaoAtivaPlacenta = true;
      this.blocos[15].blocked = false;
    } else {
      this.impressaoAtivaPlacenta = false;
      this.blocos[15].blocked = true;
    }
    this.ativarImpressao();
  }
  ativarImpressaoLiquidoAmniotico(event) {
    if (event.ativo) {
      this.impressaoAtivaLiquidoAmniotico = true;
      this.blocos[14].blocked = false;
    } else {
      this.impressaoAtivaLiquidoAmniotico = false;
      this.blocos[14].blocked = true;
    }
    this.ativarImpressao();
  }

  ativarImpressao() {
    if (this.impressaoAtivaCrescimentoFetal && this.impressaoAtivaPlacenta && this.impressaoAtivaLiquidoAmniotico)
      this._controlImprimir.habilitarImprimir();
    else
      this._controlImprimir.desabilitarImprimir();
  }
}
