import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule,
         MatFormFieldModule,
         MatToolbarModule,
         MatButtonModule,
         MatSidenavModule,
         MatIconModule,
         MatInputModule,
         MatListModule,
         MatTableModule,
         MatPaginatorModule,
         MatSortModule,
         MatGridListModule,
         MatCardModule,
         MatMenuModule,
         MatTabsModule,
         MatDatepickerModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatNativeDateModule,
         MatRadioModule,
         MatCheckboxModule,
         MatSelectModule,
         MatTooltipModule, 
         MatSlideToggleModule} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from 'src/app/utils/textEditor/text-editor/text-editor.module';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { AortaIliacaComponent } from './aorta-iliaca.component';
import { AoiliAortaComponent } from './TermosComponents/aoili-aorta/aoili-aorta.component';
import { AoiliArteriaDireitaComponent } from './TermosComponents/aoili-arteria-direita/aoili-arteria-direita.component';
import { AoiliArteriaEsquerdaComponent } from './TermosComponents/aoili-arteria-esquerda/aoili-arteria-esquerda.component';
import { AoiliArteriaExternaDireitaComponent } from './TermosComponents/aoili-arteria-externa-direita/aoili-arteria-externa-direita.component';
import { AoiliArteriaExternaEsquerdaComponent } from './TermosComponents/aoili-arteria-externa-esquerda/aoili-arteria-externa-esquerda.component';
import { AoiliArteriaInternaDireitaComponent } from './TermosComponents/aoili-arteria-interna-direita/aoili-arteria-interna-direita.component';
import { AoiliArteriaInternaEsquerdaComponent } from './TermosComponents/aoili-arteria-interna-esquerda/aoili-arteria-interna-esquerda.component';
import { AoiliAteromatoseComponent } from './TermosComponents/aoili-ateromatose/aoili-ateromatose.component';
import { AoiliEndoproteseComponent } from './TermosComponents/aoili-endoprotese/aoili-endoprotese.component';
import { AoiliTabelaComponent } from './TermosComponents/aoili-tabela/aoili-tabela.component';
import { LaudoFooterModule } from '../../../../utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    DragDropModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    TextEditorModule,
    CustomDirectiveModule,
    LaudoFooterModule,
    SharedModule,
    TextEditorJSmodule,
    MatSlideToggleModule,
  ],
  declarations: [
    AortaIliacaComponent, 
    AoiliAortaComponent,
    AoiliArteriaDireitaComponent, 
    AoiliArteriaEsquerdaComponent, 
    AoiliArteriaExternaDireitaComponent, 
    AoiliArteriaExternaEsquerdaComponent, 
    AoiliArteriaInternaDireitaComponent, 
    AoiliArteriaInternaEsquerdaComponent, 
    AoiliAteromatoseComponent, 
    AoiliEndoproteseComponent, 
    AoiliTabelaComponent]
})
export class AortaIliacaModule { }
