import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit{
  icon : string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

    ngOnInit() {
      const msgSplit = this.message.split('|');
  
      if(msgSplit.length > 1){
        this.icon = msgSplit[0];
        this.message = msgSplit[1];
      } else {
        this.message = msgSplit[0];
      }
  
    }

  ClickNao(): void {
    this.dialogRef.close();
  }
}
