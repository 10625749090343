import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-extremidades',
  templateUrl: './mst-extremidades.component.html',
  styleUrls: ['./mst-extremidades.component.css']
})
export class MstExtremidadesComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaAlterado = new EventEmitter();

  @ViewChild('slExtremidades') slExtremidades: MatSelect;

  @ViewChild('inAlteradaExtre') inAlteradaExtre: ElementRef;

  showAndHideTerms: any;

  extremidades: ArrayDropDown[] = [
    { value: 'proporcionais e simétricos', viewValue: 'Segmentos proporcionais e simétricos' },
    { value: 'Alteradas: ', viewValue: 'Alteradas' }
  ];


  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlteradoExtre: false,
    }
  }

  changeSelect() {
    this.verificaAlterado();
    this.catchIDs();
  }

  blur() {
    this.catchIDs();
  }

  catchIDs() {
    debugger;
    this.termoSelecionado.emit('Clean');
    let elemento;
    if (this.slExtremidades && this.slExtremidades.value) {
      if (this.inAlteradaExtre && this.slExtremidades.value == 'Alteradas: ')
        elemento = { id: this.slExtremidades.id, value: this.inAlteradaExtre.nativeElement.value };
      else
        elemento = { id: this.slExtremidades.id, value: this.slExtremidades.value };

      const objeto = { elemento: elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }


    this.listaCompleta.emit();

  }

  verificaAlterado() {
    if (this.slExtremidades.value == 'Alteradas: ') {
      this.showAndHideTerms.sahAlteradoExtre = true;
    } else {
      this.showAndHideTerms.sahAlteradoExtre = false;
    }
  }

}
