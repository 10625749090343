import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-rodape-exame',
  templateUrl: './rodape-exame.component.html',
  styleUrls: ['./rodape-exame.component.css']
})
export class RodapeExameComponent implements OnInit {

  @Output() enviaRodape = new EventEmitter();

  @ViewChild('nomeMedico') nomeMedico: ElementRef;
  @ViewChild('crm') crm: ElementRef;
  @ViewChild('ckcopiarHtml') ckcopiarHtml: MatCheckbox;

  nomeUser;
  crmBusca;

  nomeObj = '';
  crmObj = '';
  showCampos = false;

  constructor(
  ) { }

  ngOnInit() {

  }

  adicionaRemoveCampos() {
    this.nomeUser = localStorage.getItem('nomeUsuario');
    this.crmBusca = localStorage.getItem('crm');

    if (this.ckcopiarHtml.checked === false) {
      this.showCampos = true;
      if (this.nomeUser) 
        this.nomeObj = `<b>${this.nomeUser}<b>`;
      if (this.crmBusca)
        this.crmObj = `<b> - ${this.crmBusca}<b>`;

      this.adicionaTexto();
    } else {
      this.showCampos = false;
      this.nomeObj = '';
      this.crmObj = '';
      this.adicionaTexto();
    }
  }

  blurNome() {
    let nomeMedico = this.nomeMedico.nativeElement.value;

    if (this.nomeMedico){
      if (nomeMedico !== '') {
        this.nomeObj = `<b>${nomeMedico}</b>`;
        this.adicionaTexto();
      } else {
        this.nomeObj = '';
        this.adicionaTexto();
      }
    }
  }

  blurCrm() {
    let crm = this.crm.nativeElement.value;
    if (this.crm){
      if (crm !== '') {
        this.crmObj = `<b> - CRM: ${crm}</b>`;
        this.adicionaTexto();
      } else {
        this.crmObj = '';
        this.adicionaTexto();
      }
    }
  }

  adicionaTexto(){
    const obj = {
      dataAtual: this.nomeObj,
      nome: this.crmObj,
    }

    let text = Object.entries(obj)
    let textCompleto;

    if(text) {
      text.forEach(desc => {
        if (desc[1] !== undefined || desc[1] !== null || desc[1] !== '') {
          if(textCompleto === undefined && desc[1] !== '') {
            textCompleto = desc[1];
          } else if (textCompleto !== undefined && desc[1] !== '') {
            textCompleto += desc[1];
          }
        }
      });
    }

    if (textCompleto !== undefined && textCompleto !== '') 
      this.enviaRodape.emit(`<br/><br/><br/><br/><br/><br/><div style="text-align: right; display: flex;"><div style="width: 95%;">___________________________</br>${textCompleto}</div></div><br/>`);
    else 
      this.enviaRodape.emit('');
  }
}
