import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-desbloq-laudo-dialog',
  templateUrl: './desbloq-laudo-dialog.component.html',
  styleUrls: ['./desbloq-laudo-dialog.component.css']
})
export class DesbloqLaudoDialogComponent implements OnInit {
  icon: string = '';

  @ViewChild('inMotivo') inMedida1: ElementRef;


  constructor(
    public dialogRef: MatDialogRef<DesbloqLaudoDialogComponent>,
    private _toastr: ToastrManager) { }

  ngOnInit() {
  }

  ClickNao(): void {
    this.dialogRef.close();
  }

  ClickSim() {
    if (this.inMedida1.nativeElement.value)
      this.dialogRef.close({ motivo: this.inMedida1.nativeElement.value })
    else
      this._toastr.errorToastr('Preencha o motivo');
  }
}
