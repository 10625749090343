import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-tendoes-compartimento-medial',
  templateUrl: './tendoes-compartimento-medial.component.html',
  styleUrls: ['./tendoes-compartimento-medial.component.css']
})
export class TendoesCompartimentoMedialComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdTendinopatia') rdTendinopatia: MatRadioButton;
  @ViewChild('rdTenossinovite') rdTenossinovite: MatRadioButton;
  @ViewChild('rdTendiTenossi') rdTendiTenossi: MatRadioButton;
  @ViewChild('slTendao') slTendao: MatSelect;
  @ViewChild('slSegmento') slSegmento: MatSelect;

  tendoes: ArrayDropDown[] = [
    { value: 'tibial posterior', viewValue: 'Tibial posterior' },
    { value: 'flexor longo dos dedos', viewValue: 'Flexor longo dos dedos' },
    { value: 'flexor longo do hálux', viewValue: 'Flexor longo do hálux' },
  ];

  segmentos: ArrayDropDown[] = [
    { value: 'retromaleolar', viewValue: 'Retromaleolar' },
    { value: 'inframaleolar', viewValue: 'Inframaleolar' },
    { value: 'retromaleolar e inframaleolar', viewValue: 'Retromaleolar e inframaleolar' },
    { value: 'insercional', viewValue: 'Insercional' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCompartimento: false
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahCompartimento = false;
    this.catchIDs();
  }

  clickDefault() {
    this.showAndHideTerms.sahCompartimento = true;
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdTendinopatia.checked) {
      this.termoSelecionado.emit(this.rdTendinopatia.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmento && this.slSegmento.value) {
        const objeto = { elemento: this.slSegmento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTenossinovite.checked) {
      this.termoSelecionado.emit(this.rdTenossinovite.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmento && this.slSegmento.value) {
        const objeto = { elemento: this.slSegmento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.rdTendiTenossi.checked) {
      this.termoSelecionado.emit(this.rdTendiTenossi.value);
      
      if (this.slTendao && this.slTendao.value) {
        const objeto = { elemento: this.slTendao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegmento && this.slSegmento.value) {
        const objeto = { elemento: this.slSegmento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
