import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextEditorModule } from '../../../../utils/textEditor/text-editor/text-editor.module';
import { CervicalModule } from '../cervical/cervical.module';
import { TransfontanelarComponent } from './transfontanelar.component';
import { SistemaVentricularComponent } from './TermosComponents/sistema-ventricular/sistema-ventricular.component';
import { ParenquimaCerebralComponent } from './TermosComponents/parenquima-cerebral/parenquima-cerebral.component';
import { CustomDirectiveModule } from 'src/app/directives/custom-directive.module';
import { LaudoFooterModule } from 'src/app/utils/laudo-footer/laudo-footer.module';
import { SharedModule } from 'src/app/laudos/shared/shared.module';
import { TextEditorJSmodule } from 'src/app/utils/text-editor-js/text-editor-js.module';

@NgModule({
    imports: [
        MatExpansionModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatCardModule,
        DragDropModule,
        MatMenuModule,
        MatTabsModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        FormsModule,
        AngularEditorModule,
        HttpClientModule,
        LayoutModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        TextEditorModule,
        CommonModule,
        CervicalModule,
        CustomDirectiveModule,
        LaudoFooterModule,
        SharedModule,
        TextEditorJSmodule,
        MatSlideToggleModule
    ],
    exports: [
        TransfontanelarComponent
    ],
    declarations: [
        TransfontanelarComponent,
        SistemaVentricularComponent,
        ParenquimaCerebralComponent
    ]
})
export class TransfontanelarModule { }
