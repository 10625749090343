import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { MedicalReportConfig } from "../models/BO/MedicalReportConfig";
import { ArrayDropDown } from "../models/Helper/ArrayDropDown";
import { ConfigLaudoService } from "../services/config-laudo.service";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-config-laudo",
  templateUrl: "./config-laudo.component.html",
  styleUrls: ["./config-laudo.component.css", "../app.component.css"],
})
export class ConfigLaudoComponent implements OnInit {
  formConfig: FormGroup;
  $usuario: any;
  $config: any;
  gravar: string = "Salvar";
  private formSubmitAttempt: boolean;
  public loading: boolean = true;

  Languages: ArrayDropDown[] = [
    { value: 'br', viewValue: 'br' },
    { value: 'pt', viewValue: 'pt' },
  ];
  selectedLanguage: string;
  selectedLanguageIcon: string;

  constructor(
    private _configLaudoService: ConfigLaudoService,
    private _userService: UserService,
    private toastr: ToastrManager,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('language')) {
      this.selectedLanguage = localStorage.getItem('language');
    } else {
      this.selectedLanguage = this.translate.currentLang;
    }

    this.Languages.forEach((lang) => {
      if (lang.viewValue == this.selectedLanguage) {
        this.selectedLanguageIcon = lang.value;
      }
    });

    localStorage.setItem('reloadlaudos', 'false');
  }
  ngAfterViewInit() {
    this.$usuario = new Object();
    this.carregaUsuario();
  }

  carregaUsuario() {
    const userID = +localStorage.getItem("userID");
    if (userID) {
      this._userService.buscarUsuario(userID).subscribe((usuario) => {
        this.carregaConfig(usuario);
        this.$usuario = usuario;
        this.loading = false;
      });
    } else {
      this.toastr.errorToastr("Erro ao carregar configurações", "Erro", {
        position: "top-right",
        toastTimeout: 4000,
      });
      this.loading = false;
    }
  }

  carregaConfig(usuario: any) {
    if (!usuario) return;

    this._configLaudoService
      .buscaConfig(usuario.id)
      .toPromise()
      .then((config) => {
        if (config && config.length > 0) {
          console.log(config);
          this.$config = config;
        } else {
          this._configLaudoService
            .carregarConfig()
            .toPromise()
            .then((rules: MedicalReportConfig[]) => {
              if (rules && rules.length > 0) {
                this.$config = rules;
              }
            });
        }
      });
  }

  onSubmit() {
    this.$config.forEach((itens) => {
      localStorage.setItem("cmRule", JSON.stringify(this.$config[1]));
      if (itens.userId === undefined || itens.userId === 0) {
        itens.userId = this.$usuario.id;
      }
    });

    this._configLaudoService.salvaConfig(this.$config).subscribe(
      (res) => {
        localStorage.setItem('ruleUpdated', 'false');
        this.toastr.successToastr(
          "Configurações salvas com sucesso",
          "Sucesso!",
          {
            position: "top-right",
            toastTimeout: 4000,
          }
        );
      },
      (err) => {
        this.toastr.errorToastr("Erro ao atualizar dados", "Desculpe!", {
          position: "top-right",
          toastTimeout: 4000,
        });
      }
    );
    this.formSubmitAttempt = true;
  }

  changeLanguage() {
    this.translate.use(this.selectedLanguage);
    localStorage.setItem('language', this.selectedLanguage);
    localStorage.setItem('reloadlaudos', 'true');
    localStorage.setItem('bancosCarregados', '[]');
    this.Languages.forEach(lang => {
      if (lang.viewValue == this.selectedLanguage) {
        this.selectedLanguageIcon = lang.value;
      }
    });
    if (this.selectedLanguage == 'pt') {
      this.toastr.successToastr('Alguns laudos ainda não possuem tradução para este idioma', 'Idioma alterado para Português de Portugal', { position: 'top-right', toastTimeout: 10000, maxShown: 1 });
    }
  }
}
