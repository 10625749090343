import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Validacoes } from '../../../../../utils/validacoes';

@Component({
  selector: 'app-parenquima-pulmonar-rsna',
  templateUrl: './parenquima-pulmonar-rsna.component.html',
  styleUrls: ['./parenquima-pulmonar-rsna.component.css']
})
export class ParenquimaPulmonarRsnaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterado') rdAlterado: MatRadioButton;

  //REST PARENQUIMA
  @ViewChild('ckRestParenquima') ckRestParenquima: MatCheckbox;

  //COVID
  @ViewChild('ckCovid') ckCovid: MatCheckbox;
  // @ViewChild('ckNegativoPneu') ckNegativoPneu: MatCheckbox;

  // Aparencia tipica
  @ViewChild('rdVidroFoscPeri') rdVidroFoscPeri: MatRadioButton;
  @ViewChild('slLoboVidroFoscPeri') slLoboVidroFoscPeri: MatSelect;
  @ViewChild('slConsolidacaoVidroFoscPeri') slConsolidacaoVidroFoscPeri: MatSelect;
  @ViewChild('ckPaviEspPeri') ckPaviEspPeri: MatCheckbox;
  @ViewChild('ckPaviLocPeri') ckPaviLocPeri: MatCheckbox;
  @ViewChild('slLoboPaviLocPeri') slLoboPaviLocPeri: MatSelect;
  @ViewChild('ckConsolidacoesEsp') ckConsolidacoesEsp: MatCheckbox;
  @ViewChild('slLoboConsolidacoesEsp') slLoboConsolidacoesEsp: MatSelect;
  @ViewChild('ckConsEspPeri') ckConsEspPeri: MatCheckbox;
  @ViewChild('ckConsLocPeri') ckConsLocPeri: MatCheckbox;
  @ViewChild('slLoboConsLocPeri') slLoboConsLocPeri: MatSelect;
  @ViewChild('ckHaloPeri') ckHaloPeri: MatCheckbox;
  @ViewChild('slLoboHaloPeri') slLoboHaloPeri: MatSelect;

  @ViewChild('rdVidroFoscMult') rdVidroFoscMult: MatRadioButton;
  @ViewChild('slLoboVidroFoscMult') slLoboVidroFoscMult: MatSelect;
  @ViewChild('slConsolidacaoVidroFoscMult') slConsolidacaoVidroFoscMult: MatSelect;
  @ViewChild('ckPaviEspMult') ckPaviEspMult: MatCheckbox;
  @ViewChild('ckPaviLocMult') ckPaviLocMult: MatCheckbox;
  @ViewChild('slLoboPaviLocMult') slLoboPaviLocMult: MatSelect;
  @ViewChild('ckConsolidacoesEspMult') ckConsolidacoesEspMult: MatCheckbox;
  @ViewChild('slLoboConsolidacoesEspMult') slLoboConsolidacoesEspMult: MatSelect;
  @ViewChild('ckConsEspMult') ckConsEspMult: MatCheckbox;
  @ViewChild('ckConsLocMult') ckConsLocMult: MatCheckbox;
  @ViewChild('slLoboConsLocMult') slLoboConsLocMult: MatSelect;
  @ViewChild('ckHaloMult') ckHaloMult: MatCheckbox;
  @ViewChild('slLoboHaloMult') slLoboHaloMult: MatSelect;

  @ViewChild('rdHaloInvertido') rdHaloInvertido: MatRadioButton;
  @ViewChild('slLocalizacaoHaloInvert') slLocalizacaoHaloInvert: MatSelect;

  // Aparencia indeterminada
  @ViewChild('slLoboVidroNao') slLoboVidroNao: MatSelect;
  @ViewChild('slSegVidroNao') slSegVidroNao: MatSelect;
  @ViewChild('slDistslVidroNao') slDistslVidroNao: MatSelect;
  @ViewChild('ckConsolidacaoEsp') ckConsolidacaoEsp: MatCheckbox;
  @ViewChild('ckConsolidacaoLoc') ckConsolidacaoLoc: MatCheckbox;
  @ViewChild('slLocVidroNao') slLocVidroNao: MatSelect;

  // Aparencia indeterminada

  @ViewChild('ckConsolidacao') ckConsolidacao: MatCheckbox;
  @ViewChild('rdConsLoc') rdConsLoc: MatRadioButton;
  @ViewChild('slLoboConsLoc') slLoboConsLoc: MatSelect;
  @ViewChild('slSegConsLoc') slSegConsLoc: MatSelect;
  @ViewChild('rdConsAte') rdConsAte: MatRadioButton;
  @ViewChild('slLoboConsAte') slLoboConsAte: MatSelect;
  @ViewChild('slSegConsAte') slSegConsAte: MatSelect;

  @ViewChild('ckMicronodulosCovid') ckMicronodulosCovid: MatCheckbox;
  @ViewChild('rdMicroCentroCovid') rdMicroCentroCovid: MatRadioButton;
  @ViewChild('rdMicroCentroEspCovid') rdMicroCentroEspCovid: MatRadioButton;
  @ViewChild('rdMicroCentroDifCovid') rdMicroCentroDifCovid: MatRadioButton;
  @ViewChild('slLoboPreMicro') slLoboPreMicro: MatSelect;
  @ViewChild('slLoboMicroCovid') slLoboMicroCovid: MatSelect;
  @ViewChild('slSegMicroCovid') slSegMicroCovid: MatSelect;

  @ViewChild('ckEspessamentDerr') ckEspessamentDerr: MatCheckbox;
  @ViewChild('slLadoEspDerr') slLadoEspDerr: MatSelect;
  @ViewChild('slGrauEspDerr') slGrauEspDerr: MatSelect;

  // Extensao
  @ViewChild('ckExtensaoAco') ckExtensaoAco: MatCheckbox;
  @ViewChild('slExtensaoCovid') slExtensaoCovid: MatSelect;

  //Atelectasia
  @ViewChild('ckAtelectasia') ckAtelectasia: MatCheckbox;

  @ViewChild('ckAteLoc') ckAteLoc: MatRadioButton;
  @ViewChild('slLoboAteLoc') slLoboAteLoc: MatSelect;
  @ViewChild('slSegAteLoc') slSegAteLoc: MatSelect;
  @ViewChild('ckAteSeg') ckAteSeg: MatRadioButton;
  @ViewChild('slLoboAteSeg') slLoboAteSeg: MatSelect;
  @ViewChild('slTipoAte') slTipoAte: MatSelect;
  @ViewChild('ckAteLam') ckAteLam: MatRadioButton;
  @ViewChild('slLoboAteLam') slLoboAteLam: MatSelect;
  @ViewChild('ckAteEsp') ckAteEsp: MatRadioButton;
  @ViewChild('ckAteEst') ckAteEst: MatRadioButton;
  @ViewChild('slLoboAteEst') slLoboAteEst: MatSelect;

  //Infarto
  @ViewChild('ckInfarto') ckInfarto: MatCheckbox;
  @ViewChild('slLoboInf') slLoboInf: MatSelect;
  @ViewChild('slSegInf') slSegInf: MatSelect;

  //Enfisema
  @ViewChild('ckEnfisema') ckEnfisema: MatCheckbox;
  @ViewChild('rdEnfLoc') rdEnfLoc: MatRadioButton;
  @ViewChild('rdEnfiPara') rdEnfiPara: MatRadioButton;
  @ViewChild('rdEnfCentPara') rdEnfCentPara: MatRadioButton;
  @ViewChild('rdEnfPan') rdEnfPan: MatRadioButton;
  @ViewChild('rdRarosEnf') rdRarosEnf: MatRadioButton;
  @ViewChild('slLoboEnf') slLoboEnf: MatSelect;
  @ViewChild('slTipoEnf') slTipoEnf: MatSelect;

  //Intersticiopatia
  @ViewChild('ckIntersticiopatia') ckIntersticiopatia: MatCheckbox;
  @ViewChild('rdIntGeral') rdIntGeral: MatRadioButton;
  @ViewChild('rdPine') rdPine: MatRadioButton;
  @ViewChild('rdPiu') rdPiu: MatRadioButton;
  @ViewChild('rdFibrosePul') rdFibrosePul: MatRadioButton;
  @ViewChild('rdPneumoniaOrg') rdPneumoniaOrg: MatRadioButton;
  @ViewChild('rdPneumoniteHip') rdPneumoniteHip: MatRadioButton;
  @ViewChild('slLoboInt') slLoboInt: MatSelect;
  @ViewChild('ckAreaInt') ckAreaInt: MatCheckbox;

  //Nodulo
  @ViewChild('ckNodulo') ckNodulo: MatCheckbox;
  @ViewChild('rdNodSup') rdNodSup: MatRadioButton;

  @ViewChild('rd1NodSup') rd1NodSup: MatRadioButton;
  @ViewChild('slComposicao1Nod') slComposicao1Nod: MatSelect;
  @ViewChild('slContorno1Nod') slContorno1Nod: MatSelect;
  @ViewChild('slLobo1NodSup') slLobo1NodSup: MatSelect;
  @ViewChild('slSeg1NodSup') slSeg1NodSup: MatSelect;
  @ViewChild('inMedida1NodSup') inMedida1NodSup: ElementRef;
  @ViewChild('inMedida2NodSup') inMedida2NodSup: ElementRef;
  @ViewChild('inMedida3NodSup') inMedida3NodSup: ElementRef;

  @ViewChild('rd2NodSup') rd2NodSup: MatRadioButton;
  @ViewChild('slLobo2NodSup') slLobo2NodSup: MatSelect;
  @ViewChildren('NodSuspChildren') NodSuspChildren: QueryList<any>;

  @ViewChild('rdNodMult') rdNodMult: MatRadioButton;
  @ViewChild('slComposicaoNodMult') slComposicaoNodMult: MatSelect;
  @ViewChild('slContornosNodMult') slContornosNodMult: MatSelect;
  @ViewChild('slLoboNodMult') slLoboNodMult: MatSelect;
  @ViewChildren('NodMultChildren') NodMultChildren: QueryList<any>;

  @ViewChild('rdNodMet') rdNodMet: MatRadioButton;
  @ViewChild('slMetContorno') slMetContorno: MatSelect;
  @ViewChild('slLoboMetastase') slLoboMetastase: MatSelect;
  @ViewChildren('metastaseChildren') metastaseChildren: QueryList<any>;

  @ViewChild('ckNodDiminutos') ckNodDiminutos: MatCheckbox;
  @ViewChild('rdDimInc') rdDimInc: MatRadioButton;
  @ViewChild('rdDimRarosInc') rdDimRarosInc: MatRadioButton;
  @ViewChild('rdDimRarosRes') rdDimRarosRes: MatRadioButton;
  @ViewChild('rdDimCalc') rdDimCalc: MatRadioButton;
  @ViewChild('inMedidaDim') inMedidaDim: ElementRef;
  @ViewChild('inMedidaDimRarosInc') inMedidaDimRarosInc: ElementRef;
  @ViewChild('inMedidaDimRarosRes') inMedidaDimRarosRes: ElementRef;
  @ViewChild('inMedidaDimRes') inMedidaDimRes: ElementRef;

  @ViewChild('ckDimNcNc') ckDimNcNc: MatCheckbox;
  @ViewChild('inMedidaDimNcNc') inMedidaDimNcNc: ElementRef;

  // Massa Pulmonar
  @ViewChild('ckMassaPul') ckMassaPul: MatCheckbox;
  @ViewChild('slComposicaoMassa') slComposicaoMassa: MatSelect;
  @ViewChild('slContornoMassa') slContornoMassa: MatSelect;
  @ViewChild('slLoboMassa') slLoboMassa: MatSelect;
  @ViewChild('slSegMassa') slSegMassa: MatSelect;
  @ViewChild('inMedida1Massa') inMedida1Massa: ElementRef;
  @ViewChild('inMedida2Massa') inMedida2Massa: ElementRef;
  @ViewChild('inMedida3Massa') inMedida3Massa: ElementRef;
  @ViewChild('inVolumeMassa') inVolumeMassa: ElementRef;

  // Micronodulos
  @ViewChild('ckMicronodulos') ckMicronodulos: MatCheckbox;
  @ViewChild('rdMicroCentro') rdMicroCentro: MatRadioButton;
  @ViewChild('rdMicroCentroEsp') rdMicroCentroEsp: MatRadioButton;
  @ViewChild('rdMicroCentroDif') rdMicroCentroDif: MatRadioButton;
  @ViewChild('rdMicroPeri') rdMicroPeri: MatRadioButton;
  @ViewChild('rdMicroPeriEsp') rdMicroPeriEsp: MatRadioButton;
  @ViewChild('rdMicroPeriDif') rdMicroPeriDif: MatRadioButton;
  @ViewChild('rdMicroRanDif') rdMicroRanDif: MatRadioButton;
  @ViewChild('slLoboPreMicroCovid') slLoboPreMicroCovid: MatSelect;
  @ViewChild('slLoboMicro') slLoboMicro: MatSelect;
  @ViewChild('slSegMicro') slSegMicro: MatSelect;

  //Congestão Pulmonar
  @ViewChild('ckCongestao') ckCongestao: MatCheckbox;

  //Bronquiectasias
  @ViewChild('ckBronquiectasias') ckBronquiectasias: MatCheckbox;
  @ViewChild('rdBronLoc') rdBronLoc: MatRadioButton;
  @ViewChild('rdBronDif') rdBronDif: MatRadioButton;
  @ViewChild('rdBronSeq') rdBronSeq: MatRadioButton;
  @ViewChild('slTipoBron') slTipoBron: MatSelect;
  @ViewChild('slLoboBron') slLoboBron: MatSelect;

  //Cistos Aéreos
  @ViewChild('ckCistoAereo') ckCistoAereo: MatCheckbox;
  @ViewChild('rdComSeg') rdComSeg: MatRadioButton;
  @ViewChild('rdEsparsos') rdEsparsos: MatRadioButton;
  @ViewChild('rdSubpleural') rdSubpleural: MatRadioButton;
  @ViewChild('slLoboCisto') slLoboCisto: MatSelect;
  @ViewChild('slSegCisto') slSegCisto: MatSelect;
  @ViewChild('inMedidaCisto') inMedidaCisto: ElementRef;
  @ViewChild('slLoboEspar') slLoboEspar: MatSelect;
  @ViewChild('inMedidaEspar') inMedidaEspar: ElementRef;
  @ViewChild('slLoboSub') slLoboSub: MatSelect;
  @ViewChild('inMedidaSub') inMedidaSub: ElementRef;

  lobosCovid: ArrayDropDown[] = [
    { value: 'em todos os lobos pulmonares', viewValue: 'Todos os lobos pulmonares' },
    { value: 'no lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'no lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'no lobo médio', viewValue: 'Lobo médio' },
    { value: 'no lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'no lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
    { value: 'na língula', viewValue: 'Língula' }
  ];

  consolidacoesCovid: ArrayDropDown[] = [
    { value: 'focos esparsos', viewValue: 'Focos esparsos' }
  ];

  distribuicoesCovid: ArrayDropDown[] = [
    { value: 'difusas', viewValue: 'Difusas' },
    { value: 'multifocais', viewValue: 'Multifocais' },
    { value: 'peri-hilares', viewValue: 'Peri-hilares' },
    { value: 'unilaterais', viewValue: 'Unilaterais' }
  ];

  consolidacoesEspCovid: ArrayDropDown[] = [
    { value: 'áreas confluentes de consolidação', viewValue: 'Áreas confluentes de consolidação' }
  ];

  ladosCovid: ArrayDropDown[] = [
    { value: 'à direita', viewValue: 'Direita' },
    { value: 'à esquerda', viewValue: 'Esquerda' },
    { value: 'bilateral', viewValue: 'bilateral' }
  ];

  grausCovid: ArrayDropDown[] = [
    { value: 'pequeno', viewValue: 'Pequeno' },
    { value: 'moderado', viewValue: 'Moderado' },
    { value: 'acentuado', viewValue: 'Acentuado' }
  ];

  extensoesCovid: ArrayDropDown[] = [
    { value: 'menor que 25%', viewValue: 'Menor que 25%' },
    { value: 'entre 25-50%', viewValue: 'Entre 25-50%' },
    { value: 'maior que 50%', viewValue: 'Maior que 50%' }
  ];

  lobos: ArrayDropDown[] = [
    { value: 'no lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'no lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'no lobo médio', viewValue: 'Lobo médio' },
    { value: 'no lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'no lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
    { value: 'na língula', viewValue: 'Língula' }
  ];

  loboSimples: ArrayDropDown[] = [
    { value: 'lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'lobo médio', viewValue: 'Lobo médio' },
    { value: 'lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
  ];

  lobosOpacidades: ArrayDropDown[] = [
    { value: 'em todos os lobos pulmonares', viewValue: 'Todos os lobos pulmonares' },
    { value: 'no lobo superior direito', viewValue: 'Lobo superior direito' },
    { value: 'no lobo inferior direito', viewValue: 'Lobo inferior direito' },
    { value: 'no lobo médio', viewValue: 'Lobo médio' },
    { value: 'no lobo superior esquerdo', viewValue: 'Lobo superior esquerdo' },
    { value: 'no lobo inferior esquerdo', viewValue: 'Lobo inferior esquerdo' },
    { value: 'na língula', viewValue: 'Língula' },
  ];

  loboSupDir: ArrayDropDown[] = [
    { value: 'segmento apical', viewValue: 'Segmento apical' },
    { value: 'segmento anterior', viewValue: 'Segmento anterior' },
    { value: 'segmento posterior', viewValue: 'Segmento posterior' }
  ];

  loboMedio: ArrayDropDown[] = [
    { value: 'segmento medial', viewValue: 'Segmento medial' },
    { value: 'segmento lateral', viewValue: 'Segmento lateral' }
  ];

  loboInfDir: ArrayDropDown[] = [
    { value: 'segmento superior', viewValue: 'Segmento superior' },
    { value: 'segmento basal anterior', viewValue: 'Segmento basal anterior' },
    { value: 'segmento basal posterior', viewValue: 'Segmento basal posterior' },
    { value: 'segmento basal lateral', viewValue: 'Segmento basal lateral' },
    { value: 'segmento basal medial', viewValue: 'Segmento basal medial' }
  ];

  loboSupEsq: ArrayDropDown[] = [
    { value: 'segmento apicoposterior', viewValue: 'Segmento apicoposterior' },
    { value: 'segmento anterior', viewValue: 'Segmento anterior' },
    { value: 'segmento lingular superior', viewValue: 'Segmento lingular superior' },
    { value: 'segmento lingular inferior', viewValue: 'Segmento lingular inferior' },
  ];

  loboInfEsq: ArrayDropDown[] = [
    { value: 'segmento superior', viewValue: 'Segmento superior' },
    { value: 'segmento basal anteromedial', viewValue: 'Segmento basal anteromedial' },
    { value: 'segmento basal posterior', viewValue: 'Segmento basal posterior' },
    { value: 'segmento basal lateral', viewValue: 'Segmento basal lateral' }
  ];

  distribuicoes: ArrayDropDown[] = [
    { value: 'difusas e multifocais', viewValue: 'Difusas e multifocais' },
    { value: 'esparsas e bilaterais', viewValue: 'Esparsas e bilaterais' },
    { value: 'esparsas', viewValue: 'Esparsas' },
  ];

  extensoes: ArrayDropDown[] = [
    { value: 'menos de 25%', viewValue: 'Menos de 25%' },
    { value: 'entre 25-50%', viewValue: 'Entre 25-50%' },
    { value: 'mais de 50%', viewValue: 'Mais de 50%' }
  ];

  achados: ArrayDropDown[] = [
    { value: 'espessamento de septos interlobulares', viewValue: 'Espessamento de septos interlobulares' },
    { value: 'áreas confluentes de consolidação', viewValue: 'Áreas confluentes de consolidação' },
    { value: 'ectasia e espessamento parietal brônquico', viewValue: 'Ectasia e espessamento parietal brônquico' },
    { value: 'bandas subpleurais', viewValue: 'Bandas subpleurais' }
  ];

  corads: ArrayDropDown[] = [
    { value: 'CO-RADS 1 (Não suspeito)', viewValue: '1 - Não suspeito' },
    { value: 'CO-RADS 2 (Baixa suspeita)', viewValue: '2 - Baixa suspeita' },
    { value: 'CO-RADS 3 (Indeterminado)', viewValue: '3 - Indeterminado' },
    { value: 'CO-RADS 4 (Alta suspeita)', viewValue: '4 - Alta suspeita' },
    { value: 'CO-RADS 5 (Suspeita muito alta)', viewValue: '5 - Suspeita muito alta' },
    { value: 'CO-RADS 6 (PCR +)', viewValue: '6 - PCR +' }
  ];

  distribuicoesVidro: ArrayDropDown[] = [
    { value: 'periférica', viewValue: 'Periférica' },
    { value: 'peribroncovascular', viewValue: 'Peribroncovascular' },
    { value: 'central', viewValue: 'Central' }
  ];

  padroes: ArrayDropDown[] = [
    { value: 'Áreas', viewValue: 'Áreas' },
    { value: 'Focos', viewValue: 'Focos' }
  ];

  tipos: ArrayDropDown[] = [
    { value: 'segmentar', viewValue: 'Segmentar' },
    { value: 'subsegmentar', viewValue: 'Subsegmentar' }
  ];

  tiposEnf: ArrayDropDown[] = [
    { value: 'centrolobular', viewValue: 'Centrolobular' },
    { value: 'parasseptal', viewValue: 'Parasseptal' },
    { value: 'centrolobular e parasseptal', viewValue: 'Centrolobular e parasseptal' },
  ];

  graus: ArrayDropDown[] = [
    { value: 'esparso', viewValue: 'Esparso' },
    { value: 'difuso', viewValue: 'Difuso' }
  ];

  composicoes: ArrayDropDown[] = [
    { value: 'sólido', viewValue: 'Sólido' },
    { value: 'parcialmente sólido', viewValue: 'Parcialmente sólido' },
    { value: 'em vidro fosco', viewValue: 'Vidro fosco' }
  ];

  composicoesPlu: ArrayDropDown[] = [
    { value: 'sólidos', viewValue: 'Sólidos' },
    { value: 'parcialmente sólidos', viewValue: 'Parcialmente sólidos' },
    { value: 'em vidro fosco', viewValue: 'Vidro fosco' }
  ];

  composicoesMassa: ArrayDropDown[] = [
    { value: 'sólida', viewValue: 'Sólida' },
    { value: 'parcialmente sólida', viewValue: 'Parcialmente sólida' },
    { value: 'em vidro fosco', viewValue: 'Vidro fosco' }
  ];

  contornos: ArrayDropDown[] = [
    { value: 'espiculado', viewValue: 'Espiculado' },
    { value: 'de contornos lobulados', viewValue: 'Contornos lobulados' },
    { value: 'de contornos regulares', viewValue: 'Contornos regulares' }
  ];

  contornosPlu: ArrayDropDown[] = [
    { value: 'espiculados', viewValue: 'Espiculados' },
    { value: 'de contornos lobulados', viewValue: 'Contornos lobulados' },
    { value: 'de contornos regulares', viewValue: 'Contornos regulares' }
  ];

  contornosMassa: ArrayDropDown[] = [
    { value: 'espiculada', viewValue: 'Espiculada' },
    { value: 'de contornos lobulados', viewValue: 'Contornos lobulados' },
    { value: 'de contornos regulares', viewValue: 'Contornos regulares' }
  ];

  tiposBron: ArrayDropDown[] = [
    { value: 'cilíndricas', viewValue: 'Cilíndricas' },
    { value: 'varicosas', viewValue: 'Varicosas' },
    { value: 'císticas', viewValue: 'Císticas' },
    { value: 'irregulares', viewValue: 'irregulares' }
  ];

  showAndHideTerms: any;
  localizaCadeia: string;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  obj: any;
  objetofilho: any;
  volumeMassa = 'Volume';
  public idCovidInd: String = '1875';
  unidadeMedida: string;

  constructor(private _validacoes: Validacoes, private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahAlterado: true,
      sahCovid: false,
      sahVidroFoscPeri: false,
      sahkVidroFoscMult: false,
      sahHaloInvertido: false,
      sahAparenciaIndeterminada: false,
      sahAparenciaAtipica: false,
      sahCosolidacao: false,
      sahMicronodulosCovid: false,
      sahMicroLoboPreCovid: false,
      sahMicroLoboSegCovid: false,
      sahEspessamentoDerr: false,
      sahExtensaoAco: false,
      sahAtelectasia: false,
      sahInfarto: false,
      sahEnfisema: false,
      sahEnfisemaGrau: false,
      sahEnfisemaTipo: false,
      sahIntersticiopatia: false,
      sahIntLoboPre: false,
      sahIntArea: false,
      sahNodulo: false,
      sahNodSup: false,
      sah1NodSup: false,
      sah2NodSup: false,
      sahNodMult: false,
      sahNodMet: false,
      sahNodDiminutos: false,
      sahNodDimInc: false,
      sahNodDimRarosInc: false,
      sahNodDimRarosRes: false,
      sahNodDimCalc: false,
      sahNodDimMedida: false,
      sahMassaPulmonar: false,
      sahMicronodulos: false,
      sahMicroLoboPre: false,
      sahMicroLoboSeg: false,
      sahBronquiectasia: false,
      sahBronqTipo: false,
      sahCistoAereo: false,
      sahComSeg: false,
      sahSemSeg: false,
      sahEsparsos: false,
      sahSubpleural: false,
      sahNodDimNcNc: false,
    };

    this.lesoesForm = this._fb.group({
      nodSuspeitos: this._fb.array([this.addSuspGrup()]),
      nodMultiplos: this._fb.array([this.addMultGrup()]),
      metastase: this._fb.array([this.addMetastaseGrup()]),
    });

      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  //Nod Susp
  addSuspGrup() {
    return this._fb.group({
      composicao: [],
      contorno: [],
      lobo: [],
      segmento: [],
      medida: [],
    });
  }

  addSusp() {
    this.suspArray.push(this.addSuspGrup());
    this.catchIDs();
  }

  removeSusp(index) {
    this.suspArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get suspArray() {
    return <FormArray>this.lesoesForm.get('nodSuspeitos');
  }

  //Nod Multiplos
  addMultGrup() {
    return this._fb.group({
      lobo: [],
      segmento: [],
      medida: [],
    });
  }

  addMult() {
    this.multArray.push(this.addMultGrup());
    this.catchIDs();
  }

  removeMult(index) {
    this.multArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get multArray() {
    return <FormArray>this.lesoesForm.get('nodMultiplos');
  }

  //Metastase
  addMetastaseGrup() {
    return this._fb.group({
      lobo: [],
      segmento: [],
      medida: [],
    });
  }

  addMetastase() {
    this.metastaseArray.push(this.addMetastaseGrup());
    this.catchIDs();
  }

  removeMetastase(index) {
    this.metastaseArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }

  get metastaseArray() {
    return <FormArray>this.lesoesForm.get('metastase');
  }

  clickNormal() {
    this.showAndHideTerms.sahAlterado = false;
    this.catchIDs();
  }

  clickAlterado() {
    this.showAndHideTerms.sahAlterado = true;
    this.showAndHideTerms.sahCovid = false;
    this.showAndHideTerms.sahAtelectasia = false;
    this.showAndHideTerms.sahEnfisema = false;
    this.showAndHideTerms.sahInfarto = false;
    this.showAndHideTerms.sahIntersticiopatia = false;
    this.showAndHideTerms.sahNodulo = false;
    this.showAndHideTerms.sahNodDiminutos = false;
    this.showAndHideTerms.sahMassaPulmonar = false;
    this.showAndHideTerms.sahMicronodulos = false;
    this.showAndHideTerms.sahBronquiectasia = false;
    this.showAndHideTerms.sahCistoAereo = false;
    this.catchIDs();
  }

  clickCovid() {
    if (this.showAndHideTerms.sahCovid === false) {
      this.showAndHideTerms.sahCovid = true;
      this.showAndHideTerms.sahAparenciaIndeterminada = false;
      this.showAndHideTerms.sahAparenciaAtipica = false;
      this.showAndHideTerms.sahExtensaoAco = false;
      this.showAndHideTerms.sahVidroFoscPeri = false;
      this.showAndHideTerms.sahkVidroFoscMult = false;
      this.showAndHideTerms.sahHaloInvertido = false;
      this.showAndHideTerms.sahCosolidacao = false;
      this.showAndHideTerms.sahMicronodulosCovid = false;
      this.showAndHideTerms.sahExtensaoAco = false;
      this.showAndHideTerms.sahEspessamentoDerr = false;
    } else {
      this.showAndHideTerms.sahCovid = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickVidroFoscPeri() {
    if (this.showAndHideTerms.sahVidroFoscPeri === false)
      this.showAndHideTerms.sahVidroFoscPeri = true;
    else
      this.showAndHideTerms.sahVidroFoscPeri = false;
    this.catchIDs();
  }

  clickVidroFoscMult() {
    if (this.showAndHideTerms.sahkVidroFoscMult === false)
      this.showAndHideTerms.sahkVidroFoscMult = true;
    else
      this.showAndHideTerms.sahkVidroFoscMult = false;
    this.catchIDs();
  }

  clickHaloInvertido() {
    if (this.showAndHideTerms.sahHaloInvertido === false)
      this.showAndHideTerms.sahHaloInvertido = true;
    else
      this.showAndHideTerms.sahHaloInvertido = false;
    this.catchIDs();
  }

  clickAparenciaInd() {
    if (this.showAndHideTerms.sahAparenciaIndeterminada === false) {
      this.showAndHideTerms.sahAparenciaIndeterminada = true;
    } else {
      this.showAndHideTerms.sahAparenciaIndeterminada = false;
    }

    // this.ckNegativoPneu.checked = false;
    this.catchIDs();
  }

  clickAparenciaAtip() {
    if (this.showAndHideTerms.sahAparenciaAtipica === false) {
      this.showAndHideTerms.sahAparenciaAtipica = true;
      this.showAndHideTerms.sahCosolidacao = false;
      this.showAndHideTerms.sahMicronodulosCovid = false;
      this.showAndHideTerms.sahEspessamentoDerr = false;
    } else {
      this.showAndHideTerms.sahAparenciaAtipica = false;
    }

    // this.ckNegativoPneu.checked = false;
    this.catchIDs();
  }

  clickConsolidacao() {
    if (this.showAndHideTerms.sahCosolidacao === false)
      this.showAndHideTerms.sahCosolidacao = true;
    else
      this.showAndHideTerms.sahCosolidacao = false;
    this.catchIDs();
  }

  clickMicronodulosCovid() {
    if (this.showAndHideTerms.sahMicronodulosCovid === false) {
      this.showAndHideTerms.sahMicronodulosCovid = true;
      this.showAndHideTerms.sahMicroLoboSegCovid = true;
      this.showAndHideTerms.sahMicroLoboPreCovid = false;

    } else {
      this.showAndHideTerms.sahMicronodulosCovid = false;
    }
    this.catchIDs();
  }

  clickLoboSegCovid() {
    this.showAndHideTerms.sahMicroLoboSegCovid = true;
    this.showAndHideTerms.sahMicroLoboPreCovid = false;
    this.catchIDs();
  }

  clickLoboPreCovid() {
    this.showAndHideTerms.sahMicroLoboSegCovid = false;
    this.showAndHideTerms.sahMicroLoboPreCovid = true;
    this.catchIDs();
  }

  clickEspessamentDerrame() {
    if (this.showAndHideTerms.sahEspessamentoDerr === false)
      this.showAndHideTerms.sahEspessamentoDerr = true;
    else
      this.showAndHideTerms.sahEspessamentoDerr = false;
    this.catchIDs();
  }

  clickExtensaoAco() {
    if (this.showAndHideTerms.sahExtensaoAco === false)
      this.showAndHideTerms.sahExtensaoAco = true;
    else
      this.showAndHideTerms.sahExtensaoAco = false;

    // this.ckNegativoPneu.checked = false;
    this.catchIDs();
  }

  clickAtelectasia() {
    if (this.showAndHideTerms.sahAtelectasia === false) {
      this.showAndHideTerms.sahAtelectasia = true;
    } else {
      this.showAndHideTerms.sahAtelectasia = false;
    }

    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickAtelectasiaLoc() {
    this.catchIDs();
  }

  clickAtelectasiaSeg() {
    this.catchIDs();
  }

  clickAtelectasiaLam() {
    this.catchIDs();
  }

  clickAtelectasiaEsp() {
    this.catchIDs();
  }

  clickAtelectasiaEst() {
    this.catchIDs();
  }

  clickInfarto() {
    if (this.showAndHideTerms.sahInfarto === false)
      this.showAndHideTerms.sahInfarto = true;
    else
      this.showAndHideTerms.sahInfarto = false;
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEnfisema() {
    if (this.showAndHideTerms.sahEnfisema === false) {
      this.showAndHideTerms.sahEnfisema = true;
      this.showAndHideTerms.sahEnfisemaGrau = true;
      this.showAndHideTerms.sahEnfisemaTipo = false;
    } else {
      this.showAndHideTerms.sahEnfisema = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickEnfisemaGrau() {
    this.showAndHideTerms.sahEnfisemaGrau = true;
    this.showAndHideTerms.sahEnfisemaTipo = false;
    this.catchIDs();
  }

  clickEnfisemaDef() {
    this.showAndHideTerms.sahEnfisemaGrau = false;
    this.showAndHideTerms.sahEnfisemaTipo = false;
    this.catchIDs();
  }

  clickEnfisemaTipo() {
    this.showAndHideTerms.sahEnfisemaGrau = false;
    this.showAndHideTerms.sahEnfisemaTipo = true;
    this.catchIDs();
  }

  clickIntersticiopatia() {
    if (this.showAndHideTerms.sahIntersticiopatia === false) {
      this.showAndHideTerms.sahIntersticiopatia = true;
      this.showAndHideTerms.sahIntLoboPre = true;
      this.showAndHideTerms.sahIntArea = false;
    } else {
      this.showAndHideTerms.sahIntersticiopatia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickIntersticiopatiaLobo() {
    this.showAndHideTerms.sahIntLoboPre = true;
    this.showAndHideTerms.sahIntArea = false;
    this.catchIDs();
  }

  clickIntersticiopatiaDef() {
    this.showAndHideTerms.sahIntLoboPre = false;
    this.showAndHideTerms.sahIntArea = false;
    this.catchIDs();
  }

  clickIntersticiopatiaAll() {
    this.showAndHideTerms.sahIntLoboPre = true;
    this.showAndHideTerms.sahIntArea = true;
    this.catchIDs();
  }

  clickNodulo() {
    if (this.showAndHideTerms.sahNodulo === false) {
      this.showAndHideTerms.sahNodulo = true;
      this.showAndHideTerms.sahNodSup = true;
      this.showAndHideTerms.sah1NodSup = true;
      this.showAndHideTerms.sah2NodSup = false;
      this.showAndHideTerms.sahNodMult = false;
      this.showAndHideTerms.sahNodMet = false;
    } else {
      this.showAndHideTerms.sahNodulo = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickNodSup() {
    this.showAndHideTerms.sahNodSup = true;
    this.showAndHideTerms.sah1NodSup = true;
    this.showAndHideTerms.sah2NodSup = false;
    this.showAndHideTerms.sahNodMult = false;
    this.showAndHideTerms.sahNodMet = false;
    this.ajustaArrayDinamico();
  }

  click1NodSup() {
    this.showAndHideTerms.sah1NodSup = true;
    this.showAndHideTerms.sah2NodSup = false;
  }

  click2NodSup() {
    this.showAndHideTerms.sah1NodSup = false;
    this.showAndHideTerms.sah2NodSup = true;
    this.ajustaArrayDinamico();
  }

  clickNodMult() {
    this.showAndHideTerms.sahNodSup = false;
    this.showAndHideTerms.sahNodMult = true;
    this.showAndHideTerms.sahNodMet = false;
    this.ajustaArrayDinamico();
  }

  clickNodMet() {
    this.showAndHideTerms.sahNodSup = false;
    this.showAndHideTerms.sahNodMult = false;
    this.showAndHideTerms.sahNodMet = true;
    this.ajustaArrayDinamico();
  }

  clickNodDiminutos() {
    if (this.showAndHideTerms.sahNodDiminutos === false) {
      this.showAndHideTerms.sahNodDiminutos = true;
      this.showAndHideTerms.sahNodDimInc = false;
      this.showAndHideTerms.sahNodDimRarosInc = false;
      this.showAndHideTerms.sahNodDimRarosRes = false;
      this.showAndHideTerms.sahNodDimCalc = false;
      this.showAndHideTerms.sahNodDimNcNc = false;
    } else {
      this.showAndHideTerms.sahNodDiminutos = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  public clickNodDimInc() {
    if (this.showAndHideTerms.sahNodDimInc === false) {
      this.showAndHideTerms.sahNodDimInc = true;
    } else {
      this.showAndHideTerms.sahNodDimInc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  public clickNodDimRarosInc() {
    if (this.showAndHideTerms.sahNodDimRarosInc === false) {
      this.showAndHideTerms.sahNodDimRarosInc = true;
    } else {
      this.showAndHideTerms.sahNodDimRarosInc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }


  public clickNodDimRarosRes() {
    if (this.showAndHideTerms.sahNodDimRarosRes === false) {
      this.showAndHideTerms.sahNodDimRarosRes = true;
    } else {
      this.showAndHideTerms.sahNodDimRarosRes = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }


  public clickNodDimCalc() {
    if (this.showAndHideTerms.sahNodDimCalc === false) {
      this.showAndHideTerms.sahNodDimCalc = true;
    } else {
      this.showAndHideTerms.sahNodDimCalc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickNodDimNcNc() {
    if (this.showAndHideTerms.sahNodDimNcNc === false) {
      this.showAndHideTerms.sahNodDimNcNc = true;
    } else {
      this.showAndHideTerms.sahNodDimNcNc = false;
    }
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  clickMassa() {
    if (this.showAndHideTerms.sahMassaPulmonar === false)
      this.showAndHideTerms.sahMassaPulmonar = true;
    else
      this.showAndHideTerms.sahMassaPulmonar = false;
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickMicronodulos() {
    if (this.showAndHideTerms.sahMicronodulos === false) {
      this.showAndHideTerms.sahMicronodulos = true;
      this.showAndHideTerms.sahMicroLoboSeg = true;
      this.showAndHideTerms.sahMicroLoboPre = false;
    } else {
      this.showAndHideTerms.sahMicronodulos = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickMicroPeri() {
    this.showAndHideTerms.sahMicroLoboSeg = true;
    this.showAndHideTerms.sahMicroLoboPre = false;
    this.catchIDs();
  }

  clickMicroPeriEspDif() {
    this.showAndHideTerms.sahMicroLoboSeg = false;
    this.showAndHideTerms.sahMicroLoboPre = true;
    this.limpaLoboSeg();
    this.catchIDs();
  }

  clickMicroRanDif() {
    this.showAndHideTerms.sahMicroLoboSeg = false;
    this.showAndHideTerms.sahMicroLoboPre = false;
    this.limpaLoboSeg();
    this.catchIDs();

  }

  clickCongestao() {
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickBronquios() {
    if (this.showAndHideTerms.sahBronquiectasia === false) {
      this.showAndHideTerms.sahBronquiectasia = true;
      this.showAndHideTerms.sahBronqTipo = true;
    } else {
      this.showAndHideTerms.sahBronquiectasia = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickBronqAll() {
    this.showAndHideTerms.sahBronqTipo = true;
    this.catchIDs();
  }

  clickSemTipo() {
    this.showAndHideTerms.sahBronqTipo = false;
  }

  clickCistoAereo() {
    if (this.showAndHideTerms.sahCistoAereo === false) {
      this.showAndHideTerms.sahCistoAereo = true;
      this.showAndHideTerms.sahComSeg = true;
      this.showAndHideTerms.sahEsparsos = false;
      this.showAndHideTerms.sahSubpleural = false;
    } else {
      this.showAndHideTerms.sahCistoAereo = false;
    }
    this.rdAlterado.checked = true;
    this.verificaAlterado();
    this.catchIDs();
  }

  clickCsistoComSem() {
    this.showAndHideTerms.sahComSeg = true;
    this.showAndHideTerms.sahEsparsos = false;
    this.showAndHideTerms.sahSubpleural = false;
    this.catchIDs();
  }

  clickEsparcos() {
    this.showAndHideTerms.sahComSeg = false;
    this.showAndHideTerms.sahSemSeg = false;
    this.showAndHideTerms.sahEsparsos = true;
    this.showAndHideTerms.sahSubpleural = false;
    this.catchIDs();
  }

  clickSubpleural() {
    this.showAndHideTerms.sahComSeg = false;
    this.showAndHideTerms.sahSemSeg = false;
    this.showAndHideTerms.sahEsparsos = false;
    this.showAndHideTerms.sahSubpleural = true;
    this.catchIDs();
  }

  limpaSegmento(slSegmento: any) {
    //! Deixar metodo dinamico
    if (slSegmento.value)
      slSegmento.value = '';

    this.catchIDs();
  }

  
  limpaLoboSeg() {
    if (this.slLoboMicro && this.slLoboMicro.value !== '') {
      this.slLoboMicro.value = '';
    }

    if (this.slSegMicro && this.slSegMicro.value !== '') {
      this.slSegMicro.value = ''
    }

    if (this.slLoboPreMicro && this.slLoboPreMicro.value !== '') {
      this.slLoboPreMicro.value = ''
    }
  }

  changeSelect() {
    this.catchIDs();
  }

  clickDefault() {
    this.rdAlterado.checked = true;
    this.catchIDs();
  }

  blurDimensao() {
    this.catchIDs();
  }

  clickCheckBox() {
    this.catchIDs();
  }

  blurMedida() {
    this.catchIDs();
  }

  catchIDs() {
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdAlterado.checked) {
      if (this.ckCovid && this.ckCovid.checked) {

        this.emitCovid();
      }

      if (this.countLesoes > 0)
        this.emitAtelectasiaLesao();
      else
        this.emitAtelectasia();

      if (this.countLesoes > 0)
        this.emitInfartoLesao();
      else
        this.emitInfarto();

      if (this.countLesoes > 0)
        this.emitEnfisemaLesao();
      else
        this.emitEnfisema();

      if (this.countLesoes > 0)
        this.emitIntersticiopatiaLesao();
      else
        this.emitIntersticiopatia();

      if (this.countLesoes > 0)
        this.emitMassaLesao();
      else
        this.emitMassa();

      if (this.countLesoes > 0)
        this.emitMicronoduloLesao();
      else
        this.emitMicronodulo();

      if (this.countLesoes > 0)
        this.emitCongestaoLesao();
      else
        this.emitCongestao();

      if (this.countLesoes > 0)
        this.emitBronquiectasiasLesao();
      else
        this.emitBronquiectasias();

      if (this.countLesoes > 0)
        this.emitNoduloLesao();
      else
        this.emitNodulo();

      if (this.countLesoes > 0)
        this.emitNodDiminutosLesao();
      else
        this.emitNodDiminutos();

      if (this.countLesoes > 0)
        this.emitCistoAereoLesao();
      else
        this.emitCistoAereo();

      //RESTANTE PARENQUIMA PRECISA SER O ULTIMO
      if (this.countLesoes > 0)
        this.emitParemquimaLesao();
      else
        this.emitParemquima();
    }

    this.listaCompleta.emit();
  }

  emitParemquima() {
    if (this.ckRestParenquima && this.ckRestParenquima.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckRestParenquima.id);
    }
  }

  emitParemquimaLesao() {
    if (this.ckRestParenquima && this.ckRestParenquima.checked) {
      this.countLesoes++;
      const objetoPar = { id: this.ckRestParenquima.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoPar);
    }
  }

  emitCovid() {
    // if (this.ckNegativoPneu && this.ckNegativoPneu.checked) {
    //   this.countLesoes++;
    //   this.termoSelecionado.emit(+this.ckNegativoPneu.id);
    // }

    this.emitAparenciaTipicaCovid();

    if (this.countLesoes > 0)
      this.emitAparenciaIndCovidLesao();
    else
      this.emitAparenciaIndCovid();


    if (this.countLesoes > 0)
      this.emitAparenciaAtipCovidLesao();
    else
      this.emitAparenciaAtipCovid();

    if (this.ckExtensaoAco && this.ckExtensaoAco.checked) {
      if (this.countLesoes > 0)
        this.emitExtensaoCovidLesao();
      else
        this.emitExtensaoCovid();
    }
  }

  emitAparenciaTipicaCovid() {
    if (this.rdVidroFoscPeri && this.rdVidroFoscPeri.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdVidroFoscPeri.id);

      if (this.slLoboVidroFoscPeri && this.slLoboVidroFoscPeri.value) {
        const objeto = { elemento: this.slLoboVidroFoscPeri, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

        //this.termoSelecionado.emit(1865);
      }

      //! Refatorar lógica
      // Lógica de achados
      let achados = '';
      if (this.ckPaviEspPeri && this.ckPaviEspPeri.checked)
        achados = '</br>- espessamento de septos interlobulares (“pavimentação em mosaico”)';

      if (this.ckPaviLocPeri && this.ckPaviLocPeri.checked) {
        if (this.slLoboPaviLocPeri && this.slLoboPaviLocPeri.value) {
          achados += '</br>- espessamento de septos interlobulares (“pavimentação em mosaico”) ' + this.formatMultipleLobos(this.slLoboPaviLocPeri.value);
        }
      }

      if (this.ckConsolidacoesEsp && this.ckConsolidacoesEsp.checked) {
        if (this.slLoboConsolidacoesEsp && this.slLoboConsolidacoesEsp.value) {
          achados += '</br>- focos de consolidações esparsas ' + this.formatMultipleLobos(this.slLoboConsolidacoesEsp.value);
        }
      }

      if (this.ckConsEspPeri && this.ckConsEspPeri.checked)
        achados += '</br>- áreas confluentes de consolidação';

      if (this.ckConsLocPeri && this.ckConsLocPeri.checked) {
        if (this.slLoboConsLocPeri && this.slLoboConsLocPeri.value) {
          achados += '</br>- consolidação localizada ' + this.formatMultipleLobos(this.slLoboConsLocPeri.value);
        }
      }

      if (this.ckHaloPeri && this.ckHaloPeri.checked) {
        if (this.slLoboHaloPeri && this.slLoboHaloPeri.value) {
          achados += '</br>- opacidade em vidro fosco margeada por consolidação (“sinal do halo invertido”) ' + this.formatMultipleLobos(this.slLoboHaloPeri.value);
        }
      }

      if (achados) {
        const elemento = { id: 1866, value: achados };
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    }

    if (this.countLesoes > 0)
      this.emitVidroFoscMultLesao();
    else
      this.emitVidroFoscMult();

    if (this.countLesoes > 0)
      this.emitHaloInvertidoLesao();
    else
      this.emitHaloInvertido();

  }

  emitVidroFoscMult() {
    if (this.rdVidroFoscMult && this.rdVidroFoscMult.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdVidroFoscMult.id);

      if (this.slLoboVidroFoscMult && this.slLoboVidroFoscMult.value) {
        const objeto = { elemento: this.slLoboVidroFoscMult, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);

        // slConsolidacaoVidroFoscMult
        //this.termoSelecionado.emit(1870);
      }

      //! Refatorar lógica
      // Lógica de achados
      let achados = '';
      if (this.ckPaviEspMult && this.ckPaviEspMult.checked)
        achados = '</br>- espessamento de septos interlobulares (“pavimentação em mosaico”)';

      if (this.ckPaviLocMult && this.ckPaviLocMult.checked) {
        if (this.slLoboPaviLocMult && this.slLoboPaviLocMult.value) {
          achados += '</br>- espessamento de septos interlobulares (“pavimentação em mosaico”) ' + this.formatMultipleLobos(this.slLoboPaviLocMult.value);
        }
      }

      if (this.ckConsolidacoesEspMult && this.ckConsolidacoesEspMult.checked) {
        if (this.slLoboConsolidacoesEspMult && this.slLoboConsolidacoesEspMult.value) {
          achados += '</br>- focos de consolidações esparsas ' + this.formatMultipleLobos(this.slLoboConsolidacoesEspMult.value);
        }
      }

      if (this.ckConsEspMult && this.ckConsEspMult.checked)
        achados += '</br>- áreas confluentes de consolidação';

      if (this.ckConsLocMult && this.ckConsLocMult.checked) {
        if (this.slLoboConsLocMult && this.slLoboConsLocMult.value) {
          achados += '</br>- consolidação localizada ' + this.formatMultipleLobos(this.slLoboConsLocMult.value);
        }
      }

      if (this.ckHaloMult && this.ckHaloMult.checked) {
        if (this.slLoboHaloMult && this.slLoboHaloMult.value) {
          achados += '</br>- opacidade em vidro fosco margeada por consolidação (“sinal do halo invertido”) ' + this.formatMultipleLobos(this.slLoboHaloMult.value);
        }
      }

      if (achados) {
        const elemento = { id: 1871, value: achados };
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitVidroFoscMultLesao() {
    if (this.rdVidroFoscMult && this.rdVidroFoscMult.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.rdVidroFoscMult.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);


      if (this.slLoboVidroFoscMult && this.slLoboVidroFoscMult.value) {
        const objeto = { elemento: this.slLoboVidroFoscMult, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);

        //slConsolidacaoVidroFoscMult
        const objetoCov = { id: 1870, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }

      //! Refatorar lógica
      // Lógica de achados
      let achados = '';
      if (this.ckPaviEspMult && this.ckPaviEspMult.checked)
        achados = '</br>- espessamento de septos interlobulares (“pavimentação em mosaico”)';

      if (this.ckPaviLocMult && this.ckPaviLocMult.checked) {
        if (this.slLoboPaviLocMult && this.slLoboPaviLocMult.value) {
          achados += '</br>- espessamento de septos interlobulares (“pavimentação em mosaico”) ' + this.formatMultipleLobos(this.slLoboPaviLocMult.value);
        }
      }

      if (this.ckConsolidacoesEspMult && this.ckConsolidacoesEspMult.checked) {
        if (this.slLoboConsolidacoesEspMult && this.slLoboConsolidacoesEspMult.value) {
          achados += '</br>- focos de consolidações esparsas ' + this.formatMultipleLobos(this.slLoboConsolidacoesEspMult.value);
        }
      }

      if (this.ckConsEspMult && this.ckConsEspMult.checked)
        achados += '</br>- áreas confluentes de consolidação';

      if (this.ckConsLocMult && this.ckConsLocMult.checked) {
        if (this.slLoboConsLocMult && this.slLoboConsLocMult.value) {
          achados += '</br>- consolidação localizada ' + this.formatMultipleLobos(this.slLoboConsLocMult.value);
        }
      }

      if (this.ckHaloMult && this.ckHaloMult.checked) {
        if (this.slLoboHaloMult && this.slLoboHaloMult.value) {
          achados += '</br>- opacidade em vidro fosco margeada por consolidação (“sinal do halo invertido”) ' + this.formatMultipleLobos(this.slLoboHaloMult.value);
        }
      }

      if (achados) {
        const elemento = { id: 1871, value: achados };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHaloInvertido() {
    if (this.rdHaloInvertido && this.rdHaloInvertido.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.rdHaloInvertido.id);

      // Lobo extra na carga
      this.termoSelecionado.emit(1873);

      if (this.slLocalizacaoHaloInvert && this.slLocalizacaoHaloInvert.value) {
        const objeto = { elemento: this.slLocalizacaoHaloInvert, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitHaloInvertidoLesao() {
    if (this.rdHaloInvertido && this.rdHaloInvertido.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.rdHaloInvertido.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      // Lobo extra na carga
      const objet = { id: 1873, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objet);

      if (this.slLocalizacaoHaloInvert && this.slLocalizacaoHaloInvert.value) {
        const objeto = { elemento: this.slLocalizacaoHaloInvert, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAparenciaIndCovid() {

    if (this.slLoboVidroNao && this.slLoboVidroNao.value) {
      this.termoSelecionado.emit(this.idCovidInd);
      this.countLesoes++;
      if (this.slSegVidroNao && this.slSegVidroNao.value) {
        const lobo = this.formataLobo(this.slLoboVidroNao.value);

        const elemento = { id: this.slLoboVidroNao.id, value: lobo };
        const objeto = { elemento: elemento, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      } else {
        const objeto = { elemento: this.slLoboVidroNao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

    if (this.slSegVidroNao && this.slSegVidroNao.value) {
      const objeto = { elemento: this.slSegVidroNao, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slDistslVidroNao && this.slDistslVidroNao.value) {
      const objeto = { elemento: this.slDistslVidroNao, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.ckConsolidacaoEsp && this.ckConsolidacaoEsp.checked) {
      this.termoSelecionado.emit(this.ckConsolidacaoEsp.id);
    }

    if (this.ckConsolidacaoLoc && this.ckConsolidacaoLoc.checked) {
      this.termoSelecionado.emit(this.ckConsolidacaoLoc.id);

      if (this.slLocVidroNao && this.slLocVidroNao.value) {
        const objeto = { elemento: this.slLocVidroNao, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAparenciaIndCovidLesao() {

    if (this.slLoboVidroNao && this.slLoboVidroNao.value) {
      this.countLesoes++;
      const objetoCov = { id: this.idCovidInd, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);
      if (this.slSegVidroNao && this.slSegVidroNao.value) {
        const lobo = this.formataLobo(this.slLoboVidroNao.value);

        const elemento = { id: this.slLoboVidroNao.id, value: lobo };
        const objeto = { elemento: elemento, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      } else {
        const objeto = { elemento: this.slLoboVidroNao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }


    if (this.slSegVidroNao && this.slSegVidroNao.value) {
      const objeto = { elemento: this.slSegVidroNao, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.slDistslVidroNao && this.slDistslVidroNao.value) {
      const objeto = { elemento: this.slDistslVidroNao, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }

    if (this.ckConsolidacaoEsp && this.ckConsolidacaoEsp.checked) {
      const objetoCov = { id: this.ckConsolidacaoEsp.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);
    }

    if (this.ckConsolidacaoLoc && this.ckConsolidacaoLoc.checked) {
      const objetoCov = { id: this.ckConsolidacaoLoc.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLocVidroNao && this.slLocVidroNao.value) {
        const objeto = { elemento: this.slLocVidroNao, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitAparenciaAtipCovid() {
    if (this.ckConsolidacao && this.ckConsolidacao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckConsolidacao.id);

      if (this.rdConsLoc && this.rdConsLoc.checked) {
        this.termoSelecionado.emit(this.rdConsLoc.id);

        if (this.slLoboConsLoc && this.slLoboConsLoc.value) {
          if (this.slSegConsLoc && this.slSegConsLoc.value) {
            const lobo = this.formataLobo(this.slLoboConsLoc.value);

            const elemento = { id: this.slLoboConsLoc.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboConsLoc, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegConsLoc && this.slSegConsLoc.value) {
          const objeto = { elemento: this.slSegConsLoc, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rdConsAte && this.rdConsAte.checked) {
        this.termoSelecionado.emit(this.rdConsAte.id);

        if (this.slLoboConsAte && this.slLoboConsAte.value) {
          if (this.slSegConsAte && this.slSegConsAte.value) {
            const lobo = this.formataLobo(this.slLoboConsAte.value);

            const elemento = { id: this.slLoboConsAte.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboConsAte, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegConsAte && this.slSegConsAte.value) {
          const objeto = { elemento: this.slSegConsAte, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }

    if (this.countLesoes > 0)
      this.emitMicronoduloCovidLesao();
    else
      this.emitMicronoduloCovid();

    if (this.countLesoes > 0)
      this.emitEspessamentoCovidLesao();
    else
      this.emitEspessamentoCovid();

  }

  emitAparenciaAtipCovidLesao() {
    if (this.ckConsolidacao && this.ckConsolidacao.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckConsolidacao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.rdConsLoc && this.rdConsLoc.checked) {
        const objetoCov = { id: this.rdConsLoc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboConsLoc && this.slLoboConsLoc.value) {
          if (this.slSegConsLoc && this.slSegConsLoc.value) {
            const lobo = this.formataLobo(this.slLoboConsLoc.value);

            const elemento = { id: this.slLoboConsLoc.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboConsLoc, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegConsLoc && this.slSegConsLoc.value) {
          const objeto = { elemento: this.slSegConsLoc, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      } else if (this.rdConsAte && this.rdConsAte.checked) {
        const objetoCov = { id: this.rdConsAte.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboConsAte && this.slLoboConsAte.value) {
          if (this.slSegConsAte && this.slSegConsAte.value) {
            const lobo = this.formataLobo(this.slLoboConsAte.value);

            const elemento = { id: this.slLoboConsAte.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {
            const objeto = { elemento: this.slLoboConsAte, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegConsAte && this.slSegConsAte.value) {
          const objeto = { elemento: this.slSegConsAte, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }
    }

    this.emitMicronoduloCovidLesao();

    this.emitEspessamentoCovidLesao();
  }

  emitMicronoduloCovid() {
    if (this.ckMicronodulosCovid && this.ckMicronodulosCovid.checked) {
      this.countLesoes++;

      if (this.rdMicroCentroCovid && this.rdMicroCentroCovid.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroCovid.id);

      } else if (this.rdMicroCentroEspCovid && this.rdMicroCentroEspCovid.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroEspCovid.id);

      } else if (this.rdMicroCentroDifCovid && this.rdMicroCentroDifCovid.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroDifCovid.id);

      }

      if (this.slLoboMicroCovid && this.slLoboMicroCovid.value) {
        if (this.slSegMicroCovid && this.slSegMicroCovid.value) {
          const lobo = this.formataLobo(this.slLoboMicroCovid.value);

          const elemento = { id: this.slLoboMicroCovid.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboMicroCovid, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegMicroCovid && this.slSegMicroCovid.value) {
        const objeto = { elemento: this.slSegMicroCovid, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboPreMicroCovid && this.slLoboPreMicroCovid.value) {
        const objeto = { elemento: this.slLoboPreMicroCovid, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitMicronoduloCovidLesao() {
    if (this.ckMicronodulosCovid && this.ckMicronodulosCovid.checked) {
      this.countLesoes++;

      if (this.rdMicroCentroCovid && this.rdMicroCentroCovid.checked) {
        const objetoCov = { id: this.rdMicroCentroCovid.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

      } else if (this.rdMicroCentroEspCovid && this.rdMicroCentroEspCovid.checked) {
        const objetoCov = { id: this.rdMicroCentroEspCovid.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

      } else if (this.rdMicroCentroDifCovid && this.rdMicroCentroDifCovid.checked) {
        const objetoCov = { id: this.rdMicroCentroDifCovid.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);
      }

      if (this.slLoboMicroCovid && this.slLoboMicroCovid.value) {
        if (this.slSegMicroCovid && this.slSegMicroCovid.value) {
          const lobo = this.formataLobo(this.slLoboMicroCovid.value);

          const elemento = { id: this.slLoboMicroCovid.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboMicroCovid, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegMicroCovid && this.slSegMicroCovid.value) {
        const objeto = { elemento: this.slSegMicroCovid, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboPreMicroCovid && this.slLoboPreMicroCovid.value) {
        const objeto = { elemento: this.slLoboPreMicroCovid, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspessamentoCovid() {
    if (this.ckEspessamentDerr && this.ckEspessamentDerr.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEspessamentDerr.id);

      if (this.slLadoEspDerr && this.slLadoEspDerr.value) {
        const objeto = { elemento: this.slLadoEspDerr, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slGrauEspDerr && this.slGrauEspDerr.value) {
        const objeto = { elemento: this.slGrauEspDerr, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEspessamentoCovidLesao() {
    if (this.ckEspessamentDerr && this.ckEspessamentDerr.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckEspessamentDerr.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLadoEspDerr && this.slLadoEspDerr.value) {
        const objeto = { elemento: this.slLadoEspDerr, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slGrauEspDerr && this.slGrauEspDerr.value) {
        const objeto = { elemento: this.slGrauEspDerr, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitExtensaoCovid() {
    this.countLesoes++;
    this.termoSelecionado.emit(this.ckExtensaoAco.id);

    if (this.slExtensaoCovid && this.slExtensaoCovid.value) {
      const objeto = { elemento: this.slExtensaoCovid, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }
  }

  emitExtensaoCovidLesao() {
    this.countLesoes++;
    const objetoCov = { id: this.ckExtensaoAco.id, cenario: this.countLesoes };
    this.lesoesSelecionadas.emit(objetoCov);

    if (this.slExtensaoCovid && this.slExtensaoCovid.value) {
      const objeto = { elemento: this.slExtensaoCovid, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
    }
  }

  emitAtelectasia() {
    if (this.ckAtelectasia && this.ckAtelectasia.checked) {

      if (this.ckAteLoc && this.ckAteLoc.checked) {
        if (this.countLesoes > 0) {
          this.emitCkckAteLocLesoes();
        } else {
          this.emitCkckAteLoc();
        }
      }

      if (this.ckAteSeg && this.ckAteSeg.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteSegLesao();
        } else {
          this.emitckAteSeg();
        }
      }
      if (this.ckAteLam && this.ckAteLam.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteLamLesoes();
        } else {
          this.emitckAteLam();
        }
      }
      if (this.ckAteEsp && this.ckAteEsp.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteEspLesoes();
        } else {
          this.emitckAteEsp();
        }
      }

      if (this.ckAteEst && this.ckAteEst.checked) {
        if (this.countLesoes > 0) {
          this.emitckAteEstLesoes();
        } else {
          this.emitckAteEst();
        }
      }

    }
  }

  emitAtelectasiaLesao() {
    if (this.ckAtelectasia && this.ckAtelectasia.checked) {

      if (this.ckAteLoc && this.ckAteLoc.checked) {
        this.emitCkckAteLocLesoes();
      }

      if (this.ckAteSeg && this.ckAteSeg.checked) {
        this.emitckAteSegLesao();
      }

      if (this.ckAteLam && this.ckAteLam.checked) {
        this.emitckAteLamLesoes();
      }
      if (this.ckAteEsp && this.ckAteEsp.checked) {
        this.emitckAteEspLesoes();
      }

      if (this.ckAteEst && this.ckAteEst.checked) {
        this.emitckAteEstLesoes();
      }
    }
  }

  emitCkckAteLoc() {

    if (this.ckAteLoc && this.ckAteLoc.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteLoc.id);

      if (this.slLoboAteLoc && this.slLoboAteLoc.value) {
        if (this.slSegAteLoc && this.slSegAteLoc.value) {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);

          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);
          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegAteLoc && this.slSegAteLoc.value) {
        const objeto = { elemento: this.slSegAteLoc, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCkckAteLocLesoes() {
    if (this.ckAteLoc && this.ckAteLoc.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteLoc.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLoboAteLoc && this.slLoboAteLoc.value) {
        if (this.slSegAteLoc && this.slSegAteLoc.value) {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);
          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const obj = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(obj);
        } else {
          const lobo = this.formataLobo(this.slLoboAteLoc.value);
          const elemento = { id: this.slLoboAteLoc.id, value: lobo };
          const obj = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(obj);
        }
      }

      if (this.slSegAteLoc && this.slSegAteLoc.value) {
        const obj = { elemento: this.slSegAteLoc, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitckAteSeg() {
    if (this.ckAteSeg && this.ckAteSeg.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteSeg.id);

      if (this.slTipoAte && this.slTipoAte.value) {
        const objeto = { elemento: this.slTipoAte, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboAteSeg && this.slLoboAteSeg.value) {
        const objeto = { elemento: this.slLoboAteSeg, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }

  }

  emitckAteSegLesao() {
    if (this.ckAteSeg && this.ckAteSeg.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteSeg.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slTipoAte && this.slTipoAte.value) {
        const obj = { elemento: this.slTipoAte, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }

      if (this.slLoboAteSeg && this.slLoboAteSeg.value) {
        const obj = { elemento: this.slLoboAteSeg, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitckAteLam() {
    if (this.ckAteLam && this.ckAteLam.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteLam.id);

      if (this.slLoboAteLam && this.slLoboAteLam.value) {
        const objeto = { elemento: this.slLoboAteLam, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitckAteLamLesoes() {
    if (this.ckAteLam && this.ckAteLam.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteLam.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLoboAteLam && this.slLoboAteLam.value) {
        const obj = { elemento: this.slLoboAteLam, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitckAteEsp() {
    if (this.ckAteEsp && this.ckAteEsp.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteEsp.id);
    }
  }

  emitckAteEspLesoes() {
    if (this.ckAteEsp && this.ckAteEsp.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteEsp.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);
    }
  }

  emitckAteEst() {
    if (this.ckAteEst && this.ckAteEst.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckAtelectasia.id);
      this.termoSelecionado.emit(this.ckAteEst.id);

      if (this.slLoboAteEst && this.slLoboAteEst.value) {
        const objeto = { elemento: this.slLoboAteEst, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitckAteEstLesoes() {
    if (this.ckAteEst && this.ckAteEst.checked) {
      this.countLesoes++;
      const objeto = { id: this.ckAtelectasia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);
      const objetoCov = { id: this.ckAteEst.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.slLoboAteEst && this.slLoboAteEst.value) {
        const obj = { elemento: this.slLoboAteEst, isPrimeiraLesao: false };
        this.termosGenericos.emit(obj);
      }
    }
  }

  emitInfarto() {
    if (this.ckInfarto && this.ckInfarto.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(1648);
      this.termoSelecionado.emit(this.ckInfarto.id);

      if (this.slLoboInf && this.slLoboInf.value) {
        if (this.slSegInf && this.slSegInf.value) {
          const lobo = this.formataLobo(this.slLoboInf.value);

          const elemento = { id: this.slLoboInf.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboInf, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegInf && this.slSegInf.value) {
        const objeto = { elemento: this.slSegInf, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

    }
  }

  emitInfartoLesao() {
    if (this.ckInfarto && this.ckInfarto.checked) {
      this.countLesoes++;
      const objetoCov = { id: 1648, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      const obj = { id: this.ckInfarto.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slLoboInf && this.slLoboInf.value) {
        if (this.slSegInf && this.slSegInf.value) {
          const lobo = this.formataLobo(this.slLoboInf.value);

          const elemento = { id: this.slLoboInf.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboInf, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegInf && this.slSegInf.value) {
        const objeto = { elemento: this.slSegInf, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEnfisema() {
    if (this.ckEnfisema && this.ckEnfisema.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEnfisema.id);

      if (this.rdEnfLoc && this.rdEnfLoc.checked) {
        this.termoSelecionado.emit(this.rdEnfLoc.id);

      } else if (this.rdEnfiPara && this.rdEnfiPara.checked) {
        this.termoSelecionado.emit(this.rdEnfiPara.id);

      } else if (this.rdEnfCentPara && this.rdEnfCentPara.checked) {
        this.termoSelecionado.emit(this.rdEnfCentPara.id);

      } else if (this.rdRarosEnf && this.rdRarosEnf.checked) {
        this.termoSelecionado.emit(this.rdRarosEnf.id);

      } else if (this.rdEnfPan && this.rdEnfPan.checked) {
        this.termoSelecionado.emit(this.rdEnfPan.id);
      }

      if (this.slLoboEnf && this.slLoboEnf.value) {
        const objeto = { elemento: this.slLoboEnf, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slTipoEnf && this.slTipoEnf.value) {
        const objeto = { elemento: this.slTipoEnf, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitEnfisemaLesao() {
    if (this.ckEnfisema && this.ckEnfisema.checked) {
      this.countLesoes++;
      const obj = { id: this.ckEnfisema.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdEnfLoc && this.rdEnfLoc.checked) {
        const obj = { id: this.rdEnfLoc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdEnfiPara && this.rdEnfiPara.checked) {
        const obj = { id: this.rdEnfiPara.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdEnfCentPara && this.rdEnfCentPara.checked) {
        const obj = { id: this.rdEnfCentPara.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdRarosEnf && this.rdRarosEnf.checked) {
        const obj = { id: this.rdRarosEnf.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdEnfPan && this.rdEnfPan.checked) {
        const obj = { id: this.rdEnfPan.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slLoboEnf && this.slLoboEnf.value) {
        const objeto = { elemento: this.slLoboEnf, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slTipoEnf && this.slTipoEnf.value) {
        const objeto = { elemento: this.slTipoEnf, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitIntersticiopatia() {
    if (this.ckIntersticiopatia && this.ckIntersticiopatia.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckIntersticiopatia.id);

      if (this.rdIntGeral && this.rdIntGeral.checked) {
        this.termoSelecionado.emit(this.rdIntGeral.id);

      } else if (this.rdPine && this.rdPine.checked) {
        this.termoSelecionado.emit(this.rdPine.id);

      } else if (this.rdPiu && this.rdPiu.checked) {
        this.termoSelecionado.emit(this.rdPiu.id);

      } else if (this.rdFibrosePul && this.rdFibrosePul.checked) {
        this.termoSelecionado.emit(this.rdFibrosePul.id);

      } else if (this.rdPneumoniaOrg && this.rdPneumoniaOrg.checked) {
        this.termoSelecionado.emit(this.rdPneumoniaOrg.id);

      } else if (this.rdPneumoniteHip && this.rdPneumoniteHip.checked) {
        this.termoSelecionado.emit(this.rdPneumoniteHip.id);
      }

      if (this.slLoboInt && this.slLoboInt.value) {
        const objeto = { elemento: this.slLoboInt, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAreaInt && this.ckAreaInt.checked) {
        this.termoSelecionado.emit(this.ckAreaInt.id);
      }
    }
  }

  emitIntersticiopatiaLesao() {
    if (this.ckIntersticiopatia && this.ckIntersticiopatia.checked) {
      this.countLesoes++;
      const obj = { id: this.ckIntersticiopatia.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdIntGeral && this.rdIntGeral.checked) {
        const obj = { id: this.rdIntGeral.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPine && this.rdPine.checked) {
        const obj = { id: this.rdPine.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPiu && this.rdPiu.checked) {
        const obj = { id: this.rdPiu.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdFibrosePul && this.rdFibrosePul.checked) {
        const obj = { id: this.rdFibrosePul.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPneumoniaOrg && this.rdPneumoniaOrg.checked) {
        const obj = { id: this.rdPneumoniaOrg.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdPneumoniteHip && this.rdPneumoniteHip.checked) {
        const obj = { id: this.rdPneumoniteHip.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slLoboInt && this.slLoboInt.value) {
        const objeto = { elemento: this.slLoboInt, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.ckAreaInt && this.ckAreaInt.checked) {
        const obj = { id: this.ckAreaInt.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
    }
  }

  public emitNodDiminutosDimInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.rdDimInc && this.rdDimInc.checked) {
        this.termoSelecionado.emit(this.rdDimInc.id);


        if (this.inMedidaDim && this.inMedidaDim.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDim.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosDimRarosInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);


      if (this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.termoSelecionado.emit(this.rdDimRarosInc.id);


        if (this.inMedidaDimRarosInc && this.inMedidaDimRarosInc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosInc.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosDimRarosRes() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.termoSelecionado.emit(this.rdDimRarosRes.id);


        if (this.inMedidaDimRarosRes && this.inMedidaDimRarosRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosRes.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosDimCalc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.rdDimCalc && this.rdDimCalc.checked) {
        this.termoSelecionado.emit(this.rdDimCalc.id);


        if (this.inMedidaDimRes && this.inMedidaDimRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRes.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitNodDiminutosDimNcNc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);
      this.termoSelecionado.emit(this.ckNodDiminutos.id);

      if (this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.termoSelecionado.emit(this.ckDimNcNc.id);


        if (this.inMedidaDimNcNc && this.inMedidaDimNcNc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimNcNc.nativeElement, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  public emitNodDiminutosLesaoDimInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);


      if (this.rdDimInc && this.rdDimInc.checked) {
        const obj = { id: this.rdDimInc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDim && this.inMedidaDim.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDim.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }
    }
  }

  public emitNodDiminutosLesaoDimRarosInc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);


      if (this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        const obj = { id: this.rdDimRarosInc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimRarosInc && this.inMedidaDimRarosInc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosInc.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

    }

  }

  public emitNodDiminutosLesaoDimRarosRes() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);


      if (this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        const obj = { id: this.rdDimRarosRes.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimRarosRes && this.inMedidaDimRarosRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRarosRes.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  public emitNodDiminutosLesaoDimCalc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);

      if (this.rdDimCalc && this.rdDimCalc.checked) {
        const obj = { id: this.rdDimCalc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimRes && this.inMedidaDimRes.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimRes.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }

  emitNodDiminutosLesaoDimNcNc() {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      const objeto = { id: this.ckNodDiminutos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
      this.lesoesSelecionadas.emit(objeto);

      if (this.ckDimNcNc && this.ckDimNcNc.checked) {
        const obj = { id: this.ckDimNcNc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);


        if (this.inMedidaDimNcNc && this.inMedidaDimNcNc.nativeElement.value) {
          const objeto = { elemento: this.inMedidaDimNcNc.nativeElement, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

    }
  }



  emitMassa() {
    if (this.ckMassaPul && this.ckMassaPul.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMassaPul.id);

      if (this.slComposicaoMassa && this.slComposicaoMassa.value) {
        const objeto = { elemento: this.slComposicaoMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoMassa && this.slContornoMassa.value) {
        const objeto = { elemento: this.slContornoMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboMassa && this.slLoboMassa.value) {
        const objeto = { elemento: this.slLoboMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegMassa && this.slSegMassa.value) {
        const objeto = { elemento: this.slSegMassa, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoMassa(true);
    }
  }

  emitMassaLesao() {
    if (this.ckMassaPul && this.ckMassaPul.checked) {
      this.countLesoes++;
      const obj = { id: this.ckMassaPul.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.slComposicaoMassa && this.slComposicaoMassa.value) {
        const objeto = { elemento: this.slComposicaoMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slContornoMassa && this.slContornoMassa.value) {
        const objeto = { elemento: this.slContornoMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboMassa && this.slLoboMassa.value) {
        const objeto = { elemento: this.slLoboMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slSegMassa && this.slSegMassa.value) {
        const objeto = { elemento: this.slSegMassa, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      this.formataMedindoMassa(false);
    }
  }

  emitMicronodulo() {
    if (this.ckMicronodulos && this.ckMicronodulos.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckMicronodulos.id);

      if (this.rdMicroCentro && this.rdMicroCentro.checked) {
        this.termoSelecionado.emit(this.rdMicroCentro.id);
      }
      
      if (this.rdMicroCentroEsp && this.rdMicroCentroEsp.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroEsp.id);
      }
      
      if (this.rdMicroCentroDif && this.rdMicroCentroDif.checked) {
        this.termoSelecionado.emit(this.rdMicroCentroDif.id);
      }
      
      if (this.rdMicroPeri && this.rdMicroPeri.checked) {
        this.termoSelecionado.emit(this.rdMicroPeri.id);
      }
      
      if (this.rdMicroPeriEsp && this.rdMicroPeriEsp.checked) {
        this.termoSelecionado.emit(this.rdMicroPeriEsp.id);
      }
      
      if (this.rdMicroPeriDif && this.rdMicroPeriDif.checked) {
        this.termoSelecionado.emit(this.rdMicroPeriDif.id);
      }
      
      if (this.rdMicroRanDif && this.rdMicroRanDif.checked) {
        this.termoSelecionado.emit(this.rdMicroRanDif.id);
      }

      if (this.slLoboMicro && this.slLoboMicro.value) {
        if (this.slSegMicro && this.slSegMicro.value) {
          const lobo = this.formataLobo(this.slLoboMicro.value);

          const elemento = { id: this.slLoboMicro.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboMicro, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.slSegMicro && this.slSegMicro.value) {
        const objeto = { elemento: this.slSegMicro, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboPreMicro && this.slLoboPreMicro.value) {
        const objeto = { elemento: this.slLoboPreMicro, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitMicronoduloLesao() {
    if (this.ckMicronodulos && this.ckMicronodulos.checked) {
      this.countLesoes++;
      const obj = { id: this.ckMicronodulos.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdMicroCentro && this.rdMicroCentro.checked) {
        const obj = { id: this.rdMicroCentro.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.rdMicroCentroEsp && this.rdMicroCentroEsp.checked) {
        const obj = { id: this.rdMicroCentroEsp.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.rdMicroCentroDif && this.rdMicroCentroDif.checked) {
        const obj = { id: this.rdMicroCentroDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.rdMicroPeri && this.rdMicroPeri.checked) {
        const obj = { id: this.rdMicroPeri.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.rdMicroPeriEsp && this.rdMicroPeriEsp.checked) {
        const obj = { id: this.rdMicroPeriEsp.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.rdMicroPeriDif && this.rdMicroPeriDif.checked) {
        const obj = { id: this.rdMicroPeriDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }
      
      if (this.rdMicroRanDif && this.rdMicroRanDif.checked) {
        const obj = { id: this.rdMicroRanDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slLoboMicro && this.slLoboMicro.value) {
        if (this.slSegMicro && this.slSegMicro.value) {
          const lobo = this.formataLobo(this.slLoboMicro.value);

          const elemento = { id: this.slLoboMicro.id, value: lobo };
          const objeto = { elemento: elemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        } else {
          const objeto = { elemento: this.slLoboMicro, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }


      if (this.slSegMicro && this.slSegMicro.value) {
        const objeto = { elemento: this.slSegMicro, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }


      if (this.slLoboPreMicro && this.slLoboPreMicro.value) {
        const objeto = { elemento: this.slLoboPreMicro, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitCongestao() {
    if (this.ckCongestao && this.ckCongestao.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCongestao.id);
    }
  }

  emitCongestaoLesao() {
    if (this.ckCongestao && this.ckCongestao.checked) {
      this.countLesoes++;
      const obj = { id: this.ckCongestao.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);
    }
  }

  emitBronquiectasias() {
    if (this.ckBronquiectasias && this.ckBronquiectasias.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckBronquiectasias.id);

      if (this.rdBronLoc && this.rdBronLoc.checked) {
        this.termoSelecionado.emit(this.rdBronLoc.id);

      } else if (this.rdBronDif && this.rdBronDif.checked) {
        this.termoSelecionado.emit(this.rdBronDif.id);

      } else if (this.rdBronSeq && this.rdBronSeq.checked) {
        this.termoSelecionado.emit(this.rdBronSeq.id);
      }

      if (this.slTipoBron && this.slTipoBron.value) {
        const objeto = { elemento: this.slTipoBron, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboBron && this.slLoboBron.value) {
        const objeto = { elemento: this.slLoboBron, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitBronquiectasiasLesao() {
    if (this.ckBronquiectasias && this.ckBronquiectasias.checked) {
      this.countLesoes++;
      const obj = { id: this.ckBronquiectasias.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdBronLoc && this.rdBronLoc.checked) {
        const obj = { id: this.rdBronLoc.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdBronDif && this.rdBronDif.checked) {
        const obj = { id: this.rdBronDif.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

      } else if (this.rdBronSeq && this.rdBronSeq.checked) {
        const obj = { id: this.rdBronSeq.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);
      }

      if (this.slTipoBron && this.slTipoBron.value) {
        const objeto = { elemento: this.slTipoBron, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }

      if (this.slLoboBron && this.slLoboBron.value) {
        const objeto = { elemento: this.slLoboBron, isPrimeiraLesao: false };
        this.termosGenericos.emit(objeto);
      }
    }
  }

  emitNodulo() {
    if (this.ckNodulo && this.ckNodulo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckNodulo.id);

      if (this.rdNodSup && this.rdNodSup.checked) {
        if (this.rd1NodSup && this.rd1NodSup.checked) {
          this.termoSelecionado.emit(this.rd1NodSup.id);

          if (this.slComposicao1Nod && this.slComposicao1Nod.value) {
            const objeto = { elemento: this.slComposicao1Nod, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slContorno1Nod && this.slContorno1Nod.value) {
            const objeto = { elemento: this.slContorno1Nod, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slLobo1NodSup && this.slLobo1NodSup.value) {
            const objeto = { elemento: this.slLobo1NodSup, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          if (this.slSeg1NodSup && this.slSeg1NodSup.value) {
            const objeto = { elemento: this.slSeg1NodSup, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }

          this.formataMedindoNodulo(true);
        } else if (this.rd2NodSup && this.rd2NodSup.checked) {
          this.termoSelecionado.emit(this.rd2NodSup.id);
          let posicao = 0;
          this.linha = 0;

          this.NodSuspChildren.forEach(obj => {
            if (obj.nativeElement) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }

              this.objetofilho = { id: 1681, value: obj.nativeElement.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            } else {

              if (+obj.id === 1679)
                this.linha++;

              if (obj.value) {
                this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
                this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
                this.lesoesDinamicas.emit(this.obj);
              }
            }
          });
        }
      } else if (this.rdNodMult && this.rdNodMult.checked) {
        this.termoSelecionado.emit(this.rdNodMult.id);

        if (this.slComposicaoNodMult && this.slComposicaoNodMult.value) {
          const objeto = { elemento: this.slComposicaoNodMult, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.slContornosNodMult && this.slContornosNodMult.value) {
          const objeto = { elemento: this.slContornosNodMult, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        let posicao = 0;
        this.linha = 0;

        this.NodMultChildren.forEach(obj => {
          if (obj.nativeElement) {
            if (posicao < 2) {
              posicao++;
            } else {
              posicao = 1;
            }

            this.objetofilho = { id: 1687, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1685)
              this.linha++;

            if (obj.value) {
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            }
          }
        });
      } else if (this.rdNodMet && this.rdNodMet.checked) {
        this.termoSelecionado.emit(this.rdNodMet.id);

        if (this.slMetContorno && this.slMetContorno.value) {
          const objeto = { elemento: this.slMetContorno, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        this.linha = 0;

        this.metastaseChildren.forEach(obj => {
          if (obj.nativeElement) {

            this.objetofilho = { id: 1692, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 1, isPrimeiraLesao: true };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1690)
              this.linha++;

            if (obj.value) {
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
              this.lesoesDinamicas.emit(this.obj);
            }
          }
        });
      }
    }

  }


  public emitNodDiminutosLesao(): any {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {


      if (this.countLesoes > 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosLesaoDimInc();
      } else if (this.countLesoes == 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosDimInc();
      }

      if (this.countLesoes > 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosLesaoDimRarosInc();
      } else if (this.countLesoes == 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosDimRarosInc();
      }


      if (this.countLesoes > 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosLesaoDimRarosRes();
      } else if (this.countLesoes == 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosDimRarosRes();
      }

      if (this.countLesoes > 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosLesaoDimCalc();
      } else if (this.countLesoes == 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosDimCalc();
      }

      if (this.countLesoes > 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosLesaoDimNcNc();
      } else if (this.countLesoes == 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosDimNcNc();
      }
    }


  }

  public emitNodDiminutos(): any {
    if (this.ckNodDiminutos && this.ckNodDiminutos.checked) {


      if (this.countLesoes > 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosLesaoDimInc();
      } else if (this.countLesoes == 0 && this.rdDimInc && this.rdDimInc.checked) {
        this.emitNodDiminutosDimInc();
      }

      if (this.countLesoes > 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosLesaoDimRarosInc();
      } else if (this.countLesoes == 0 && this.rdDimRarosInc && this.rdDimRarosInc.checked) {
        this.emitNodDiminutosDimRarosInc();
      }


      if (this.countLesoes > 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosLesaoDimRarosRes();
      } else if (this.countLesoes == 0 && this.rdDimRarosRes && this.rdDimRarosRes.checked) {
        this.emitNodDiminutosDimRarosRes();
      }

      if (this.countLesoes > 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosLesaoDimCalc();
      } else if (this.countLesoes == 0 && this.rdDimCalc && this.rdDimCalc.checked) {
        this.emitNodDiminutosDimCalc();
      }

      if (this.countLesoes > 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosLesaoDimNcNc();
      } else if (this.countLesoes == 0 && this.ckDimNcNc && this.ckDimNcNc.checked) {
        this.emitNodDiminutosDimNcNc();
      }
    }


  }

  emitNoduloLesao() {
    if (this.ckNodulo && this.ckNodulo.checked) {
      this.countLesoes++;
      const obj = { id: this.ckNodulo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(obj);

      if (this.rdNodSup && this.rdNodSup.checked) {
        if (this.rd1NodSup && this.rd1NodSup.checked) {
          const obj = { id: this.rd1NodSup.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(obj);

          if (this.slComposicao1Nod && this.slComposicao1Nod.value) {
            const objeto = { elemento: this.slComposicao1Nod, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          if (this.slContorno1Nod && this.slContorno1Nod.value) {
            const objeto = { elemento: this.slContorno1Nod, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          if (this.slLobo1NodSup && this.slLobo1NodSup.value) {
            const objeto = { elemento: this.slLobo1NodSup, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          if (this.slSeg1NodSup && this.slSeg1NodSup.value) {
            const objeto = { elemento: this.slSeg1NodSup, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }

          this.formataMedindoNodulo(false);

        } else if (this.rd2NodSup && this.rd2NodSup.checked) {
          const obj = { id: this.rd2NodSup.id, cenario: this.countLesoes };
          this.lesoesSelecionadas.emit(obj);

          let posicao = 0;
          this.linha = 0;

          this.NodSuspChildren.forEach(obj => {
            if (obj.nativeElement) {
              if (posicao < 3) {
                posicao++;
              } else {
                posicao = 1;
              }

              this.objetofilho = { id: 1681, value: obj.nativeElement.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.obj);
            } else {

              if (+obj.id === 1679)
                this.linha++;

              if (obj.value) {
                this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
                this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
                this.lesoesDinamicas.emit(this.obj);
              }
            }
          });
        }
      } else if (this.rdNodMult && this.rdNodMult.checked) {
        const obj = { id: this.rdNodMult.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

        if (this.slComposicaoNodMult && this.slComposicaoNodMult.value) {
          const objeto = { elemento: this.slComposicaoNodMult, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slContornosNodMult && this.slContornosNodMult.value) {
          const objeto = { elemento: this.slContornosNodMult, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        let posicao = 0;
        this.linha = 0;

        this.NodMultChildren.forEach(obj => {
          if (obj.nativeElement) {
            if (posicao < 2) {
              posicao++;
            } else {
              posicao = 1;
            }

            this.objetofilho = { id: 1687, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: posicao, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1685)
              this.linha++;

            if (obj.value) {
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.obj);
            }
          }
        });
      } else if (this.rdNodMet && this.rdNodMet.checked) {
        const obj = { id: this.rdNodMet.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(obj);

        if (this.slMetContorno && this.slMetContorno.value) {
          const objeto = { elemento: this.slMetContorno, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        this.linha = 0;

        this.metastaseChildren.forEach(obj => {
          if (obj.nativeElement) {

            this.objetofilho = { id: 1692, value: obj.nativeElement.value, cenario: this.countLesoes };
            this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 1, isPrimeiraLesao: false };
            this.lesoesDinamicas.emit(this.obj);
          } else {

            if (+obj.id === 1690)
              this.linha++;

            if (obj.value) {
              this.objetofilho = { id: obj.id, value: obj.value, cenario: this.countLesoes };
              this.obj = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: false };
              this.lesoesDinamicas.emit(this.obj);
            }
          }
        });
      }
    }

  }

  emitCistoAereo() {
    if (this.ckCistoAereo && this.ckCistoAereo.checked) {
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckCistoAereo.id);

      if (this.rdComSeg && this.rdComSeg.checked) {
        this.termoSelecionado.emit(this.rdComSeg.id);

        if (this.slLoboCisto && this.slLoboCisto.value) {
          if (this.slSegCisto && this.slSegCisto.value) {
            const lobo = this.formataLobo(this.slLoboCisto.value);

            const elemento = { id: this.slLoboCisto.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          } else {

            const objeto = { elemento: this.slLoboCisto, isPrimeiraLesao: true };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegCisto && this.slSegCisto.value) {
          const objeto = { elemento: this.slSegCisto, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaCisto && this.inMedidaCisto.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaCisto.nativeElement.value);
          const objetoElemento = { id: this.inMedidaCisto.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdEsparsos && this.rdEsparsos.checked) {
        this.termoSelecionado.emit(this.rdEsparsos.id);

        if (this.slLoboEspar && this.slLoboEspar.value) {
          const objeto = { elemento: this.slLoboEspar, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEspar && this.inMedidaEspar.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaEspar.nativeElement.value);
          const objetoElemento = { id: this.inMedidaEspar.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdSubpleural && this.rdSubpleural.checked) {
        this.termoSelecionado.emit(this.rdSubpleural.id);

        if (this.slLoboSub && this.slLoboSub.value) {
          const objeto = { elemento: this.slLoboSub, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaSub && this.inMedidaSub.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaSub.nativeElement.value);
          const objetoElemento = { id: this.inMedidaSub.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
  }

  emitCistoAereoLesao() {
    if (this.ckCistoAereo && this.ckCistoAereo.checked) {
      this.countLesoes++;
      const objetoCov = { id: this.ckCistoAereo.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objetoCov);

      if (this.rdComSeg && this.rdComSeg.checked) {
        const objetoCov = { id: this.rdComSeg.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboCisto && this.slLoboCisto.value) {
          if (this.slSegCisto && this.slSegCisto.value) {
            const lobo = this.formataLobo(this.slLoboCisto.value);

            const elemento = { id: this.slLoboCisto.id, value: lobo };
            const objeto = { elemento: elemento, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          } else {

            const objeto = { elemento: this.slLoboCisto, isPrimeiraLesao: false };
            this.termosGenericos.emit(objeto);
          }
        }

        if (this.slSegCisto && this.slSegCisto.value) {
          const objeto = { elemento: this.slSegCisto, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaCisto && this.inMedidaCisto.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaCisto.nativeElement.value);
          const objetoElemento = { id: this.inMedidaCisto.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdEsparsos && this.rdEsparsos.checked) {
        const objetoCov = { id: this.rdEsparsos.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboEspar && this.slLoboEspar.value) {
          const objeto = { elemento: this.slLoboEspar, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaEspar && this.inMedidaEspar.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaEspar.nativeElement.value);
          const objetoElemento = { id: this.inMedidaEspar.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

      if (this.rdSubpleural && this.rdSubpleural.checked) {
        const objetoCov = { id: this.rdSubpleural.id, cenario: this.countLesoes };
        this.lesoesSelecionadas.emit(objetoCov);

        if (this.slLoboSub && this.slLoboSub.value) {
          const objeto = { elemento: this.slLoboSub, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.inMedidaSub && this.inMedidaSub.nativeElement.value) {
          const comp = this._validacoes.formataDecimal(this.inMedidaSub.nativeElement.value);
          const objetoElemento = { id: this.inMedidaSub.nativeElement.id, value: comp, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

      }

    }
  }

  formataMedindoMassa(isPrimeiraLesao: boolean) {
    let medindoValor = '';
    let resultado = 0;

    if (this.inMedida1Massa && this.inMedida1Massa.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedida1Massa.nativeElement.value);


    if (this.inMedida2Massa && this.inMedida2Massa.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2Massa.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida2Massa.nativeElement.value);
    }

    if (this.inMedida3Massa && this.inMedida3Massa.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3Massa.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida3Massa.nativeElement.value);
    }

    //Esse else é necessario por que deve-se retirar o "cm" do texto da base de dados.
    medindoValor += ' cm'

    if ((this.inMedida3Massa && this.inMedida3Massa.nativeElement.value) &&
      (this.inMedida2Massa && this.inMedida2Massa.nativeElement.value) &&
      (this.inMedida1Massa && this.inMedida1Massa.nativeElement.value)) {

      resultado = this.inMedida1Massa.nativeElement.value * this.inMedida2Massa.nativeElement.value * this.inMedida3Massa.nativeElement.value * 0.523;
      this.volumeMassa = resultado.toFixed(1).toString();

      //Caso tenha as 3 medidas e tenha composição, mostre o volume.
      if (this.slComposicaoMassa && this.slComposicaoMassa.value) {
        if (this.inVolumeMassa.nativeElement.value) {
          medindoValor = medindoValor + '. Volume = ' + this._validacoes.formataDecimal(this.inVolumeMassa.nativeElement.value) + ' cm³';
        } else {
          medindoValor = medindoValor + '. Volume = ' + this._validacoes.formataDecimal(+resultado.toFixed(1)) + ' cm³';
        }
      }

    }

    if (medindoValor) {

      const objetoElemento = { id: 1704, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataMedindoNodulo(isPrimeiraLesao: boolean) {
    let medindoValor = '';

    if (this.inMedida1NodSup && this.inMedida1NodSup.nativeElement.value)
      medindoValor = this._validacoes.formataDecimal(this.inMedida1NodSup.nativeElement.value);


    if (this.inMedida2NodSup && this.inMedida2NodSup.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida2NodSup.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida2NodSup.nativeElement.value);
    }

    if (this.inMedida3NodSup && this.inMedida3NodSup.nativeElement.value) {
      if (medindoValor != '')
        medindoValor = medindoValor + ' x ' + this._validacoes.formataDecimal(this.inMedida3NodSup.nativeElement.value);
      else
        medindoValor = this._validacoes.formataDecimal(this.inMedida3NodSup.nativeElement.value);
    }

    if (medindoValor) {
      const objetoElemento = { id: 1675, value: medindoValor };
      const objetoLesao = { elemento: objetoElemento, isPrimeiraLesao: isPrimeiraLesao };
      this.termosGenericos.emit(objetoLesao);
    }
  }

  formataLobo(loboValue: string) {
    let lobo = loboValue.split('no');
    if (lobo[1])
      return lobo[1];
    else
      return lobo[0];
  }

  getArraySimples(id: string): any[] {
    if (id) {
      if (id === 'lobo superior direito')
        return this.loboSupDir;
      else if (id === 'lobo inferior direito')
        return this.loboInfDir;
      else if (id === 'lobo médio')
        return this.loboMedio;
      else if (id === 'lobo superior esquerdo')
        return this.loboSupEsq;
      else if (id === 'lobo inferior esquerdo')
        return this.loboInfEsq;
      else if (id === 'língula')
        return;
    }
  }

  getArray(id: string): any[] {
    if (id) {
      if (id === 'no lobo superior direito')
        return this.loboSupDir;
      else if (id === 'no lobo inferior direito')
        return this.loboInfDir;
      else if (id === 'no lobo médio')
        return this.loboMedio;
      else if (id === 'no lobo superior esquerdo')
        return this.loboSupEsq;
      else if (id === 'no lobo inferior esquerdo')
        return this.loboInfEsq;
      else if (id === 'na língula')
        return;
    }
  }

  ajustaArrayDinamico() {
    const arraySup = this.suspArray.length;
    for (let i = 0; i <= arraySup; i++) {
      const index = this.suspArray.length - 1
      if (index != 0) {
        this.suspArray.removeAt(index);
      }
    }
    this.suspArray.reset();

    const arrayMult = this.multArray.length;
    for (let i = 0; i <= arrayMult; i++) {
      const index = this.multArray.length - 1
      if (index != 0) {
        this.multArray.removeAt(index);
      }
    }
    this.multArray.reset();

    const arrayMet = this.metastaseArray.length;
    for (let i = 0; i <= arrayMet; i++) {
      const index = this.metastaseArray.length - 1
      if (index != 0) {
        this.metastaseArray.removeAt(index);
      }
    }
    this.metastaseArray.reset();
  }

  verificaAlterado() {
    if (!this.ckCovid.checked &&
      !this.ckAtelectasia.checked &&
      !this.ckInfarto.checked &&
      !this.ckEnfisema.checked &&
      !this.ckIntersticiopatia.checked &&
      !this.ckNodulo.checked &&
      !this.ckNodDiminutos.checked &&
      !this.ckMassaPul.checked &&
      !this.ckMicronodulos.checked &&
      !this.ckCongestao.checked &&
      !this.ckBronquiectasias.checked &&
      !this.ckCistoAereo.checked) {
      // Marca normal
      this.rdNormal.checked = true;
    }
  }

  public formatMultipleLobos(lobos: any) {
    let result: string = '';
    const arrLength = lobos.length;

    lobos.forEach(function (objArr, index) {

      if (arrLength > 1) {
        if (index > 0) {
          if (arrLength == index + 1)
            result = result + ' e ' + objArr;
          else
            result = result + ', ' + objArr;
        } else {
          result = objArr
        }
      } else {
        result = objArr
      }
    });

    return result;
  }
}
