import {  Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { UserHasTextTermService } from '../services/userHasTextTerm-service.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from '../services/user.service';
import { UserHasTextTerm } from '../models/BO/UserHasTextTerm';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ListaExamesService } from '../services/lista-exames.service';

@Component({
  selector: 'app-altera-laudo-list',
  templateUrl: './altera-laudo-list.component.html',
  styleUrls: ['./altera-laudo-list.component.css', '../app.component.css']
})

export class AlteraLaudoListComponent implements OnInit {
  maskSelected!:any;
  TextTermSelected: UserHasTextTerm = null;
  UserId: string;
  displayedColumns: string[] = ['textTermID', 'text', 'conclusion', 'active'];
  elementData: UserHasTextTerm[] = [];
  elementDataMask: any = [];
  dataSource = new MatTableDataSource<UserHasTextTerm> (this.elementData);
  dataMaskSource = new MatTableDataSource<any> (this.elementDataMask);
  public loading: boolean = true;
  editarTexto=true;
  editarMascara=false;
  listaDeExames;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private toastr: ToastrManager,
    private _userService: UserService,
    private _userHasTextTermService: UserHasTextTermService,
    private _http: HttpClient,
    private listaDeExame: ListaExamesService ) { }

  ngOnInit() {
    this.UserId = localStorage.getItem('userID');
    const _urlGetByUser = environment.nLaudoApi + "UserHasMask/GetAllMaskByUser/" + this.UserId + '/';
    this._userHasTextTermService.getUserHasTextTermByUser(this.UserId).subscribe(data => {
        this.elementData = data;
        this.dataSource = new MatTableDataSource<UserHasTextTerm> (this.elementData);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      });

      this._http.get(_urlGetByUser).toPromise().then((result)=>{
        this.elementDataMask= result;
        this.dataMaskSource = new MatTableDataSource<any> (this.elementDataMask);
        this.dataMaskSource.paginator = this.paginator;
        this.loading = false;
      });

      this.listaDeExame.getLaudos().toPromise().then(result=>{
        this.listaDeExames=result;
      });
  }

  selectTextTerm(term: UserHasTextTerm) {
    this.TextTermSelected = term;
  }

  selectMask(mask){
    this.maskSelected= mask;
  }

  deselectTextTerm() {
    this.TextTermSelected = null;
  }

  unselectMask(){
    this.maskSelected=null;
  }

  deleteMask(mask){
    const i = this.elementDataMask.indexOf(mask);
    const _urlGetByUser = environment.nLaudoApi + "UserHasMask/DeleteMaskByUser/" + this.UserId + '/' + mask.id;

    this.elementDataMask.splice(i, 1);
    this.dataMaskSource = new MatTableDataSource<UserHasTextTerm> (this.elementDataMask);
    this.unselectMask();
    this._http.delete(_urlGetByUser).subscribe();
  }

  deletarConfig(term: UserHasTextTerm){
    const index = this.elementData.indexOf(term);
    this.elementData.splice(index, 1);
    this.dataSource = new MatTableDataSource<UserHasTextTerm> (this.elementData);
    this.deselectTextTerm();
    this._userHasTextTermService.deleteUserHasTextTerm(this.UserId, term.id).subscribe();
  }

  getText(text:string):string {
    if(text.length<50){
      return text;
    }
    return text.substring(0,46)+" ...";
  }

  getExame(exame){
    let exameSelecionado = this.listaDeExames.filter((item)=>{
      return item.id === exame.medicalReportTypeID;
    })
    return exameSelecionado[0].title;
  }

  habilitarEdicaoMascara(){
    if(this.editarTexto){
      this.editarTexto=false;
      this.editarMascara=true;
    }
  }

  habilitarEdicaoCenario(){
    if(this.editarMascara){
      this.editarTexto=true;
      this.editarMascara=false;
    }
  }
}
