import { Component, OnInit } from '@angular/core';
import { EventEmitter } from 'events';

import EditorJS from '@editorjs/editorjs';
import Underline from '@editorjs/underline';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import CodeTool from '@editorjs/code';
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';

import Paragraph from 'editorjs-paragraph-with-alignment';
import Table from '@editorjs/table';
import DragDrop from 'editorjs-drag-drop';
import ImageTool from '@editorjs/image';

import { CommonService } from 'src/app/services/common.service';
const RawTool = require('@editorjs/raw');
const SimpleImage = require('@editorjs/simple-image');

interface IAdicionaRemoveHover {
  itemId: string;
  style: boolean;
  click?: boolean;
}

interface IAdicionaEstilo {
  buttonMenu?: HTMLElement;
  reportLine?: HTMLElement;
  reportLineD?: HTMLElement;
  reportLineE?: HTMLElement;
}

@Component({
  selector: 'app-text-editor-js',
  templateUrl: './text-editor-js.component.html',
  styleUrls: ['./text-editor-js.component.css']
})
export class TextEditorJsComponent implements OnInit {
  public editorJs: any;
  public laudoCompleto: any;

  constructor(private _common: CommonService) {
    this._common.recarregaDados.subscribe(blocks => {
      this.laudoCompleto = blocks;
      this.reinicaiaEditor();
    });
  }

  ngOnInit(): void {
    this._common.carregaDados.subscribe(blocks => (this.laudoCompleto = blocks));
    this.iniciarEditor();
  }

  iniciarEditor(): void {
    this.editorJs = new EditorJS({
      onReady: () => {
        new DragDrop(this.editorJs);
      },

      autofocus: true,
      inlineToolbar: true,

      tools: {
        code: CodeTool,
        raw: RawTool,
        imagee: SimpleImage,

        underline: {
          class: Underline,
          shortcut: 'CMD+SHIFT+h',
          config: {
            tagName: 'STRONG',
            placeholder: "opaTestando",
          }
        },

        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: 'https://app-nreportapi-03.azurewebsites.net/api/upload', // Your backend file uploader endpoint
              byUrl: 'https://app-nreportapi-03.azurewebsites.net/api/upload', // Your endpoint that provides uploading by Url
            }
          }
        },

        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },

        list: {
          class: List,
          inlineToolbar: true,
        },

        checklist: {
          class: Checklist,
          inlineToolbar: true,

          config: {
            checklistPlaceholder: "opaTestando",
          }
        },

        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',

          config: {
            placeholder: 'Insira uma citação',
            captionPlaceholder: 'Autor da citação',
          },
        },

        header: {
          class: Header,
          shortcut: 'CMD+SHIFT+H',
          inlineToolbar: true
        },

        marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+F',
        },

        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+ENTER',
        },
      },

      i18n: {
        messages: {
          ui: {
            'blockTunes': {
              'toggler': {
                'Click to tune': 'Clique para ajustar',
                'or drag to move': 'Ou segure para mover',
              }
            },

            'inlineToolbar': {
              'converter': {
                'Convert to': 'Converter para'
              },
            },

            'toolbar': {
              'toolbox': {
                'Add': 'Adicionar'
              },
            }
          },

          toolNames: {
            'Text': 'Texto',
            'Heading': 'Cabeçalho',
            'List': 'Lista',
            'Warning': 'Atenção',
            'Checklist': 'Lista de tarefas',
            'Quote': 'Citar',
            'Code': 'Código',
            'Delimiter': 'Delimitador',
            'Raw HTML': 'HTML cru',
            'Table': 'Tabela',
            'Link': 'Link',
            'Marker': 'Marcador',
            'Bold': 'Negrito',
            'Italic': 'Itálico',
            'InlineCode': 'Código embutido',
            'Underline': 'Sublinhado',
            'Image': 'Imagem',
          }
        }
      }
    });
  }

  reinicaiaEditor(): void {
    this.editorJs.isReady
      .then(() => {
        this.editorJs.blocks.render({
          blocks: this.laudoCompleto
        });
      }).catch((res) => {
        console.log(`${res}`);
      });

    setTimeout(() => {
      this.integracaoMenu(this.laudoCompleto);
      this.initHover();
    }, 500);

    this.removePadding();
  }

  integracaoMenu(blocks: Array<any>): void {
    const paragraphs = Array.from(document.getElementsByClassName('ce-block__content'));

    if (paragraphs != undefined) {
      paragraphs.forEach((elemento, index)  => {
        if (blocks[index].blockMenu != undefined) {
          elemento.firstElementChild.setAttribute('id', `${blocks[index].blockMenu.id}`);
        }
      });
    }

    this.adicionarHover();
  }

  initHover(): void {
    const isSelected = document.querySelector('.selected');
    const idSelected = isSelected.getAttribute('id');

    this.adicionaRemoveHover({ itemId: idSelected, style: true });
    this.adicionaRemoveHover({ itemId: idSelected, style: false });
  }

  adicionarHover(): void {
    const paragraphs = Array.from(document.getElementsByClassName('ce-paragraph'));
    const itensMenu = Array.from(document.getElementsByClassName('break-text'));

    if (paragraphs != undefined) {
      this.adicionaEventoElemento(paragraphs);
    }

    if(itensMenu != undefined) {
      this.adicionaEventoElemento(itensMenu);
    }
  }

  adicionaEventoElemento(itens: Array<any>): void {
    const caption = document.querySelector('.image-tool__caption');

    if(caption !== null)
      caption.setAttribute('style', 'display: none');

    itens.forEach(item => {
      const itemId = item.getAttribute('id');

      item.addEventListener('mouseover', () => {
        this.adicionaRemoveHover({ itemId, style: true });
        this.adicionaRemoveHoverBilateral({ itemId, style: true });
      });

      item.addEventListener('mouseout', () => {
        this.adicionaRemoveHover({ itemId, style: false });
        this.adicionaRemoveHoverBilateral({ itemId, style: false });
      });

      item.addEventListener('click', () => {
        this.adicionaClick(itemId);
      });

      item.addEventListener('blur', () => {
        this.removeEstiloLinha();
      });

      const tooltips = Array.from(document.getElementsByClassName('ct__content'));
      tooltips.forEach(tooltip => {
        tooltip.addEventListener('DOMSubtreeModified', (event) => {
          this.alterandoTooltip(event);
        });
      });
    });
  }

  alterandoTooltip(event): void {
    const element = event.target as HTMLElement;

    if(element.innerHTML === 'Move down') {
      element.innerHTML = 'Mover pra baixo';
    } else if(element.innerHTML === 'Move up') {
      element.innerHTML = 'Mover pra cima';
    } else if(element.innerHTML === 'Delete') {
      element.innerHTML = 'Apagar';
    }
  }

  adicionaClick(itemId: string): void {
    if (itemId !== null) {
      const [sideClick, itemHoverId] = itemId.split('-');

      let menuSwitch;
      if(sideClick === 'e') {
        menuSwitch = document.getElementById(`m-${itemHoverId}`);

        if(menuSwitch !== null)
          menuSwitch.dispatchEvent(new Event('click'));
      } else {
        menuSwitch = document.getElementById(`e-${itemHoverId}`);
        console.log(menuSwitch);
        if(menuSwitch !== null) {
          menuSwitch.focus();

          const focusElement = document.activeElement;

          if(focusElement.id == `e-${itemHoverId}`) {
            this.adicionaRemoveHover({
              itemId,
              style: true,
              click: true
            });
          }
        }
        //Tratamento para prever os exames Bilaterais
        else {
          this.adicionaClickBilateral(itemId);
        }
      }
    }
  }

  adicionaClickBilateral(itemId: string): void {
    const [, itemHoverId, lado] = itemId.split('-'); 

    const itemIdD = `e-${itemHoverId}-D`;
    const itemIdE = `e-${itemHoverId}-E`;
    let menuSwitchD = document.getElementById(itemIdD);
    let menuSwitchE = document.getElementById(itemIdE);

    if(menuSwitchD !== null) {
      const focusElement = document.activeElement;
      let itemId = itemIdE;
      if(focusElement.id == itemIdD) {
        this.adicionaRemoveHoverBilateral({
          itemId,
          style: true,
          click: true
        });
      }
    }

    if(menuSwitchE !== null) {
      const focusElement = document.activeElement;
      let itemId = itemIdE;
      if(focusElement.id == itemIdE) {
        this.adicionaRemoveHoverBilateral({
          itemId,
          style: true,
          click: true
        });
      }
    }
  }

  /**
   * @itemId Identifica qual bloco e/ou qual linha o mouse passou.
   * @style Adiciona ou remove estilo do(a) bloco/linha.
   * @click Troca o formulário de acordo com o item do menu ou linha vinculados.
  */
  adicionaRemoveHover({ itemId, style, click }: IAdicionaRemoveHover): void {
    if (itemId !== null) {
      const [, itemHoverId, lado] = itemId.split('-');
      let reportLine;
      const buttonMenu = document.getElementById(`m-${itemHoverId}`);
      if(lado !== undefined)
        reportLine = document.getElementById(`e-${itemHoverId}-${lado}`);
      else 
        reportLine = document.getElementById(`e-${itemHoverId}`);

      if (reportLine) {
        if(click !== undefined) {
          this.removeEstiloLinha();
  
          setTimeout(() => {
            this.adicionaEstilo({ reportLine });
          }, 100);
        } else if (click === undefined || !click) {
          if (buttonMenu !== null && reportLine !== null) {
            const isSelected = buttonMenu.classList.contains('selected');
  
            if (style && !isSelected) {
              this.adicionaEstilo({ reportLine, buttonMenu });
            } else if (!style) {
              buttonMenu.classList.remove('line-mouse-over');
              this.removeEstiloLinha();
            } else if (style && isSelected) {
              this.adicionaEstilo({ reportLine, buttonMenu });
              this.adicionaEstilo({ reportLine });
            }
          } else if (reportLine === null && style) {
            this.adicionaEstilo({ buttonMenu });
          } else if (reportLine === null && !style) {
            buttonMenu.classList.remove('line-mouse-over');
          }
        }
      }
    }
  }

  adicionaRemoveHoverBilateral({ itemId, style, click }: IAdicionaRemoveHover): void {
    if (itemId !== null) {
      const [, itemHoverId, lado] = itemId.split('-');
      const buttonMenu = document.getElementById(`m-${itemHoverId}`);

      let reportLineD = document.getElementById(`e-${itemHoverId}-D`);
      let reportLineE = document.getElementById(`e-${itemHoverId}-E`);
      
      if (reportLineD || reportLineE) {
        if(click !== undefined) {
          this.removeEstiloLinha();
  
          setTimeout(() => {
            this.adicionaEstilo({ reportLineD, reportLineE });
          }, 100);
        } else if (click === undefined || !click) {
          if (buttonMenu !== null && reportLineD !== null && reportLineE !== null) {
            const isSelected = buttonMenu.classList.contains('selected');
  
            if (style && !isSelected) {
              this.adicionaEstilo({ reportLineD, reportLineE, buttonMenu});
            } else if (!style) {
              buttonMenu.classList.remove('line-mouse-over');
              this.removeEstiloLinha();
            } else if (style && isSelected) {
              this.adicionaEstilo({ reportLineD, reportLineE, buttonMenu });
              this.adicionaEstilo({ reportLineD, reportLineE });
            }
          } else if (reportLineD === null && reportLineE === null && style) {
            this.adicionaEstilo({ buttonMenu });
          } else if (reportLineD === null && reportLineE === null && !style) {
            buttonMenu.classList.remove('line-mouse-over');
          }
        }
      }
    }
  }

  removeEstiloLinha(lado = null): void {
    let removeStyle: HTMLElement;
    let removeStyleD: HTMLElement;
    let removeStyleE: HTMLElement;

    const itensMenu = Array.from(document.getElementsByClassName('break-text'));

    itensMenu.forEach(button => {
      const selected = button.classList.contains('selected');

      if(!selected) {
        const [, itemRemoveStyle] = button.getAttribute('id').split('-');
        removeStyle = document.getElementById(`e-${itemRemoveStyle}`);
        removeStyleD = document.getElementById(`e-${itemRemoveStyle}-D`);
        removeStyleE = document.getElementById(`e-${itemRemoveStyle}-E`);

        if(removeStyle !== undefined && removeStyle !== null) {
          removeStyle.parentElement.parentElement.style.removeProperty('background');
          removeStyle.parentElement.parentElement.style.removeProperty('border-left');
        }

        if(removeStyleD !== undefined && removeStyleD !== null) {
          removeStyleD.parentElement.parentElement.style.removeProperty('background');
          removeStyleD.parentElement.parentElement.style.removeProperty('border-left');
        }

        if(removeStyleE !== undefined && removeStyleE !== null) {
          removeStyleE.parentElement.parentElement.style.removeProperty('background');
          removeStyleE.parentElement.parentElement.style.removeProperty('border-left');
        }
      }
    });
  }

  /**
   * @reportLine Adiciona o estilo da linha.
   * @buttonMenu Adiciona o estilo do button.
  */
  adicionaEstilo({  reportLineD, reportLineE, reportLine, buttonMenu }: IAdicionaEstilo): void {
    this.removeEstiloLinha();
    if(reportLine !== undefined) {
      reportLine.parentElement.parentElement.style.background = '#f2f2f2';
      reportLine.parentElement.parentElement.style.borderLeft = '3px solid #54c5ce';
    }

    if(reportLineD !== undefined) {
      reportLineD.parentElement.parentElement.style.background = '#f2f2f2';
      reportLineD.parentElement.parentElement.style.borderLeft = '3px solid #54c5ce';
    }

    if(reportLineE !== undefined) {
      reportLineE.parentElement.parentElement.style.background = '#f2f2f2';
      reportLineE.parentElement.parentElement.style.borderLeft = '3px solid #54c5ce';
    }

    if(buttonMenu !== undefined) {
      buttonMenu.classList.add('line-mouse-over');
    }
  }

  removePadding(): void {
    const codexEditorRedactors = Array.from(document.getElementsByClassName('codex-editor__redactor'));

    codexEditorRedactors.forEach(codexEditorRedactor => {
      codexEditorRedactor.removeAttribute('style');
      codexEditorRedactor.setAttribute('style', 'padding-bottom: 30px;');
    });
  }
}
