import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { finalize } from 'rxjs/operators';
import { EnderecoPagamento, Pagamento } from '../models/BO/Pagamento';
import { PagamentoService } from '../services/pagamento.service';
import { StatusPagamentoService } from '../services/status-pagamento.service';
import { AlertDialogComponent } from '../utils/dialog/alert-dialog/alert-dialog.component';
import { CardLabel, FormLabel } from 'ngx-interactive-paycard';
import { ViaCepServiceService } from '../services/via-cep-service.service';
import { Endereco } from '../models/BO/Endereco';
import { Router } from '@angular/router';
import { Validacoes } from '../utils/validacoes';
@Component({
  selector: 'app-altera-pagamento',
  templateUrl: './altera-pagamento.component.html',
  styleUrls: ['./altera-pagamento.component.css', '../app.component.css']
})
export class AlteraPagamentoComponent implements OnInit {

  @ViewChild('inNome') inNome: ElementRef;
  @ViewChild('cardNumber') cardNumber: ElementRef;
  @ViewChild('inExpiracao') inExpiracao: ElementRef;
  @ViewChild('inCVV') inCVV: ElementRef;
  @ViewChild('inCPF') inCPF: ElementRef;

  @ViewChild('inEndRua') inEndRua: ElementRef;
  @ViewChild('inEndNumero') inEndNumero: ElementRef;
  @ViewChild('inEndComplem') inEndComplem: ElementRef;
  @ViewChild('inEndBairro') inEndBairro: ElementRef;
  @ViewChild('inEndCidade') inEndCidade: ElementRef;
  @ViewChild('inEndEstado') inEndEstado: ElementRef;
  @ViewChild('inEndPais') inEndPais: ElementRef;
  @ViewChild('inEndCEP') inEndCEP: ElementRef;

  @Output() retornoPagamento = new EventEmitter();
  @Output() mostrarPagamento = new EventEmitter();

  retornoPag: any;
  cartaoHash: string;
  public loading: boolean = true;
  public formPagamentos: FormGroup;
  public enderecoForm: FormGroup;
  public formSubmitAttempt: Boolean;
  endereco: Endereco;

  cardNumberFormat = "#### #### #### ####";
  cardNumberMask = "#### **** **** ####";

  cardLabel: CardLabel = {
    expires: 'Válido até',
    cardHolder: 'Nome do titular',
    fullName: 'Nome completo',
    mm: 'MM',
    yy: 'AA'
  };

  formLabel: FormLabel = {
    cardNumber: 'Número do cartão',
    cardHolderName: 'Titular do cartão',
    expirationDate: 'Data de expiração',
    expirationMonth: 'Mês',
    expirationYear: 'Ano',
    cvv: 'CVV',
    submitButton: 'Enviar',
  };


  constructor(
    private _servicePagamentos: PagamentoService,
    private _statusPgto: StatusPagamentoService,
    private toastr: ToastrManager,
    private _dialog: MatDialog,
    private fb: FormBuilder,
    private _viaCEPService: ViaCepServiceService,
    private _route: Router,
    private utils: Validacoes,
  ) { }

  ngOnInit() {
    this.enderecoForm = this.fb.group({
      CPF: ['', [
        <any>Validators.required,
        Validators.maxLength(14),
        Validators.minLength(11)]
      ],
      CEP: ['', [Validators.required,
      ]
      ],
      Endereco: [''],
      Numero: [''],
      Bairro: [''],
      Complemento: [''],
      Cidade: [''],
      Estado: [''],
      Pais: [''],
    });

    this.formPagamentos = this.fb.group({
      cardNumber: ['', [Validators.required,
      Validators.maxLength(20),
      Validators.minLength(14)]],
      cardNome: ['', Validators.required],
      exp: ['', Validators.required],
      cvv: ['', [Validators.required,
      Validators.maxLength(3),
      Validators.minLength(3)]],
      cep: ['', [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8)
      ]],
      rua: ['', Validators.required],
      numero: ['', [Validators.required,
      Validators.maxLength(10)]],
      complemento: [null],
      distrito: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      paises: ['', Validators.required],
    });
    this.loading = false;
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formPagamentos.get(field).valid && this.formPagamentos.get(field).touched) ||
      (this.formPagamentos.get(field).untouched && this.formSubmitAttempt)
    );
  }

  criptografarCartao() {
    const cartaoSemEspaco = this.cardNumber.toString().replace(/\s/g, '');
    if (cartaoSemEspaco) {
      this._servicePagamentos.getCartaoCriptografado(cartaoSemEspaco).subscribe(
        retorno => {
          this.cartaoHash = retorno.number_token;
        }, error => {
          console.log(error);
        }
      );
    }
  };

  async clickConfirmar() {
    let pag = this.populaDadosForm();
    // console.log("oi 2")
    // console.log(JSON.stringify(pag));
    // this.loading = true;
    // this._servicePagamentos.AlteraPagamentoSafeBox(pag)
    //   .pipe(finalize(() => this.loading = false))
    //   .subscribe(
    //     retPgto => {
    //       if (retPgto) {
    //         this.retornoPag = retPgto;
    //         this._statusPgto.emitStatusPgto(this.retornoPag.statusID);
    //         this.retornoPagamento.emit(this.retornoPag.statusID);
    //       }
    //     },
    //     erro => {
    //       this.toastr.errorToastr('Erro ao processar pagamento', 'Desculpe',
    //         {
    //           position: 'top-right',
    //           toastTimeout: 4000
    //         }
    //       );
    //     }
    //   );



  }

  public populaDadosForm() {


    let pag = new Pagamento();
    // this.cartaoHash = await this.criptografarCartao();

    const cartaoSemEspaco = this.cardNumber.toString().replace(/\s/g, '');
    if (cartaoSemEspaco) {
      this._servicePagamentos.getCartaoCriptografado(cartaoSemEspaco).subscribe(
        retorno => {
          this.cartaoHash = retorno.number_token;
          if (!pag) return;
          this.formPagamentos.patchValue({
            cvv: pag.CVV = this.inCVV.toString(),
            card: pag.CardHash = this.cartaoHash,
            cardNome: pag.CardName = this.inNome.toString(),
            exp: this.inExpiracao.toString(),
            expMo: pag.ExpMonth = this.inExpiracao.toString().substring(0, 2),
            expYe: pag.ExpYear = this.inExpiracao.toString().substring(4, 6),
            user: pag.UserID = Number(localStorage.getItem('userID')),
            cpf: pag.CPF = this.inCPF.nativeElement.value.replace(/\D/g, ''),
            addre: pag.AddressUser = new EnderecoPagamento(),
            rua: pag.AddressUser.address = this.inEndRua.nativeElement.value,
            numero: pag.AddressUser.number = +this.inEndNumero.nativeElement.value,
            complemento: pag.AddressUser.complement = this.inEndComplem.nativeElement.value,
            distrito: pag.AddressUser.district = this.inEndBairro.nativeElement.value,
            cidade: pag.AddressUser.city = this.inEndCidade.nativeElement.value,
            estado: pag.AddressUser.state = this.inEndEstado.nativeElement.value,
            paises: pag.AddressUser.country = this.inEndPais.nativeElement.value,
            cep: pag.AddressUser.zipCode = this.inEndCEP.nativeElement.value.replace(/\D/g, ''),
          });

          //Ajustar depois

          this.loading = true;
          this._servicePagamentos.AlteraPagamentoSafeBox(pag)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
              retPgto => {
                this.toastr.successToastr('Seus dados foram atualizados com sucessso', 'Sucesso',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
                localStorage.setItem('payment', 'false'); //Seta regra de inclusão do cartão
                this._route.navigate(["/laudos"]);
              },
              erro => {
                this.toastr.errorToastr('Erro ao atualizar dados', 'Desculpe',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              }
            );


          return pag;
        }, error => {
          console.log(error);
        }
      );
    }


  }

  espacamentoCartao(value: string) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length > 0) {
      this.cardNumber.nativeElement.value = parts.join(' ');
    } else {
      this.cardNumber.nativeElement.value = value;
    }
  }

  formatDataExp(event) {
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );
  }
  alteraPagamento() {
    const pag = this.populaDadosForm();

    //this.loading = true;
    /*
    this._servicePagamentos.alteraPagamento(pag)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        retPgto => {
          if (retPgto) {
            this.retornoPag = retPgto;
            this._statusPgto.emitStatusPgto(this.retornoPag.statusID);
            this.retornoPagamento.emit(this.retornoPag.statusID);
          }
        },
        erro => {
          console.log(erro)
          this.toastr.errorToastr('Erro ao processar requisição tente novamente', 'Desculpe',
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
        }
      );
      */
  }

  fechar() {
    return this.mostrarPagamento.emit(false);
  }

  alertStatusMensage(mensage: string) {
    const dialogRef = this._dialog.open(AlertDialogComponent, {
      width: '450px',
      data: mensage
    });
  }

  verificaCEP(zipcode: string): boolean {
    if (zipcode.length === 8) {
      return true;
    }

    return false;
  }

  buscaCEP(cep: string) {
    cep = cep.replace(/\D/g, "");

    this.formPagamentos.patchValue({
      CEP: cep
    });

    if (cep !== '') {
      if (this.verificaCEP(cep)) {
        // this.toastr.infoToastr('Buscando CEP...',"CEP OK!!!");
        this._viaCEPService.getEnderecoPorCEP(cep)
          .subscribe(
            address => {
              if (address.erro === true) {
                this.endereco = undefined;
                this.toastr.errorToastr('CEP não encontrado', 'Desculpe',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
                this.limpaDadosEndereco();
              } else {
                this.endereco = address;
                this.populaDadosFormCEP(address);
                this.toastr.successToastr('Endereço encontrado', 'Tudo OK',
                  {
                    position: 'top-right',
                    toastTimeout: 4000
                  }
                );
              }
            },
            error => {
              this.toastr.errorToastr('Ha algo de errado no CEP digitado.', 'Desculpe',
                {
                  position: 'top-right',
                  toastTimeout: 4000
                }
              );
              this.endereco = undefined;
              this.limpaDadosEndereco();
            }
          );
      } else {
        this.toastr.errorToastr('Digite um CEP válido', 'Desculpe',
          {
            position: 'top-right',
            toastTimeout: 4000
          }
        );
        this.endereco = undefined;
      }
    }
  }

  limpaDadosEndereco() {
    this.formPagamentos.patchValue({
      rua: null,
      numero: null,
      complemento: null,
      distrito: null,
      cidade: null,
      estado: null,
      paises: null,
      // cep: null
    });
  }

  populaDadosFormCEP(dados: Endereco) {
    this.enderecoForm.patchValue({

      Endereco: dados.logradouro,
      Bairro: dados.bairro,
      Cidade: dados.localidade,
      Estado: dados.uf,
      Pais: 'Brasil',
      // cep: dados.cep
    });
  }

  maskCep(cep: string) {
    cep = cep.replace(/\D/g, "");
    cep = cep.replace(/[^\w\s]/gi, "");

    this.inEndCEP.nativeElement.value = cep;
  }

  onSubmitEvent($event) {
    this.inNome = $event.cardName;
    this.cardNumber = $event.cardNumber
    this.inCVV = $event.cvv;
    this.inExpiracao = $event.expirationMonth + $event.expirationYear;
    this.clickConfirmar();
  }

  showChangesCard($event) {
    // any changes on card (number, name, month, year, cvv)
    // console.log($event);
  }

  showChangesCardNumber($event) {
    // any changes on card number
    // console.log($event);
  }

  checkCPF(): boolean {
    let CPF = this.enderecoForm.get('CPF').value;
    if (!this.utils.validarCPF(CPF)) {
      this.toastr.errorToastr('CPF inválido', 'Falhou!!',
        {
          positionClass: 'toast-top-right',
          timeOut: 4000
        }
      );
      this.enderecoForm.patchValue({
        CPF: null
      });
      return false;
    } else {
      return true;
    }
  }
}
