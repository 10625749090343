import { Component, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-superficie-ossea-pe',
  templateUrl: './superficie-ossea-pe.component.html',
  styleUrls: ['./superficie-ossea-pe.component.css']
})
export class SuperficieOsseaPeComponent implements OnInit {
 
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesDinamicas = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdOsteosofitos') rdOsteosofitos: MatRadioButton;

  @ViewChildren('superficieOsseaChildren') superficieOsseaChildren: QueryList<any>;

  articulacoes: ArrayDropDown[] = [
    { value: 'metatarsofalângica', viewValue: 'Metatarsofalângica' },
    { value: 'interfalângica proximal', viewValue: 'Interfalângica proximal' },
    { value: 'interfalângica distal', viewValue: 'Interfalângica distal' },
    { value: 'interfalângica', viewValue: 'Interfalângica' }
  ];

  dedos: ArrayDropDown[] = [
    { value: 'hálux', viewValue: 'hálux' },
    { value: '2° dedo', viewValue: '2° dedo' },
    { value: '3° dedo', viewValue: '3° dedo' },
    { value: '4° dedo', viewValue: '4° dedo' },
    { value: '5° dedo', viewValue: '5° dedo' }
  ];

  showAndHideTerms: any;
  lesoesForm: FormGroup;
  linha: number;
  countLesoes: number;
  objeto: any;
  objetofilho: any;


  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahOsteosofitos: true
    };
    this.lesoesForm = this._fb.group({
      superficieOssea: this._fb.array([this.addSuperficieOssea()]),
    });
  }

  //Dinamico
  addSuperficieOssea() {
    return this._fb.group({
      articulacao: [],
      dedo: []
    });
  }

  addSuperficie() {
    this.superficieOsseaArray.push(this.addSuperficieOssea());
    this.catchIDs();
  }

  get superficieOsseaArray() {
    return <FormArray>this.lesoesForm.get('superficieOssea');
  }

  removeSuperficie(index) {
    this.superficieOsseaArray.removeAt(index);
    //Usado para formatar a remoção de item do dinamico
    setTimeout(() => {
      this.catchIDs();
    }, 1000);
  }
  
  clickNaoCitar() {
    this.showAndHideTerms.sahOsteosofitos = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }


  clickNormal() {
    this.showAndHideTerms.sahOsteosofitos = false;
    this.ajustaArrayDinamico();
    this.catchIDs();
  }

  clickOsteosofitos() {
    this.showAndHideTerms.sahOsteosofitos = true;
    this.catchIDs();
  }


  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }

  verificaLesoes(){
    if((this.superficieOsseaArray.value[0].articulacao || this.superficieOsseaArray.value[0].dedo)){
      this.rdOsteosofitos.checked = true;
    }
  }
  catchIDs() {
    this.termoSelecionado.emit('Clean')

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.value);
    }

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdOsteosofitos.checked) {
      this.termoSelecionado.emit(this.rdOsteosofitos.value);
      this.verificaSuperficie();
    }

    this.listaCompleta.emit();
  }

  verificaSuperficie() {
    this.linha = 0;
    this.superficieOsseaChildren.forEach(obj => {

      if(+obj.id === 1758 ) this.linha++;

      this.objetofilho = { id: obj.id, value: obj.value, cenario: 1 };
      this.objeto = { elemento: this.objetofilho, linha: this.linha, posicao: 0, isPrimeiraLesao: true };
      this.lesoesDinamicas.emit(this.objeto);
    });
  }

  ajustaArrayDinamico() {
    if ((this.rdOsteosofitos && !this.rdOsteosofitos.checked)) {
      const arrayTotal = this.superficieOsseaArray.length;
      for (let i  = 0; i <= arrayTotal; i++) {
        const index = this.superficieOsseaArray.length - 1
        if (index != 0) {
          this.superficieOsseaArray.removeAt(index);
        }
      }
      this.superficieOsseaArray.reset();
    }
  }

}
