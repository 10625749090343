import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatRadioButton, MatSelect } from '@angular/material';

@Component({
  selector: 'app-lesoes-osseas',
  templateUrl: './lesoes-osseas.component.html',
  styleUrls: ['./lesoes-osseas.component.css']
})
export class LesoesOsseasComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdAusentes') rdAusentes: MatRadioButton;
  @ViewChild('rdPresentes') rdPresentes: MatRadioButton;
  @ViewChild('ckLesaoLitica') ckLesaoLitica: MatCheckbox;
  @ViewChild('ckLesaoEsclerotica') ckLesaoEsclerotica: MatCheckbox;

  showAndHideTerms: any;
  countLesoes = 0;

  nivelLesaoLiticaFormGroup: FormGroup;
  nivelLesaoLitica:any[];
  nivelLesaoEscleroticaFormGroup: FormGroup;
  nivelLesaoEsclerotica:any[];
  isDisabled=true;
  constructor(private fb:FormBuilder) { }

  ngOnInit() {

    this.iniciaFormLesoesLiticas();
    this.iniciaFormLesoesEsclerotica();

    this.nivelLesaoLitica=[
      { id:'C7', value: ' C7', name: 'C7' },
      { id:'T1', value: ' T1', name: 'T1' },
      { id:'T2', value: ' T2', name: 'T2' },
      { id:'T3', value: ' T3', name: 'T3' },
      { id:'T4', value: ' T4', name: 'T4' },
      { id:'T5', value: ' T5', name: 'T5' },
      { id:'T6', value: ' T6', name: 'T6' },
      { id:'T7', value: ' T7', name: 'T7' },
      { id:'T8', value: ' T8', name: 'T8' },
      { id:'T9', value: ' T9', name: 'T9' },
      { id:'T10', value: ' T10', name: 'T10' },
      { id:'T11', value: ' T11', name: 'T11' },
      { id:'T12', value: ' T12', name: 'T12' },
      { id:'L1', value: ' L1', name: 'L1' }
    ];
    this.nivelLesaoEsclerotica=[
      { id:'C7E', value: ' C7', name: 'C7' },
      { id:'T1E', value: ' T1', name: 'T1' },
      { id:'T2E', value: ' T2', name: 'T2' },
      { id:'T3E', value: ' T3', name: 'T3' },
      { id:'T4E', value: ' T4', name: 'T4' },
      { id:'T5E', value: ' T5', name: 'T5' },
      { id:'T6E', value: ' T6', name: 'T6' },
      { id:'T7E', value: ' T7', name: 'T7' },
      { id:'T8E', value: ' T8', name: 'T8' },
      { id:'T9E', value: ' T9', name: 'T9' },
      { id:'T10E', value: ' T10', name: 'T10' },
      { id:'T11E', value: ' T11', name: 'T11' },
      { id:'T12E', value: ' T12', name: 'T12' },
      { id:'L1E', value: ' L1', name: 'L1' }
    ];

    this.showAndHideTerms = {
      sahLesaoLitica: false,
      sahLesaoEsclerotica:false
    }
  }

  clickDefault(){
    this.catchIDs();
  }
  clickAusentes(){
    this.showAndHideTerms.sahLesaoLitica=false;
    this.showAndHideTerms.sahLesaoEsclerotica=false;
    this.ckLesaoEsclerotica.checked=false;
    this.ckLesaoLitica.checked=false;
    this.catchIDs();
  }

  clickLesaoLitica(){
    this.showAndHideTerms.sahLesaoLitica = !this.showAndHideTerms.sahLesaoLitica;
    this.catchIDs();
  }

  clickLesaoEsclerotica(){
    this.showAndHideTerms.sahLesaoEsclerotica = !this.showAndHideTerms.sahLesaoEsclerotica;
    this.catchIDs();
  }

  clickCheckboxLesoesLiticas(event) {
      let nivelLesaoLitica = <FormArray>this.nivelLesaoLiticaFormGroup.get('nivelLesaoLitica') as FormArray;

      if (event.checked) {
        nivelLesaoLitica.push(new FormControl(event.source.value));
      } else {
        let iNivel = nivelLesaoLitica.controls.findIndex(x => x.value === event.source.value);

        nivelLesaoLitica.removeAt(iNivel);
      }

    this.catchIDs();
  }

  clickCheckboxLesoesEsclerotica(event) {
    let nivelLesaoEsclerotica = <FormArray>this.nivelLesaoEscleroticaFormGroup.get('nivelLesaoEsclerotica') as FormArray;

    if (event.checked) {
      nivelLesaoEsclerotica.push(new FormControl(event.source.value));
    } else {
      let iNivel = nivelLesaoEsclerotica.controls.findIndex(x => x.value === event.source.value);

      nivelLesaoEsclerotica.removeAt(iNivel);
    }

  this.catchIDs();
  }

  iniciaFormLesoesLiticas() {
    this.nivelLesaoLiticaFormGroup = this.fb.group({
      nivelLesaoLitica: this.fb.array([])
    });
  }

  iniciaFormLesoesEsclerotica() {
    this.nivelLesaoEscleroticaFormGroup = this.fb.group({
      nivelLesaoEsclerotica: this.fb.array([])
    });
  }

  changeSelect(){
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    if(this.ckLesaoEsclerotica.checked || this.ckLesaoLitica.checked){
      this.rdPresentes.checked=true;
      this.rdAusentes.checked=false;
    }

    if (!this.ckLesaoLitica.checked) {
      this.iniciaFormLesoesLiticas();
    }
    if (!this.ckLesaoEsclerotica.checked) {
      this.iniciaFormLesoesEsclerotica();
    }

    if (this.countLesoes > 0) {
      this.emitLesaoLiticaLesao();
    } else {
      this.emitLesaoLiticaSimples();
    }
    if (this.countLesoes > 0) {
      this.emitLesaoEscleroticaLesao();
    } else {
      this.emitLesaoEscleroticaSimples();
    }
    if(!this.ckLesaoEsclerotica.checked && !this.ckLesaoLitica.checked){
      this.termoSelecionado.emit(this.rdAusentes.id);
      this.rdAusentes.checked=true;
      this.rdPresentes.checked=false;
    }

    this.listaCompleta.emit();
  }

  emitLesaoLiticaSimples(){
    if(this.ckLesaoLitica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesaoLitica.id);

      let posicao: any = this.nivelLesaoLiticaFormGroup.value.nivelLesaoLitica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){

          let objeto = {id: 5051, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

        }else if(posicao.length > 1){
          let objeto = {id: 5051, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

          let objetoMultiplo= {id:5052, value:5052};
          let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
          this.termosGenericos.emit(elementoMultiplo);
        }
      }
    }
  }

  emitLesaoLiticaLesao(){
    if(this.ckLesaoLitica.checked){
      this.countLesoes++;
      const objeto = { id: this.ckLesaoLitica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.nivelLesaoLiticaFormGroup.value.nivelLesaoLitica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){
        let objeto = {id: 5051, value: posicao};
        let elemento = {elemento: objeto, isPrimeiraLesao: false};
        this.termosGenericos.emit(elemento);

        }else if(posicao.length > 1){
          let objeto = {id: 5051, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: false};
          this.termosGenericos.emit(elemento);

          let objetoMultiplo = { id:5052, value:5052};
          let elementoMultiplo = { elemento:objetoMultiplo, isPrimeiraLesao: false};
          this.termosGenericos.emit(elementoMultiplo);
        }
      }
    }
  }

  emitLesaoEscleroticaSimples(){
    if(this.ckLesaoEsclerotica.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckLesaoEsclerotica.id);

      let posicao = this.nivelLesaoEscleroticaFormGroup.value.nivelLesaoEsclerotica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){
          let objeto = {id: 5054, value: posicao};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

        }else if(posicao.length > 1){
          let objeto = {id: 5054, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: true};
          this.termosGenericos.emit(elemento);

          let objetoMultiplo= {id:5055, value:5055};
          let elementoMultiplo ={elemento:objetoMultiplo, isPrimeiraLesao: true};
          this.termosGenericos.emit(elementoMultiplo);
        }
      }
    }
  }

  emitLesaoEscleroticaLesao(){
    if(this.ckLesaoEsclerotica.checked){
      this.countLesoes++;
      const objeto = { id: this.ckLesaoEsclerotica.id, cenario: this.countLesoes };
      this.lesoesSelecionadas.emit(objeto);

      let posicao = this.nivelLesaoEscleroticaFormGroup.value.nivelLesaoEsclerotica;
      let posicaoTratada = this.replaceVirgula(posicao.toString());

      if(posicao.length > 0){
        if(posicao.length == 1){
        let objeto = {id: 5054, value: posicao};
        let elemento = {elemento: objeto, isPrimeiraLesao: false};
        this.termosGenericos.emit(elemento);

        }else if(posicao.length > 1){
          let objeto = {id: 5054, value: posicaoTratada};
          let elemento = {elemento: objeto, isPrimeiraLesao: false};
          this.termosGenericos.emit(elemento);

          let objetoMultiplo = { id:5055, value:5055};
          let elementoMultiplo = { elemento:objetoMultiplo, isPrimeiraLesao: false};
          this.termosGenericos.emit(elementoMultiplo);
        }
      }
    }
  }

  replaceVirgula(string){
    const lastIndex = string.lastIndexOf(',');

    const replacement = ' e ';

    let replaced;

    if (lastIndex !== -1) {
      replaced = string.substring(0, lastIndex) +replacement + string.substring(lastIndex + 1);
    }
    return replaced;
  }
}

