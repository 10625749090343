import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-pele-tecido-tornozelo',
  templateUrl: './pele-tecido-tornozelo.component.html',
  styleUrls: ['./pele-tecido-tornozelo.component.css']
})
export class PeleTecidoTornozeloComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdEdema') rdEdema: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'perimaleolar lateral', viewValue: 'Perimaleolar lateral' },
    { value: 'perimaleolar medial', viewValue: 'Perimaleolar medial' },
    { value: 'da face anterior', viewValue: 'Face anterior' },
    { value: 'da face lateral', viewValue: 'Face lateral' },
    { value: 'da face medial', viewValue: 'Face medial' },
  ];

  showAndHideTerms: any;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahEdema: true
    };
  }

  clickNormal() {
    this.showAndHideTerms.sahEdema = false;
    this.catchIDs();
  }

  clickEdema() {
    this.showAndHideTerms.sahEdema = true;
    this.catchIDs();
  }

  changeSelect() {
    this.verificaLesoes();
    this.catchIDs();
  }
    
  verificaLesoes(){
    if(this.slLocalizacao && this.slLocalizacao.value){
      this.rdEdema.checked = true;
    }
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.value);
    }

    if (this.rdEdema.checked) {
      this.termoSelecionado.emit(this.rdEdema.value);
      
      if (this.slLocalizacao && this.slLocalizacao.value) {
        const objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }

}
