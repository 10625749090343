import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { Validacoes } from 'src/app/utils/validacoes';

@Component({
  selector: 'app-cer-placenta',
  templateUrl: './cer-placenta.component.html',
  styleUrls: ['./cer-placenta.component.css']
})
export class CerPlacentaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild('rdNaoCitar') rdNaoCitar: MatRadioButton;
  @ViewChild('rdCitar') rdCitar: MatRadioButton;
  
  @ViewChild('rdNaoOcupa') rdNaoOcupa: MatRadioButton;
  @ViewChild('rdOcupaParc') rdOcupaParc: MatRadioButton;
  @ViewChild('rdOcupaTotal') rdOcupaTotal: MatRadioButton;

  @ViewChild('inMedida') inMedida: ElementRef;
  
  @ViewChild('slIncersao') slIncersao: MatSelect;

  incersoes: ArrayDropDown[] = [
    { value: 'corporal anterior', viewValue: 'Corporal Anterior' },
    { value: 'corporal posterior', viewValue: 'Corporal Posterior' }
  ];

  showAndHideTerms: any;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: true,
      sahMedida: false
    }
  }

  clickNaoCitar() {
    this.showAndHideTerms.sahCitar = false;
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  clickCitar() {
    this.showAndHideTerms.sahCitar = true;
    this.showAndHideTerms.sahMedida = false;
    this.catchIDs();
  }

  clickNaoOcupa() {
    this.showAndHideTerms.sahMedida = true;
    this.verificaLesoes();
    this.catchIDs();
  }

  clickOcupaParc() {
    this.showAndHideTerms.sahMedida = false;
    this.verificaLesoes();
    this.catchIDs();
  }

  clickOcupaTotal() {
    this.showAndHideTerms.sahMedida = false;
    this.verificaLesoes();
    this.catchIDs();
  }


  blurMedindo() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    if (this.rdNaoCitar.checked) {
      this.termoSelecionado.emit(this.rdNaoCitar.id);
    }

    if (this.rdCitar && this.rdCitar.checked) {

      if (this.rdNaoOcupa && this.rdNaoOcupa.checked) {
        this.termoSelecionado.emit(this.rdNaoOcupa.id);

        if (this.inMedida &&
          this.inMedida.nativeElement.value !== '') {
          const Diam = this._validacoes.formataDecimal(this.inMedida.nativeElement.value);
          const objetoElemento = { id: this.inMedida.nativeElement.id, value: Diam, cenario: 0 };
          const objeto = { elemento: objetoElemento, isPrimeiraLesao: false };
          this.termosGenericos.emit(objeto);
        }

        if (this.slIncersao && this.slIncersao.value) {
          const objeto = { elemento: this.slIncersao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdOcupaParc && this.rdOcupaParc.checked) {
        this.termoSelecionado.emit(this.rdOcupaParc.id);

        if (this.slIncersao && this.slIncersao.value) {
          const objeto = { elemento: this.slIncersao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }

      if (this.rdOcupaTotal && this.rdOcupaTotal.checked) {
        this.termoSelecionado.emit(this.rdOcupaTotal.id);

        if (this.slIncersao && this.slIncersao.value) {
          const objeto = { elemento: this.slIncersao, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }
    this.listaCompleta.emit();
  }

  verificaLesoes() {
    if(this.rdNaoOcupa.checked || this.rdOcupaParc.checked || this.rdOcupaTotal.checked) {
      this.rdCitar.checked = true;
    }
  }

}
