import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { HeaderComponent } from './header/header.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { ToastrModule } from 'ng6-toastr-notifications';
import { TextMaskModule } from 'angular2-text-mask';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTabsModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatFormFieldControl,
  MAT_DATE_LOCALE,
  MatTableDataSource,
  MatDialogModule,
  MAT_DATE_FORMATS,
  MatTooltipModule,
  MatCheckboxModule,
  MatBadgeModule,
  MatAutocompleteModule, MatAutocomplete, MatSelectModule, MatFormFieldModule, MatStep, MatChipsModule
} from '@angular/material';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';

// services
import { ViaCepServiceService } from './services/via-cep-service.service';
import { UserService } from './services/user.service';
import { AuthService } from './auth/auth.service';
import { User } from './auth/user';
import { Authintersepctor } from './interseptors/AuthInterseptor';
import { LoginProtocoloComponent } from './login/login-protocolo/login-protocolo.component';
import { LoginEmailComponent } from './login/login-email/login-email.component';
import { EsqueceuSenhaComponent } from './login/esqueceu-senha/esqueceu-senha.component';
import { ListaExamesService } from './services/lista-exames.service';
import { Validacoes } from './utils/validacoes';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LaudoComponent } from './laudo/laudo.component';
import { LaudoPipe } from './laudo.pipe';
import { PesquisaComponent } from './pesquisa/pesquisa.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptorServiceService } from './services/loader-interceptor-service.service';
import { LaudosComponent } from './laudos/laudos.component';
import { LaudosModule } from './laudos/laudos.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SelectedItemEmiter } from './utils/SelectedItemEmiter';
import { ClipboardModule } from 'ngx-clipboard';
import { CopyPaste } from './utils/textEditor/CopyPaste';
import { ConfirmDialogComponent } from './utils/dialog/confirm-dialog/confirm-dialog.component';
import { TermoUsoDialogComponent } from './utils/dialog/termo-uso-dialog/termo-uso-dialog.component';
import { RinsDialogComponent } from './utils/dialog/table/rins-dialog/rins-dialog.component';
import { ResiduoDialogComponent } from './utils/dialog/table/residuo-dialog/residuo-dialog.component';
import { TireoideDialogComponent } from './utils/dialog/table/tireoide-dialog/tireoide-dialog.component';
import { IntegrationComponent } from './integration/integration.component';
import { MenuTcModule } from './laudos/tc/menu-tc/menu-tc.module';
import { MenuUsModule } from './laudos/us/menu-us/menu-us.module';
import { SenhaConfirmacaoComponent } from './login/senha-confirmacao/senha-confirmacao.component';
import { MedicalReportLog } from './models/BO/MedicalReportLog';
import { MedicalReportLogService } from './services/MedicalReportLog.service';
import { AuditBlurDirective } from './directives/audit-blur.directive';
import { AuditFocusDirective } from './directives/audit-focus.directive';
import { AuditChangeDirective } from './directives/audit-change.directive';
import { CustomDirectiveModule } from './directives/custom-directive.module';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { MenuLateralModule } from './menu-lateral/menu-lateral.module';
import { PlanosComponent } from './planos/planos.component';
import { PagamentosComponent } from './planos/pagamentos/pagamentos.component';
import { PlanosModule } from './planos/planos.module';
import { AlertDialogComponent } from './utils/dialog/alert-dialog/alert-dialog.component';
import { NotificationDialogComponent} from './utils/dialog/notification-dialog/notification-dialog.component';
import { ObsCalculadoraComponent } from './laudos/us/obstetrico/obs-calculadora/obs-calculadora.component';
import { Calculadora } from './utils/calculadora';
import { PerfilComponent } from './perfil/perfil.component';
import { ListaLaudoModule } from './lista-laudo/lista-laudo.module';
import { CarotidaDialogComponent } from './utils/dialog/table/carotida-dialog/carotida-dialog.component';
import { LiquidoAmnioticoDialogComponent } from './utils/dialog/table/liquido-amniotico-dialog/liquido-amniotico-dialog.component';
import { ParametrosBiometricosDialogComponent } from './utils/dialog/table/parametros-biometricos-dialog/parametros-biometricos-dialog.component';
import { FetoDialogComponent } from './utils/dialog/table/feto-dialog/feto-dialog.component';
import { BacoDialogComponent } from './utils/dialog/table/baco-dialog/baco-dialog.component';
import { ConfigLaudoComponent } from './config-laudo/config-laudo.component';
import { TireoideTiradDialogComponent } from './utils/dialog/table/tireoide-tirad-dialog/tireoide-tirad-dialog.component';
import { SuporteComponent } from './suporte/suporte.component';
import { AlteraLaudoComponent } from './altera-laudo/altera-laudo.component';
import { MenuMgModule } from './laudos/mg/menu-mg/menu-mg.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlteraLaudoListComponent } from './altera-laudo-list/altera-laudo-list.component';
import { AnguloGrafDialogComponent } from './utils/dialog/table/angulo-graf-dialog/angulo-graf-dialog.component';
import { TireoideTuringDialogComponent } from './utils/dialog/table/tireoide-pontos-dialog/tireoide-turing-dialog.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { DesbloqLaudoDialogComponent } from './utils/dialog/desbloq-laudo-dialog/desbloq-laudo-dialog.component';
import { PouchDBService } from './services/pouchdb.service';
import { PagamentosRealizadosComponent } from './pagamentos-realizados/pagamentos-realizados.component';
import { PagamentosRealizadosModule } from './pagamentos-realizados/pagamentos-realizados.module';
import { AlteraPagamentoComponent } from './altera-pagamento/altera-pagamento.component';
import { InteractivePaycardModule } from 'ngx-interactive-paycard';
import { PagamentoDialogComponent } from './utils/dialog/pagamento-dialog/pagamento-dialog.component';
import { VersaoComponent } from './versao/versao.component';
import { TireoideDopplerNivelCervicalComponent } from './utils/dialog/imagens/tireoide-doppler-nivel-cervical/tireoide-doppler-nivel-cervical.component';
import { FigadoSegmentacaoComponent } from './utils/dialog/imagens/figado-segmentacao/figado-segmentacao.component';
import { FeedbackDialogComponent } from './utils/dialog/feedback-dialog/feedback-dialog.component';
import { MenuExpansivo } from './manual-de-uso/manual-de-uso.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TranslucenciaNucalDialogComponent } from './utils/dialog/table/translucencia-nucal-dialog/translucencia-nucal-dialog.component';
import { DuctoVenosoDialogComponent } from './utils/dialog/table/ducto-venoso-dialog/ducto-venoso-dialog.component';
import { RiscoPreEclampsiaDialogComponent } from './utils/dialog/table/risco-pre-eclampsia-dialog/risco-pre-eclampsia-dialog.component';
import { RiscoAneuploidiasDialogComponent } from './utils/dialog/table/risco-aneuploidias-dialog/risco-aneuploidias-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CadastroModule } from './cadastro/cadastro.module';
import { TranslucenciaNucalMorfoDialogComponent } from './utils/dialog/table/translucencia-nucal-morfo-dialog/translucencia-nucal-morfo-dialog.component';
import { LaudoPorVozComponent } from './laudo-por-voz/laudo-por-voz.component';
import { TextEditorModule } from './utils/textEditor/text-editor/text-editor.module';
import { TextEditorJSmodule } from './utils/text-editor-js/text-editor-js.module';
import { ObstetricoImagesReferenciaComponent } from './utils/dialog/imagens/obstetrico-images-referencia/obstetrico-images-referencia.component';
import { MemberGetMemberComponent } from './member-get-member/member-get-member.component';
import { PercentilIpMediaDialogComponent } from './utils/dialog/table/percentil-ip-medio-dialog/percentil-ip-medio-dialog.component';
import { AlteraLaudoModule } from './altera-laudo/altera-laudo.module';
import { MenuRxModule } from './laudos/rx/menu-rx/menu-rx.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DopplervelocimetriaComponent } from './laudos/us/obstetrico/transv-seg-terc-doppler/TermosComponents/dopplervelocimetria/dopplervelocimetria.component';
import { ManejoRcuiDialogComponent } from './utils/dialog/imagens/manejo-rciu-dialog/manejo-rciu-dialog.component';
registerLocaleData(localePt, 'pt');
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { GemelarDialogComponent } from './utils/dialog/gemelar-dialog/gemelar-dialog.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    LoginProtocoloComponent,
    LoginEmailComponent,
    EsqueceuSenhaComponent,
    LaudoComponent,
    LaudoPipe,
    PesquisaComponent,
    LoaderComponent,
    LaudosComponent,
    ConfirmDialogComponent,
    TermoUsoDialogComponent,
    RinsDialogComponent,
    ResiduoDialogComponent,
    TireoideDialogComponent,
    TireoideTuringDialogComponent,
    IntegrationComponent,
    SenhaConfirmacaoComponent,
    AlertDialogComponent,
    PagamentoDialogComponent,
    NotificationDialogComponent,
    PerfilComponent,
    CarotidaDialogComponent,
    LiquidoAmnioticoDialogComponent,
    ParametrosBiometricosDialogComponent,
    ManejoRcuiDialogComponent,
    PercentilIpMediaDialogComponent,
    FetoDialogComponent,
    BacoDialogComponent,
    ConfigLaudoComponent,
    TireoideTiradDialogComponent,
    SuporteComponent,
    DashboardComponent,
    // PlanosComponent,
    // PagamentosComponent
    AlteraLaudoListComponent,
    AnguloGrafDialogComponent,
    AlteraPagamentoComponent,
    DesbloqLaudoDialogComponent,
    PagamentosRealizadosComponent,
    PagamentoDialogComponent,
    VersaoComponent,
    TireoideDopplerNivelCervicalComponent,
    FigadoSegmentacaoComponent,
    FeedbackDialogComponent,
    MenuExpansivo,
    TranslucenciaNucalDialogComponent,
    DuctoVenosoDialogComponent,
    RiscoPreEclampsiaDialogComponent,
    RiscoAneuploidiasDialogComponent,
    TranslucenciaNucalMorfoDialogComponent,
    LaudoPorVozComponent,
    ObstetricoImagesReferenciaComponent,
    MemberGetMemberComponent,
    IndicadoresComponent,
    GemelarDialogComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatBadgeModule,
    FlexLayoutModule,
    CdkTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatInputModule,
    MatCheckboxModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    HttpModule,
    TextMaskModule,
    LaudosModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    FilterPipeModule,
    FormsModule,
    ClipboardModule,
    MenuTcModule,
    MenuUsModule,
    MenuLateralModule,
    MenuMgModule,
    MenuRxModule,
    PlanosModule,
    ListaLaudoModule,
    MatAutocompleteModule,
    MatSelectModule,
    RecaptchaModule,
    PagamentosRealizadosModule,
    InteractivePaycardModule,
    MatFormFieldModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatChipsModule,
    MatSlideToggleModule,
    CadastroModule,
    TextEditorModule,
    TextEditorJSmodule,
    AlteraLaudoModule,
    DragDropModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#54c5ce",
      innerStrokeColor: "#25DEC8",
      titleColor: "#483500",
      subtitleColor: "#483500",
      animationDuration: 300,
      lazy: false
    }),
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }

  }),
  PowerBIEmbedModule,
  ],
  entryComponents: [
    LoginComponent,
    LoginProtocoloComponent,
    LaudoComponent,
    PesquisaComponent,
    ConfirmDialogComponent,
    TermoUsoDialogComponent,
    RinsDialogComponent,
    ResiduoDialogComponent,
    TireoideDialogComponent,
    TireoideTuringDialogComponent,
    MenuLateralComponent,
    AlertDialogComponent,
    PagamentoDialogComponent,
    NotificationDialogComponent,
    CarotidaDialogComponent,
    LiquidoAmnioticoDialogComponent,
    ParametrosBiometricosDialogComponent,
    ManejoRcuiDialogComponent,
    PercentilIpMediaDialogComponent,
    FetoDialogComponent,
    BacoDialogComponent,
    TireoideTiradDialogComponent,
    DesbloqLaudoDialogComponent,
    AnguloGrafDialogComponent,
    TireoideDopplerNivelCervicalComponent,
    FigadoSegmentacaoComponent,
    FeedbackDialogComponent,
    TranslucenciaNucalDialogComponent,
    DuctoVenosoDialogComponent,
    RiscoPreEclampsiaDialogComponent,
    RiscoAneuploidiasDialogComponent,
    TranslucenciaNucalMorfoDialogComponent,
    ObstetricoImagesReferenciaComponent,
    DopplervelocimetriaComponent,
    GemelarDialogComponent
  ],
  providers: [
    PouchDBService,
    ViaCepServiceService,
    UserService,
    SelectedItemEmiter,
    User,
    MedicalReportLog,
    MedicalReportLogService,
    ListaExamesService,
    Validacoes,
    CopyPaste,
    ConfirmDialogComponent,
    TermoUsoDialogComponent,
    RinsDialogComponent,
    ResiduoDialogComponent,
    TireoideDialogComponent,
    TireoideTuringDialogComponent,
    AlertDialogComponent,
    PagamentoDialogComponent,
    NotificationDialogComponent,
    TranslucenciaNucalDialogComponent,
    TranslucenciaNucalMorfoDialogComponent,
    DuctoVenosoDialogComponent,
    DatePipe,
    Calculadora,
    LiquidoAmnioticoDialogComponent,
    ParametrosBiometricosDialogComponent,
    ManejoRcuiDialogComponent,
    PercentilIpMediaDialogComponent,
    FetoDialogComponent,
    BacoDialogComponent,
    TireoideTiradDialogComponent,
    AnguloGrafDialogComponent,
    TireoideDopplerNivelCervicalComponent,
    FigadoSegmentacaoComponent,
    RiscoPreEclampsiaDialogComponent,
    RiscoAneuploidiasDialogComponent,
    ObstetricoImagesReferenciaComponent,
    DopplervelocimetriaComponent,
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-br'
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Authintersepctor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorServiceService,
      multi: true
    },
    GemelarDialogComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
