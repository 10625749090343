import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ListaPlanosService } from '../services/lista-planos.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private permissionRes: boolean;
  private plnaUser: number;
  private planLaudo: number;

  constructor(
    private authService: AuthService,
    private router: Router,
    private _planoService: ListaPlanosService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isLoggedIn
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.router.navigate(['/login']);

            return false;
          } else {
            // const userID = parseInt(localStorage.getItem('userID'));
            // const routeRule = next.routeConfig.children[0].data;

            // const checkPermission = this._planoService.buscarPlanosPorUsuario(userID).toPromise().then(
            //   exames => {
            //     exames.forEach(item => {
            //       item.forEach(planUser => {
            //         if (routeRule != undefined) {
            //           routeRule.roles.map(planExame => {
            //             if (planExame === 5 || planUser.specialityID === planExame) {
            //               this.plnaUser = planUser.specialityID
            //               this.planLaudo = planExame;

            //               return this.permissionRes = true;
            //             }

            //             this.plnaUser = planUser.specialityID
            //             this.planLaudo = planExame;
            //           });
            //         }
            //       });
            //     });

            //     if (exames.length === 0 || routeRule == undefined) {
            //       if (routeRule == undefined) {

            //         return this.permissionRes = true;
            //       } else {
            //         localStorage.setItem('errorMessage', JSON.stringify({ message: 'Acesso negado!' }));
            //         this.router.navigate(['/laudos']);

            //         return this.permissionRes = false;
            //       }
            //     }

            //     if (this.plnaUser !== this.planLaudo && this.planLaudo !== 5 && this.permissionRes !== true) {
            //       localStorage.setItem('errorMessage', JSON.stringify({ message: 'Acesso negado!' }));
            //       this.router.navigate(['/laudos']);

            //       return this.permissionRes = false;
            //     }

            //     return this.permissionRes;
            //   }, erro => {
            //     console.log(erro);
            //   });

            // return checkPermission.then(res => {
            //   if (res) {
            //     return true;
            //   } else {
            //     return false;
            //   }
            // }) ? true : false;
            return true;
          }
        })
      );
  }
}
