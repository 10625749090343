import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from "rxjs";
import { User } from '../../auth/user';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-login-protocolo',
  templateUrl: './login-protocolo.component.html',
  styleUrls: ['./login-protocolo.component.css']
})
export class LoginProtocoloComponent implements OnInit {

  formProtocolo: FormGroup;

  private formSubmitAttempt: boolean;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private user: User,
    private toastr: ToastrManager
  ) { }

  ngOnInit() {
    this.formProtocolo = this.fb.group({
      protocolo: ['', Validators.required],
      password: ['', Validators.required]
    })

    this.formProtocolo.patchValue({
      protocolo: null,
      password: null
    });

  }

  isFieldInvalidPtc(field: string){

    return (
      (!this.formProtocolo.get(field).valid && this.formProtocolo.get(field).touched) ||
      (this.formProtocolo.get(field).untouched && this.formSubmitAttempt)
    );

  }

  onSubmit(){
    if(this.formProtocolo.valid){
      
      this.authService.validaProtocolo(this.formProtocolo.value)
      .subscribe(
        (res) =>{
          if(res.mensagem === "OK"){ // tudo ok o paciente sera redirecionado para a tela de cadastro
            this.authService.loggedIn.next(true);
            this.user.setProtLocalStorage(res);
            this.router.navigate(['/cadastro']);
            this.formProtocolo.reset();
          } 
          
          else if(res.mensagem === "Laudo não disponível até o momento") {
            this.toastr.errorToastr(`${res.mensagem}, tente novamente mais tarde!`,"Protocolo Sem Laudo",
              {
                position: 'top-right',
                toastTimeout: 4000
              });
            
            this.formProtocolo.enable();
          }
          
          else if(res.mensagem === "3 - Paciente invalido!") {
            this.toastr.errorToastr("Certifique-se que o protocolo e senha estão corretos","Paciente inválido",
              {
                position: 'top-right',
                toastTimeout: 4000
              }
            );
            
            this.formProtocolo.enable();
          }

          else {
            this.toastr.errorToastr(res.mensagem,"Protocolo inválido",
              {
                position: 'top-right',
                toastTimeout: 4000
              }
            );
            this.formProtocolo.enable();
          }

        },
        erro =>{ 
          this.toastr.errorToastr("Protocolo não encontrado!!!","Protocolo inválido",
            {
              position: 'top-right',
              toastTimeout: 4000
            }
          );
          console.log(erro);
          this.formProtocolo.enable();
        }
      )

    }

    
    this.formSubmitAttempt = true;
  }

}
