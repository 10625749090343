import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton, MatSelect, MatCheckbox } from '@angular/material';
import { ArrayDropDown } from '../../../../../../models/Helper/ArrayDropDown';

@Component({
  selector: 'app-arteria-poplitea',
  templateUrl: './arteria-poplitea.component.html',
  styleUrls: ['./arteria-poplitea.component.css']
})
export class ArteriaPopliteaComponent implements OnInit {

  public showAndHideTerms: any;

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdnaoCaracterizada') rdnaoCaracterizada: MatRadioButton;
  @ViewChild('rdPopliteaPlacaComMedida') rdPopliteaPlacaComMedida: MatRadioButton;
  @ViewChild('rdPopliteaMultiplasPlacasEstenosantes') rdPopliteaMultiplasPlacasEstenosantes: MatRadioButton;
  @ViewChild('rdPopliteaTromboseComMedida') rdPopliteaTromboseComMedida: MatRadioButton;
  @ViewChild('rdPopliteaEndoproteseStent') rdPopliteaEndoproteseStent: MatRadioButton;
  @ViewChild('rdPopliteaAneurismaSemtrombose') rdPopliteaAneurismaSemtrombose: MatRadioButton;
  @ViewChild('slmotivo') slmotivo: MatSelect;
  @ViewChild('sllocalizacao') sllocalizacao: MatSelect;
  @ViewChild('slecotextura') slecotextura: MatSelect;
  @ViewChild('slecogenicidade') slecogenicidade: MatSelect;
  @ViewChild('slsuperficie') slsuperficie: MatSelect;
  @ViewChild('Inespessura') Inespessura: ElementRef;
  @ViewChild('slestenose') slestenose: MatSelect;
  @ViewChild('slfluxodistal') slfluxodistal: MatSelect;
  @ViewChild('Inextensao') Inextensao: ElementRef;
  @ViewChild('Incalibre') Incalibre: ElementRef;
  @ViewChild('sltrombo') sltrombo: MatSelect;
  @ViewChild('sllocalizacaos') sllocalizacaos: MatSelect;


  public motivo: ArrayDropDown[] = [
    { value: 'edema', viewValue: 'Edema' },
    { value: 'atenuação do feixe acústico', viewValue: 'Atenuação do feixe acústico' },
  ];

  public localizacao: ArrayDropDown[] = [
    { value: 'no terço proximal', viewValue: 'Terço Proximal' },
    { value: 'no terço médio', viewValue: 'Terço Médio' },
    { value: 'no terço distal', viewValue: 'Terço Distal' },
  ];

  public ecogenicidade: ArrayDropDown[] = [
    { value: 'calcificada', viewValue: 'Calcificada' },
    { value: 'mista', viewValue: 'Mista' },
    { value: 'iso/ hiperecogênica', viewValue: 'Iso/ Hiperecogênica' },
    { value: 'hipoecogênica', viewValue: 'Hipoecogênica' },
  ];


  public ecotextura: ArrayDropDown[] = [
    { value: 'homogênea', viewValue: 'Homogênea' },
    { value: 'heterogênea', viewValue: 'Heterogênea' },
  ];

  public superficie: ArrayDropDown[] = [
    { value: 'regular', viewValue: 'Regular' },
    { value: 'irregular', viewValue: 'Irregular' },
    { value: 'ulcerada', viewValue: 'Ulcerada' },
  ];


  public estenose: ArrayDropDown[] = [
    {
      value: 'sem determinar estenose hemodinamicamente significativa (< 50%)',
      viewValue: 'Sem determinar estenose hemodinamicamente significativa (< 50%)'
    },
    {
      value: 'determinando estenose hemodinamicamente significativa (≥ 50%)',
      viewValue: 'Determinando estenose hemodinamicamente significativa (≥ 50%)'
    },
    { value: 'determinando suboclusão', viewValue: 'Determinando Suboclusão' },
  ];

  public fluxodistal: ArrayDropDown[] = [
    { value: 'bifásico', viewValue: 'Bifásico' },
    { value: 'monofásico', viewValue: 'Monofásico' },
    { value: 'tardus-parvus', viewValue: 'Tardus-parvus' },
  ];

  public localizacaos: ArrayDropDown[] = [
    { value: 'em toda sua extensão', viewValue: 'Toda Sua Extensão' },
    { value: 'no terço proximal', viewValue: 'Terço Proximal' },
    { value: 'no terço médio', viewValue: 'Terço Médio' },
    { value: 'no terço distal', viewValue: 'Terço Distal' },
  ];


  public trombo: ArrayDropDown[] = [
    { value: 'trombo mural', viewValue: 'Trombo Mural' },
    { value: 'trombose', viewValue: 'Trombose' },
  ];

  public unidadeMedida: string;

  constructor() { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahnaoCaracterizada: false,
      sahPopliteaPlacaComMedida: false,
      sahPopliteaMultiplasPlacasEstenosantes: false,
      sahPopliteaTromboseComMedida: false,
      sahPopliteaEndoproteseStenta: false,
      sahPopliteaAneurismaSemtrombose: false,
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public clicknormal(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = false;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = false;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = false;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clicknaocaracterizada(): any {
    this.showAndHideTerms.sahnaoCaracterizada = true;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = false;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = false;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = false;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickPopliteaPlacaComMedida(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = true;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = false;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = false;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = false;
    this.catchIDs();
  }


  public clickPopliteaMultiplasPlacasEstenosantes(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = false;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = true;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = false;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = false;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickPopliteaTromboseComMedida(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = false;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = true;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = false;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickPopliteaEndoproteseStenta(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = false;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = false;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = true;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = false;
    this.catchIDs();
  }

  public clickPopliteaAneurismaSemtrombose(): any {
    this.showAndHideTerms.sahnaoCaracterizada = false;
    this.showAndHideTerms.sahPopliteaPlacaComMedida = false;
    this.showAndHideTerms.sahPopliteaMultiplasPlacasEstenosantes = false;
    this.showAndHideTerms.sahPopliteaTromboseComMedida = false;
    this.showAndHideTerms.sahPopliteaEndoproteseStenta = false;
    this.showAndHideTerms.sahPopliteaAneurismaSemtrombose = true;
    this.catchIDs();
  }

  public changeSelect(): any {
    this.catchIDs();
  }


  public blurMedindo(): any {
    this.catchIDs();
  }


  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');

    if (this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    // Não Caracterizada
    if (this.rdnaoCaracterizada && this.rdnaoCaracterizada.checked) {
      this.termoSelecionado.emit(this.rdnaoCaracterizada.id);

      if (this.slmotivo && this.slmotivo.value) {
        const objeto = { elemento: this.slmotivo };
        this.termosGenericos.emit(objeto);
      }
    }

    // Placa Com Medida
    if (this.rdPopliteaPlacaComMedida && this.rdPopliteaPlacaComMedida.checked) {
      this.termoSelecionado.emit(this.rdPopliteaPlacaComMedida.id);


      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

      if (this.slecogenicidade && this.slecogenicidade.value) {
        const objeto = { elemento: this.slecogenicidade };
        this.termosGenericos.emit(objeto);
      }

      if (this.slecotextura && this.slecotextura.value) {
        const objeto = { elemento: this.slecotextura };
        this.termosGenericos.emit(objeto);
      }

      if (this.slsuperficie && this.slsuperficie.value) {
        const objeto = { elemento: this.slsuperficie };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inespessura && this.Inespessura.nativeElement.value) {
        const objeto = { elemento: this.Inespessura.nativeElement };
        this.termosGenericos.emit(objeto);
      }

      if (this.slestenose && this.slestenose.value) {
        const objeto = { elemento: this.slestenose };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inextensao && this.Inextensao.nativeElement.value) {
        const objeto = { elemento: this.Inextensao.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    // Poplitea Multiplas Placas Estenosantes
    if (this.rdPopliteaMultiplasPlacasEstenosantes && this.rdPopliteaMultiplasPlacasEstenosantes.checked) {
      this.termoSelecionado.emit(this.rdPopliteaMultiplasPlacasEstenosantes.id);

      if (this.slfluxodistal && this.slfluxodistal.value) {
        const objeto = { elemento: this.slfluxodistal };
        this.termosGenericos.emit(objeto);
      }
    }

    // Poplitea Trombose Com Medida
    if (this.rdPopliteaTromboseComMedida && this.rdPopliteaTromboseComMedida.checked) {
      this.termoSelecionado.emit(this.rdPopliteaTromboseComMedida.id);

      if (this.sllocalizacaos && this.sllocalizacaos.value) {
        const objeto = { elemento: this.sllocalizacaos };
        this.termosGenericos.emit(objeto);
      }

      if (this.Inextensao && this.Inextensao.nativeElement.value) {
        const objeto = { elemento: this.Inextensao.nativeElement };
        this.termosGenericos.emit(objeto);
      }
    }

    // Poplitea Endoprotese Stent
    if (this.rdPopliteaEndoproteseStent && this.rdPopliteaEndoproteseStent.checked) {
      this.termoSelecionado.emit(this.rdPopliteaEndoproteseStent.id);

      if (this.sllocalizacao && this.sllocalizacao.value) {
        const objeto = { elemento: this.sllocalizacao };
        this.termosGenericos.emit(objeto);
      }

      if (this.slestenose && this.slestenose.value) {
        const objeto = { elemento: this.slestenose };
        this.termosGenericos.emit(objeto);
      }
    }

    // Poplitea Aneurisma Sem Trombose
    if (this.rdPopliteaAneurismaSemtrombose && this.rdPopliteaAneurismaSemtrombose.checked) {
      this.termoSelecionado.emit(this.rdPopliteaAneurismaSemtrombose.id);

      if (this.sltrombo && this.sltrombo.value) {
        const objeto = { elemento: this.sltrombo };
        this.termosGenericos.emit(objeto);
      }

      if (this.Incalibre && this.Incalibre.nativeElement.value) {
        const objeto = { elemento: this.Incalibre.nativeElement };
        this.termosGenericos.emit(objeto);
      }

      if (this.sllocalizacaos && this.sllocalizacaos.value) {
        const objeto = { elemento: this.sllocalizacaos };
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();

  }

}
