import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioButton } from '@angular/material';
import { Validacoes } from 'src/app/utils/validacoes';


@Component({
  selector: 'app-tabela-direto',
  templateUrl: './tabela-direto.component.html',
  styleUrls: ['./tabela-direto.component.css']
})
export class TabelaDiretoComponent implements OnInit {
  public showAndHideTerms: any;
  public phRRADireita = 'RRA Direita';
  public phRRAEsquerda = 'RRA Esquerda';


  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() atualizaTexto = new EventEmitter();

  @ViewChild('rdnaocitar') rdnaocitar: MatRadioButton;
  @ViewChild('rdcitar') rdcitar: MatRadioButton;
  @ViewChild('ACCAortaCm') ACCAortaCm: ElementRef;
  @ViewChild('ACCRenalDirCm') ACCRenalDirCm: ElementRef;
  @ViewChild('ACCRenalEsqCm') ACCRenalEsqCm: ElementRef;
  @ViewChild('ACCRRADir') ACCRRADir: ElementRef;
  @ViewChild('ACCRRAEsq') ACCRRAEsq: ElementRef;

  unidadeMedida: string;

  constructor(private _validacoes: Validacoes) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahCitar: false
    };
      let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  public ClickNaoCitar(): any {
    this.showAndHideTerms.sahCitar = false;
    this.catchIDs();
  }

  public ClickCitar(): any {
    this.showAndHideTerms.sahCitar = true;
    this.catchIDs();
  }

  public blurMedida(): any {
    this.catchIDs();
  }

  public vericaMedidas(): any {
    let calculoAorta: any = 0;
    let calculoRenal: any = 0;
    let calculoRenalEsq: any = 0;

    if (this.ACCAortaCm !== undefined &&
      this.ACCAortaCm.nativeElement.value != undefined && this.rdcitar.checked) {

      const element = calculoAorta = { value: this.ACCAortaCm.nativeElement.value, id: this.ACCAortaCm.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCAortaCm.nativeElement.value) {
      const element = { value: 0, id: this.ACCAortaCm.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCRenalDirCm !== undefined &&
      this.ACCRenalDirCm.nativeElement.value && this.rdcitar.checked) {

      const element = calculoRenal = { value: this.ACCRenalDirCm.nativeElement.value, id: this.ACCRenalDirCm.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCRenalDirCm.nativeElement.value) {
      const element = { value: 0, id: this.ACCRenalDirCm.nativeElement.id };
      this.enviaMedida.emit(element);

    } else if (!this.ACCRenalDirCm.nativeElement.value) {
      const element = { value: 0, id: this.ACCRenalDirCm.nativeElement.id };
      this.enviaMedida.emit(element);
    }


    if (this.ACCRenalEsqCm !== undefined &&
      this.ACCRenalEsqCm.nativeElement.value && this.rdcitar.checked) {

      const element = calculoRenalEsq = { value: this.ACCRenalEsqCm.nativeElement.value, id: this.ACCRenalEsqCm.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (this.rdnaocitar.checked && this.ACCRenalEsqCm.nativeElement.value) {
      const element = { value: 0, id: this.ACCRenalEsqCm.nativeElement.id };
      this.enviaMedida.emit(element);
    } else if (!this.ACCRenalEsqCm.nativeElement.value) {
      const element = { value: 0, id: this.ACCRenalEsqCm.nativeElement.id };
      this.enviaMedida.emit(element);
    }

    if (this.ACCAortaCm && this.ACCAortaCm.nativeElement.value
      && this.ACCRenalDirCm && this.ACCRenalDirCm.nativeElement.value) {

      const calculo = calculoRenal.value / calculoAorta.value ;

      this.phRRADireita = this._validacoes.formataDecimal(calculo.toFixed(1).toString());


      if (this.ACCRRADir !== undefined &&
        this.ACCRRADir.nativeElement.value && this.rdcitar.checked) {

        const element = { value: this.ACCRRADir.nativeElement.value, id: this.ACCRRADir.nativeElement.id };
        this.enviaMedida.emit(element);
      } else {

        const element = { value: this.phRRADireita, id: this.ACCRRADir.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.rdnaocitar.checked && this.ACCRRADir.nativeElement.value) {
        const element = { value: 0, id: this.ACCRRADir.nativeElement.id, calculo: 0 };
        this.enviaMedida.emit(element);

      } else if (this.rdnaocitar.checked && this.phRRADireita) {
        const element = { value: 0, id: this.ACCRRADir.nativeElement.id, calculo: 0 };
        this.enviaMedida.emit(element);

      }
    }

    if (this.ACCAortaCm && this.ACCAortaCm.nativeElement.value
      && this.ACCRenalEsqCm && this.ACCRenalEsqCm.nativeElement.value) {

      const calculoEsq = calculoRenalEsq.value / calculoAorta.value ;

      this.phRRAEsquerda = this._validacoes.formataDecimal(calculoEsq.toFixed(1).toString());

      if (this.ACCRRAEsq !== undefined &&
        this.ACCRRAEsq.nativeElement.value && this.rdcitar.checked) {

        const element = { value: this.ACCRRAEsq.nativeElement.value, id: this.ACCRRAEsq.nativeElement.id };
        this.enviaMedida.emit(element);
      } else {

        const element = { value: this.phRRAEsquerda, id: this.ACCRRAEsq.nativeElement.id };
        this.enviaMedida.emit(element);
      }

      if (this.rdnaocitar.checked && this.ACCRRAEsq.nativeElement.value) {
        const element = { value: 0, id: this.ACCRRAEsq.nativeElement.id, calculoEsq: 0 };
        this.enviaMedida.emit(element);

      } else if (this.rdnaocitar.checked && this.phRRAEsquerda) {
        const element = { value: 0, id: this.ACCRRAEsq.nativeElement.id, calculoEsq: 0 };
        this.enviaMedida.emit(element);
      }
    }
  }


  public catchIDs(): any {
    this.termoSelecionado.emit('Clean');
    this.phRRADireita = 'RRA Direita';
    this.phRRAEsquerda = 'RRA Esquerda';

    if (this.rdnaocitar && this.rdnaocitar.checked) {
      // this.termoSelecionado.emit(this.rdnaocitar.id);
    }

    this.atualizaTexto.emit();
    this.vericaMedidas();
    this.listaCompleta.emit();
  }
}
